import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'

import Card from '@material-ui/core/Card'
import Paper from '@material-ui/core/Paper'
import SwipeableTabs from '../Reusable/SwipeableTabs'

import ReleasMarkdown from './ReleaseNotes.md'
import UpcomingReleases from './UpcomingReleases.md'

const styles = theme => ({
    text: {
        marginLeft: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit * 2,
    },
})

class ReleaseNotes extends Component {
    constructor(props) {
        super(props)

        this.state = { previousText: null, upcomingText: null }
    }

    componentWillMount() {
        fetch(ReleasMarkdown)
            .then(response => response.text())
            .then(previousText => {
                this.setState({ previousText: previousText })
            })

        fetch(UpcomingReleases)
            .then(response => response.text())
            .then(upcomingText => {
                this.setState({ upcomingText: upcomingText })
            })
    }

    render() {
        var Remarkable = require('remarkable')
        var md = new Remarkable({ html: true })
        const { classes } = this.props

        return (
            <SwipeableTabs titles={['Previous Releases', 'Upcoming Features']}>
                <Card>
                    <div
                        className={classes.text}
                        dangerouslySetInnerHTML={{
                            __html: md.render(this.state.previousText),
                        }}
                    />
                </Card>
                <Card>
                    <div
                        className={classes.text}
                        dangerouslySetInnerHTML={{
                            __html: md.render(this.state.upcomingText),
                        }}
                    />
                </Card>
            </SwipeableTabs>
        )
    }
}

ReleaseNotes.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ReleaseNotes)
