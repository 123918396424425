import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import {
    Modal,
    Paper,
    Menu,
    TableCell,
    IconButton,
    Icon,
    MenuItem,
    ListItem,
    List,
    ListItemText,
    InputBase,
    Tooltip,
    Grid,
    Button,
    Typography,
    Collapse,
    TextField,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import EnhancedTable from '../Reusable/EnhancedTable'
import { PostData } from '../../services/PostData'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    lower: {
        textTransform: 'capitalize',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    pullRight: {
        float: 'right',
    },
})

class OpportunityValuesByQuote extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            searchField: '',
            columns: [],
            allQuotes: [],
            close_prob: 'All',
            booking_type: 'All'
        }

        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
    }

    handleSearchClick = searchVal => {
        if (!searchVal) {
            searchVal = this.state.searchField
        }

        this.setState({ searchValue: searchVal })
        this.fetchList('Company Name', searchVal)
    }

    handleSearchChange = e => {
        this.setState({ searchField: e.target.value })
    }

    componentWillMount() {
        this.searchQuotes('')
        //this.getDropdowns()

    }
    // getDropdowns = () =>{
    //     const data = {
    //         action: 'getNewDropdown',
    //         subsystem_code: 'OPPORTUNITY',
    //     }

    //     PostData(
    //         'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
    //         data
    //     ).then(response => {
    //         if (response.result) {
    //             this.setState({ dropdown: response.dropdowns })
    //         }
    //     })
    // }

    handleFilterChange = e =>{
        
        let value = e.target.value
        let name = e.target.name
        let columns = this.state.allQuotes
        let filterValue = ''

        this.setState({ [name]: value }, () => {
            if (this.state.booking_type !== 'All') {
                filterValue = this.state.booking_type.toUpperCase().replace(' ', '_')
    
                columns = columns.filter(quote=>{
                    if (quote.booking_type === filterValue) {
                        return true
                    }
                    return false
                })
            }

            if (this.state.close_prob !== 'All') {    
                columns = columns.filter(quote=>{
                    if (Number(quote.close_prob) >= Number(this.state.close_prob)) {
                        return true
                    }
                    return false
                })
            }
    
            this.setState({ columns: columns })
        })
    }

    openQuote = quote =>{   
        
        let userData = JSON.parse(sessionStorage.getItem('userData'))
            if (quote != 0) {
                let milestoneData
                const data1 = {
                    action: 'getQuoteCurrentMilestone',
                    current_milestone_template: quote.alternate_terms,
                    sbu:
                        userData === null ? null : userData.strategic_business_unit,
                    userid: userData === null ? null : userData.userid,
                    quote_number: quote.quote_number,
                    //hug_schema: 'hug_horizons'
                }

                PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data1).then(
                    response => {
                        if (response.result && response.result !== 'false') {
                            milestoneData = response.quote_milestonesArr
                            const data2 = {
                                action: 'getQMDforQuote2',

                                quote_number: quote.quote_number,
                                //hug_schema: 'hug_horizons',
                                sbu:
                                    userData === null
                                        ? null
                                        : userData.strategic_business_unit,
                            }

                            PostData(
                                'Quotes/ajax/ajaxhugQuoteSystem2019.php',
                                data2
                            ).then(response2 => {
                                const data3 = {
                                    action: 'getStartupData2', //faster than getStartUpData, 2 just has less data
                                    crm_id: quote.crm_id,
                                    hug_schema: 'hug_' +(JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                                    strategic_business_unit: JSON.parse(
                                        sessionStorage.getItem('userData')
                                    ).strategic_business_unit,
                                }
                                PostData('CRM/ajax/ajaxCRM_2018.php', data3).then(response3 => {
                                    this.props.setFormData(
                                        {
                                            header: response2.qte_header,
                                            module_details: response2.Q_Modules_Details,
                                            milestoneData: milestoneData,
                                            crmData: response3.CRMdata,
                                            new: false
                                        },
                                        'Quotes'
                                    )
                                })
                                
                            })
                        }
                    }
                )
        }
    }
    handleSearchChange = e => {
        e.persist()
        this.setState(()=> {return {searchField: e.target.value}})
    }

    handleSearchClick = () =>{
        this.searchQuotes(this.state.searchField)
    }

    searchQuotes = searchVal =>{
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        const data = {
            action: 'getQuotesWithOpportunities',
            hug_schema: 'hug_'+ userData.strategic_business_unit.toLowerCase(),
            searchVal: searchVal,
        }
        PostData('Quotes/ajax/ajaxQuotesList_2018.php', data).then(response =>{
            this.setState(()=>{
                return{columns: response.resultSet, allQuotes: response.resultSet}
            })
        })
    }

    render() {
        const { classes } = this.props

        const rows = [
            {
                id: 'quote_number',
                numeric: false,
                disablePadding: false,
                label: 'Number',
            },
            {
                id: 'clientname',
                numeric: true,
                disablePadding: false,
                label: 'Company',
            },
            {
                id: 'name',
                numeric: true,
                disablePadding: false,
                label: 'Name',
            },
            {
                id: 'status',
                numeric: true,
                disablePadding: false,
                label: 'Status',
            },
            {
                id: 'lead_stage',
                numeric: true,
                disablePadding: false,
                label: 'Stage',
            },
            {
                id: 'close_prob',
                numeric: true,
                disablePadding: false,
                label: 'Close Probability',
            },
            {
                id: 'quoteamount',
                numeric: true,
                disablePadding: false,
                label: 'Amount',
            },
            {
                id: 'saas_total_saas',
                numeric: true,
                disablePadding: false,
                label: 'SAAS',
            },
            {
                id: 'subscription_fees_dollar_value',
                numeric: true,
                disablePadding: false,
                label: 'Subscription',
            },
            {
                id: 'support_fees_dollar_value',
                numeric: true,
                disablePadding: false,
                label: 'Maintenance',
            },
            {
                id: 'total',
                numeric: true,
                disablePadding: false,
                label: 'Total',
            },
        ]

        return (
            <EnhancedTable
                className={classes.secondary}
                rows={rows}
                columns={this.state.columns}
                showHeader={true}
                link={this.openQuote}
                toolbar={
                    <CustomToolbar
                        classes={classes}
                        handleSearchClick={this.handleSearchClick}
                        handleSearchChange={this.handleSearchChange}
                        handleFilterChange={this.handleFilterChange}
                        // booking_type={this.state.booking_type}
                        dropdown={this.state.dropdown}
                        close_prob={this.state.close_prob}
                    />
                }
                tableRow={<CustomTableCells />}
                totalRow={<CustomTotalRow quotes={this.state.columns} />}
            />
        )
    }
}

class CustomTableCells extends React.Component {
    toTitleCase = str => {
        if (str !== null) {
            str = str.replace(/_/g, ' ')
            return str.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
        }
    }
    
    render() {
        const n = this.props.n

        return (
            <React.Fragment >
                <TableCell padding="checkbox" />
                <TableCell style={{cursor: 'pointer'}} component="th" scope="row" padding={'default'}>
                    {n.quote_number}
                </TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">{n.clientname}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">{n.name}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">{this.toTitleCase(n.status)}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">{this.toTitleCase(n.lead_stage)}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">{n.close_prob}%</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">${n.quoteamount ? n.quoteamount : 0}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">${n.saas_fees_dollar_value ? n.saas_fees_dollar_value : 0}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">${n.subscription_fees_dollar_value ? n.subscription_fees_dollar_value : 0}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">${n.support_fees_dollar_value ? n.support_fees_dollar_value : 0}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">${Number(n.quoteamount) + Number(n.saas_fees_dollar_value)
                    + Number(n.subscription_fees_dollar_value) + Number(n.support_fees_dollar_value)}</TableCell>
            </React.Fragment>
        )
    }
}

class CustomToolbar extends React.Component {
    render() {
        // let booking_type = ['All', 'New Named', 'Internal Based']
        // this.props.dropdown && this.props.dropdown.forEach(dropdown =>{
            
        //     if(dropdown.control_code === 'BOOKING_TYPE'){
        //         booking_type.push(dropdown)
        //     }
        // })

        let close_prob = ['All', "1", "5", "10", "25", "50", "75", "80", "90", "100"]
        
        const { classes } = this.props
        return (
            <React.Fragment>
                <Grid spacing = {5} container>
                    <Grid item style={{ paddingTop: 5, marginTop: 35 }}>
                        <InputBase
                            className={this.props.classes.input}
                            placeholder={'Search'}
                            onChange={this.props.handleSearchChange}
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    this.props.handleSearchClick()
                                    ev.preventDefault()
                                }
                            }}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 10 }}>
                        <IconButton
                            className={this.props.classes.iconButton}
                            aria-label={'Search'}
                            onClick={
                                this.props.handleSearchClick
                            }
                        >
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1} xl={1} style={{marginTop: 5}}>
                        <TextField
                            value={this.props.close_prob}
                            name='close_prob'
                            id='close_prob'
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            label="Close Probability"
                        >
                            {close_prob && close_prob.map(item =>(
                                <MenuItem value={item} key={item} >
                                    {item}
                                </MenuItem>)
                                )}
                        </TextField>
                    </Grid>
                    {/* <Grid item xs={4} sm={3} md={2} lg={1} xl={1} style={{marginTop: 5}}>
                        <TextField
                            value={this.props.booking_type}
                            name='booking_type'
                            id='booking_type'
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            label="Booking Type"
                        >
                            {booking_type && booking_type.map(item =>(
                                <MenuItem value={item} key={item} >
                                    {item}
                                </MenuItem>)
                                )}
                        </TextField>
                    </Grid> */}
                </Grid>
            </React.Fragment>
        )
    }
}

class CustomTotalRow extends React.Component {
    render() {
        let quoteTotal = 0
        let saasTotal = 0
        let subscriptionTotal = 0
        let maintenanceTotal = 0
        
        this.props.quotes.map(quote => {
            quoteTotal += Number(quote.quoteamount)
            saasTotal += Number(quote.saas_total_saas)
            subscriptionTotal += Number(quote.subscription_fees_dollar_value)
            maintenanceTotal += Number(quote.support_fees_dollar_value)
        })

        return (
            <React.Fragment>
                <TableCell padding="checkbox" />
                <TableCell colspan={6} style={{cursor: 'pointer'}} component="th" scope="row" padding={'default'}>
                    Totals:
                </TableCell>
                <TableCell align="right">${quoteTotal.toFixed(2)}</TableCell>
                <TableCell align="right">${saasTotal.toFixed(2)}</TableCell>
                <TableCell align="right">${subscriptionTotal.toFixed(2)}</TableCell>
                <TableCell align="right">${maintenanceTotal.toFixed(2)}</TableCell>
                <TableCell align="right">${Number(quoteTotal.toFixed(2)) + Number(saasTotal.toFixed(2)) + Number(subscriptionTotal.toFixed(2)) + Number(maintenanceTotal.toFixed(2))}</TableCell>
            </React.Fragment>
        )
    }
}

OpportunityValuesByQuote.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(OpportunityValuesByQuote)
