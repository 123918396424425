import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import moment from 'moment';
import { Link } from 'react-router-dom'

import { PostData } from '../../../services/PostData'

import { withStyles, rgbToHex } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import DateFnsUtils from '@date-io/date-fns'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import ReactToPrint from 'react-to-print';

import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import {
    faChevronDown,
    faChevronUp,
    faMapMarkedAlt,
    faBurn,
    faAward,
    faBan,
    faFilePdf,
    faPrint,
    faSave,
    faReceipt,
    faPlus
} from '@fortawesome/pro-light-svg-icons'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { faTimes, faCopy, faLayerPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { closestIndexTo, isFirstDayOfMonth } from 'date-fns'

import Tooltip from '@material-ui/core/Tooltip'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { addValidators, validate } from '../../../utils/Validate'
import { FilePicker } from 'react-file-picker'
import CKEditor from 'ckeditor4-react'
import { DropzoneArea } from 'material-ui-dropzone'
import NumberFormat from 'react-number-format'
import Divider from '@material-ui/core/Divider'
import FileUploader from '../../Reusable/FileUploader'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'

import { Collapse } from '@material-ui/core'
import FormWrapper from '../../Reusable/FormWrapper'
import SwipeableTabs from '../../Reusable/SwipeableTabs'
import Notes from '../Notes/Notes'
import NoteForm from '../Notes/NoteForm'
import ProductRecap from '../Products/ProductRecap'
import { isNullOrUndefined } from 'util'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SaveWarning from '../../Reusable/SaveWarning'
import {CSVLink, CSVDownload} from 'react-csv';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    modifiedTextField: {
        marginTop: 5,
        marginRight: 5,
        marginLeft: 5,
        paddingRight: 15,
    },
    modifiedText: {
        marginLeft: 5,
        paddingRight: 15,
        fontSize: 12,
    },
    
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        //marginLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '98%',
        background: 'rgb(248,248,248)',
    },
    saasTable: {
        border: '2px solid rgb(232,232,232)',
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        width: '98%',
        background: 'rgb(248,248,248)',
    },
    row: {
        background: 'white',
    },
    rowHeader: {
        background: theme.palette.secondary.light,
        color: 'white',
    },
    rowHeaderTotal: {
        background: 'rgb(235,235,235)',
        fontWeight: 500,
    },
    cardButtons: {
        color: '#0166ff',
    },
    rowGrandTotal: {
        background: 'rgb(230,230,230)',
        fontWeight: 500,
    },
    cellHeaderTotal: {
        fontWeight: 500,
    },
    cellGrandTotal: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(15),
    },
    cellHeader: {
        fontWeight: 500,
        color: 'white',
    },
    list: {
        marginTop: theme.spacing(1),
        borderTop: '2px solid rgb(232,232,232)',
    },
    paper: {
        marginTop: theme.spacing(1),
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        marginTop: theme.spacing(1),
    },
    wonIt: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        backgroundColor: '#ecf9f1',
        border: '4px solid #ecf9f1',
    },
    lostIt: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        backgroundColor: '#ffe6e6',
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
        
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    add: {
        color: 'black',
        padding: 5,
        margin: 1,
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
    buttons: {
        color: 'white',
        backgroundColor: theme.palette.primary.dark,

        marginTop: 4,
        marginBottom: 4,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 500,
    },
    total: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 500,
        paddingLeft: 23,
    },
    wrongtotal: {
        color: 'red',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 500,
        paddingLeft: 23,
    },
    specialText: {
        marginLeft: 5,
        paddingRight: 15,
        fontSize:12,
        color: 'white'
    },
    specialText2: {
        marginLeft: 5,
        paddingRight: 15,
        fontSize:12,
        color: 'rgb(240,240,240)',
        cursor: 'context-menu',
    },
    highlight: {
        cursor: 'pointer',
    },
    lines: {
        cursor: 'pointer',
        backgroundColor: theme.palette.secondary.paper,
    },
    selected: {
        backgroundColor: 'rgb(240,240,240)',
        color: theme.palette.secondary.main.dark,
        fontWeight: '500',
    },
    fileBox: {
        minHeight: '10px',
    },
    locked: {
        background: 'rgb(248,248,248)',
    },
    cellGrandTotal: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(15),
    },
    errorButton: {
        backgroundColor: '#f44336',
        color: 'white'
    },
    scrolling: {
        position: 'sticky',
        top: 0
    },
    save: {
        color: 'white',
        //textTransform: 'capitalize',
        // fontSize: 19,
        marginRight: 10,
    },
    icon: {
        marginRight: 5,
        marginBottom: 2,
    },
})

const DEFAULT_STATEMENT_OF_WORK = 'dataVoice will install and configure the product modules pursuant to the scope set forth in the Product Description '
    +'section of the proposal. Products listed as Optional at the time of signing are not included in any installation and configuration until they are '
    +'purchased by the customer. Any material changes or deviations from the standard configuration may, in dataVoice’s sole discretion, result in additional fee(s).'
    +'<p>'
    +'Project Management fees for a 90 day project implementation have been included. If the project delays beyond the 90 period, dataVoice '
    +'reserves the right to submit a change order for additional project management fees as necessary.'

class QuotesForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: true,
            dropdown: [],
            header: {
                description: '',
                quoteby: '',
                rep_assigned: JSON.parse(sessionStorage.getItem('userData'))
                    .userid,
                referred_by: '',
                lead_stage: '',
                status: '',
                close_prob: '',
                quotedate: '',
                quoteclosedate: '',
                quotesentto: '',
                quotedoc: '',
                proposal_milestone: '',
                statement_of_work: '',
                booking_type: '',
                service_type: [],
                campaign_id: null,
            },
            productPricing: {},
            original_product_details: {},
            product_details: {},
            recap: {},
            milestoneData: {},
            selectedProductLine: '',
            selecteProductModuleName: '',
            selectedProductModule: '',
            overview: true,
            products: false,
            milestones: false,
            saasCalc: false,
            wonQuote: false,
            lostQuote: false,
            newWO: {
                contractdate: '', //this.getCurrentDate(),
                duedate: '',
                assignto: '',
                purchase_order: '',
                po_date: '',
                po_requested_by: '',
            },
            lostData: {
                quoteclosedate: this.getCurrentDate(),
                reason_lost: '',
                competition: '',
            },
            newQuote: false,

            addMilestone: false,
            newMilestone: {},
            activeMilestones: [],
            meters: [],
            addPO: false,
            POdata: {},
            hover: false,
            emailData: {},
            saasData: {},
            noteFormOpen: false,
            files: {},
            popupWonOpen: false,
            popupLostOpen: false,
            locked: false,
            noClientID: false,
            clientInfo: {},
            includeQuoteValue: {
                net_software_price: true,
                professional_fees: true,
                hardware: true,
                training: true,
                custom: true,
            },
            // duplicate: false,
            lostInfoAdded: false,
            csvData: [],
            showProfile: false,
            showPurchaseOrder: false,
            tabIndex: 0,
            optionalLines: [],
        }
    }
    
    componentWillMount() {
        // console.log('mount')
        this.setupForm(this.props);
    }

    componentWillReceiveProps(nextProps) {
        // console.log('recieve')
        this.setupForm(nextProps);
    }

    //Sets up the form
    setupForm = props => {
        // console.log(props)
        // console.log('setup form')

        if (props.formData.header !== undefined) {
            let temp = this.state
            if(!temp.header){
                temp.header = {}
            }
            temp.header = props.formData.header
            temp.header.rep_assigned = props.formData.header.rep_assigned || JSON.parse(sessionStorage.getItem('userData')).userid
            /*if(props.formData.module_details.length > 0){
                temp.selectedProductLine = props.formData.module_details[0].product_line
                temp.selectedProductModule = props.formData.module_details[0].product_module
            }*/

            temp.csvData = [];

            let originalProductDetails = []
            temp.product_details = props.formData.module_details

            // Sort the product details by product line
            // temp.product_details.sort((a, b) => (a.product_line > b.product_line) ? 1 : -1)

            let lastProductLine = ''
            let hasProductsWithQty = false

            let productLineTotals = [];
            let grandTotals = {
                Product_Line: 'Total',
                Module: '',
                Quantity: 0,
                List_Price: 0,
                License_Fee: 0,
                PS_Fees: 0,
                Net_Invoice: 0,
                Maintenance: 0,
                Annual_Cost: 0,
            }            

            temp.product_details.map(productDetails => {
                if (productDetails.custom_description === null || productDetails.custom_description === '') {
                    productDetails.custom_description = productDetails.detailed_sales_desc
                }

                //if (productDetails.addon_option !== 'true' && productDetails.qty > 0) {    
                if (productDetails.qty > 0) {    
                    hasProductsWithQty = true

                    let maintenance = productDetails.maint_cost
                    //let maintenance = (productDetails.maint_cost > 0 ? productDetails.maint_cost : productDetails.lic_fee_amount)

                    let annualCost = (productDetails.lic_fee_period === 'MONTHLY' ? 
                        (productDetails.maint_cost > 0 ? productDetails.maint_cost : productDetails.lic_fee_amount) * 12 :
                        (productDetails.maint_cost > 0 ? productDetails.maint_cost : productDetails.lic_fee_amount))

                    // If the product line is not equal to the last one, then we're changing section, so we need to add the last section totals
                    // if (lastProductLine !== '' && lastProductLine !== productDetails.product_line) {
                    //     temp.csvData.push(productLineTotals[lastProductLine])
                    // }

                    lastProductLine = productDetails.product_line
                    
                    // Add the totals for this product module
                    if (productLineTotals[productDetails.product_line] === undefined) {
                        productLineTotals[productDetails.product_line] = {
                            Product_Line: productDetails.product_line + ' Total',
                            Module: '',
                            Quantity: 0,
                            List_Price: 0,
                            License_Fee: 0,
                            PS_Fees: 0,
                            Net_Invoice: 0,
                            Maintenance: 0,
                            Annual_Cost: 0,
                        }
                    }

                    productLineTotals[productDetails.product_line].Quantity += Number(productDetails.qty)
                    productLineTotals[productDetails.product_line].List_Price += Number(productDetails.price_each)
                    productLineTotals[productDetails.product_line].License_Fee += Number(productDetails.software_fees)
                    productLineTotals[productDetails.product_line].PS_Fees += Number(productDetails.professional_services)
                    productLineTotals[productDetails.product_line].Net_Invoice += Number(productDetails.net_billable_amount)
                    productLineTotals[productDetails.product_line].Maintenance += Number(maintenance)
                    productLineTotals[productDetails.product_line].Annual_Cost += Number(annualCost)
                    
                    // Add the totals for this product line to the grand total
                    grandTotals.Quantity += Number(productDetails.qty)
                    grandTotals.List_Price += Number(productDetails.price_each)
                    grandTotals.License_Fee += Number(productDetails.software_fees)
                    grandTotals.PS_Fees += Number(productDetails.professional_services)
                    grandTotals.Net_Invoice += Number(productDetails.net_billable_amount)
                    grandTotals.Maintenance += Number(maintenance)
                    grandTotals.Annual_Cost += Number(annualCost)

                    let userFeesAmount = productDetails.quote_user_fees_amount * productDetails.qty;
                    
                    if (productDetails.user_fee_period === "MONTHLY") {
                        userFeesAmount = userFeesAmount * 12 
                    }

                    // Add the product module date to the csv data
                    temp.csvData.push({
                        Product_Line: productDetails.product_line,
                        Module: productDetails.product_desc,
                        Quantity: productDetails.qty,
                        List_Price: productDetails.price_each,
                        License_Fee: productDetails.software_fees,
                        PS_Fees: productDetails.professional_services,
                        Net_Invoice: productDetails.net_billable_amount,
                        Maintenance: maintenance,
                        Annual_Cost: annualCost,
                        Lic_Fee_Amount: productDetails.lic_fee_amount,
                        User_Fees_Amount: userFeesAmount,
                        Discount: productDetails.discount,
                        Optional: productDetails.addon_option === 'true' ? 1 : 0,
                    })
                }
            })

            // if (hasProductsWithQty) {
            //     temp.csvData.push(productLineTotals[lastProductLine])
            //     temp.csvData.push(grandTotals)
            // }

            temp.csvData.sort(function (a,b) {
                return (a['Product_Line']+a['Module']).localeCompare(b['Product_Line']+b['Module']);       
            });

            if (temp.product_details !== null && props.formData.module_details !== undefined) {
                for (let i = 0; i < temp.product_details.length; i++) {
                    let originalProduct = {}
                    Object.keys(temp.product_details[i]).map(key => {
                        originalProduct[key] = temp.product_details[i][key]
                    })
                    originalProductDetails.push(originalProduct)
                }
            }
            temp.original_product_details = originalProductDetails

            temp.milestoneData = props.formData.milestoneData
            temp.utilityPersonnel = this.getUtilityPersonnel()
            temp.harrisPersonnel = this.getHarrisPersonnel()
    
            this.getDropdown()
            this.getQuoteRecap()
            this.getOptionalProductLines()
            this.getMeterCount()
            this.getProductPricing()
    
            if (props.formData.new === true) {
                temp.newQuote = true
                typeof props.formData.header.service_type === 'object' ? temp.header.service_type = props.formData.header.service_type : temp.header.service_type = temp.header.service_type = []//makes the mulitselect work
                temp.header.status = 'OPEN'
                // temp.header.quoteclosedate = this.getCurrentDate()
                // temp.header.statement_of_work = DEFAULT_STATEMENT_OF_WORK
                temp.header.statement_of_work = props.crmData.default_statement_of_work
            }
    
            if (props.formData.opportunityInfo) {
                temp.opportunityInfo = props.formData.opportunityInfo
            }
    
            temp.loading = false

            let POdata = {}
            POdata.purchase_order = temp.header.purchase_order
            POdata.po_requested_by = temp.header.po_requested_by
            POdata.po_date = temp.header.po_date
            temp.POdata = POdata

            let newWO = {}
            newWO.purchase_order = temp.header.purchase_order
            newWO.po_requested_by = temp.header.po_requested_by
            newWO.po_date = temp.header.po_date
            temp.newWO = newWO
    
            this.setState(temp)
    
            //Makes sure required fields are filled in
            addValidators(this, {
                name: 'required',
                rep_assigned: 'required',
                lead_stage: 'required',
                close_prob: 'required',
                booking_type: 'required',
                alternate_terms: 'required',
                quoteclosedate: 'required',
                contract_payment: 'required'
            })
    
            if (this.state.header.status === 'WON' && (this.state.header.work_order === '' || this.state.header.work_order === null || this.state.header.work_order === undefined)) {
                this.setState({ locked: true, wonQuote: true })
                this.addWonValidators()
            } else if (this.state.header.status === 'LOST') {
                this.setState({ locked: true, lostQuote: true })
                this.addLostValidators()

                if (this.state.header.competition && this.state.header.reason_lost) {
                    this.setState({ lostInfoAdded: true })
                }
            }
        }
    }

    addWonValidators = () => {
        addValidators(this, {
            contractdate: 'required',
            duedate: 'required',
            assignto: 'required',
        })
    }

    addLostValidators = () => {
        // addValidators(this, {
        //     competition: 'required',
        //     reason_lost: 'required',
        // })
    }

    reloadForm = () => {
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        let state = this.state

        const data1 = {
            action: 'getQuoteCurrentMilestone',
            current_milestone_template: this.state.header.alternate_terms,
            sbu: userData === null ? null : userData.strategic_business_unit,
            userid: userData === null ? null : userData.userid,
            quote_number: this.state.header.quote_number,
            //hug_schema: 'hug_horizons'
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data1).then(
            response => {
                if (response.result && response.result !== 'false') {
                    // console.log(response)
                    this.setState({milestoneData: response.quote_milestonesArr})
                }
            }
        )

        const data2 = {
            action: 'getQMDforQuote2',
            quote_number: this.state.header.quote_number,
            //hug_schema: 'hug_horizons',
            sbu: userData === null ? null : userData.strategic_business_unit,
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data2).then(response => {
            if (response.result && response.result !== 'false') {
                this.setState({product_details: response.Q_Modules_Details})
            }
        })
    }

    handleClose = () => {
        this.props.handleFormClose(true)
    }

    handleTabChange = (index) => {
        this.setState({ tabIndex: index })
    }

    handleCopy = () => {
        this.setState({ loading: true }, () => {
            let header = this.state.header
            header.name = header.name + " (Duplicate)"
            this.setState({ header: header}, () => {
                this.createQuote(true)
            }) 
        })
    }

    //Gets list of contacts in company
    getUtilityPersonnel = () =>{
        const data = {
            action: 'getUtilityPersonnelList',
            crm_id: this.props.formData.crmData.crm_id,
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
        }

        PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {
            if (response.result) {
                this.setState({
                    utilityPersonnel: response.UtilityPersonnelList,
                })
            }
        })
    }

    getHarrisPersonnel = () =>{
        const data = {
            action: 'getContacts',
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
        }

        PostData('CRM/ajax/ajaxContact_Notes.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ harrisPersonnel: response.contacts })
                }
            }
        )
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //Structure
    //////////////////////////////////////////////////////////////////////////////////////////////////

    //When an opportunity is turned into a quote, this saves the quote number to the old opportunity
    handleQuoteNumSave = quoteNum => {
        let data = {
            action: 'updateColumnValue',
            qualifier: 'recordid=' + this.state.opportunityInfo.recordid,
            column_sql_type: 'integer',
            column_name: 'quote_number',
            column_value: parseInt(quoteNum),
            recordid: this.state.opportunityInfo.recordid,
            user: JSON.parse(sessionStorage.getItem('userData')).userid,
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
            table_name: 'hug.harris_sbu_leads_tracking',
        }

        PostData('CRM/ajax/ajaxCRMLeadsTracker.php', data).then(response => {
            if (response.result) {
                this.props.updateComponent('Opportunities')
            }
        })
        let data2 = {
            action: 'updateColumnValue',
            qualifier: 'recordid=' + this.state.opportunityInfo.recordid,
            column_sql_type: 'text',
            column_name: 'lead_stage',
            column_value: 'Ready To Quote - 25%',
            recordid: this.state.opportunityInfo.recordid,
            user: JSON.parse(sessionStorage.getItem('userData')).userid,
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
            table_name: 'hug.harris_sbu_leads_tracking',
        }

        PostData('CRM/ajax/ajaxCRMLeadsTracker.php', data2).then(response2 => {
            if (response2.result) {
                this.props.updateComponent('Opportunities')
            }
        })
    }

    handleDateChange = (date, field, won, po) => {
        let data 
        
        if (won) {
            data = this.state.newWO
        } else if (po) {
            data = this.state.POdata  
        } else {
            data = this.state.header
        }

        data[field] = date
        this.setState({ data: data })
    }

    //Saves all aspects of the quote or creates a new quote
    handleSave = (event, quote_number = null) => {
        let fields = (this.state.header.status === 'WON' ? {...this.state.header, ...this.state.newWO } : this.state.header)

        if (validate(this, fields)) {
            if (!this.state.newQuote) {
                if (quote_number === null) {
                    quote_number = this.state.header.quote_number
                }

                // If the quote has already been won, create the work order
                if (this.state.status === 'WON' && (this.state.header.work_order === '' || this.state.header.work_order === null || this.state.header.work_order === undefined)) {
                    this.createWO()

                // IF the quote has already been lost, set the lost info
                } else if (this.state.status === 'LOST') {
                    this.getLostQuote()
                } 

                this.handleProductDetailsSave(quote_number)
                this.saveServiceTypes(quote_number)
                this.handleOverviewSave2(quote_number)
            } else {
                this.createQuote()
            }

            return true
        }

        return false
    }

    //Gets all dropdowns in the form
    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'QUOTE',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }

    getCurrentDate(separator = '/') {
        let newDate = new Date()
        let date = newDate.getDate()
        if (date < 10) {
            date = String(date)
            date = '0' + date
        }
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()

        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`
    }

    //Converts strings to Title Case
    toTitleCase = str =>{
        str= str.replace(/_/g, ' ')
        return str.replace(
            /([^\W_]+[^\s-]*) */g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////
    //Extra Conditions
    /////////////////////////////////////////////////////////////////////////////////////////////////

    //Changes for this.state.newWO
    handleWonQuoteChange = event => {
        let change = this.state.newWO
        change[event.target.name] = event.target.value
        this.setState({ newWO: change })
    }

    //Changes for quote status
    handleStatusQuoteChange = event => {
        let change = this.state.header
        change[event.target.name] = event.target.value
        this.setState({ header: change })
    }

    //Allows admins to unlock a locked quote
    unlock = () => {
        let change = this.state.header
        change.status = 'OPEN'
        this.setState({ header: change, locked: false })
    }

    //Creates a work order when quote marked as Won. Will create a new client record if one doesnt exist
    createWO = () => {
        let fields = (this.state.header.status === 'WON' ? {...this.state.header, ...this.state.newWO } : this.state.header)

        if (validate(this, fields)) {
            const data = {
                action: 'createWorkorder',
                hug_schema:
                    'hug_' +
                    JSON.parse(
                        sessionStorage.getItem('userData')
                    ).strategic_business_unit.toLowerCase(),
                user: JSON.parse(sessionStorage.getItem('userData')).userid,
                sbu: JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,
                contractdate: this.state.newWO.contractdate,
                duedate: this.state.newWO.duedate,
                assignto: this.state.newWO.assignto,
                purchase_order: this.state.newWO.purchase_order,
                po_date: this.state.newWO.po_date,
                po_requested_by: this.state.newWO.po_requested_by,
                quote_number: this.state.header.quote_number,
                crm_id: this.state.header.crm_id,
                status: this.state.header.status,
            }

            PostData('Quotes/ajax/ajaxhugWonQuote_2019.php', data).then(
                response => {
                    if (response.result === true) {
                        let header = this.state.header
                        header.status = 'WON'
                        header.work_order = response.new_work_order_no
                        this.setState({ header: header })
                        this.handleSave()
                    } else {
                        const data1 = {
                            action: 'getClientInfo',
                            crm_id: this.state.header.crm_id,
                            strategic_business_unit: JSON.parse(
                                sessionStorage.getItem('userData')
                            ).strategic_business_unit,
                        }
                
                        PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data1).then(response => {
                            if (response.result && response.ResultsFound_sbu =="No Record Found for sbu") {
                                this.setState({noClientID: true})
                            }
                        })  
                        
                    }

                    this.props.updateComponent('Quotes')
                    this.props.updateComponent('WorkOrders')
                    this.props.updateComponent('Products')
                }
            )
        }
    }


    //Adds a client ID if there isnt one for the BU
    handleNoClientID = () =>{
        this.setState({ noClientID: false })
        const data = {
            action: 'insert_record',
            crm_id: this.state.header.crm_id,
            user_id: JSON.parse(sessionStorage.getItem('userData')).userid,
            strategic_business_unit: JSON.parse(
                sessionStorage.getItem('userData')
            ).strategic_business_unit,
            sbu_client_id:
                JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit +
                '_' +
                this.state.header.crm_id,
            hug_schema:
                'hug_' +
                JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,

            harris_client_id:
                'TEMP_HARRIS_' +
                JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit +
                '_' +
                this.state.header.crm_id,
            active: true,
            date_won: this.getCurrentDate(),
        }

        PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data).then(response => {
            this.props.updateComponent('ClientInfo')
            this.createWO()
        })
    }

    //Not currently used
    /*handlewinError = () =>{
        this.setState({ winError: false })
        const data = {
            action: 'update_record',
            user_id: JSON.parse(sessionStorage.getItem('userData')).userid,
            recordid: this.state.clientInfo.recordid,
            crm_id: this.state.header.crm_id,
            strategic_business_unit: JSON.parse(
                sessionStorage.getItem('userData')
            ).strategic_business_unit,
            sbu_client_id: this.state.clientInfo.sbu_client_id,
            harris_client_id:
                'TEMP_HARRIS_' +
                JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit +
                '_' +
                this.state.header.crm_id,
            team_support_id: this.state.clientInfo.team_support_id,
            active: true,
            date_won: this.state.clientInfo.date_won,
            date_lost: this.state.clientInfo.date_lost,
            purchase_order_required: this.state.clientInfo
                .purchase_order_required,
            at_risk_status: this.state.clientInfo.at_risk_status,
            client_info_note: this.state.clientInfo.client_info_note,
            at_risk_comments: this.state.clientInfo.at_risk_comments,
        }

        PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data).then(response => {
            this.props.updateComponent('ClientInfo')
            this.createWO()
        })
            
        
       
    }*/

    //Shows quote locked message
    showLocked = () => {
        const { classes } = this.props
        if (
            this.state.header.status == 'WON' ||
            this.state.header.status == 'LOST' ||
            this.state.header.status == 'CLOSED'
        ) {
            return (
                <Card>
                    <Grid container spacing={1} className={classes.locked}>
                        <Grid item xs={11}>
                            <Typography
                                component="h6"
                                //variant="h6"
                                color="inherit"
                                className={classes.textField}
                            >
                                {'The quote details below cannot be edited because it is marked as ' +
                                    this.state.header.status}
                            </Typography>
                        </Grid>
                        {JSON.parse(sessionStorage.getItem('userData'))
                            .access_level == 1 ? (
                            <Grid item xs={1}>
                                <Button onClick={this.unlock}>Unlock</Button>
                            </Grid>
                        ) : (
                            <Grid item xs={1}></Grid>
                        )}
                    </Grid>
                </Card>
            )
        }
    }

    //Marks a quote as lost and updates DB
    getLostQuote = () => {
        if (validate(this, this.state.header)) {
            const data = {
                action: 'lost_quote',
                //hug_schema: 'hug_horizons',
                user: JSON.parse(sessionStorage.getItem('userData')).userid,
                sbu: JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,
                quoteclosedate: this.state.quoteclosedate,
                reason_lost: this.state.reason_lost,
                competition: this.state.competition,
                quote_number: this.state.quote_number,
                company: '',
                global_quote_number: this.state.header.quote_number,
                status: this.state.header.status,
                cause_of_loss: this.state.header.cause_of_loss
            }

            PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
                response => {
                    if (response.result) {
                        let header = this.state.header
                        header.status = 'LOST'

                        this.setState({
                            // lostQuote: false,
                            lostInfoAdded: true,
                            header: header
                        })

                        this.handleSave()
                        // this.handleClose()
                    }
                }
            )
        }
    }

    //Shows popup form if quote is marked as won
    showWonQuoteForm = () => {        
        const { classes } = this.props
        if (this.state.wonQuote == true && (this.state.header.work_order === null || this.state.header.work_order === undefined || this.state.work_order === '')) {
            return (
                <Grid container spacing={1} style={{paddingBottom: 10}}>
                    <Grid item xs={12}>
                        <Card>
                            <div className={classes.secondary}>
                                <Grid container>
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        color="inherit"
                                        className={classes.textField}
                                    >
                                        Create Work Order
                                    </Typography>
                                    {/* <Grid item className={classes.buttonGrid}>
                                        <Button
                                            color="primary"
                                            className={classes.add}
                                            onClick={this.createWO}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCheck}
                                                size="2x"
                                                color="white"
                                            />
                                        </Button>

                                        <Button
                                            onClick={() =>
                                                this.setState({ wonQuote: false })
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                size="2x"
                                                color="white"
                                            />
                                        </Button>
                                    </Grid> */}
                                </Grid>
                            </div>

                            <SaveWarning invalid={true} customMessage="Please complete this form to create the work order" />

                            {/* <Paper className={classes.wonIt}>
                                <Typography>
                                    Please complete this form to create the work order
                                </Typography>
                            </Paper> */}

                            <Grid container spacing={1} className={classes.wonIt}>
                                <Grid item xs={12} sm={4}>
                                    {/* <TextField
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        id="contractdate"
                                        name="contractdate"
                                        label="Contract Date"
                                        type="date"
                                        fullWidth
                                        className={classes.textField}
                                        defaultValue={this.getCurrentDate()}
                                        value={this.state.newWO.contractdate}
                                        onChange={this.handleWonQuoteChange}
                                    /> */}

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            // disabled={this.state.header.work_order !== null}
                                            required
                                            id="contractdate"
                                            name="contractdate"
                                            label="Contract Date"
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            className={classes.textField}
                                            // defaultValue={this.getCurrentDate()}
                                            value={this.state.newWO.contractdate || null}
                                            onChange={(date) => {this.handleDateChange(date, 'contractdate', true)}}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            error={this.state.errorMessages.contractdate !== undefined}
                                            helperText={this.state.errorMessages.contractdate}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    {/* <TextField
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        id="duedate"
                                        name="duedate"
                                        label="Due Date"
                                        type="date"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.newWO.duedate}
                                        onChange={this.handleWonQuoteChange}
                                    /> */}

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            // disabled={this.state.header.work_order !== null}
                                            required
                                            id="duedate"
                                            name="duedate"
                                            label="Due Date"
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            className={classes.textField}
                                            value={this.state.newWO.duedate || null}
                                            onChange={(date) => {this.handleDateChange(date, 'duedate', true)}}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                            error={this.state.errorMessages.duedate !== undefined}
                                            helperText={this.state.errorMessages.duedate}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    {/* <TextField
                                        required
                                        id="assignto"
                                        name="assignto"
                                        label="Assigned To"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.newWO.assignto}
                                        onChange={this.handleWonQuoteChange}
                                    /> */}

                                    {this.harrisPersonnelDropdown(
                                        'assignto',
                                        'Assigned To'
                                    )}
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="purchase_order"
                                        name="purchase_order"
                                        label="Purchase Order Number"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.newWO.purchase_order}
                                        onChange={this.handleWonQuoteChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    {/* <TextField
                                        InputLabelProps={{ shrink: true }}
                                        id="po_date"
                                        name="po_date"
                                        label="Purchase order Date"
                                        type="date"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.newWO.po_date}
                                        onChange={this.handleWonQuoteChange}
                                    /> */}

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            id="po_date"
                                            name="po_date"
                                            label="Purchase Order Date"
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            className={classes.textField}
                                            value={this.state.newWO.po_date || null}
                                            onChange={(date) => {this.handleDateChange(date, 'po_date', true)}}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextField
                                        id="po_requested_by"
                                        name="po_requested_by"
                                        label="Purchase Order By"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.newWO.po_requested_by}
                                        onChange={this.handleWonQuoteChange}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.save}
                                        onClick={this.createWO}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            size="1x"
                                            color="white"
                                            className={classes.icon}
                                        />
                                        Create Work Order
                                    </Button>
                                </Grid>

                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            )
        }
    }
    //MARK: showOpp
    /*showOppInfo = () => {
        const { classes } = this.props
        if (this.state.oppData.fromOpp) {
            return (
                <Card>
                    <Grid container spacing={1} className={classes.wonIt}>
                        <Grid item xs={12} direction="column">
                            <Typography
                                component="h6"
                                variant="h6"
                                color="inherit"
                            >
                                Opportunity
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="h6" color="inherit">
                                {'Name: ' + this.state.oppData.opportunity_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="h6" color="inherit">
                                {'Stage: ' + this.state.oppData.lead_stage}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="h6" color="inherit">
                                {'Source: ' + this.state.oppData.lead_source}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="h6" color="inherit">
                                {'Booking Type: ' +
                                    this.state.oppData.booking_type}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="h6" color="inherit">
                                {'Total Value: ' +
                                    this.state.oppData.total_opportunity_value}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography component="h6" color="inherit">
                                {'Probable Value: ' +
                                    this.state.oppData
                                        .probable_opportunity_value}
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            )
        }
    }*/

    //Shows a message at top once quote has already been won and locked
    showQuoteWon = () => {
        const { classes } = this.props
        if (this.state.header.status == 'WON' && this.state.header.work_order) {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Card>
                            <Grid
                                container
                                spacing={1}
                                className={classes.wonIt}
                            >
                                <Grid item xs={3}>
                                    <Typography
                                        component="h6"
                                        //variant="h6"
                                        color="inherit"
                                    >
                                        {'Won Quote on: ' +
                                            this.state.header.quoteclosedate}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography
                                        component="h6"
                                        //variant="h6"
                                        color="inherit"
                                    >
                                        {'Work Order: ' +
                                            this.state.header.work_order}
                                    </Typography>
                                </Grid>
                                {/*<Grid item xs={2}>
                                    <Typography
                                        component="h6"
                                        //variant="h6"
                                        color="inherit"
                                    >
                                        {'Purchase Order: ' +
                                            this.state.header.purchase_order}
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography
                                        component="h6"
                                        //variant="h6"
                                        color="inherit"
                                    >
                                        {'PO Date: ' +
                                            this.state.header.po_date}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        component="h6"
                                        //variant="h6"
                                        color="inherit"
                                    >
                                        {'Requested By: ' +
                                            this.state.header.po_requested_by}
                                    </Typography>
                                </Grid>*/}
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            )
        }
    }

    //Shows popup if quote status is marked as lost
    showLostQuoteForm = () => {
        const { classes } = this.props
        if (this.state.lostQuote == true && !this.state.lostInfoAdded) {
            return (
                <Grid container spacing={1} style={{paddingBottom: 10}}>
                    <Grid item xs={12}>
                        <Card>
                            <div className={classes.secondary}>
                                <Grid container>
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        color="inherit"
                                        className={classes.textField}
                                    >
                                        Lost Quote Information
                                    </Typography>
                                </Grid>
                            </div>

                            <Grid
                                container
                                spacing={1}
                                className={classes.lostIt}
                            >
                                <Grid item xs={12} sm={2}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            id="quoteclosedate"
                                            name="quoteclosedate"
                                            label="Lost Date"
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            className={classes.textField}
                                            // defaultValue={this.getCurrentDate()}
                                            value={this.state.header.quoteclosedate ? moment(this.state.header.quoteclosedate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD') : null}
                                            onChange={(date) => {this.handleDateChange(date, 'quoteclosedate', true)}}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    {this.viewOverviewDropdown(
                                        'CAUSE_OF_LOSS',
                                        'cause_of_loss',
                                        'Cause',
                                        true
                                    )}
                                </Grid>
                                <Grid item xs={12} sm={8}>
                                    <TextField
                                        id="competition"
                                        name="competition"
                                        label="Lost To"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.header.competition}
                                        onChange={this.handleStatusQuoteChange}
                                        error={this.state.errorMessages.competition !== undefined}
                                        helperText={this.state.errorMessages.competition}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <TextField
                                        id="reason_lost"
                                        name="reason_lost"
                                        label="Reason Lost"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.header.reason_lost}
                                        onChange={this.handleStatusQuoteChange}
                                        multiline
                                        rows="2"
                                        variant="outlined"
                                        error={this.state.errorMessages.reason_lost !== undefined}
                                        helperText={this.state.errorMessages.reason_lost}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        className={classes.save}
                                        onClick={this.getLostQuote}
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlus}
                                            size="1x"
                                            color="white"
                                            className={classes.icon}
                                        />
                                        Add Lost Quote Information
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            )
        }
    }

    //shows message once quote is already lost and locked
    showQuoteLost = () => {
        const { classes } = this.props
        if (this.state.header.status == 'LOST' && this.state.lostInfoAdded) {
            return (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Card>
                            <Grid
                                container
                                spacing={1}
                                className={classes.lostIt}
                            >
                                <Grid item xs={3}>
                                    <Typography
                                        component="h6"
                                        //variant="h6"
                                        color="inherit"
                                    >
                                        {'Lost Quote on: ' + (this.state.header.quoteclosedate ? this.state.header.quoteclosedate : '')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        component="h6"
                                        //variant="h6"
                                        color="inherit"
                                    >
                                        {'Cause: ' + (this.state.header.cause_of_loss ? this.state.header.cause_of_loss : '')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography
                                        component="h6"
                                        //variant="h6"
                                        color="inherit"
                                    >
                                        {'To: ' + (this.state.header.competition ? this.state.header.competition : '')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography
                                        component="h6"
                                        //variant="h6"
                                        color="inherit"
                                    >
                                        {'Reason: ' + (this.state.header.reason_lost ? this.state.header.reason_lost : '')}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            )
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////
    //MARK: New Quote
    /////////////////////////////////////////////////////////////////////////////////////////////////

    //Creates a new quote
    createQuote = (copy = false) => {
        const serviceType = this.state.header.service_type

        const data = {
            action: 'createNewQuote',
            crm_id: this.props.formData.crmData.crm_id,
            user: JSON.parse(sessionStorage.getItem('userData')).userid,
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            //hug_schema: ,
            name: this.state.header.name,
            description: this.state.header.description,
            quoteby: JSON.parse(sessionStorage.getItem('userData')).userid,
            rep_assigned: this.state.header.rep_assigned,
            referred_by: this.state.header.referred_by,
            lead_stage: this.state.header.lead_stage,
            status: 'OPEN',
            close_prob: this.state.header.close_prob,
            quotedate: this.getCurrentDate(),
            quoteclosedate: this.state.header.quoteclosedate,
            quotesentto: this.state.header.quotesentto,
            quotedoc: this.state.header.quotedoc,
            proposal_milestone: this.state.header.alternate_terms,
            statement_of_work: this.state.header.statement_of_work,
            booking_type: this.state.header.booking_type,
            contract_payment: this.state.header.contract_payment,
            service_type: this.state.header.service_type,
            prospect_objective: this.state.header.prospect_objective,
            obstacles_to_closing: this.state.header.obstacles_to_closing,
            decision_process: this.state.header.decision_process,
            contract_process: this.state.header.contract_process,
            competitive_comment: this.state.header.competitive_comment,
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
            response => {
                if (response.result) {
                    if (
                        this.state.opportunityInfo &&
                        this.state.opportunityInfo.opportunity_name
                    ) {
                        //saves the quote number to the opportunity it came from
                        this.handleQuoteNumSave(response.newquoteno)
                    }

                    //saves the service types using the quote number
                    this.saveServiceTypes(response.newquoteno, serviceType)
                    this.props.updateComponent('Quotes')

                    this.props.setMain('Loading')
                    this.handleOpen(response.newquoteno, serviceType)
                    
                    if (copy === true) {
                        this.handleSave(null, response.newquoteno)
                    }
                }
            }
        )
    }

    //Only used for a new quote once initial info filled out in overview tab
    handleOpen = (number) =>{
        let change = this.state.header
        change.quote_number = number
        change.crm_id = this.props.formData.crmData.crm_id
        change.status = 'OPEN'
        this.setState({header: change, loading: false})
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        let milestoneData

            const data1 = {
                action: 'getQuoteCurrentMilestone',
                current_milestone_template: this.state.header.alternate_terms,
                sbu:
                    userData === null ? null : userData.strategic_business_unit,
                userid: userData === null ? null : userData.userid,
                quote_number: number,
                //hug_schema: 'hug_horizons'
            }

            PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data1).then(
                response => {
                    if (response.result && response.result !== 'false') {
                        milestoneData = response.quote_milestonesArr
                        const data2 = {
                            action: 'getQMDforQuote2',

                            quote_number: number,
                            //hug_schema: 'hug_horizons',
                            sbu:
                                userData === null
                                    ? null
                                    : userData.strategic_business_unit,
                        }

                        PostData(
                            'Quotes/ajax/ajaxhugQuoteSystem2019.php',
                            data2
                        ).then(response => {
                            this.props.setFormData(
                                {
                                    header: this.state.header,
                                    module_details: response.Q_Modules_Details,
                                    milestoneData: milestoneData,
                                    crmData: this.props.crmData,
                                    new: false
                                },
                                'Quotes'
                            )

                        })
                    }
                }
            )

    }

    //////////////////////////////////////////////////////////////////////////////////////////////////
    //Overview Screen
    //////////////////////////////////////////////////////////////////////////////////////////////////

    //Overview changes
    handleOverviewChange = event => {
        let change = this.state.header
        if (event.target == undefined) {
            change.statement_of_work = event.editor.getData()
            this.setState({ header: change })
        } else {
            if (event.target.name == 'status') {
                if (event.target.value == 'WON') {
                    this.setState({ popupWonOpen: true })
                    this.setState({ lostQuote: false })
                } else if (event.target.value == 'LOST') {
                    this.setState({ popupLostOpen: true })
                    this.setState({ wonQuote: false })
                } else {
                    this.setState({ wonQuote: false })
                    this.setState({ lostQuote: false })
                }
            } else if (event.target.name == 'lead_stage') {
                this.state.header.date_modified_lead_stage = this.getCurrentDate()
                change[event.target.name] = event.target.value
                this.setState({ header: change })
            } else {
                if (event.target.name == 'status') {
                    if (event.target.value == 'WON') {
                        this.setState({ popupWonOpen: true })
                        this.setState({ lostQuote: false })
                    } else if (event.target.value == 'LOST') {
                        this.setState({ popupLostOpen: true })
                        this.setState({ wonQuote: false })
                    } else {
                        this.setState({ wonQuote: false })
                        this.setState({ lostQuote: false })
                    }
                }
                else if(event.target.name == 'lead_stage'){
                    this.state.header.date_modified_lead_stage = this.getCurrentDate()
                    change[event.target.name] = event.target.value
                    this.setState({ header: change })
                }
                else if(event.target.name == 'alternate_terms'){
                    
                    change[event.target.name] = event.target.value
                    this.setState({ header: change })
                    this.getCurrentMilestones()
                }
                else{
                    change[event.target.name] = event.target.value
                    this.setState({ header: change })
                }
                if (event.target.name == 'service_type') {
                    this.handleServiceChange()
                }
                if (event.target.name == 'lead_stage') {
                    this.getCloseProb()
                }
            }
            if (event.target.name == 'service_type') {
                this.handleServiceChange()
            }
            if (event.target.name == 'lead_stage') {
                this.getCloseProb()
            }
        }
    }

    handleSOWChange = (event) => {
        let statement_of_work = event.editor.getData()

        let header = this.state.header
        header.statement_of_work = statement_of_work
        this.setState({ header: header })

        let data = {
            hug_schema: 'hug_' + JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase(),
            action: 'save_scope',
            quote_number: this.state.header.quote_number,
            statement_of_work: statement_of_work
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data)
    }

    //Calculates meter count
    handleServiceChange = () => {
        let total = 0
        this.state.meters.forEach(service => {
            this.state.header.service_type.forEach(selected => {
                if (
                    this.toTitleCase(service.service) == selected &&
                    service.count !== null
                ) {
                    total = total + parseFloat(service.count)
                }
            })
        })
        this.state.header.meter_count = total
    }

    //Saving service type multiselect
    saveServiceTypes = (quoteNum, serviceType = null) => {
        if (serviceType === null) {
            serviceType = this.state.header.service_type
        }   

        const delData = {
            quoteNum: quoteNum,
            table_name:
                'hug_' +
                JSON.parse(
                    sessionStorage.getItem('userData')
                ).strategic_business_unit.toLowerCase() +
                '.quote_service_types',
            action: 'delete_service_types',
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', delData).then(
            response => {
                for (let i = 0; i < serviceType.length; i++) {
                    const data = {
                        quote_number: quoteNum,
                        service_type: serviceType[i],
                        action: 'saveServiceTypes',
                        hug_schema:
                            'hug_' +
                            JSON.parse(
                                sessionStorage.getItem('userData')
                            ).strategic_business_unit.toLowerCase(),
                    }
                    PostData(
                        'Quotes/ajax/ajaxhugQuoteSystem2019.php',
                        data
                    ).then(response => {})
                }
            }
        )
    }

    //Shows the close prob based on the lead stage
    getCloseProb = () =>{

        let percentage
        if (this.state.header.lead_stage === 'PROPOSAL_LOW') {
            percentage = 25
        }
        if (this.state.header.lead_stage === 'PROPOSAL_MIDDLE') {
            percentage = 50
        }
        if (this.state.header.lead_stage === 'PROPOSAL_HIGH') {
            percentage = 75
        }
        if (this.state.header.lead_stage === 'SHORT_LISTED') {
            percentage = 75
        }
        if (this.state.header.lead_stage === 'VENDOR_OF_CHOICE') {
            percentage = 80
        }
        if (this.state.header.lead_stage === 'CONTRACT_NEGOTIATIONS') {
            percentage = 90
        }
        if (this.state.header.lead_stage === 'WON') {
            percentage = 100
        }
        
        let change = this.state.header
        change.close_prob = percentage
        this.setState({ header: change })
    }

    //Gets the service data and meter counts
    getMeterCount = () => {
        const data = {
            action: 'getUtilityServices',
            crm_id: this.props.formData.crmData.crm_id,
        }

        PostData('CRM/ajax/ajaxCRM_UtilityServices.php', data).then(
            response => {
                if (response.result && response.utility_services_states) {
                    let services = []

                    response.utility_services_states.forEach(service => {
                        services.push({
                            service: service.service_type,
                            count: service.meter_count,
                        })
                    })
                    this.setState({ meters: services })
                }
            }
        )
    }

    //Organizes service type dropdown
    getServiceTypes = () => {
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == 'SERVICE_TYPE') {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })
        return list
    }

    //Shows the utility Contact dropdown
    personnelDropdown = (id, name) => {
        let arr = []
        for (let key in this.state.utilityPersonnel) {
            arr.push({
                name: this.state.utilityPersonnel[key].name,
                id: this.state.utilityPersonnel[key].name,
            })
        }

        const { classes } = this.props
        return (
            <TextField
                select
                disabled={this.state.locked}
                fullWidth
                id={id}
                name={id}
                label={name}
                className={classes.textField}
                onChange={this.handleOverviewChange}
                value={this.state.header.quotesentto}
            >
                {arr.map(person => (
                    <MenuItem key={person.id} value={person.id}>
                        {person.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    harrisPersonnelDropdown = (id, name, isWO = true) => {
        let arr = []

        if (!isWO) {
            arr.push({
                name: 'None',
                id: '', 
            })
        }

        for (let key in this.state.harrisPersonnel) {
            arr.push({
                name: this.state.harrisPersonnel[key].name,
                id: this.state.harrisPersonnel[key].name,
            })
        }

        const { classes } = this.props
        return (
            <TextField
                select
                // disabled={this.state.header.work_order !== null}
                disabled={isWO ? false : this.state.locked}
                fullWidth
                id={id}
                name={id}
                label={name}
                className={classes.textField}
                onChange={isWO ? this.handleWonQuoteChange : this.handleOverviewChange}
                value={isWO ? this.state.newWO[id] : this.state.header[id]}
                error={this.state.errorMessages[id] !== undefined}
                helperText={this.state.errorMessages[id]}
            >
                {arr.map(person => (
                    <MenuItem key={person.id} value={person.id}>
                        {person.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    //Saves Overview changes
    /*handleOverviewSave = () => {
        let count = 0
        let length = Object.keys(this.state.header).length

        for (let key in this.state.header) {
            let value = this.state.header[key]

            const data = {
                action: 'update_Values',
                quote_number: this.state.header.quote_number,
                column_name: key,
                column_value: value,
                //hug_schema: 'hug_horizons',
                sbu: JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,
                colun_sql_type: 'text',
                table_name: 'quotes_header',
            }

            PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
                response => {
                    count++

                    if (count >= length) {
                        this.props.updateComponent('Quotes')
                    }
                }
            )
        }
    }*/

    winQuote = () => {
        let header = this.state.header
        header.status = 'WON'
        header.actualclosedate = this.getCurrentDate()
    
        this.setState({
            wonQuote: true,
            popupWonOpen: false,
            header: header
        }, () => {
            this.handleOverviewSave2(this.state.header.quote_number)
        })
    }

    loseQuote = () => {
        let header = this.state.header
        header.status = 'LOST'
    
        this.setState({
            lostQuote: true,
            popupLostOpen: false,
            header: header
        }, () => {
            this.handleOverviewSave2(this.state.header.quote_number)
        })
    }

    //New single server call for saving quotes_header
    handleOverviewSave2 = (quote_number) => {
        const data = {
            action: 'update_quote_header_values2',
            quote_number: quote_number,
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            hug_schema: 'hug_' +JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase(),
            
            actual_travel_expenses: this.state.header.actual_travel_expenses || '',
            actualclosedate: this.state.header.actualclosedate,
            alternate_terms: this.state.header.alternate_terms,
            booking_type: this.state.header.booking_type,
            campaign_id: this.state.header.campaign_id,
            cis_vendor: this.state.header.cis_vendor,
            
            close_prob: this.state.header.close_prob,
            competition: this.state.header.competition,
            competitive_comment: this.state.header.competitive_comment,
            contract_payment: this.state.header.contract_payment,
            contract_process: this.state.header.contract_process,
            contract_type: this.state.header.contract_type,
            
            custom_parameters: this.state.header.custom_parameters,
            decision_process: this.state.header.decision_process,
            description: this.state.header.description,
            email_sent_date: this.state.header.email_sent_date,
            inbound_integration: this.state.header.inbound_integration,
            ivr_upgrade_quote: this.state.header.ivr_upgrade_quote,
            lastfollowupdate: this.state.header.lastfollowupdate,
            lastfollowuptype: this.state.header.lastfollowuptype,
            lead_stage: this.state.header.lead_stage,
            license_down_payment: this.state.header.license_down_payment,
            license_fee_interest_rate: this.state.header.license_fee_interest_rate,
            license_fee_years: this.state.header.license_fee_years,
            meter_count: this.state.header.meter_count,
            monthly_down_payment_pct: this.state.header.monthly_down_payment_pct,
            monthly_license_fee: this.state.header.monthly_license_fee,
            name: this.state.header.name,
            nextfollowupdate: this.state.header.nextfollowupdate,
            obstacles_to_closing: this.state.header.obstacles_to_closing,
            outbound_integration: this.state.header.outbound_integration,
            pbxports: this.state.header.pbxports,
            phonesystem: this.state.header.phonesystem,
            po_date: this.state.header.po_date,
            po_requested_by: this.state.header.po_requested_by,
            prospect_objective: this.state.header.prospect_objective,
            purchase_order: this.state.header.purchase_order,
            
            quoteamount: this.state.header.quoteamount,
            quoteby: this.state.header.quoteby,
            quoteclosedate: moment(this.state.header.quoteclosedate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            quotedate: this.state.header.quotedate,
            quotedoc: this.state.header.quotedoc,
            quotes_labor_rate: this.state.header.quotes_labor_rate,
            quotesentto: this.state.header.quotesentto,
            reason_lost: this.state.header.reason_lost,
            rep_assigned: this.state.header.rep_assigned,
            referred_by: this.state.header.referred_by,
            saas_annual_maintenance_pct: this.state.header.saas_annual_maintenance_pct,
            saas_annual_maintenance_pct_increase: this.state.header.saas_annual_maintenance_pct_increase,
            saas_interest_rate: this.state.header.saas_interest_rate,
            saas_monthly_payments: this.state.header.saas_monthly_payments,
            saas_monthly_payments_interest: this.state.header.saas_monthly_payments_interest,
            saas_monthly_payments_ps_support: this.state.header.saas_monthly_payments_ps_support,
            saas_monthly_payments_saas: this.state.header.saas_monthly_payments_saas,
            saas_pricing_teir: this.state.header.saas_pricing_teir,
            saas_term_years: this.state.header.saas_term_years,
            saas_total_interest: this.state.header.saas_total_interest,
            saas_total_payments: this.state.header.saas_total_payments,
            saas_total_ps_support: this.state.header.saas_total_ps_support,
            saas_total_saas: this.state.header.saas_total_saas,
            
            service_type: this.state.header.service_type,
            statement_of_work: this.state.header.statement_of_work,
            status: this.state.header.status,
            training_type: this.state.header.training_type,
            work_order: this.state.header.work_order,

            cause_of_loss: this.state.header.cause_of_loss
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
            response => {
               this.props.updateComponent('Quotes')
                
            }
        )
        
    }

    //Shows overview dropdowns
    viewOverviewDropdown = (control, id, name, required) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })

        if (required === true) {
            return (
                <TextField
                    select
                    disabled={id !== 'cause_of_loss' && (this.state.locked || id === 'status' || (control === 'MILESTONE' && !this.props.formData.new))}
                    required
                    fullWidth
                    id={id}
                    name={id}
                    label={name}
                    className={classes.textField}
                    onChange={this.handleOverviewChange}
                    value={this.state.header[id]}
                    error={this.state.errorMessages[id] !== undefined}
                    helperText={this.state.errorMessages[id]}
                >
                    {list.map(item => (
                        <MenuItem key={item.name} value={item.code}>
                            {item.name}
                        </MenuItem>
                    ))}
                </TextField>
            )
        } else {
            return (
                <TextField
                    select
                    disabled={this.state.locked}
                    fullWidth
                    id={id}
                    name={id}
                    label={name}
                    className={classes.textField}
                    onChange={this.handleOverviewChange}
                    value={this.state.header[id]}
                >
                    {list.map(item => (
                        <MenuItem key={item.name} value={item.code}>
                            {item.name}
                        </MenuItem>
                    ))}
                </TextField>
            )
        }
    }

    //Shows the Overview tab/////////////////////////
    showOverview = () => {
        const { classes } = this.props
        let service_types = this.getServiceTypes()
        // console.log('quote close date: ' + this.state.header.quoteclosedate)
        return (
            <Grid container spacing={1} className={classes.body}>
                {!this.state.loading && 
                    <Grid item xs={12}>
                        <Grid
                            container
                            spacing={1}
                            justify="space-evenly"
                            alignItems="flex-end"
                        >
                            <Grid item xs={9}>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="inherit"
                                >
                                    General
                                </Typography>
                            </Grid>

                            <Grid item xs={3}>
                                {/* <Button
                                    // disabled
                                    color="white"
                                    variant="contained"
                                    onClick={() =>
                                        this.setState({ duplicate: true })
                                    }
                                    style={{
                                        marginTop: 4,
                                        marginBottom: 4,
                                        marginRight: 5
                                    }}
                                    className={classes.secondary}
                                >
                                    <FontAwesomeIcon
                                        icon={faCopy}
                                        size="1x"
                                        color="white"
                                        style={{
                                            marginRight: 5,
                                        }}
                                    />
                                    Copy
                                </Button> */}
    
                                {!this.state.newQuote && this.state.header.status !== 'WON' && this.state.header.status !== 'LOST' && (
                                    <React.Fragment>
                                        {/* <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() =>
                                                this.setState({ addPO: true })
                                            }
                                            //className={classes.buttons}
                                            style={{
                                                marginLeft: 5,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faLayerPlus}
                                                size="1x"
                                                color="white"
                                                style={{
                                                    marginRight: 5,
                                                }}
                                            />
                                            Add PO
                                        </Button> */}

                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() =>
                                                this.setState({ popupWonOpen: true, lostQuote: false })
                                            }
                                            //className={classes.buttons}
                                            style={{
                                                marginLeft: 5,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faAward}
                                                size="1x"
                                                color="white"
                                                style={{
                                                    marginRight: 5,
                                                }}
                                            />
                                            Won It
                                        </Button>

                                        <Button
                                            color="#f44336"
                                            variant="contained"
                                            onClick={() => 
                                                this.setState({ popupLostOpen: true, wonQuote: false })
                                            }
                                            className={classes.errorButton}
                                            style={{
                                                marginLeft: 5,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faBan}
                                                size="1x"
                                                color="white"
                                                style={{
                                                    marginRight: 5,
                                                }}
                                            />
                                            Lost It
                                        </Button>
                                    </React.Fragment>
                                )}
                            </Grid>

                        {/* Line 1 */}
                        <Grid item xs={12} sm={4} md={4}>
                            <TextField
                                required
                                disabled={this.state.locked}
                                id="name"
                                name="name"
                                label="Quote Name"
                                fullWidth
                                className={classes.textField}
                                value={this.state.header.name}
                                onChange={this.handleOverviewChange}
                                error={this.state.errorMessages.name !== undefined}
                                helperText={this.state.errorMessages.name}
                            />
                        </Grid>
                        {/* {!this.state.newQuote && ( */}
                            <Grid item xs={12} sm={3} md={2}>
                                {this.viewOverviewDropdown(
                                    'QUOTE_STATUS',
                                    'status',
                                    'Quote Status',
                                    true
                                )}
                            </Grid>
                        {/* )} */}
                        <Grid item xs={12} sm={3} md={2}>
                            {this.viewOverviewDropdown(
                                'BOOK_TYPE',
                                'booking_type',
                                'Booking Type',
                                true
                            )}
                        </Grid>
                        <Grid item xs={12} md={2}>
                            {this.viewOverviewDropdown(
                                'CONTRACT_PAYMENT',
                                'contract_payment',
                                'Payment Type',
                                true
                            )}
                        </Grid>
                        <Grid item xs={12} md={2}>
                            {this.viewOverviewDropdown(
                                'MILESTONE',
                                'alternate_terms',
                                'Milestone Template',
                                true
                            )}
                        </Grid>

                        {/* Line 2 */}
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                disabled={this.state.locked}
                                rows="2"
                                variant="outlined"
                                id="description"
                                name="description"
                                label="Title"
                                fullWidth
                                className={classes.textField}
                                value={this.state.header.description || ''}
                                onChange={this.handleOverviewChange}
                            />
                        </Grid>

                        {/* Line 3 */}
                        <Grid item xs={12} md={3}>
                            <TextField
                                required
                                disabled={this.state.locked}
                                id="rep_assigned"
                                name="rep_assigned"
                                label="Rep Assigned"
                                fullWidth
                                className={classes.textField}
                                defaultValue={
                                    JSON.parse(sessionStorage.getItem('userData'))
                                        .name
                                }
                                value={this.state.header.rep_assigned}
                                onChange={this.handleOverviewChange}
                                error={
                                    this.state.errorMessages.rep_assigned !==
                                    undefined
                                }
                                helperText={this.state.errorMessages.rep_assigned}
                            />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            {this.harrisPersonnelDropdown(
                                'referred_by',
                                'Referred By',
                                false
                            )}
                        </Grid>  
                        <Grid item xs={12} md={3}>
                            {this.personnelDropdown(
                                'quotesentto',
                                'Customer Contact'
                            )}
                        </Grid>    
                        <Grid item xs={12} md={2}>
                            <TextField
                                disabled
                                required
                                id="quoteby"
                                name="quoteby"
                                label="Created By"
                                fullWidth
                                className={classes.textField}
                                defaultValue={this.state.header.quoteby}
                                onChange={this.handleOverviewChange}
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <TextField
                                disabled
                                required
                                id="quotedate"
                                name="quotedate"
                                InputLabelProps={{ shrink: true }}
                                label="Date Created"
                                fullWidth
                                className={classes.textField}
                                value={
                                    this.state.header.quotedate ||
                                    this.getCurrentDate()
                                }
                                onChange={this.handleOverviewChange}
                            />
                        </Grid>

                        {/* Line 3 */}
                        <Grid item xs={12} md={3}>
                            {this.viewOverviewDropdown(
                                'LEAD_STAGE',
                                'lead_stage',
                                'Lead Stage',
                                true
                            )}
                        </Grid>

                        <Grid item xs={12} md={1}>
                            <NumberFormat
                                disabled
                                id="close_prob"
                                name="close_prob"
                                label="Close %"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.header.close_prob}
                                suffix={'%'}
                                defaultValue={0}
                            />
                        </Grid>

                        <Grid item xs={12} md={2}>
                            {/* <TextField
                                required
                                disabled={this.state.locked}
                                id="quoteclosedate"
                                name="quoteclosedate"
                                label="Close Date"
                                InputLabelProps={{ shrink: true }}
                                type="date"
                                fullWidth
                                className={classes.textField}
                                value={this.state.header.quoteclosedate || ''}
                                onChange={this.handleOverviewChange}
                                error={
                                    this.state.errorMessages.quoteclosedate !==
                                    undefined
                                }
                                helperText={this.state.errorMessages.quoteclosedate}
                            /> */}

                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    required
                                    disabled={this.state.locked}
                                    id="quoteclosedate"
                                    name="quoteclosedate"
                                    label="Projected Close Date"
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    className={classes.textField}
                                    // defaultValue={this.getCurrentDate()}
                                    value={this.state.header.quoteclosedate ? moment(this.state.header.quoteclosedate, 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD') : null}
                                    onChange={(date) => {this.handleDateChange(date, 'quoteclosedate', false)}}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                    error={this.state.errorMessages.quoteclosedate !== undefined}
                                    helperText={this.state.errorMessages.quoteclosedate}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        {/* <Grid item xs={12} md={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    disabled={this.state.locked}
                                    id="actualclosedate"
                                    name="actualclosedate"
                                    label="Actual Close Date"
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    className={classes.textField}
                                    // defaultValue={this.getCurrentDate()}
                                    value={this.state.header.actualclosedate || null}
                                    onChange={(date) => {this.handleDateChange(date, 'actualclosedate', false)}}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid> */}


                        <Grid item xs={12} md={3}>
                            <FormControl
                                className={classes.textField}
                                style={{ width: '100%' }}
                            >
                                <InputLabel error={false}>
                                    Service Type
                                </InputLabel>
                                <Select
                                    name="service_type"
                                    multiple
                                    fullWidth
                                    disabled={this.state.locked}
                                    value={this.state.header.service_type}
                                    onChange={this.handleOverviewChange}
                                    renderValue={selected => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            {selected &&
                                                selected.map(value => (
                                                    <Chip
                                                        key="service_type"
                                                        label={value}
                                                        style={{ margin: 2 }}
                                                    />
                                                ))}
                                        </div>
                                    )}
                                >
                                    {service_types.map(item => (
                                        <MenuItem key={item.name} value={item.name}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <NumberFormat
                                disabled
                                id="meter_count"
                                name="meter_count"
                                label="Meter Count"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.header.meter_count}
                                thousandSeparator={true}
                                defaultValue={0}
                            />
                        </Grid>
                        <Grid item xs={12} md={2}>
                            {this.viewOverviewDropdown(
                                'CAMPAIGN',
                                'campaign_id',
                                'Campaign',
                                false,
                            )}
                        </Grid>

                        {/* Line 4 */}
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={1}>
                                    <Typography className={classes.modifiedText}>
                                        {this.state.header.date_modified_lead_stage
                                            ? 'Stage Modified: ' +
                                            this.state.header.date_modified_lead_stage
                                            : ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Grid item xs={4}>
                            <TextField
                                disabled={this.state.locked}
                                id="po_requested_by"
                                name="po_requested_by"
                                label="Purchase Order Requester"
                                fullWidth
                                className={classes.textField}
                                value={
                                    this.state.header.po_requested_by
                                }
                                onChange={this.handleOverviewChange}
                            />
                        </Grid> */}
                        {/* <Grid item xs={4}> */}
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    disabled={this.state.locked}
                                    id="po_date"
                                    name="po_date"
                                    label="Purchase Order Date"
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    className={classes.textField}
                                    value={this.state.header.po_date || null}
                                    onChange={(date) => {this.handleDateChange(date, 'po_date')}}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </MuiPickersUtilsProvider> */}

                            {/* <TextField
                                required
                                InputLabelProps={{ shrink: true }}
                                id="po_date"
                                name="po_date"
                                label="PO Date"
                                type="date"
                                fullWidth
                                className={classes.textField}
                                value={this.state.POdata.po_date}
                                onChange={this.handleAddPOChange}
                            /> */}
                        {/* </Grid>           */}

                        {/* Line 5 */}

                        <Grid item xs={2}>
                            <Typography component="h1" variant="h6" color="inherit">
                                Purchase Order
                            </Typography>
                        </Grid>

                        <Grid item xs={1} className={classes.more}>
                            <Button onClick={() => {this.setState({ showPurchaseOrder: !this.state.showPurchaseOrder })}}>
                                {this.state.showPurchaseOrder ? (
                                    <FontAwesomeIcon icon={faChevronUp} />
                                ) : (
                                    <FontAwesomeIcon icon={faChevronDown} />
                                )}
                            </Button>
                        </Grid>    

                        <Grid item xs={9}></Grid>

                        {this.state.showPurchaseOrder && (
                            <React.Fragment>
                                <Grid item xs={4}>
                                    <TextField
                                        // disabled={this.state.locked}
                                        id="purchase_order"
                                        name="purchase_order"
                                        label="Purchase Order #"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.header.purchase_order}
                                        onChange={this.handleOverviewChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        // disabled={this.state.locked}
                                        id="po_requested_by"
                                        name="po_requested_by"
                                        label="Purchase Order Requester"
                                        fullWidth
                                        className={classes.textField}
                                        value={
                                            this.state.header.po_requested_by
                                        }
                                        onChange={this.handleOverviewChange}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            // disabled={this.state.locked}
                                            id="po_date"
                                            name="po_date"
                                            label="Purchase Order Date"
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            className={classes.textField}
                                            value={this.state.header.po_date || null}
                                            onChange={(date) => {this.handleDateChange(date, 'po_date')}}
                                            fullWidth
                                            InputLabelProps={{ shrink: true }}
                                        />
                                    </MuiPickersUtilsProvider>

                                    {/* <TextField
                                        required
                                        InputLabelProps={{ shrink: true }}
                                        id="po_date"
                                        name="po_date"
                                        label="PO Date"
                                        type="date"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.POdata.po_date}
                                        onChange={this.handleAddPOChange}
                                    /> */}
                                </Grid>    
                            </React.Fragment>
                        )}
             

                        <Grid item xs={2}>
                            <Typography component="h1" variant="h6" color="inherit">
                                Profile
                            </Typography>
                        </Grid>

                        <Grid item xs={1} className={classes.more}>
                            <Button onClick={() => {this.setState({ showProfile: !this.state.showProfile })}}>
                                {this.state.showProfile ? (
                                    <FontAwesomeIcon icon={faChevronUp} />
                                ) : (
                                    <FontAwesomeIcon icon={faChevronDown} />
                                )}
                            </Button>
                        </Grid>  

                        <Grid item xs={9}></Grid>                 
                        
                        {this.state.showProfile && (
                            <React.Fragment>
                                <Grid item xs={12}>
                                    <TextField
                                        multiline
                                        disabled={this.state.locked}
                                        rows="2"
                                        variant="outlined"
                                        id="prospect_objective"
                                        name="prospect_objective"
                                        label="Objectives"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.header.prospect_objective}
                                        onChange={this.handleOverviewChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        multiline
                                        disabled={this.state.locked}
                                        rows="2"
                                        variant="outlined"
                                        id="obstacles_to_closing"
                                        name="obstacles_to_closing"
                                        label="Obstacles To Closing"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.header.obstacles_to_closing}
                                        onChange={this.handleOverviewChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        multiline
                                        disabled={this.state.locked}
                                        rows="2"
                                        variant="outlined"
                                        id="decision_process"
                                        name="decision_process"
                                        label="Decision Process"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.header.decision_process}
                                        onChange={this.handleOverviewChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        multiline
                                        disabled={this.state.locked}
                                        rows="2"
                                        variant="outlined"
                                        id="contract_process"
                                        name="contract_process"
                                        label="Contract Process"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.header.contract_process}
                                        onChange={this.handleOverviewChange}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        multiline
                                        disabled={this.state.locked}
                                        rows="2"
                                        variant="outlined"
                                        id="competitive_comment"
                                        name="competitive_comment"
                                        label="Competitive Comment"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.header.competitive_comment}
                                        onChange={this.handleOverviewChange}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}


                        {/* <Grid item xs={12} sm={4} md ={2} className={classes.fileBox}>
                            <DropzoneArea 
                                acceptedFiles={['.txt', '.pdf']}
                                className={classes.fileBox}
                                onChange={this.handleFileChange}
                                value={this.state.header.quotedoc}
                                dropzoneText='Attach Files'
                                height='10px'
                            />
                        </Grid> */}

                        {/* <Grid item xs={12} sm={4} md={2}>
                            <FileUploader />
                        </Grid> */}
                        </Grid>
                    </Grid>
                }
            </Grid>
        )
    }

    //Not currently used
    /*handleFileChange = files => {
        let change = this.state.header
        change.quotedoc = files
        this.setState({ header: change })
        
    }*/

    //Shows scope tab
    showWorkStatement = () => {
        return (
            <Grid container spacing={1}>
                <Grid item xs={10}>
                    <Typography component="h1" variant="h6" color="inherit">
                        Scope of Work
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <CKEditor
                        data={this.state.header.statement_of_work || ''}
                        config={{
                            //oolbar: [[]],
                            removePlugins: ['elementspath'],
                            height: 500,
                            readOnly: this.state.locked ? true : false,
                        }}
                        onChange={this.handleSOWChange}
                    />
                </Grid>
            </Grid>
        )
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////////////////////////////
    //Product Screen
    /////////////////////////////////////////////////////////////////////////////////////////////////

    //product details change
    handleProductDetailsChange = event => {
        let index = this.state.product_details.findIndex(
            element =>
                element.product_line == this.state.selectedProductLine &&
                element.product_module == this.state.selectedProductModule
        )
        let change = this.state.product_details
        change[index][event.target.name] = event.target.value
        this.setState({ product_details: change })
    }

    //Calculates total quote amount
    calculateQuoteAmount = () => {
        let total = 0
        this.state.product_details.forEach(product => {
            if (parseFloat(product.qty) > 0) {
                total = total + (parseFloat(product.qty) * parseFloat(product.price_each) - parseFloat(product.discount) 
                    + parseFloat(product.labor_hours) * parseFloat(product.labor_rate));
            }
        })
        let change = this.state.header
        change.quoteamount = total
        console.log(total)
        this.setState({ header: change })
    }

    //product details number change and calculations
    handleNumProductDetailsChange = event => {
        let value = event.target.value.replace(/[,$]/g, '')

        let index = this.state.product_details.findIndex(
            element =>
                element.product_line == this.state.selectedProductLine &&
                element.product_module == this.state.selectedProductModule
        )

        let change = this.state.product_details
        let selected = change[index]
        selected[event.target.name] = value

        if (isNaN(parseFloat(value))) {
            return
        }

        // Convert the strings to floats for the database
        selected.qty = parseFloat(selected.qty)
        selected.price_each = parseFloat(selected.price_each)
        selected.discount = parseFloat(selected.discount)
        selected.labor_hours = parseFloat(selected.labor_hours)
        selected.labor_rate = parseFloat(selected.labor_rate)

        selected.software_fees = selected.qty * selected.price_each - selected.discount
        selected.professional_services = selected.labor_rate * selected.labor_hours * selected.qty
        selected.net_billable_amount = parseFloat(selected.software_fees) + parseFloat(selected.professional_services)

        // Automatically update the maintenance cost, only if the maintenance cost hasn't been adjusted manually
        if (event.target.name !== 'maint_cost') {
            selected.maint_cost =  Number(selected.price_each) *  Number(selected.qty) * Number(selected.software_technical_support_pct)	
        } else {
            selected.maint_cost = parseFloat(selected.maint_cost)
        }
        
        this.setState({ product_details: change })
        this.calculateQuoteAmount(selected)
    }

    handleOptionalSwtich = () => {
        let index = this.state.product_details.findIndex(
            element =>
                element.product_line == this.state.selectedProductLine &&
                element.product_module == this.state.selectedProductModule
        )

        let change = this.state.product_details
        let selected = change[index]

        selected['addon_option'] = (selected['addon_option'] === 'true' ? 'false' : 'true')
        this.setState({ product_details: change })
    }

    //Saves products
    handleProductDetailsSave = (quote_number) => {
        if (this.state.product_details) {
            let original_product_details = this.state.original_product_details
                            
            for (let i = 0; i < this.state.product_details.length; i++) {
                let product = this.state.product_details[i]
                let keys = Object.keys(product)
                let flag = false

                keys.map(key => {
                    if (product[key] != original_product_details[i][key]) {
                        flag = true
                    }
                })

                if (flag) {
                    const data = {
                        action: 'updateQuoteDetails',
                        sbu: JSON.parse(sessionStorage.getItem('userData'))
                            .strategic_business_unit,
                        product_module: product.product_module,
                        product_line: product.product_line,
                        quote_number: quote_number,
                        qty: product.qty,
                        price_each: product.price_each,
                        price_extended: product.qty * product.price_each,
                        discount: product.discount,
                        software_fees: product.software_fees,
                        labor_hours: product.labor_hours,
                        labor_rate: product.labor_rate,
                        professional_services: product.professional_services,
                        net_billable_amount: product.net_billable_amount,
                        maint_cost: product.maint_cost,
                        lic_fee_period: product.lic_fee_period,
                        lic_fee_amount: product.lic_fee_amount,
                        user_fees_period: product.user_fee_period,
                        user_fees_amount: product.user_fee_amount,
                        addon_option: product.addon_option ? product.addon_option : 'false',
                        custom_description: product.custom_description,
                        transactional_price: Number(product.transactional_price),
                    }
    
                    PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
                        response => {
                            if (response.result) {
                                this.getQuoteRecap(true)
                            }
                        }
                    ) 
                }
            }
        }
    }

    //Navigates between the products on screen
    handleProductLineChange = product => {
        this.setState({ selectedProductLine: product })
        this.setState({ selectedProductModule: '', selectedProductModuleName: '' })
    }

    //Navigates between product modules on screen
    handleProductModuleChange = (module, name) => {
        window.scroll({
            top: 0, 
            left: 0, 
            behavior: 'smooth'
        });
        
        this.setState({ selectedProductModule: module, selecteProductModuleName: name })
    }

    //Gets pricing for each product
    getProductPricing = () =>{
        const data = {
            action: 'getProductPricing',
            hug_schema:
                'hug_' +
                JSON.parse(
                    sessionStorage.getItem('userData')
                ).strategic_business_unit.toLowerCase(),
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
            response => {
                if (response.result) {
                    this.setState({
                        productPricing: response.ProductPricingArr,
                    })
                }
            }
        )
    }

    //Server call for recap table data
    getQuoteRecap = (isProductSaving = false) => {
        const data = {
            action: 'getQuoteRecapSection',
            current_milestone_template: '',
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            quote_number: this.props.formData.header.quote_number,
            //hug_schema: 'hug_horizons'
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ recap: response.quoteRecapArr }, () => {
                        if (isProductSaving) {
                            this.recalculateMilestones()
                        }
                    })
                }
            }
        )
    }

    getOptionalProductLines = () => {
        const data = {
            action: 'getOptionalProductLines',
            current_milestone_template: '',
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            quote_number: this.props.formData.header.quote_number,
            //hug_schema: 'hug_horizons'
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ optionalLines: response.optionalLines })
                }
            }
        )
    }

    recalculateMilestones = () => {
        if (this.state.recap !== undefined && this.state.recap !== null) {
            let totals = {
                total_list_price: {amount: 0},
                discount: {amount: 0},
                net_software_price: {amount: 0},
                total_mhrs: {amount: 0},
                professional_fees: {amount: 0},
                net_invoice_amount: {amount: 0},
                maint_cost: {amount: 0}
            }

            Object.keys(this.state.recap).map(product => {
                if (product.addon_option !== 'true') {
                    let data_rollup = this.state.recap[product]
    
                    totals.total_list_price.amount += Number(data_rollup.total_list_price)
                    totals.discount.amount += Number(data_rollup.discount)
                    totals.net_software_price.amount += Number(data_rollup.net_software_price)
                    totals.total_mhrs.amount += Number(data_rollup.total_mhrs)
                    totals.professional_fees.amount += Number(data_rollup.professional_fees)
                    totals.net_invoice_amount.amount += Number(data_rollup.net_invoice_amount)
                    totals.maint_cost.amount  += Number(data_rollup.maint_cost)	
                }
            })
    
            let data = {
                action:"recalc_quote_milestones" ,
                current_milestone_template: this.state.header.alternate_terms,
                total_quote_license_fees: totals.net_software_price,
                total_quote_hours: totals.total_mhrs,
                total_quote_professional_services: totals.professional_fees,
                quote_totals_array: totals,
                sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,	
                quote_number: this.state.header.quote_number, 
                hug_schema: 'hug_' + JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase(), 
            }

            // console.log(data)
    
            PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(response => {
                // console.log(response)
                if (response.result) {
                    this.getCurrentMilestones()                
                }
            })
        }
    }

    getProductModules = () => {
        let modules = []
        if (this.state.product_details) {
            this.state.product_details.forEach(product => {
                if (product.product_line == this.state.selectedProductLine) {
                    modules.push(product)
                }
            })
        }
        return modules.map(product => this.viewProductModules(product), this)
    }

    //Shows table of product modules
    viewProductModules = product => {
        const { classes } = this.props
        if (this.state.selectedProductLine == product.product_line) {
            return (
                <TableRow
                    onClick={() =>
                        this.handleProductModuleChange(product.product_module, product.product_desc)
                    }
                >
                    <TableCell className={classes.highlight}>
                        {product.product_desc}
                    </TableCell>
                    <TableCell align="right">{product.qty}</TableCell>
                    <TableCell align="right">
                        <NumberFormat
                            value={product.net_billable_amount}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </TableCell>
                </TableRow>
            )
        }
    }

    //Organizes product lines
    getProductLines = () => {
        let lines = []
        if (this.state.product_details) {
            this.state.product_details.forEach(product => {
                if (!lines.includes(product.product_line)) {
                    lines.push(product.product_line)
                }
            })
        }
        return lines.map(product => this.viewProductLines(product), this)
    }

    //Shows list of product lines
    viewProductLines = product => {
        const { classes } = this.props
        if (this.state.selectedProductLine == product) {
            return (
                <ListItem
                    className={classes.selected}
                    onClick={() => this.handleProductLineChange(product)}
                >
                    {product}
                </ListItem>
            )
        } else {
            return (
                <ListItem
                    className={classes.lines}
                    onClick={() => this.handleProductLineChange(product)}
                >
                    {product}
                </ListItem>
            )
        }
    }

    //Shows the product tab////////////////////////
    showProducts = () => {
        const { classes } = this.props

        return (
            <React.Fragment>
                <Grid container spacing={0} className={classes.body}>
                    <Grid item xs={3}>
                        <Typography component="h1" variant="h6" color="inherit">
                            Product
                        </Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Typography component="h1" variant="h6" color="inherit">
                            {' Module'}
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography component="h1" variant="h6" color="inherit">
                            {this.state.selecteProductModuleName}
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={3}
                        className={classes.list}
                        style={{ maxHeight: '500px', overflow: 'auto' }}
                    >
                        <List disablePadding={true}>
                            {this.getProductLines()}
                        </List>
                    </Grid>
                    <Grid item xs={5}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Module</TableCell>
                                    <TableCell align="right">
                                        Quantity
                                    </TableCell>
                                    <TableCell align="right">
                                        Net Invoice
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.getProductModules()}</TableBody>
                        </Table>
                    </Grid>

                    <Grid item xs={4} className={classes.paper}>
                        {this.mainScreen()}
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    //displays the selected product module data, and is editable
    mainScreen = () => {
        const { classes } = this.props
        if (this.state.selectedProductModule != '') {
            let selected
            this.state.product_details.forEach(product => {
                if (product.product_module == this.state.selectedProductModule && product.product_line == this.state.selectedProductLine) {
                    selected = product
                }
            })

            // let selectedPricing = {}
            // for (let key in this.state.productPricing) {
            //     let value = this.state.productPricing[key]
            //     if (value.product_module == selected.product_module) {
            //         selectedPricing = value
            //     }
            // }

            return (
                <div style={{    
                    postion: 'sticky',
                    top: 30
                }}>
                <Card>
                    <Grid container spacing={1} className={classes.body}>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={
                                    <Switch checked={selected.addon_option === 'true'} onChange={this.handleOptionalSwtich}  />
                                }
                                label="Optional"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                disabled={this.state.locked}
                                id="qty"
                                name="qty"
                                label="Quantity"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={selected.qty}
                                onChange={this.handleNumProductDetailsChange}
                                thousandSeparator={true}
                                decimalScale={0}
                                defaultValue={0}
                                allowEmptyFormatting={true}
                                //isNumericString={true}
                                fixedDecimalScale={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                disabled
                                id="price_each"
                                name="price_each"
                                label="List Price Each"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={selected.price_each || 0.00}
                                //onChange={this.handleNumProductDetailsChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                disabled={this.state.locked || selected.qty === 0 || selected.qty === '0'}
                                id="discount"
                                name="discount"
                                label="Discount"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={selected.discount}
                                onChange={this.handleNumProductDetailsChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                disabled
                                id="software_fees"
                                name="software_fees"
                                label="Software Fees"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={selected.software_fees}
                                //onChange={this.handleNumProductDetailsChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                disabled={this.state.locked || selected.qty === 0 || selected.qty === '0'}
                                id="labor_rate"
                                name="labor_rate"
                                label="Labor Rate"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={selected.labor_rate}
                                onChange={this.handleNumProductDetailsChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                // disabled={this.state.locked || selected.qty === 0 || selected.qty === '0'}      
                                disabled                          
                                id="labor_hours"
                                name="labor_hours"
                                label="Labor Hours"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={selected.labor_hours}
                                onChange={this.handleNumProductDetailsChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                defaultValue={0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                disabled
                                id="professional_services"
                                name="professional_services"
                                label="Professional Services"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={selected.professional_services}
                                //onChange={this.handleNumProductDetailsChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                disabled
                                id="net_billable_amount"
                                name="net_billable_amount"
                                label="Net Billable Amount"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={selected.net_billable_amount}
                                //onChange={this.handleNumProductDetailsChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                            >
                                {'Maintenance'}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                disabled={this.state.locked || selected.qty === 0 || selected.qty === '0'}
                                id="maint_cost"
                                name="maint_cost"
                                label="Maintenance Cost"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={selected.maint_cost || 0.00}
                                onChange={this.handleNumProductDetailsChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled
                                required
                                id="lic_fee_period"
                                name="lic_fee_period"
                                label="License Fee Period"
                                fullWidth
                                className={classes.textField}
                                value={selected.lic_fee_period}
                                onChange={this.handleProductDetailsChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                disabled
                                required
                                id="user_fee_period"
                                name="user_fee_period"
                                label="User Fee Period"
                                fullWidth
                                className={classes.textField}
                                value={selected.user_fee_period}
                                onChange={this.handleProductDetailsChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                disabled
                                id="user_fee_amount"
                                name="user_fee_amount"
                                label="User Fee Amount"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                //defaultValue={selected.user_fee_amount}
                                value={selected.user_fee_amount}
                                // onChange={this.handleNumProductDetailsChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                allowEmptyFormatting={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                disabled={this.state.locked || selected.qty === 0 || selected.qty === '0'}
                                id="transactional_price"
                                name="transactional_price"
                                label="Transactional Price"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={selected.transactional_price || 0.00}
                                onChange={this.handleNumProductDetailsChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                multiline
                                // rows="2"
                                variant="outlined"
                                id="custom_description"
                                name="custom_description"
                                label="Description"
                                fullWidth
                                className={classes.textField}
                                value={selected.custom_description || ''}
                                onChange={this.handleProductDetailsChange}
                            />
                        </Grid>
                    </Grid>
                </Card>
                </div>
            )
        }
    }

    ////////////////////////////////////////////////////////////////////////////////////

    //Milestone changes
    handleMilestoneChange = (milestone, event) => {
        let id
        if (event.target.name !== 'active_flag') {
            id = event.target.id
        } else {
            id = milestone.recordid
        }
        let index = this.state.milestoneData.findIndex(
            element => element.recordid == id
        )
        let change = this.state.milestoneData
        change[index].modified_date = this.getCurrentDate()
        change[index].modified_by = JSON.parse(
            sessionStorage.getItem('userData')
        ).name
        change[index][event.target.name] = event.target.value
        this.setState({ milestoneData: change })
    }

    //Milestone number changes
    handleMilestoneNumChange = event => {
        let index = this.state.milestoneData.findIndex(
            element => element.recordid == event.target.id
        )
        let value = event.target.value.replace(/[,$%]/g, '')
        if (isNaN(value)) {
            return
        }
        let change = this.state.milestoneData
        change[index][event.target.name] = value
        this.setState({ milestoneData: change })
    }

    //Changes when adding milestone
    handleNewMilestoneChange = event => {
        let change = this.state.newMilestone
        change[event.target.name] = event.target.value
        this.setState({ newMilestone: change })
    }

    //Changes when adding number milesone fields
    handleNewMilestoneNumChange = event => {
        let value = event.target.value.replace(/[,$%]/g, '')
        if (isNaN(value)) {
            return
        }
        let change = this.state.newMilestone
        change[event.target.name] = value
        this.setState({ newMilestone: change })
    }

    //The state change for the popup
    handleAddMilestoneBool = () => {
        this.setState({ addMilestone: false })
    }

    //Gets the current milestones for the quote
    getCurrentMilestones = () =>{
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        const data1 = {
            action: 'getQuoteCurrentMilestone',
            current_milestone_template: this.state.header.alternate_terms,
            sbu: userData === null ? null : userData.strategic_business_unit,
            userid: userData === null ? null : userData.userid,
            quote_number: this.state.header.quote_number,
            //hug_schema: 'hug_horizons'
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data1).then(
            response => {
                if (response.result && response.result !== 'false') {
                    this.setState({milestoneData: response.quote_milestonesArr})
                }
            })
    }

    //Saves the current milestones
    handleMilestoneSave = (quote_number) => {
        let count = 0
        let length = this.state.milestoneData.length

        this.state.milestoneData.forEach(milestone => {
            // console.log(milestone)
            const data = {
                action: 'update_quote_milestones_record',
                //hug_schema: 'hug_horizons',
                userid: JSON.parse(sessionStorage.getItem('userData')).userid,
                recordid: milestone.recordid,
                strategic_business_unit: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
                quote_number: quote_number,
                milestone_code: this.state.header.alternate_terms,
                order_sequence: milestone.order_sequence,
                milestone_booking_type: this.state.header.booking_type,
                contract_type: null,
                milestone_type: milestone.milestone_type,
                milestone_lic_ps: milestone.milestone_lic_ps,
                milestone_contingent_on: milestone.milestone_contingent_on,
                milestone_desc: milestone.milestone_desc,
                percent_or_dollar_amount_calculation:
                    milestone.percent_or_dollar_amount_calculation,
                milestone_license_fee: milestone.milestone_license_fee,
                milestone_professional_services:
                    milestone.milestone_professional_services,
                milestone_professional_services_hours:
                    milestone.milestone_professional_services_hours,
                active_flag: milestone.active_flag,
                milestone_percentage: milestone.milestone_percentage,
                modified_by: milestone.modified_by,
                modified_date: milestone.modified_date,
            }

            PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
                response => {
                    if (response.result) {
                        count++

                        if (count === length) {
                            this.recalculateMilestones()
                        }
                    }
                }
            )
        })
    }

    //Server call to add milestone
    addMilestone = () => {
        this.handleMilestoneSave()
        const data = {
            action: 'insert_quote_milestones_record',
            //hug_schema: 'hug_horizons',
            userid: JSON.parse(sessionStorage.getItem('userData')).userid,
            strategic_business_unit: JSON.parse(
                sessionStorage.getItem('userData')
            ).strategic_business_unit,
            quote_number: this.state.header.quote_number,
            milestone_code: this.state.header.alternate_terms,
            order_sequence: this.state.newMilestone.order_sequence,
            milestone_booking_type: this.state.header.booking_type,
            contract_type: null,
            milestone_type: this.state.newMilestone.milestone_type,
            milestone_lic_ps: 'All',
            milestone_contingent_on: this.state.newMilestone
                .milestone_contingent_on,
            milestone_desc: this.state.newMilestone.milestone_desc,
            percent_or_dollar_amount_calculation: 'PERCENT',
            milestone_license_fee:
                this.state.newMilestone.milestone_license_fee || 0,
            milestone_professional_services:
                this.state.newMilestone.milestone_professional_services || 0,
            milestone_professional_services_hours:
                this.state.newMilestone.milestone_professional_services_hours ||
                0,
            active_flag: '1',
            milestone_percentage:
                this.state.newMilestone.milestone_percentage || 0,
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ addMilestone: false })
                    this.setState({ newMilestone: {} })

                    this.getCurrentMilestones()
                }
            }
        )
    }

    //Shows milestone dropdowns
    viewMilestoneDropdown = (control, milestone, name, label) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })

        return (
            <TextField
                select
                disabled={this.state.locked}
                fullWidth
                id={milestone.milestone_type}
                name={name}
                label={label}
                className={classes.textField}
                onChange={e => this.handleMilestoneChange(milestone, e)}
                value={milestone[name]}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    //Shows mielstone dropdown when adding milestone
    viewNewMilestoneDropdown = (control, id, name) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })

        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                label={name}
                className={classes.textField}
                onChange={this.handleNewMilestoneChange}
                value={this.state.newMilestone[id]}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    //Shows popup when adding new milestone
    showAddMilestone = () => {
        const { classes } = this.props
        if (this.state.addMilestone === true && this.state.locked !== true) {
            return (
                <Card>
                    <div className={classes.secondary}>
                        <Grid container>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                className={classes.textField}
                            >
                                Add Payment
                            </Typography>
                            <Grid item className={classes.buttonGrid}>
                                <Button
                                    color="primary"
                                    className={classes.add}
                                    onClick={this.addMilestone}
                                >
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        size="2x"
                                        color="white"
                                    />
                                </Button>

                                <Button onClick={this.handleAddMilestoneBool}>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        size="2x"
                                        color="white"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={1} className={classes.outside}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="milestone_type"
                                name="milestone_type"
                                label="Payment Name"
                                className={classes.textField}
                                fullWidth
                                value={this.state.newMilestone.milestone_type}
                                onChange={this.handleNewMilestoneChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <NumberFormat
                                required
                                id="order_sequence"
                                name="order_sequence"
                                label="Order"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.newMilestone.order_sequence}
                                onChange={this.handleNewMilestoneNumChange}
                                thousandSeparator={true}
                                decimalScale={0}
                                fixedDecimalScale={true}
                                allowEmptyFormatting={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={7}>
                            <TextField
                                id="milestone_contingent_on"
                                name="milestone_contingent_on"
                                label="Contingent On"
                                className={classes.textField}
                                fullWidth
                                value={
                                    this.state.newMilestone
                                        .milestone_contingent_on
                                }
                                onChange={this.handleNewMilestoneChange}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                multiline
                                rows="2"
                                variant="outlined"
                                id="milestone_desc"
                                name="milestone_desc"
                                label="Description"
                                fullWidth
                                className={classes.textField}
                                value={this.state.newMilestone.milestone_desc}
                                onChange={this.handleNewMilestoneChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <NumberFormat
                                required
                                id="milestone_percentage"
                                name="milestone_percentage"
                                label="Percentage"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={
                                    this.state.newMilestone.milestone_percentage
                                }
                                onChange={this.handleNewMilestoneNumChange}
                                thousandSeparator={true}
                                decimalScale={0}
                                suffix={'%'}
                                fixedDecimalScale={true}
                                defaultValue={0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NumberFormat
                                id="milestone_license_fee"
                                name="milestone_license_fee"
                                label="License Fee"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={
                                    this.state.newMilestone
                                        .milestone_license_fee
                                }
                                onChange={this.handleNewMilestoneNumChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <NumberFormat
                                id="milestone_professional_services_hours"
                                name="milestone_professional_services_hours"
                                label="Professional Services Hours"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={
                                    this.state.newMilestone
                                        .milestone_professional_services_hours
                                }
                                onChange={this.handleNewMilestoneNumChange}
                                thousandSeparator={true}
                                decimalScale={0}
                                fixedDecimalScale={true}
                                defaultValue={0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberFormat
                                id="milestone_professional_services"
                                name="milestone_professional_services"
                                label="Professional Services Cost"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={
                                    this.state.newMilestone
                                        .milestone_professional_services
                                }
                                onChange={this.handleNewMilestoneNumChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                    </Grid>
                </Card>
            )
        }
    }

    //adds up percentage and dollar totals for milestones
    getMilestoneTotals = (id, id2) => {
        const { classes } = this.props
        if (this.state.milestoneData != undefined) {
            let arr = []
            this.state.milestoneData.forEach(milestone => {
                if (milestone.active_flag == 1) {
                    arr.push(milestone)
                }
            })
            let total = 0

            if (id2 != 0) {
                arr.forEach(milestone => {
                    total =
                        parseFloat(total) +
                        (parseFloat(milestone[id]) + parseFloat(milestone[id2]))
                })
            } else {
                arr.forEach(milestone => {
                    total = parseFloat(total) + parseFloat(milestone[id])
                })
            }
            if (id == 'milestone_percentage') {
                if (total == 100) {
                    return (
                        <Typography className={classes.total}>
                            {total.toFixed(0) + '%'}
                        </Typography>
                    )
                } else {
                    return (
                        <Typography className={classes.wrongtotal}>
                            {total.toFixed(0) + '%'}
                        </Typography>
                    )
                }
            } else {
                return (
                    <Typography className={classes.total}>
                        {'$' + total.toFixed(2)}
                    </Typography>
                )
            }
        }
    }

    //Shows the current milestones
    showMilestones = () => {
        const { classes } = this.props
        let arr = []

        this.state.milestoneData.forEach(milestone => {
            if (milestone.active_flag == 1) {
                arr.push(milestone)
            }
        })

        if (this.state.milestoneData != undefined) {
            return arr.map(milestone => (
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        id={milestone.milestone_type}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>
                                    {parseFloat(
                                        milestone.milestone_percentage
                                    ).toFixed(0) + '%'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    {this.toTitleCase(milestone.milestone_type)}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    {'$' +
                                        (
                                            parseFloat(
                                                milestone.milestone_license_fee
                                            ) +
                                            parseFloat(
                                                milestone.milestone_professional_services
                                            )
                                        ).toFixed(2)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={1}>
                                <NumberFormat
                                    required
                                    disabled={this.state.locked}
                                    id={milestone.recordid}
                                    name="milestone_percentage"
                                    label="Percentage"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={milestone.milestone_percentage}
                                    onChange={this.handleMilestoneNumChange}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    suffix={'%'}
                                    fixedDecimalScale={true}
                                    defaultValue={0}
                                    allowEmptyFormatting={true}
                                />
                            </Grid>

                            <Grid item xs={12} sm={3}>
                                <NumberFormat
                                    disabled={this.state.locked}
                                    id={milestone.recordid}
                                    name="milestone_license_fee"
                                    label="License Fees"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={milestone.milestone_license_fee}
                                    onChange={this.handleMilestoneNumChange}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={'$'}
                                    fixedDecimalScale={true}
                                    defaultValue={0.0}
                                    allowEmptyFormatting={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <NumberFormat
                                    disabled={this.state.locked}
                                    id={milestone.recordid}
                                    name="milestone_professional_services_hours"
                                    label="PS Hours"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={
                                        milestone.milestone_professional_services_hours
                                    }
                                    onChange={this.handleMilestoneNumChange}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    defaultValue={0}
                                    allowEmptyFormatting={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <NumberFormat
                                    disabled={this.state.locked}
                                    id={milestone.recordid}
                                    name="milestone_professional_services"
                                    label="PS Fees"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={
                                        milestone.milestone_professional_services
                                    }
                                    onChange={this.handleMilestoneNumChange}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={'$'}
                                    fixedDecimalScale={true}
                                    defaultValue={0.0}
                                    allowEmptyFormatting={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                {this.viewMilestoneDropdown(
                                    'MILESTONE_STATUS',
                                    milestone,
                                    'active_flag',
                                    'Status'
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={this.state.locked}
                                    multiline
                                    rows="1"
                                    variant="outlined"
                                    id={milestone.recordid}
                                    name="milestone_desc"
                                    label="Milestone Description"
                                    fullWidth
                                    className={classes.textField}
                                    value={milestone.milestone_desc}
                                    onChange={e =>
                                        this.handleMilestoneChange(milestone, e)
                                    }
                                />
                            </Grid>
                            <Grid item>
                                <Typography className={classes.modifiedText}>
                                    {milestone.modified_by
                                        ? 'Modified By: ' +
                                          milestone.modified_by +
                                          ' On: ' +
                                          milestone.modified_date
                                        : ''}
                                </Typography>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))
        }
    }

    //The whole payment plan tab
    milestoneScreen = () => {
        const { classes } = this.props
        return (
            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <TextField
                        disabled
                        required
                        id="alternate_terms"
                        name="milestone_booking_type"
                        label="Milestone Template Code"
                        fullWidth
                        className={classes.textField}
                        value={this.state.header.alternate_terms}
                        //onChange={this.handleProductDetailsChange}
                    />
                </Grid>
                
                <Grid item xs={6}>
                    <TextField
                        disabled
                        required
                        id="milestone_booking_type"
                        name="milestone_booking_type"
                        label="Booking Type"
                        fullWidth
                        className={classes.textField}
                        value={this.state.header.booking_type}
                        //onChange={this.handleProductDetailsChange}
                    />
                </Grid>
                
                <Grid item xs={12}>
                    {this.showAddMilestone()}
                </Grid>
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        className={classes.textField}
                    >
                        Payment Schedule
                    </Typography>
                </Grid>
                <Grid item className={classes.addGrid}>
                    <Tooltip title={'Add Milestone'}>
                        <IconButton
                            onClick={() =>
                                this.setState({ addMilestone: true })
                            }
                            className={classes.add}
                        >
                            <FontAwesomeIcon
                                icon={faPlusCircle}
                                size="1x"
                                color="black"
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    {this.showMilestones()}
                </Grid>
                <Grid item xs={7}>
                    {this.getMilestoneTotals('milestone_percentage', 0)}
                </Grid>
                <Grid item>
                    <Typography
                        
                        color="inherit"
                        className={classes.specialText}
                    >
                        EPN EPN EPN__
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    {this.getMilestoneTotals(
                        'milestone_professional_services',
                        'milestone_license_fee'
                    )}
                </Grid>
                
            </Grid>
        )
    }

    /////////////////////////////////////////////////////////////////////////////////////
    //SAAS CALC
    /////////////////////////////////////////////////////////////////////////////////////

    //Shows dropdowns in saas tab
    viewSaasDropdown = (control, id, name) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })

        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                label={name}
                className={classes.textField}
                onChange={this.handleSaasCalcChange}
                value={parseInt(this.state.header[id])}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    //calculates total quote value
    calculateTotalFees = (type) =>{
        let total = 0
        if(this.state.includeQuoteValue[type] === true){
            for (let product in this.state.recap) {
                total = parseFloat(total) + parseFloat(this.state.recap[product][type])
            }
        }
        return total
    }

    //changes to saas values
    handleSaasCalcChange = event => {
        let change = this.state.header
        let value = event.target.value.replace(/[,$]/g, '')
        change[event.target.name] = value
        this.setState({ header: change })
    }

    //changes for what to include in the quote value
    handleSaasIncludeChange = id =>{
        
        let change = this.state.includeQuoteValue
        change[id] = !this.state.includeQuoteValue[id]
        this.setState({ includeQuoteValue: change })
    }

    calculateTotalInterest = () => {
        if (parseFloat(this.state.header.saas_total_saas) > 0 && parseFloat(this.state.header.saas_interest_rate) > 0) {
            return (parseFloat(this.state.header.saas_total_saas) * parseFloat(this.state.header.saas_interest_rate) / 100) 
        } else {
            return 0;
        }
    }

    //Calculates totals for each row on yearly breakdown table
    calculateYearlyBreakdown = () =>{
        let year
        let balance
        //let maintenance
        let interest
        //let saas = 0
        let fees = 0
        let annualFee = 0
        let userFee = 0
        let monthlyPayment = 0
        let total = 0
        let rows = []

        this.state.product_details.map(product => {
            if (product.qty > 0 && product.addon_option === 'false') {
                if (product.lic_fee_period == 'ANNUAL') {
                    annualFee += parseFloat(product.lic_fee_amount)
                }

                if (product.user_fee_period === 'ANNUAL') {
                    userFee += parseFloat(product.user_fee_amount)
                }
            }
        })

        interest = this.calculateTotalInterest();
        interest = interest / parseFloat(this.state.header.saas_term_years);

        for(let i = 1; i <= this.state.header.saas_term_years; i++){
            year = i
            balance = parseFloat(this.state.header.saas_total_saas) / parseFloat(this.state.header.saas_term_years) * (parseFloat(this.state.header.saas_term_years) - i +1)
            // maintenance = (this.calculateTotalFees('net_software_price') * 0.25) + (this.calculateTotalFees('net_software_price') * 0.25 * (parseFloat(this.state.header.saas_annual_maintenance_pct_increase) / 100 * (i - 1)))
            // saas = parseFloat(this.state.header.saas_total_saas) / parseFloat(this.state.header.saas_term_years)

            if (i === 1) {
                monthlyPayment = parseFloat(balance) / (parseFloat(this.state.header.saas_term_years) * 12)
            } else {
                annualFee = annualFee + (annualFee * (parseFloat(this.state.header.saas_annual_maintenance_pct_increase) / 100));
            }

            // total = annualFee + userFee + interest + (monthlyPayment * 12)
            total = annualFee + userFee + interest + monthlyPayment
            rows.push({year, balance, annualFee, userFee, interest, monthlyPayment, total})
        }
        this.calculateYearlyBreakdownTotals(rows)
        return this.yearlyBreakdownTable(rows)

    }

    //Calculates bottom totals of yearly breakdown
    calculateYearlyBreakdownTotals = (rows) =>{
        // let maint = 0
        let interest = 0
        // let saas = 0
        let annualFee = 0
        let userFee = 0
        let monthlyPayment = 0
        let total = 0

        rows.forEach(row =>{
            // maint = maint + row.maintenance
            interest = interest + row.interest
            // saas = saas + row.saas
            annualFee = annualFee + row.annualFee
            userFee = userFee + row.userFee
            monthlyPayment = monthlyPayment + row.monthlyPayment
            total = total + row.total
        })

        // this.state.header.saas_total_ps_support = maint
        this.state.header.saas_total_interest = interest
        // this.state.header.saas_total_saas = saas
        this.state.header.saas_annual_fee = annualFee
        this.state.header.saas_user_fee = userFee
        this.state.header.saas_monthly_payments = monthlyPayment
        this.state.header.saas_total = total
        this.state.header.saas_total_interest = interest;
    }


    //The yearly breakdown rows
    yearlyBreakdownTable = (rows) =>{
        const { classes } = this.props
        return (
            rows.map(item => (
            <TableRow>
                <TableCell  align="right">
                    <NumberFormat
                        value={item.year}
                        displayType={'text'}
                    />
                </TableCell>
                <TableCell align="right">
                    <NumberFormat
                        value={item.balance}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                {/* <TableCell align="right">
                    <NumberFormat
                        value={item.maintenance}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell> */}
                <TableCell align="right">
                    <NumberFormat
                        value={item.annualFee}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                <TableCell align="right">
                    <NumberFormat
                        value={item.userFee}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                <TableCell align="right">
                    <NumberFormat
                        value={item.interest}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                {/* <TableCell align="right">
                    <NumberFormat
                        value={item.saas}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell> */}
                <TableCell align="right">
                    <NumberFormat
                        value={item.monthlyPayment}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                <TableCell align="right" className={classes.cellHeaderTotal}>
                    <NumberFormat
                        value={item.total}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
            </TableRow>))
            
        )
    }

    //This builds the whole saas tab
    showSaasCalc = () => {

        this.state.totalQuoteAmount =  this.calculateTotalFees('net_software_price') +
        this.calculateTotalFees('professional_fees')
        this.state.header.saas_total_saas = parseFloat(this.state.totalQuoteAmount) - parseFloat(this.state.header.license_down_payment)
        const { classes } = this.props

        return (
            <React.Fragment>

                <Grid container spacing={1} className={classes.body}>

                    <Grid item xs={12} sm={6} md={6}>
                        <Card>
                            <Grid item xs={12} sm={12}>
                                <Typography 
                                    component="h1" 
                                    variant="h6" 
                                    color="inherit"
                                    className={classes.textField}>
                                    Quote Value
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Table className={classes.saasTable} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell align="right">Cost</TableCell>
                                            <TableCell align="right">Include</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>License Fees</TableCell>
                                            <TableCell  align="right">
                                                <NumberFormat
                                                    value={this.calculateTotalFees('net_software_price')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => {this.handleSaasIncludeChange('net_software_price')}} >
                                                    {this.state.includeQuoteValue.net_software_price ? <FontAwesomeIcon icon={faCheck} size="1x"/> : <FontAwesomeIcon icon={faTimes} size="1x"/>}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>PS Fees</TableCell>
                                            <TableCell  align="right">
                                                <NumberFormat
                                                    value={this.calculateTotalFees('professional_fees')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => {this.handleSaasIncludeChange('professional_fees')}} >
                                                    {this.state.includeQuoteValue.professional_fees ? <FontAwesomeIcon icon={faCheck} size="1x"/> : <FontAwesomeIcon icon={faTimes} size="1x"/>}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Hardware</TableCell>
                                            <TableCell align="right">
                                                <NumberFormat
                                                    //value={this.calculateTotalFees('professional_fees')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => {this.handleSaasIncludeChange('hardware')}} >
                                                    {this.state.includeQuoteValue.hardware ? <FontAwesomeIcon icon={faCheck} size="1x"/> : <FontAwesomeIcon icon={faTimes} size="1x"/>}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Training</TableCell>
                                            <TableCell align="right">
                                                <NumberFormat
                                                    //value={this.calculateTotalFees('professional_fees')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => {this.handleSaasIncludeChange('training')}} >
                                                    {this.state.includeQuoteValue.training ? <FontAwesomeIcon icon={faCheck} size="1x"/> : <FontAwesomeIcon icon={faTimes} size="1x"/>}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Custom</TableCell>
                                            <TableCell align="right">
                                                <NumberFormat
                                                    //value={this.calculateTotalFees('professional_fees')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => {this.handleSaasIncludeChange('custom')}} >
                                                    {this.state.includeQuoteValue.custom ? <FontAwesomeIcon icon={faCheck} size="1x"/> : <FontAwesomeIcon icon={faTimes} size="1x"/>}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.cellGrandTotal}>Total Costs</TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.state.totalQuoteAmount}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}></TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Total Interest</TableCell>
                                            <TableCell align="right">
                                                <NumberFormat
                                                    value={this.calculateTotalInterest()}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right"></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Card>
                        <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Typography 
                                        component="h1" 
                                        variant="h6" 
                                        color="inherit"
                                        className={classes.textField}>
                                        SaaS Calculation
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    {this.viewSaasDropdown('SAAS_YEARS','saas_term_years','Agreement Years')}
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    {/* this.viewSaasDropdown('SAAS_INTEREST','saas_interest_rate','Interest Rate') */}
                                    <NumberFormat
                                        disabled={this.state.locked}
                                        id='saas_interest_rate'
                                        name="saas_interest_rate"
                                        label="Interest Rate"
                                        fullWidth
                                        className={classes.textField}
                                        customInput={TextField}
                                        value={this.state.header.saas_interest_rate}
                                        onChange={this.handleSaasCalcChange}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        defaultValue={0.0}
                                        allowEmptyFormatting={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <NumberFormat
                                        disabled={this.state.locked}
                                        id='license_down_payment'
                                        name="license_down_payment"
                                        label="Down Payment"
                                        fullWidth
                                        className={classes.textField}
                                        customInput={TextField}
                                        value={this.state.header.license_down_payment}
                                        onChange={this.handleSaasCalcChange}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        prefix={'$'}
                                        fixedDecimalScale={true}
                                        defaultValue={0.0}
                                        allowEmptyFormatting={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        disabled
                                        id="saas_annual_maintenance_pct"
                                        name="saas_annual_maintenance_pct"
                                        label="Annual Maintenance"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.header.saas_annual_maintenance_pct}
                                        onChange={this.handleSaasCalcChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    {this.viewSaasDropdown('SAAS_MAINT_PCT_INCR','saas_annual_maintenance_pct_increase','Annual Maintenance Increase')}
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider variant='middle' />
                                </Grid>

                                {/*This wasnt updating properly, shows yearly and monthly totals*/}

                                {/*<Grid item xs={12} >
                                    <Table className={classes.saasTable} size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Total Project Cost</TableCell>
                                                <TableCell  align="right">
                                                    <NumberFormat
                                                        value={this.state.header.saas_total_saas + this.state.header.saas_total_interest + this.state.header.saas_total_ps_support}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Annual Project Cost</TableCell>
                                                <TableCell  align="right">
                                                    <NumberFormat
                                                        value={(this.state.header.saas_total_saas + this.state.header.saas_total_interest + this.state.header.saas_total_ps_support) / this.state.header.saas_term_years}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Monthly Project Cost</TableCell>
                                                <TableCell  align="right">
                                                    <NumberFormat
                                                        value={(this.state.header.saas_total_saas + this.state.header.saas_total_interest + this.state.header.saas_total_ps_support) / this.state.header.saas_term_years /12}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                </TableCell>
                                                
                                            </TableRow>
                                        </TableBody>
                                                </Table>
                                </Grid>*/}
                                
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} >
                        <Card>
                            <Grid item xs={12} sm={12}>
                                <Typography 
                                    component="h1" 
                                    variant="h6" 
                                    color="inherit"
                                    className={classes.textField}>
                                    Yearly Breakdown
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Table className={classes.saasTable} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Year</TableCell>
                                            <TableCell align="right">Balance</TableCell>
                                            {/* <TableCell align="right">Maintenance</TableCell> */}
                                            <TableCell align="right">Annual Fee</TableCell>
                                            <TableCell align="right">User License Fee</TableCell>
                                            <TableCell align="right">Interest</TableCell>
                                            {/* <TableCell align="right">SaaS</TableCell> */}
                                            <TableCell align="right">Monthly Payment</TableCell>
                                            <TableCell align="right">Total Annual Fees</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.calculateYearlyBreakdown()}
                                        <TableRow>
                                            <TableCell className={classes.rowHeaderTotal}>Total</TableCell>
                                            <TableCell className={classes.rowHeaderTotal}/>
                                            {/* <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_total_ps_support}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell> */}
                                            <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_annual_fee}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_user_fee}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_total_interest}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell>
                                            {/* <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_total_saas}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell> */}
                                            <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_monthly_payments}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.rowGrandTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_total}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell>
                                        </TableRow>
                                        
                                    </TableBody>
                                </Table>
                            </Grid>
                            
                        </Card>
                    </Grid>

                    
                </Grid>
            </React.Fragment>
        )
    }

    ///////////////////////////////////////////////////////////////////////////////

    ///////////////////////////////////////////////////////////////////////////////
    //Notes
    //////////////////////////////////////////////////////////////////////////////
    handleNoteFormOpen = () => {
        this.setState({ noteFormOpen: true })
    }

    handleNoteFormClose = () => {
        this.setState({ noteFormOpen: false })
    }

    noteForm = () => {
        return (
            <React.Fragment>
                {this.state.noteFormOpen ? (
                    <NoteForm
                        crmData={this.props.formData.crmData}
                        handleFormClose={this.handleNoteFormClose}
                        updateComponent={this.props.updateComponent}
                        quoteNumber={this.state.header.quote_number}
                        modal
                    />
                ) : (
                    <Notes
                        crmData={this.props.formData.crmData}
                        handleFormOpen={this.handleNoteFormOpen}
                        quoteNumber={this.state.header.quote_number}
                        modal
                    />
                )}
            </React.Fragment>
        )
    }
    ////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////
    //Email
    //////////////////////////////////////////////////////////////////////////////////////


    //Server call to send email
    emailQuote = () => {
        const data = {
            action: 'eMail_Send',
            quote_number: this.state.header.quote_number,
            hug_schema:
                'hug_' +
                JSON.parse(
                    sessionStorage.getItem('userData')
                ).strategic_business_unit.toLowerCase(),
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
            sendors_userid: JSON.parse(sessionStorage.getItem('userData'))
                .userid,
            sendors_name: JSON.parse(sessionStorage.getItem('userData')).name,
            sendors_email_address: JSON.parse(
                sessionStorage.getItem('userData')
            ).email,
            to_name: this.state.emailData.to_name,
            to_email_address: this.state.emailData.to_email_address,
            cc: this.state.emailData.cc,
            bcc: this.state.emailData.bcc,
            confirmEmailRead: this.state.emailData.confirmEmailRead,
            subject: this.state.emailData.subject,
            eMailbody: this.state.emailData.eMailbody,
            quote_attachment: this.state.emailData.quote_attachment,
        }

        PostData('Quotes/ajax/ajaxEmailer2018.php', data).then(response => {
            if (response.result) {
            }
        })
    }

    //Eamil changes
    handleEmailChange = event => {
        let change = this.state.emailData
        if (event.target == undefined) {
            change.eMailbody = event.editor.getData()
            this.setState({ emailData: change })
        } else {
            change[event.target.name] = event.target.value
            this.setState({ emailData: change })
        }
    }

    //Shows the email tab
    showEmail = () => {
        const { classes } = this.props

        return (
            <React.Fragment>
                <Grid container spacing={1} className={classes.body}>
                    <Grid item xs={11}>
                        <Typography component="h1" variant="h6" color="inherit">
                            Email Quote PDF
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Button onClick={this.emailQuote}>Send</Button>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="to_name"
                            name="to_name"
                            label="To"
                            fullWidth
                            className={classes.textField}
                            value={this.state.emailData.to_name}
                            onChange={this.handleEmailChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required
                            id="to_email_address"
                            name="to_email_address"
                            label="To Email"
                            fullWidth
                            className={classes.textField}
                            value={this.state.emailData.to_email_address}
                            onChange={this.handleEmailChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled
                            required
                            id="sendors_name"
                            name="sendors_name"
                            label="From"
                            fullWidth
                            className={classes.textField}
                            value={
                                JSON.parse(sessionStorage.getItem('userData'))
                                    .name
                            }
                            onChange={this.handleEmailChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled
                            required
                            id="sendors_email_address"
                            name="sendors_email_address"
                            label="From Email"
                            fullWidth
                            className={classes.textField}
                            value={
                                JSON.parse(sessionStorage.getItem('userData'))
                                    .email
                            }
                            onChange={this.handleEmailChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="cc"
                            name="cc"
                            label="CC"
                            fullWidth
                            className={classes.textField}
                            value={this.state.emailData.cc}
                            onChange={this.handleEmailChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id="bcc"
                            name="bcc"
                            label="Bcc"
                            fullWidth
                            className={classes.textField}
                            value={this.state.emailData.bcc}
                            onChange={this.handleEmailChange}
                        />
                    </Grid>

                    <Grid item xs={12} sm={12}>
                        <TextField
                            id="subject"
                            name="subject"
                            label="Subject"
                            fullWidth
                            className={classes.textField}
                            value={this.state.emailData.subject}
                            onChange={this.handleEmailChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField
                            disabled
                            id="quote_attachment"
                            name="quote_attachment"
                            label="Quote Attachment"
                            fullWidth
                            className={classes.textField}
                            value={this.state.emailData.quote_attachment}
                            onChange={this.handleEmailChange}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <CKEditor
                            data={this.state.emailData.eMailbody}
                            config={{
                                //oolbar: [[]],
                                removePlugins: ['elementspath'],
                                height: 200,
                                readOnly: false,
                            }}
                            onChange={this.handleEmailChange}
                        />
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }

    //////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////
    //Top Buttons
    /////////////////////////////////////////////////////////////////////////////////////

    //PO changes
    handleAddPOChange = event => {
        let change = this.state.POdata
        change[event.target.name] = event.target.value
        this.setState({ POdata: change })
    }


    //Server call to add PO
    addPO = () => {
        let newWO = this.state.newWO
        newWO.purchase_order = this.state.POdata.purchase_order
        newWO.po_requested_by = this.state.POdata.po_requested_by
        newWO.po_date = this.state.POdata.po_date
        this.setState({ newWO: newWO })

        const data = {
            action: 'addPO',
            purchase_order: this.state.POdata.purchase_order,
            po_requested_by: this.state.POdata.po_requested_by,
            po_date: this.state.POdata.po_date,
            quote_number: this.state.header.quote_number,
            hug_schema:
                'hug_' +
                JSON.parse(
                    sessionStorage.getItem('userData')
                ).strategic_business_unit.toLowerCase(),
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ addPO: false })
                }
            }
        )
    }

    //Shows popup when new PO is being created - not used anymore
    // viewAddPO = () => {
    //     const { classes } = this.props
    //         return (
    //             <Grid container spacing={1}>
    //                 <Grid item xs={12}>
    //                     {/* <Card> */}
    //                         {/* <div className={classes.secondary}>
    //                             <Grid container>
    //                                 <Typography
    //                                     component="h1"
    //                                     variant="h6"
    //                                     color="inherit"
    //                                     className={classes.textField}
    //                                 >
    //                                     Add PO
    //                                 </Typography>
    //                                 <Grid item className={classes.buttonGrid}>
    //                                     <Button
    //                                         color="primary"
    //                                         className={classes.add}
    //                                         onClick={this.addPO}
    //                                     >
    //                                         <FontAwesomeIcon
    //                                             icon={faCheck}
    //                                             size="2x"
    //                                             color="white"
    //                                         />
    //                                     </Button>
    //                                     <Button
    //                                         onClick={() =>
    //                                             this.setState({ addPO: false })
    //                                         }
    //                                     >
    //                                         <FontAwesomeIcon
    //                                             icon={faTimes}
    //                                             size="2x"
    //                                             color="white"
    //                                         />
    //                                     </Button>
    //                                 </Grid>
    //                             </Grid>
    //                         </div> */}
    //                         <Grid
    //                             container
    //                             spacing={1}
    //                             className={classes.body}
    //                         >
    //                             <Grid item xs={4}>
    //                                 <TextField
    //                                     required
    //                                     id="purchase_order"
    //                                     name="purchase_order"
    //                                     label="Purchase Order #"
    //                                     fullWidth
    //                                     className={classes.textField}
    //                                     value={this.state.POdata.purchase_order}
    //                                     onChange={this.handleAddPOChange}
    //                                 />
    //                             </Grid>
    //                             <Grid item xs={4}>
    //                                 <TextField
    //                                     required
    //                                     id="po_requested_by"
    //                                     name="po_requested_by"
    //                                     label="Purchase Order Requester"
    //                                     fullWidth
    //                                     className={classes.textField}
    //                                     value={
    //                                         this.state.POdata.po_requested_by
    //                                     }
    //                                     onChange={this.handleAddPOChange}
    //                                 />
    //                             </Grid>
    //                             <Grid item xs={4}>
    //                                 <MuiPickersUtilsProvider utils={DateFnsUtils}>
    //                                     <DatePicker
    //                                         id="po_date"
    //                                         name="po_date"
    //                                         label="Purchase Order Date"
    //                                         variant="inline"
    //                                         format="MM/dd/yyyy"
    //                                         className={classes.textField}
    //                                         value={this.state.POdata.po_date || null}
    //                                         onChange={(date) => {this.handleDateChange(date, 'po_date', false, true)}}
    //                                         fullWidth
    //                                         InputLabelProps={{ shrink: true }}
    //                                     />
    //                                 </MuiPickersUtilsProvider>

    //                                 {/* <TextField
    //                                     required
    //                                     InputLabelProps={{ shrink: true }}
    //                                     id="po_date"
    //                                     name="po_date"
    //                                     label="PO Date"
    //                                     type="date"
    //                                     fullWidth
    //                                     className={classes.textField}
    //                                     value={this.state.POdata.po_date}
    //                                     onChange={this.handleAddPOChange}
    //                                 /> */}
    //                             </Grid>
    //                         </Grid>
    //                     {/* </Card> */}
    //                 </Grid>
    //             </Grid>
    //         )
    // }

    firstTab = () => {
        return (
            <React.Fragment>
                {this.showQuoteWon()}
                {this.showQuoteLost()}
                {this.showWonQuoteForm()}
                {this.showLostQuoteForm()}
                {this.showLocked()}
                {this.showOverview()}
            </React.Fragment>
        )
    }

    //? 2.3.0 - New Quote Module
    getPDF = () => {
        const data = {
            quote_number: this.state.header.quote_number,
            hug_schema: 'hug_' + JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase(),
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            workStmt: this.state.header.statement_of_work || '',
            description: this.state.header.description || '',
            m_code: this.state.header.alternate_terms,
            customer_name: this.state.header.quotesentto || ''
        }

        PostData('Quotes/ajax/ajaxpdf.php', data).then(
            response => {
                if (response.path) {
                    window.open(response.path, '_blank');
                }
            }
        )
    }

    getNewPDF = () => {
        console.log(this.state.header)
        const data = {
            quote_number: this.state.header.quote_number,
            hug_schema: 'hug_' + JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase(),
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            workStmt: '<p>'+this.state.header.statement_of_work+'</p>' || '',
            description: this.state.header.description || '',
            m_code: this.state.header.alternate_terms,
            customer_name: this.state.header.quotesentto || ''
        }

        PostData('Quotes/ajax/ajaxpdfNew.php', data).then(
            response => {
                if (response.path) {
                    window.open(response.path, '_blank');
                }
            }
        )
    }

    // getPDF = () => {
    //     const data = {
    //         quote_number: this.state.header.quote_number,
    //         hug_schema: 'hug_' + JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase(),
    //         sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
    //     }

    //     PostData('Quotes/ajax/ajaxPrepareQuotePdf_2019.php', data).then(
    //         response => {
    //             if (response.result) {
    //                 window.open(response.path, '_blank');
    //             }
    //         }
    //     )
    // }

    productRecap = () => {
        return (
            <React.Fragment>
            <Grid container>
                <Grid item xs={6}>
                    <Typography component="h1" variant="h6" color="inherit">
                        Product Recap
                    </Typography>
                </Grid>

                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={3}>
                        </Grid>
                        
                        <Grid item xs={3}>
                            <CSVLink data={this.state.csvData} style={{textDecoration: 'none'}}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    //className={classes.buttons}
                                    style={{
                                        marginLeft: 5,
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faFileDownload}
                                        size="1x"
                                        style={{
                                            color: 'white',
                                            marginRight: 10,
                                        }}
                                    />
                                    Export CSV
                                </Button>
                            </CSVLink>
                        </Grid>
                        
                        <Grid item xs={3}>
                            <ReactToPrint
                                trigger={() => {
                                    return (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            //className={classes.buttons}
                                            style={{
                                                marginLeft: 15,
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPrint}
                                                size="1x"
                                                color="white"
                                                style={{
                                                    marginRight: 5,
                                                }}
                                            />
                                            Recap PDF
                                        </Button>
                                    )
                                }}
                                content={() => {
                                    return this.componentRef
                                }}
                            /> 
                        </Grid>
                        
                        {/* <Grid item xs={3}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.getPDF}
                                //className={classes.buttons}
                                style={{
                                    marginLeft: 5,
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    size="1x"
                                    color="white"
                                    style={{
                                        marginRight: 5,
                                    }}
                                />
                                Quote PDF
                            </Button>
                        </Grid> */}

                        <Grid item xs={3}>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={this.getNewPDF}
                                //className={classes.buttons}
                                style={{
                                    marginLeft: 5,
                                }}
                            >
                                <FontAwesomeIcon
                                    icon={faFilePdf}
                                    size="1x"
                                    color="white"
                                    style={{
                                        marginRight: 5,
                                    }}
                                />
                                Quote PDF
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

                <div style={{display: 'none'}}>
                    <ProductRecap
                        ref={el => (this.componentRef = el)}
                        product_details={
                            this.state.product_details
                        }
                        recap={this.state.recap}
                        print={true}
                        customerName={this.props.crmData.company}
                        quoteNumber={this.state.header.quote_number}
                    />  
                </div>

                <ProductRecap
                    product_details={
                        this.state.product_details
                    }
                    recap={this.state.recap}
                />  
            </Grid>      

            <Grid container>
                <Grid item xs={9}>
                    <Typography component="h1" variant="h6" color="inherit">
                        Optional Modules
                    </Typography>
                </Grid>
                <Grid item xs={2}>

                </Grid>

                <ProductRecap
                    product_details={
                        this.state.product_details
                    }
                    recap={this.state.recap}
                    optionalLines={this.state.optionalLines}
                    optional
                />      
            </Grid>
            </React.Fragment>
        )
    }

    /////////////////////////////////////////////////////////////////////////////////////

    //render
    render() {
        this.handleServiceChange()

        const { classes } = this.props
        const headerName = this.props.crmData ? this.props.crmData.company + " - " + this.state.header.quote_number : this.state.header.quote_number

        return (
            <Card>
            <FormWrapper
                invalid={this.state.invalid}
                type="Quote"
                name={headerName}
                handleSave={this.handleSave}
                handleClose={this.handleClose}
                create={this.state.newQuote}
                handleCopy={this.handleCopy}
                customMessage={'Please fill in the highlighted fields on the Overview tab to save'}
            >
                <Dialog
                    open={this.state.popupWonOpen}
                    aria-labelledby="Mark Won?"
                    aria-describedby="description"
                >
                    <DialogTitle id="Mark Won">
                        {'Change Status to Won?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="description">
                            {
                                'This will add all active Products, and lock the quote. Save all changes first!'
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {                                
                                this.setState({ popupWonOpen: false })
                            }}
                            className={classes.cardButtons}
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => {
                                this.winQuote()
                            }}
                            className={classes.cardButtons}
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.popupLostOpen}
                    aria-labelledby="Mark Lost?"
                    aria-describedby="description"
                >
                    <DialogTitle id="Mark Lost">
                        {'Change Status to Lost?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="description">
                            {'This will lock the quote'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ popupLostOpen: false })
                            }}
                            className={classes.cardButtons}
                        >
                            No
                        </Button>
                        <Button
                            onClick={() => {
                                this.loseQuote()
                            }}
                            className={classes.cardButtons}
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.noClientID}
                    aria-labelledby="Not Client"
                    aria-describedby="description"
                >
                    <DialogTitle id="Not Client">
                        {'Error: Cannot Create Quote'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="description">
                            {
                                'This company is not a Harris client. Create a Client Record with a temporary Harris ID?'
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ noClientID: false })
                            }}
                            className={classes.cardButtons}
                        >
                            Close
                        </Button>
                        <Button
                            onClick={this.handleNoClientID}
                            className={classes.cardButtons}
                            autoFocus
                        >
                            Create Client Record
                        </Button>
                    </DialogActions>
                </Dialog>
                {/*<Dialog
                        open={this.state.winError}
                        aria-labelledby="No Harris ID"
                        aria-describedby="description"
                    >
                        <DialogTitle id="No Harris ID">
                            {'Error: Cannot Create Quote'}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="description">
                                {'This company does not have a Harris ID. Generate a temporary ID?'}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {this.setState({ winError: false })}}
                                
                                className={classes.cardButtons}
                            >
                                Close
                            </Button>
                            <Button
                            
                                onClick={this.handlewinError}
                                className={classes.cardButtons}
                                autoFocus
                            >
                                Generate Temp ID
                            </Button>
                        </DialogActions>
                    </Dialog>*/}

                {!this.state.newQuote ? (
                    <Grid container>
                        <Grid item xs={12}>
                            <SwipeableTabs
                                titles={[
                                    'Overview',
                                    'Scope',
                                    'Products',
                                    this.state.header.contract_payment =='License'?'Payment Plan':'SaaS',
                                    'Product recap',
                                    // 'Email Quote',
                                    'Notes',
                                ]}
                                handleTabChange={this.handleTabChange}
                            >
                                <div>{this.firstTab()}</div>

                                <div>{this.state.tabIndex === 1 ? this.showWorkStatement() : ''}</div>

                                <div>{this.showProducts()}</div>

                                {this.state.header.contract_payment =='License' ? <div>{this.milestoneScreen()}</div>:

                                <div>{this.showSaasCalc() }</div>}
                                
                                <div>{this.productRecap()}</div>
                                
                                {/* <div>{this.showEmail()}</div> */}

                                <div>{this.noteForm()}</div>
                            </SwipeableTabs>
                        </Grid>
                    </Grid>
                ) : (
                    this.firstTab()
                )}

                {/* {this.theToggle()} */}
            </FormWrapper>
             </Card>

            // </Grid>
        )
    }
}


QuotesForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(QuotesForm)
