import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
//import { styles } from '../../Layout/styles'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'
import FullScreenDialog from '../../Reusable/FullScreenDialog'

import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import { CardMedia } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { faDatabase } from '@fortawesome/free-solid-svg-icons'
import { Z_DATA_ERROR } from 'zlib'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },

    lower: {
        textTransform: 'capitalize',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    showMore: {
        width: '100%',
    },
    add: {
        padding: 5,
        margin: 1,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
})

function Transition(props) {
    return <Slide direction="up" {...props} />
}

class Alias extends Component {
    constructor(props) {
        super(props)

        this.state = {
            alias: [],
            more: false,
            open: false,
        }

        this.handleToggle = this.handleToggle.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
        //this.handleSave = this.handleSave.bind(this)
    }

    componentWillMount() {
        this.setState({ crmData: this.props.crmData })
        const data = {
            action: 'getCrmAlias',
            crm_id: this.props.crmData.crm_id,
            user: JSON.parse(sessionStorage.getItem('userData')).userid,
        }

        PostData('CRM/ajax/ajaxCRM_master_alias.php', data).then(response => {
            if (response.result) {
                this.setState({ alias: response.crm_alias })
            }
        })
        this.getNextRecordid()
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.crmData !== this.state.crmData) {
            this.setState({ crmData: nextProps.crmData, prevProps: nextProps })
            const data = {
                action: 'getCrmAlias',
                crm_id: nextProps.crmData.crm_id,
                user: JSON.parse(sessionStorage.getItem('userData')).userid,
            }

            PostData('CRM/ajax/ajaxCRM_master_alias.php', data).then(
                response => {
                    if (response.result) {
                        this.setState({ alias: response.crm_alias })
                    }
                }
            )
            this.getNextRecordid()
        }
    }
    //opens form
    handleOpen = recordid => {
        let data = {}
        if (recordid === 0) {
            //array format: [id/name, label, default value, isDisabled (bool)]
            data = {
                //add Alias data
                recordid: ['recordid', 'Record Id', this.state.nextId, true],
                crm_id: [
                    'crm_id',
                    'CRM ID',
                    this.state.crmData.crm_id,
                    true,
                ],
                company: [
                    'company',
                    'Utility Name',
                    this.props.crmData.company,
                    true,
                ],
                alias_company_name: [
                    'alias_company_name',
                    'Company Alias',
                    '',
                    false,
                ],
                created_by_userid: [
                    'created_by_userid',
                    'Created By',
                    JSON.parse(sessionStorage.getItem('userData')).userid,
                    true,
                ],
                created_datetime: [
                    'created_datetime',
                    'Created Date',
                    this.getCurrentDate(),
                    true,
                ],
                modified_by_userid: [
                    'modified_by_userid',
                    'Last Modified By',
                    JSON.parse(sessionStorage.getItem('userData')).userid,
                    true,
                ],
                modified_datetime: [
                    'modified_datetime',
                    'Last Modified Date',
                    this.getCurrentDate(),
                    true,
                ],
                notes: [
                    'notes',
                    'Notes',
                    '',
                    false
                ],
                add: true,
            }
        } else {
            if (recordid.created_datetime.length > 11) {
                recordid.created_datetime = recordid.created_datetime.slice(
                    0,
                    10
                )
                recordid.modified_datetime = recordid.modified_datetime.slice(
                    0,
                    10
                )
            }

            data = {
                //edit Alias Data
                recordid: ['recordid', 'Record Id', recordid.recordid, true],
                crm_id: ['crm_id', 'CRM ID', recordid.crm_id, true],
                company: ['company', 'Utility Name', recordid.company, true],
                alias_company_name: [
                    'alias_company_name',
                    'Company Alias',
                    recordid.alias_company_name,
                    false,
                ],
                created_by_userid: [
                    'created_by_userid',
                    'Created By',
                    recordid.created_by_userid,
                    true,
                ],
                created_datetime: [
                    'created_datetime',
                    'Created Date',
                    recordid.created_datetime,
                    true,
                ],
                modified_by_userid: [
                    'modified_by_userid',
                    'Last Modified By',
                    recordid.modified_by_userid,
                    true,
                ],
                modified_datetime: [
                    'modified_datetime',
                    'Last Modified Date',
                    recordid.modified_datetime,
                    true,
                ],
                notes: [
                    'notes',
                    'Notes',
                    recordid.notes,
                    false
                ],
                add: false,
            }
        }
        this.props.setFormData(data, 'Alias')
    }

    //closes form
    handleClose = () => {
        this.setState({ open: false })
    }

    //Opens and closes the more slider which only shows up if there are more alias' to sell
    handleToggle = () => {
        this.setState({ more: !this.state.more })
    }

    //Function fetchs the biggest existing recordid and adds 1 to it
    getNextRecordid = () => {
        let recordid
        let data = {
            action: 'getNextRecordid',
            user: JSON.parse(sessionStorage.getItem('userData')).userid,
        }

        PostData('CRM/ajax/ajaxCRM_master_alias.php', data).then(response => {
            if (response.result && response.crm_alias[0] !== undefined) {
                recordid = parseInt(response.crm_alias[0].max) + 1
                this.setState({ nextId: recordid })
            }
        })
    }

    getCurrentDate(separator = '-') {
        let newDate = new Date()
        let date = newDate.getDate()
        if (date < 10) {
            date = String(date)
            date = '0' + date
        }
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()

        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`
    }

    getAliasList = () => {
        const { classes } = this.props
        if (
            this.state.alias !== undefined &&
            this.state.alias !== null &&
            this.state.alias.length > 0
        ) {
            if (this.state.more) {
                return (
                    <React.Fragment>
                        {this.state.alias.map(
                            data => (
                                <Grid item xs={2}>
                                    <Button
                                        onClick={() => this.handleOpen(data)}
                                    >
                                        <ListItem>
                                            <ListItemText
                                                className={classes.lower}
                                                primary={
                                                    data.alias_company_name
                                                }
                                                secondary={data.company}
                                            />
                                        </ListItem>
                                    </Button>
                                </Grid>
                            ),
                            this
                        )}
                        <Grid item xs={true} />
                    </React.Fragment>
                )
            } else {
                let aliasList = []
                let iterator = 6
                if (this.state.alias.length <= 6) {
                    iterator = this.state.alias.length
                }
                for (let i = 0; i < iterator; i++) {
                    let data = this.state.alias[i]

                    aliasList.push(
                        <Grid item xs={2}>
                            <Button onClick={() => this.handleOpen(data)}>
                                <ListItem>
                                    <ListItemText
                                        className={classes.lower}
                                        primary={data.alias_company_name}
                                        secondary={data.company}
                                    />
                                </ListItem>
                            </Button>
                        </Grid>
                    )
                }

                aliasList[aliasList.length + 1] = (
                    <React.Fragment>
                        <Grid item xs={true} />
                    </React.Fragment>
                )

                return <React.Fragment>{aliasList}</React.Fragment>
            }
        } else {
            return ''
        }
    }
    getMoreButton() {
        const { classes } = this.props
        if (this.state.alias && this.state.alias.length > 6) {
            return (
                <Grid container>
                    <Button
                        className={classes.showMore}
                        onClick={this.handleToggle}
                    >
                        {this.state.more ? (
                            <div>
                                <FontAwesomeIcon
                                    icon={faChevronUp}
                                    size="1x"
                                    style={{
                                        marginRight: 10,
                                    }}
                                />
                                {this.state.alias.length} of{' '}
                                {this.state.alias.length}
                            </div>
                        ) : (
                            <div>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    size="1x"
                                    style={{
                                        marginRight: 10,
                                    }}
                                />
                                6 of {this.state.alias.length}
                            </div>
                        )}
                    </Button>
                </Grid>
            )
        }
    }

    render() {
        const { classes } = this.props
        
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Card>
                            {/*<div className={classes.secondary}>
                                <Grid container spacing={1}>
                                    <Grid item xs={10}>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            color="inherit"
                                            className={classes.textField}
                                        >
                                            Alias
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.addGrid}>
                                        <IconButton
                                            onClick={() => this.handleOpen(0)}
                                            className={classes.add}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                size="1x"
                                                color="white"
                                            />
                                        </IconButton>
                                    </Grid>
                                </Grid>
        </div>*/}           <Grid container spacing={1}  className={classes.sort}>
                                <Grid item className={classes.addGrid}>
                                    <Tooltip title={'Add Alias'}>
                                        <Button
                                            onClick={() => this.handleOpen(0)}
                                            className={classes.add}
                                            style={{
                                                color: 'black'
                                            }}
                                        >
                                            Add Alias
                                            <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                color="black"
                                                style={{
                                                    marginLeft: 5,
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                                    
                                </Grid>
                                <Divider variant='middle'/>
                            <List className={classes.root}>
                                <Grid
                                    container
                                    spacing={1}
                                    justify="space-evenly"
                                >
                                    {this.getAliasList()}
                                </Grid>
                            </List>
                            {this.getMoreButton()}
                        </Card>
                    </Grid>
                </Grid>

                <Dialog
                    //fullScreen
                    fullWidth
                    maxWidth="lg"
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    {/* <AppBar >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    onClick={this.handleClose}
                                    aria-label="Close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <div className={classes.flex} />
                                <Button
                                    color="inherit"
                                    onClick={this.handleSave}
                                >
                                    save
                                </Button>
                            </Toolbar>
                        </AppBar> */}
                    {/*<QuotesForm quotesData={this.state.quotesData} */}/>
                </Dialog>
            </React.Fragment>
        )
    }
}
Alias.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Alias)
