import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import { PostData } from '../../services/PostData'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Geocode from 'react-geocode'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'

import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import NumberFormat from 'react-number-format'
import SaveWarning from '../Reusable/SaveWarning'
import FormWrapper from '../Reusable/FormWrapper'

const styles = theme => ({
    textField: {
        margin: 1,
        marginLeft: 5,
        paddingBottom: 0,
        paddingTop: 5,
        paddingLeft: 3,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
})
//This key is currently locked to production by roy and we can only use the maps javascript api and I think street view at the moment
const GOOGLE_API_KEY = 'AIzaSyDawfj5dCkG6DMQkjGHiGRAHD1FrmooidU'

class AddCRMForm extends React.Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.state = {
            response: [],
            invalid: false,
            addressChanged: false
        }
    }
    //saves state change
    handleChange = event => {
        event.persist()
        const name = event.target.name
        let value = event.target.value
        
        if (name === 'phone') {
            value = value.replace(/-/g, '')
            value = value.replace(/[,(]/g, '')
            value = value.replace(/[,)]/g, '')
            value = value.replace(/ /g, '')
        } else if (name === 'meter_count' || name === 'meter_count2') {
            value = value.replace(/,/g, '')
        } else if (
            name === 'zip_code' &&
            this.state.zip_code[1] === 'Postal Code'
        ) {
            ///////postal code formatting ///////
            if (value.length > 7) {
                return
            }
            //console.log(this.state.zip_code)
            if (
                value.length === 4 &&
                event.nativeEvent.inputType !== 'insertText'
            ) {
                //double backspace to get rid of space and previous character
                value = value.substr(0, 3)
                this.setState({
                    [name]: ['zip_code', 'Postal Code', value, false],
                })
                return
            }
            if (value.length === 4) {
                value = value.substr(0, 3) + ' ' + value.substr(3)
            }
            value = value.toUpperCase()
            let type = 'num'
            let i = value.length - 1
            if (i % 2 === 0) {
                if (value.length <= 4) {
                    type = 'char'
                } else {
                    type = 'num'
                }
            } else {
                if (value.length <= 4) {
                    type = 'num'
                } else {
                    type = 'char'
                }
            }
            if (type === 'char') {
                if (
                    value.charAt(i).toUpperCase() ==
                    value.charAt(i).toLowerCase()
                ) {
                    //easiest way to check if char is a letter
                    value = value.substr(0, value.length - 1)
                }
            } else {
                if (
                    value.charAt(i).toUpperCase() !=
                    value.charAt(i).toLowerCase()
                ) {
                    value = value.substr(0, value.length - 1)
                }
            }
        } //////END OF POSTAL CODE FORMATTING/////////
        let change = this.state
        change[event.target.name][2] = value

        this.setState(change)
        if(this.state.invalid && this.state.company[2] && this.state.address_1[2] && this.state.country[2] && this.state.state[2] && this.state.city[2] && this.state.zip_code[2]&& this.state.phone[2] && this.state.utility_type[2]){
            this.setState({invalid: false})
        }
        if(name === 'address_1' || name==='city' || name === 'state' || name === 'country'){
            this.setState({addressChanged: true})
        }

        //if country is canada, changes the state dropdown to just provinces,
        //if country is United States changes the state dropdown to states
        //if country is any other country it makes state a regular text field
        //Default is regular text field until either canada or united states is selected
        if (event.target.name === this.state.country[0]) {
            if (event.target.value === this.state.country[7]) {
                let province = this.state.state
                province.length > 5 && (province = province.splice(0, 4))
                province[1] = 'Province' //sets label to Province
                province[3] = true //sets isDropdown value to true
                province[2] = '' //sets Value to ''

                let zip_code = this.state.zip_code

                zip_code[2] = ''
                zip_code[1] = 'Postal Code'

                province = province.concat(this.state.provinces)

                this.setState({ state: province, zip_code: zip_code })
            } else if (event.target.value === this.state.country[9]) {
                let state = this.state.state
                let zip_code = this.state.zip_code
                state.length > 5 && (state = state.splice(0, 4))
                state[1] = 'State' //sets label
                state[2] = '' //sets default values
                state[3] = true //sets isdropdown
                zip_code[2] = ''
                zip_code[1] = 'Zip Code'
                state = state.concat(this.state.states)

                this.setState({ state: state, zip_code: zip_code })
            } else {
                let state = this.state.state
                let zip_code = this.state.zip_code
                state.length > 5 && (state = state.splice(0, 4))
                state[3] = false //sets isdropdown value
                state[2] = '' //sets default value
                state[1] = 'State' //sets label
                zip_code[2] = ''
                zip_code[1] = 'Zip Code'
                this.setState({ state: state, zip_code: zip_code })
            }
        }

        
    }

    setLatLong = () =>{
        
        let addy = (this.state.address_1 ? this.state.address_1[2] : '') + ', ' + (this.state.city ? this.state.city[2] : '') + ', '+ (this.state.state?this.state.state[2]:'') + ', ' + (this.state.country?this.state.country[2]:'')
        
        ///////////// AUTO SET LAT AND LONG/////////////////////
        //This Section auto sets long and lat but costs money to use
        // in order to do less calls to google to ultimately save money, this section is run onClickAway from address, state, country or city and only run if one of those fields has changed
        //for addition information on the api refer to https://www.npmjs.com/package/react-geocode
        //for addition information on the api key which you need an account to get, refer to https://developers.google.com/maps/documentation/geocoding/get-api-key
        //Left as it currently is, it will ask google for a response and google says no, so basically nothing happens
        if(this.state.address_1[2] && this.state.country[2] && this.state.state[2] && this.state.city[2] && this.state.addressChanged){
            
        //if statement above only lets this section run if address, country, state and city are filled, and if the active event is one of those fields
            Geocode.enableDebug()
            Geocode.setApiKey(GOOGLE_API_KEY)
            Geocode.fromAddress(addy).then(
                response => {
                    const { lat, lng } = response.results[0].geometry.location;
                    let change = this.state
                    change.latitude[2] = lat
                    change.longitude[2] = lng 
                    this.setState(change)
                },
                error => {
                    console.error(error)
                }
            )
            this.setState({addressChanged: false})
        }
        
    }

    handleClose = () => {
        this.props.handleFormClose()
    }
    //creates state for each field containing a list with values [id, label, defaultValue ,isdropdown(bool)]
    //if the field requires a dropdown, the dropdown values are pushed to the end of the list
    componentWillMount() {
        if (this.props.formData !== undefined) {
            for (let field in this.props.formData) {
                let new_state = {}
                new_state[field] = this.props.formData[field]
                this.setState(new_state)
            }
        }

        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'ADD_COMPANY',
        }

        PostData(
            'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ response: response })
                let fields = this.state
                let state = []
                let province = []
                for (let i = 0; i < response.dropdowns.length; i++) {
                    for (let field in this.state) {
                        if (
                            fields[field][1] === response.dropdowns[i].name &&
                            fields[field][1] !== 'State'
                        ) {
                            fields[field].push(response.dropdowns[i].item_name)
                            fields[field][3] = true
                        } else if (fields[field][1] === 'State') {
                            if (response.dropdowns[i].name === 'State') {
                                state.push(response.dropdowns[i].item_name)
                            } else if (
                                response.dropdowns[i].name === 'Province'
                            ) {
                                province.push(response.dropdowns[i].item_name)
                            }
                        }
                    }
                }
                this.setState(fields) //sets all dropdowns except states and provinces
                this.setState({ states: state }) //sets a list of states
                this.setState({ provinces: province }) //sets a list of provinces
            }
        })
    }
    validate = () => {
        let condition = true
        for (let field in this.props.formData) {
            if (
                field === 'service_type' ||
                field === 'meter_count' ||
                field === 'meter_count2' ||
                field === 'service_type2' ||
                field === 'service_type2' ||
                field === 'latitude' ||
                field === 'longitude' ||
                //field === 'strategic_business_unit' ||
                field === 'web_site' ||
                field === 'fax' ||
                field === 'p_o_box'
            ) {
                continue
            }
            if (!this.state[field][2]) {
                console.log(field)
                condition = false
            }
        }

        return condition
    }
    handleSave = () => {
        if (this.validate()) {
            //auto generates harris client id using the first word of the company name, and the first letters of the rest and finally the sbu in all caps at the end
            let harris_client_id = ''
            let temp = this.state.company[2].split(' ')
            for(let i = 0; i<temp.length; i++){
                if(i===0){
                    harris_client_id += temp[0].toUpperCase()
                    harris_client_id += '_'
                }else{
                    
                    harris_client_id += temp[i].charAt(0).toUpperCase()
                }
            }
            harris_client_id += '_'
            const data = {
                harris_client_id: harris_client_id,
                action: 'addNewCRMRecord',
                userid: JSON.parse(sessionStorage.getItem('userData')).userid,
                strategic_business_unit: this.state.strategic_business_unit ? this.state.strategic_business_unit[2] : '',
                company: this.state.company[2],
                address_1: this.state.address_1[2],
                p_o_box: this.state.p_o_box[2],
                city: this.state.city[2],
                state: this.state.state[2],
                zip_code: this.state.zip_code[2],
                country: this.state.country[2],
                phone: this.state.phone[2],
                fax: this.state.fax[2],
                utility_type: this.state.utility_type[2],
                web_site: this.state.web_site[2],
                service_type:this.state.service_type  ?  this.state.service_type[2].toUpperCase() : '',
                meter_count: this.state.meter_count ? this.state.meter_count[2] : '',
                service_type2: this.state.service_type2
                    ? this.state.service_type2[2].toUpperCase()
                    : '',
                meter_count2: this.state.meter_count2
                    ? this.state.meter_count2[2]
                    : '',
                latitude: this.state.latitude ? this.state.latitude[2] : '',
                longitude: this.state.logitude ? this.state.longitude[2] : '',
            }

            const data2 = {
                action: 'getNewDropdown',
                subsystem_code: 'SERVICES',
            }
    
            PostData(
                'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
                data2
            ).then(response => {
                data['services'] = response.dropdowns
                
                PostData('CRM/ajax/ajaxSetUpNewAcct.php', data).then(response => {
                    this.setState({ redirect: response.newCRMID })
                    this.props.handleFormClose()
                })
            })

        }else{
            this.setState({invalid: true})
        }
    }

    //renders each field
    getList = data => {
        let fieldList = []
        let condition = false
        const { classes } = this.props

        
        for (let field in data) {
            let required = true
            if (
                field === 'response' ||
                field === 'states' ||
                field === 'provinces' || 
                field === 'invalid' || 
                field === 'addressChanged'
            ) {
                continue
            }
            if (
                field === 'service_type' ||
                field === 'meter_count' ||
                field === 'service_type2' ||
                field === 'service_type2' ||
                field === 'latitude' ||
                field === 'longitude' ||
                field === 'strategic_business_unit' ||
                field === 'web_site' ||
                field === 'fax' ||
                field === 'p_o_box'
            ) {
                required = false
            }
            let inputProps = {}
            if(field === 'zip_code'){
                inputProps = {spellCheck: 'false'}
            }
            if(field === 'strategic_business_unit'){
                fieldList.push(
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            className={classes.textField}
                            style={{ width: '100%' }}
                        >
                            <InputLabel
                                
                            >
                                {data[field][1]}
                            </InputLabel>
                            <Select
                                
                                name={field}
                                multiple
                                fullWidth
                                value={data[field][2]}
                                onChange={this.handleChange}
                                renderValue={selected => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {selected &&
                                            selected.map(value => (
                                                <Chip
                                                    key={field}
                                                    label={value}
                                                    style={{ margin: 2 }}
                                                />
                                            ))}
                                    </div>
                                )}
                            >
                                {data[field] &&
                                data[field].map(option =>
                                    condition === true ? (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ) : (
                                        option === true && (condition = true)
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Grid>
                )
                condition = false
            }
            else if (data[field] && data[field][3]) {
                if(field === 'country'){
                    fieldList.push(
                        <ClickAwayListener onClickAway={this.setLatLong}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    required = {required}
                                    id={data[field][0]}
                                    name={data[field][0]}
                                    label={data[field][1]}
                                    fullWidth
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    select
                                    value={data[field][2]}
                                    error={required && this.state.invalid && !this.state[field][2]}
                                    helperText={required && this.state.invalid && !this.state[field][2] && 'This field cannot be blank'}
                                >
                                    {data[field] &&
                                        data[field].map(option =>
                                            condition === true ? (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ) : (
                                                option === true && (condition = true)
                                            )
                                        )}
                                </TextField>
                            </Grid>
                        </ClickAwayListener>
                    )
                }else{
                fieldList.push(
                    <Grid item xs={12} sm={6}>
                        <TextField
                            required = {required}
                            id={data[field][0]}
                            name={data[field][0]}
                            label={data[field][1]}
                            fullWidth
                            className={classes.textField}
                            onChange={this.handleChange}
                            select
                            value={data[field][2]}
                            error={required && this.state.invalid && !this.state[field][2]}
                            helperText={required && this.state.invalid && !this.state[field][2] && 'This field cannot be blank'}
                        >
                            {data[field] &&
                                data[field].map(option =>
                                    condition === true ? (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ) : (
                                        option === true && (condition = true)
                                    )
                                )}
                        </TextField>
                    </Grid>
                )}
                condition = false
            } else if (
                (field !== 'meter_count' &&
                    field !== 'meter_count2' &&
                    field !== 'phone' &&
                    field !== 'longitude' &&
                    field !== 'latitude' &&
                    field !== 'fax' &&
                    field !== 'zip_code') ||
                (field === 'zip_code' &&
                    this.state.country[2] !== 'United States ')
            ) {
                if(field === 'state' || field === 'address' || field === 'city'){
                    fieldList.push(
                        <ClickAwayListener onClickAway={this.setLatLong}>
                            <Grid item xs={12} sm={6}>
                        <TextField
                            inputProps = {inputProps}
                            required = {required}
                            id={data[field] && data[field][0]}
                            name={data[field] && data[field][0]}
                            label={data[field] && data[field][1]}
                            fullWidth
                            value={data[field] && data[field][2]}
                            className={classes.textField}
                            defaultValue={data[field] && data[field][2]}
                            onChange={this.handleChange}
                            error={required && this.state.invalid && !this.state[field][2]}
                            helperText={required && this.state.invalid && !this.state[field][2] && 'This field cannot be blank'}
                        />
                    </Grid>
                        </ClickAwayListener>
                    )
                }else{
                fieldList.push(
                    <Grid item xs={12} sm={6}>
                        <TextField
                            inputProps = {inputProps}
                            required = {required}
                            id={data[field] && data[field][0]}
                            name={data[field] && data[field][0]}
                            label={data[field] && data[field][1]}
                            fullWidth
                            value={data[field] && data[field][2]}
                            className={classes.textField}
                            defaultValue={data[field] && data[field][2]}
                            onChange={this.handleChange}
                            error={required && this.state.invalid && !this.state[field][2]}
                            helperText={required && this.state.invalid && !this.state[field][2] && 'This field cannot be blank'}
                        />
                    </Grid>
                        )}
            } else if (field === 'zip_code') {
                fieldList.push(
                    <Grid item xs={12} sm={6}>
                        <NumberFormat
                            required = {required}
                            format="#####"
                            name={field}
                            id={field}
                            label={data[field] && this.state[field][1]}
                            fullWidth
                            customInput={TextField}
                            className={classes.textField}
                            value={data[field] && this.state[field][2]}
                            onChange={this.handleChange}
                            error={required && this.state.invalid && !this.state[field][2]}
                            helperText={required && this.state.invalid && !this.state[field][2] && 'This field cannot be blank'}
                        />
                    </Grid>
                )
            } else if (field === 'phone' || field === 'fax') {
                fieldList.push(
                    <Grid item xs={12} sm={6}>
                        <NumberFormat
                            required = {required}
                            format="(###) ###-####"
                            id={field}
                            name={field}
                            label={data[field] && this.state[field][1]}
                            fullWidth
                            className={classes.textField}
                            customInput={TextField}
                            value={data[field] && this.state[field][2]}
                            onChange={this.handleChange}
                            error={required && this.state.invalid && !this.state[field][2]}
                            helperText={required && this.state.invalid && !this.state[field][2] && 'This field cannot be blank'}
                        />
                    </Grid>
                )
            } else if (field === 'longitude' || field === 'latitude') {
                fieldList.push(
                    <Grid item xs={12} sm={6}>
                        <NumberFormat
                            id={field}
                            name={field}
                            label={data[field] && this.state[field][1]}
                            fullWidth
                            className={classes.textField}
                            customInput={TextField}
                            value={data[field] && this.state[field][2]}
                            onChange={this.handleChange}
                            thousandSeparator={true}
                            decimalScale={6}
                            allowEmptyFormatting={true}
                            allowNegative={true}
                            isAllowed={values => {
                                const { formattedValue, floatValue } = values
                                if (field === 'latitude') {
                                    return (
                                        formattedValue === '-' ||
                                        formattedValue === '' ||
                                        (floatValue <= 90 && floatValue >= -90)
                                    )
                                }

                                return (
                                    formattedValue === '-' ||
                                    formattedValue === '' ||
                                    (floatValue <= 180 && floatValue >= -180)
                                )
                            }}
                        />
                    </Grid>
                )
            } else {
                fieldList.push(
                    <Grid item xs={12} sm={6}>
                        <NumberFormat
                            id={field}
                            name={field}
                            label={data[field] && this.state[field][1]}
                            fullWidth
                            className={classes.textField}
                            customInput={TextField}
                            value={data[field] && this.state[field][2]}
                            onChange={this.handleChange}
                            thousandSeparator={true}
                            decimalScale={0}
                            fixedDecimalScale={true}
                            defaultValue={
                                this.state[field][2] ? this.state[field][2] : 0
                            }
                            allowEmptyFormatting={true}
                        />
                    </Grid>
                )
            }
        }
        return <React.Fragment>{fieldList}</React.Fragment>
    }

    render() {
        const { classes } = this.props
        //console.log(this.state)
        return (
            <FormWrapper
                invalid={this.state.invalid}
                type="Quote"
                name={'Add New CRM Record'}
                handleSave={this.handleSave}
                handleClose={this.handleClose}
                create={true}
            >
            <Grid container className={classes.outside}>
                <Grid item sm={12}>
                    <Card>
                        
                        
                        <Grid container spacing={1} className={classes.body}>
                            {!this.state.redirect && this.getList(this.state)}
                        </Grid>
                    </Card>
                </Grid>
                {this.state.redirect && (
                    <Redirect to={'/company/' + this.state.redirect} />
                )}
            </Grid>
            </FormWrapper>
        )
    }
}

AddCRMForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AddCRMForm)
