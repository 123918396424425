import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import {
    Modal,
    Paper,
    Menu,
    TableCell,
    IconButton,
    Icon,
    MenuItem,
    ListItem,
    List,
    ListItemText,
    InputBase,
    Tooltip,
    Grid,
    Button,
    Typography,
    Collapse,
    TextField,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import DateFnsUtils from '@date-io/date-fns'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    lower: {
        textTransform: 'capitalize',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    pullRight: {
        float: 'right',
    },
})

const CLOSE_PROBS = [
    {item_name: 'All'},
    {item_name: '25'},
    {item_name: '50'},
    {item_name: '75'},
    {item_name: '80'},
    {item_name: '90'},
    {item_name: '100'}
]

class Quotes extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            searchField: '',
            columns: [],
            status: 'Open',
            year: 'All',
            type: 'All',
            close_prob: 'All'
        }

        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
    }

    handleSearchClick = searchVal => {
        if (!searchVal) {
            searchVal = this.state.searchField
        }

        this.setState({ searchValue: searchVal })
        this.fetchList('Company Name', searchVal)
    }

    handleSearchChange = e => {
        this.setState({ searchField: e.target.value })
    }

    componentWillMount() {
        this.searchQuotes('')
        this.getDropdowns()
        this.setState({ status: 'Open' })
    }
    
    getDropdowns = () =>{
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'QUOTE',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }

    toTitleCase = str => {
        if (str !== null) {
            str = str.replace(/_/g, ' ')
            return str.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
        }
    }

    handleFilterChange = e =>{
        console.log(e)
        let value = e.target.value
        let name = e.target.name
        let columns = this.state.allQuotes

        this.setState({ [name] : value }, () => {
            if (this.state.status !== 'All') {
                columns = columns.filter(quote => {
                    return quote.status.toLowerCase() === this.state.status.toLowerCase()
                })
            }

            if (this.state.year !== 'All') {
                columns = columns.filter(quote => {
                    return quote.quoteclosedate.substr(0,4) == this.state.year
                })
            }

            if (this.state.type !== 'All') {
                columns = columns.filter(quote => {
                    if (this.state.type === 'New Named' && 
                        (quote.booking_type === 'NEW_NAMED' || quote.booking_type === 'ORGANIC')) {
                            return true
                    
                    } else if (this.state.type === 'Internal' && quote.booking_type === 'INTERNAL_BASED') {
                        return true
                    }
                    return false
                })
            }

            if (this.state.close_prob !== 'All') {
                columns = columns.filter(quote => {
                    return Number(quote.close_prob) === Number(this.state.close_prob)
                })
            }

            this.setState({ columns: columns })
        })

        // if (name === 'type') {
        //     columns.filter(quote => {
        //         if (e.target.value === 'New Named (NN)' && (quote.booking_type === 'New Named' || quote.booking_type === 'Organic')) {
        //             return true
        //         } else if (e.target.value === 'Internal Based (IB)' && quote.booking_type === 'Internal Based') {
        //             return true
        //         }
        //         return false
        //     })
        // } else if (name === 'close_prob') {
        //     columns.filter(quote => {
        //         return quote.booking_type === e.target.value
        //     })
        // } else {
        //     columns = columns.filter(quote=>{
        //         let filter1 = false
        //         let filter2 = false
                
        //         if(name === 'status' ? quote.status.toLowerCase() === value.toLowerCase() || value.toLowerCase() === 'all' : quote.quoteclosedate.substr(0,4) == value || value.toLowerCase() === 'all'){
        //             filter1 = true
        //         }
        //         if(name === 'status' ? quote.quoteclosedate.substr(0,4) == this.state.year || this.state.year.toLowerCase() === 'all' : quote.status.toLowerCase() === this.state.status.toLowerCase() || this.state.status.toLowerCase() === 'all'){
        //             filter2 = true
        //         }
        //         filter2 = true
        //         return filter1 && filter2
        //     })
        // }
        
        // this.setState(()=>{
        //     return{columns: columns, [name]: value}
        // })

    }
    openQuote = quote =>{   
        
        let userData = JSON.parse(sessionStorage.getItem('userData'))
            if (quote != 0) {
                let milestoneData
                const data1 = {
                    action: 'getQuoteCurrentMilestone',
                    current_milestone_template: quote.alternate_terms,
                    sbu: userData === null ? null : userData.strategic_business_unit,
                    userid: userData === null ? null : userData.userid,
                    quote_number: quote.quote_number,
                    //hug_schema: 'hug_horizons'
                }

                PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data1).then(
                    response => {
                        if (response.result && response.result !== 'false') {
                            milestoneData = response.quote_milestonesArr
                            const data2 = {
                                action: 'getQMDforQuote2',

                                quote_number: quote.quote_number,
                                //hug_schema: 'hug_horizons',
                                sbu:
                                    userData === null
                                        ? null
                                        : userData.strategic_business_unit,
                            }

                            PostData(
                                'Quotes/ajax/ajaxhugQuoteSystem2019.php',
                                data2
                            ).then(response2 => {
                                const data3 = {
                                    action: 'getStartupData2', //faster than getStartUpData, 2 just has less data
                                    crm_id: quote.crm_id,
                                    hug_schema: 'hug_' +(JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                                    strategic_business_unit: JSON.parse(
                                        sessionStorage.getItem('userData')
                                    ).strategic_business_unit,
                                }
                                PostData('CRM/ajax/ajaxCRM_2018.php', data3).then(response3 => {
                                    this.props.setFormData(
                                        {
                                            header: response2.qte_header,
                                            module_details: response2.Q_Modules_Details,
                                            milestoneData: milestoneData,
                                            crmData: response3.CRMdata,
                                            new: false
                                        },
                                        'Quotes'
                                    )
                                })
                                
                            })
                        }
                    }
                )
        }
    }

    handleSearchChange = e => {
        e.persist()
        this.setState(()=> {return {searchField: e.target.value}})
    }

    handleSearchClick = () =>{
        this.searchQuotes(this.state.searchField)
    }

    searchQuotes = searchVal =>{
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        const data = {
            action: 'searchQuoteForSBU',
            hug_schema: 'hug_'+ userData.strategic_business_unit.toLowerCase(),
            searchVal: searchVal,
        }

        PostData('Quotes/ajax/ajaxQuotesList_2018.php', data).then(response =>{
            let columns = response.resultSet.filter(quote => {
                return quote.status === 'OPEN'
            })

            columns.map(column => {
                column.close_prob = Number(column.close_prob)
            })

            this.setState(()=>{
                return{columns: columns, allQuotes: response.resultSet}
            })
        })
    }

    render() {
        const { classes } = this.props

        const rows = [
            {
                id: 'quote_number',
                numeric: false,
                disablePadding: true,
                label: 'Number',
            },
            {
                id: 'clientname',
                numeric: true,
                disablePadding: false,
                label: 'Company',
            },
            {
                id: 'name',
                numeric: true,
                disablePadding: false,
                label: 'Name',
            },
            {
                id: 'booking_type',
                numeric: true,
                disablePadding: false,
                label: 'Booking Type',
            },
            {
                id: 'status',
                numeric: true,
                disablePadding: false,
                label: 'Status',
            },
            {
                id: 'close_prob',
                numeric: true,
                disablePadding: false,
                label: 'Close Probability',
            },
            {
                id: 'quoteamount',
                numeric: true,
                disablePadding: false,
                label: 'Amount',
            },
            {
                id: 'quoteclosedate',
                numeric: true,
                disablePadding: false,
                label: 'Close Date',
            },
            {
                id: 'quotesentto',
                numeric: true,
                disablePadding: false,
                label: 'Sent To',
            },
        ]

        return (
            <EnhancedTable
                className={classes.secondary}
                rows={rows}
                columns={this.state.columns}
                showHeader={true}
                link={this.openQuote}
                toolbar={
                    <CustomToolbar
                        classes={classes}
                        handleSearchClick={this.handleSearchClick}
                        handleSearchChange={this.handleSearchChange}
                        handleFilterChange={this.handleFilterChange}
                        status={this.state.status}
                        dropdown={this.state.dropdown}
                        year={this.state.year}
                        type={this.state.type}
                        close_prob={this.state.close_prob}
                    />
                }
                tableRow={<CustomTableCells />}
            />
        )
    }
}

class CustomTableCells extends React.Component {
    // state = {
    //     close_prob: this.props.n.close_prob,
    //     quoteclosedate: this.props.n.quoteclosedate
    // }

    state = {
        close_prob: null,
        quoteclosedate: null,
    }

    changeDate = date => {
        let event = {
            target: {
                name: 'quoteclosedate',
                value: date
            }
        }

        this.handleChange(event)
    }

    handleChange = event => {
        this.setState({ [event.target.name] : event.target.value })
        let userData = JSON.parse(sessionStorage.getItem('userData'))

        let data = {
            action: 'update_Values',
            quote_number: this.props.n.quote_number,
            hug_schema: userData === null ? null : 'hug_' + userData.strategic_business_unit.toLowerCase(),
            column_name: event.target.name,
            column_value: event.target.value
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data)
    }

    toTitleCase = str => {
        if (str !== null) {
            str = str.replace(/_/g, ' ')
            return str.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
        }
    }
    
    stopClick = e => {
        e.preventDefault()
        e.stopPropagation()
    }

    render() {
        const n = this.props.n

        return (
            <React.Fragment >
                <TableCell padding="checkbox" />
                <TableCell style={{cursor: 'pointer'}} component="th" scope="row" padding={'default'}>
                    {n.quote_number}
                </TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">{n.clientname}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">{n.name}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">{this.toTitleCase(n.booking_type)}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">{this.toTitleCase(n.status)}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right" onClick={this.stopClick}>
                    <TextField
                        name='close_prob'
                        id='close_prob'
                        fullWidth
                        select
                        value={this.state.close_prob ? this.state.close_prob : n.close_prob}
                        onChange={this.handleChange}
                        InputProps={{
                            disableUnderline: true,
                        }}
                        label={false}
                    >
                        {CLOSE_PROBS.map(item =>(
                            <MenuItem value={item.item_name} key={item.item_name} >
                                {item.item_name}
                            </MenuItem>)
                        )}
                    </TextField>
                </TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">{n.quoteamount === null ? '0.00' : n.quoteamount}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right" onClick={this.stopClick}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            required
                            id="quotedate"
                            name="quotedate"
                            label={false}
                            variant="inline"
                            format="MM/dd/yyyy"
                            value={this.state.quoteclosedate ? this.state.quoteclosedate : n.quoteclosedate}
                            onChange={(date) => {this.changeDate(date)}}
                            fullWidth
                            InputLabelProps={{ shrink: true, disableUnderline: true }}
                        />
                    </MuiPickersUtilsProvider>
                </TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">{n.quotesentto}</TableCell>
            </React.Fragment>
        )
    }
}

class CustomToolbar extends React.Component {
    render() {
        let status = []
        let year = []
        // let types = [{item_name: 'All'}]

        this.props.dropdown && this.props.dropdown.forEach(dropdown =>{       
            if(dropdown.control_code === 'SORT_STATUS'){
                status.push(dropdown)
            }

            if(dropdown.control_code === 'SORT_YEAR' ){
                year.push(dropdown)
            }

            // if(dropdown.control_code === 'BOOKING_TYPE' ){
            //     types.push(dropdown)
            // }
        })

        const types = [
            'All',
            'New Named',
            'Internal',
        ]
        
        const { classes } = this.props
        return (
            <React.Fragment>
                <Grid spacing = {5} container>
                    <Grid item style={{ paddingTop: 5, marginTop: 35 }}>
                        <InputBase
                            className={this.props.classes.input}
                            placeholder={'Search'}
                            onChange={this.props.handleSearchChange}
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    this.props.handleSearchClick()
                                    ev.preventDefault()
                                }
                            }}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 10 }}>
                        <IconButton
                            className={this.props.classes.iconButton}
                            aria-label={'Search'}
                            onClick={
                                this.props.handleSearchClick
                            }
                        >
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1} xl={1} style={{marginTop: 5}}>
                        <TextField
                            value={this.props.status}
                            name='status'
                            id='status'
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            label="Quote Status"
                        >
                            {status && status.map(item =>(
                                <MenuItem value={item.item_name} key={item.item_name} >
                                    {item.item_name}
                                </MenuItem>)
                                )}
                        </TextField>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1} xl={1} style={{marginTop: 5}}>
                        <TextField
                            value={this.props.year}
                            name='year'
                            id='year'
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            label="Quote Year"
                        >
                            {year && year.map(item =>(
                                <MenuItem value={item.item_name} key={item.item_name} >
                                    {item.item_name}
                                </MenuItem>)
                                )}
                        </TextField>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1} xl={1} style={{marginTop: 5}}>
                        <TextField
                            value={this.props.type}
                            name='type'
                            id='type'
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            label="Booking Type"
                        >
                            {types && types.map(item =>(
                                <MenuItem value={item} key={item} >
                                    {item}
                                </MenuItem>)
                                )}
                        </TextField>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={1} xl={1} style={{marginTop: 5}}>
                        <TextField
                            value={this.props.close_prob}
                            name='close_prob'
                            id='close_prob'
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            label="Close Probability"
                        >
                            {CLOSE_PROBS.map(item =>(
                                <MenuItem value={item.item_name} key={item.item_name} >
                                    {item.item_name}
                                </MenuItem>)
                                )}
                        </TextField>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

Quotes.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Quotes)
