//This component will render a list and table like products and systems
//It takes props: 
//      - active: defaultTableName
//      - header: ['header1', header2] // max of 10 columns, is the column headers, stored in a basic array of strings
//      - data: [{tableName: 'string', column1: ''}, ... ]
//          -column names should all be the same and appear in header 
//      - selectorColumn: a string value of the name of the column in the data object that will represent the table name
//          -this value should be the same in every element of the same table so the component is able to identify what values should be where 





import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Grid from '@material-ui/core/Grid'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'

//Recursive function that checks if an item is in a list
//To call include list/array as first arg and item you want to find in the list as second element
//Third is only needed if you want to start at a different index than 0
function isInList(list,item, i=0){
    if(list.length === 0 || i >= list.length){
        return false
    }
    else if(list[i] === item){
        return true
    } else if(i+1 === item.length){
        return false
    }else{
        return isInList(list, item, i+1)
    }
    
}
const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },
    lowercase: {
        textTransform: 'none',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    add: {
        padding: 5,
        margin: 1,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    lines:{
        cursor:'pointer',
        backgroundColor: theme.palette.secondary.paper,
    },
    selected: {
        backgroundColor: 'rgb(230,230,230)',
        color: theme.palette.secondary.main.dark,
        fontWeight: '500',
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        //marginLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '98%',
        //background: 'rgb(248,248,248)',
    },
    list: {
        marginTop: theme.spacing(1),
        borderTop: '2px solid rgb(232,232,232)',
    },
    highlight: {
        cursor:'pointer',
    },
})

class TableWithSelector extends React.Component{
    constructor(props){
        super(props)
        this.state={
            active: this.props.active,

        }
    }
    getSelectorList = () =>{
        let selectorList = []
        let checkerList = []
        const { classes } = this.props
        for(let i = 0; i<this.props.data.length; i++){
            if(!isInList(checkerList, (this.props.data[i][this.props.selectorColumn]))){
                checkerList.push(this.props.data[i][this.props.selectorColumn])
                
                if (this.state.active == this.props.data[i][this.props.selectorColumn]) {
                    selectorList.push (
                        <ListItem
                            className={classes.selected}
                        >
                            {this.props.data[i][this.props.selectorColumn]}
                        </ListItem>
                    )
                } else {
                    selectorList.push (
                        <ListItem
                            className={classes.lines}
                            onClick={() => this.setState({active: this.props.data[i][this.props.selectorColumn]})}
                        >
                            {this.props.data[i][this.props.selectorColumn]}
                        </ListItem>
                    )
                }
            }
        }

        return selectorList
        
        
        

    }

    getTable = () =>{
        let values = this.props.data.filter((data) =>{
            return data[this.props.selectorColumn] === this.state.active
        })
        
        return values.map(row=>
            <TableRow>
                {Object.keys(row).map(cell => 
                cell !== this.props.selectorColumn &&
                    <TableCell>{row[cell]}</TableCell>)}
            </TableRow>
        )
    }


    render() {
        const { classes } = this.props

        return(
            <List className={classes.root}>
                <Grid container spacing={0}>
                    <Grid item xs={3}
                        style={{ maxHeight: 300, overflow: 'auto' }}>
                        <List disablePadding={true} className={classes.list}>{this.getSelectorList()}</List>
                    </Grid>
                    <Grid item xs={9}>
                        <Table className={classes.table} size="small" >
                            <TableHead>
                                <TableRow>
                                    {this.props.header.map(tableHeader=>
                                    <TableCell>{tableHeader}</TableCell>)}
                                    
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.getTable()}
                            </TableBody> 
                        </Table>
                    </Grid>
                </Grid>
            </List>
        )
    }
}

TableWithSelector.propTypes = {
    classes: PropTypes.object.isRequired,
    active: PropTypes.string.isRequired,
    header: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    selectorColumn: PropTypes.string.isRequired
}

export default withStyles(styles)(TableWithSelector)