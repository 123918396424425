import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import store from '../../store/Store'
import { setFormOpen } from '../../store/actions/Actions'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import TextField from '@material-ui/core/TextField'
import Icon from '@material-ui/core/Icon'

const styles = {
    appBar: {
        position: 'relative',
    },
    flex: {
        flex: 1,
    },
}

function Transition(props) {
    return <Slide direction="up" {...props} />
}

class FullScreenDialog extends React.Component {
    state = {
        open: false
    }

    handleClickOpen = () => {
        //store.dispatch(setFormOpen(true))
        this.setState({open: true})
    }

    handleClose = () => {
        //store.dispatch(setFormOpen(false))
        this.setState({open: false})
    }

    handleSave = () => {
        this.props.handleSave()
        //store.dispatch(setFormOpen(false))
        this.setState({open: false})
    }

    render() {
        const { classes } = this.props
        let open = this.state.open
        let icon = (this.props.icon === undefined) ? 'add' : this.props.icon

        return (
            <div>
                <Tooltip title={this.props.buttonText}>
                    <Button
                        aria-label={this.props.buttonText}
                        onClick={this.handleClickOpen}
                    >
                        <Icon>{icon}</Icon>
                        {this.props.buttonText}
                    </Button>
                </Tooltip>

                <Dialog
                    //fullScreen
                    fullWidth
                    maxWidth="lg"
                    open={open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            onClick={this.handleClose}
                            aria-label="Close"
                        >
                            <CloseIcon />
                        </IconButton>
                        <div className={classes.flex} />
                        <Button color="inherit" onClick={this.handleSave}>
                            save
                        </Button>
                    </Toolbar>
                </AppBar>

                {this.props.children}
                </Dialog>
            </div>
        )
    }
}

FullScreenDialog.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FullScreenDialog)
