import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {SortableElement} from 'react-sortable-hoc'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import {
    faTimes,
    faEdit,
    faTrash,
    faPlus,
    faCheck,
    faBars
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const styles = theme => ({
    textField: {
        margin: 1,
        marginLeft: 5,
        paddingBottom: 5,
        paddingTop: 5,
        paddingLeft: 3,
        paddingRight: 5,
        textTransform: 'capitalize',
        marginRight: 5,
        marginTop: 10,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
    buttons: {
        marginLeft: 2,
    },
    text: {
        textTransform: 'none'
    },
    list:{
        padding: 0,
        margin: 0,
    }
})

const SortableListContainer = SortableElement((props) => {
    const { classes } = props
    let value = props.listItem.name;
    return (<List className={classes.list}>
                <Divider/>
                
        {props.listItem.active ? (
            
                <ListItem>
                <TextField
                    className={classes.text}
                    name={props.listItem.name}
                    id={props.index}
                    label={props.listItem.name}
                    value={value}
                    onChange={(e) => props.handleTextEdit(props.index, e.target.value)}
                    fullWidth
                    onKeyPress={ev => {
                        if (ev.key === 'Enter') {
                            props.handleEditSave(props.index)
                            ev.preventDefault()
                        }
                    }}
                />
                <ListItemIcon>
                    <IconButton
                        onClick={(e) => { e.preventDefault(); props.handleEditToggle(props.index)}}
                        id={props.index}
                    >
                        <FontAwesomeIcon icon={faTimes} />
                    </IconButton>
                </ListItemIcon>
                <ListItemIcon>
                    <IconButton
                        onClick={() => props.handleEditSave(props.index)}
                    >
                        <FontAwesomeIcon icon={faCheck} />
                    </IconButton>
                </ListItemIcon>
                
            </ListItem>
            
            
        ) : (
            
            <ListItem className={classes.text}>
                
                <ListItemText
                    primary={value}
                />
                <ListItemIcon>
                    <IconButton
                        id={props.index}
                        className={classes.buttons}
                        name={value}
                        onClick={() => props.handleEditToggle(props.index)}
                    >
                        <FontAwesomeIcon icon={faEdit} />
                    </IconButton>
                </ListItemIcon>
                <ListItemIcon>
                    <IconButton
                        className={classes.buttons}
                        onClick={(e) =>{ e.preventDefault();
                            props.toggleDialog(
                                props.listItem.name
                            )
                        }}
                        id={value}
                    >
                        <FontAwesomeIcon icon={faTrash} />
                    </IconButton>
                </ListItemIcon>
                
            </ListItem>
            
        )}
        <Divider />
        {props.listItems[props.listItems.length -1].id === props.index && <Divider/>}
    </List>)
})
SortableListContainer.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SortableListContainer)
