import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { withStyles } from '@material-ui/core/styles'
import TypoGraphy from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import ToolTip from '@material-ui/core/ToolTip'
import Modal from '@material-ui/core/Modal'
import SaveWarning from '../../Reusable/SaveWarning'
import {
    List,
    ListItemIcon,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core'
import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { PostData } from '../../../services/PostData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCog, faCheck } from '@fortawesome/free-solid-svg-icons'
import { faTimesOctagon } from '@fortawesome/pro-solid-svg-icons'
import { addValidators, validate } from '../../../utils/Validate'


const styles = theme => ({
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    root: {
        display: 'flex',
    },
    list: {
        paddingBottom: 0,
        marginBottom: 0,
    },
    listItem: {
        paddingVerical: 0,
    },
    listIcon: {
        paddingRight: 5,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    paper: {
        marginRight: theme.spacing.unit * 6,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    avatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: '#605070',
    },
    phoneNumber: {
        fontSize: 16,
    },
    textField: {
        margin: 4,
        marginTop: 0,
        paddingTop: 5,
        marginBottom: 7,
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    buttonText: {
        textTransform: 'none',
    },
})

class ChangePassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            oldWrong: true,
            new1: '',
            old: '',
            new2: '',
            passEqual: false,
            passLength: false,
            passUpper: false,
            passLower: false,
            passNum: false,
        }
        //this.changePassword = this.changePassword.bind(this)
    }
    componentWillMount() {
        addValidators(this, {
            old: 'required',
            new1: 'required',
            new2: 'required',
        })
    }

    handlePassChange = e => {
        if (e.target.name === 'old') {
            this.setState({ [e.target.name]: e.target.value })
            this.verifyOldPass(e.target.value)
        } else if (e.target.name === 'new1') {
            this.checkPass(e.target.value, 'new1')
        } else {
            this.checkPass(e.target.value, 'new2')
        }
    }
    verifyOldPass = value => {
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        if (userData && this.state.old) {
            let data = {
                action: 'check_pwd',
                user_id: userData.userid,
                password: value,
            }

            PostData('ajax/ajaxHUGlogin.php', data).then(response => {
                if (response.result) {
                    console.log('true')
                    this.setState({ oldWrong: false })
                } else {
                    console.log('false')
                    this.setState({ oldWrong: true })
                }
            })
        }
    }
    checkPass = (value, x) => {
        let newState = this.state
        newState[x] = value

        if (x === 'new1') {
            let passNum = false
            let passUpper = false
            let passLower = false
            if (newState.new1.length >= 8) {
                newState.passLength = true
            } else {
                newState.passLength = false
            }

            for (let i = 0; i < newState.new1.length; i++) {
                if (!isNaN(newState.new1.charAt(i))) {
                    passNum = true
                }
                if (
                    newState.new1.charAt(i).toUpperCase() ===
                    newState.new1.charAt(i)
                ) {
                    passUpper = true
                }
                if (
                    newState.new1.charAt(i).toLowerCase() ===
                    newState.new1.charAt(i)
                ) {
                    passLower = true
                }
            }
            newState.passUpper = passUpper
            newState.passLower = passLower
            newState.passNum = passNum
        }
        if (newState.new1 === newState.new2 && newState.new1.length > 0) {
            newState.passEqual = true
        } else {
            newState.passEqual = false
        }

        this.setState(newState)
    }
    go = () => {
        if (validate(this)) {
            if (
                !this.state.oldWrong &&
                this.state.passLength &&
                this.state.passNum &&
                this.state.passUpper &&
                this.state.passLower &&
                this.state.passEqual
            ) {
                const data = {
                    new_password: this.state.new1,
                    user_id: JSON.parse(sessionStorage.getItem('userData'))
                        .userid,
                    action: 'admin_reset_pswd',
                }
                PostData('ajax/ajaxHUGlogin.php', data).then(response => {
                    if (response.result) {
                        this.setState({ changed: true })
                    } else {
                        this.setState({
                            changeError: true,
                            errMsg: response.SqlUpdateScript,
                        })
                    }
                })
            } else {
                this.props.invalid()
            }
        } else {
            this.props.invalid()
        }
    }

    render() {
        const { classes } = this.props
        return (
            
            
                <div>
                    <Grid container xs={12} direction="column" spacing={1}>
                        <Grid item xs={12}>
                            <List dense className={classes.list}>
                                <ListItem>
                                    <ListItemText
                                        primary={
                                            <List>
                                                <ListItemText primary="Password Verification" />
                                            </List>
                                        }
                                        secondary={
                                            <List>
                                                <ListItem
                                                    dense
                                                    className={classes.listItem}
                                                >
                                                    <ListItemIcon
                                                        className={
                                                            classes.listIcon
                                                        }
                                                    >
                                                        {this.state.oldWrong ? (
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faTimesOctagon
                                                                }
                                                                color={'red'}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                color={'green'}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            'Old Password must be correct'
                                                        }
                                                    />
                                                </ListItem>
                                                <ListItem dense>
                                                    <ListItemIcon>
                                                        {!this.state
                                                            .passLength ? (
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faTimesOctagon
                                                                }
                                                                color={'red'}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                color={'green'}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            'Password must contain more than 8 characters'
                                                        }
                                                    />
                                                </ListItem>

                                                <ListItem dense>
                                                    <ListItemIcon>
                                                        {!this.state.passNum ? (
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faTimesOctagon
                                                                }
                                                                color={'red'}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                color={'green'}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            'Password must contain a Number'
                                                        }
                                                    />
                                                </ListItem>

                                                <ListItem dense>
                                                    <ListItemIcon>
                                                        {!this.state
                                                            .passUpper ? (
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faTimesOctagon
                                                                }
                                                                color={'red'}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                color={'green'}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            'Password must contain an uppercase character'
                                                        }
                                                    />
                                                </ListItem>

                                                <ListItem dense>
                                                    <ListItemIcon>
                                                        {!this.state
                                                            .passLower ? (
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faTimesOctagon
                                                                }
                                                                color={'red'}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                color={'green'}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            'Password must contain a lowercase character'
                                                        }
                                                    />
                                                </ListItem>

                                                <ListItem dense>
                                                    <ListItemIcon>
                                                        {!this.state
                                                            .passEqual ? (
                                                            <FontAwesomeIcon
                                                                icon={
                                                                    faTimesOctagon
                                                                }
                                                                color={'red'}
                                                            />
                                                        ) : (
                                                            <FontAwesomeIcon
                                                                icon={faCheck}
                                                                color={'green'}
                                                            />
                                                        )}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={
                                                            'Passwords must be equal'
                                                        }
                                                    />
                                                </ListItem>
                                            </List>
                                        }
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid spacing={5} style={{paddingLeft: 5}} item xs={12}>
                            <TextField
                                name="old"
                                id={0}
                                label={'Old Password'}
                                value={this.state.old}
                                type="password"
                                required
                                fullWidth
                                onChange={this.handlePassChange}
                                style={{
                                    padding: 5,
                                    marginHorizontal: 10,
                                }}
                                error={
                                    this.state.invalid &&
                                    (!this.state.old || this.state.oldWrong)
                                }
                                helperText={
                                    this.state.invalid &&
                                    (!this.state.old || this.state.oldWrong)
                                        ? 'Error: Wrong Password'
                                        : 'Please enter your old password'
                                }
                            />
                        </Grid>
                        <Grid style={{paddingLeft: 5}}item xs={12}>
                            <TextField
                                name="new1"
                                id={1}
                                label={'New Password'}
                                value={this.state.new1}
                                type="password"
                                required
                                fullWidth
                                onChange={this.handlePassChange}
                                style={{
                                    padding: 5,
                                    marginHorizontal: 10,
                                }}
                                error={this.state.invalid && !this.state.new1}
                                helperText={
                                    this.state.invalid &&
                                    !this.state.new1 &&
                                    'Please Enter Your new Password '
                                }
                            />
                        </Grid>
                        <Grid style={{paddingLeft: 5}} item xs={12}>
                            <TextField
                            
                                name="new2"
                                id={1}
                                label={'Re-Enter Password'}
                                value={this.state.new2}
                                type="password"
                                required
                                fullWidth
                                onChange={this.handlePassChange}
                                style={{
                                    padding: 5,
                                    marginHorizontal: 10,
                                }}
                            />
                        </Grid>

                        <Grid container direction="row">
                            <div
                                style={{
                                    marginLeft: 'auto',
                                    marginBottom: 5,
                                    marginTop: 10,
                                    marginRight: 5,
                                }}
                            >
                                <Button
                                    style={{ marginBottom: 5 }}
                                    onClick={this.go}
                                >
                                    <TypoGraphy>Go</TypoGraphy>
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                
                <Dialog
                    open={this.state.changed}
                    aria-labelledby="title"
                    aria-describedby="description"
                >
                    <DialogTitle id="title">{'Success'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="description">
                            Password Changed Successfully
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({ changed: false })
                                this.props.handleFormClose()
                            }}
                            className={classes.cardButtons}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.changeError}
                    aria-labelledby="error"
                    aria-describedby="description"
                >
                    <DialogTitle id="">
                        <TypoGraphy>Error: Password Change Failed</TypoGraphy>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="description">
                            <TypoGraphy>{this.state.errMsg}</TypoGraphy>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() =>
                                this.setState({
                                    changeError: false,
                                    errMsg: '',
                                })
                            }
                            className={classes.cardButtons}
                            autoFocus
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

ChangePassword.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ChangePassword)
