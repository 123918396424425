import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, MenuItem } from '@material-ui/core'

export default class MenuOptions extends React.Component {
    state = {
        selected: null
    }

    selectOption = event => {
        let value = event.target.value
        // this.setState({ selected: value })
        this.props.closeMenuOptions()

        const url = process.env.REACT_APP_API_URL+this.props.optionsLink+'?'+this.props.optionName+'='+value
        let tab = window.open(url, '_blank')
        tab.focus()
    }

    render() {        
        return(
            <Dialog open={this.props.open} aria-labelledby="form-dialog-title" fullWidth maxWidth={this.props.size ? this.props.size : 'xs'}>
                <DialogTitle id="form-dialog-title">Menu Options</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={this.props.label}
                        select
                        fullWidth
                        value={this.state.selected}
                        onChange={this.selectOption}
                    >
                        {Object.keys(this.props.options).map(item =>(
                            <MenuItem value={this.props.options[item]} key={item} >
                                {item}
                            </MenuItem>)
                        )}
                    </TextField>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.props.closeMenuOptions} color="primary">
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>  
        )
    }
}