import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

const styles = theme => ({
    textField: {
        marginLeft: 10,
        marginRight: theme.spacing.unit,
        paddingLeft: 20,
    },
})

class MainDrawerSearchDropdown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            suggestions: [],
            prevList: {}
        }
    }

    componentWillReceiveProps(nextProps) {
            
            let suggestions = []
            let iterator = 10
            if(nextProps.list.length < 10){
                iterator = nextProps.list.length
            }
            for (let i = 0; i < iterator; i++) { //! nextProps.list.length
                const newSuggestion = {
                    company: nextProps.list[i].company,
                    crm_id: nextProps.list[i].crm_id,
                }
                if (i === 0) {
                    suggestions.push(newSuggestion)
                    continue
                }

                if (suggestions[suggestions.length - 1].crm_id !== newSuggestion.crm_id) {
                    suggestions.push(newSuggestion)
                }
            }

            this.setState({ list: suggestions })   
    }

    render() {
        return (
            <MenuList>
                {this.state.list.map(item => (
                    <MenuItem
                        key={item.company}
                        onClick={this.props.handleMenuItemClick}
                        data-value={item.company}
                        data-id={item.crm_id}
                    >
                        {item.company}
                    </MenuItem>
                ))}
            </MenuList>
        )
    }
}

MainDrawerSearchDropdown.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MainDrawerSearchDropdown)