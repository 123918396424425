import React from 'react'
import PropTypes from 'prop-types'

import { PostData } from '../../../services/PostData'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import {
    faChevronDown,
    faChevronUp,
    faMapMarkedAlt,
    faBurn,
} from '@fortawesome/pro-light-svg-icons'

import DateFnsUtils from '@date-io/date-fns'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import FormWrapper from '../../Reusable/FormWrapper'
import NumberFormat from 'react-number-format'
import SwipeableTabs from '../../Reusable/SwipeableTabs'
import Notes from '../Notes/Notes'
import NoteForm from '../Notes/NoteForm'
import ProductRecap from '../Products/ProductRecap'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import IconButton from '@material-ui/core/IconButton'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { faArrowAltRight } from '@fortawesome/pro-light-svg-icons'
import Tooltip from '@material-ui/core/Tooltip'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import Saas from './Saas'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    buttons: {
        color: 'white',
        backgroundColor: theme.palette.secondary.main,
        marginTop: 4,
        marginBottom: 4,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '98%',
        background: 'rgb(248,248,248)',
    },
    message: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        backgroundColor: '#ffe6e6',
    },
    buttons: {
        color: 'white',
        backgroundColor: theme.palette.primary.dark,
        marginTop: 4,
        marginBottom: 4,
    },
})

class WorkOrdersForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            harrisPersonnel: {},
            WOdetails: {},
            WOcost: {},
            dropdown: [],
            product_details:[],
            recap: {},
            milestoneData: [],
            newMilestone: {},
            addMilestone: false,
            RevRecData: [],
            newRevRec: {},
            addRev: false,
            editRev: false,
            revRecComplete: false,
            quote: {},
        }

    }

    componentWillMount() {
        this.getProducts()
        this.getRecap()
        this.getQuote()

        if (this.props.formData !== undefined) {
            let WOdetails = this.props.formData.WOdetails.woArray[0]
            if (WOdetails.name === '' || WOdetails.name === null || WOdetails.name === undefined) {
                WOdetails.name = WOdetails.quote_name
            }
            this.setState({
                WOdetails: WOdetails,
            })
            this.setState({
                WOcost: this.props.formData.WOdetails.woItemsArray[0],
            })
            this.setState({
                harrisPersonnel: this.props.formData.WOdetails
                    .harris_sbu_personnel,
            })
            if(this.props.formData.WOdetails.milestones !== null ){
                this.setState({
                    milestoneData: this.props.formData.WOdetails.milestones[this.props.formData.num],
                })
            }
            if(this.props.formData.WOdetails.rev_recog !== null && this.props.formData.WOdetails.rev_recog !== undefined){
                this.setState({ RevRecData: this.props.formData.WOdetails.rev_recog }, () => {
                    this.setRevRecTotals()
                })
            }

            this.getDropdown()
        }
    }

    // Get quote data
    getQuote = () => {
        const data = {
            action: 'getQuoteHeaderRecord',
            crm_id: this.props.crmData.crm_id,
            quote_number: this.props.formData.WOdetails.woArray[0].quote_number,
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            hug_schema: 'hug_' + JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase(),
        }

        PostData(
            'Quotes/ajax/ajaxhugQuoteSystem2019.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ quote: response.QuotesHeader })
            }
        })
    }

    getRevRecTotals = () => {
        let revRecTotals = {
            milestone_pct: 0,
            milestone_professional_services_hours: 0,
            milestone_professional_services: 0,
            milestone_license_fee: 0,
            recognized_hours: 0,
            recognition_amount_professional_services: 0,
            recognition_amount_license: 0, 
        }

        if (this.state.milestoneData) {
            this.state.milestoneData.forEach(mile => {
                if(mile.active_flag !== 'f' && mile.active_flag !== false && mile.active_flag !== 0) {
                    revRecTotals['milestone_pct'] += Number(mile.milestone_pct)
                    revRecTotals['milestone_professional_services_hours'] += Number(mile.milestone_professional_services_hours)
                    revRecTotals['milestone_professional_services'] += Number(mile.milestone_professional_services)
                    revRecTotals['milestone_license_fee'] += Number(mile.milestone_license_fee)
                }
            })
        }

        if (this.state.RevRecData) {
            this.state.RevRecData.forEach(rec =>{
                revRecTotals['recognized_hours'] += Number(rec.recognized_hours)
                revRecTotals['recognition_amount_professional_services'] += Number(rec.recognition_amount_professional_services)
                revRecTotals['recognition_amount_license'] += Number(rec.recognition_amount_license)
            })
        }

        return revRecTotals
    }

    setRevRecTotals = () => {
        let revRecTotals = this.getRevRecTotals()
        let milestonePaymentTotal = revRecTotals.milestone_professional_services + revRecTotals.milestone_license_fee
        let revRecPaymentTotal = revRecTotals.recognition_amount_professional_services + revRecTotals.recognition_amount_license

        if (milestonePaymentTotal > 0) {
            if (milestonePaymentTotal - revRecPaymentTotal <= 0) {
                this.setState({ revRecComplete: true })
            }
        } 

        this.setState({ revRecTotals: revRecTotals })
    }

    //Saves all data in form
    handleSave = () => {
        this.handleWOdetailsSave()
        this.handleMilestoneSave()
        return true
    }

    handleClose = () => {
        this.props.handleFormClose(true)
    }

    closeWorkOrder = () => {
        let WOdetails = this.state.WOdetails
        WOdetails.wo_status = 'CLOSED'
        this.setState({ WOdetails: WOdetails }, () => {
            this.handleSave()
            this.handleClose()
        })
    }

    //Gets the products associated with this work order
    getProducts = () =>{
        const data = {
            action: 'getQMDforQuote2',
            quote_number: this.props.formData.WOdetails.woArray[0].quote_number,
            sbu:
            JSON.parse(sessionStorage.getItem('userData'))
            .strategic_business_unit,
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php',data).then(
            response => {
                if (response.result) {
                    this.setState({ product_details: response.Q_Modules_Details })
                }

        })
    }

    //Gets the data for the product recap section
    getRecap =() =>{
        const data = {
            action: 'getQuoteRecapSection',
            current_milestone_template: '',
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
            quote_number: this.props.formData.WOdetails.woArray[0].quote_number,
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ recap: response.quoteRecapArr })
                }
            }
        )
    }

    //Gets dropdown data
    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'WORK_ORDER',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }

    //Converts true and false to Y and N for the database and reports
    YesNo = input => {
        if (input === 'Y') {
            return true
        } else if (input === 'N') {
            return false
        } else if (input === true) {
            return 'Y'
        } else if (input === false) {
            return 'N'
        } else if (input === null) {
            return false
        }
    }


    
    //////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////
    //Header
    //////////////////////////////////////////////////////////////////////////////////////////////////

    //handles number changes
    handleWOdetailsNumChange = event => {
        let value = event.target.value.replace(/[,$]/g , '')
        if(isNaN(value) ){
            return
        }
        let change = this.state.WOdetails
        change[event.target.name] = value
        this.setState({ WOdetails: change })
    }

    //handles text changes
    handleWOdetailsChange = event => {
        let change = this.state.WOdetails
        change[event.target.name] = event.target.value
        this.setState({ WOdetails: change })
    }

    //handles checkbox changes
    handleCChange = event => {
        let change = this.state.WOdetails
        change[event.target.id] = this.YesNo(event.target.checked)
        this.setState({ WOdetails: change })
    }

    //handles date changes
    handleDateChange = (date, field) => {
        let change = this.state.WOdetails
        change[field] = date
        this.setState({ WOdetails: change })
    }

    //Saves all header fields
    handleWOdetailsSave = () => {
        for (let key in this.state.WOdetails) {
            let value = this.state.WOdetails[key]
            let type = typeof value

            const data = {
                action: 'updateColumnValue',
                table_name: 'work_orders',
                column_sql_type: type,
                column_name: key,
                column_value: value,
                qualifier: 'work_order_no = ' + this.state.WOdetails.work_order_no,
                //hug_schema: 'hug_horizons'
                sbu: JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,
            }

            PostData('Production/ajax/ajaxWORK_ORDER_details.php', data).then(
                response => {
                    this.props.updateComponent('WorkOrders')
                }
            )
        }
    }

    //Creates the dropdowns
    viewWOdetailsDropdown = (control, id, name) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })

        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                label={name}
                className={classes.textField}
                onChange={this.handleWOdetailsChange}
                value={this.state.WOdetails[id]}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    //Creates the harris personnel dropdown
    personnelDropdown = (id, name) => {
        let arr = []
        for (let key in this.state.harrisPersonnel) {
            arr.push({
                name: this.state.harrisPersonnel[key].name,
                id: this.state.harrisPersonnel[key].userid,
            })
        }
        
        const { classes } = this.props
        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                label={name}
                className={classes.textField}
                onChange={this.handleWOdetailsChange}
                value={this.state.WOdetails[id]}
            >
                {arr.map(person => (
                    <MenuItem key={person.id} value={person.id}>
                        {person.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    //Shows the Overview Tab
    showHeader = () => {
        const { classes } = this.props
        return (
            <Grid container className={classes.outside}>
                    <Grid container spacing={1} className={classes.body}>
                        <Grid item xs={12} sm={12}>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                            >
                                General
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                id="name"
                                name="name"
                                label="Name"
                                fullWidth
                                className={classes.textField}
                                value={this.state.WOdetails.name}
                                onChange={this.handleWOdetailsChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {this.viewWOdetailsDropdown(
                                'ORDER_STATUS',
                                'wo_status',
                                'WO Status'
                            )}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            {this.viewWOdetailsDropdown(
                                'BOOKING_TYPE',
                                'booking_type',
                                'Booking Type'
                            )}
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    id="work_order_date"
                                    name="work_order_date"
                                    label="Order Date"
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    className={classes.textField}
                                    value={this.state.WOdetails.work_order_date}
                                    onChange={(date) => {this.handleDateChange(date, 'work_order_date')}}
                                    fullWidth
                                    InputLabelProps={{ shrink: true }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <TextField
                                disabled
                                id="wo_entered_by"
                                name="wo_entered_by"
                                label="Created By"
                                fullWidth
                                className={classes.textField}
                                value={this.state.WOdetails.wo_entered_by}
                            />
                        </Grid>
  
                        <Grid item xs={12} sm={12}>
                            <TextField
                                id="wo_description"
                                name="wo_description"
                                label="Description"
                                fullWidth
                                className={classes.textField}
                                value={this.state.WOdetails.wo_description}
                                onChange={this.handleWOdetailsChange}
                                multiline
                                rows="2"
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={6} sm={2} md={1}>
                            <TextField
                                disabled
                                id="quote_number"
                                name="quote_number"
                                label="Quote"
                                fullWidth
                                className={classes.textField}
                                value={this.state.WOdetails.quote_number}
                                
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                disabled
                                id="quotesentto"
                                name="quotesentto"
                                label="Quote Sent to"
                                fullWidth
                                className={classes.textField}
                                value={this.state.WOdetails.quotesentto}
                                
                            />
                        </Grid>
                        <Grid item xs={6} sm={2}>
                            <TextField
                                disabled
                                id="purchase_order"
                                name="purchase_order"
                                label="PO"
                                fullWidth
                                className={classes.textField}
                                value={this.state.WOdetails.purchase_order}
                                
                            />
                        </Grid>
                        <Grid item xs={12} sm={2}>
                            <NumberFormat 
                                disabled
                                id="wo_amount"
                                name="wo_amount"
                                label="Work Order Amount"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField} 
                                value={this.state.WOdetails.wo_amount}
                                onChange={this.handleWOdetailsNumChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.00}
                                allowEmptyFormatting={true}
                                
                                /> 
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <FormControlLabel
                                className={classes.textField}
                                control={
                                    <Checkbox
                                        defaultChecked={this.YesNo(
                                            this.state.WOdetails.invoiced
                                        )}
                                        id="invoiced"
                                        onClick={this.handleCChange}
                                    />
                                }
                                label="Invoiced"
                            />
                        </Grid>
                        <Grid item xs={12} sm={2} md={2}>
                            <FormControlLabel
                                className={classes.textField}
                                control={
                                    <Checkbox
                                        defaultChecked={this.YesNo(
                                            this.state.WOdetails.billable
                                        )}
                                        id="billable"
                                        onClick={this.handleCChange}
                                    />
                                }
                                label="Billable"
                            />
                        </Grid>

                        <Grid item xs={12} sm={3}>
                            {this.personnelDropdown(
                                'project_manager',
                                'Assigned To/Manager'
                            )}
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <TextField
                                disabled
                                id="referred_by"
                                name="referred_by"
                                label="Referred By"
                                fullWidth
                                className={classes.textField}
                                value={this.state.WOdetails.referred_by}
                            />
                        </Grid>

                    </Grid>
            </Grid>
        )
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //Payment Plan
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    handleMilestoneChange = (milestone, event) => {
        if (
            this.state.WOdetails.status != 'WON' &&
            this.state.WOdetails.status != 'LOST' &&
            this.state.WOdetails.status != 'CLOSED'
        ) {
            console.log('change')
            let id
            if(event.target.name !== 'active_flag' ){
                id = event.target.id
            }
            else{
                id = milestone.recordid
            }
            let index = this.state.milestoneData.findIndex(
                element => element.recordid == id
            )
            let change = this.state.milestoneData
            change[index][event.target.name] = event.target.value
            this.setState({ milestoneData: change })

        }
    }
    handleMilestoneNumChange = event => {
        if (
            this.state.WOdetails.status != 'WON' &&
            this.state.WOdetails.status != 'LOST' &&
            this.state.WOdetails.status != 'CLOSED'
        ) {
            let index = this.state.milestoneData.findIndex(
                element => element.recordid == event.target.id
            )

            let value = event.target.value.replace(/[,$%]/g, '')
            if (isNaN(value)) {
                return
            }
            let change = this.state.milestoneData
            change[index][event.target.name] = value
            this.setState({ milestoneData: change })
        }
    }

    handleNewMilestoneChange = event => {
        let change = this.state.newMilestone
        change[event.target.name] = event.target.value
        this.setState({ newMilestone: change })
    }
    
    handleNewMilestoneNumChange = event => {
        let value = event.target.value.replace(/[,$%]/g, '')
        if (isNaN(value)) {
            return
        }
        let change = this.state.newMilestone
        change[event.target.name] = value
        this.setState({ newMilestone: change })
    }

    handleAddMilestoneBool = () => {
        this.setState({ addMilestone: false })
    }

    handleMilestoneDateChange = (date, recordid) => {
        if (
            this.state.WOdetails.status != 'WON' &&
            this.state.WOdetails.status != 'LOST' &&
            this.state.WOdetails.status != 'CLOSED'
        ) {
            let index = this.state.milestoneData.findIndex(
                element => element.recordid == recordid
            )

            let milestones = this.state.milestoneData
            milestones[index].milestone_date = date
            this.setState({ milestoneData: milestones })
        }
    }

    handleMilestoneSave = () => {
        this.state.milestoneData.forEach(milestone =>{
            const data = {
                action: 'update_WO_milestone',
                recordid: milestone.recordid,
                hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                userid: JSON.parse(sessionStorage.getItem('userData')).userid,
                strategic_business_unit: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
                work_order_no: this.state.WOdetails.work_order_no,
                report_sequence: null,
                milestone_type: milestone.milestone_type,
                milestone_desc: milestone.milestone_desc,
                milestone_date: milestone.milestone_date,
                milestone_license_fee: milestone.milestone_license_fee,
                milestone_professional_services: milestone.milestone_professional_services,
                milestone_professional_services_hours: milestone.milestone_professional_services_hours,
                milestone_pct: milestone.milestone_pct,
                percent_or_dollar_amount_calculation: 'percent',
                active_flag: milestone.active_flag,
            }

            PostData('Production/ajax/ajaxWORK_ORDER_details.php', data).then(
                response => {
                    if (response.result) {
                    
                    }
                }
            )
        })
    }

    addMilestone = () => {
        this.handleMilestoneSave()
        const data = {
            action: 'insert_new_milestone_record',
            hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
            userid: JSON.parse(sessionStorage.getItem('userData')).userid,
            strategic_business_unit: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            work_order_no: this.state.WOdetails.work_order_no,
            report_sequence: null,
            milestone_type: this.state.newMilestone.milestone_type,
            milestone_desc: this.state.newMilestone.milestone_desc,
            milestone_license_fee: this.state.newMilestone.milestone_license_fee ||0,
            milestone_professional_services: this.state.newMilestone.milestone_professional_services ||0,
            milestone_professional_services_hours: this.state.newMilestone.milestone_professional_services_hours ||0,
            milestone_pct: this.state.newMilestone.milestone_pct ||0,
            percent_or_dollar_amount_calculation: 'percent',
            active_flag: '1',
            
        }

        PostData('Production/ajax/ajaxWORK_ORDER_details.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ addMilestone: false })
                    this.setState({ newMilestone: {} })

                    const data = {
                        action: 'get_WO_milestones',
                        hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                        sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
                        work_order_no: this.state.WOdetails.work_order_no,
                        
                    }
            
                    PostData('Production/ajax/ajaxWORK_ORDER_details.php', data).then(
                        response => {
                            if (response.result) {
                                this.setState({ milestoneData: response.quote_milestonesArr })

                            }
                        }
                    )
                }
            }
        )

    }

    viewMilestoneDropdown = (control, milestone, name, label) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })

        return (
            <TextField
                select
                fullWidth
                id={milestone.milestone_type}
                name={name}
                label={label}
                className={classes.textField}
                onChange={(e) =>this.handleMilestoneChange(milestone, e)}
                value={milestone[name]}
                
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    viewNewMilestoneDropdown = (control, id, name) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })

        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                label={name}
                className={classes.textField}
                onChange={this.handleNewMilestoneChange}
                value={this.state.newMilestone[id]}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    showAddMilestone = () => {
        const { classes } = this.props
        if (this.state.addMilestone === true) {
            return (
                <Card>
                    <div className={classes.secondary}>
                        <Grid container>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                className={classes.textField}
                            >
                                Add Payment
                            </Typography>
                            <Grid item className={classes.buttonGrid}>
                                <Button
                                    color="primary"
                                    className={classes.add}
                                    onClick={this.addMilestone}
                                >
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        size="2x"
                                        color="white"
                                    />
                                </Button>

                                <Button onClick={this.handleAddMilestoneBool}>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        size="2x"
                                        color="white"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={1} className={classes.outside}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="milestone_type"
                                name="milestone_type"
                                label="Payment Name"
                                className={classes.textField}
                                fullWidth
                                value={this.state.newMilestone.milestone_type}
                                onChange={this.handleNewMilestoneChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <NumberFormat
                                required
                                id="order_sequence"
                                name="order_sequence"
                                label="Order"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.newMilestone.order_sequence}
                                onChange={this.handleNewMilestoneNumChange}
                                thousandSeparator={true}
                                decimalScale={0}
                                fixedDecimalScale={true}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={12}>
                            <TextField
                                multiline
                                rows="2"
                                variant="outlined"
                                
                                id="milestone_desc"
                                name="milestone_desc"
                                label="Description"
                                fullWidth
                                className={classes.textField}
                                value={this.state.newMilestone.milestone_desc}
                                onChange={this.handleNewMilestoneChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={1}>
                            <NumberFormat
                                required
                                id="milestone_pct"
                                name="milestone_pct"
                                label="Percentage"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={
                                    this.state.newMilestone.milestone_pct
                                }
                                onChange={this.handleNewMilestoneNumChange}
                                thousandSeparator={true}
                                decimalScale={0}
                                suffix={'%'}
                                fixedDecimalScale={true}
                                defaultValue={0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <NumberFormat
                                
                                id="milestone_license_fee"
                                name="milestone_license_fee"
                                label="License Fee"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={
                                    this.state.newMilestone
                                        .milestone_license_fee
                                }
                                onChange={this.handleNewMilestoneNumChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        
                        <Grid item xs={12} sm={4}>
                            <NumberFormat
                                
                                id="milestone_professional_services_hours"
                                name="milestone_professional_services_hours"
                                label="Professional Services Hours"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={
                                    this.state.newMilestone
                                        .milestone_professional_services_hours
                                }
                                onChange={this.handleNewMilestoneNumChange}
                                thousandSeparator={true}
                                decimalScale={0}
                                fixedDecimalScale={true}
                                defaultValue={0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <NumberFormat
                                
                                id="milestone_professional_services"
                                name="milestone_professional_services"
                                label="Professional Services Cost"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={
                                    this.state.newMilestone
                                        .milestone_professional_services
                                }
                                onChange={this.handleNewMilestoneNumChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                fixedDecimalScale={true}
                                defaultValue={0.0}
                                allowEmptyFormatting={true}
                            />
                        </Grid>
                        
                    </Grid>
                </Card>
            )
        }
    }

    getMilestoneTotals = (id, id2) => {
        const { classes } = this.props
        if (this.state.milestoneData != undefined) {
            let arr = []
            this.state.milestoneData.forEach(milestone => {
                if (milestone.active_flag !== 'f' && milestone.active_flag !== false && milestone.active_flag !== 0) {
                    arr.push(milestone)
                }
            })
            let total = 0

            if (id2 != 0) {
                arr.forEach(milestone => {
                    total =
                        parseFloat(total) +
                        (parseFloat(milestone[id]) + parseFloat(milestone[id2]))
                })
            } else {
                arr.forEach(milestone => {
                    total = parseFloat(total) + parseFloat(milestone[id])
                })
            }
            if (id == 'milestone_pct') {
                if (total == 100) {
                    return (
                        <Typography className={classes.total}>
                            {total.toFixed(0) + '%'}
                        </Typography>
                    )
                } else {
                    return (
                        <Typography className={classes.wrongtotal}>
                            {total.toFixed(0) + '%'}
                        </Typography>
                    )
                }
            } else {
                return (
                    <Typography className={classes.total}>
                        {'$' + total.toFixed(2)}
                    </Typography>
                )
            }
        }
    }

    showMilestones = () => {
        const { classes } = this.props
        let arr = []

        console.log(this.state.milestoneData)
        this.state.milestoneData.forEach(milestone => {
            if (milestone.active_flag !== 'f' && milestone.active_flag !== false && milestone.active_flag !== 0) {
                arr.push(milestone)
            }
        })

        console.log(this.state.milestoneData)

        if (this.state.milestoneData != undefined) {
            return arr.map(milestone => (
                <ExpansionPanel>
                    <ExpansionPanelSummary
                        aria-controls="panel1a-content"
                        id={milestone.milestone_type}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={2}>
                                <Typography className={classes.heading}>
                                    {parseFloat(
                                        milestone.milestone_pct
                                    ).toFixed(0) + '%'}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography>
                                    {milestone.milestone_type}
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography>
                                    {'$' +
                                        (
                                            parseFloat(
                                                milestone.milestone_license_fee
                                            ) +
                                            parseFloat(
                                                milestone.milestone_professional_services
                                            )
                                        ).toFixed(2)}
                                </Typography>
                            </Grid>
                            
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={1}>
                                <NumberFormat
                                    required
                                    id={milestone.recordid}
                                    name="milestone_pct"
                                    label="Percentage"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={milestone.milestone_pct}
                                    onChange={this.handleMilestoneNumChange}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    suffix={'%'}
                                    fixedDecimalScale={true}
                                    defaultValue={0}
                                    allowEmptyFormatting={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <NumberFormat
                                    id={milestone.recordid}
                                    name="milestone_professional_services_hours"
                                    label="PS Hours"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={milestone.milestone_professional_services_hours}
                                    onChange={this.handleMilestoneNumChange}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    fixedDecimalScale={true}
                                    defaultValue={0}
                                    allowEmptyFormatting={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <NumberFormat
                                    id={milestone.recordid}
                                    name="milestone_license_fee"
                                    label="License Fees"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={milestone.milestone_license_fee}
                                    onChange={this.handleMilestoneNumChange}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={'$'}
                                    fixedDecimalScale={true}
                                    defaultValue={0.0}
                                    allowEmptyFormatting={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <NumberFormat
                                    id={milestone.recordid}
                                    name="milestone_professional_services"
                                    label="PS Fees"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={
                                        milestone.milestone_professional_services
                                    }
                                    onChange={this.handleMilestoneNumChange}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    prefix={'$'}
                                    fixedDecimalScale={true}
                                    defaultValue={0.0}
                                    allowEmptyFormatting={true}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                            {this.viewMilestoneDropdown(
                                'MILESTONE_STATUS',
                                milestone,
                                'active_flag',
                                'Status'
                            )}
                            </Grid>
                            <Grid item xs={12} sm={10}>
                                <TextField
                                    multiline
                                    rows="1"
                                    variant="outlined"
                                    
                                    id={milestone.recordid}
                                    name="milestone_desc"
                                    label="Milestone Description"
                                    fullWidth
                                    className={classes.textField}
                                    value={milestone.milestone_desc}
                                    onChange={(e) =>this.handleMilestoneChange(milestone, e)}
                                />
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            ))
        }
    }

    milestoneScreen = () => {
        const { classes } = this.props
        return (
            <Grid container spacing={1}>
                
                <Grid item xs={12}>
                    {this.showAddMilestone()}
                </Grid>
                <Grid item>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        className={classes.textField}
                    >
                        Payment Schedule
                    </Typography>
                </Grid>
                <Grid item className={classes.addGrid}>
                    <Tooltip title={'Add Milestone'}>
                        <IconButton
                            onClick={() =>
                                this.setState({ addMilestone: true })
                            }
                            className={classes.add}
                        >
                            <FontAwesomeIcon
                                icon={faPlusCircle}
                                size="1x"
                                color="black"
                            />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item xs={12}>
                    {this.showMilestones()}
                </Grid>
                <Grid item xs={8}>
                    {this.getMilestoneTotals('milestone_pct', 0)}
                </Grid>
                <Grid item xs={3}>
                    {this.getMilestoneTotals(
                        'milestone_professional_services',
                        'milestone_license_fee'
                    )}
                </Grid>
            </Grid>
        )
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////
    //RevRec
    ///////////////////////////////////////////////////////////////////////////////////////////////////


    handleRecRevNumChange = (event) =>{
        let value = event.target.value.replace(/[,$%]/g, '')
        if (isNaN(value)) {
            console.log(value)
            return
        }
        let change = this.state.newRevRec
        change[event.target.name] = value
        this.setState({ newRevRec: change })
    }

    handleRecRevChange = (event) =>{
        let change = this.state.newRevRec
        change[event.target.name] = event.target.value
        this.setState({ newRevRec: change })
    }

    handleEditRecRevNumChange = (event) =>{
        let value = event.target.value.replace(/[,$%]/g, '')
        if (isNaN(value)) {
            return
        }
        let change = this.state.editRev
        change[event.target.name] = value
        this.setState({ editRev: change })
    }

    handleEditRecRevChange = (event) =>{
        let change = this.state.editRev
        change[event.target.name] = event.target.value
        this.setState({ editRev: change })
    }

    totalInvoiced = () =>{
        let invoiced = 0
        this.state.RevRecData.forEach(rec =>{
            console.log(rec)
            if(rec.recognition_amount_license !== null){
                invoiced = parseFloat(invoiced) + parseFloat(rec.recognition_amount_license.replace(/[,$%]/g, ''))
            }
        })

        this.state.WOdetails.amount_invoiced = invoiced
    }
    
    addRevRec = () =>{
        const data = {
            action: 'insert_rev_recog_record',
            userid: JSON.parse(sessionStorage.getItem('userData')).userid,
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
            work_order_no: this.state.WOdetails.work_order_no,
            recognition_type: this.state.addRev.milestone_type,
            recognition_date: this.state.newRevRec.recognition_date,
            recognition_amount_license: this.state.newRevRec.recognition_amount_license,
            recognition_amount_professional_services: this.state.newRevRec.recognition_amount_professional_services,
            recognized_hours: this.state.newRevRec.recognized_hours,
            hardware: this.state.newRevRec.hardware,
            deferred_maintenance: this.state.newRevRec.deferred_maintenance,   
        }

        PostData('RevenueRecognition/ajax/ajaxRevRecognition.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ addRev: false })
                    this.setState({ newRevRec: {} })
                    this.totalInvoiced()
                    this.refreshRevRec()

                    // const data = {
                    //     action: 'get_RevRec',
                    //     hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                    //     sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
                    //     work_order_no: this.state.WOdetails.work_order_no,
                        
                    // }
            
                    // PostData('RevenueRecognition/ajax/ajaxRevRecognition.php', data).then(
                    //     response => {
                    //         if (response.result && response.result !== 'false') {
                    //             console.log('response: ' + response)
                    //             this.setState({ RevRecData: response.quote_RevRecArr })
                    //         }
                    //     }
                    // )
                }
            }
        )
    }

    editRevRec = () =>{
        const data = {
            action: 'update_rev_recog_record',
            userid: JSON.parse(sessionStorage.getItem('userData')).userid,
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
            recordid: this.state.editRev.recordid,
            work_order_no: this.state.WOdetails.work_order_no,
            recognition_type: this.state.editRev.recognition_type,
            recognition_date: this.state.editRev.recognition_date,
            recognition_amount_license: this.state.editRev.recognition_amount_license,
            recognition_amount_professional_services: this.state.editRev.recognition_amount_professional_services,
            recognized_hours: this.state.editRev.recognized_hours,
            hardware: this.state.editRev.hardware,
            deferred_maintenance: this.state.editRev.deferred_maintenance,
        }

        PostData('RevenueRecognition/ajax/ajaxRevRecognition.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ editRev: false })
                    this.totalInvoiced()   
                    this.refreshRevRec()                
                }
            }
        )
    }

    refreshRevRec = () => {
        const userData = JSON.parse(sessionStorage.getItem('userData'))

        const data = {
            action: 'getWOdetails',
            sort_direction: 'ASC',
            sort_column: '',
            hug_schema: 'hug_' + userData.strategic_business_unit.toLowerCase(),
            userid: userData.userid,
            sbu: userData.strategic_business_unit,
            crm_id: this.props.crmData.crm_id,
            work_order_no: this.state.WOdetails.work_order_no,
        }

        PostData('Production/ajax/ajaxWORK_ORDER_details.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ RevRecData: response.rev_recog }, () => {
                        this.setRevRecTotals()
                    })
                }
            }
        )
    }

    closeWorkOrderButton = () => {
        const { classes } = this.props

        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <Grid
                            container
                            spacing={1}
                            className={classes.message}
                        >
                            <Grid item xs={10}>
                                <Typography
                                    component="h6"
                                    color="inherit"
                                >
                                    This work order has no revenue left to recognize. Would you like to close it?
                                </Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Button
                                    color="secondary"
                                    variant="contained" 
                                    className={classes.buttons}
                                    onClick={this.closeWorkOrder}
                                >
                                    Close Work Order
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        )
    }

    showEditScreen = () =>{
        const { classes } = this.props

        if(this.state.addRev !== false ){
            return (    
                    <Card>
                        <Grid container spacing={1} className={classes.body}>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                className={classes.textField}
                            >
                                {this.state.addRev.milestone_type}
                            </Typography>
                            <Grid item className={classes.buttonGrid}>
                                <Button
                                    //color="primary"
                                    className={classes.add}
                                    onClick={this.addRevRec}
                                >
                                    <FontAwesomeIcon
                                        icon={faCheck}
                                        size="2x"
                                        //color="white"
                                    />
                                </Button>

                                <Button onClick={() =>this.setState({ addRev: false })}>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        size="2x"
                                        //color="white"
                                    />
                                </Button>
                            </Grid>
                            
                            <Grid item xs={12} >
                                <TextField
                                    required
                                    InputLabelProps={{ shrink: true }}
                                    id="recognition_date"
                                    name="recognition_date"
                                    label="Rec Date"
                                    type="date"
                                    fullWidth
                                    className={classes.textField}
                                    value={this.state.newRevRec.recognition_date}
                                    error={!this.state.newRevRec.recognition_date}
                                    onChange={this.handleRecRevChange}
                                />
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <NumberFormat
                                    required
                                    id="recognized_hours"
                                    name="recognized_hours"
                                    label="PS Hours"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={this.state.newRevRec.recognized_hours}
                                    error={!this.state.newRevRec.recognized_hours}
                                    onChange={this.handleRecRevNumChange}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    defaultValue={0}
                                    allowEmptyFormatting={true}
                                    fixedDecimalScale={true}
                                />
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <NumberFormat
                                    required
                                    id="recognition_amount_professional_services"
                                    name="recognition_amount_professional_services"
                                    label="PS Fees"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={this.state.newRevRec.recognition_amount_professional_services}
                                    error={!this.state.newRevRec.recognition_amount_professional_services}
                                    onChange={this.handleRecRevNumChange}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    defaultValue={0.00}
                                    prefix={'$'}
                                    allowEmptyFormatting={true}
                                    fixedDecimalScale={true}
                                />
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <NumberFormat
                                    required
                                    id="recognition_amount_license"
                                    name="recognition_amount_license"
                                    label="License Fees"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={this.state.newRevRec.recognition_amount_license}
                                    error={!this.state.newRevRec.recognition_amount_license}
                                    onChange={this.handleRecRevNumChange}
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    defaultValue={0.00}
                                    prefix={'$'}
                                    allowEmptyFormatting={true}
                                    fixedDecimalScale={true}
                                />
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <NumberFormat
                                    id="hardware"
                                    name="hardware"
                                    label="Hardware"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={this.state.newRevRec.hardware}
                                    onChange={this.handleRecRevNumChange}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    defaultValue={0}
                                    allowEmptyFormatting={true}
                                    fixedDecimalScale={true}
                                />
                            </Grid>
                            <Grid xs={12} sm={6}>
                                <NumberFormat
                                    id="deferred_maintenance"
                                    name="deferred_maintenance"
                                    label="Deferred Maintenance"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={this.state.newRevRec.deferred_maintenance}
                                    onChange={this.handleRecRevNumChange}
                                    thousandSeparator={true}
                                    decimalScale={0}
                                    defaultValue={0}
                                    allowEmptyFormatting={true}
                                    fixedDecimalScale={true}
                                />
                            </Grid>
                        </Grid>

                        <Typography className={classes.textField}>
                            Please complete all the fields with a * to set this recognition
                        </Typography>

                    </Card>
                
            )
        }
        else if (this.state.editRev !== false){
            return (
                
                <Card>
                    <Grid container spacing={1} className={classes.body}>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            className={classes.textField}
                        >
                            {this.state.editRev.recognition_type}
                        </Typography>
                        <Grid item className={classes.buttonGrid}>
                            <Button
                                //color="primary"
                                className={classes.add}
                                onClick={this.editRevRec}
                            >
                                <FontAwesomeIcon
                                    icon={faCheck}
                                    size="2x"
                                    //color="white"
                                />
                            </Button>

                            <Button onClick={() =>this.setState({ editRev: false })}>
                                <FontAwesomeIcon
                                    icon={faTimes}
                                    size="2x"
                                    //color="white"
                                />
                            </Button>
                        </Grid>
                        <Grid item xs={12} >
                            <TextField
                                required
                                InputLabelProps={{ shrink: true }}
                                id="recognition_date"
                                name="recognition_date"
                                label="Rec Date"
                                type="date"
                                fullWidth
                                className={classes.textField}
                                value={this.state.editRev.recognition_date}
                                onChange={this.handleEditRecRevChange}
                            />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <NumberFormat
                                id="recognized_hours"
                                name="recognized_hours"
                                label="PS Hours"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.editRev.recognized_hours}
                                onChange={this.handleEditRecRevNumChange}
                                thousandSeparator={true}
                                decimalScale={0}
                                defaultValue={0}
                                allowEmptyFormatting={true}
                                fixedDecimalScale={true}
                            />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <NumberFormat
                                id="recognition_amount_professional_services"
                                name="recognition_amount_professional_services"
                                label="PS Fees"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.editRev.recognition_amount_professional_services}
                                onChange={this.handleEditRecRevNumChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                defaultValue={0.00}
                                prefix={'$'}
                                allowEmptyFormatting={true}
                                fixedDecimalScale={true}
                            />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <NumberFormat
                                id="recognition_amount_license"
                                name="recognition_amount_license"
                                label="License Fees"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.editRev.recognition_amount_license}
                                onChange={this.handleEditRecRevNumChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                defaultValue={0.00}
                                prefix={'$'}
                                allowEmptyFormatting={true}
                                fixedDecimalScale={true}
                            />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <NumberFormat
                                id="hardware"
                                name="hardware"
                                label="Hardware"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.editRev.hardware}
                                onChange={this.handleEditRecRevNumChange}
                                thousandSeparator={true}
                                decimalScale={0}
                                defaultValue={0}
                                allowEmptyFormatting={true}
                                fixedDecimalScale={true}
                            />
                        </Grid>
                        <Grid xs={12} sm={6}>
                            <NumberFormat
                                id="deferred_maintenance"
                                name="deferred_maintenance"
                                label="Deferred Maintenance"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.editRev.deferred_maintenance}
                                onChange={this.handleEditRecRevNumChange}
                                thousandSeparator={true}
                                decimalScale={0}
                                defaultValue={0}
                                allowEmptyFormatting={true}
                                fixedDecimalScale={true}
                            />
                        </Grid>
                    </Grid>
                </Card>            
            )
        }
    }

    getPayment = () => {
        // console.log(this.state.milestoneData)
        // console.log(this.state.RevRecData)

        let payment = []

        if (this.state.milestoneData) {
            this.state.milestoneData.forEach(mile => {
                if(mile.active_flag !== 'f' && mile.active_flag !== false && mile.active_flag !== 0){
                    payment.push(mile)
                }
            })
        }
        
        return payment.map(mile => this.viewPaymentTable(mile), this)
    }

    getRevRecTotalRow = () => {
        const { classes } = this.props
        let revRecTotals = this.getRevRecTotals()

        if (revRecTotals) {
            return (
                <TableRow>
                    <TableCell className={classes.highlight}>Totals</TableCell>
                    <TableCell align="right"><NumberFormat
                                value={revRecTotals.milestone_pct.toFixed(2)}
                                displayType={'text'}
                                thousandSeparator={true}
                                suffix={'%'}
                            />
                    </TableCell>
                    <TableCell align="right"><NumberFormat
                            value={revRecTotals.milestone_professional_services_hours.toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true}
                            
                        />
                    </TableCell>
                    <TableCell align="right"><NumberFormat
                            value={revRecTotals.milestone_professional_services.toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <NumberFormat
                            value={revRecTotals.milestone_license_fee.toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell align="right"><NumberFormat
                            value={revRecTotals.recognized_hours.toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true}
                            
                        />
                    </TableCell>
                    <TableCell align="right"><NumberFormat
                            value={revRecTotals.recognition_amount_professional_services.toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <NumberFormat
                            value={revRecTotals.recognition_amount_license.toFixed(2)}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </TableCell>
                </TableRow>
            )
        }
    }

    viewPaymentTable = (mile) => {
        const { classes } = this.props
        let rev = false
        // console.log(this.state.RevRecData)

        this.state.RevRecData && this.state.RevRecData.forEach(rec =>{
            if(rec.recognition_type === mile.milestone_type){
                rev = rec
            }
        })
        if(rev === false){
            return (
                <TableRow>
                    <TableCell className={classes.highlight}>{mile.milestone_type}</TableCell>
                    <TableCell align="right"><NumberFormat
                            value={mile.milestone_pct}
                            displayType={'text'}
                            thousandSeparator={true}
                            suffix={'%'}
                        />
                    </TableCell>
                    <TableCell align="right"><NumberFormat
                            value={mile.milestone_professional_services_hours}
                            displayType={'text'}
                            thousandSeparator={true}
                            
                        />
                    </TableCell>
                    <TableCell align="right"><NumberFormat
                            value={mile.milestone_professional_services}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <NumberFormat
                            value={mile.milestone_license_fee}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </TableCell>
                    <TableCell onClick={() =>this.setState({ addRev: mile })}>
                        <Button>
                            <FontAwesomeIcon
                                icon={faArrowAltRight}
                                size="2x"
                            />
                        </Button>
                    </TableCell>
                    <TableCell />
                    <TableCell />
                    <TableCell />
                    <TableCell />
                </TableRow>
            )
        }
        else{
            return (
                <TableRow
                    ///onClick={() =>
                      //  this.handleProductModuleChange(product.product_module)
                    //}
                >
                    <TableCell className={classes.highlight}>{mile.milestone_type}</TableCell>
                    <TableCell align="right"><NumberFormat
                            value={mile.milestone_pct}
                            displayType={'text'}
                            thousandSeparator={true}
                            suffix={'%'}
                        />
                    </TableCell>
                    <TableCell align="right"><NumberFormat
                            value={mile.milestone_professional_services_hours}
                            displayType={'text'}
                            thousandSeparator={true}
                            
                        />
                    </TableCell>
                    <TableCell align="right"><NumberFormat
                            value={mile.milestone_professional_services}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <NumberFormat
                            value={mile.milestone_license_fee}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </TableCell>
                    <TableCell  onClick={() =>this.setState({ editRev: rev })}>
                        <Button>
                            <FontAwesomeIcon
                                icon={faArrowAltRight}
                                size="2x"

                            />
                        </Button>
                    </TableCell>
                    <TableCell >
                           {rev.recognition_date}
                    </TableCell>
                    <TableCell align="right"><NumberFormat
                            value={rev.recognized_hours}
                            displayType={'text'}
                            thousandSeparator={true}
                            
                        />
                    </TableCell>
                    <TableCell align="right"><NumberFormat
                            value={rev.recognition_amount_professional_services}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </TableCell>
                    <TableCell align="right">
                        <NumberFormat
                            value={rev.recognition_amount_license}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                        />
                    </TableCell>
                </TableRow>
            )
        }
    }

    showRevRec = () => {
        const { classes } = this.props

        return (
            <React.Fragment>
                {this.state.revRecComplete && this.state.WOdetails.wo_status !== 'CLOSED' && this.closeWorkOrderButton()}

                <Grid container spacing={0} className={classes.body}>
                    
                    <Grid item xs={6}>
                        <Typography component="h1" variant="h6" color="inherit">
                            Payment Schedule
                        </Typography>
                    </Grid>
                   
                    <Grid item xs={6}>
                        <Typography component="h1" variant="h6" color="inherit">
                            RevRec
                        </Typography>
                    </Grid>
                    
                    <Grid item xs={9}>
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell align="right">
                                        Pct
                                    </TableCell>
                                    <TableCell align="right">
                                        PS Hours
                                    </TableCell>
                                    <TableCell align="right">
                                        PS Fees
                                    </TableCell>
                                    <TableCell align="right">
                                        License
                                    </TableCell>
                                    <TableCell >
                                        Rec
                                    </TableCell>
                                    <TableCell >
                                        Rec Date
                                    </TableCell>
                                    <TableCell align="right">
                                        PS Hours
                                    </TableCell>
                                    <TableCell align="right">
                                        PS Fees
                                    </TableCell>
                                    <TableCell align="right">
                                        License
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{this.getPayment()}{this.getRevRecTotalRow()}</TableBody>
                        </Table>
                    </Grid>
                    <Grid xs ={3}>
                        {this.showEditScreen()}
                    </Grid>
                    
                    
                </Grid>
            </React.Fragment>
        )
    }

    ///////////////////////////////////////////////////////////////////////////////
    //Notes
    //////////////////////////////////////////////////////////////////////////////
    handleNoteFormOpen = () => {
        this.setState({ noteFormOpen: true })
    }

    handleNoteFormClose = () => {
        this.setState({ noteFormOpen: false })
    }

    noteForm = () => {
        return (
            <React.Fragment>
                {this.state.noteFormOpen ? (
                    <NoteForm
                        crmData={this.props.crmData}
                        handleFormClose={this.handleNoteFormClose}
                        updateComponent={this.props.updateComponent}
                        workOrderNumber={this.state.WOdetails.work_order_no}
                        modal
                    />
                ) : (
                    <Notes
                        crmData={this.props.crmData}
                        handleFormOpen={this.handleNoteFormOpen}
                        workOrderNumber={this.state.WOdetails.work_order_no}
                        modal
                    />
                )}
            </React.Fragment>
        )
    }


    render() {
        const { classes } = this.props
        return (
            <Card>
            <FormWrapper
                invalid={this.state.invalid}
                type="Work Order"
                name={this.state.WOdetails.work_order_no}
                handleSave={this.handleSave}
                handleClose={this.handleClose}
            >
                <Grid container>
                        <Grid item xs={12}>
                            <SwipeableTabs
                                titles={[
                                    'Overview',
                                    'Scope',
                                    'Products',
                                    (this.state.quote && this.state.quote.contract_payment === 'SaaS' ? 'SaaS' : 'Payment Plan'),
                                    'Rev Rec',
                                    'Notes'
                                ]}
                            >
                                <div>{this.showHeader()}</div>

                                <div>
                                    <div dangerouslySetInnerHTML={{ __html: (this.state.WOdetails.statement_of_work ? this.state.WOdetails.statement_of_work : '')}}>
                                    </div>
                                </div>

                                <div><ProductRecap 
                                        product_details={this.state.product_details}
                                        recap={this.state.recap}/>
                                </div>

                                <div>{
                                    this.state.quote && this.state.quote.contract_payment === 'SaaS' 
                                        ? <Saas quote={this.state.quote} product_details={this.state.product_details}></Saas> 
                                        : this.milestoneScreen()
                                }</div>

                                <div>{this.showRevRec()}</div>

                                <div>{this.noteForm()}</div>

                            </SwipeableTabs>
                        </Grid>
                    </Grid>
            </FormWrapper>
            </Card>
        )
    }
}

WorkOrdersForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(WorkOrdersForm)
