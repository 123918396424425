import React from 'react'
import PropTypes from 'prop-types'

import {
    ClickAwayListener,
    Grid,
    TextField,
    Typography,
    Card,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    ExpansionPanelActions,
    Button,
    Paper,
    Tooltip,
    InputAdornment,
    IconButton,
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    Collapse,
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import CKEditor from 'ckeditor4-react'
import { PostData } from '../../../services/PostData'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronDown,
    faChevronUp,
    faMapMarkedAlt,
    faBurn,
    faExternalLink,
    faEdit,
    faTimes,
    faPhone,
    faLink,
    faEnvelope
} from '@fortawesome/pro-light-svg-icons'
import { formatDateTime } from '../../../utils/Date'
import { addValidators, validate } from '../../../utils/Validate'
import FormWrapper from '../../Reusable/FormWrapper'
import InputMask from 'react-input-mask'
import NumberFormat from 'react-number-format'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
})

class ContactForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            contactData: {
                recordid: '',
                crm_id: '',
                name: '',
                title: '',
                contacts_role: '',
                preferred_name: '',
                decision_role: '',
                phonenumber: '',
                extension: '',
                mobilenumber: '',
                email: '',
                comments: '',
                service_type: '',
                key_contact_list: 'N',
                general_manager: 'N',
                unsubscribed: 'N',
                reference: 'N',
                support_contact: 'N',
            },
            dropdown: [],
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    handleChange = event => {
        let contactData = this.state.contactData
        contactData[event.target.name] = event.target.value
        contactData.last_updated = this.getCurrentDate()
        this.setState({ contactData: contactData })
    }
    phoneFormat = value => {
        value = value.replace(/[()]/g, '')
        value = value.replace(/-/g, '')
        return value
    }

    handleCChange = event => {
        let contactData = this.state.contactData
        contactData[event.target.id] = this.YesNo(event.target.checked)
        this.setState({ contactData: contactData })
    }

    deleteContact = () => {
        const data = {
            action: 'delete',
            contactsrecordid: this.state.contactData.recordid,
        }

        PostData('CRM/ajax/ajaxCRMUtilityPersonnel2018.php', data).then(
            response => {
                this.props.updateComponent('Contacts')
                this.handleClose()
            }
        )
    }

    handleClose = () => {
        this.props.handleFormClose()
    }

    handleSave = () => {
        if (validate(this, this.state.contactData)) {
            //this.props.handleFormClose()

            let data = {
                action: this.state.contactData.recordid == '' ? 'Add' : 'Change',
                contactsrecordid: this.state.contactData.recordid,
            }

            let combinedData = { ...data, ...this.state.contactData }
            combinedData.crm_id = this.state.crmData.crm_id

            PostData(
                'CRM/ajax/ajaxCRMUtilityPersonnel2018.php',
                combinedData
            ).then(response => {
                this.props.updateComponent('Contacts')

                if (response.recordid) {
                    let contactData = this.state.contactData
                    contactData.recordid = response.recordid
                    this.setState({ contactData: contactData })
                }
            })

            return true
        }

        return false
    }

    componentWillMount() {
        this.setState({
            crmData: this.props.crmData,
        })

        if (this.props.formData.recordid !== undefined) {
            this.setState({ contactData: this.props.formData })
        } else {
            this.setState({ 
                officePhoneEdit: true,
                mobilePhoneEdit: true, 
                emailEdit: true,
            })
        }

        this.getDropdown()

        addValidators(this, {
            name: 'required',
            contacts_role: 'required',
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.crmData !== this.state.crmData) {
            this.setState({
                crmData: this.props.crmData,
            })

            if (this.props.formData.recordid !== undefined) {
                this.setState({ contactData: this.props.formData })
            } else {
                this.setState({ 
                    officePhoneEdit: true,
                    mobilePhoneEdit: true, 
                    emailEdit: true,
                })
            }
        }
    }
    /*componentWillReceiveProps(nextProps){
        this.setState({contactData: nextProps.formData})
        
    }*/

    getCurrentDate(separator = '-') {
        let newDate = new Date()
        let date = newDate.getDate()
        if (date < 10) {
            date = String(date)
            date = '0' + date
        }
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()

        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`
    }

    //gets all dropdowns for page
    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'PEOPLE',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            this.setState({ dropdown: response.dropdowns })
        })
    }

    //displays specific dropdown
    viewDropdown = (control, id, name, required) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })

        if (required) {
            return (
                <TextField
                    required
                    select
                    fullWidth
                    id={id}
                    name={id}
                    label={name}
                    className={classes.textField}
                    onChange={this.handleChange}
                    value={this.state.contactData[id]}
                    error={this.state.errorMessages.contacts_role !== undefined}
                    helperText={this.state.errorMessages.contacts_role}
                >
                    {list.map(item => (
                        <MenuItem key={item.name} value={item.code}>
                            {item.name}
                        </MenuItem>
                    ))}
                </TextField>
            )
        } else {
            return (
                <TextField
                    select
                    fullWidth
                    id={id}
                    name={id}
                    label={name}
                    className={classes.textField}
                    onChange={this.handleChange}
                    value={this.state.contactData[id]}
                >
                    {list.map(item => (
                        <MenuItem key={item.name} value={item.code}>
                            {item.name}
                        </MenuItem>
                    ))}
                </TextField>
            )
        }
    }

    //Converts true and false to Y and N for the database and reports, yes its dumb
    YesNo = input => {
        if (input === 'Y') {
            return true
        } else if (input === 'N') {
            return false
        } else if (input === true) {
            return 'Y'
        } else if (input === false) {
            return 'N'
        } else if (input === null) {
            return false
        }
    }

    render() {
        const { classes } = this.props

        return (
            <FormWrapper
                invalid={this.state.invalid}
                type="Contact"
                name={this.state.contactData.name}
                handleSave={this.handleSave}
                handleClose={this.handleClose}
                handleDelete={this.deleteContact}
            >
                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h6" color="inherit">
                        General
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        fullWidth
                        className={classes.textField}
                        defaultValue={this.state.contactData.name}
                        onChange={this.handleChange}
                        error={this.state.errorMessages.name !== undefined}
                        helperText={this.state.errorMessages.name}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="preferred_name"
                        name="preferred_name"
                        label="Preferred Name"
                        fullWidth
                        className={classes.textField}
                        defaultValue={this.state.contactData.preferred_name}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="title"
                        name="title"
                        label="Title"
                        fullWidth
                        className={classes.textField}
                        defaultValue={this.state.contactData.title}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {this.viewDropdown(
                        'ROLE',
                        'contacts_role',
                        'Contacts Role',
                        true
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {this.viewDropdown(
                        'DECISION_ROLE',
                        'decision_role',
                        'Decision Making Role',
                        false
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {this.viewDropdown(
                        'SERVICES_TYPE',
                        'service_type',
                        'Primary Service Type',
                        false
                    )}
                </Grid>

                <Grid item xs={6} sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={this.YesNo(
                                    this.state.contactData.main_contact
                                )}
                                id="main_contact"
                                onClick={this.handleCChange}
                            />
                        }
                        label="Main Contact"
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={this.YesNo(
                                    this.state.contactData.finance_contact
                                )}
                                id="finance_contact"
                                onClick={this.handleCChange}
                            />
                        }
                        label="Finance Contact"
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={this.YesNo(
                                    this.state.contactData.maintenance_contact
                                )}
                                id="maintenance_contact"
                                onClick={this.handleCChange}
                            />
                        }
                        label="Maintenance Contact"
                    />
                </Grid>

                <Grid item xs={6} sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={this.YesNo(
                                    this.state.contactData.key_contact_list
                                )}
                                id="key_contact_list"
                                onClick={this.handleCChange}
                            />
                        }
                        label="Key Contact"
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={this.YesNo(
                                    this.state.contactData.support_contact
                                )}
                                id="support_contact"
                                onClick={this.handleCChange}
                            />
                        }
                        label="Support"
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={this.YesNo(
                                    this.state.contactData.reference
                                )}
                                id="reference"
                                onClick={this.handleCChange}
                            />
                        }
                        label="Reference"
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={this.YesNo(
                                    this.state.contactData.unsubscribed
                                )}
                                id="unsubscribed"
                                onClick={this.handleCChange}
                            />
                        }
                        label="Unsubscribed"
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h6" color="inherit">
                        Contact
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/* <NumberFormat
                        id="phonenumber"
                        name="phonenumber"
                        label="Phone Number"
                        fullWidth
                        className={classes.textField}
                        customInput={TextField}
                        value={this.state.contactData.phonenumber}
                        onChange={this.handleChange}
                        format="(###) ###-####"
                    /> */}

                    {this.state.officePhoneEdit ? (
                        <ClickAwayListener onClickAway={this.handleClickAway}>
                            <NumberFormat
                                id="phonenumber"
                                name="phonenumber"
                                label="Phone Number"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.contactData.phonenumber}
                                onChange={this.handleChange}
                                format="(###) ###-####"
                                InputProps={{
                                    endAdornment: (
                                        this.state.contactData.name !== '' && (
                                            <InputAdornment
                                                position="end"
                                                component="span"
                                            >
                                                <Tooltip title={'Close Editing'}>
                                                    <IconButton size="small">
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                            size="1x"
                                                            color="black"
                                                            style={{
                                                                marginLeft: 5,
                                                            }}
                                                            onClick={e => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                this.setState({
                                                                    officePhoneEdit: false,
                                                                })
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    ),
                                }}
                            />
                        </ClickAwayListener>
                    ) : (
                        <a
                            href={'tel:' + this.state.contactData.phonenumber}
                            style={{ textDecoration: 'none' }}
                        >
                            <NumberFormat
                                id="phonenumber"
                                name="phonenumber"
                                label="Phone Number"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.contactData.phonenumber}
                                format="(###) ###-####"
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            component="span"
                                        >
                                            <Tooltip title={'Call'}>
                                                <IconButton size="small">
                                                    <FontAwesomeIcon
                                                        icon={faPhone}
                                                        size="1x"
                                                        color="black"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            component="span"
                                        >
                                            <Tooltip
                                                title={'Edit Phone Number'}
                                            >
                                                <IconButton size="small">
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                        size="1x"
                                                        color="black"
                                                        style={{
                                                            marginLeft: 5,
                                                        }}
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            this.setState({
                                                                officePhoneEdit: true,
                                                            })
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </a>
                    )}
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        id="extension"
                        name="extension"
                        label="Extension"
                        fullWidth
                        className={classes.textField}
                        value={this.state.contactData.extension}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/* <NumberFormat
                        id="mobilephone"
                        name="mobilephone"
                        label="Mobile Phone"
                        fullWidth
                        className={classes.textField}
                        customInput={TextField}
                        value={this.state.contactData.mobilephone}
                        onChange={this.handleChange}
                        format="(###) ###-####"
                    /> */}

                    {this.state.mobilePhoneEdit ? (
                        <ClickAwayListener onClickAway={this.handleClickAway}>
                            <NumberFormat
                                id="mobilephone"
                                name="mobilephone"
                                label="Mobile Phone"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.contactData.mobilephone}
                                onChange={this.handleChange}
                                format="(###) ###-####"
                                InputProps={{
                                    endAdornment: (
                                        this.state.contactData.name !== '' && (
                                            <InputAdornment
                                                position="end"
                                                component="span"
                                            >
                                                <Tooltip title={'Close Editing'}>
                                                    <IconButton size="small">
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                            size="1x"
                                                            color="black"
                                                            style={{
                                                                marginLeft: 5,
                                                            }}
                                                            onClick={e => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                this.setState({
                                                                    mobilePhoneEdit: false,
                                                                })
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    ),
                                }}
                            />
                        </ClickAwayListener>
                    ) : (
                        <a
                            href={'tel:' + this.state.contactData.mobilephone}
                            style={{ textDecoration: 'none' }}
                        >
                            <NumberFormat
                                id="mobilephone"
                                name="mobilephone"
                                label="Mobile Phone"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.contactData.mobilephone}
                                format="(###) ###-####"
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            component="span"
                                        >
                                            <Tooltip title={'Call'}>
                                                <IconButton size="small">
                                                    <FontAwesomeIcon
                                                        icon={faPhone}
                                                        size="1x"
                                                        color="black"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            component="span"
                                        >
                                            <Tooltip
                                                title={'Edit Mobile Phone'}
                                            >
                                                <IconButton size="small">
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                        size="1x"
                                                        color="black"
                                                        style={{
                                                            marginLeft: 5,
                                                        }}
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            this.setState({
                                                                mobilePhoneEdit: true,
                                                            })
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </a>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {/* <TextField
                        id="email"
                        name="email"
                        label="Email"
                        fullWidth
                        className={classes.textField}
                        value={this.state.contactData.email}
                        onChange={this.handleChange}
                    /> */}

                    {this.state.emailEdit ? (
                        <ClickAwayListener onClickAway={this.handleClickAway}>
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                fullWidth
                                className={classes.textField}
                                value={this.state.contactData.email}
                                onChange={this.handleChange}
                                InputProps={{
                                    endAdornment: (
                                        this.state.contactData.name !== '' && (
                                            <InputAdornment
                                                position="end"
                                                component="span"
                                            >
                                                <Tooltip title={'Close Editing'}>
                                                    <IconButton size="small">
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                            size="1x"
                                                            color="black"
                                                            style={{
                                                                marginLeft: 5,
                                                            }}
                                                            onClick={e => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                this.setState({
                                                                    emailEdit: false,
                                                                })
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    ),
                                }}
                            />
                        </ClickAwayListener>
                    ) : (
                        <a
                            href={'mailto:' + this.state.contactData.email}
                            style={{ textDecoration: 'none' }}
                        >
                            <TextField
                                id="email"
                                name="email"
                                label="Email"
                                fullWidth
                                className={classes.textField}
                                value={this.state.contactData.email}
                                onChange={this.handleChange}
                                InputProps={{
                                    disableUnderline: true,
                                    startAdornment: (
                                        <InputAdornment
                                            position="start"
                                            component="span"
                                        >
                                            <Tooltip title={'Email'}>
                                                <IconButton size="small">
                                                    <FontAwesomeIcon
                                                        icon={faEnvelope}
                                                        size="1x"
                                                        color="black"
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment
                                            position="end"
                                            component="span"
                                        >
                                            <Tooltip
                                                title={'Edit Email'}
                                            >
                                                <IconButton size="small">
                                                    <FontAwesomeIcon
                                                        icon={faEdit}
                                                        size="1x"
                                                        color="black"
                                                        style={{
                                                            marginLeft: 5,
                                                        }}
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            e.stopPropagation()
                                                            this.setState({
                                                                emailEdit: true,
                                                            })
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </a>
                    )}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h6" color="inherit">
                        Notes
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        multiline
                        rows="3"
                        variant="outlined"
                        id="comments"
                        name="comments"
                        label="Notes"
                        fullWidth
                        className={classes.textField}
                        value={this.state.contactData.comments}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        id="last_updated"
                        name="last_updated"
                        label="Last Updated"
                        fullWidth
                        className={classes.textField}
                        defaultValue={this.state.contactData.last_updated}
                        disabled
                    />
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                    <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#B00020',
                            color: 'white',
                        }}
                        onClick={this.deleteContact}>
                        Delete Contact
                    </Button>
                </Grid> */}
            </FormWrapper>

            //             </Grid>
            //         </Card>
            //     </Grid>
            // </Grid>
        )
    }
}

ContactForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ContactForm)
