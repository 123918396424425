import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
//import { styles } from '../../Layout/styles'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'
import FullScreenDialog from '../../Reusable/FullScreenDialog'

import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import { CardMedia } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import NumberFormat from 'react-number-format'

import QuotesForm from './QuotesForm'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { faPlusCircle, faLayerPlus } from '@fortawesome/pro-light-svg-icons'
import { faGripHorizontal } from '@fortawesome/pro-light-svg-icons'
import { faGripLines } from '@fortawesome/pro-light-svg-icons'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },
    sort: {
        textAlign: 'center',
    },
    viewButtons: {
        justifySelf: 'end',
    },
    lower: {
        textTransform: 'capitalize',
        padding: 0,
        margin: 0,
    },
    lowercase: {
        textTransform: 'none',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    showMore: {
        width: '100%',
    },
    add: {
        padding: 7,
        marginRight: 5,
    },
    typo: {
        paddingTop: 4,
        paddingLeft: 18,
    },
    total:{
        paddingTop: 5,
        paddingLeft: 4,
        fontWeight: 500,
    },
    drop: {
        //paddingBottom: 3,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        width: '97%',
        //background: 'rgb(248,248,248)',
    },
    highlight: {
        cursor:'pointer',
    },
    


   
})

function Transition(props) {
    return <Slide direction="up" {...props} />
}

class Quotes extends Component {
    constructor(props) {
        super(props)

        this.state = {
            quotes: [],
            milestoneData: [],
            more: false,
            open: false,
            dropdown: [],
            filter: {
                status: 'OPEN',
                year: 'ALL',
            },
            gridView: true,
            bubbleView: false,
            totalNumber: 0,
            sortNumber: 0,
        }

        this.handleToggle = this.handleToggle.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentWillMount() {
        this.getDropdown()
        
        let userData = JSON.parse(sessionStorage.getItem('userData'))


        if (this.props.crmData) {
            const data = {
                hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                sbu:
                    userData === null ? null : userData.strategic_business_unit,
                action: 'getOpenQuoteForUtility',
                q_status: 'ALL',
                report_year: 'none',
                crm_id: this.props.crmData.crm_id,
                newnamed_organic_filter: 'ALL',
            }

            PostData('Quotes/ajax/ajaxQuotesList_2018.php', data).then(
                response => {
                    if (response.result && response.resultSet) {
                        let totalQuote = 0
                        let totalQuoteProbability = 0
                        response.resultSet.map(quote => {
                            if (quote.name === '' || quote.name === null || quote.name === undefined) {
                                if (quote.description !== '' && quote.description !== null && quote.description !== undefined) {
                                    quote.name = quote.description
                                } else {
                                    quote.name = 'Quote #' + quote.quote_number
                                }
                            }

                            totalQuote += parseFloat(quote.quote_amt)
                            if (
                                quote.close_prob !== '0% UNKNOWN' &&
                                quote.close_prob
                            ) {
                                totalQuoteProbability +=
                                    parseFloat(quote.quote_amt) *
                                    (parseFloat(
                                        quote.close_prob.substring(0, 2)
                                    ) /
                                        100)
                            }
                        })

                        this.setState({ quotes: response.resultSet })
                        this.setState({ totalQuote: totalQuote })
                        this.setState({
                            totalQuoteProbability: totalQuoteProbability,
                        })
                    }
                }
            )
        }
    }
    componentWillReceiveProps(nextProps) {
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        //console.log(nextProps)
        if (nextProps.crmData && (nextProps.crmData !== this.state.crmData || nextProps.update === 'Quotes')) {
            //console.log('yeet')
            this.getDropdown()
            this.setState({ crmData: nextProps.crmData })
            const data = {
                hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                sbu:
                    userData === null ? null : userData.strategic_business_unit,
                action: 'getOpenQuoteForUtility',
                q_status: 'ALL',
                report_year: 'none',
                crm_id: nextProps.crmData.crm_id,
                newnamed_organic_filter: 'ALL',
            }

            PostData('Quotes/ajax/ajaxQuotesList_2018.php', data).then(
                response => {
                    if (response.result && response.resultSet) {
                        let totalQuote = 0
                        let totalQuoteProbability = 0

                        response.resultSet.map(quote => {
                            if (quote.name === '' || quote.name === null || quote.name === undefined) {
                                if (quote.description !== '' && quote.description !== null && quote.description !== undefined) {
                                    quote.name = quote.description
                                } else {
                                    quote.name = 'Quote #' + quote.quote_number
                                }
                            }

                            totalQuote += parseFloat(quote.quote_amt)
                            if (
                                quote.close_prob !== '0% UNKNOWN' &&
                                quote.close_prob
                            ) {
                                totalQuoteProbability +=
                                    parseFloat(quote.quote_amt) *
                                    (parseFloat(
                                        quote.close_prob.substring(0, 2)
                                    ) /
                                        100)
                            }
                        })
                        
                        this.setState({ quotes: response.resultSet })
                        this.setState({ totalQuote: totalQuote })
                        this.setState({
                            totalQuoteProbability: totalQuoteProbability,
                        })
                    }
                }
            )
        }
    }



    handleOpen = quote => {
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        if (quote != 0) {
            let milestoneData
            const data1 = {
                action: 'getQuoteCurrentMilestone',
                current_milestone_template: quote.alternate_terms,
                sbu:
                    userData === null ? null : userData.strategic_business_unit,
                userid: userData === null ? null : userData.userid,
                quote_number: quote.quote_number,
                //hug_schema: 'hug_horizons'
            }

            PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data1).then(
                response => {
                    if (response.result && response.result !== 'false') {
                        milestoneData = response.quote_milestonesArr
                        const data2 = {
                            action: 'getQMDforQuote2',
                            
                            quote_number: quote.quote_number,
                            //hug_schema: 'hug_horizons',
                            sbu:
                                userData === null
                                    ? null
                                    : userData.strategic_business_unit,
                        }

                        PostData(
                            'Quotes/ajax/ajaxhugQuoteSystem2019.php',
                            data2
                        ).then(response => {
                            this.props.setFormData(
                                {
                                    header: response.qte_header,
                                    module_details: response.Q_Modules_Details,
                                    milestoneData: milestoneData,
                                    crmData: this.props.crmData,
                                    new: false
                                },
                                'Quotes'
                            )
                            
                        })
                    }
                }
            )
        } else {
            this.props.setFormData(
                {
                    header: {},
                    module_details: [],
                    milestoneData: [],
                    crmData: this.props.crmData,
                    new: true,
                     
                },
                'Quotes'
            )
        }
    }

    //changes filters
    handleFilterChange = event => {
        let change = this.state.filter
        change[event.target.name] = event.target.value
        this.setState({ filter: change })
    }

    //Changes to bubbleView
    handleView1Change = () => {
        
        if (this.state.bubbleView == false) {
            
            this.setState({ bubbleView: true, gridView: false })
        }
    }
    //changes to gridview
    handleView2Change = () => {
        
        if (this.state.gridView == false) {
            
            this.setState({ bubbleView: false, gridView: true })
        }
    }

    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'QUOTE',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }

    //Shows dropdowns for filters
    viewFilterDropdown = (control, id, name) => {
        const { classes } = this.props
        let list = []
        if(this.state.dropdown.length > 0){
            this.state.dropdown.forEach(item =>{
                if (item.control_code == control ){
                    
                    list.push({name: item.item_name, code: item.item_code})
                }
            })
        }
        
        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                className={classes.drop}
                onChange={this.handleFilterChange}
                defaultValue="ALL"
                value={this.state.filter[id]}
                InputProps={{
                    disableUnderline: true,
                }}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    getCurrentDate() {
        let newDate = new Date()
        let date = newDate.getDate()
        let month = newDate.getMonth()
        let year = newDate.getFullYear()
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]

        return `${months[month]} ${date}th`
    }
    handleClose = () => {
        this.setState({ open: false })
    }

    handleToggle = () => {
        this.setState({ more: !this.state.more })
    }

    //Claculates the pipeline and adjusted pipeline with percentage
    calculatePipeline = (adjusted) =>{
        const { classes } = this.props
        let total = 0

        this.state.quotes.forEach(quote =>{
            if (quote.status === 'OPEN') {
                if (adjusted == false){
                    total = (parseFloat(total) + parseFloat(Number(quote.quote_amt))).toFixed(2)
                }
                else{
                    total = (parseFloat(total) + parseFloat(Number(quote.quote_amt)) * 0.01 * parseFloat(Number(quote.close_prob))).toFixed(2)
                }
            }
        })
            
        return (
            <Typography
                component="h6"
                color="inherit"
                className={classes.total}
                >
                    <NumberFormat value={total} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                    
            </Typography>
        )
    }

    //Gets the list of quotes depending on filters
    getQuotesList = () => {
        const { classes } = this.props
        
        if (this.state.quotes && this.state.quotes.length > 0) {
            let sort = []
            let newSort = []

            this.state.quotes.forEach(quote => {
                if (
                    this.state.filter.status == 'ALL' &&
                    this.state.filter.year == 'ALL'
                ) {
                    sort.push(quote)
                } else if (this.state.filter.year == 'ALL') {
                    if (this.state.filter.status == quote.status) {
                        sort.push(quote)
                    }
                } else if (this.state.filter.status == 'ALL') {
                    if (
                        this.state.filter.year ==
                        quote.quoteclosedate.substring(0, 4)
                    ) {
                        sort.push(quote)
                    }
                } else if (
                    this.state.filter.status == quote.status &&
                    this.state.filter.year ==
                        quote.quoteclosedate.substring(0, 4)
                ) {
                    sort.push(quote)
                }
            })
            
            
            //this.state.totalNumber = sort.length

            if(sort.length > 5){
                if (!this.state.more) {
                    for (let i = 0; i < 5; i++){
                        newSort[i] = sort[i]
                    }
                    sort = newSort
                }
            }
            
            //this.state.sortNumber = sort.length
            
            if(this.state.bubbleView == true){
                let quoteList =[]
                sort.forEach(quote =>{
                    quoteList.push(
                        <Grid item xs={3}>
                            <Button
                                className={classes.lowercase}
                                onClick={() => this.handleOpen(quote)}
                            >
                                <ListItem>
                                    <ListItemText
                                        className={classes.listItem}
                                        primary={quote.name}
                                        secondary={quote.status}
                                    />
                                </ListItem>
                            </Button>
                        </Grid>
                    )
                })
                quoteList[quoteList.length + 1] = (
                    <React.Fragment>
                        <Grid item xs={true} />
                    </React.Fragment>
                )

                return <React.Fragment>{quoteList}</React.Fragment>
            } else {
                let quoteList = []
                sort.forEach(quote => {
                    quoteList.push(
                        <TableRow
                            onClick={() => this.handleOpen(quote)}
                        >
                            <TableCell
                                className={classes.highlight}
                            >{quote.quote_number}</TableCell>
                            <TableCell>{(quote.name && quote.name !== '' && quote.name !== null) ? quote.name : quote.description}</TableCell>
                            <TableCell>{quote.quotedate}</TableCell>
                            <TableCell>{quote.status}</TableCell>
                            <TableCell><NumberFormat value={quote.close_prob} displayType={'text'} suffix={'%'} /></TableCell>
                            <TableCell>{quote.quoteclosedate}</TableCell>
                            <TableCell><NumberFormat value={quote.quote_amt} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TableCell>
                        </TableRow>
                    )
                })

                return (
                    <React.Fragment>
                        {this.viewGridView(quoteList)}
                    </React.Fragment>
                )
            }
        }
    }

    //Creates table for grid view
    viewGridView = list => {
        const { classes } = this.props
            return (
                <Table className={classes.table} size="small" >
                    <TableHead>
                        <TableRow>
                            
                            <TableCell>Number</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Created Date</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Probability</TableCell>
                            <TableCell>Close Date</TableCell>
                            <TableCell>Net Invoice</TableCell>

                            
                        </TableRow>
                    </TableHead>
                    <TableBody>{list}</TableBody>
                </Table>
            )
    }

    getMoreButton() {
        const { classes } = this.props
        let sort = []

        this.state.quotes.forEach(quote => {
            if (
                this.state.filter.status == 'ALL' &&
                this.state.filter.year == 'ALL'
            ) {
                sort.push(quote)
            } else if (this.state.filter.year == 'ALL') {
                if (this.state.filter.status == quote.status) {
                    sort.push(quote)
                }
            } else if (this.state.filter.status == 'ALL') {
                if (
                    this.state.filter.year ==
                    quote.quoteclosedate.substring(0, 4)
                ) {
                    sort.push(quote)
                }
            } else if (
                this.state.filter.status == quote.status &&
                this.state.filter.year ==
                    quote.quoteclosedate.substring(0, 4)
            ) {
                sort.push(quote)
            }
        })
        
        if (this.state.quotes && sort.length > 5) {
            return (
                <Grid container xs={12}>
                    <Button
                        className={classes.showMore}
                        onClick={this.handleToggle}
                    >
                        {this.state.more  ? (
                            <div>
                                <FontAwesomeIcon
                                    icon={faChevronUp}
                                    //size="2x"
                                />
                                &nbsp;1 to {sort.length} of {sort.length}
                            </div>
                        ) : (
                            <div>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    //size="2x"
                                />
                                &nbsp;1 to 5 of {sort.length}
                            </div>
                        )}
                    </Button>
                </Grid>
            )
        }
    }

    handleToggle = () => {
        this.setState({ more: !this.state.more })
    }
    

    render() {
        const { classes } = this.props

        if (this.props.index !== 1) {
            return ''
        }

        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        {/* <Card> */}
                            
                            <Grid container spacing={1}  className={classes.sort}>
                            
                                <Grid item>
                                    <Typography
                                        component="h6"
                                        color="inherit"
                                        className={classes.typo}
                                    >
                                        Status:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {this.viewFilterDropdown(
                                        'SORT_STATUS',
                                        'status',
                                        'Status'
                                    )}
                                </Grid>

                                <Grid item>
                                    <Typography
                                        component="h6"
                                        color="inherit"
                                        
                                        className={classes.typo}
                                    >
                                        Year:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {this.viewFilterDropdown(
                                        'SORT_YEAR',
                                        'year',
                                        'Year'
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography
                                        component="h6"
                                        color="inherit"
                                        className={classes.typo}
                                        >
                                            Pipeline:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {this.calculatePipeline(false)}
                                </Grid>
                                <Grid item>
                                    <Typography
                                        component="h6"
                                        color="inherit"
                                        className={classes.typo}
                                        >
                                            Adjusted:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {this.calculatePipeline(true)}
                                </Grid>
                                

                                <Grid item>
                                    <Tooltip title={'Bubble View'}>
                                        <IconButton
                                            onClick={this.handleView1Change}
                                            size="small"
                                            className={classes.add}
                                            style={this.state.bubbleView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripHorizontal}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={'Grid View'}>
                                        <IconButton
                                            onClick={this.handleView2Change}
                                            size="small"
                                            className={classes.add}
                                            style={this.state.gridView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripLines}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                
                                <Grid item className={classes.addGrid}>
                                    <Tooltip title={'Add Quote'}>
                                        <Button
                                                onClick={() =>
                                                    this.handleOpen(0)
                                                }
                                                className={classes.add}
                                            >
                                                Add Quote
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    size="1x"
                                                    color="black"
                                                    style={{
                                                        marginLeft: 5,
                                                    }}
                                                />
                                            </Button>
                                        </Tooltip>
                                </Grid>  
                                

                                
                            </Grid>

                            <Divider variant="middle" />

                            <List className={classes.root}>
                                <Grid
                                    container
                                    spacing={1}
                                    justify="space-evenly"
                                >
                                    {this.getQuotesList()}
                                </Grid>
                            </List>
                            {this.getMoreButton()}
                        
                        {/* </Card> */}
                    </Grid>
                </Grid>

                <Dialog
                    //fullScreen
                    fullWidth
                    maxWidth="lg"
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    {/* <AppBar >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    onClick={this.handleClose}
                                    aria-label="Close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <div className={classes.flex} />
                                <Button
                                    color="inherit"
                                    onClick={this.handleSave}
                                >
                                    save
                                </Button>
                            </Toolbar>
                        </AppBar> */}
                    {/*<QuotesForm quotesData={this.state.quotesData} */}/>
                </Dialog>
            </React.Fragment>
        )
    }
}
Quotes.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Quotes)