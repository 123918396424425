import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import CKEditor from 'ckeditor4-react'
import Toolbar from '@material-ui/core/Toolbar'
import AppBar from '@material-ui/core/AppBar'
import Divider from '@material-ui/core/Divider'
import MenuItem from '@material-ui/core/MenuItem'
import { OverflowDetector } from 'react-overflow'

import { PostData } from '../../../services/PostData'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { faPlusCircle, faPencil } from '@fortawesome/pro-light-svg-icons'

import { formatDate, formatTime } from '../../../utils/Date'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 1,
    },
    buttontextField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 0,
        paddingTop: 1,
    },
    subjectLine: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,

    },
    drop: {
        paddingTop: 5,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    add: {
        padding: 5,
        margin: 1,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    typo: {
        paddingTop: 7,
        paddingLeft: 18,
    },
    privateButton:{
        padding: 0,
        backgroundColor: '#dcefdc',
    },
    publicButton:{
        padding: 0,
        backgroundColor: '#ffe6e6',
    },
    showMore: {
        width: '100%',
    },
})

class Notes extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            notes: [],
            dropdown: [],
            filter: {
                contact_type: 'All',
            },
            showMore: false
        }
    }

    componentWillMount() {
        if (this.props.crmData) {
            this.setState({ crmData: this.props.crmData })
            this.getColumns(this.props.crmData.crm_id)
            this.getDropdown()
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.crmData !== nextProps.crmData) {
            this.setState({ crmData: nextProps.crmData })
            this.getColumns(nextProps.crmData.crm_id)
            this.getDropdown()
        }
    }

    getColumns = id => {
        let userData = JSON.parse(sessionStorage.getItem('userData'))

        const data = {
            action: 'getContactNotes',
            crm_id: id,
            strategic_business_unit:
                userData === null ? null : userData.strategic_business_unit,
        }

        if (this.props.quoteNumber) {
            data.quoteNumber = this.props.quoteNumber
        }

        if (this.props.workOrderNumber) {
            data.workOrderNumber = this.props.workOrderNumber
        }

        PostData('CRM/ajax/ajaxContact_Notes.php', data).then(response => {
            if (response.result) {
                this.setState({ notes: response.ContactNotes })
            }
        })
    }

    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'NOTES',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }

    

    viewFilterDropdown = (control, id, name) => {
        const { classes } = this.props
        let list = []
        if (this.state.dropdown.length > 0) {
            this.state.dropdown.forEach(item => {
                if (item.control_code == control) {
                    list.push({ name: item.item_name, code: item.item_code })
                }
            })
        }

        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                className={classes.drop}
                onChange={this.handleFilterChange}
                defaultValue="All"
                value={this.state.filter[id]}
                InputProps={{
                    disableUnderline: true,
                }}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    handleFilterChange = event => {
        let change = this.state.filter
        change[event.target.name] = event.target.value
        this.setState({ filter: change })
    }

    handleToggle = () => {
        this.setState({ showMore: !this.state.showMore })
    }

    render() {
        const { classes } = this.props
        let sort = []
        let count = 0

        if (this.state.notes) {
            this.state.notes.forEach(note => {
                if (this.state.showMore || count < 10) {
                    if (this.state.filter.contact_type == 'All') {
                        sort.push(note)
                    } else if (this.state.filter.contact_type == note.contact_type) {
                        sort.push(note)
                    }
                }

                count++
            })
        }

            return (
                <React.Fragment>
                    <Card>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.secondary}
                                >
                                    <Grid item xs={11}>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            color="inherit"
                                            className={classes.textField}
                                        >
                                            Notes
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    className={classes.sort}
                                >
                                    <Grid item>
                                        <Typography
                                            component="h6"
                                            color="inherit"
                                            className={classes.typo}
                                        >
                                            Contact Type:
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        {this.viewFilterDropdown(
                                            'SORT_CONTACT_TYPE',
                                            'contact_type',
                                            'Contact Type'
                                        )}
                                    </Grid>
                                    <Grid item className={classes.addGrid}>
                                        <Tooltip title={'Add Note'}>
                                            <Button
                                                className={classes.add}
                                                onClick={() => {
                                                    this.props.handleFormOpen(
                                                        'Note'
                                                    )
                                                }}
                                            >
                                                Add Note
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    color="black"
                                                    style={{
                                                        marginLeft: 5,
                                                    }}
                                                />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>

                                <Divider />
                                

                                {/* </Card> */}
                            </Grid>

                            {this.state.notes && sort.map(
                                note => (
                                    <Grid item xs={12}>
                                        <NoteEditor
                                            setFormData={this.props.setFormData}
                                            classes={classes}
                                            note={note}
                                            style={{ marginLeft: 10 }}
                                        />
                                        <Divider style={{ marginTop: 15 }} />
                                        
                                    </Grid>
                                ),
                                this
                            )}

                            {count > 10 && (
                                <Grid item xs={12}>
                                    <Button
                                        className={classes.showMore}
                                        onClick={this.handleToggle}
                                    >
                                                {this.state.showMore ? (
                                                    <div>
                                                        <FontAwesomeIcon
                                                            icon={faChevronUp}
                                                            //size="2x"
                                                        />
                                                        &nbsp;1 to {count} of {count}
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <FontAwesomeIcon
                                                            icon={faChevronDown}
                                                            //size="2x"
                                                        />
                                                        &nbsp;1 to 10 of {count}
                                                    </div>
                                                )}
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </Card>
                </React.Fragment>
            )
    }
}

class NoteEditor extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            more: false,
            isOverflowed: false,
            didOverflow: false,
            note: {},
        }

        this.handleToggle = this.handleToggle.bind(this)
    }

    handleToggle = () => {
        this.setState({ more: !this.state.more })
    }

    componentWillMount() {
        this.setState({note: this.props.note})
    }

    handlePrivacyChange = () =>{
        let change = this.state.note
        if(change.privacy_status === '1'){
            change.privacy_status = '2'
        }
        else{
            change.privacy_status = '1'
        }
        this.setState({note: change})

        let data = {
            action: 'update_contact_notes_table',
            recordid: this.state.note.recordid,
            privacy_status: this.state.note.privacy_status
        }

        PostData('CRM/ajax/ajaxContact_Notes.php', data).then(
            response => {
            }
        )
    }

    handleOverflowChange = isOverflowed => {
        console.log(isOverflowed)
        this.setState({ didOverflow: true, isOverflowed: isOverflowed })
    }

    render() {
        const classes = this.props.classes
        let note = {...this.state.note}
        note.isEditing = true

        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={4} md={3} lg={2}>
                        <Grid container spacing={0}>
                            <Grid item xs={12}>
                                <Typography
                                    component="h2"
                                    variant="h6"
                                    color="inherit"
                                    //noWrap
                                    className={classes.subjectLine}
                                    style={{ textAlign: 'left' }}
                                >
                                    {note.subject}
                                </Typography>
                            </Grid>

                            <Grid item xs={12} >
                                <Typography
                                    component="p"
                                    variant="caption"
                                    color="inherit"
                                    //noWrap
                                    className={classes.textField}
                                    style={{ textAlign: 'left' }}
                                >
                                    {formatDate(note.contact_date) +
                                        ' ' +
                                        (note.contact_time === null ? '' : formatTime(note.contact_time))}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} >
                                <Typography
                                    component="p"
                                    variant="caption"
                                    color="inherit"
                                    className={classes.textField}
                                    style={{ textAlign: 'left' }}
                                >
                                    {'By: ' + note.contact_by}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    component="p"
                                    variant="caption"
                                    color="inherit"
                                    className={classes.textField}
                                    style={{ textAlign: 'left' }}
                                >
                                    {'Type: ' + note.contact_type}
                                </Typography>
                            </Grid>
                            
                            {note.utility_contact_name && (<Grid item xs={12}>
                                <Typography
                                    component="p"
                                    variant="caption"
                                    color="inherit"
                                    className={classes.textField}
                                    style={{ textAlign: 'left' }}
                                >
                                    {'To: ' + note.utility_contact_name}
                                </Typography>
                            </Grid>)}

                            {note.lead_source && (<Grid item xs={12} >
                                <Typography
                                    component="p"
                                    variant="caption"
                                    color="inherit"
                                    className={classes.textField}
                                    style={{ textAlign: 'left' }}
                                >
                                    {'Source: ' + note.lead_source}
                                </Typography>
                            </Grid>)}

                            {note.campaign && (<Grid item xs={12} >
                                <Typography
                                    component="p"
                                    variant="caption"
                                    color="inherit"
                                    className={classes.textField}
                                    style={{ textAlign: 'left' }}
                                >
                                    {'Campaign: ' + note.campaign}
                                </Typography>
                            </Grid>)}
                            {note.quote_number && (<Grid item xs={12} >
                                <Typography
                                    component="p"
                                    variant="caption"
                                    color="inherit"
                                    className={classes.textField}
                                    style={{ textAlign: 'left' }}
                                >
                                    {'Quote: ' + note.quote_number}
                                </Typography>
                            </Grid>)}
                            <Grid item xs={12} >
                                {note.create_by === JSON.parse(sessionStorage.getItem('userData')).userid ? (
                                    <Button className={note.privacy_status === '1' ? classes.privateButton : classes.publicButton} onClick={this.handlePrivacyChange} id={note.recordid} >
                                        <Typography
                                            component="p"
                                            variant="caption"
                                            color="inherit"
                                            className={classes.buttontextField}
                                            style={{ textAlign: 'left' }}
                                        >
                                            {note.privacy_status === '1' ? 'PUBLIC' : 'PRIVATE'}
                                        </Typography>
                                    </Button>
                                ) : (
                                    <Typography
                                            component="p"
                                            variant="caption"
                                            color="inherit"
                                            className={classes.buttontextField}
                                            style={{ textAlign: 'left' }}
                                        >
                                            {note.privacy_status === '1' ? 'PUBLIC' : 'PRIVATE'}
                                        </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={7} md={8} lg={9}>
                        {/* <CKEditor
                            isReadOnly={true}
                            data={noteData}
                            config={{
                                removePlugins: ['elementspath', 'resize'],
                                toolbar: [],
                                height: 95,
                                width: ['98%'],
                            }}
                        /> */}

                        <div style={
                            { 
                                maxHeight: (this.state.more ? 'none' : 80), 
                                overflow: (this.state.more ? 'visible' : 'hidden') 
                            }
                        }>
                            <OverflowDetector
                                onOverflowChange={this.handleOverflowChange}
                                style={{ width: '100%' }}
                            >
                                <div  
                                    dangerouslySetInnerHTML={{ __html: note.notes }} 
                                    style={
                                        { 
                                            maxHeight: (this.state.more ? 'none' : 75), 
                                            overflow: 'visile' 
                                        }
                                    }
                                />
                            </OverflowDetector>
                        </div>

                        {this.state.didOverflow && !this.state.more && (
                            <Button
                                color="inherit"
                                onClick={this.handleToggle}
                                style={{ width: '100%' }}
                            >
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    size="1x"
                                    style={{
                                        marginRight: 10,
                                    }}
                                />
                                Show More
                            </Button>
                        )}

                        {this.state.more && (
                            <Button
                                color="inherit"
                                onClick={this.handleToggle}
                                style={{ width: '100%' }}
                            >
                                <FontAwesomeIcon
                                    icon={faChevronUp}
                                    size="1x"
                                    style={{
                                        marginRight: 10,
                                    }}
                                />
                                Show Less
                            </Button>
                        )}
                    </Grid>

                    <Grid item xs={1} md={1} lg={1}>
                        <Button
                            color="inherit"
                            onClick={() => {
                                this.props.setFormData(note, 'Note')
                            }}
                            style={{ width: '100%' }}
                        >
                            <FontAwesomeIcon
                                icon={faPencil}
                                size="1x"
                                style={{
                                    marginRight: 10,
                                }}
                            />
                            Edit
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

Notes.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Notes)
