import React from 'react'
import MenuList from '@material-ui/core/MenuList'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import { makeStyles } from '@material-ui/core/styles'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Typography from '@material-ui/core/Typography'
import DraftsIcon from '@material-ui/icons/Drafts'
import SendIcon from '@material-ui/icons/Send'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import { withStyles } from '@material-ui/core/styles'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { PostData } from '../../../services/PostData'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import IconButton from '@material-ui/core/IconButton'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import Tooltip from '@material-ui/core/Tooltip'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import { faBold } from '@fortawesome/free-solid-svg-icons'
import NumberFormat from 'react-number-format'

const styles = theme => ({
    root: {
        width: 'flex',
    },
    sort: {
        textAlign: 'center',
    },
    drawer: {
        backgroundColor: theme.palette.secondary.paper,
        //color: 'white',
    },
    typo: {
        paddingTop: 4,
        paddingLeft: 18,
    },
    lines: {
        cursor: 'pointer',
        backgroundColor: theme.palette.secondary.paper,
    },
    selected: {
        backgroundColor: 'rgb(230,230,230)',
        color: theme.palette.secondary.main.dark,
        fontWeight: '500',
    },
    showMore: {
        width: '100%',
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        //marginLeft: theme.spacing(1),
        width: '97%',
        //background: 'rgb(248,248,248)',
    },
    list: {
        marginTop: theme.spacing(1),
        borderTop: '2px solid rgb(232,232,232)',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },
    add: {
        padding: 5,
        margin: 1,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    highlight: {
        cursor: 'pointer',
    },
})

class Products extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            active: '',
            more: false,
            filter: {
                status: 'PURCHASED',
                purchased_state: 'ALL',
            },
            dropdown: [],
        }
        this.handleToggle = this.handleToggle.bind(this)
    }

    componentWillMount() {
        if (
            this.props.crmData !== undefined &&
            this.props.crmData.crm_id !== undefined
        ) {
            this.getData(this.props.crmData.crm_id)
        }
        this.getDropdown()
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.crmData !== this.state.crmData || nextProps.loading === 'Products') {
            this.setState({ crmData: nextProps.crmData })
            this.getData(nextProps.crmData.crm_id)
        }
    }

    handleToggle = () => {
        this.setState({ more: !this.state.more })
    }

    getMoreButton() {
        let arr = []
        this.state.data && this.state.data.forEach(product => {
                
            if (this.state.active == product.product_line) {
                
                if (
                    this.state.filter.purchased_state == 'ALL' ||
                    product.purchased_state ==
                        this.state.filter.purchased_state 
                ) {
                    
                    if (
                        this.state.filter.status == 'ALL' ||
                        product.status == this.state.filter.status ||
                        (this.state.filter.status === 'NOT_PURCHASED' && (!product.status || product.status.toLowerCase() !== 'purchased' ))
                    ) {
                        
                        arr.push(product)
                    } else if (
                        this.state.filter.status == 'AVAILABLE' &&
                        product.status === null
                    ) {
                        
                        arr.push(product)
                    }
                }
            }
        })
        let totalLength = arr.length
        if(!this.state.more){
            arr = arr.slice(0,5)
        }

        const length = arr.length
        const { classes } = this.props
        if (totalLength > 5) {
            return (
                <Grid container xs={12}>
                    <Button
                        className={classes.showMore}
                        onClick={this.handleToggle}
                    >
                        {this.state.more ? (
                            <div>
                                <FontAwesomeIcon
                                    icon={faChevronUp}
                                    size="1x"
                                    style={{
                                        marginRight: 10,
                                    }}
                                />
                                1 to {length} of {totalLength}
                            </div>
                        ) : (
                            <div>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    size="1x"
                                    style={{
                                        marginRight: 10,
                                    }}
                                />
                                1 to 5 of {totalLength}
                            </div>
                        )}
                    </Button>
                </Grid>
            )
        }
    }

    handleFilterChange = event => {
        let change = this.state.filter
        change[event.target.name] = event.target.value
        this.setState({ filter: change })
    }

    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'PRODUCTS',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }

    viewFilterDropdown = (control, id, name) => {
        const { classes } = this.props
        let list = []
        if (this.state.dropdown.length > 0) {
            this.state.dropdown.forEach(item => {
                if (item.control_code == control) {
                    list.push({ name: item.item_name, code: item.item_code })
                }
            })
        }

        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                className={classes.drop}
                onChange={this.handleFilterChange}
                defaultValue="ALL"
                value={this.state.filter[id]}
                InputProps={{
                    disableUnderline: true,
                }}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    getData = crm_id => {
        let userData = JSON.parse(sessionStorage.getItem('userData'))

        const data1 = {
            action: 'getClientInfo',
            crm_id: crm_id,
            strategic_business_unit:
                userData === null ? null : userData.strategic_business_unit,
        }

        let sbu_client_id = []
        PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data1).then(response1 => {
            if (response1.result && response1.ClientInfo) {
                let clientid = response1.ClientInfo[0]
                    ? response1.ClientInfo[0].sbu_client_id
                    : ''

                const data = {
                    action: 'GetClientsWithModules2',
                    hug_schema:
                        'hug_' +
                        (userData.strategic_business_unit.toLowerCase()),
                    sbu:
                        userData === null
                            ? null
                            : userData.strategic_business_unit,
                    clientid: clientid,
                }

                PostData(
                    'CustomerModuleSearch/ajax/ajaxCustomerModulesSearch.php',
                    data
                ).then(response => {
                    if (response.result) {
                        //console.log(response)
                        this.setState({ data: response.ClientsWithModules, sbu_client_id:  clientid})
                    }
                })
            }
        })
        /*console.log(sbu_client_id)
        let whereclause = 'WHERE'
        for(let i = 0; i<sbu_client_id.length; i++){
            console.log(sbu_client_id[i])
            whereclause = whereclause + ' sbu_client_id=' + "'" + sbu_client_id[i] + "' OR" 
        }
    
        whereclause = whereclause.slice(0,-2)
        console.log(whereclause)
        const data = {
            action: 'GetClientsWithModules2',
            hug_schema: 'hug_horizons',
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
            whereclause: whereclause,
        }

        PostData(
            'CustomerModuleSearch/ajax/ajaxCustomerModulesSearch.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ data: response.ClientsWithModules })
            }
        })*/
    }

    handleOpen = product => {
        product['sbu_client_id'] = this.state.sbu_client_id
        this.props.setFormData(product, 'Products')
    }

    handleClick = product => {
        this.setState({ active: product })
    }

    //displays product list
    viewProduct = product => {
        const { classes } = this.props
        if (this.state.active == product) {
            return (
                <ListItem
                    className={classes.selected}
                    onClick={() => this.handleClick(product)}
                >
                    {product}
                </ListItem>
            )
        } else {
            return (
                <ListItem
                    className={classes.lines}
                    onClick={() => this.handleClick(product)}
                >
                    {product}
                </ListItem>
            )
        }
    }

    //gets products list
    getProduct = () => {
        let arr = []
        if (this.state.data) {
            this.state.data.forEach(product => {
                if (!arr.includes(product.product_line)  
                    //(product.status == this.state.filter.status || this.state.filter.status === 'ALL') 
                ) {
                    arr.push(product.product_line)
                }
            })

            let products = arr.map(product => this.viewProduct(product), this)
            return products
        }
    }

    toTitleCase = str => {
        if (str !== null) {
            str = str.replace(/_/g, ' ')
            return str.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
        }
    }

    //gets products for the product module selected
    getMain = () => {
        let arr = []
        if (this.state.data) {
            this.state.data.forEach(product => {
                
                if (this.state.active == product.product_line) {
                    
                    if (
                        this.state.filter.purchased_state == 'ALL' ||
                        product.purchased_state ==
                            this.state.filter.purchased_state 
                    ) {
                        
                        if (
                            this.state.filter.status == 'ALL' ||
                            product.status == this.state.filter.status ||
                            (this.state.filter.status === 'NOT_PURCHASED' && (!product.status || product.status.toLowerCase() !== 'purchased' ))
                        ) {
                            
                            arr.push(product)
                        } else if (
                            this.state.filter.status == 'AVAILABLE' && (product.status === null || product.status === 'NOT_PURCHASED')
                        ) {
                            
                            arr.push(product)
                        }
                    }
                }
            })
            if(!this.state.more){
                arr = arr.slice(0,5)
            }
            
            let products = arr.map(product => this.mainScreen(product), this)

            return products
        }
    }

    //displays table of products
    mainScreen = product => {
        const { classes } = this.props

        let quantity = 0
        if (product.quantity !== undefined && product.quantity !== null && product.quantity !== 0) {
            quantity += Number(product.quantity)
        }
        if (product.purchased_qty !== undefined && product.purchased_qty !== null && product.purchased_qty !== 0) {
            quantity += Number(product.purchased_qty)
        }

        let maintenance_cost = 0
        if (product.maint_cost !== undefined && product.maint_cost !== null && product.maint_cost !== 0) {
            maintenance_cost += Number(product.maint_cost)
        }
        if (product.new_maint_cost !== undefined && product.new_maint_cost !== null && product.new_maint_cost !== 0) {
            maintenance_cost += Number(product.new_maint_cost)
        }

        return (
            <TableRow onClick={() => this.handleOpen(product)}>
                <TableCell className={classes.highlight}>
                    {product.product_desc}
                </TableCell>
                <TableCell>{this.toTitleCase(product.status)}</TableCell>
                <TableCell>
                    {this.toTitleCase(product.purchased_state)}
                </TableCell>
                <TableCell align="right">
                    <NumberFormat
                        value={quantity}
                        displayType={'text'}
                        thousandSeparator={true}
                    />
                </TableCell>
                {/* <TableCell align="right">
                    <NumberFormat
                        value={product.purchased_qty}
                        displayType={'text'}
                        thousandSeparator={true}
                    />
                </TableCell> */}
                <TableCell align="right">
                    <NumberFormat
                        value={product.net_purchase_price}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                    />
                </TableCell>
                <TableCell align="right">
                    <NumberFormat
                        value={maintenance_cost}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                    />
                </TableCell>
            </TableRow>
        )
    }

    render() {
        const { classes } = this.props

        if (this.props.index !== 3) {
            return ''
        }
        //console.log(this.state)

        return (
            <React.Fragment>
                {/* <div style={hide ? {display: 'none'} : null}> */}
                <div className={classes.drawer}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            {/*<div className={classes.secondary}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            color="inherit"
                                            className={classes.textField}
                                        >
                                            Products
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.addGrid}>
                                        <Tooltip title={'Add Product'}>
                                            <IconButton 
                                                className={classes.add}
                                                onClick={() => this.handleOpen()}
                                            >
                                                <FontAwesomeIcon icon={faPlusCircle} color='white' />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </div>*/}
                            <Grid
                                container
                                spacing={1}
                                className={classes.sort}
                            >
                                <Grid item>
                                    <Typography
                                        component="h6"
                                        color="inherit"
                                        className={classes.typo}
                                    >
                                        Status:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {this.viewFilterDropdown(
                                        'STATUS_FILTER',
                                        'status',
                                        'Status'
                                    )}
                                </Grid>
                                <Grid item>
                                    <Typography
                                        component="h6"
                                        color="inherit"
                                        className={classes.typo}
                                    >
                                        Stage:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {this.viewFilterDropdown(
                                        'STATE_FILTER',
                                        'purchased_state',
                                        'Purchased State'
                                    )}
                                </Grid>
                            </Grid>
                            <Divider variant="middle" />
                        </Grid>

                        <Grid
                            item
                            xs={3}
                            className={classes.list}
                            style={{ maxHeight: 300, overflow: 'auto' }}
                        >
                            <List disablePadding={true}>
                                {this.getProduct()}
                            </List>
                        </Grid>
                        <Grid item xs={9}>
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Module</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Stage</TableCell>
                                        {/* <TableCell align="right">
                                            Quantity
                                        </TableCell> */}
                                        <TableCell align="right">
                                            Quantity
                                        </TableCell>
                                        <TableCell align="right">
                                            License Fee
                                        </TableCell>
                                        <TableCell align="right">
                                            Maintenance Cost
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{this.getMain()}</TableBody>
                            </Table>
                        </Grid>
                        {this.getMoreButton()}
                    </Grid>
                </div>
                {/* </div> */}
            </React.Fragment>
        )
    }
}

Products.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Products)
