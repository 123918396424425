import React from 'react'
import CompanySearch from '../../CompanySearch/CompanySearch'
import Company from '../../Company/Company'
import ReleaseNotes from '../../ReleaseNotes/ReleaseNotes'
import Quotes from '../../Sales/Quotes/Quotes'
import QuotesForm from '../../Company/Quotes/QuotesForm'
import CustomerList from '../../Sales/CustomerList/CustomerList'
import CustomerContacts from '../../Sales/CustomerContacts/CustomerContacts'
import CallCampaigns from '../../Sales/CallCampaigns/CallCampaigns'
import Tradeshows from '../../Sales/Tradeshows/Tradeshows'
import TotalFeesPerClient from '../../Reports/TotalFeesPerClient'
import OpportunityValuesByQuote from '../../Reports/OpportunityValuesByQuote'
import NotesSinceDate from '../../Reports/NotesSinceDate'
import { PostData } from '../../../services/PostData'
import WorkOrdersForm from '../../Company/WorkOrders/WorkOrdersForm'
import FixServices from '../../FixServices'

export default class MainContainer extends React.Component {
    constructor(props) {
        super(props)
    }

    componentWillMount() {
        
        if (this.props.main === 'Company') {
            this.setRecentCompanies(this.props)
        }
        this.setState({ props: this.props })
    }
    componentWillReceiveProps(nextProps) {
        
        if (nextProps.main === 'Company') {
            this.setRecentCompanies(nextProps)
            if(this.state.props.main === 'CompanySearch'){
                this.props.handleFormClose()
            }
            
        }
        this.setState({ props: nextProps })
    }



    setRecentCompanies = props => {
        //SECTION SETS RECENT COMPANIES
        if (props.crmData) {
            let id = props.crmData.crm_id
            if(!id){
                return
            }
            let recent_companies = JSON.parse(
                sessionStorage.getItem('userData')
            ).recent_companies
            let sessionData = JSON.parse(sessionStorage.getItem('userData'))
            if (!recent_companies) {
                recent_companies = []
            } else {
                if (typeof recent_companies === 'string') {
                    recent_companies = recent_companies.substr(1)
                    recent_companies = recent_companies.substr(
                        0,
                        recent_companies.length - 1
                    )
                    recent_companies = recent_companies.split(',')

                    for (let i = 0; i < recent_companies.length; i++) {
                        recent_companies[i] = recent_companies[i].substr(1)
                        recent_companies[i] = recent_companies[i].substr(
                            0,
                            recent_companies[i].length - 1
                        )
                    }
                }
                //console.log(recent_companies)
                let index = recent_companies.indexOf(id)
                if(index === 0){
                    return
                }

                if (index > -1) {
                    if (recent_companies.length > 1) {
                        recent_companies.splice(index, 1)
                    }
                }
                if (
                    recent_companies.length === 1 &&
                    recent_companies[0] === id
                ) {
                    recent_companies = []
                }
                if (recent_companies.length === 15) {
                    recent_companies.pop()
                }
            }
            recent_companies.unshift(id)
            const data = {
                action: 'set_recent_companies',
                user_id: JSON.parse(sessionStorage.getItem('userData')).userid,
                recent_companies: JSON.stringify(recent_companies),
            }
            PostData('ajax/ajaxHUGlogin.php', data).then(response => {
                //console.log(response)
            })
            sessionData.recent_companies = recent_companies
            sessionStorage.setItem('userData', JSON.stringify(sessionData))
        }
    }
    getMain = () => {
        switch (this.state.props.main) {
            case 'Company':
                return (
                    <Company
                        loading={this.state.props.loading}
                        companyId={this.state.props.companyId}
                        setCrmData={this.state.props.setCrmData}
                        setFormData={this.state.props.setFormData}
                        handleFormOpen={this.state.props.handleFormOpen}
                        handleDrawerClose={this.state.props.handleFormClose}
                        formOpen={this.state.props.formOpen}
                        crmData={this.state.props.crmData}
                        updateComponent={this.state.props.updateComponent}
                        update={this.state.props.update}
                    />
                )
            case 'CompanySearch':
                return (
                    <CompanySearch
                        setFormData={this.props.setFormData}
                        handleFormOpen={this.props.handleFormOpen}
                        handleFormClose={this.handleFormClose}
                    />
                )
            case 'ReleaseNotes':
                return <ReleaseNotes />
            case 'Quotes':
                return <Quotes
                            setFormData={this.props.setFormData}
                        />
            case 'QuotesForm':
                    return <QuotesForm 
                        formData={this.state.props.formData} 
                        crmData={this.state.props.crmData}
                        updateComponent={this.state.props.updateComponent}
                        handleFormClose={this.state.props.handleFormClose}
                        setFormData={this.state.props.setFormData}
                        setMain={this.state.props.setMain}
                    />
            case 'WorkOrdersForm':
                return <WorkOrdersForm 
                    formData={this.state.props.formData} 
                    crmData={this.state.props.crmData}
                    updateComponent={this.state.props.updateComponent}
                    handleFormClose={this.state.props.handleFormClose}
                    setFormData={this.state.props.setFormData}
                />
            case 'CustomerList':
                return <CustomerList
                            setFormData={this.state.props.setFormData}
                        />
            case 'ContactList':
                return <CustomerContacts
                            setFormData={this.state.props.setFormData}
                        />
            case 'CallCampaign':
                return <CallCampaigns
                            setFormData={this.state.props.setFormData}
                        />
            case 'Tradeshows': 
                return <Tradeshows
                            setFormData={this.state.prop.setFormData}
                        />
            case 'TotalFeesPerClient': 
                return <TotalFeesPerClient />
            case 'OpportunityValuesByQuote': 
                return <OpportunityValuesByQuote />
            case 'NotesSinceDate':
                return <NotesSinceDate />
            case 'FixServices':
                return <FixServices />
            default:
                return ''
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.getMain()}
            </React.Fragment>
        );
    }
}
