import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import { faGripHorizontal } from '@fortawesome/pro-light-svg-icons'
import { faGripLines } from '@fortawesome/pro-light-svg-icons'
import InputMask from 'react-input-mask'; 
import NumberFormat from 'react-number-format';



import { PostData } from '../../../services/PostData'
import ContactForm from './ContactForm'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronDown,
    faChevronUp,
    faPlusCircle,
} from '@fortawesome/pro-light-svg-icons'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },
    lowercase: {
        textTransform: 'none',
    },
    sort: {
        textAlign: 'center',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    add: {
        padding: 7,
        marginRight: 5,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    showMore: {
        width: '100%',
    },
    phoneNumber: {
        fontSize: 16,
    },
    listItem: {
        paddingVertical: 5,
    },
    typo: {
        paddingTop: 4,
        paddingLeft: 18,
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        width: '98%',
        //background: 'rgb(248,248,248)',
    },
    highlight: {
        cursor:'pointer',
    },
})

function Transition(props) {
    return <Slide direction="up" {...props} />
}

class Contacts extends Component {
    constructor(props) {
        super(props)

        this.state = {
            contacts: [],
            more: false,
            dropdown: [],
            filter: {
                role: 'ALL',
                keycontact: false,
            },
            gridView: false,
            bubbleView: true,
        }

        this.handleOpen = this.handleOpen.bind(this)
    }

    componentWillMount() {
        this.getDropdown()
        let userData = JSON.parse(sessionStorage.getItem('userData'))

        this.setState({ crmData: this.props.crmData })
        if (this.props.crmData) {
            const data = {
                action: 'getUtilityPersonnelList',
                crm_id: this.props.crmData.crm_id,
                sbu:
                    userData === null ? null : userData.strategic_business_unit,
                quote_type: 'NEW_NAMED',
            }

            PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {
                if (response.result) {
                    this.setState({ contacts: response.UtilityPersonnelList })
                } else {
                    
                }
            })
        }
    }
    componentWillReceiveProps(nextProps) {
        //console.log(nextProps)
        if (nextProps.crmData && nextProps.crmData !== this.state.crmData || nextProps.loading === 'Contacts') {
            this.getDropdown()
            this.setState({ crmData: nextProps.crmData })
            const data = {
                action: 'getUtilityPersonnelList',
                crm_id: nextProps.crmData.crm_id,
                sbu: JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,
                quote_type: 'NEW_NAMED',
            }

            PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {
                if (response.result) {
                    this.setState({ contacts: response.UtilityPersonnelList })
                } else {
                    
                }
            })
        }
    }
    handleToggle = () => {
        this.setState({ more: !this.state.more })
    }


    //opens side bar
    handleOpen = recordid => {
        const data = {
            action: 'editUtilityPersonnelInfo',
            recordid: recordid,
        }

        PostData('CRM/ajax/ajaxCRMUtilityPersonnel2018.php', data).then(
            response => {
                this.props.setFormData(response.rowUtilPersonnelRtn, 'Contact')
            }
        )
    }

    handleView1Change = () => {
        
        if (this.state.bubbleView == false) {
            this.setState({ bubbleView: true, gridView: false })
        }
    }
    handleView2Change = () => {
        
        if (this.state.gridView == false) {
            this.setState({ bubbleView: false, gridView: true })
        }
    }

    //displays all contacts in company
    getContactsList = contact => {
        const { classes } = this.props
        if (
            this.state.filter.role == 'ALL' ||
            this.state.filter.role == contact.contacts_role
        ) {
            if (this.state.bubbleView == true) {
                return (
                    <React.Fragment>
                        <Grid item xs={2}>
                            <Button
                                className={classes.lowercase}
                                onClick={() =>
                                    this.handleOpen(contact.recordid)
                                }
                            >
                                <ListItem>
                                    <ListItemText
                                        primary={contact.name}
                                        className={classes.listItem}
                                        secondary={
                                            <div>
                                                <div>{contact.title}</div>
                                                <div
                                                    className={
                                                        classes.phoneNumber
                                                    }
                                                ><i>
                                                    <NumberFormat         
                                                        id="phonenumber"
                                                        name="phonenumber"
                                                        label="Phone Number"
                                                        fullWidth
                                                        className={classes.phoneNumber}
                                                        customInput={TextField} 
                                                        value={contact.phonenumber}
                                                        displayType={'text'}
                                                        format='(###) ###-####'
                                                    /></i>
                                                </div>
                                            </div>
                                        }
                                    />
                                </ListItem>
                            </Button>
                        </Grid>
                    </React.Fragment>
                )
            } else {
                return (

            <TableRow onClick={() => this.handleOpen(contact.recordid)}>
                
                <TableCell className={classes.highlight}>{contact.name}</TableCell>
                <TableCell>{contact.title}</TableCell>
                <TableCell>
                    <NumberFormat         
                        id="phonenumber"
                        name="phonenumber"
                        label="Phone Number"
                        fullWidth
                        className={classes.textField}
                        customInput={TextField} 
                        value={contact.phonenumber}
                        displayType={'text'}
                        format='(###) ###-####'
                    /></TableCell>
                <TableCell>{contact.extension}</TableCell>
                <TableCell>{contact.email}</TableCell>
            </TableRow>
                    )
                }
        }
        else {
            return (<React.Fragment/>)
        }
    }

    viewGridView = inside => {
        const { classes } = this.props

            if(this.state.gridView == true){
                
                return (
                    <Table className={classes.table} size="small" >
                        <TableHead>
                            <TableRow>
                                
                                <TableCell>Name</TableCell>
                                <TableCell>Title</TableCell>
                                <TableCell>Phone Number</TableCell>
                                <TableCell>Ext</TableCell>
                                <TableCell>Email</TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>{inside}</TableBody>
                    </Table>
                )
            }
    }

    handleFilterChange = event => {
        let change = this.state.filter
        change[event.target.name] = event.target.value
        this.setState({ filter: change })
    }

    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'CONTACT',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }

    viewFilterDropdown = (control, id, name) => {
        const { classes } = this.props
        let list = []
        if(this.state.dropdown.length > 0){
            this.state.dropdown.forEach(item =>{
                if (item.control_code == control ){
                    
                    list.push({name: item.item_name, code: item.item_code})
                }
            })
        }
        
        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                className={classes.drop}
                onChange={this.handleFilterChange}
                defaultValue="ALL"
                value={this.state.filter[id]}
                InputProps={{
                    disableUnderline: true,
                }}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    //shows ALL contacts if show more is selected
    showMore = () => {
        if (!this.state.contacts) {
            return <React.Fragment />
        }

        let arr = this.state.contacts.map(
            contact => this.getContactsList(contact),
            this
        )
        let newarr = []
        for (let i = 0; i < arr.length; i++) {
            newarr[i] = arr[i]
        }

        newarr[newarr.length + 1] = (
            <React.Fragment>
                <Grid item xs={true}>
                </Grid>
            </React.Fragment>
        )

        if (this.state.gridView == true) {
            return this.viewGridView(newarr)
        } else return newarr
    }

    //Shows only 6 contacts by default
    showLess = () => {
        if (!this.state.contacts) {
            return <React.Fragment />
        }

        let arr = this.state.contacts.map(
            contact => this.getContactsList(contact),
            this
        )
        let newarr = []

        for (let i = 0; i < 6; i++) {
            newarr[i] = arr[i]
        }
        if (this.state.gridView == true) {
            return this.viewGridView(newarr)
        } else return newarr
    }

    //Toggles show more and show less
    theToggle = () => {
        if (this.state.more == true) {
            return this.showMore()
        } else {
            return this.showLess()
        }
    }

    render() {
        const { classes } = this.props
        const length = this.state.contacts && this.state.contacts.filter(contact =>{
            if (
                this.state.filter.role == 'ALL' ||
                this.state.filter.role == contact.contacts_role
            ){
                return true
            }else{
                return false
            }
        }).length
        return (
            <React.Fragment>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <Card>
                            <div className={classes.secondary}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            color="inherit"
                                            className={classes.textField}
                                        >
                                            People
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid
                                container
                                spacing={1}
                                className={classes.sort}
                            >
                                <Grid item>
                                    <Typography
                                        component="h6"
                                        color="inherit"
                                        className={classes.typo}
                                    >
                                        Role:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {this.viewFilterDropdown(
                                        'SORT_ROLE',
                                        'role',
                                        'Role'
                                    )}
                                </Grid>

                                <Grid item>
                                    <Tooltip title={'Bubble View'}>
                                        <IconButton
                                            onClick={this.handleView1Change}
                                            size="small"
                                            className={classes.add}
                                            style={this.state.bubbleView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripHorizontal}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={'Grid View'}>
                                        <IconButton
                                            onClick={this.handleView2Change}
                                            size="small"
                                            className={classes.add}
                                            style={this.state.gridView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripLines}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                                <Grid item className={classes.addGrid}>
                                        <Tooltip title={'Add Person'}>
                                            <Button
                                                className={classes.add}
                                                onClick={() =>
                                                    this.handleOpen('0')
                                                }
                                                style={{
                                                    color: 'black'
                                                }}
                                            >
                                                Add Person
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    color="black"
                                                    style={{
                                                        marginLeft: 5,
                                                    }}
                                                />
                                            </Button>
                                        </Tooltip>
                                </Grid>  

                                
                                
                            </Grid>

                            <Divider variant="middle" />

                            <Grid container spacing={0} justify="space-evenly">
                                {this.theToggle()}
                            </Grid>
                            <Grid container>
                                {this.state.contacts && length > 6 && <Button
                                    className={classes.showMore}
                                    onClick={this.handleToggle}
                                >
                                    {this.state.more  ? (
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faChevronUp}
                                                //size="2x"
                                            />
                                            &nbsp;1 to {length} of {length}
                                        </div>
                                    ) : (
                                        <div>
                                            <FontAwesomeIcon
                                                icon={faChevronDown}
                                                //size="2x"
                                            />
                                            &nbsp;1 to 6 of {length}
                                        </div>
                                    )}
                                </Button>}
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                <div>
                    <Dialog
                        //fullScreen
                        fullWidth
                        maxWidth="lg"
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                    >
                        {/* <AppBar >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    onClick={this.handleClose}
                                    aria-label="Close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <div className={classes.flex} />
                                <Button
                                    color="inherit"
                                    onClick={this.handleSave}
                                >
                                    save
                                </Button>
                            </Toolbar>
                        </AppBar> */}

                        <ContactForm contactData={this.state.contactData} />
                    </Dialog>
                </div>
            </React.Fragment>
        )
    }
}

Contacts.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Contacts)