import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import {
    Modal,
    Paper,
    Menu,
    TableCell,
    IconButton,
    Icon,
    MenuItem,
    ListItem,
    List,
    ListItemText,
    InputBase,
    Tooltip,
    Grid,
    Button,
    Typography,
    Collapse,
    TextField,
    CircularProgress
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {CSVLink, CSVDownload} from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

import EnhancedTable from '../Reusable/EnhancedTable'
import { PostData } from '../../services/PostData'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    lower: {
        textTransform: 'capitalize',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    pullRight: {
        float: 'right',
    },
})

const rows = [
    {
        id: 'sbu_client_id',
        numeric: false,
        disablePadding: false,
        label: 'Client',
    },
    {
        id: 'saas',
        numeric: true,
        disablePadding: false,
        label: 'SAAS',
    },
    {
        id: 'subscription',
        numeric: true,
        disablePadding: false,
        label: 'Subscription',
    },
    {
        id: 'maintenance',
        numeric: true,
        disablePadding: false,
        label: 'Maintenance',
    },
    {
        id: 'total',
        numeric: true,
        disablePadding: false,
        label: 'Total',
    },
    // {
    //     id: 'openTickets',
    //     numeric: true,
    //     disablePadding: false,
    //     label: 'Open TS Tickets',
    // }
]

class TotalFeesPerClient extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            originalColumns: [],
            columns: [],
            ticketsLoading: true,
            search: null
        }
    }

    handleSearchChange = (event) => {
        this.setState({ search: event.target.value })
    }

    handleSearchClick = () => {
        let columns = this.state.originalColumns
        let search = this.state.search.charAt(0).toUpperCase() + this.state.search.slice(1)
        search = new RegExp(search, 'g')
        
        columns = columns.filter(column => {
            return column.name.match(search)
        })

        this.setState({ columns: columns })
    }

    componentWillMount() {
        const userData = JSON.parse(sessionStorage.getItem('userData'))

        let data = {
            action: 'getPSInfo',
            strategic_business_unit: userData.strategic_business_unit,
            hug_schema: 'hug_' + userData.strategic_business_unit.toLowerCase(),
        }

        PostData('MaintenanceAgreements/ajax/ajaxUtilAnnualCharges.php', data).then(response =>{
            this.setState({ columns: response.columns, originalColumns: response.columns })

            // let ticketData = {
            //     action: 'get_OPEN_TeamSupportTickets',
            //     sbu: 'dataVoice'
            // }
    
            // PostData('TeamSupport/ajax/ajaxT_Support.php', ticketData).then(tickets =>{
            //     let columns = this.state.columns

            //     tickets.resultSet.Tickets.map(ticket => {
            //         if (ticket.Customers !== null && ticket.Customers !== undefined) {
            //             columns.map(column => {
            //                 if (column.name === ticket.Customers.Customer.CustomerName) {
            //                     column.ticketCount++
            //                 }
            //             })
            //         }
            //     })

            //     this.setState({ columns: columns, ticketsLoading: false })
            // })
        })
    }

    render() {
        const { classes } = this.props

        return (
            <EnhancedTable
                className={classes.secondary}
                rows={rows}
                columns={this.state.columns}
                showHeader={true}
                // link={this.openQuote}
                toolbar={
                    <CustomToolbar
                        classes={classes}
                        handleSearchClick={this.handleSearchClick}
                        handleSearchChange={this.handleSearchChange}
                        handleFilterChange={this.handleFilterChange}
                        csvData={this.state.columns}
                        // status={this.state.status}
                        // dropdown={this.state.dropdown}
                        // year={this.state.year}
                    />
                }
                tableRow={<CustomTableCells loading={this.state.ticketsLoading} />}
                totalRow={<CustomTotalRow clients={this.state.columns} />}
            />
        )
    }
}

class CustomTableCells extends React.Component {    
    render() {
        const n = this.props.n

        return (
            <React.Fragment >
                <TableCell padding="checkbox" />
                <TableCell style={{cursor: 'pointer'}} component="th" scope="row" padding={'default'}>
                    {n.name}
                </TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">${n.saas ? n.saas.toFixed(2) : 0}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">${n.subscription ? n.subscription.toFixed(2) : 0}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">${n.maintenance ? n.maintenance.toFixed(2) : 0}</TableCell>
                <TableCell style={{cursor: 'pointer'}} align="right">${Number(n.saas.toFixed(2)) + Number(n.subscription.toFixed(2)) + Number(n.maintenance.toFixed(2))}</TableCell>
                {/* <TableCell style={{cursor: 'pointer'}} align="right">
                    {this.props.loading ? (
                        <CircularProgress size={10} />
                    ) : n.ticketCount}
                </TableCell> */}
            </React.Fragment>
        )
    }
}

class CustomTotalRow extends React.Component {
    render() {
        let saasTotal = 0
        let subscriptionTotal = 0
        let maintenanceTotal = 0
        
        this.props.clients.map(client => {
            saasTotal += Number(client.saas)
            subscriptionTotal += Number(client.subscription)
            maintenanceTotal += Number(client.maintenance)
        })

        return (
            <React.Fragment>
                <TableCell padding="checkbox" />
                <TableCell style={{cursor: 'pointer'}} component="th" scope="row" padding={'default'}>
                    Totals:
                </TableCell>
                <TableCell align="right">${saasTotal.toFixed(2)}</TableCell>
                <TableCell align="right">${subscriptionTotal.toFixed(2)}</TableCell>
                <TableCell align="right">${maintenanceTotal.toFixed(2)}</TableCell>
                <TableCell align="right">${(Number(saasTotal.toFixed(2)) + Number(subscriptionTotal.toFixed(2)) + Number(maintenanceTotal.toFixed(2))).toFixed(2)}</TableCell>
            </React.Fragment>
        )
    }
}


class CustomToolbar extends React.Component {
    render() {
        // let status = []
        // let year = []
        // this.props.dropdown && this.props.dropdown.forEach(dropdown =>{
            
        //     if(dropdown.control_code === 'SORT_STATUS'){
        //         status.push(dropdown)
        //     }
        //     if(dropdown.control_code === 'SORT_YEAR' ){
        //         year.push(dropdown)
        //     }
        // })
        
        let csvData = this.props.csvData

        const { classes } = this.props
        return (
            <React.Fragment>
                <Grid spacing={5} container>
                    <Grid item xs={2} style={{ paddingTop: 5, marginTop: 35 }}>
                        <InputBase
                            className={this.props.classes.input}
                            placeholder={'Search'}
                            onChange={this.props.handleSearchChange}
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    this.props.handleSearchClick()
                                    ev.preventDefault()
                                }
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} style={{ marginTop: 10 }}>
                        <IconButton
                            className={this.props.classes.iconButton}
                            aria-label={'Search'}
                            onClick={
                                this.props.handleSearchClick
                            }
                        >
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                    {/* <Grid item xs={4} sm={3} md={2} lg={1} xl={1} style={{marginTop: 5}}>
                        <TextField
                            value={this.props.status}
                            name='status'
                            id='status'
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            label="Quote Status"
                        >
                            {status && status.map(item =>(
                                <MenuItem value={item.item_name} key={item.item_name} >
                                    {item.item_name}
                                </MenuItem>)
                                )}
                        </TextField>
                    </Grid> */}
                    <Grid item xs={1} style={{marginTop: 10}}>
                        <CSVLink data={csvData} style={{textDecoration: 'none'}}>
                            <Button style={{textDecoration: 'none'}}>
                                <FontAwesomeIcon
                                    icon={faFileDownload}
                                    size="1x"
                                    style={{
                                        color: 'black',
                                        marginRight: 10,
                                    }}
                                />
                                Export
                            </Button>
                        </CSVLink>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

TotalFeesPerClient.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TotalFeesPerClient)
