import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'

const styles = theme => ({
    error: {
        backgroundColor: theme.palette.error,
    },
})

class SimpleSnackbar extends React.Component {
    render() {
        const { classes } = this.props

        return (
            <Snackbar
                open={this.props.open}
                autoHideDuration={5000}
                onClose={this.props.handleClose}
            >
                <SnackbarContent
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={
                        <span id="message-id">
                            We did not recognize this user ID or password
                        </span>
                    }
                    style={{ backgroundColor: '#f44336' }}
                />
            </Snackbar>
        )
    }
}

SimpleSnackbar.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SimpleSnackbar)
