import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import store from '../../../store/Store'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'
import FullScreenDialog from '../../Reusable/FullScreenDialog'

import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import { CardMedia } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Icon from '@material-ui/core/Icon'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Tooltip from '@material-ui/core/Tooltip'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import { faEdit } from '@fortawesome/pro-light-svg-icons'

import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

import SystemsForm from './SystemsForm'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },
    lowercase: {
        textTransform: 'none',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    add: {
        padding: 5,
        margin: 1,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    lines:{
        cursor:'pointer',
        backgroundColor: theme.palette.secondary.paper,
    },
    selected: {
        backgroundColor: 'rgb(230,230,230)',
        color: theme.palette.secondary.main.dark,
        fontWeight: '500',
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        //marginLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '98%',
        //background: 'rgb(248,248,248)',
    },
    list: {
        marginTop: theme.spacing(1),
        borderTop: '2px solid rgb(232,232,232)',
    },
    highlight: {
        cursor:'pointer',
    },
})

function Transition(props) {
    return <Slide direction="up" {...props} />
}



function createColumn(
    system_recordid,
    system_type,
    system_name,
    active,
    notes,
    modified_datetime,
    created_datetime
) {
    if (system_name === 'none') {
        system_name = ''
    }
    
    return {
        system_recordid,
        system_type,
        system_name,
        active,
        notes,
        modified_datetime,
        created_datetime
    }
}

class Systems extends Component {
    constructor(props) {
        super(props)

        this.state = {
            columns: [],
            inactive: [],
            more: false,
            crmData: {},
            active: 'Customer Service',
            dropdown: [],
        }

        this.handleOpen = this.handleOpen.bind(this)
    }

    componentWillMount() {
        this.getDropdown()
        if(this.props.crmData){
            this.getColumns(this.props.crmData.crm_id)
            this.setState({crmData: this.props.crmData})
        }
    }

    componentWillReceiveProps(nextProps) {
        
        if (nextProps.crmData !== this.state.crmData) {
            this.getDropdown()
            
            this.setState({ crmData: nextProps.crmData })
            nextProps.crmData && this.getColumns(nextProps.crmData.crm_id)
        }
        
    }
    //Gets all field information
    getColumns = (id) => {
        
        if (id) {
            const data = {
                action: 'getUtilitySystems',
                crm_id: id,
            }

            PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {
                
                if (response.result) {
                    
                    let columns = []
                    let inactive = []
                    if (response.UtilitySystems) {
                        response.UtilitySystems.forEach(system => {
                            columns.push(
                                createColumn(
                                    system.system_recordid,
                                    system.system_type,
                                    system.system_name,
                                    system.active,
                                    system.notes,
                                    system.modified_datetime,
                                    system.created_datetime,
                                )
                            )

                            if (system.active == 0) {
                                inactive.push({
                                    system_type: system.system_type,
                                    system_recordid: system.system_recordid,
                                })
                                
                            }

                        })

                        this.setState({ columns: columns })
                        this.setState({ inactive: inactive })
                    }
                }
            })
        }
    }

    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'SYSTEMS',
            control_code: 'SYSTEM_TYPE'
        }

        PostData('Migrations/dropdowns/ajax/ajaxGetDropdownItems.php', data).then(
            response => {
                if(response.result){
                    this.setState({dropdown: response.dropdowns})
                }

            }
        )
    }

    handleEditOpen = () =>{
        
        this.props.setFormData({systems: this.state.columns}, 'EditSystem')
    }

    handleToggle = () => {
        this.setState({ more: !this.state.more })
    }

    handleClick = type => {
        this.setState({ active: type})
    }

    getSystemGroupings = () =>{
        let groupings = []
        if (this.state.columns) {
            this.state.dropdown.forEach(item => {
                this.state.columns.forEach(system =>{
                    if(system.system_type == item.item_code && item.sub_item_1 == this.state.active ){
                        groupings.push({system: system, type: item.item_name})
                    }
                })
            })
        }

        return groupings.map(item => this.viewSystemGroupings(item), this)
    }

    viewSystemGroupings = system => {
        
        const { classes } = this.props
        
            return (
                <TableRow onClick={() =>this.handleOpen(system.system.system_recordid,system.system.system_type)}>
                    <TableCell className={classes.highlight} >{system.type}</TableCell>
                    <TableCell>{system.system.system_name || ''}</TableCell>
                    <TableCell>{system.system.created_datetime}</TableCell>
                    <TableCell>{system.system.modified_datetime}</TableCell>
                    
                </TableRow>
            )
        
    }

    viewSystemTypes = type => {
        const { classes } = this.props
        if (this.state.active == type) {
            return (
                <ListItem
                    className={classes.selected}
                    onClick={() => this.handleClick(type)}
                    
                >
                    {type}
                </ListItem>
            )
        } else {
            return (
                <ListItem
                    className={classes.lines}
                    onClick={() => this.handleClick(type)}
                >
                    {type}
                </ListItem>
            )
        }
    }

    //gets products list
    getSystemTypes = () => {
        let types = []
        if(this.state.dropdown.length > 0){
            this.state.dropdown.forEach(item =>{
                if(item.control_code =='SYSTEM_TYPE')
                    if (!types.includes(item.sub_item_1)) {
                        types.push(item.sub_item_1)
                    }
            })
        }
        return types.map(type => this.viewSystemTypes(type), this)
    }

    handleOpen = (recordid) => {
        

        if (recordid == undefined) {
            this.props.setFormData(
                { inactive: this.state.inactive, systemData: '' },
                'System'
            )
        } else {
            const data = {
                action: 'getSystemInfo',
                system_recordid: recordid,
            }
            

            PostData('CRM/ajax/ajaxCRM_SystemInfo.php', data).then(response => {
                
                this.props.setFormData(
                    { inactive: false, systemData: response.UtilitySystemsInfo },
                    'System'
                )
            })
        }
    }

    getSystemList = system => {
        const { classes } = this.props
        if (system.active == 1) {
            return (
                <React.Fragment>
                    <Grid item xs={3}>
                        <Button
                            className={classes.lowercase}
                            onClick={() =>
                                this.handleOpen(
                                    system.system_recordid,
                                    system.system_type
                                )
                            }
                        >
                            <ListItem>
                                <ListItemText
                                    primary={system.system_name}
                                    secondary={
                                        <div>
                                            <div>{system.system_type}</div>
                                            {/*<div><i>{system.notes}</i></div>*/}
                                        </div>
                                    }
                                />
                            </ListItem>
                        </Button>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    

    render() {
        const { classes } = this.props

        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Card>
                            <div className={classes.secondary}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            color="inherit"
                                            className={classes.textField}
                                        >
                                            Systems
                                        </Typography>
                                    </Grid>
                                    {/*<Grid item className={classes.addGrid}>
                                        <Tooltip title={'Add System'}>
                                            <IconButton
                                                onClick={() =>
                                                    this.handleOpen()
                                                }
                                                className={classes.add}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    color="white"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                            </Grid>*/}
                                </Grid>
                            </div>
                            <Grid container spacing={1}>
                                <Grid item className={classes.addGrid}>
                                    <Tooltip title={'Bulk Edit'}>
                                        <Button
                                            onClick={this.handleEditOpen}
                                            className={classes.add}
                                            size='small'
                                            >
                                            Bulk Edit
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                size="1x"
                                                color="black"
                                                style={{
                                                    marginLeft: 5,
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                </Grid>                 
                            </Grid>

                            <Divider variant="middle" />

                            <List className={classes.root}>
                                <Grid container spacing={0}>
                                    <Grid item xs={3}
                                        style={{ maxHeight: 300, overflow: 'auto' }}>
                                        <List disablePadding={true} className={classes.list}>{this.getSystemTypes()}</List>
                                    </Grid>
                                    <Grid item xs={9}>
                                        <Table className={classes.table} size="small" >
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Type</TableCell>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>Created</TableCell>
                                                    <TableCell>Modified</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {this.getSystemGroupings()}
                                            </TableBody> 
                                        </Table>
                                    </Grid>
                                </Grid>
                            </List>
                        </Card>
                    </Grid>
                </Grid>
                <div>
                    <Dialog
                        //fullScreen
                        fullWidth
                        maxWidth="lg"
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                    >
                        {/* <AppBar >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    onClick={this.handleClose}
                                    aria-label="Close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <div className={classes.flex} />
                                <Button
                                    color="inherit"
                                    onClick={this.handleSave}
                                >
                                    save
                                </Button>
                            </Toolbar>
                        </AppBar> */}

                        <SystemsForm SystemData={this.state.columns} />
                    </Dialog>
                </div>
            </React.Fragment>
        )
    }
}

/*class CustomTableCells extends React.Component {
    render() {
        const n = this.props.n

        return (
            <React.Fragment>
                <TableCell padding="checkbox" />
                <TableCell component="th" scope="row" padding="none">
                    {n.system_type}
                </TableCell>
                <TableCell align="right">{n.system_name}</TableCell>
            </React.Fragment>
        )
    }
}

class CustomDialog extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            contact_date: '2019-08-15',
            contact_time: '',
            contact_type: '',
            contact_by: '',
            contact_name: '',
            lead_source: '',
            source_id: '',
            campaign_id: '',
            privacy_status: '',
            subject: '',
            notes: '',
            utility_contact_name: '',
        }
    }

    handleSave = () => {
        let data = {
            action: 'insert_contact_notes_table',
            crm_id: 32929,
            strategic_business_unit: 'Horizons',
            user_id: 'rh.horizons',
        }

        const combinedData = { ...data, ...this.state }

        PostData('CRM/ajax/ajaxContact_Notes.php', combinedData).then(
            response => {
                this.props.getColumns()
            }
        )
    }

    render() {
        return (
            <FullScreenDialog handleSave={this.handleSave}>
                <List>

                </List>
            </FullScreenDialog>
        )
    }
}*/

Systems.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Systems)
