import React from 'react'
import { SortableContainer } from 'react-sortable-hoc'
import SortableListContainer from './SortableListContainer'

const ListContainer = SortableContainer(props => {
    return (
        <div>
            {props.listItems.map((listItem, id, name) => (
                <SortableListContainer
                    key={name}
                    index={id}
                    listItem={listItem}
                    handleTextEdit={props.handleTextEdit}
                    handleEditToggle={props.handleEditToggle}
                    handleEditSave={props.handleEditSave}
                    toggleDialog={props.toggleDialog}
                    listItems={props.listItems}
                />
            ))}
        </div>
    )
})

export default ListContainer
