import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTimes} from '@fortawesome/pro-light-svg-icons'
import {faEdit} from '@fortawesome/pro-light-svg-icons'
import { PostData } from '../../../services/PostData'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' +  theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        background: 'rgb(248,248,248)',
        width: '97%'
    },
})

class SupportDatesForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            supportData: {
            },
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange = event => {
        
        let original = this.state.supportData
        if (event.target.name == 'OMS'){
            let newSupportData = this.state.supportData.OMS
            newSupportData[event.target.id] = event.target.value
            original.OMS = newSupportData
        }
        else if (event.target.name == 'IVR'){
            let newSupportData = this.state.supportData.IVR
            newSupportData[event.target.id] = event.target.value
            original.IVR = newSupportData
        }
        
        this.setState({supportData: original})
    }

    handleSave = () => {
        this.handleClose()

        const data = {
            action: 'Update_Record',
            //hug_schema: 'hug_horizons',
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            sbu_client_id: 'ZULU',
            recordid_oms: this.state.supportData.OMS.recordid,
            recordid_ivr: this.state.supportData.IVR.recordid,
            product_oms: this.state.supportData.OMS.product,
            product_ivr: this.state.supportData.IVR.product,
            system_type_oms: this.state.supportData.OMS.system_type,
            system_type_ivr: this.state.supportData.IVR.system_type,
            support_level_oms: this.state.supportData.OMS.support_level,
            support_level_ivr: this.state.supportData.IVR.support_level,
            maintenance_type_oms: this.state.supportData.OMS.maintenance_type,
            maintenance_type_ivr: this.state.supportData.IVR.maintenance_type,
            installed_date_oms: this.state.supportData.OMS.installed_date,
            installed_date_ivr: this.state.supportData.IVR.installed_date,
            billing_month_oms: this.state.supportData.OMS.billing_month,
            billing_month_ivr: this.state.supportData.IVR.billing_month,
            last_invoice_date_oms: this.state.supportData.OMS.last_invoice_date,
            last_invoice_date_ivr: this.state.supportData.IVR.last_invoice_date,
            last_invoiced_amount_oms: this.state.supportData.OMS.last_invoice_amount,
            last_invoiced_amount_ivr: this.state.supportData.IVR.last_invoice_amount,
            contract_start_date_oms: this.state.supportData.OMS.contract_start_date,
            contract_end_date_oms: this.state.supportData.OMS.contract_end_date,
            contract_start_date_ivr: this.state.supportData.IVR.contract_start_date,
            contract_end_date_ivr: this.state.supportData.IVR.contract_start_date,
            active_flag_oms: this.state.supportData.OMS.active_flag,
            active_flag_ivr: this.state.supportData.IVR.active_flag,
            comments_oms: this.state.supportData.OMS.comments,
            comments_ivr: this.state.supportData.IVR.comments,

        }

        if (data.last_invoiced_amount_oms == null) {
            data.last_invoiced_amount_oms = 0
        }

        PostData('Support_Info/ajax/ajaxCustomerInfo.php', data).then(
            response => {
                this.props.updateComponent('SupportDates')
            }
        )
    }

    handleClose = () => {
        this.props.handleFormClose()
    }

    componentWillMount() {
        this.setState({ supportData: this.props.formData })
    }

    render() {
        const { classes } = this.props
        if (this.state.supportData != undefined){
            return (
                <Grid container className={classes.outside}>
                    <Grid item sm={12}>
                        <Card>
                            <div className={classes.secondary}>
                                <Grid container spacing={1} alignItems="center">
                                <Typography
                                        component="h1"
                                        variant="h6"
                                        color="inherit"
                                        className={classes.textField}
                                    >
                                        
                                        Edit Support Dates
                                        
                                    </Typography>
                                    
                                    <Grid item className={classes.buttonGrid}>
                                        <Button
                                            color="primary"
                                            className={classes.save}
                                            onClick={this.handleSave}
                                        >
                                            Save
                                        </Button>
                                        <Button onClick={this.handleClose}>
                                            <FontAwesomeIcon
                                                icon={faTimes}
                                                size="2x"
                                                color="white"
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>

                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}> 
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        color="inherit"
                                        
                                    >
                                        General
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Table className={classes.table} size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell></TableCell>
                                                <TableCell>OMS</TableCell>
                                                <TableCell>IVR</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            
                                            <TableRow>
                                            <TableCell >Support Level</TableCell>
                                                <TableCell><TextField
                                                    required
                                                    id="support_level"
                                                    name='OMS'
                                                    
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.OMS.support_level}
                                                    onChange={this.handleChange}
                                                /></TableCell>
                                                <TableCell><TextField
                                                    required
                                                    id="support_level"
                                                    name='IVR'
                                                    
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.IVR.support_level}
                                                    onChange={this.handleChange}
                                                /></TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Maintenance Type</TableCell>
                                                <TableCell><TextField
                                                    required
                                                    id="maintenance_type"
                                                    name='OMS'
                                                    
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.OMS.maintenance_type}
                                                    onChange={this.handleChange}
                                                /></TableCell>
                                                <TableCell><TextField
                                                    required
                                                    id="maintenance_type"
                                                    name='IVR'
                                                    
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.IVR.maintenance_type}
                                                    onChange={this.handleChange}
                                                /></TableCell>
                                            </TableRow>
                                            
                                            <TableRow>
                                                <TableCell>
                                                    Maintenance Agreement Start Date
                                                </TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="contract_start_date"
                                                    name='OMS'
                                                    
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.OMS.contract_start_date}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="contract_start_date"
                                                    name='IVR'
                                                    
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.IVR.contract_start_date}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Maintenance Agreement Expiration
                                                    Date
                                                </TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="contract_end_date"
                                                    name='OMS'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.OMS.contract_end_date}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="contract_end_date"
                                                    name='IVR'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.IVR.contract_end_date}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                            </TableRow> 
                                            <TableRow>
                                                <TableCell>System Type</TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="system_type"
                                                    name='OMS'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.OMS.system_type}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="system_type"
                                                    name='IVR'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.IVR.system_type}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Install Date</TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="installed_date"
                                                    name='OMS'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.OMS.installed_date}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="installed_date"
                                                    name='IVR'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.IVR.installed_date}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Last Invoice Date</TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="last_invoice_date"
                                                    name='OMS'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.OMS.last_invoice_date}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="last_invoice_date"
                                                    name='IVR'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.IVR.last_invoice_date}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Last Invoice Amount
                                                </TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="last_invoice_amount"
                                                    name='OMS'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.OMS.last_invoiced_amount}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                                <TableCell>
                                                <TextField
                                                    required
                                                    id="last_invoice_amount"
                                                    name='IVR'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.IVR.last_invoiced_amount}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Next Invoice Month
                                                </TableCell>
                                                <TableCell ><TextField
                                                    required
                                                    id="billing_month"
                                                    name='OMS'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.OMS.billing_month}
                                                    onChange={this.handleChange}
                                                />
                                                </TableCell>
                                                <TableCell ><TextField
                                                    required
                                                    id="billing_month"
                                                    name='IVR'
                                                    fullWidth
                                                    className={classes.textField}
                                                    defaultValue={this.state.supportData.IVR.billing_month}
                                                    onChange={this.handleChange}
                                                /></TableCell>
                                            </TableRow>
                                            
                                        </TableBody>
                                    </Table>
                                </Grid>
                                
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>








                
            )
            }
    }
}

SupportDatesForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SupportDatesForm)
