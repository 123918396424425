import React from 'react'
import PropTypes from 'prop-types'

import { PostData } from '../../../services/PostData'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import NumberFormat from 'react-number-format';

import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from '@material-ui/core'
import FormWrapper from '../../Reusable/FormWrapper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    number: {
        fontSize: 16,
        border: '1px solid',
    },

    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        //marginLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '98%',
        background: 'rgb(248,248,248)',
    },
    row: {
        background: 'white',
    },
    rowHeader: {
        background: theme.palette.secondary.light,
        color:'white',
    },
    rowHeaderTotal: {
        background: 'rgb(235,235,235)',
        fontWeight: 500,
    },
    rowGrandTotal: {
        background: 'rgb(230,230,230)',
        fontWeight: 500,
    },
    cellHeaderTotal: {
        fontWeight: 500,
    },
    cellGrandTotal: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(15),
    },
    cellHeader: {
        fontWeight: 500,
        color: 'white',
    },
})


//This class is used in the quote form and work orders form
class ProductRecap extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            recap: {},
            product_details: [],
        }

    }

    //Converts strings to Title Case
    toTitleCase = str => {
        if (str !== null) {
            str = str.replace(/_/g, ' ')
            return str.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
        }
    }

    //Gets grand totals for each column
    getRecapTotals = field => {
        let total = 0
        for (let key in this.props.recap) {
            total = parseFloat(total) + parseFloat(this.props.recap[key][field])
        }
        return total
    }

    //Makes the rows and puts them in order
    showProductSummary = () => {
        if(this.props.product_details){
            const { classes } = this.props

            let recap = []
            for (let key in this.props.recap) {
                recap.push(this.props.recap[key])
            }

            let activeModules = []
            this.props.product_details.forEach(product => {
                if (product.qty > 0 && ((!this.props.optional && product.addon_option === 'false') || (this.props.optional && product.addon_option === 'true'))) {
                    activeModules.push(product)
                }
            })

            if (this.props.optional && this.props.optionalLines) {
                recap = []
                Object.keys(this.props.optionalLines).forEach(line => {
                    recap.push(this.props.optionalLines[line])
                });
            }

            let item = []
            recap.forEach(line => {
                item.push(
                    <TableRow className={classes.rowHeader}>
                        <TableCell className={classes.cellHeader}>
                            {line.product_line}
                        </TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell />
                    </TableRow>
                )
                
                activeModules.forEach(module => {
                    // console.log(module)

                    if (line.product_line == module.product_line) {
                        // console.log(module)

                        let annualCost = 0;

                        if (module.user_fee_amount > 0) {
                            if (module.user_fee_period === 'MONTHLY') {
                                annualCost = module.user_fee_amount * 12
                            } else {
                                annualCost = module.user_fee_amount
                            }

                            annualCost = parseFloat(annualCost) * module.qty

                        } else {
                            if (module.lic_fee_period === 'MONTHLY') {
                                if (module.maint_cost > 0) {
                                    annualCost = module.maint_cost * 12
                                } else {
                                    annualCost = module.lic_fee_amount * 12
                                }
                            } else {
                                if (module.maint_cost > 0) {
                                    annualCost = module.maint_cost
                                } else {
                                    annualCost = module.lic_fee_amount
                                }
                            }

                            annualCost = parseFloat(annualCost)
                        }

                        item.push(
                            <TableRow className={classes.row}>
                                {/* <TableCell>
                                    {line.product_line}
                                </TableCell> */}
                                <TableCell
                                    onClick={() =>
                                        this.setState({
                                            selectedProductModule:
                                                module.product_module,
                                            selectedProductLine:
                                                module.product_line,
                                        })
                                    }
                                    className={classes.highlight}
                                >
                                    {module.product_desc}
                                </TableCell>
                                <TableCell align="right">
                                    <NumberFormat
                                        value={module.qty}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberFormat
                                        value={module.price_each}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberFormat
                                        value={module.price_each * module.qty}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberFormat
                                        value={module.discount}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberFormat
                                        value={module.software_fees}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </TableCell>
                                {/* <TableCell align="right" className={classes.cellGrandTotal}>
                                    <Typography>{module.lic_fee_period}</Typography>
                                </TableCell> */}
                                <TableCell align="right">
                                    <NumberFormat
                                        value={module.professional_services}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberFormat
                                        value={module.labor_hours * module.qty}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberFormat
                                        value={module.net_billable_amount}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    {module.maint_cost > 0 ? 'ANNUAL' : (module.lic_fee_amount > 0 ? module.lic_fee_period : module.user_fee_period)}
                                </TableCell>
                                <TableCell align="right">
                                    <NumberFormat
                                        value={module.maint_cost}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberFormat
                                        value={module.lic_fee_amount > 0 ? module.lic_fee_amount : module.user_fee_amount}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberFormat
                                        value={module.transactional_price != '0.00' ? module.transactional_price : ''}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </TableCell>
                                <TableCell align="right">
                                    <NumberFormat
                                        value={annualCost}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    }                
                })

                if (!this.props.optional) {
                    item.push(
                        <TableRow className={classes.rowHeaderTotal}>
                            <TableCell className={classes.cellHeaderTotal}>
                                {line.product_line + ' Total'}
                            </TableCell>
                            {/* <TableCell /> */}
                            <TableCell align="right" className={classes.cellHeaderTotal}>
                                <NumberFormat
                                    value={line.quantity}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </TableCell>
                            <TableCell align="right" className={classes.cellHeaderTotal}>
                                <NumberFormat
                                    value={line.price_each}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                />
                            </TableCell>
                            <TableCell align="right" className={classes.cellHeaderTotal}>
                                <NumberFormat
                                    value={line.total_price_each}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                />
                            </TableCell>
                            <TableCell align="right" className={classes.cellHeaderTotal}>
                                <NumberFormat
                                    value={line.discount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                />
                            </TableCell>
                            <TableCell align="right" className={classes.cellHeaderTotal}>
                                <NumberFormat
                                    value={line.net_software_price}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                />
                            </TableCell>
                            {/* <TableCell align="right" className={classes.cellGrandTotal}>
                                <Typography></Typography>
                            </TableCell> */}
                            <TableCell align="right" className={classes.cellHeaderTotal}>
                                <NumberFormat
                                    value={line.professional_fees}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                />
                            </TableCell>
                            <TableCell align="right" className={classes.cellHeaderTotal}>
                                <NumberFormat
                                    value={line.total_mhrs}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </TableCell>
                            <TableCell align="right" className={classes.cellHeaderTotal}>
                                <NumberFormat
                                    value={line.net_invoice_amount}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                />
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell align="right" className={classes.cellHeaderTotal}>
                                <NumberFormat
                                    value={line.maint_cost}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                />
                            </TableCell>
                            <TableCell align="right" className={classes.cellHeaderTotal}>
                                <NumberFormat
                                    value={line.annual_cost}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                />
                            </TableCell>
                            <TableCell align="right" className={classes.cellHeaderTotal}>
                                <Typography component="h6" variant="body2" color="inherit">TBD</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <NumberFormat
                                    value={line.annual_cost}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                />
                            </TableCell>
                        </TableRow>
                    )
                }
            })

            return item
        }
    }

    //Makes the table and totals
    render() {
        const { classes } = this.props
            return (
                <div>
                    {this.props.print && (
                        <Typography variant="h5" gutterBottom style={{paddingTop: 16, paddingLeft: 16}}>
                            {'Quote ' + this.props.quoteNumber + ', ' + this.props.customerName} 
                        </Typography>
                    )}

                    <Grid container spacing={1} style={this.props.print ? {paddingTop: 16, paddingLeft: 16, paddingRight: 16} : {}}>
                        <Grid item xs={12}>
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        {/* <TableCell>Product Line</TableCell> */}
                                        <TableCell>Module</TableCell>
                                        <TableCell align="right">Qty.</TableCell>
                                        <TableCell align="right">List Price</TableCell>
                                        <TableCell align="right">Total List Price</TableCell>
                                        {/* <TableCell align="right">License Period</TableCell> */}
                                        <TableCell align="right">Discount</TableCell>
                                        <TableCell align="right">License Fee</TableCell>
                                        <TableCell align="right">PS Fees</TableCell>
                                        <TableCell align="right">PS Hours</TableCell>
                                        <TableCell align="right">Net Invoice</TableCell>
                                        <TableCell align="right">Maint. Period</TableCell>
                                        <TableCell align="right">Maint.</TableCell>
                                        <TableCell align="right">Fee</TableCell>
                                        <TableCell align="right">Trans.</TableCell>
                                        <TableCell align="right">Annual Cost</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>{this.showProductSummary()}
                                
                                    {!this.props.optional && (
                                        <TableRow >
                                            <TableCell className={classes.cellGrandTotal}>Total</TableCell>

                                            {/* <TableCell /> */}
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.getRecapTotals('quantity')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}

                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.getRecapTotals('price_each')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.getRecapTotals('total_price_each')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.getRecapTotals('discount')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.getRecapTotals('net_software_price')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            {/* <TableCell align="right" className={classes.cellGrandTotal}>
                                                <Typography></Typography>
                                            </TableCell> */}
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.getRecapTotals('professional_fees')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.getRecapTotals('total_mhrs')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.getRecapTotals('net_invoice_amount')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.getRecapTotals('maint_cost')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.getRecapTotals('annual_cost')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <Typography component="h6" variant="body2" color="inherit">TBD</Typography>
                                            </TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.getRecapTotals('annual_cost')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>

                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </Grid>

                        {!this.props.print && !this.props.optional && (
                            <Grid item xs={10} sm={10}>
                                <Typography component="h6" variant="body2" color="inherit">
                                    *Totals Update On Save
                                </Typography>
                                <br></br>
                            </Grid>
                        )}
                    </Grid>
                </div>
            )
    }
}

ProductRecap.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ProductRecap)