import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../Layout/styles'

import Menu from '@material-ui/core/Menu'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'

import EnhancedTable from '../Reusable/EnhancedTable'
import { PostData } from '../../services/PostData'

function createColumn(
    id,
    step_number,
    type,
    subject,
    template_name,
    template_link,
    date,
    assigned_name,
    contact_attempted,
    status
) {
    return {
        id,
        step_number,
        type,
        subject,
        template_name,
        template_link,
        date,
        assigned_name,
        contact_attempted,
        status,
    }
}

class Steps extends Component {
    state = {
        // Get the campaign id passed in by the parent campaign inquiry component
        id: this.props.id,
        columns: [],
    }

    componentWillMount() {
        const data = {
            action: 'getSteps',
            id: this.state.id,
        }

        PostData('master_campaign/ajax/ajaxMasterCampaign.php', data).then(
            response => {
                if (response.result) {
                    let columns = []

                    response.steps.forEach(step => {
                        columns.push(
                            createColumn(
                                step.id,
                                step.step_number,
                                step.type,
                                step.subject,
                                step.template_name,
                                step.template_link,
                                step.date,
                                step.assigned_name,
                                step.contact_attempted,
                                step.status
                            )
                        )
                    })

                    this.setState({ columns: columns })
                }
            }
        )
    }

    render() {
        const { classes } = this.props

        const rows = [
            {
                id: 'step_number',
                numeric: false,
                disablePadding: true,
                label: 'Step Number',
            },
            {
                id: 'type',
                numeric: true,
                disablePadding: false,
                label: 'Type',
            },
            {
                id: 'subject',
                numeric: true,
                disablePadding: false,
                label: 'Subject',
            },
            {
                id: 'template',
                numeric: true,
                disablePadding: false,
                label: 'Template',
            },
            {
                id: 'date',
                numeric: true,
                disablePadding: false,
                label: 'Date',
            },
            {
                id: 'assigned_to',
                numeric: true,
                disablePadding: false,
                label: 'Assigned To',
            },
            // {
            //     id: 'contact_attempted',
            //     numeric: true,
            //     disablePadding: false,
            //     label: 'Contact Attempted',
            // },
            {
                id: 'status',
                numeric: true,
                disablePadding: false,
                label: 'Status',
            },
            {
                id: 'actions',
                numeric: true,
                disablePadding: false,
                label: 'Actions',
            },
        ]

        return (
            <EnhancedTable
                rows={rows}
                columns={this.state.columns}
                additionalData={this.state.steps}
                tableRow={<CustomTableCells campaignId={this.state.id} />}
            />
        )
    }
}

class CustomTableCells extends React.Component {
    render() {
        const n = this.props.n

        return (
            <React.Fragment>
                <TableCell padding="checkbox" />
                <TableCell component="th" scope="row" padding="none">
                    {n.step_number}
                </TableCell>
                <TableCell align="right">{n.type}</TableCell>
                <TableCell align="right">{n.subject}</TableCell>
                <TableCell align="right">
                    {n.template_link !== null && (
                        <Link to={n.template_link} target="_blank">
                            {n.template_name}
                        </Link>
                    )}
                </TableCell>
                <TableCell align="right">{n.date}</TableCell>
                <TableCell align="right">{n.assigned_name}</TableCell>
                {/* <TableCell align="right">{n.contact_attempted}</TableCell> */}
                <TableCell align="right">
                    <StatusMenu
                        n={n}
                        handleMenuClick={this.handleMenuItemClick}
                    />
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        component={Link}
                        to={
                            '/campaign-call-list/' +
                            this.props.campaignId +
                            '/' +
                            n.id
                        }
                    >
                        <Icon>list</Icon>
                    </IconButton>
                </TableCell>
            </React.Fragment>
        )
    }
}

class StatusMenu extends Component {
    state = {
        anchorEl: null,
        selectedIndex: this.props.n.status,
    }

    handleClickListItem = event => {
        this.setState({ anchorEl: event.currentTarget })
    }

    handleMenuItemClick = (event, index) => {
        this.setState({ selectedIndex: index, anchorEl: null })

        const data = {
            action: 'setStepStatus',
            id: this.props.n.id,
            status: index,
        }

        PostData('master_campaign/ajax/ajaxMasterCampaign.php', data)
    }

    handleClose = () => {
        this.setState({ anchorEl: null })
    }

    render() {
        const { classes } = this.props
        const { anchorEl } = this.state
        const n = this.props.n

        return (
            <React.Fragment>
                <List>
                    <ListItem
                        button
                        aria-haspopup="true"
                        aria-controls="lock-menu"
                        aria-label={n.status}
                        onClick={this.handleClickListItem}
                    >
                        <ListItemText
                            primary={this.state.selectedIndex}
                            style={{ textAlign: 'right' }}
                        />
                    </ListItem>
                </List>
                <Menu
                    id="lock-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                    <MenuItem
                        key="Started"
                        onClick={event =>
                            this.handleMenuItemClick(event, 'Started')
                        }
                    >
                        Started
                    </MenuItem>
                    <MenuItem
                        key={2}
                        onClick={event =>
                            this.handleMenuItemClick(event, 'In Progress')
                        }
                    >
                        In Progress
                    </MenuItem>
                    <MenuItem
                        key={2}
                        onClick={event =>
                            this.handleMenuItemClick(event, 'Completed')
                        }
                    >
                        Completed
                    </MenuItem>
                </Menu>
            </React.Fragment>
        )
    }
}

Steps.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Steps)
