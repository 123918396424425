import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import Divider from '@material-ui/core/Divider'
import Tooltip from '@material-ui/core/Tooltip'

import { PostData } from '../../../services/PostData'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { faGripHorizontal } from '@fortawesome/pro-light-svg-icons'
import { faGripLines } from '@fortawesome/pro-light-svg-icons'
import { faTint } from '@fortawesome/pro-regular-svg-icons'
import { faBolt } from '@fortawesome/pro-regular-svg-icons'
import { faGasPump } from '@fortawesome/pro-regular-svg-icons'
import { faTintSlash } from '@fortawesome/pro-regular-svg-icons'
import { faLightbulbExclamation } from '@fortawesome/pro-regular-svg-icons'
import { faWifi } from '@fortawesome/pro-regular-svg-icons'
import { faNetworkWired } from '@fortawesome/pro-regular-svg-icons'
import { faTvRetro } from '@fortawesome/pro-regular-svg-icons'
import { faSatelliteDish } from '@fortawesome/pro-regular-svg-icons'
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons'
import { faPhoneVolume } from '@fortawesome/pro-regular-svg-icons'
import { faWaterLower } from '@fortawesome/pro-regular-svg-icons'
import { faThunderstorm } from '@fortawesome/pro-regular-svg-icons'
import { faSun } from '@fortawesome/pro-regular-svg-icons'
import { faTrafficLight } from '@fortawesome/pro-regular-svg-icons'
import { faLightbulbOn } from '@fortawesome/pro-regular-svg-icons'
import { faLightbulbSlash } from '@fortawesome/pro-regular-svg-icons'
import { faLightbulb } from '@fortawesome/pro-regular-svg-icons'
import StreetLight from '../../../images/icons/street-light.ico'
import { faBurn } from '@fortawesome/pro-regular-svg-icons'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },
    lowercase: {
        textTransform: 'none',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    showMore: {
        width: '100%',
    },
    add: {
        padding: 7,
        marginRight: 5,
        marginBottom: 1,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        width: '98%',
        //background: 'rgb(248,248,248)',
    },
    highlight: {
        cursor: 'pointer',
    },
})

function createColumn(
    active,
    at_risk_comments,
    at_risk_status,
    client_info_note,
    date_lost,
    date_won,
    purchase_order_required,
    team_support_id,
    strategic_business_unit,
    sbu_client_id,
    harris_client_id,
    crm_id,
    recordid,
    services,
    products
) {
    return {
        active,
        at_risk_comments,
        at_risk_status,
        client_info_note,
        date_lost,
        date_won,
        purchase_order_required,
        team_support_id,
        strategic_business_unit,
        sbu_client_id,
        harris_client_id,
        crm_id,
        recordid,
        services,
        products,
    }
}

class ClientInfo extends Component {
    constructor(props) {
        super(props)

        this.state = {
            columns: [],
            crmData: this.props.crmData,
            bubbleView: false,
            add: true,
        }

        //this.handleSave = this.handleSave.bind(this)
    }

    componentWillMount() {
        
        const data = {
            action: 'getClientInfo',
            crm_id: this.state.crmData.crm_id,
            strategic_business_unit: JSON.parse(
                sessionStorage.getItem('userData')
            ).strategic_business_unit,
        }
        const userData = JSON.parse(
            sessionStorage.getItem('userData')
        )

        PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data).then(response => {
            
            if (response.result) {
                
                let columns = []
                


                response.AllClientInfo &&  response.AllClientInfo.forEach(client => {
                    if(userData.strategic_business_unit.toLowerCase() === client.strategic_business_unit.toLowerCase()){
                        this.setState({add: false})
                    }

                    const data2 = {
                        sbu: client.strategic_business_unit,
                        action: 'getActiveServiceBySBU',
                        crm_id: this.state.crmData.crm_id,
                    }
                    PostData(
                        'CRM/ajax/ajaxCRM_UtilityServices.php',
                        data2
                    ).then(response2 => {
                        if (response2.result) {
                            const data3 = {
                                hug_schema:
                                    'hug_' + (client.strategic_business_unit.toLowerCase()),
                                client_id: client.sbu_client_id,
                                action: 'GetClientsWithModules3',
                            }
                            PostData(
                                'CustomerModuleSearch/ajax/ajaxCustomerModulesSearch.php',
                                data3
                            ).then(response3 => {
                                let services = []
                                let productLines = []
                                
                                for (let i = 0; i < response2.utility_services_states.length; i++) {
                                    let icon = this.getServiceIcon(response2.utility_services_states[i].service_type)
                                    if (!services.includes(icon)) {
                                        services.push(icon)
                                    }
                                }

                                if (response3.ClientsWithModules) {
                                    for (let x = 0; x < response3.ClientsWithModules.length; x++) {
                                        productLines.push(this.getProductItem(response3.ClientsWithModules[x].product_line, x + 1 === response3.ClientsWithModules.length))
                                    }
                                }
                                
                                columns.push(
                                    createColumn(
                                        client.active,
                                        client.at_risk_comments,
                                        client.at_risk_status,
                                        client.client_info_note,
                                        client.date_lost,
                                        client.date_won,
                                        client.purchase_order_required,
                                        client.team_support_id,
                                        client.strategic_business_unit,
                                        client.sbu_client_id,
                                        client.harris_client_id,
                                        client.crm_id,
                                        client.recordid,
                                        services,
                                        productLines
                                    )
                                )

                                this.setState({ columns: columns })
                            })
                        }
                    })
                })
            }
        })
    }

    getServiceIcon = type => {
        switch (type) {
            case 'WATER':
                return <FontAwesomeIcon icon={faTint} size="1x" />
                break
            case 'ELECTRIC':
                return <FontAwesomeIcon icon={faLightbulb} size="1x" />
                break
            case 'GAS':
                return <FontAwesomeIcon icon={faBurn} size="1x" />
                break
            case 'WASTE_WATER':
                return <FontAwesomeIcon icon={faTintSlash} size="1x" />
                break
            case 'SECURITY_LIGHTS':
                return (
                    <FontAwesomeIcon icon={faLightbulbExclamation} size="1x" />
                )
                break
            case 'FIBER':
                return <FontAwesomeIcon icon={faNetworkWired} size="1x" />
                break
            case 'INTERNET':
                return <FontAwesomeIcon icon={faWifi} size="1x" />
                break
            case 'CABLE':
                return <FontAwesomeIcon icon={faTvRetro} size="1x" />
                break
            case 'SATELLITE':
                return <FontAwesomeIcon icon={faSatelliteDish} size="1x" />
                break
            case 'SANITATION':
                return <FontAwesomeIcon icon={faTrashAlt} size="1x" />
                break
            case 'TELEPHONE':
                return <FontAwesomeIcon icon={faPhoneVolume} size="1x" />
                break
            case 'WATER_IRRIGATION':
                return <FontAwesomeIcon icon={faWaterLower} size="1x" />
                break
            case 'STORM_DRAINAGE':
                return <FontAwesomeIcon icon={faThunderstorm} size="1x" />
                break
            case 'SOLAR':
                return <FontAwesomeIcon icon={faSun} size="1x" />
                break
            case 'STREET_LIGHTS':
                return (
                    <img
                        src={StreetLight}
                        style={{
                            height: '15px',
                            //width: '15%',
                            padding: 0,
                            margin: 0
                        }}
                    />
                )
                break
            case 'ELECTRIC_NONOUTAGE':
                return <FontAwesomeIcon icon={faLightbulbSlash} size="1x" />
                break
        }
    }
    getProductItem = (item, isLast) => {
        if (isLast) {
            return item.replace(/_/g,' ')
        } else {
            return item.replace(/_/g, ' ') + ', '
        }

        //return(<Typography>{item}{!isLast && ','}</Typography>)
        //return item + !isLast && ', '
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.crmData.crm_id !== nextProps.crmData.crm_id )  {
            const userData = JSON.parse(
                sessionStorage.getItem('userData')
            )
            this.setState({crmData: nextProps.crmData})
            
            const data = {
                action: 'getClientInfo',
                crm_id: nextProps.crmData.crm_id,
                strategic_business_unit: JSON.parse(
                    sessionStorage.getItem('userData')
                ).strategic_business_unit,
            }
            let flag = true
            PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data).then(response => {
                if (response.result) {
                    let columns = []

                    response.AllClientInfo && response.AllClientInfo.forEach((client, idx) => {
                        if(userData.strategic_business_unit && client.strategic_business_unit && userData.strategic_business_unit.toLowerCase() === client.strategic_business_unit.toLowerCase()){
                            flag = false
                        } 
                        if(idx + 1 === response.AllClientInfo.length){
                            this.setState({add: flag})
                        }
                        const data2 = {
                            sbu: client.strategic_business_unit,
                            action: 'getActiveServiceBySBU',
                            crm_id: nextProps.crmData.crm_id,
                        }
                        PostData(
                            'CRM/ajax/ajaxCRM_UtilityServices.php',
                            data2
                        ).then(response2 => {
                            if (response2.result) {
                                const data3 = {
                                    hug_schema:
                                    'hug_' + (client.strategic_business_unit),
                                    client_id: client.sbu_client_id,
                                    action: 'GetClientsWithModules3',
                                }
                                PostData(
                                    'CustomerModuleSearch/ajax/ajaxCustomerModulesSearch.php',
                                    data3
                                ).then(response3 => {
                                    let services = []
                                    let foundServices = []
                                    let productLines = []

                                    for (let i = 0; i < response2.utility_services_states.length; i++) {
                                        if (!foundServices.includes(response2.utility_services_states[i].service_type)) {
                                            services.push(this.getServiceIcon(response2.utility_services_states[i].service_type))
                                            foundServices.push(response2.utility_services_states[i].service_type)
                                        }
                                    }
    
                                    if (response3.ClientsWithModules) {
                                        for (let x = 0; x < response3.ClientsWithModules.length; x++) {
                                            productLines.push(this.getProductItem(response3.ClientsWithModules[x].product_line, x + 1 === response3.ClientsWithModules.length))
                                        }
                                    }
                                    
                                    columns.push(
                                        createColumn(
                                            client.active,
                                            client.at_risk_comments,
                                            client.at_risk_status,
                                            client.client_info_note,
                                            client.date_lost,
                                            client.date_won,
                                            client.purchase_order_required,
                                            client.team_support_id,
                                            client.strategic_business_unit,
                                            client.sbu_client_id,
                                            client.harris_client_id,
                                            client.crm_id,
                                            client.recordid,
                                            services,
                                            productLines
                                        )
                                    )

                                    this.setState({
                                        columns: columns,
                                    })
                                })
                            }
                        })
                    })
                }
            })
            
        }
    }

    handleOpen = client => {
        if (client == 0) {
           // console.log(this.state.crmData)
            this.props.setFormData(
                {crmData: this.state.crmData, client: {}},
                'ClientInfo'
            )
        } else {
            //console.log(client)
            this.props.setFormData(
                { client: client, crmData: this.state.crmData },
                'ClientInfo'
            )
        }
    }


    getClientList = client => {
        const { classes } = this.props

        if (this.state.bubbleView) {
            if (
                client.strategic_business_unit ==
                JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit.replace(/[' ']/g , '')
            ) {
                return (
                    <React.Fragment>
                        <Grid item xs={2}>
                            <Button
                                className={classes.lowercase}
                                onClick={() => this.handleOpen(client)}
                            >
                                <ListItem>
                                    <ListItemText
                                        primary={client.strategic_business_unit}
                                        secondary={
                                            <div>
                                                <div>
                                                    {client.sbu_client_id}
                                                </div>
                                                <div>
                                                    <i>
                                                        {
                                                            client.harris_client_id
                                                        }
                                                    </i>
                                                </div>
                                            </div>
                                        }
                                    />
                                </ListItem>
                            </Button>
                        </Grid>
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <Grid item xs={2}>
                            <ListItem>
                                <ListItemText
                                    primary={client.strategic_business_unit}
                                    secondary={
                                        <div>
                                            <div>{client.sbu_client_id}</div>
                                            <div>
                                                <i>{client.harris_client_id}</i>
                                            </div>
                                        </div>
                                    }
                                />
                            </ListItem>
                        </Grid>
                    </React.Fragment>
                )
            }
        } else {
            if (
                client.strategic_business_unit ===
                JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit.replace(/[' ']/g , '')
            ) {
                return (
                    <React.Fragment>
                        <TableRow
                            onClick={() => this.handleOpen(client)}
                            className={classes.highlight}
                        >
                            <TableCell>
                                {client.active !== "0" && (
                                    <FontAwesomeIcon
                                        icon={faCaretRight}
                                        size="1x"
                                    />
                                )}
                                {client.active && ' '}
                                {client.strategic_business_unit}
                            </TableCell>
                            <TableCell>{client.date_won}</TableCell>
                            <TableCell>{client.date_lost}</TableCell>

                            <TableCell>
                                {client.at_risk_status === 'YES'
                                    ? 'At Risk'
                                    : 'Not At Risk'}
                            </TableCell>
                            <TableCell>{client.products}</TableCell>
                            <TableCell>{client.services}</TableCell>
                        </TableRow>
                    </React.Fragment>
                )
            } else {
                return (
                    <React.Fragment>
                        <TableRow>
                            <TableCell>
                                {client.strategic_business_unit}
                            </TableCell>
                            <TableCell>{client.date_won}</TableCell>
                            <TableCell>{client.date_lost}</TableCell>

                            <TableCell>
                                {client.at_risk_status === 'NO'
                                    ? 'Not At Risk'
                                    : 'At Risk'}
                            </TableCell>
                            <TableCell>{client.products}</TableCell>
                            <TableCell>{client.services}</TableCell>
                        </TableRow>
                    </React.Fragment>
                )
            }
        }
    }

    viewGridView = list => {
        const { classes } = this.props
        return (
            
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>SBU</TableCell>
                            <TableCell>Active Date</TableCell>
                            <TableCell>Inactive Date</TableCell>
                            <TableCell>Risk Status</TableCell>
                            <TableCell>Product Lines</TableCell>
                            <TableCell>Services</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{list}</TableBody>
                </Table>
            
        )
    }

    setBubble = () => {
        this.setState({ bubbleView: true })
    }
    setGrid = () => {
        this.setState({ bubbleView: false })
    }

    showMore = array => {
        let arr = array.columns.map(client => this.getClientList(client), this)

        arr[arr.length + 1] = (
            <React.Fragment>
                <Grid item xs={true} />
            </React.Fragment>
        )
        if (!this.state.bubbleView) {
            return this.viewGridView(arr)
        } else {
            return arr
        }
    }
    render() {
        const { classes } = this.props
        //console.log(this.state.add)
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        {/* <Card> */}
                            {/*<div className={classes.secondary}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={11}>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            color="inherit"
                                            className={classes.textField}
                                        >
                                            Client Info
                                        </Typography>
                                    </Grid>
                                    {this.state.add && (
                                        <Grid item className={classes.addGrid}>
                                            <IconButton
                                                className={classes.add}
                                                onClick={() => this.handleOpen(0)}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    color="white"
                                                />
                                            </IconButton>
                                        </Grid>
                                        )   
                                    }
                                </Grid>
                                </div>*/}
                            <Grid
                                container
                                spacing={1}
                                className={classes.sort}
                            >
                                <Grid item>
                                    <Tooltip title={'Bubble View'}>
                                        <IconButton
                                            onClick={this.setBubble}
                                            size="small"
                                            className={classes.add}
                                            style={this.state.bubbleView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripHorizontal}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={'Grid View'}>
                                        <IconButton
                                            onClick={this.setGrid}
                                            size="small"
                                            className={classes.add}
                                            style={!this.state.bubbleView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripLines}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                                {this.state.add && (
                                    <Grid item className={classes.addGrid}>
                                        <Tooltip title={'Add Client Info'}>
                                            <Button
                                                className={classes.add}
                                                onClick={() => this.handleOpen(0)}
                                            >
                                                Add Client Info
                                                <FontAwesomeIcon
                                                        icon={faPlusCircle}
                                                        color="black"
                                                        style={{
                                                            marginLeft: 5,
                                                        }}
                                                />
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                            <Divider variant='middle'/>

                            <List className={classes.root}>
                                <Grid
                                    container
                                    spacing={1}
                                    alignItems="center"
                                    justify="space-evenly"
                                >
                                    {this.showMore(this.state)}
                                </Grid>
                            </List>
                        {/* </Card> */}
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

ClientInfo.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ClientInfo)
