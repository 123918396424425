import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

import inhance from '../../../images/SBU/inhance.png'
import smartworks from '../../../images/SBU/smartworks.png'
import partner from '../../../images/SBU/partner.png'
import cayenta from '../../../images/SBU/cayenta.png'
import datavoice from '../../../images/SBU/datavoice.png'
import horizons from '../../../images/SBU/horizons.png'
import dynatouch from '../../../images/SBU/dynatouch.png'
import cogsdale from '../../../images/SBU/cogsdale.png'
import northstar from '../../../images/SBU/northstar.png'
import advanced from '../../../images/SBU/advanced.png'
import cognera from '../../../images/SBU/cognera.png'

import NumberFormat from 'react-number-format'
import { withStyles } from '@material-ui/core/styles'
import {
    Checkbox,
    Modal,
    Paper,
    Menu,
    TableCell,
    IconButton,
    Icon,
    MenuItem,
    ListItem,
    List,
    ListItemText,
    InputBase,
    Tooltip,
    Grid,
    Chip,
    Button,
    Typography,
    Collapse,
    TextField,
    ClickAwayListener,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTint,
    faTintSlash,
    faLightbulbExclamation,
    faWifi,
    faNetworkWired,
    faTvRetro,
    faSatelliteDish,
    faTrashAlt,
    faPhoneVolume,
    faWaterLower,
    faThunderstorm,
    faSun,
    faLightbulbSlash,
    faLightbulb,
    faBurn,
    faEdit,
} from '@fortawesome/pro-regular-svg-icons'
import StreetLight from '../../../images/icons/street-light.ico'
import SearchIcon from '@material-ui/icons/Search'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    lower: {
        textTransform: 'capitalize',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    pullRight: {
        float: 'right',
    },
})

class CallCampaign extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchVal: '',
            searchField: '',
            columns: [],
            change: false,
            redirect: false,
            first: true,
            utility_type: 'All',
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
            meter_count: [0, null],
        }

        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
    }

    handleSearchChange = e => {
        this.setState({ searchField: e.target.value })
    }

    componentWillMount() {
        this.search(this.state)
        this.getDropdowns()
    }
    getDropdowns = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'CALL_CAMPAIGN',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }
    handleFilterChange = e => {
        let value = e.target.value
        let name = e.target.name
        value.replace(/,/g, '')

        let nextState = this.state
        nextState[name] = value
        this.search(nextState)
    }

    handleSearchChange = e => {
        e.persist()
        this.setState(() => {
            return { searchField: e.target.value }
        })
    }

    handleSearchClick = () => {
        this.setState({ searchVal: this.state.searchField })
        let nextState = this.state
        nextState.searchVal = this.state.searchField
        this.search(nextState)
    }
    
    getChip = bu => {
        switch (bu) {
            case 'Cayenta':
                return <img src={cayenta} style={{ height: 25, width: 80 }} />
            case 'dataVoice':
                return (
                    <img
                        src={datavoice}
                        style={{ height: 32, width: 'auto' }}
                    />
                )
            case 'Horizons':
                return (
                    <img src={horizons} style={{ height: 30, width: 'auto' }} />
                )
            case 'Partner':
                return (
                    <img src={partner} style={{ height: 25, width: 'auto' }} />
                )
            case 'Advanced':
                return (
                    <img src={advanced} style={{ height: 30, width: 'auto' }} />
                )
            case 'SmartWorks':
                return (
                    <img
                        src={smartworks}
                        style={{ height: 30, width: 'auto' }}
                    />
                )
            case 'inHANCE':
                return (
                    <img src={inhance} style={{ height: 30, width: 'auto' }} />
                )
            case 'inHance':
                return (
                    <img src={inhance} style={{ height: 30, width: 'auto' }} />
                )
            case 'Cognera':
                return (
                    <img src={cognera} style={{ height: 30, width: 'auto' }} />
                )
            case 'Cogsdale':
                return (
                    <img src={cogsdale} style={{ height: 30, width: 'auto' }} />
                )
            case 'NorthStar':
                return (
                    <img
                        src={northstar}
                        style={{ height: 30, width: 'auto' }}
                    />
                )
            case 'DynaTouch':
                return (
                    <img
                        src={dynatouch}
                        style={{ height: 30, width: 'auto' }}
                    />
                )
            default:
                return bu
        }
    }
    search = (nextState = this.state) => {
        const data = {
            action: 'getCRMdata',
            sbu: nextState.sbu ? nextState.sbu : this.state.sbu,
            searchVal: nextState.searchVal,
            meterCountLow: nextState.meter_count ? nextState.meter_count[0] : this.state.meter_count[0],
            meterCountHigh: nextState.meter_count
                ? nextState.meter_count[1]
                : this.state.meter_count[1],
            utility_type: nextState.utility_type ? nextState.utility_type : this.state.utility_type,
        }

        PostData('MarketingReports/ajax/ajaxCRM_muni_data.php', data).then(
            response => {
                nextState.columns = response.resultSet
                nextState.change = false
                if(nextState.first){
                    nextState.meter_count[1] = response.maxMeters
                    nextState.first = false
                }
                this.setState(nextState)
            }
        )
    }
    setRedirect = site =>
        this.setState(() => {
            return { redirect: site }
        })
    hoverLink = () =>
        this.setState(prevState => {
            return { redirect: !this.state.redirect }
        })
    handleMeterChange = e =>{
        let meters = this.state.meter_count
        let value = e.target.value
        value = value.replace(/,/g, '')
        meters[e.target.id] = value

        this.setState({meter_count: meters, change: true})
    }
    render() {
        const { classes } = this.props

        const rows = [
            {
                id: 'crm_id',
                numeric: false,
                disablePadding: true,
                label: 'CRM ID',
            },
            {
                id: 'company',
                numeric: true,
                disablePadding: false,
                label: 'Company',
            },
            {
                id: 'meter_count',
                numeric: true,
                disablePadding: false,
                label: 'Meter Count',
            },
            {
                id: 'address',
                numeric: true,
                disablePadding: false,
                label: 'Address',
            },
            {
                id: 'phone',
                numeric: true,
                disablePadding: true,
                label: 'Phone',
            },
            {
                id: 'utility_type',
                numeric: true,
                disablePadding: false,
                label: 'Utility Type',
            },
            {
                id: 'strategic_business_unit',
                numeric: false,
                disablePadding: false,
                label: 'SBU',
            },
        ]

        return (
            <React.Fragment>
                <EnhancedTable
                    className={classes.secondary}
                    rows={rows}
                    columns={this.state.columns}
                    showHeader={true}
                    link={!this.state.redirect && '/company/'}
                    toolbar={
                        <CustomToolbar
                            classes={classes}
                            handleSearchClick={this.handleSearchClick}
                            handleSearchChange={this.handleSearchChange}
                            handleFilterChange={this.handleFilterChange}
                            handleMeterChange={this.handleMeterChange}
                            sbu={this.state.sbu}
                            dropdown={this.state.dropdown}
                            utility_type={this.state.utility_type}
                            meter_count={this.state.meter_count}
                            search={this.search}
                            change={this.state.change}
                        />
                    }
                    tableRow={
                        <CustomTableCells
                            openClientInfoForm={this.openClientInfoForm}
                            hoverLink={this.hoverLink}
                            getChip={this.getChip}
                        />
                    }
                />
            </React.Fragment>
        )
    }
}

class CustomTableCells extends React.Component {
    render() {
        const n = this.props.n

        return (
            <React.Fragment>
                <TableCell padding="checkbox" />
                <TableCell
                    style={{ cursor: 'pointer' }}
                    component="th"
                    scope="row"
                    padding={'default'}
                >
                    {n.crm_id}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.company}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.meter_count}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {`${n.address_1}, ${n.city},`}
                    <br /> {` ${n.state}, ${n.zip_code}`}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.phone}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.utility_type}
                </TableCell>
                {/*<TableCell style={{ cursor: 'pointer' }} onMouseEnter={this.props.hoverLink} onMouseLeave={this.props.hoverLink} align="right">
                    <a href={'https://'+n.web_site} rel='external' target='_blank'>{n.web_site}</a>
        </TableCell>*/}
                <TableCell
                    style={{ cursor: 'pointer', maxWidth: '31vw' }}
                    align="left"
                >
                    {n.strategic_business_unit.map(bu => (
                        <Chip label={this.props.getChip(bu)} />
                    ))}
                </TableCell>
            </React.Fragment>
        )
    }
}

class CustomToolbar extends React.Component {
    render() {
        let sbu = []
        this.props.dropdown &&
            this.props.dropdown.forEach(dropdown => {
                if (dropdown.control_code === 'STRATEGIC_BUSINESS_UNIT') {
                    sbu.push(dropdown)
                }
            })

        const { classes } = this.props
        return (
            <React.Fragment>
                <Grid spacing={5} container>
                    <Grid item style={{ paddingTop: 5, marginTop: 35 }}>
                        <InputBase
                            className={this.props.classes.input}
                            placeholder={'Search'}
                            onChange={this.props.handleSearchChange}
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    this.props.handleSearchClick()
                                    ev.preventDefault()
                                }
                            }}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 10 }}>
                        <IconButton
                            className={this.props.classes.iconButton}
                            aria-label={'Search'}
                            onClick={this.props.handleSearchClick}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        sm={5}
                        md={4}
                        lg={3}
                        xl={2}
                        style={{ marginTop: 5 }}
                    >
                        <TextField
                            value={this.props.sbu}
                            name="sbu"
                            id="sbu"
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            
                            label="Strategic Business Unit"
                        >
                            {sbu &&
                                sbu.map(item => (
                                    <MenuItem
                                        value={item.item_name}
                                        key={item.item_name}
                                    >
                                        {item.item_name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        sm={4}
                        md={3}
                        lg={2}
                        xl={1}
                        style={{ marginTop: 5 }}
                    >
                        <TextField
                            value={this.props.utility_type}
                            id="utility_type"
                            name="utility_type"
                            label="Utility Type"
                            onChange={this.props.handleFilterChange}
                            select
                            fullWidth
                            
                        >
                            {this.props.dropdown &&
                                this.props.dropdown.map(
                                    item =>
                                        item.control_code ===
                                            'UTILITY_TYPE' && (
                                            <MenuItem
                                                value={item.item_name}
                                                key={item.item_name}
                                            >
                                                {item.item_name}
                                            </MenuItem>
                                        )
                                )}
                        </TextField>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={2} xl={1}>
                        <ClickAwayListener onClickAway={this.props.change && this.props.search}>
                            <NumberFormat
                                
                                value={
                                    this.props.meter_count &&
                                    this.props.meter_count[0]
                                }
                                id={0}
                                customInput={TextField}
                                thousandSeparator={true}
                                name={'meter_count_low'}
                                
                                decimalSeparator={false}
                                label={'Meters Greater Than'}
                                onChange={this.props.handleMeterChange}
                            />
                        </ClickAwayListener>
                    </Grid>
                    <Grid item xs={4} sm={3} md={2} lg={2} xl={1}>
                        <ClickAwayListener onClickAway={()=>this.props.change && this.props.search}>
                            <NumberFormat
                                
                                thousandSeparator={true}
                                customInput={TextField}
                                decimalSeparator={false}
                                value={
                                    this.props.meter_count &&
                                    this.props.meter_count[1]
                                }
                                id={1}
                                name={'meter_count_high'}
                                
                                label="Meters Less Than"
                                onChange={this.props.handleMeterChange}
                            />
                        </ClickAwayListener>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

CallCampaign.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CallCampaign)
