import React from 'react'
import PropTypes from 'prop-types'

import { PostData } from '../../../services/PostData'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import { addValidators, validate } from '../../../utils/Validate'

import { faSave } from '@fortawesome/free-solid-svg-icons'

import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import FormWrapper from '../../Reusable/FormWrapper'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
})

class SystemsForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            systemData: {
                system_recordid: '',
                system_type: '',
                system_name: '',
                active: '',
                notes: '',
                modified_by_userid: '',
                modified_datetime: '',
                install_date: '',
            },
            inactive: [],
            dropdown: [],
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    componentWillMount() {
        this.setState({ systemData: this.props.formData.systemData })
        this.setState({ inactive: this.props.formData.inactive })
        this.getDropdown()
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.formData.systemData && nextProps.formData.systemData.modified_datetime){
            nextProps.formData.systemData.modified_datetime = nextProps.formData.systemData.modified_datetime
        }
        if(nextProps.formData.systemData && nextProps.formData.systemData.install_date){
            nextProps.formData.systemData.install_date = nextProps.formData.systemData.install_date
        }
            //if the modified_datetime and install date was saved in the database with time the line above gets rid of it so it shows up in the date field,
        //does nothing if it is just date without time
        this.setState({ systemData: nextProps.formData.systemData })
        this.setState({ inactive: nextProps.formData.inactive })
        this.getDropdown()
    }

    //handles all changes
    handleChange = event => {
        let newSystemData = this.state.systemData
        if(event.target.name == 'system_name' ){
            newSystemData.created_by_userid = JSON.parse(sessionStorage.getItem('userData')).userid
            newSystemData.created_datetime = this.getCurrentDate()
            newSystemData.active = 1
        }
        if(event.target.name == 'system_name' && event.target.value == 'None'){
            newSystemData.created_by_userid = null
            newSystemData.created_datetime = null
            newSystemData.active = 0
        }

        newSystemData[event.target.name] = event.target.value
        this.setState({ systemData: newSystemData })
    }

    //saves fields
    handleSave = () => {
        const data = {
            action: 'updateSystemInfoRecord',
            userid: JSON.parse(sessionStorage.getItem('userData')).userid,
            system_recordid: this.state.systemData.system_recordid,
            system_name: this.state.systemData.system_name,
            active: this.state.systemData.active,
            notes: this.state.systemData.notes,
            created_datetime: this.state.systemData.created_datetime,
            created_by_userid: this.state.systemData.created_by_userid,
        }

        PostData('CRM/ajax/ajaxCRM_SystemInfo.php', data).then(response => {
            //this.handleClose()
            this.props.updateComponent('Systems')
        })
        return true
    }

    toTitleCase = str =>{
        str= str.replace(/_/g, ' ')
        return str.replace(
            /([^\W_]+[^\s-]*) */g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }


    //closes sidebar
    handleClose = () => {
        this.props.handleFormClose()
    }
    getCurrentDate(separator = '-') {
        let newDate = new Date()
        let date = newDate.getDate()
        if (date < 10) {
            date = String(date)
            date = '0' + date
        }
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()

        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`
    }


    deleteSystem = () => {
        let newSystemData = this.state.systemData
        newSystemData.system_name = 'None'
        newSystemData.created_by_userid = null
        newSystemData.created_datetime = null
        newSystemData.active = 0
        this.setState({ systemData: newSystemData })
        this.handleSave()
        this.handleClose()
    }

    //gets all dropdowns for the page
    getDropdown = () => {
        const data = {
            action: 'getDropdownItems',
            subsystem_code: 'SYSTEMS',
            control_code: this.props.formData.systemData.system_type.toUpperCase()
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            //filters system type for ones that are not active
            let newState = this.state
            if (response.result) {
                let dropdowns = []
                response.dropdowns.filter(dropdown => {
                    let condition = false
                    //if inactive is false, adds dropdown no matter what since system type will be already set
                    if (newState.inactive !== false) {
                        newState.inactive.forEach(field => {
                            if (field.system_type === dropdown.item_code.toLowerCase() || dropdown.control_code !== 'SYSTEM_TYPE') {
                                condition = true
                            }
                        })
                    } else {
                        condition = true
                    }

                    if (condition) {
                        dropdowns.push(dropdown)
                    }
                })

                console.log(dropdowns)
                newState.dropdown = dropdowns
                this.setState(newState)
            }
        })
    }

    //displays specific dropdown on page
    viewDropdown = (control, name) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })

        console.log(list)
        
        return (
            <TextField
                select
                fullWidth
                id={name==='Type' ? name.toLowerCase() : 'system_'+ name.toLowerCase()}
                name={name==='Type' ? name.toLowerCase() : 'system_'+ name.toLowerCase()}
                label={'System ' + name}
                className={classes.textField}
                onChange={name === 'Type' ? this.handleNew : this.handleChange}
                value={this.state.systemData['system_' + name.toLowerCase()]}
                
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.name}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    //If adding a new system, this function adds extra field
    newSystem = () => {
        const { classes } = this.props

        if (this.state.systemData.system_type == undefined) {
            return (
                <Grid item xs={12} sm={12}>
                    {this.viewDropdown('SYSTEM_TYPE', 'Type')}
                </Grid>
            )
        }
    }

    //If adding new system, this will find the record in the database once a service type is chosen
    handleNew = event => {
        let type = event.target.value

        this.state.inactive.forEach(system => {
            if (system.system_type == type) {
                const data = {
                    action: 'getSystemInfo',
                    system_recordid: system.system_recordid,
                }

                PostData('CRM/ajax/ajaxCRM_SystemInfo.php', data).then(
                    response => {
                        this.setState({
                            systemData: response.UtilitySystemsInfo,
                        })
                        this.state.systemData.active = '1'
                    }
                )
            }
        })
    }

    render() {
        const { classes } = this.props

        return (

            <FormWrapper
                invalid={this.state.invalid}
                type="System"
                name={this.toTitleCase(this.state.systemData.system_type)}
                handleSave={this.handleSave}
                handleClose={this.handleClose}
                handleDelete={this.deleteSystem}
            >
                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h6" color="inherit">
                        General
                    </Typography>
                </Grid>
                {this.newSystem()}

                <Grid item xs={12}>
                    {this.viewDropdown(this.state.systemData.system_type.toUpperCase(), 'Name')}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        disabled
                        InputLabelProps={{ shrink: true }}
                        name='created_datetime'
                        type='date'
                        label='Created Date'
                        onChange={this.handleChange}
                        value={this.state.systemData.created_datetime}
                        className={classes.textField}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        disabled
                        name='created_by_userid'
                        label='Created By'
                        onChange={this.handleChange}
                        value={this.state.systemData.created_by_userid}
                        className={classes.textField}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        InputLabelProps={{ shrink: true }}
                        name='modified_datetime'
                        type='date'
                        label='Modified Date'
                        id='modified_datetime'
                        onChange={this.handleChange}
                        value={this.state.systemData.modified_datetime}
                        className={classes.textField}
                        disabled
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="modified_by_userid"
                        label="Modified By"
                        id="modified_by_userid"
                        onChange={this.handleChange}
                        value={this.state.systemData.modified_by_userid}
                        className={classes.textField}
                        fullWidth
                        disabled
                    />
                </Grid>
                
                
                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h6" color="inherit">
                        Notes
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        multiline
                        rows="3"
                        variant="outlined"
                        id="notes"
                        name="notes"
                        label="Notes"
                        fullWidth
                        className={classes.textField}
                        value={this.state.systemData.notes}
                        onChange={this.handleChange}
                    />
                </Grid>

                {/* <Grid item xs={12} sm={12}>
                    <Button onClick={this.deleteSystem}>Delete System</Button>
                </Grid> */}
            </FormWrapper>

            //         </Grid>
            //     </Card>
            // </Grid>
        )
    }
}

SystemsForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SystemsForm)
