import React from 'react'
import PropTypes from 'prop-types'

import { PostData } from '../../../services/PostData'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'

import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import FormWrapper from '../../Reusable/FormWrapper'
import { addValidators, validateOne } from '../../../utils/Validate'

const styles = theme => ({
    textField: {
        margin: 1,
        marginLeft: 5,
        paddingBottom: 0,
        paddingTop: 5,
        paddingLeft: 3,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
})

class AliasForm extends React.Component {
    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }
    //saves state change
    handleChange = event => {
        let change = this.state
        change[event.target.name][2] = event.target.value

        this.setState(change)
    }

    handleClose = () => {
        this.props.handleFormClose()
    }
    //creates state for each field containing a list with values [id, label, defaultValue ,isdropdown(bool)]
    componentWillMount() {
        this.setupForm(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.setupForm(nextProps)
    }

    setupForm = props => {
        if (props.formData !== undefined) {
            for (let field in props.formData) {
                let new_state = {}
                new_state[field] = props.formData[field]
                this.setState(new_state)
            }
        }

        addValidators(this, {
            alias_company_name: 'required',
        })
    }

    handleSave = () => {
        
        if (
            validateOne(
                this,
                this.state.alias_company_name[0],
                this.state.alias_company_name[2]
            )
        ) {
            //this.props.handleFormClose()
            
            let data
            if (!this.state.add) {
                data = {
                    action: 'update_crm_alias_record',
                    user: JSON.parse(sessionStorage.getItem('userData')).userid,
                    recordid: this.state.recordid[2],
                    notes: this.state.notes[2] ? this.state.notes[2] : '',
                    alias_company_name: this.state.alias_company_name[2],
                }
            } else {
                data = {
                    action: 'insert_crm_alias_record',
                    user: this.state.modified_by_userid[2],
                    crm_id: this.state.crm_id[2],
                    notes: this.state.notes[2] ? this.state.notes[2]: ''  ,
                    alias_company_name: this.state.alias_company_name[2],
                }
            }
            PostData('CRM/ajax/ajaxCRM_master_alias.php', data).then(
                response => {
                    if (response.result) {
                        this.props.updateComponent('Alias')
                        this.setState({add: false})
                    }
                }
            )

            return true
        }

        return false
    }
    //renders each field
    getAliasList = alias => {
        let fieldList = []
        const { classes } = this.props
        for (let field in alias) {
            if (
                field !== 'alias_company_name' &&
                field !== 'company' &&
                field !== 'created_by_userid' &&
                field !== 'created_datetime' &&
                field !== 'modified_datetime' &&
                field !== 'modified_by_userid' &&
                field !== 'crm_id' && 
                field !== 'notes'
            ) {
                continue
            }

            fieldList.push(
                <Grid item xs={12} sm={field === 'notes' || field === 'alias_company_name' ? 12 : 6}>
                    <TextField
                        required={(field === 'alias_company_name')}
                        disabled={alias[field][3]}
                        id={alias[field][0]}
                        name={alias[field][0]}
                        label={alias[field][1]}
                        fullWidth
                        value={alias[field][2]}
                        className={classes.textField}
                        defaultValue={alias[field][2]}
                        onChange={this.handleChange}
                        error={
                            alias[field][0] === 'alias_company_name' &&
                            this.state.errorMessages.alias_company_name !==
                                undefined
                        }
                        helperText={
                            alias[field][0] === 'alias_company_name'
                                ? this.state.errorMessages.alias_company_name
                                : null
                        }
                        multiline={field === 'notes'}
                        rowsMax={15}
                        variant={field === 'notes' ? 'outlined' : 'standard'}
                    />
                </Grid>
            )
        }
        return <React.Fragment>{fieldList}</React.Fragment>
    }

    render() {
        const { classes } = this.props

        return (
            <FormWrapper
                invalid={this.state.invalid}
                type="Alias"
                name={this.props.formData.alias_company_name[2]}
                handleSave={this.handleSave}
                handleClose={this.handleClose}
            >
                {this.getAliasList(this.state)}
            </FormWrapper>

            // <Grid container className={classes.outside}>
            //     <Card>
            //         <SaveWarning invalid={this.state.invalid} />

            //         <div className={classes.secondary}>
            //             <Grid container>
            //                 <Typography
            //                     component="h1"
            //                     variant="h6"
            //                     color="inherit"
            //                     className={classes.textField}
            //                 >
            //                     {this.props.formData.alias_company_name[2]
            //                         ? 'Edit Alias'
            //                         : 'Add Alias'}
            //                 </Typography>
            //                 <Grid item className={classes.buttonGrid}>
            //                     <Button
            //                         color="primary"
            //                         className={classes.save}
            //                         onClick={this.handleSave}
            //                     >
            //                         Save
            //                     </Button>

            //                     <Button onClick={this.handleClose}>
            //                         <FontAwesomeIcon
            //                             icon={faTimes}
            //                             size="2x"
            //                             color="white"
            //                         />
            //                     </Button>
            //                 </Grid>
            //             </Grid>
            //         </div>

            //         <Grid container spacing={1} className={classes.body}>
            //             {this.getAliasList(this.state)}
            //         </Grid>
            //     </Card>
            // </Grid>
        )
    }
}

AliasForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AliasForm)
