import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Card, Divider } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import TableWithSelector from '../../Reusable/TableWithSelector'
const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },
    lowercase: {
        textTransform: 'none',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    add: {
        padding: 5,
        margin: 1,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    lines: {
        cursor: 'pointer',
        backgroundColor: theme.palette.secondary.paper,
    },
    selected: {
        backgroundColor: 'rgb(230,230,230)',
        color: theme.palette.secondary.main.dark,
        fontWeight: '500',
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        //marginLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '98%',
        //background: 'rgb(248,248,248)',
    },
    list: {
        marginTop: theme.spacing(1),
        borderTop: '2px solid rgb(232,232,232)',
    },
    highlight: {
        cursor: 'pointer',
    },
})
class Finance extends React.Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        const { classes } = this.props
        return (
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Card>
                        <div className={classes.secondary}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item>
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        color="inherit"
                                        className={classes.textField}
                                    >
                                        Finance
                                    </Typography>
                                </Grid>
                                {/*<Grid item className={classes.addGrid}>
                            <Tooltip title={'Add System'}>
                                <IconButton
                                    onClick={() =>
                                        this.handleOpen()
                                    }
                                    className={classes.add}
                                >
                                    <FontAwesomeIcon
                                        icon={faPlusCircle}
                                        color="white"
                                    />
                                </IconButton>
                            </Tooltip>
                                </Grid>*/}
                            </Grid>
                        </div>

                        <TableWithSelector
                            active="test"
                            header={[
                                'Test 1' ,
                                'Test 2' ,
                                'Test 3' ,
                            ]}
                            data={[
                                {
                                    tableName: 'test',
                                    test_1: '1',
                                    test_2: '2',
                                    test_3: '3',
                                },
                                {
                                    tableName: 'test',
                                    test_1: '4',
                                    test_2: '5',
                                    test_3: '6',
                                },
                                {
                                    tableName: 'test',
                                    test_1: '7',
                                    test_2: '8',
                                    test_3: '9',
                                },
                                {
                                    tableName: 'Financial Data',
                                    test_1: '10',
                                    test_2: '11',
                                    test_3: '12',
                                },
                                {
                                    tableName: 'Financial Data',
                                    test_1: '13',
                                    test_2: '14',
                                    test_3: '15',
                                },
                                {
                                    tableName: 'Financial Data',
                                    test_1: '16',
                                    test_2: '17',
                                    test_3: '18',
                                },
                            ]}
                            selectorColumn="tableName"
                        />
                    </Card>
                </Grid>
            </Grid>
        )
    }
}
Finance.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(Finance)
