import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Typography from '@material-ui/core/Typography'
import Collapse from '@material-ui/core/Collapse'
import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { withStyles } from '@material-ui/core/styles'
import TypoGraphy from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'

import ListItemText from '@material-ui/core/ListItemText'
import Switch from '@material-ui/core/Switch'

import TextField from '@material-ui/core/TextField'

import ToolTip from '@material-ui/core/ToolTip'

import SaveWarning from '../../Reusable/SaveWarning'
import {
    faChevronDown,
    faChevronUp,
    faEdit,
} from '@fortawesome/pro-light-svg-icons'
import {
    List,
    ListItemIcon,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core'
import { faTimes, faCheck } from '@fortawesome/pro-light-svg-icons'
import ChangePassword from './ChangePassword'
import { faBuilding } from '@fortawesome/pro-regular-svg-icons'
import { PostData } from '../../../services/PostData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {
    TableCell,
    Table,
    TableRow,
    TableHead,
    TableBody,
} from '@material-ui/core'

const styles = theme => ({
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    root: {
        display: 'flex',
    },
    list: {
        paddingBottom: 0,
        marginBottom: 0,
    },
    more: {
        flexDirection: 'row',
        marginRight: 'auto',
    },
    listItem: {
        paddingVerical: 0,
    },
    listIcon: {
        paddingRight: 5,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        padding: 5,
    },
    paper: {
        marginRight: theme.spacing.unit * 6,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    avatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: '#605070',
    },
    phoneNumber: {
        fontSize: 16,
    },
    textField: {
        margin: 4,
        marginTop: 0,
        paddingTop: 5,
        marginBottom: 7,
    },
    buttonIcon: {
        marginRight: theme.spacing(1),
    },
    buttonText: {
        textTransform: 'none',
    },
})

class AccountSettings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            password: false,
            general: false,
            info: true,
            invalid: false,
        }
    }

    handleGeneral = () =>
        this.setState(prevState => {
            return { general: !prevState.general }
        })
    handlePassword = () =>
        this.setState(prevState => {
            return { password: !prevState.password }
        })
    handleInfo = () =>
        this.setState(prevState => {
            return { info: !prevState.info }
        })
    setInvalid = () =>
        this.setState(() => {
            return { invalid: true }
        })
    setValid = () =>
        this.setState(() => {
            return { invalid: false }
        })
    getHeader(value) {
        const { classes } = this.props
        let changeFunc = ''
        if (value === 'Change Password') {
            changeFunc = 'Password'
        } else if (value === 'Account Information') {
            changeFunc = 'Info'
        } else {
            changeFunc = value
        }
        return (
            <Grid
                style={{ marginLeft: 10, marginTop: 10 }}
                item
                className={classes.more}
            >
                <Typography component="h1" variant="h6" color="inherit">
                    {value}
                </Typography>

                <Button onClick={this['handle' + changeFunc]}>
                    {this.state[changeFunc.toLowerCase()] ? (
                        <FontAwesomeIcon icon={faChevronUp} />
                    ) : (
                        <FontAwesomeIcon icon={faChevronDown} />
                    )}
                </Button>
            </Grid>
        )
    }
    render() {
        const { classes } = this.props
        
        return (
            <Grid
                className={classes.outside}
                container
                direction="row"
                alignItems="stretch"
                xs={12}
            >
                <Card>
                    <SaveWarning invalid={this.state.invalid} />
                    <div className={classes.secondary}>
                        <Grid container>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                className={classes.textField}
                            >
                                Account Settings
                            </Typography>
                            <Grid item className={classes.buttonGrid}>
                                <Button onClick={this.props.handleFormClose}>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        size="2x"
                                        color="white"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            {this.getHeader('Account Information')}
                            <Collapse in={this.state.info}>
                                <AccountInfo
                                    handleFormClose={this.props.handleFormClose}
                                    classes={classes}
                                />
                            </Collapse>
                        </Grid>

                        <Grid item xs={12}>
                            {this.getHeader('Change Password')}
                            <Collapse in={this.state.password}>
                                <ChangePassword
                                    handleFormClose={this.handlePassword}
                                    invalid={this.setInvalid}
                                />
                            </Collapse>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        )
    }
}

AccountSettings.propTypes = {
    classes: PropTypes.object.isRequired,
}

//export default withStyles(styles)

class AccountInfo extends React.Component {
    constructor(props) {
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        const accessLvlNum = userData.access_level
        super(props)
        let accessLvl = this.accessLvlNumToWrd(accessLvlNum)
        this.state = {
            recent: false,
            recCompInfo: [],
            redirect: '',
            gridView: userData.default_grid_view,
            name: { edit: false, val: userData.name },
            userid: { val: userData.userid },
            title: { edit: false, val: userData.title },
            department: { edit: false, val: userData.department },
            strategic_business_unit: {
                edit: false,
                val: userData.strategic_business_unit,
            },
            access_level: { edit: false, val: accessLvl },
        }
    }

    componentWillMount() {
        this.getDropdowns()
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        let recents = userData.recent_companies
        

        if (typeof recents === 'string') {
            //Sometimes javascript automatically converts the stringified array to array automatically, when it doesn't this will fix it (can change every npm run start!!)
            recents = recents.substr(1, recents.length - 2) //shaves off [ and ] (first and last characters)
            recents = recents.split(',')

            typeof recents !== 'string' &&
                recents.forEach((id, i) => {
                    //loop does run twice (with different purpose), however max i is 5 so shouldnt matter performance wise
                    recents[i] = id.substr(1, id.length - 2)
                })
            
        }
        //Fetchs recent companies using crm_id provided in userData.recent_companies
        recents &&
            recents.forEach((id, i) => {
                //loops through recent companies
                //id refers to crmId, i refers to the iteration of the loop
                const data = {
                    action: 'doIdSearch',
                    LETTERsearch: id,
                }
                PostData('CRM/ajax/ajaxCRMSearch.php', data).then(response => {
                    if (response.result) {
                        this.setState(prevState => {
                            let newComp = response.resultSet[0]
                            let recCompInfo = prevState.recCompInfo
                            newComp['sort_order'] = i //adds sort order
                            recCompInfo.push(newComp)

                            if (i === recents.lenth - 1) {
                                //Fixes the order on the last iteration
                                recCompInfo = recCompInfo.sort((a, b) => {
                                    return a.sort_order - b.sort_order
                                })
                            }
                            return { recCompInfo: recCompInfo }
                        })
                    }
                })
            })
    }
    handleRecent = () =>
        this.setState(prevState => {
            return { recent: !prevState.recent }
        })
    handleRedirect = id => {
        this.setState(() => {
            return { redirect: id }
        })
    }

    getDropdowns = () => {
        const data = {
            subsystem_code: 'ACCOUNT_INFO',
            action: 'getNewDropdown',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            
            this.setState({ dropdown: response.dropdowns })
        })
    }
    handleSwitchChange = e => {
        let checked = e.target.checked
        let state = e.target.name
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        const data = {
            column_value: checked,
            action: 'updateColumnValue',
            qualifier: ' recordid = ' + userData.recordid + ' ',
            column_sql_type: 'boolean',
            column_name: 'default_grid_view',
            table_name: 'hug.harris_personnel',
            recordid: userData.recordid,
            user: userData.userid,
            sbu: userData.strategic_business_unit,
        }
        PostData('CRM/ajax/ajaxCRMLeadsTracker.php', data).then(response => {
            if (response.result) {
                this.setState(() => {
                    userData.default_grid_view = checked
                    sessionStorage.setItem('userData', JSON.stringify(userData))
                    return { [state]: checked }
                })
            }
        })
    }
    handleChange = e => {
        e.persist()
        this.setState(prevState => {
            let change = prevState[e.target.name]
            change.val = e.target.value
            return { [e.target.name]: change }
        })
    }

    accessLvlNumToWrd = num => {
        switch (num) {
            case '1':
                return 'Administrator'
            case '2':
                return 'SBU Admin'
            case '3':
                return 'SBU Read Only'
            case '4':
                return 'Sales'
            case '5':
                return 'Visitor'
        }
    }

    listItemSwitch = (label, state) => {
        return (
            <ListItem
                style={{
                    borderColor: '#e6e6e6',
                    borderStyle: 'none solid solid none',
                    borderWidth: 1,
                }}
                dense
            >
                <ListItemText primary={label} />
                <ListItemIcon>
                    <Switch
                        name={state}
                        checked={this.state[state]}
                        size="small"
                        onClick={this.handleSwitchChange}
                    />
                </ListItemIcon>
            </ListItem>
        )
    }
    accessLvlWrdToNum = word =>{
        switch(word){
            case('Administrator'):
                return '1'
            case('SBU Admin'):
                return '2'
            case('SBU Read Only'):
                return '3'
            case('Sales'):
                return '4'
            case('Visitor'):
                return '5'
            
        }
        return word
    }
    saveInfo = name => {
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        let column_value = this.state[name].val
        if(name==='access_level'){
            column_value = this.accessLvlWrdToNum(column_value)
        }
        const data = {
            column_value: column_value,
            action: 'updateColumnValue',
            qualifier: ' recordid = ' + userData.recordid + ' ',
            column_sql_type: 'text',
            column_name: name,
            table_name: 'hug.harris_personnel',
            recordid: userData.recordid,
            user: userData.userid,
            sbu: userData.strategic_business_unit,
        }
        PostData('CRM/ajax/ajaxCRMLeadsTracker.php', data).then(response => {
            
            if (response.result || response.result !== 'false') {
                this.setState(prevState => {
                    let change = prevState[name]
                    change.edit = false
                    userData[name] = column_value
                    sessionStorage.setItem('userData', JSON.stringify(userData))
                    return { [name]: change }
                })
            } else {
                this.handleEditButton(name)
            }
        })
    }
    handleEditButton = e =>
        this.setState(prevState => {
            let change = prevState[e]
            change.edit = !change.edit
            change.val =
                e === 'access_level'
                    ? this.accessLvlNumToWrd(
                        JSON.parse(sessionStorage.getItem('userData'))[e]
                    )
                    : JSON.parse(sessionStorage.getItem('userData'))[e]
            return { [e]: change }
        })
    editItem = (name, state, rightBorder, edit) => {
        const { classes } = this.props
        
        let borderStyle = {
            height: 55,
            borderStyle: 'none none solid solid',
            borderWidth: 1,
            borderColor: '#e6e6e6',
        }
        if (rightBorder) {
            borderStyle = {
                height: 55,
                borderStyle: 'none solid solid none',
                borderWidth: 1,
                borderColor: '#e6e6e6',
            }
        }
        
        let dropdowns = []
        if (state === 'access_level' || state === 'strategic_business_unit') {
            this.state.dropdown &&
                this.state.dropdown.forEach(item => {
                    if (
                        item.control_code === name.toUpperCase() ||
                        item.control_code === state.toUpperCase()
                    ) {
                        //console.log(item)
                        dropdowns.push(item)
                    }
                })
        }
        
        
        if (this.state[state].edit) {
            return (
                <React.Fragment>
                    <ListItem  style={borderStyle}>
                        <Grid container spacing = {2} xs={12}>
                            <Grid item xs={8}>
                                {state === 'access_level' ||
                                state === 'strategic_business_unit' ? (
                                    <TextField
                                        name={state}
                                        label={name}
                                        InputProps={{
                                            disableUnderline: true,
                                            spellCheck: 'false'
                                        }}
                                        value={this.state[state].val}
                                        onChange={this.handleChange}
                                        style={{
                                            margin: 5,
                                            marginBotton: 1,
                                            paddingBottom: 0,
                                            marginLeft: 0
                                        }}
                                        id={name}
                                        fullWidth
                                        select
                                    >
                                        {dropdowns &&
                                            dropdowns.map(item => (
                                                <MenuItem
                                                    key={item.item_name}
                                                    name={item.item_name}
                                                    value={item.item_name}
                                                >
                                                    {item.item_name}
                                                </MenuItem>
                                            ))}
                                    </TextField>
                                ) : (
                                    <TextField
                                        name={state}
                                        label={name}
                                        InputProps={{
                                            disableUnderline: true,
                                            spellCheck: 'false',
                                        }}
                                        value={this.state[state].val}
                                        onChange={this.handleChange}
                                        style={{
                                            margin: 5,
                                            marginBotton: 1,
                                            paddingBottom: 0,
                                            marginLeft: 0
                                        }}
                                        id={name}
                                        fullWidth
                                    />
                                )}
                            </Grid>
                            <Grid style={{paddingTop: 15}} item xs={2}>
                                <IconButton
                                    
                                    onClick={() => this.handleEditButton(state)}
                                    //style={{padding: 3, }}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </IconButton>
                            </Grid>
                            <Grid style={{paddingTop: 15}} item xs={2}>
                                <IconButton
                                    
                                    onClick={(state === 'strategic_business_unit' || state === 'access_level') ? () => {
                                        let userData = JSON.parse(sessionStorage.getItem('userData'))
                                        //console.log(userData)
                                        userData[state] = this.accessLvlWrdToNum(this.state[state].val)
                                        sessionStorage.setItem('userData', JSON.stringify(userData))
                                        //console.log(JSON.parse(sessionStorage.getItem('userData')))
                                        this.handleEditButton(state)
                                    } : () => this.saveInfo(state)}
                                >
                                    <FontAwesomeIcon icon={faCheck} />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </ListItem>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <ListItem style={borderStyle}>
                        <ListItemText
                            primary={name}
                            style={{ marginBottom: 0, paddingBottom: 0 }}
                            secondary={this.state[state].val}
                        />
                        <ListItemIcon>
                            {edit && (
                                <IconButton
                                   
                                    onClick={() => this.handleEditButton(state)}
                                >
                                    <FontAwesomeIcon icon={faEdit} />
                                </IconButton>
                            )}
                        </ListItemIcon>
                    </ListItem>
                </React.Fragment>
            )
        }
    }
    recentCompanies = () => {
        let recents = this.state.recCompInfo
        const { classes } = this.props

        return (
            <React.Fragment>
                <ListItem
                    style={{
                        height: 55,
                        borderColor: '#e6e6e6',
                        borderStyle: 'none solid solid solid',
                        borderWidth: 1,
                    }}
                    button
                    onClick={this.handleRecent}
                >
                    {/*<ListItemIcon>
                        <FontAwesomeIcon icon={faBuilding} />
                    </ListItemIcon>*/}
                    <ListItemText primary="My Recent Companies" />
                    <FontAwesomeIcon
                        icon={this.state.recent ? faChevronUp : faChevronDown}
                    />
                </ListItem>
                <Collapse in={this.state.recent}>
                    {/*<List style={{paddingLeft: 10, width: '50%'}} >
                        <TableHead>
                            <TableRow>
                                <CustomTableCells n={{crm_id: 'ID', company:'Name', utility_type: 'Type' }}/>
                            </TableRow>
                        </TableHead>*/}

                    {recents &&
                        recents.map(company => (
                            <ListItem
                                dense
                                button
                                onClick={() =>
                                    this.handleRedirect(company.crm_id)
                                }
                                style={{
                                    borderColor: '#e6e6e6',
                                    borderStyle: 'none solid solid solid',
                                    borderWidth: 1,
                                }}
                            >
                                <Typography
                                    variant="inherit"
                                    component="li"
                                    noWrap
                                >
                                    {company.company}
                                </Typography>
                                {/*<ListItemText primaryTypographyProps={{noWrap: true, align: 'justify'}} primary={company.company}/>*/}
                            </ListItem>
                        ))}
                    {/*</List >*/}
                </Collapse>
            </React.Fragment>
        )
    }
    render() {
        
        
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        
        //pretty easy to make a new basic title and val box with or without edit button, all you have to do is add the state
        // format {edit: bool (default to false as true will make it a textfield), val: string(default userData.[name])}
        //Once youve added the state just make the copy and paste the Grid and editItem func and change the values as needed
        //the format of the editItem func is (Label, id (string value used for column name in database and the state name **HAS TO BE THE SAME), borderRight (if true border will be on the right else on the left), edit(if true there will be an edit button else no edit button))
        return (
            <List>
                <Grid container direction="row">
                    <Divider
                        style={{
                            backgroundColor: '#e6e6e6',
                            borderColor: '#e6e6e6',
                            width: '100%',
                            color: '#e6e6e6',
                            padding: 0,
                            margin: 0,
                            height: 1,
                        }}
                    />
                    <Grid item xs={6}>
                        {this.editItem('Name', 'name', true, true)}
                    </Grid>
                    <Grid item xs={6}>
                        {this.editItem('User Id', 'userid', false, false)}
                    </Grid>
                    <Grid item xs={6}>
                        {this.editItem(
                            'Access',
                            'access_level',
                            true,
                            userData.super_user_flag ==
                                1 /*super users can change their access level */
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {this.editItem(
                            'Strategic Business Unit',
                            'strategic_business_unit',
                            false,
                            userData.name === 'Eric Skelton' || userData.name === 'Russell Hanson'//userData.access_level == 1
                        )}
                    </Grid>
                    <Grid item xs={6}>
                        {this.editItem(
                            'Title',
                            'title',
                            true,
                            userData.access_level < 3
                        ) /*only SBU Admins or Administrators can edit*/}
                    </Grid>
                    <Grid item xs={6}>
                        {this.editItem(
                            'Department',
                            'department',
                            false,
                            userData.access_level < 3
                        ) /*only SBU Admins or Administrators can edit*/}
                    </Grid>
                    {/*<Grid item xs={6}>
                        {/*this.listItemSwitch('Default Grid View', 'gridView')
                    </Grid>*/}
                    <Grid item xs={6}>
                        {this.recentCompanies()}
                    </Grid>
                </Grid>
                {this.state.redirect && (
                    <Redirect
                        to={{
                            pathname: '/company/' + this.state.redirect,
                            state: { closeForm: true },
                        }}
                    />
                )}
            </List>
        )
    }
}
AccountInfo.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AccountSettings)
