import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import { faArrowCircleLeft } from '@fortawesome/pro-light-svg-icons'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'

import DateFnsUtils from '@date-io/date-fns'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import CKEditor from 'ckeditor4-react'

import { PostData } from '../../../services/PostData'
import { getCurrentDate } from '../../../utils/Date'

import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'

import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { addValidators, validate } from '../../../utils/Validate'
import SaveWarning from '../../Reusable/SaveWarning'
import FormWrapper from '../../Reusable/FormWrapper'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
    toolbarSpacer: {
        marginTop: theme.spacing.unit * 9,
    },
    backButton: {
        margin: 2,
        padding: 0,
    },
})

class NoteForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            recordid: null,
            contact_date: this.getCurrentDate(),
            contact_time: new Date(),
            contact_type: '',
            contact_by: '',
            lead_source: '',
            source_id: '',
            campaign_id: '',
            privacy_status: 1,
            subject: '',
            notes: '',
            utility_contact_name: '',
            other: false,
            tradeshow: null,
            tradeshows: [],
            isEditing: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    handleClose = () => {
        this.props.handleFormClose()
    }

    handlePopoverClose = () => {
        this.setState({ anchorEl: null })
    }

    // componentWillReceiveProps(nextProps) {
    //     console.log(nextProps)
    //     this.setState({...this.state, nextProps})
    // }

    componentWillMount() {
        addValidators(this, {
            contact_type: 'required',
            subject: 'required',
        })

        this.getCompanyNames()
        this.getDropdown()
        //this.getTradeshows()

        this.setState({...this.state, ...this.props.formData})

        if (!this.props.formData.isEditing) {
            this.setState({
                contact_by: JSON.parse(sessionStorage.getItem('userData')).name,
            })
        }
    }

    getCurrentDate(separator = '-') {
        let newDate = new Date()
        let date = newDate.getDate()
        if (date < 10) {
            date = String(date)
            date = '0' + date
        }
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()

        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`
    }

    getCompanyNames() {
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        const data = {
            action: 'getUtilityPersonnelList',
            crm_id: this.props.crmData.crm_id,
            sbu: userData === null ? null : userData.strategic_business_unit,
            quote_type: 'NEW_NAMED',
        }

        PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {
            if (response.result) {
                let contactList = []
                for (let i = 0; i < response.UtilityPersonnelList.length; i++) {
                    contactList.push(response.UtilityPersonnelList[i].name)
                }

                this.setState({
                    crmData: this.props.crmData,
                    contactList: contactList,
                })
            }
        })
    }

    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'NOTES',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                let newState = this.state
                newState.dropdown = response.dropdowns

                const data2 = {
                    action: 'getContacts',
                    sbu: JSON.parse(sessionStorage.getItem('userData'))
                        .strategic_business_unit,
                }
                PostData('CRM/ajax/ajaxContact_Notes.php', data2).then(
                    response2 => {
                        if (response2.result) {
                            newState['contactByDropDown'] = response2.contacts

                            this.setState(newState)
                        }
                    }
                )
            }
        })
    }

    getTradeshows = () => {
        const data = {
            action: 'getTradeShowList',
            hug_schema: 'hug_datavoice',
            sort_direction: 'ASC',
            sbu: 'dataVoice',
            sort_column: 'event_name',
            requested_Sbu: 'ALL',
            wherefilter: 'ALL',
            wherefilteryear: new Date().getFullYear(),
            // wherefilteryear2: new Date().getFullYear()-1,
        }

        PostData('Migrations/dropdowns/ajax/ajaxGetDropdownItems.php', data).then(response => {
            if (this.state.tradeshows.length === 0) {
                this.setState({ tradeshows: response.tradeshows })
            }
        })
    }

    viewDropdown = (control, name) => {
        const { classes } = this.props
        let list = []
        if (this.state.dropdown) {
            this.state.dropdown.forEach(item => {
                if (item.control_code == control) {
                    list.push({ name: item.item_name, code: item.item_code })
                }
            })

            return (
                <TextField
                    select
                    fullWidth
                    required={name === 'Contact Type'}
                    id={control.toLowerCase()}
                    name={control.toLowerCase()}
                    label={name}
                    className={classes.textField}
                    onChange={this.handleChange}
                    value={this.state[control.toLowerCase()]}
                    error={
                        name === 'Contact Type' &&
                        this.state.errorMessages.contact_type !== undefined
                    }
                    helperText={
                        name === 'Contact Type' &&
                        this.state.errorMessages.contact_type
                    }
                >
                    {list.map(item => (
                        <MenuItem key={item.name} value={item.name}>
                            {item.name}
                        </MenuItem>
                    ))}
                </TextField>
            )
        }
    }

    onBackClick = () => {
        this.setState({ other: false })
    }

    handleChange = event => {
        if (event.target.value === 'Tradeshow Visit' || event.target.value === 'Appointment Scheduled at Show') {
            this.getTradeshows()
        }

        if (event.target.name === 'tradeshow') {
            this.setState({ lead_source: event.target.value, tradeshow: event.target.value })
        } else if (event.target.name === 'lead_source' && event.target.value === 'Other') {
            this.setState({ other: true, lead_source: '' })
        } else {
            this.setState({ [event.target.name]: event.target.value }, () => {console.log(this.state)})
        }
    }

    handleDateChange = date => {
        this.setState({ contact_date: date })
    }

    handleTimeChange = time => {
        this.setState({ contact_time: time })
    }

    handleNoteChange = event => {
        this.setState({ notes: event.editor.getData() })
    }

    handlePopoverClose = () => {
        this.setState({ anchorEl: null })
    }

    handleSave = event => {
        if (validate(this)) {
            let data = {
                action: 'insert_contact_notes_table',
                crm_id: this.props.crmData.crm_id,
                strategic_business_unit: JSON.parse(
                    sessionStorage.getItem('userData')
                ).strategic_business_unit,
                user_id: JSON.parse(sessionStorage.getItem('userData')).userid,  
            }

            if (this.props.quoteNumber) {
                data.quoteNumber = this.props.quoteNumber
            }

            if (this.props.workOrderNumber) {
                data.workOrderNumber = this.props.workOrderNumber
            }

            const combinedData = { ...data, ...this.state }

            if (combinedData.contact_type === 'Tradeshow Visit') {
                combinedData.contact_type = 'Trade_Show'
            } else if (combinedData.contact_type === 'Appointment Scheduled at Show') {
                combinedData.contact_type = 'Appointment_Scheduled'
            }

            PostData('CRM/ajax/ajaxContact_Notes.php', combinedData).then(
                response => {
                    this.props.updateComponent('Notes')
                    this.props.handleFormClose()
                }
            )

            return true
        }

        return false
    }
    //When lead source is in other mode, this checks if the value entered is in the dropdown
    //is executed upon any click in the drawer
    handleCheck = () => {
        if (this.state.other) {
            for (let i = 1; i < this.state.dropdown.length; i++) {
                if (this.state.dropdown[i].control_code === 'LEAD_SOURCE') {
                    if (
                        this.state.dropdown[i].item_name ===
                        this.state.lead_source
                    ) {
                        this.setState({ other: false })
                    }
                }
            }
        }
    }

    render() {
        const { classes } = this.props
        const modal = this.props.modal

        return (
            <Grid
                container
                className={classes.outside}
                onClick={this.handleCheck}
            >

                <FormWrapper
                    invalid={this.state.invalid}
                    type="Note"
                    name={this.state.isEditing ? this.state.subject : false}
                    handleSave={this.handleSave}
                    handleClose={this.handleClose}
                    alwaysClose
                >
                    <Grid container spacing={1} className={classes.body}>
                        <Grid item xs={12}  >
                            <TextField
                                required
                                label="Subject"
                                name="subject"
                                defaultValue={this.state.subject}
                                onChange={this.handleChange}
                                fullWidth
                                className={classes.textField}
                                error={
                                    this.state.errorMessages.subject !==
                                    undefined
                                }
                                helperText={this.state.errorMessages.subject}
                            />
                        </Grid>
                        <Grid item sm={12} md={6} lg={modal ? 3 : 6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    id='contact_date'
                                    name="contact_date"
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    disabled={this.state.isEditing}
                                    label="Date"
                                    className={classes.textField}
                                    value={this.state.contact_date}
                                    onChange={this.handleDateChange}
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider>
                            {/* <TextField
                                label='Date'
                                id = 'contact_date'
                                name = 'contact_date'
                                onChange={this.handleChange}
                                className={classes.textField}
                                type='date'
                                //defaultValue={this.getCurrentDate()}
                                value={this.state.contact_date}
                                fullWidth
                            /> */}
                        </Grid>
                        <Grid item sm={12} md={6} lg={modal ? 3 : 6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <TimePicker
                                        id='contact_time'
                                        name="contact_time"
                                        variant="inline"
                                        label="Time"
                                        disabled={this.state.isEditing}
                                        className={classes.textField}
                                        value={this.state.contact_time}
                                        onChange={this.handleTimeChange}
                                        fullWidth
                                    />
                            </MuiPickersUtilsProvider>
                            
                                {/* <TextField
                                    value={this.state.contact_time}
                                    onChange={this.handleChange}
                                    fullWidth
                                    className={classes.textField}
                                    type='time'
                                    id='contact_time'
                                    name='contact_time'
                                    label='Time'
                                /> */}
                            
                        </Grid>

                        <Grid item sm={12} md={6} lg={modal ? 3 : 6}>
                            {this.viewDropdown(
                                'CONTACT_TYPE',
                                'Contact Type'
                            ) }
                        </Grid>
                        
                        {/* <Grid item sm={12} md={6} lg={modal ? 3 : 6}>
                            {this.viewDropdown(
                                'CAMPAIGN',
                                'Campaign'
                            ) }
                        </Grid> */}

                        {(this.state.contact_type !== 'Tradeshow Visit' && this.state.contact_type !== 'Appointment Scheduled at Show') ? (                    
                            <Grid item sm={12} md={6} lg={modal ? 3 : 6}>
                                {this.state.other ? (
                                    <TextField
                                        label="Lead Source"
                                        name="lead_source"
                                        defaultValue={this.state.lead_source}
                                        onChange={this.handleChange}
                                        fullWidth
                                        className={classes.textField}
                                        InputProps={{
                                            startAdornment: (
                                                <IconButton
                                                    onClick={this.onBackClick}
                                                    className={classes.backButton}
                                                >
                                                    <InputAdornment position="start">
                                                        <FontAwesomeIcon
                                                            icon={faArrowCircleLeft}
                                                            size="1x"
                                                        />
                                                    </InputAdornment>
                                                </IconButton>
                                            ),
                                        }}
                                    />
                                ) : (
                                    this.viewDropdown('LEAD_SOURCE', 'Lead Source')
                                ) }
                            </Grid>
                        ) : (
                            <Grid item sm={12} md={6} lg={modal ? 3 : 6}>
                                <TextField
                                    select
                                    fullWidth
                                    id={'tradeshow'}
                                    name={'tradeshow'}
                                    label={'Tradeshow'}
                                    className={classes.textField}
                                    onChange={this.handleChange}
                                    value={this.state.tradeshow}
                                >
                                    {this.state.tradeshows.map(item => (
                                        <MenuItem key={item.recordid} value={item.event_name}>
                                            {item.calendar_year + ' - ' + item.event_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        )}

                        <Grid item sm={12} md={6} lg={modal ? 3 : 6}>
                            {this.viewDropdown(
                                'CAMPAIGN',
                                'Campaign'
                            ) }
                        </Grid>

                        <Grid item sm={12} md={6} lg={modal ? 3 : 6}>
                            <TextField
                                label="Contact Name"
                                name="utility_contact_name"
                                defaultValue={this.state.utility_contact_name}
                                onChange={this.handleChange}
                                fullWidth
                                className={classes.textField}
                                value={this.state.utility_contact_name}
                                select
                            >
                                <MenuItem 
                                    key = ''
                                    id=''
                                    name=''
                                    value=''
                                >
                                    </MenuItem>                                
                                    {this.state.contactList &&
                                    this.state.contactList.map(name => (
                                        <MenuItem
                                            key={name}
                                            id={name}
                                            name={name}
                                            value={name}
                                        >
                                            {name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item sm={12} md={6} lg={modal ? 3 : 6}>
                            <TextField
                                label="Contact By"
                                name="contact_by"
                                defaultValue={this.state.contact_by}
                                onChange={this.handleChange}
                                fullWidth
                                className={classes.textField}
                                value={this.state.contact_by}
                                select
                            >
                                {this.state.contactByDropDown &&
                                    this.state.contactByDropDown.map(name => (
                                        <MenuItem
                                            key={name.name}
                                            id={name.name}
                                            name={name.name}
                                            value={name.name}
                                        >
                                            {name.name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                        </Grid>
                        <Grid item sm={12} md={6} lg={modal ? 3 : 6}>
                            <TextField
                                select
                                label="Privacy Status"
                                name="privacy_status"
                                value={this.state.privacy_status}
                                onChange={this.handleChange}
                                fullWidth
                                className={classes.textField}
                            >
                                <MenuItem key={1} value={1}>
                                    Public
                                </MenuItem>
                                <MenuItem key={2} value={2}>
                                    Private
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <TextField
                            label="Note"
                            multiline={true}
                            rows={6}
                            rowsMax={7}
                            fullWidth
                            defaultValue={this.state.notes}
                            onChange={this.handleChange}
                            fullWidth
                            className={classes.textField}
                        /> */}
                            <CKEditor
                                data={this.state.notes}
                                config={{
                                    //oolbar: [[]],
                                    removePlugins: ['elementspath'],
                                    height: 200,
                                    readOnly: true,
                                }}
                                onChange={this.handleNoteChange}
                            />
                        </Grid>
                    </Grid>
                </FormWrapper>
                {/* </Card> */}
            </Grid>
        )
    }
}

NoteForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(NoteForm)
