import React from 'react'
import PropTypes from 'prop-types'

import DateFnsUtils from '@date-io/date-fns'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'

import { withStyles } from '@material-ui/core/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText';

import InputAdornment from '@material-ui/core/InputAdornment'
import Switch from '@material-ui/core/Switch'
import ToolTip from '@material-ui/core/ToolTip'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Chip from '@material-ui/core/Chip'

import { addValidators, validate } from '../../../utils/Validate'

import NumberFormat from 'react-number-format'
import Select from '@material-ui/core/Select'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faArrowCircleLeft, faAward, faBan } from '@fortawesome/pro-light-svg-icons'

import { PostData } from '../../../services/PostData'
import {
    faChevronDown,
    faChevronUp,
    faCheck,
} from '@fortawesome/pro-light-svg-icons'
import {
    faSlidersHSquare,
    faLock,
    faLockOpen,
} from '@fortawesome/pro-regular-svg-icons'
import FormWrapper from '../../Reusable/FormWrapper'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

// MARK: styles
const styles = theme => ({
    quote: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        backgroundColor: '#ecf9f1',
        textTransform: 'capitalize',
    },
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    more: {
        flexDirection: 'row',
        marginRight: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
    collapseStyle: {
        width: '100%',
        flexDirection: 'row',
    },
    textFieldGrid: {
        flexDirection: 'row',
    },
    backButton: {
        margin: 2,
        padding: 0,
    },
    cardButtons: {
        color: '#0166ff',
    },
    quoteButtonGrid: {
        textTransform: 'capitalize',
        width: '100%',
    },
    quoteButton: {},
    icon: {
        marginRight: 5,
        marginBottom: 2,
    },
    errorButton: {
        backgroundColor: '#f44336',
        color: 'white',
        textTransform: 'capitalize',
        width: '100%',
    },
})

class OpportunitiesForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            //If the said label is supposed to be a dropdown menu, all the dropdown labels will be added to the list in component will mount
            //so the dropdown labels will start at index 1
            // MARK: state
            labels: {
                ////////GENERAL\\\\\\\\
                //recordid: ['Record ID'],
                //crm_id: ['CRM ID'],
                strategic_business_unit: ['Strategic Business Unit'],
                rep_assigned: ['Harris Rep'],
                opportunity_name: ['Opportunity Name'],
                lead_source: ['Lead Source'],
                booking_type: ['Booking Type'],
                product_interest: ['Product'],
                service_type: ['Service Type'],
                lead_stage: ['Lead Stage'],
                rfp_partners: ['RFP Partners'],
                lead_rating: ['Lead Rating'],

                /////////FINANCIAL\\\\\\\\\\
                date_started_tracking: ['Date Started Tracking'],
                closing_date: ['Closing Date'],
                next_closing_step: ['Next Steps'],
                saas: ['SaaS'],
                license_fee_dollar_value: ['License Fee'],

                subscription_fees_dollar_value: ['Subscription Fees'],
                support_fees_dollar_value: ['Support Fees'],
                saas_fees_dollar_value: ['SAAS Fees'],

                professional_services_dollar_value: [
                    'Professional Services Fee',
                ],
                close_probability: ['Close Probability Percentage'],
                transaction_fees_month: ['Transaction Fees/Month'],
                transaction_fees_year: ['Transaction Fees/Year'],
                total_opportunity_value: ['Total Opportunity Value'],
                probable_opportunity_value: ['Probable Opportunity Value'],

                ////////PROFILE\\\\\\\\\
                prospect_objective: ['Objectives'],

                obstacles_to_closing: ['Obstacles To Closing'],
                decision_process: ['Decision Process'],
                contract_process: ['Contract Process'],
                competitive_comment: ['Competitive Comment'],
                lost_to: ['Reason Lost'],
            },
            //product_interest: [],
            //service_type: [],
            //rfp_partners: [],
            showFinancial: false,
            showGeneral: true,
            showProfile: false,
            crmData: {},
            popupOpen: false,
            other: false,
            quote: false,
            quoteData: {},
            lost: false,
            lostSet: false,
            unlocked: false,
            calcChanged: false,
            close: false,
            closing: false,
            change: {
                prospect_objective: false,
                obstacles_to_closing: false,
                decision_process: false,
                contract_process: false,
                competitive_comment: false,
                lost_to: false,
            },
            changeQuoteOpen: false,
            newQuoteNumber: null,
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleSave = this.handleSave.bind(this)
        this.handleNumFieldChange = this.handleNumFieldChange.bind(this)
        this.handleSourceChange = this.handleSourceChange.bind(this)
        this.handleStageChange = this.handleStageChange.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this)
    }

    toggleDialog = () => {
        if (validate(this) && parseFloat(this.state.total_opportunity_value) > 0) {
            this.setState({ popupOpen: !this.state.popupOpen })
        }
    }

    // MARK: HandleChange
    handleNumFieldChange = event => {
        let value = event.target.value
        const name = event.target.name
        value = value.replace(/,/g, '')
        value = value.replace(/[,$]/g, '')
        value = value.replace(/[,%]/g, '')
        let newState = this.state
        newState[name] = value
        newState.calcChanged = true

        this.calcFinancials(newState)
    }
    handleSourceChange = event => {
        let value = event.target.value
        const name = event.target.name

        if (value === 'Other') {
            this.setState({ other: true, lead_source: '' })
        } else {
            this.setState({ lead_source: value })
        }
    }

    handleStageChange = event => {
        let newState = this.state

        const probable_opportunity_value = this.handleProbValCalc(
            event.target.value
        )
        const close_probability = this.handleStage(event.target.value)

        if (this.state.lost && event.target.value !== 'Lost - 0%') {
            newState.probable_opportunity_value = Number(
                probable_opportunity_value
            )

            newState.lost = false
            newState.lost_to = ''
            newState.lostSet = false
            newState.unlocked = false
            newState.labels.lead_stage.pop()
            newState[event.target.name] = event.target.value
            newState.close_probability = close_probability
        } else {
            newState.probable_opportunity_value = Number(
                probable_opportunity_value
            )
            newState.close_probability = close_probability
            newState[event.target.name] = event.target.value
        }
        newState.calcChanged = true
        //console.log(newState)

        this.calcFinancials(newState)
    }
    handleNameChange = event => {
        let value = event.target.value
        if (value.length > 90) {
            this.setState({ opportunity_name: value.substr(0, 90) })
        } else {
            this.setState({ opportunity_name: value })
        }
    }

    handleChange = event => {
        let errorMessages = this.state.errorMessages
        errorMessages[event.target.name] = null
        
        if (event.target.name === 'product_interest') {
            if (typeof event.target.value === 'string') {
                this.setState({ [event.target.name]: [event.target.value], errorMessages: errorMessages  })
                return
            }
        }

        this.setState({ [event.target.name]: event.target.value, errorMessages: errorMessages }, () => {console.log(this.state)})
    }

    handleClosingDateChange = date => {
        let closing_date = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
        let errorMessages = this.state.errorMessages
        errorMessages.closing_date = null
        this.setState({ closing_date: closing_date, errorMessages: errorMessages })
    }

    handleProfileChange = event => {
        const name = event.target.name
        const value = event.target.value
        if (value.length <= 250) {
            this.setState(prevState => {
                if (
                    prevState.recordid &&
                    prevState[name] &&
                    !prevState.change[name]
                ) {
                    prevState.change[name] = true
                } else if (prevState[name] === value) {
                    prevState.change[name] = false
                }
                prevState[name] = value
                return prevState
            })
        }
    }

    handleSaasChange = event => {
        let newState = this.state
        newState.saas = event.target.checked
        newState.license_fee_dollar_value = ''
        newState.subscription_fees_dollar_value = ''
        newState.support_fees_dollar_value = ''
        newState.saas_fees_dollar_value = ''
        newState.calcChanged = true

        this.calcFinancials(newState)
    }
    toggleLock = () => {
        if (!this.state.unlocked) {
            let newState = this.state
            const data = {
                action: 'getDropdownItems',
                control_code: 'LEAD_STAGE',
                subsystem_code: 'OPPORTUNITY',
            }
            PostData(
                'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
                data
            ).then(response => {
                if (response.result) {
                    for (let i = 0; i < response.dropdowns.length; i++) {
                        newState.labels.lead_stage.push(
                            response.dropdowns[i].item_name
                        )
                    }
                    newState.lost
                        ? newState.labels.lead_stage.push('Lost - 0%')
                        : newState.labels.lead_stage.push(
                              'Ready To Quote - 25%'
                          )
                    newState.unlocked = true
                    this.setState(newState)
                }
            })
        } else {
            let labels = this.state.labels
            labels.lead_stage = ['Lead Stage']
            this.setState({ unlocked: !this.state.unlocked, labels: labels })
        }
    }
    handleStage(value) {
        const stages = [
            { name: 'Awaiting on RFP - 25%', pct: '25' },
            { name: 'Presenting to Management - 25%', pct: '25' },
            { name: 'Opportunity - 25%', pct: '25' },
            { name: 'Prospect - 10%', pct: '10' },
            { name: 'Qualified Lead - 5%', pct: '5' },
            { name: 'Pursue Lead - 1%', pct: '1' },
            { name: 'Suspect - 0%', pct: '0' },
            { name: 'Lost - 0%', pct: '0' },
        ]
        for (let i = 0; i < stages.length; i++) {
            if (value === stages[i].name) {
                return stages[i].pct
            }
        }
        return 0
    }
    handleProbValCalc = value => {
        const stages = [
            { name: 'Ready To Quote - 25%', pct: '25' },
            { name: 'Awaiting on RFP - 25%', pct: '25' },
            { name: 'Presenting to Management - 25%', pct: '25' },
            { name: 'Opportunity - 25%', pct: '25' },
            { name: 'Prospect - 10%', pct: '10' },
            { name: 'Qualified Lead - 5%', pct: '5' },
            { name: 'Pursue Lead - 1%', pct: '1' },
            { name: 'Suspect - 0%', pct: '0' },
            { name: 'Lost - 0%', pct: '0' },
        ]
        for (let i = 0; i < stages.length; i++) {
            if (value === stages[i].name) {
                return (
                    (this.state.total_opportunity_value *
                        parseInt(stages[i].pct)) /
                    100
                )
            }
        }
    }

    //calculates and sets the state of probable and total opportunity values
    // MARK: calcFinance
    generateQuote = () => {
        let formData = {}
        formData.opportunityInfo = {
            opportunity_name: this.state.opportunity_name,
            lead_stage: this.state.lead_stage,
            lead_source: this.state.lead_source,
            service_type: this.state.service_type,
            close_prob: this.state.close_probability
                ? this.state.close_probability
                : '',
            quoteclosedate: this.state.closing_date
                ? this.state.closing_date
                : '',
            total_opportunity_value: this.state.total_opportunity_value
                ? this.state.total_opportunity_value
                : '',
            probable_opportunity_value: this.state.probable_opportunity_value
                ? this.state.probable_opportunity_value
                : '',
            booking_type: this.state.booking_type
                ? this.state.booking_type
                : '',
            recordid: this.state.recordid ? this.state.recordid : '',
        }

        let booking_type = this.state.booking_type.toUpperCase()
        switch (booking_type) {
            case 'NEW NAMED (NN)':
                booking_type = 'NEW_NAMED'
                break
            case 'INTERNAL BASED (IB)':
                booking_type = 'INTERNAL_BASED'
                break
        }

        formData.header = {
            name: this.state.opportunity_name,
            quoteby: JSON.parse(sessionStorage.getItem('userData')).userid,
            booking_type: booking_type,
            lead_stage: this.nameToCode(this.state.lead_stage),
            close_prob: this.state.close_probability,
            prospect_objective: this.state.prospect_objective,
            obstacles_to_closing: this.state.obstacles_to_closing,
            decision_process: this.state.decision_process,
            contract_process: this.state.contract_process,
            competitive_comment: this.state.competitive_comment,
            lead_stage: 'PROPOSAL_LOW',
            quoteclosedate: this.state.closing_date,
            contract_payment: this.state.saas ? 'SaaS' : 'License',
            status: 'OPEN',
            rep_assigned: this.state.rep_assigned,
            service_type: this.state.service_type,
        }

        this.props.setFormData(
            {
                header: formData.header,
                module_details: [],
                milestoneData: [],
                crmData: this.state.crmData,
                opportunityInfo: formData.opportunityInfo,
                new: true,
            },
            'Quotes'
        )
    }
    calcFinancials = (new_state = this.state) => {
        if (new_state.calcChanged) {
            const calculationFields = [
                'license_fee_dollar_value',
                'professional_services_dollar_value',
                'support_fees_dollar_value',
                'saas_fees_dollar_value',
                'subscription_fees_dollar_value',
                'transaction_fees_month'
            ]
            let calcVal = 0
            for (let x = 0; x < calculationFields.length; x++) {
                if (new_state[calculationFields[x]] && parseFloat(new_state[calculationFields[x]]) > 0) {
                    if (calculationFields[x] === 'transaction_fees_month') {
                        calcVal += (parseFloat(new_state[calculationFields[x]]) * 12)
                    } else {
                        calcVal += parseFloat(new_state[calculationFields[x]])
                    }
                }
            }

            new_state.total_opportunity_value = String(calcVal)
            new_state.transaction_fees_year = String(
                parseFloat(this.state.transaction_fees_month) * 12
            )
            new_state.probable_opportunity_value = String(
                (parseFloat(new_state.close_probability) *
                    parseFloat(new_state.total_opportunity_value)) /
                    100
            )
            if (new_state.probable_opportunity_value === 'NaN') {
                new_state.probable_opportunity_value = '0.00'
            }
            new_state.calcChanged = false
            if (new_state.total_opportunity_value) {
                new_state.moneyError = false
            }
            this.setState(new_state)
        }
    }
    //changes the lead source field from text to dropdown
    onBackClick = () => {
        this.setState({ other: !this.state.other, lead_source: '' })
    }
    handleClose = () => {
        this.props.handleFormClose()
    }

    nameToCode = name => {
        if (typeof name !== 'string') {
            return name
        }
        //console.log(name)
        name = name.toUpperCase()
        let code = ''

        for (let i = 0; i < name.length; i++) {
            if (name.charAt(i) === ' ') {
                code += '_'
            } else {
                code += name.charAt(i)
            }
        }
        return code
    }

    getCurrentDate(seperator = '-') {
        let newDate = new Date()
        let date = newDate.getDate()

        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()
        //const months = [
        //    'January',
        //    'February',
        //    'March',
        //    'April',
        //    'May',
        //    'June',
        //    'July',
        //    'August',
        //    'September',
        //    'October',
        //    'November',
        //    'December',
        //]

        return month + seperator + date + seperator + year
    }
    //MARK: mount
    componentWillMount() {
        this.getDropdowns()
        let closing_date = null
        
        // A bug in date fns is subtracting a day from the date for some reason, so this is a workaround
        if (this.props.formData.closing_date !== undefined && this.props.formData.closing_date !== null && this.props.formData.closing_date !== '') {
            closing_date = new Date(this.props.formData.closing_date)
            closing_date = new Date(closing_date.setDate(closing_date.getDate() + 1))
        }
        
        this.setupForm(this.props, closing_date)
    }

    componentWillReceiveProps(nextProps) {
        this.setupForm(nextProps)

        if (
            this.state.labels.lead_stage.length === 1 &&
            !nextProps.formData.lost_to &&
            !nextProps.formData.quote_number
        ) {
            const data = {
                action: 'getDropdownItems',
                control_code: 'LEAD_STAGE',
                subsystem_code: 'OPPORTUNITY',
            }
            PostData(
                'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
                data
            ).then(response => {
                if (response.result) {
                    let labels = this.state.labels
                    labels['lead_stage'] = ['Lead Stage']
                    for (let i = 0; i < response.dropdowns.length; i++) {
                        labels.lead_stage.push(response.dropdowns[i].item_name)
                    }
                    this.setState({ labels: labels })
                    //if(this.state.labels.lead_stage.length === 1){
                    //    this.setState(new_state)//fixes bug that occurs when you switch from a lost or quoted opp to a open one without closing the drawer, caused dropdowns not to load in time
                    //}
                }
            })
        }
    }

    setupForm = (props, closing_date) => {
        this.getAvailableQuotes()

        const user = JSON.parse(sessionStorage.getItem('userData'))

        if (typeof props.formData !== 'string' && props.formData !== undefined) {
            this.getQuoteDetails(props, props.formData.quote_number)
            let new_state = props.formData
            new_state.props = props
            new_state.labels = this.state.labels
            new_state.errMsg = ''
            new_state.saas = props.formData.saas === 't'
            //if(new_state.labels.lead_stage.length === 1 && (props.formData.lead_stage !== 'Lost - 0%') && !new_state.quote_number){
            //
            //    const data = {
            //        action: 'getDropdownItems',
            //        control_code: 'LEAD_STAGE',
            //        subsystem_code: 'OPPORTUNITY',
            //    }
            //    PostData(
            //        'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            //        data
            //    ).then(response => {
            //        if (response.result) {
            //            for (let i = 0; i < response.dropdowns.length; i++) {
            //                new_state.labels.lead_stage.push(
            //                    response.dropdowns[i].item_name
            //                )
            //            }
            //            //if(this.state.labels.lead_stage.length === 1){
            //            //    this.setState(new_state)//fixes bug that occurs when you switch from a lost or quoted opp to a open one without closing the drawer, caused dropdowns not to load in time
            //            //}
            //        }
            //    })
            //}
            new_state.service_type = props.formData.service_type

            if(props.formData.date_started_tracking !== null && props.formData.date_started_tracking !== undefined){ 
                new_state.date_started_tracking = props.formData.date_started_tracking.substr(
                    0,
                    10
                )
            } else {
                new_state.date_started_tracking = ''
            }

            new_state.lostSet = props.formData.lost_to ? true : false
            new_state.lost = props.formData.lost_to ? true : false
            //console.log(new_state.lostSet)
            new_state.transaction_fees_year =
                new_state.transaction_fees_month * 12
            if (new_state.quote_number) {
                //console.log('QUOTED')
                new_state.lead_stage = 'Ready To Quote - 25%'

                new_state.labels.lead_stage = ['Lead Stage']

                new_state.close_probability = 25
                new_state.probable_opportunity_value =
                    (parseInt(new_state.close_probability) *
                        parseInt(new_state.total_opportunity_value)) /
                    100
            } else if (new_state.lead_stage === 'Lost - 0%') {
                new_state.lead_stage = 'Lost - 0%'
                new_state.labels.lead_stage = ['Lead Stage']
                new_state.close_probability = '0'
                new_state.probable_opportunity_value = '0'
            } else {
                new_state.close_probability = props.formData.close_probability
                    ? props.formData.close_probability
                    : this.handleStage(props.formData.lead_stage)
            }
            //console.log(new_state)

            if (closing_date !== undefined && closing_date !== null && !new_state.closing_date_fixed) {
                new_state.closing_date = closing_date
                new_state.closing_date_fixed = true
            }

            this.setState(new_state)
            this.forceUpdate()
            //new_state.close_probability = this.handleStage(this.props.formData.lead_stage) ? this.handleStage(this.props.formData.lead_stage) : ''
            //new_state.probable_opportunity_value = String(total)
        } else {
            let new_state = {}
            new_state['booking_type'] = ''
            new_state['strategic_business_unit'] = user.strategic_business_unit
            new_state['rep_assigned'] = user.userid
            new_state['date_started_tracking'] = this.getCurrentDate('/')
            new_state['opportunity_name'] = ''
            new_state['lead_rating'] = ''
            new_state['lead_stage'] = ''
            new_state['lead_source'] = ''
            new_state['product_interest'] = []
            new_state['service_type'] = []
            new_state['license_fee_dollar_value'] = ''
            new_state['support_fees_dollar_value'] = ''
            new_state['active_state'] = ''
            new_state['revenue_sharing'] = ''
            new_state['next_closing_step'] = ''
            new_state['closing_date'] = ''
            new_state['rfp_partners'] = []
            new_state['prospect_objective'] = ''
            new_state['obstacles_to_closing'] = ''
            new_state['decision_process'] = ''
            new_state['contract_process'] = ''
            new_state['competitive_comment'] = ''
            new_state['saas_fees_dollar_value'] = ''
            new_state['subscription_fees_dollar_value'] = ''
            new_state['professional_services_dollar_value'] = ''
            new_state['close_probability'] = ''
            new_state['total_opportunity_value'] = ''
            new_state['probable_opportunity_value'] = ''
            new_state['campaign_id'] = ''
            new_state['transaction_fees_month'] = ''
            new_state['transaction_fees_year'] = ''
            new_state['lost_to'] = ''
            new_state['quote_number'] = ''

            this.setState(new_state)
        }
        addValidators(this, {
            opportunity_name: 'required',
            booking_type: 'required',
            lead_stage: 'required',
            lead_source: 'required',
            lead_rating: 'required',
            service_type: 'required',
            product_interest: 'required',
            closing_date: 'required',
            //close_probability: 'required',
        })

        this.setState({ crmData: props.crmData })
    }

    getDropdowns = () => {
        //MARK: dropdowns
        /*-------DROPDOWNS-------*/

        let fields = this.state.labels

        let numOfDDowns = 0
        for (let index = 0; index < fields.length; index++) {
            if (fields[index].length > 1) {
                numOfDDowns++
            }
        }
        if (numOfDDowns < 2) {
            const data = {
                action: 'getNewDropdown',
                subsystem_code: 'OPPORTUNITY',
            }

            //let done = false
            PostData(
                'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
                data
            ).then(response => {
                if (response.result) {
                    let inDropdown = false
                    for (let i = 0; i < response.dropdowns.length; i++) {
                        //checks if the value in lead source in in the dropdown and will later set State accordingly
                        if (
                            response.dropdowns[i].item_name ===
                                this.state.lead_source ||
                            !this.state.lead_source
                        ) {
                            inDropdown = true
                        }
                        for (let field in fields) {
                            /*if (fields[field][0] === 'Service Type' && !done) {
                            //gets active service types for company
                            //done insures that this section of code is executed only once
                            done = true
                            const data2 = {
                                action: 'getUtilityServices',
                                crm_id: this.props.crmData.crm_id,
                            }
                            PostData(
                                'CRM/ajax/ajaxCRM_UtilityServices.php',
                                data2
                            ).then(response => {
                                if (response.result) {
                                    for (
                                        let i = 0;
                                        i <
                                        response.utility_services_states.length;
                                        i++
                                    ) {
                                        //console.log(response.utility_services_states[i].active)
                                        if (
                                            response.utility_services_states[i]
                                                .active === '1' && response.utility_services_states[i].meter_count > 0
                                        ) {
                                            //Because service types are saved as all caps the statement below
                                            //changes them to lowcase with first letter capitalized :)
                                            //and when saved they will be saved as seen on screen
                                            fields[field].push(
                                                response.utility_services_states[
                                                    i
                                                ].service_type
                                                    .charAt(0)
                                                    .toUpperCase() +
                                                    response.utility_services_states[
                                                        i
                                                    ].service_type
                                                        .slice(1)
                                                        .toLowerCase()
                                            )
                                        }
									}
									let tempList = []
									for(let x = 0; x<fields[field].length; x++){
										let tempVar = true
										for(let y =0; y<tempList.length; y++){
											if(tempList[y] === fields[field][x]){
												tempVar = false
												continue
											}
										}
										if(tempVar){
											tempList.push(fields[field][x])
										}
									}
									fields[field] = tempList
                                    this.setState({ labels: fields })
                                    this.calcFinancials()
                                }
                            })
                            continue
                        }*/
                            //adds all other dropdowns
                            if (
                                field === 'lead_stage' &&
                                (this.props.formData.quote_number ||
                                    this.state.lostSet)
                            ) {
                                continue
                            }
                            if (
                                fields[field][0] === response.dropdowns[i].name
                            ) {
                                fields[field].push(
                                    response.dropdowns[i].item_name
                                )
                            }
                        }
                    }

                    console.log(this.state.labels)

                    let userData = JSON.parse(
                        sessionStorage.getItem('userData')
                    )
                    const data1 = {
                        action: 'getClientInfo',
                        crm_id: this.state.crmData.crm_id,
                        strategic_business_unit:
                            userData === null
                                ? null
                                : userData.strategic_business_unit,
                    }

                    PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data1).then(
                        response2 => {
                            if (response2.result && response2.ClientInfo) {
                                let clientid = response2.ClientInfo[0]
                                    ? response2.ClientInfo[0].sbu_client_id
                                    : ''

                                const data2 = {
                                    action: 'GetProductLines',
                                    hug_schema:
                                        'hug_' +
                                        JSON.parse(
                                            sessionStorage.getItem('userData')
                                        ).strategic_business_unit.toLowerCase(),
                                }

                                PostData(
                                    'CustomerModuleSearch/ajax/ajaxCustomerModulesSearch.php',
                                    data2
                                ).then(response3 => {
                                    if (response3.productLines) {
                                        for (let x = 0; x < response3.productLines.length; x++) {
                                            fields['product_interest'].push(response3.productLines[x].code)
                                        }
                                    } else if (fields['product_interest'].length === 1) {
                                        fields['product_interest'].push('No Products Found for Active BU')
                                    }
                                    this.setState({ labels: fields })
                                    this.setState({ other: !inDropdown })
                                })
                            }
                        }
                    )
                }
            })
        }
    }

    /*componentWillReceiveProps(nextProps){
        console.log(nextProps)
        this.setState({crmData: nextProps.crmData})
        let serviceTypeLabel = this.state.labels.service_type[0]
        
        const data = {
            action: 'getUtilityServices',
            recordid: nextProps.crmData.crm_id
        }
        PostData('CRM/ajax/ajaxCRM_UtilityServices.php', data).then(
            response => {
                if (response.result && response.utility_services_states) {
                    for(let i = 0; i<response.utility_services_states; i++){
                        if(response.utility_services_states[i].active === '1'){
                            serviceTypeLabel.push(response.utility_services_states[i].service_type)
                        }
                    }
                }
            }
        )
    }*/

    //takes date in form "Month 00th/nd/st, year" for example March 30th, 2019 or April 1st, 2019
    //Changes it into number format YYYY-DD-MM
    //if you pass in a date that is already in the number format YYYY/DD/MM or YYYY-DD-MM it will return whatever you passed in
    /*dateWordToNum = date => {
        date = String(date)
        if (date.charAt(4) === '/' || date.charAt(4) === '-') {
            return date
        }
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        let newDate = ''
        for (let i = 0; i < months.length; i++) {
            if (date.slice(0, months[i].length) === months[i]) {
                let month = i + 1
                if (month < 10) {
                    month = '0' + String(month)
                }
                let day = date.slice(months[i].length + 1, months[i].length + 3)
                let year = ''
                if (
                    day.substr(day.length - 1) === 's' ||
                    day.substr(day.length - 1) === 'n' ||
                    day.substr(day.length - 1) === 't'
                ) {
                    day = '0' + day.charAt(0)
                    year = date.slice(
                        months[i].length + 6,
                        months[i].length + 10
                    )
                } else {
                    year = date.slice(
                        months[i].length + 7,
                        months[i].length + 11
                    )
                }
                newDate = newDate + year + '-' + month + '-' + day
            }
        }
        return newDate
    }*/
    //takes date in form YYYY MM DD can handle any seperator for example YYYY/MM/DD or YYYY-MM-DD
    //If you pass in a date that is already in word form, it will return what you passed in
    /*dateNumToWord(date) {
        const months = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ]
        for (let i = 0; i < months.length; i++) {
            if (date.slice(0, months[i].length) === months[i]) {
                return date
            }
        }
        let year = date.slice(0, 4)
        let month = date.slice(6, 8)
        let day = date.substr(date.length - 2)
        month = months[parseInt(month) - 1]
        day = parseInt(day)
        return `${month} ${day}${
            day < 20 && day > 10
                ? 'th'
                : String(date)[String(date).length - 1] === '2'
                ? 'nd'
                : String(date)[String(date).length - 1] === '1'
                ? 'st'
                : String(date)[String(date).length - 1] === '3'
                ? 'rd'
                : 'th'
        }, ${year}`
    }
    //MARK: handlesave
    handleDialog = () => {
        if (
            this.state.lead_source &&
            this.state.opportunity_name &&
            this.state.lead_stage === 'Contract Negotiations' || 
            this.state.lead_stage === 'Won' &&
            this.state.service_type &&
            !this.state.quote_number
        ) {
            this.setState({ popupOpen: true })
        } else {
            this.handleSave()
        }
    }*/
    handleDialog = () => {
        if (validate(this) && parseFloat(this.state.total_opportunity_value) > 0) {
            this.setState(prevState => {
                return { popupOpen: !prevState.popupOpen, invalid: false }
            })
        } else {
            this.setState({ invalid: true })
        }
    }
    initiateSave = () => {
        for (let field in this.state.change) {
            if (this.state.change[field]) {
                if (validate(this) && parseFloat(this.state.total_opportunity_value) > 0 && this.state.total_opportunity_value != 0) {
                    if (this.state.lost && !this.state.lostSet) {
                        this.setState({ lostError: true })

                        return false
                    }

                    this.setState({ closing: true })
                    return
                } else {
                    return false
                }
            } else {
            }
        }
        return this.handleSave()
    }
    handleSave = () => {
        if (validate(this) && parseFloat(this.state.total_opportunity_value) > 0 && this.state.total_opportunity_value != 0) {
            if (this.state.lost && !this.state.lostSet) {
                this.setState({ lostError: true })

                return false
            }
            if (!this.state.recordid) {
                //console.log(this.state)
                let data = {
                    action: 'insert_record',
                    recordid: this.state.recordid,
                    crm_id: this.state.crmData.crm_id,
                    strategic_business_unit: this.state.strategic_business_unit,
                    opportunity_name: this.state.opportunity_name,
                    rep_assigned: this.state.rep_assigned,
                    lead_rating: this.state.lead_rating,
                    lead_stage: this.state.lead_stage,
                    professional_services_dollar_value: this.state
                        .professional_services_dollar_value,
                    lead_source: this.state.lead_source,
                    product_interest: this.state.product_interest,
                    service_type: this.state.service_type,
                    license_fee_dollar_value: this.state
                        .license_fee_dollar_value,
                    support_fees_dollar_value: this.state
                        .support_fees_dollar_value,
                    date_started_tracking: this.state.date_started_tracking,
                    active_state: this.state.active_state,
                    revenue_sharing: this.state.revenue_sharing,
                    next_closing_step: this.state.next_closing_step,
                    booking_type: this.state.booking_type,
                    closing_date: this.state.closing_date,
                    rfp_partners: this.state.rfp_partners,
                    prospect_objective: this.state.prospect_objective,
                    obstacles_to_closing: this.state.obstacles_to_closing,
                    decision_process: this.state.decision_process,
                    contract_process: this.state.contract_process,
                    competitive_comment: this.state.competitive_comment,
                    saas_fees_dollar_value: this.state.saas_fees_dollar_value,
                    subscription_fees_dollar_value: this.state
                        .subscription_fees_dollar_value,
                    close_probability: this.state.close_probability,
                    total_opportunity_value: this.state.total_opportunity_value,
                    probable_opportunity_value: this.state
                        .probable_opportunity_value,
                    campaign_id: this.state.campaign_id,
                    transaction_fees_month: this.state.transaction_fees_month,
                    lost_to: this.state.lost_to,
                    saas: this.state.saas ? this.state.saas : false,
                }

                PostData('CRM/ajax/ajaxCRMLeadsTracker.php', data).then(
                    response => {
                        //console.log(response.recordid)
                        if (response.result) {
                            this.setState({ recordid: response.recordid })
                            const serviceData = {
                                action: 'update_child_table',
                                child: 'service_type',
                                recordid: response.recordid,
                                values: this.state.service_type,
                            }

                            //Below saves products, services and rfps, then if they pushed save and close
                            PostData(
                                'CRM/ajax/ajaxCRMLeadsTracker.php',
                                serviceData
                            ).then(responseServices => {
                                //console.log(responseServices)
                                const productData = {
                                    action: 'update_child_table',
                                    child: 'product_interest',
                                    recordid: response.recordid,
                                    values: this.state.product_interest,
                                }
                                PostData(
                                    'CRM/ajax/ajaxCRMLeadsTracker.php',
                                    productData
                                ).then(responseProducts => {
                                    // console.log(responseProducts)
                                    const rfpData = {
                                        action: 'update_child_table',
                                        child: 'rfp_partners',
                                        recordid: response.recordid,
                                        values: this.state.rfp_partners,
                                    }
                                    PostData(
                                        'CRM/ajax/ajaxCRMLeadsTracker.php',
                                        rfpData
                                    ).then(responseRFPS => {
                                        //console.log(responseRFPS)
                                        let formData = this.state

                                        //console.log(formData)

                                        this.props.updateComponent(
                                            'Opportunities',
                                            formData
                                        )
                                        //this.props.updateComponent('Opportunities')
                                    })
                                })
                            })
                        }
                        //let formData = {}
                        //formData['formData'] = this.state
                        //formData = {
                        //    ...this.state.props,
                        //    ...formData,
                        //}
                        //formData.formData['recordid'] = response.recordid
                        //this.setupForm(formData)
                    }
                )
            } else {
                let bigSaveData = {
                    action: 'update_record',
                    recordid: this.state.recordid,
                    transaction_fees_month:
                        this.state.transaction_fees_month || '0',
                    updated_by: JSON.parse(sessionStorage.getItem('userData'))
                        .userid,
                    updated_date: this.getCurrentDate(),
                }
                for (let field in this.state.labels) {
                    if (
                        field === 'service_type' ||
                        field === 'rfp_partners' ||
                        field === 'product_interest'
                    ) {
                        let data = {
                            action: 'update_child_table',
                            child: field,
                            recordid: this.state.recordid,
                            values: this.state[field],
                        }
                        PostData('CRM/ajax/ajaxCRMLeadsTracker.php', data).then(
                            response => {
                                if (response.result) {
                                    //console.log(response)
                                    if (field === 'service_type') {
                                        let formData = {}
                                        formData = this.state
                                        formData.updated_by = JSON.parse(
                                            sessionStorage.getItem('userData')
                                        ).userid
                                        formData.updated_date = this.getCurrentDate()
                                        formData = {
                                            ...this.state.props,
                                            ...formData,
                                        }
                                        //console.log(formData)
                                        this.props.updateComponent(
                                            'Opportunities',
                                            formData
                                        )
                                    }
                                    //this.props.updateComponent('Opportunities')
                                }
                            }
                        )
                    }
                }
                for (let field2 in this.state.labels) {
                    if (
                        field2 !== 'service_type' &&
                        field2 !== 'product_interest' &&
                        field2 !== 'rfp_partners' &&
                        field2 !== 'transaction_fees_year' &&
                        field2 !== 'strategic_business_unit' &&
                        field2 !== 'rep_assigned' &&
                        field2 !== 'transaction_fees_month'
                    ) {
                        if (field2 === 'saas') {
                            if (this.state.saas) {
                                bigSaveData[field2] = this.state[field2]
                            } else {
                                bigSaveData[field2] = false
                            }
                        }
                        bigSaveData[field2] = this.state[field2]
                    }
                }

                PostData('CRM/ajax/ajaxCRMLeadsTracker.php', bigSaveData).then(
                    response => {
                        if (response.result) {
                            //console.log(response)
                            //if(response.result !== "false"){
                            //    loopBreak = true
                            //}
                            let formData = {}
                            formData = this.state

                            formData = {
                                ...this.state.props,
                                ...formData,
                            }
                            this.props.updateComponent(
                                'Opportunities',
                                formData
                            )
                        }
                    }
                )
            }

            return true
        }
        if (
            !this.state.closing_date ||
            !this.state.total_opportunity_value ||
            this.state.total_opportunity_value == 0
        ) {
            this.setState({ showFinancial: true, showProfile: false })
            if (
                !this.state.total_opportunity_value ||
                this.state.total_opportunity_value == 0
            ) {
                this.setState({ moneyError: true })
            }
        } else {
            this.setState({ showFinancial: false, showProfile: false })
        }
        if (
            !this.state.opportunity_name ||
            !this.state.lead_stage ||
            !this.state.lead_source ||
            !this.state.booking_type ||
            !this.state.service_type ||
            !this.state.product_interest
        ) {
            this.setState({ showGeneral: true, showProfile: false })
        } else {
            this.setState({ showGeneral: false, showProfile: false })
        }

        return false
    }

    handleGeneral = () => {
        this.setState({ showGeneral: !this.state.showGeneral })
    }
    handleFinancial = () => {
        this.setState({ showFinancial: !this.state.showFinancial })
    }
    handleProfile = () => {
        this.setState({ showProfile: !this.state.showProfile })
    }

    //MARK: getHeader
    getHeader(value) {
        const { classes } = this.props
        return (
            <React.Fragment>
                <Grid item xs={2} className={classes.more}>
                    <Typography component="h1" variant="h6" color="inherit">
                        {value}
                    </Typography>
                </Grid>

                <Grid item xs={2} className={classes.more}>
                    <Button onClick={this['handle' + value]}>
                        {this.state['show' + value] ? (
                            <FontAwesomeIcon icon={faChevronUp} />
                        ) : (
                            <FontAwesomeIcon icon={faChevronDown} />
                        )}
                    </Button>
                </Grid>

                <Grid item xs={8}></Grid>
            </React.Fragment>
        )
    }

    getUpdated = () => {
        const { classes } = this.props
        return (
            <Collapse
                in={this.state.props && this.state.props.formData.updated_by}
                style={{ width: '100%' }}
            >
                <Grid container xs={12}>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled
                            value={
                                this.state.props
                                    ? this.state.props.formData.updated_by
                                    : this.props.formData.updated_by
                            }
                            label={'Updated By'}
                            fullWidth
                            className={classes.textField}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            disabled
                            value={
                                this.state.props
                                    ? this.state.props.formData.updated_date
                                    : this.props.formData.updated_date
                            }
                            label={'Updated Date'}
                            fullWidth
                            className={classes.textField}
                        />
                    </Grid>
                </Grid>
            </Collapse>
        )
    }
    //MARK: getGeneral
    getGeneral = () => {        
        let fieldList = []
        let disabled = this.state.lost || this.state.quote
        const { classes } = this.props

        if (disabled && this.state.unlocked) {
            disabled = false
        }

        for (let field in this.state.labels) {
            if (field === 'date_started_tracking') {
                break
            }
            if (
                (field === 'product_interest' ||
                    field === 'service_type' ||
                    field === 'rfp_partners') &&
                this.state.labels[field].length > 2
            ) {
                // console.log(field + ": " + this.state[field].length)

                fieldList.push(
                    <Grid item xs={12} sm={6}>
                        <FormControl
                            className={classes.textField}
                            style={{ width: '100%' }}
                        >
                            <InputLabel
                                required={field !== 'rfp_partners'}
                                error={this.state.invalid && !this.state[field]}
                                style={this.state.invalid && (this.state[field].length === 0) && field !== 'rfp_partners' ? {color: 'red'} : {}}
                            >
                                {this.state.labels[field][0]}
                            </InputLabel>
                            <Select
                                error={this.state.invalid && (this.state[field].length === 0) && field !== 'rfp_partners'}
                                // helperText={
                                //     this.state.invalid &&
                                //     this.state[field] &&
                                //     field !== 'rfp_partners'
                                //         ? this.state.errorMessages[field]
                                //         : ''
                                // }
                                name={field}
                                multiple
                                fullWidth
                                disabled={disabled}
                                required={
                                    field === 'product_interest' ||
                                    field === 'service_type'
                                }
                                value={this.state[field]}
                                onChange={this.handleChange}
                                renderValue={selected => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap',
                                        }}
                                    >
                                        {selected &&
                                            selected.map(value => (
                                                <Chip
                                                    key={field}
                                                    label={value}
                                                    style={{ margin: 2 }}
                                                />
                                            ))}
                                    </div>
                                )}
                            >
                                {this.state.labels[field].map(
                                    option =>
                                        option !==
                                            this.state.labels[field][0] && (
                                            <MenuItem
                                                key={option}
                                                value={option}
                                            >
                                                {option}
                                            </MenuItem>
                                        )
                                )}
                            </Select>
                            <FormHelperText style={{color: 'red'}}>{this.state.invalid ? this.state.errorMessages[field] : ''}</FormHelperText>
                        </FormControl>
                    </Grid>
                )
            } else if (
                this.state.labels[field].length > 1 &&
                ((field === 'lead_source' ? !this.state.other : true) && //You are probably gonna break something if you change this or the next line
                (field === 'lead_stage' ? this.state[field] !== 'Ready To Quote - 25%' : true)) ||
                (field === 'lead_rating')
            ) {
                fieldList.push(
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={
                                (field === 'lead_stage' && this.state.quote) ||
                                disabled
                            }
                            id={field}
                            name={field}
                            label={this.state.labels[field][0]}
                            fullWidth
                            required
                            className={classes.textField}
                            value={
                                field === 'lead_stage' && this.state.quote
                                    ? 'Ready To Quote - 25%'
                                    : this.state[field]
                            }
                            select
                            onChange={
                                field === 'lead_source'
                                    ? this.handleSourceChange
                                    : field === 'lead_stage'
                                    ? this.handleStageChange
                                    : this.handleChange
                            }
                            error={
                                (field === 'opportunity_name' ||
                                    field === 'lead_source' ||
                                    field === 'booking_type' ||
                                    field === 'lead_stage' ||
                                    field === 'service_type' ||
                                    field === 'product_interest' ||
                                    field === 'lead_rating') &&
                                this.state.invalid &&
                                !this.state[field]
                            }
                            helperText={
                                (field === 'opportunity_name' ||
                                    field === 'lead_source' ||
                                    field === 'booking_type' ||
                                    field === 'lead_stage' ||
                                    field === 'service_type' ||
                                    field === 'product_interest' ||
                                    field === 'lead_rating') &&
                                !this.state[field] &&
                                this.state.invalid
                                    ? this.state.errorMessages[field]
                                    : null
                            }
                        >
                            {this.state.labels[field].map(
                                (option, i) =>
                                    i !== 0 &&
                                    (field === 'lead_stage' ? (
                                        i > 7 || i === 0 ? (
                                            option === 'Ready To Quote - 25%' ||
                                            option === 'Lost - 0%'
                                        ) : (
                                            <MenuItem
                                                key={option}
                                                value={option}
                                            >
                                                {option}
                                            </MenuItem>
                                        )
                                    ) : (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))
                            )}
                        </TextField>
                    </Grid>
                )
            } else if (
                field !== 'opportunity_name' &&
                field !== 'lead_source'
            ) {
                fieldList.push(
                    <Grid item xs={12} sm={6}>
                        <TextField
                            id={field}
                            disabled={
                                field === 'strategic_business_unit' ||
                                field === 'rep_assigned' ||
                                field === 'lead_stage' ||
                                disabled
                            }
                            name={field}
                            label={this.state.labels[field][0]}
                            fullWidth
                            className={classes.textField}
                            value={
                                field === 'lead_stage' && this.state.quote
                                    ? 'Ready To Quote - 25%'
                                    : this.state[field]
                                    ? this.state[field]
                                    : ''
                            }
                            onChange={this.handleChange}
                            error={
                                (field === 'opportunity_name' ||
                                    field === 'lead_source' ||
                                    field === 'booking_type' ||
                                    field === 'lead_stage' ||
                                    field === 'service_type') &&
                                this.state.errorMessages[field] !== undefined
                            }
                            helperText={
                                field === 'opportunity_name' ||
                                field === 'lead_source' ||
                                field === 'booking_type' ||
                                field === 'lead_stage' ||
                                field === 'service_type'
                                    ? this.state.errorMessages[field]
                                    : null
                            }
                        />
                    </Grid>
                )
            } else if (this.state.other && field === 'lead_source') {
                fieldList.push(
                    <Grid item xs={12} sm={6}>
                        <TextField
                            disabled={disabled}
                            id={field}
                            name={field}
                            required
                            label={this.state.labels[field][0]}
                            fullWidth
                            className={classes.textField}
                            value={this.state[field] ? this.state[field] : ''}
                            error={this.state.invalid && !this.state[field]}
                            helperText={
                                this.state.invalid &&
                                !this.state[field] &&
                                'This field cannot be blank'
                            }
                            onChange={this.handleChange}
                            InputProps={{
                                startAdornment: (
                                    <IconButton
                                        onClick={this.onBackClick}
                                        className={classes.backButton}
                                    >
                                        <InputAdornment position="start">
                                            <FontAwesomeIcon
                                                icon={faArrowCircleLeft}
                                                size="1x"
                                            />
                                        </InputAdornment>
                                    </IconButton>
                                ),
                            }}
                        />
                    </Grid>
                )
            } else if (field !== 'lead_source') {
                fieldList.push(
                    <Grid item xs={12}>
                        <TextField
                            disabled={disabled}
                            id={field}
                            name={field}
                            label={this.state.labels[field][0]}
                            fullWidth
                            required
                            className={classes.textField}
                            value={this.state[field] ? this.state[field] : ''}
                            onChange={this.handleNameChange}
                            error={this.state.invalid && !this.state[field]}
                            helperText={
                                this.state.invalid &&
                                !this.state[field] &&
                                'This field cannot be blank'
                            }
                        />
                    </Grid>
                )
            }
        }

        return (
            <React.Fragment>
                {this.getHeader('General')}
                <Collapse in={this.state.showGeneral}>
                    {this.getUpdated()}
                    <Grid container xs={12}>
                        {fieldList}
                    </Grid>
                </Collapse>
            </React.Fragment>
        )
    }

    //MARK: getFinance
    getFinancial = () => {
        let fieldList = []
        let toggle = false

        let isDisabled = this.state.lost || this.state.quote
        if (isDisabled && this.state.unlocked) {
            isDisabled = false
        }
        const { classes } = this.props

        for (let field in this.state.labels) {
            if (field === 'date_started_tracking') {
                toggle = true
            } else if (field === 'prospect_objective') {
                toggle = false
            }

            if (toggle) {
                if (
                    field === 'support_fees_dollar_value' ||
                    field === 'subscription_fees_dollar_value' ||
                    field === 'saas_fees_dollar_value' ||
                    field === 'license_fee_dollar_value'
                    //These fields are rendered in saas and are therefore put in here as to not create a dupe
                ) {
                    continue
                }
                if (field === 'saas') {
                    /////////////////SAAS ROW////////////////////////
                    if (this.state.saas) {
                        fieldList.push(
                            <React.Fragment>
                                <Grid item xs={12} sm={3}>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Typography
                                                style={{
                                                    marginLeft: 20,
                                                    marginTop: 19,
                                                }}
                                            >
                                                SaaS
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ marginTop: 11 }}>
                                            <Switch
                                                disabled={isDisabled}
                                                checked={this.state.saas}
                                                onChange={this.handleSaasChange}
                                                id="saas"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <NumberFormat
                                        id={'subscription_fees_dollar_value'}
                                        name={'subscription_fees_dollar_value'}
                                        label={'Subscription Fees'}
                                        fullWidth
                                        className={classes.textField}
                                        customInput={TextField}
                                        value={
                                            this.state
                                                .subscription_fees_dollar_value
                                        }
                                        onChange={this.handleNumFieldChange}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        prefix={'$'}
                                        // error={
                                        //     this.state.moneyError && field === 'total_opportunity_value'
                                        //     // field !==
                                        //     //     'transaction_fees_month' &&
                                        //     // field !== 'transaction_fees_year' &&
                                        //     // field !==
                                        //     //     'probable_opportunity_value'
                                        // }
                                        fixedDecimalScale={true}
                                        allowEmptyFormatting={true}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <NumberFormat
                                        id={'saas_fees_dollar_value'}
                                        name={'saas_fees_dollar_value'}
                                        label={'SaaS Fees'}
                                        fullWidth
                                        className={classes.textField}
                                        customInput={TextField}
                                        value={
                                            this.state.saas_fees_dollar_value
                                        }
                                        onChange={this.handleNumFieldChange}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        prefix={'$'}
                                        // error={
                                        //     this.state.moneyError &&
                                        //     field !==
                                        //         'transaction_fees_month' &&
                                        //     field !== 'transaction_fees_year' &&
                                        //     field !==
                                        //         'probable_opportunity_value'
                                        // }
                                        fixedDecimalScale={true}
                                        allowEmptyFormatting={true}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                            </React.Fragment>
                        )
                    } else {
                        fieldList.push(
                            <React.Fragment>
                                <Grid item xs={12} sm={3}>
                                    <Grid container direction="row">
                                        <Grid item>
                                            <Typography
                                                style={{
                                                    marginLeft: 20,
                                                    marginTop: 19,
                                                }}
                                            >
                                                SaaS
                                            </Typography>
                                        </Grid>
                                        <Grid item style={{ marginTop: 11 }}>
                                            <Switch
                                                disabled={isDisabled}
                                                checked={this.state.saas}
                                                onChange={this.handleSaasChange}
                                                id="saas"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <NumberFormat
                                        id={'support_fees_dollar_value'}
                                        name={'support_fees_dollar_value'}
                                        label={'Support Fees'}
                                        fullWidth
                                        className={classes.textField}
                                        customInput={TextField}
                                        value={
                                            this.state.support_fees_dollar_value
                                        }
                                        onChange={this.handleNumFieldChange}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        prefix={'$'}
                                        helperText={
                                            (this.state.moneyError && field === 'total_opportunity_value') ? 'Total Opportunity Value Cannot be Zero' : ''
                                            // field !==
                                            //     'transaction_fees_month' &&
                                            // field !== 'transaction_fees_year' &&
                                            // field !==
                                            //     'probable_opportunity_value'
                                            //     ? field ===
                                            //       'total_opportunity_value'
                                            //         ? 'This field cannot be zero'
                                            //         : 'Total Opportunity Value Cannot be Zero'
                                            //     : ''
                                        }
                                        error={
                                            this.state.moneyError && field === 'total_opportunity_value'
                                            // field !==
                                            //     'transaction_fees_month' &&
                                            // field !== 'transaction_fees_year' &&
                                            // field !==
                                            //     'probable_opportunity_value'
                                        }
                                        fixedDecimalScale={true}
                                        allowEmptyFormatting={true}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                    <NumberFormat
                                        id={'license_fee_dollar_value'}
                                        name={'license_fee_dollar_value'}
                                        label={'License Fees'}
                                        fullWidth
                                        className={classes.textField}
                                        customInput={TextField}
                                        value={
                                            this.state.license_fee_dollar_value
                                        }
                                        onChange={this.handleNumFieldChange}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        prefix={'$'}
                                        helperText={
                                            (this.state.moneyError && field === 'total_opportunity_value') ? 'Total Opportunity Value Cannot be Zero' : ''
                                            // field !==
                                            //     'transaction_fees_month' &&
                                            // field !== 'transaction_fees_year' &&
                                            // field !==
                                            //     'probable_opportunity_value'
                                            //     ? field ===
                                            //       'total_opportunity_value'
                                            //         ? 'This field cannot be zero'
                                            //         : 'Total Opportunity Value Cannot be Zero'
                                            //     : ''
                                        }
                                        error={
                                            this.state.moneyError && field === 'total_opportunity_value'
                                            // field !==
                                            //     'transaction_fees_month' &&
                                            // field !== 'transaction_fees_year' &&
                                            // field !==
                                            //     'probable_opportunity_value'
                                        }
                                        fixedDecimalScale={true}
                                        allowEmptyFormatting={true}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                            </React.Fragment>
                        )
                    }
                } else if (field === 'date_started_tracking') {
                    fieldList.push(
                        <Grid item xs={12} sm={6}>
                            <TextField
                                id={field}
                                disabled
                                name={field}
                                label={this.state.labels[field][0]}
                                fullWidth
                                className={classes.textField}
                                value={this.state[field]}
                            />
                        </Grid>
                    )
                } else if (field === 'closing_date') {
                    fieldList.push(
                        <Grid item xs={12} sm={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    required
                                    id='closing_date'
                                    name="closing_date"
                                    variant="inline"
                                    format="MM/dd/yyyy"
                                    label="Closing Date"
                                    className={classes.textField}
                                    value={this.state.closing_date || null}
                                    onChange={this.handleClosingDateChange}
                                    error={this.state.invalid && this.state.errorMessages.closing_date}
                                    helperText={this.state.errorMessages.closing_date}
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider>
                            {/* <TextField
                                disabled={isDisabled}
                                value={this.state[field]}
                                InputLabelProps={{ shrink: true }}
                                id={field}
                                type="date"
                                required
                                name={field}
                                label={this.state.labels[field][0]}
                                onChange={this.handleChange}
                                fullWidth
                                className={classes.textField}
                                error={this.state.invalid && !this.state[field]}
                                helperText={
                                    this.state.invalid &&
                                    !this.state[field] &&
                                    'This field cannot be blank'
                                }
                            /> */}
                        </Grid>
                    )
                } else if (this.state.labels[field].length > 1) {
                    fieldList.push(
                        <Grid item xs={12} sm={12}>
                            <TextField
                                disabled={isDisabled}
                                id={field}
                                name={field}
                                label={this.state.labels[field][0]}
                                fullWidth
                                className={classes.textField}
                                value={this.state[field]}
                                select
                                onChange={this.handleChange}
                            >
                                {this.state.labels[field].map(
                                    option =>
                                        option !==
                                            this.state.labels[field][0] && (
                                            <MenuItem
                                                key={option}
                                                value={option}
                                            >
                                                {option}
                                            </MenuItem>
                                        )
                                )}
                            </TextField>
                        </Grid>
                    )
                } else if (field !== 'close_probability') {
                    let disabled =
                        field === 'date_started_tracking' ||
                        field === 'transaction_fees_year' ||
                        field === 'total_opportunity_value' ||
                        field === 'probable_opportunity_value' ||
                        field === 'close_probability'
                    fieldList.push(
                        <Grid item xs={12} sm={6}>
                            <NumberFormat
                                required={
                                    field !== 'transaction_fees_month' &&
                                    field !== 'transaction_fees_year' &&
                                    field !== 'probable_opportunity_value' &&
                                    field !== 'professional_services_dollar_value'
                                }
                                id={field}
                                name={field}
                                label={this.state.labels[field][0]}
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state[field]}
                                onChange={this.handleNumFieldChange}
                                thousandSeparator={true}
                                decimalScale={2}
                                prefix={'$'}
                                helperText={
                                    (this.state.moneyError && field === 'total_opportunity_value') ? 'Total Opportunity Value Cannot be Zero' : ''
                                    // field !== 'transaction_fees_month' &&
                                    // field !== 'transaction_fees_year' &&
                                    // field !== 'probable_opportunity_value'
                                        // ? field === 'total_opportunity_value'
                                        //     ? 'This field cannot be zero'
                                        //     : 'Total Opportunity Value Cannot be Zero'
                                        // : ''
                                }
                                error={
                                    this.state.moneyError && field === 'total_opportunity_value'
                                    // field !== 'transaction_fees_month' &&
                                    // field !== 'transaction_fees_year' &&
                                    // field !== 'probable_opportunity_value'
                                }
                                fixedDecimalScale={true}
                                allowEmptyFormatting={true}
                                disabled={disabled || isDisabled}
                            />

                            {/*<TextField
                           //    id={field}
                           //    disabled={disabled}
                           //    name={field}
                           //    label={this.state.labels[field][0]}
                           //    fullWidth
                           //    className={classes.textField}
                           //    value={
                           //    this.state[field]
                           //            ? this.state[field]
                           //            : this.state[field] ===
                           //            'transaction_fees_year'
                           //            ? '0'
                           //            : ''
                           //    }
                           //    onChange={this.handleChange}
                           //    onClick={
                           //        !disabled &&
                           //        (() => this.onMoneyFieldClick(field, dolla))
                           //    }
                           //    InputProps={
                           //        dolla && {
                           //            startAdornment: (
                           //                <InputAdornment position="start">
                           //                    {/*<FontAwesomeIcon
                           //                        icon={field === 'close_probability' ? faPercent :faDollarSign}
                           //                        size="1x"
                           //                    />* /}
                           //                    {field === 'close_probability'
                           //                        ? '%'
                           //                        : '$'}
                           //                </InputAdornment>
                           //            ),
                           //        }
                           //    }
                        ///>*/}
                        </Grid>
                    )
                } else {
                    let disabled =
                        field === 'date_started_tracking' ||
                        field === 'transaction_fees_year' ||
                        field === 'total_opportunity_value' ||
                        field === 'probable_opportunity_value' ||
                        field === 'close_probability'
                    fieldList.push(
                        <Grid item xs={12} sm={6}>
                            {/*<ClickAwayListener
                                onClickAway={this.calcFinancials}
                                onTouchEnd={this.calcFinancials}
                            >
                            */}
                            <NumberFormat
                                id={field}
                                name={field}
                                label={this.state.labels[field][0]}
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state[field]}
                                onChange={this.handleNumFieldChange}
                                thousandSeparator={true}
                                decimalScale={
                                    field === 'close_probability' ? 0 : 2
                                }
                                suffix={field === 'close_probability' && '%'}
                                fixedDecimalScale={true}
                                allowEmptyFormatting={true}
                                disabled={disabled || isDisabled}
                                error={this.state.invalid && !this.state[field] && field !== 'close_probability'}
                                helperText={
                                    this.state.invalid &&
                                    !this.state[field] &&
                                    this.state.errorMessages[field]
                                }
                                isAllowed={value => {
                                    if (
                                        field === 'close_probability' &&
                                        value > 100
                                    ) {
                                        return false
                                    }
                                    return true
                                }}
                            />
                            {
                                //</ClickAwayListener>
                            }
                        </Grid>
                    )
                }
            }
        }
        return (
            <React.Fragment>
                {this.getHeader('Financial')}
                <Collapse in={this.state.showFinancial}>
                    <Grid container xs={12}>
                        {fieldList}
                    </Grid>
                </Collapse>
            </React.Fragment>
        )
    }
    //MARK: getProf
    getProfile = () => {
        let disabled = this.state.lost || this.state.quote
        let fieldList = []
        const { classes } = this.props
        let toggle = false

        if (disabled && this.state.unlocked) {
            disabled = false
        }

        for (let field in this.state.labels) {
            if (field === 'prospect_objective') {
                toggle = true
            }
            if (this.state.lead_stage !== 'Lost - 0%' && field === 'lost_to') {
                toggle = false
            }
            if (toggle) {
                fieldList.push(
                    <Grid item xs={12}>
                        <TextField
                            disabled={disabled}
                            multiline
                            rowsMax={15}
                            variant="outlined"
                            id={field}
                            name={field}
                            label={this.state.labels[field][0]}
                            fullWidth
                            className={classes.textField}
                            value={this.state[field] ? this.state[field] : ''}
                            onChange={this.handleProfileChange}
                        />
                    </Grid>
                )
            }
        }

        return (
            <React.Fragment>
                {this.getHeader('Profile')}
                <Collapse in={this.state.showProfile}>
                    <Grid container xs={12}>
                        {fieldList}
                    </Grid>
                </Collapse>
            </React.Fragment>
        )
    }

    lost = () => {
        if (validate(this) && this.state.total_opportunity_value > 0) {
            let newState = this.state
            newState.labels['lead_stage'] = ['Lead Stage']
            newState['lead_stage'] = 'Lost - 0%'
            newState.lost = true
            newState.close_probability = '0'
            newState.invalid = false
            this.setState(newState)
        }
    }

    //MARK: getQuoteDetails
    getQuoteDetails = (props, quoteNumber) => {
        //console.log(props.formData.quote_number)
        if (quoteNumber) {
            const data = {
                action: 'getBasicInfoByQuoteNum',
                crm_id: props.crmData.crm_id,
                hug_schema:
                    'hug_' +
                    JSON.parse(
                        sessionStorage.getItem('userData')
                    ).strategic_business_unit.toLowerCase(),
                sbu: JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,
                quote_number: quoteNumber,
            }

            PostData('Quotes/ajax/ajaxQuotesList_2018.php', data).then(
                response => {
                    if (response.result) {
                        this.setState({
                            quoteData: response.resultSet[0],
                            quote: true,
                        })
                    } else {
                        this.setState({ quote: false, quoteData: null })
                    }
                }
            )
        } else {
            this.setState({ quote: false, quoteData: null })
        }
    }

    getAvailableQuotes = () => {
        let userData = JSON.parse(sessionStorage.getItem('userData'))

        const data = {
            hug_schema: 'hug_' + userData.strategic_business_unit.toLowerCase(),
            sbu: userData === null ? null : userData.strategic_business_unit,
            action: 'getOpenQuoteForUtility',
            q_status: 'ALL',
            report_year: 'none',
            crm_id: this.props.crmData.crm_id,
            newnamed_organic_filter: 'ALL',
        }

        PostData('Quotes/ajax/ajaxQuotesList_2018.php', data).then(
            response => {
                if (response.result && response.resultSet) {
                    this.setState({ availableQuoteNumbers: response.resultSet })
                }
            }
        )
    }

    getButtons = () => {
        const { classes } = this.props
        if (this.state.closing === 'close') {
            this.props.handleFormClose()
        }
        if (
            this.state.props
                ? this.state.props.formData.opportunity_name
                : false
        ) {
            if (this.state.quote) {
                let icon = this.state.unlocked ? faLockOpen : faLock
                return (
                    <Grid container xs={12}>
                        {JSON.parse(sessionStorage.getItem('userData'))
                            .access_level == 1 && (
                            <Card
                                style={{
                                    width: '100%',
                                    margin: 7,
                                    backgroundColor: '#ecf9f1',
                                }}
                            >
                                <Grid container xs={12}>
                                    <Grid item xs={11}>
                                        <Typography
                                            style={{
                                                marginTop: 10,
                                                marginLeft: 5,
                                            }}
                                        >
                                            {this.state.unlocked
                                                ? 'This Opportunity can be edited because it has been unlocked'
                                                : 'This Opportunity is locked as it has been marked Ready to Quote'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={this.toggleLock}
                                            className={classes.quoteButton}
                                            style={{ marginLeft: 'auto' }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Card>
                        )}
                    </Grid>
                )
            }
            if (!this.state.lostSet) {
                return (
                    <Grid
                        container
                        xs={12}
                        sm={12}
                        className={classes.quoteButtonGrid}
                    >
                        <Grid item xs={12}>
                            <Collapse in={!this.state.lost}>
                                <Grid container xs={12}>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        className={classes.quoteButton}
                                    >
                                        {/* <Button
                                            onClick={this.lost}
                                            className={classes.quoteButtonGrid}
                                        >
                                            Lost
                                        </Button> */}

                                        <Button
                                            color="#f44336"
                                            variant="contained"
                                            className={classes.errorButton}
                                            onClick={this.lost}
                                        >
                                            <FontAwesomeIcon
                                                icon={faBan}
                                                size="1x"
                                                color="white"
                                                className={classes.icon}
                                            />
                                            Lost
                                        </Button>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={6}
                                        className={classes.quoteButton}
                                    >
                                        {/* <Button
                                            onClick={this.handleDialog}
                                            className={classes.quoteButtonGrid}
                                        >
                                            Ready To Quote
                                        </Button> */}

                                        <Button
                                            color="primary"
                                            variant="contained"
                                            className={classes.quoteButtonGrid}
                                            onClick={this.handleDialog}
                                        >
                                            <FontAwesomeIcon
                                                icon={faAward}
                                                size="1x"
                                                color="white"
                                                className={classes.icon}
                                            />
                                            Ready To Quote
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>

                        <Grid item xs={12}>
                            <Collapse
                                in={!this.state.lostSet && this.state.lost}
                            >
                                <Grid container xs={12}>
                                    <Grid item xs={11}>
                                        <TextField
                                            name="lost_to"
                                            id="lost_to"
                                            label="Reason Lost"
                                            onChange={this.handleProfileChange}
                                            fullWidth
                                            variant="outlined"
                                            className={classes.textField}
                                            value={this.state.lost_to}
                                            error={this.state.lostError}
                                            helperText={
                                                this.state.lostError &&
                                                'This field cannot be blank'
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            style={{ marginTop: 5 }}
                                            onClick={() => {
                                                if (this.state.lost_to) {
                                                    this.setState({
                                                        lostSet: true,
                                                        lostError: false,
                                                    }, () => {
                                                        this.handleSave()
                                                    })
                                                } else {
                                                    this.setState({
                                                        lostError: true,
                                                    })
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faCheck} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>
                )
            } else if (this.state.quote || this.state.lost) {
                let icon = this.state.unlocked ? faLockOpen : faLock

                return (
                    <Grid container xs={12}>
                        {JSON.parse(sessionStorage.getItem('userData'))
                            .access_level == 1 && (
                            <Card
                                style={{
                                    width: '100%',
                                    margin: 7,
                                    backgroundColor: '#ecf9f1',
                                }}
                            >
                                <Grid container xs={12}>
                                    <Grid item xs={11}>
                                        <Typography
                                            style={{
                                                marginTop: 10,
                                                marginLeft: 5,
                                            }}
                                        >
                                            {this.state.unlocked
                                                ? 'This Opportunity can be edited because it has been unlocked'
                                                : 'This Opportunity cannot be edited since it is marked as Lost'}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <IconButton
                                            onClick={this.toggleLock}
                                            className={classes.quoteButton}
                                            style={{ marginLeft: 'auto' }}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Card>
                        )}
                    </Grid>
                )
            }
        }
    }
    onQuoteClick = () => {
        let quote = this.state.quoteData
        let userData = JSON.parse(sessionStorage.getItem('userData'))

        if (quote != 0 && quote) {
            let milestoneData
            const data1 = {
                action: 'getQuoteCurrentMilestone',
                current_milestone_template: quote ? quote.alternate_terms : '',
                sbu:
                    userData === null ? null : userData.strategic_business_unit,
                userid: userData === null ? null : userData.userid,
                quote_number: quote.quote_number,
                //hug_schema: 'hug_horizons',
            }

            PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data1).then(
                response => {
                    if (response.result) {
                        milestoneData = response.quote_milestonesArr
                            ? response.quote_milestonesArr
                            : {}
                        //console.log(response)
                        const data2 = {
                            action: 'getQMDforQuote2',
                            quote_number: quote.quote_number,
                            //hug_schema: 'hug_horizons',
                            sbu:
                                userData === null
                                    ? null
                                    : userData.strategic_business_unit,
                        }

                        PostData(
                            'Quotes/ajax/ajaxhugQuoteSystem2019.php',
                            data2
                        ).then(response => {
                            this.props.handleFormClose()
                            this.props.setFormData(
                                {
                                    header: response.qte_header,
                                    module_details: response.Q_Modules_Details,
                                    milestoneData: milestoneData
                                        ? milestoneData
                                        : {},
                                    crmData: this.state.crmData,
                                },
                                'Quotes'
                            )
                        })
                    }
                }
            )
        } else {
            this.setState({
                invalid: true,
                errMsg: 'Error: Quote No Longer Exists',
            })
        }
    }

    getQuote = () => {
        const { classes } = this.props
        if (this.state.quote) {
            return (
                <>
                    <ToolTip title={'Open Quote'}>
                        <Button onClick={this.onQuoteClick}>
                            <Card>
                                <Grid
                                    container
                                    className={classes.quote}
                                    spacing={1}
                                >
                                    <Grid item xs={12}>
                                        <Typography component="h6" color="inherit">
                                            {'Quote Name: ' +
                                                (this.state.quoteData &&
                                                    this.state.quoteData.name)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography component="h6" color="inherit">
                                            {'Quote Number: ' +
                                                this.state.quoteData.quote_number}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography component="h6" color="inherit">
                                            {'Quote Status: ' +
                                                (this.state.quoteData &&
                                                    this.state.quoteData.status)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Typography component="h6" color="inherit">
                                            {'Quote Amount: ' +
                                                (this.state.quoteData &&
                                                    this.state.quoteData
                                                        .quoteamount)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Button onClick={(e) => {
                                                e.stopPropagation()
                                                this.setState({ changeQuoteOpen: true })
                                            }}>
                                            Change Quote
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Button>
                    </ToolTip>
                    <Dialog
                        open={this.state.changeQuoteOpen}
                        aria-labelledby="Change Quote"
                        aria-describedby="description"
                        fullWidth={true}
                        maxWidth={'sm'}
                    >
                        <DialogTitle id="Change Quote">
                            {'Change Quote'}
                        </DialogTitle>
                        <DialogContent>
                            <Grid
                                container
                                spacing={1}
                            >
                                <Grid item xs="12">
                                    <Select
                                        label="Select Existing Quote"
                                        name={'newQuoteNumber'}
                                        fullWidth
                                        onChange={this.handleChange}
                                        value={this.state.newQuoteNumber}
                                    >
                                        {this.state.availableQuoteNumbers && this.state.availableQuoteNumbers.map(quote =>
                                            <MenuItem
                                                key={quote.quote_number}
                                                value={quote.quote_number}
                                            >
                                                {quote.quote_number+' - '+quote.name}
                                            </MenuItem>
                                        )}
                                    </Select>
                                </Grid>
                                <Grid item xs="6">
                                    <Button
                                        onClick={this.createNewQuote}
                                        className={classes.cardButtons}
                                    >
                                        Create New Quote
                                    </Button>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({ changeQuoteOpen: false })
                                }}
                                className={classes.cardButtons}
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={this.changeQuote}
                                className={classes.cardButtons}
                                autoFocus
                            >
                                Save
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            )
        }
    }
    handleQuoteStage = () => {
        this.setState(() => {
            return {
                lead_stage: 'Ready To Quote - 25%',
                close_probability: '25',
            }
        })
        this.calcFinancials()
    }
    handleSaveNote = () => {
        let note = ''
        note += '<p><strong>Record Id: </strong>' + this.state.recordid + '</p>'
        note +=
            '<p><strong>Opportunity Name: </strong>' +
            this.state.opportunity_name +
            '</p>'
        for (let field in this.state.change) {
            if (this.state.change[field]) {
                note +=
                    '<p><strong>' +
                    this.state.labels[field][0] +
                    ': </strong>' +
                    this.state.props.formData[field] +
                    '</p>'
            }
        }
        //note = '<div>{note}</div>'
        //console.log(note)
        const data = {
            action: 'insert_contact_notes_table',
            strategic_business_unit: JSON.parse(
                sessionStorage.getItem('userData')
            ).strategic_business_unit,
            user_id: JSON.parse(sessionStorage.getItem('userData')).userid,
            privacy_status: 2,
            contact_type: '',
            subject: 'Opportunity Profile',
            utility_contact_name: '',
            contact_by: JSON.parse(sessionStorage.getItem('userData')).name,
            lead_source: '',
            notes: note,
            crm_id: this.props.crmData.crm_id,
            source_id: '',
            campaign_id: '',
        }

        PostData('CRM/ajax/ajaxContact_Notes.php', data).then(response => {
            this.props.updateComponent('Notes')
        })
    }

    setClose = () => {
        this.setState({ close: true })
    }

    createQuote = () => {
        this.handleDialog()
        this.handleQuoteStage()
        this.handleSave()
        this.generateQuote()
    }

    createNewQuote = () => {
        this.setState({ changeQuoteOpen: false })
        this.handleQuoteStage()
        this.handleSave()
        this.generateQuote()
    }

    changeQuote = () => {
        this.setState({ changeQuoteOpen: false })

        const data = {
            action: 'changeQuoteNumber',
            recordid: this.state.recordid,
            quoteNumber: this.state.newQuoteNumber,
            updated_by: JSON.parse(sessionStorage.getItem('userData')).userid,
            updated_date: this.getCurrentDate(),
        }

        PostData('CRM/ajax/ajaxCRMLeadsTracker.php',data).then(response => {
            this.getQuoteDetails(this.props, this.state.newQuoteNumber)
        })
    }

    //MARK: render
    render() {
        const { classes } = this.props
        //console.log(this.state)

        return (
            // <Grid
            //     onClick={this.handleCheck}
            //     container
            //     className={classes.outside}
            //     direction="column"
            //     alignItems="stretch"
            // >
            //     <Card>
            //         <SaveWarning invalid={this.state.invalid} />
            //         <div className={classes.secondary}>
            //             <Grid container>
            //                 <Typography
            //                     component="h1"
            //                     variant="h6"
            //                     color="inherit"
            //                     className={classes.textField}
            //                 >
            //                     {this.props.formData.opportunity_name
            //                         ? 'Edit Opportunity ' +
            //                         this.props.formData.opportunity_name
            //                         : 'Add Opportunity'}
            //                 </Typography>
            //                 <Grid item className={classes.buttonGrid}>
            // { this.state['opportunity_name'] &&
            // this.state['lead_source'] &&
            // this.state['booking_type'] &&
            // this.state['lead_stage'] &&
            // this.state['service_type']  &&
            // <Button
            //     color="primary"
            //     className={classes.save}
            //     onClick={this.handleDialog}
            // >
            //     Quote
            // </IconButton>}
            //                     <Button
            //                         color="primary"
            //                         className={classes.save}
            //                         onClick={this.handleDialog}
            //                     >
            //                         Save
            //                     </Button>

            //                     <Button onClick={this.handleClose}>
            //                         <FontAwesomeIcon
            //                             icon={faTimes}
            //                             size="2x"
            //                             color="white"
            //                         />
            //                     </Button>
            //                 </Grid>
            //             </Grid>
            //         </div>
            //         <Grid container spacing={1} className={classes.body}>
            <Grid container>
                <FormWrapper
                    invalid={this.state.invalid}
                    type="Opportunity"
                    name={
                        this.state.props
                            ? this.state.props.formData.opportunity_name
                            : ''
                    }
                    setClose={this.setClose}
                    handleSave={this.initiateSave}
                    handleClose={() => {
                        this.handleClose()
                    }}
                    customMessage={this.state.errMsg ? this.state.errMsg : null}
                >
                    {this.getButtons()}
                    {this.getQuote()}
                    
                    {this.getGeneral()}
                    {this.getFinancial()}
                    {this.getProfile()}
                    <Dialog
                        open={this.state.popupOpen}
                        aria-labelledby="Generate Quote?"
                        aria-describedby="description"
                    >
                        <DialogTitle id="Generate Quote">
                            {'Generate Quote?'}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="description">
                                {'Would you like to generate a quote from ' +
                                    this.state.opportunity_name +
                                    '? This will also save the form.'}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                onClick={() => {
                                    this.setState({ popupOpen: false })
                                }}
                                className={classes.cardButtons}
                            >
                                No
                            </Button>
                            <Button
                                onClick={this.createQuote}
                                className={classes.cardButtons}
                                autoFocus
                            >
                                Yes
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {
                        <Dialog
                            open={this.state.closing === true}
                            aria-labelledby="CloseForm"
                            aria-describedby="description"
                        >
                            <DialogTitle id="CloseForm">
                                {'Save Profile?'}
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText id="description">
                                    {
                                        'You have made changes to the profile of this opportunity. Do you want to save the old values to Notes?'
                                    }
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => {
                                        this.setState({ closing: false })
                                    }}
                                    className={classes.cardButtons}
                                >
                                    No
                                </Button>
                                <Button
                                    onClick={() => {
                                        this.handleSave()
                                        this.handleSaveNote()
                                        this.state.close &&
                                            this.props.handleFormClose()
                                        this.setState({ closing: false })
                                    }}
                                    className={classes.cardButtons}
                                    autoFocus
                                >
                                    Yes
                                </Button>
                            </DialogActions>
                        </Dialog>
                    }
                </FormWrapper>
            </Grid>
            //     </Card>
            // </Grid>
        )
    }
}
// MARK: end
OpportunitiesForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(OpportunitiesForm)
