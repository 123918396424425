import React from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'

import { PostData } from '../../services/PostData'

import QuotesForm from './Quotes/QuotesForm'
import CompanyForm from './CompanyForm'
import ContactForm from './Contacts/ContactForm'
import Contacts from './Contacts/Contacts'
import Notes from './Notes/Notes'
import Services from './Services/Services'
import Systems from './Systems/Systems'
import BottomTabs from '../Layout/BottomTabs/BottomTabs'
import { faEmptySet } from '@fortawesome/pro-regular-svg-icons'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    grid: {
        //marginVertical: 2,
        paddingVertical: 2,
    },
    
})

class Company extends React.Component {
    state = {
        crmData: {},
        contactData: {},
        crmFound: false,
        contactFound: false,
        quotesFound: false,
    }

    componentWillMount() {
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        
        if (
            this.props.crmData === undefined ||
            this.props.crmData.crm_id === undefined
        ) {
            /*const data = {
                action: 'getStartupData',
                //crm_id: window.location.href.slice(-5),
                crm_id: this.props.companyId,
                strategic_business_unit:
                    userData === null ? null : userData.strategic_business_unit,
                hug_schema: 'hug_'+userData.strategic_business_unit,
            }

            // Fetch the company data
            PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {
                this.setState({ crmData: response.CRMdata })
                this.setState({ crmFound: true })
                this.props.setCrmData(response.CRMdata)
            })*/
            
            this.setState({crmData: this.props.crmData})
            this.setState({crmFound: true})
        } else {
            this.setState({ crmData: this.props.crmData })
            this.setState({ crmFound: true })
        }

        // // If there is a contact id in the url
        // if (
        //     this.props.crmData !== undefined &&
        //     this.props.crmData.crm_id !== undefined
        // ) {
        //     const contactId = this.props.crmData.crm_id

        //     const contactData = {
        //         action: 'editUtilityPersonnelInfo',
        //         recordid: contactId,
        //     }

        //     // Fetch the contact data
        //     PostData(
        //         'CRM/ajax/ajaxCRMUtilityPersonnel2018.php',
        //         contactData
        //     ).then(response => {
        //         this.setState({ contactData: response.rowUtilPersonnelRtn })
        //     })
        // }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.crmData !== this.state.crmData) {
            //this.setState({ crmFound: false })
            this.setState({ crmData: nextProps.crmData })
            this.setState({ crmFound: true })

            // // If there is a contact id in the url
            // if (
            //     nextProps.crmData !== undefined &&
            //     nextProps.crmData.crm_id !== undefined
            // ) {
            //     const contactId = nextProps.crmData.crm_id

            //     const contactData = {
            //         action: 'editUtilityPersonnelInfo',
            //         recordid: contactId,
            //     }

            //     // Fetch the contact data
            //     PostData(
            //         'CRM/ajax/ajaxCRMUtilityPersonnel2018.php',
            //         contactData
            //     ).then(response => {
            //         this.setState({ contactData: response.rowUtilPersonnelRtn })
            //     })
            // }
        }
    }

    render() {
        const { classes } = this.props

        return (
            <div>
                <Grid  container spacing={1} xs={true} sm={true} lg={true} md={true} xl={12}>
                    <Grid
                        item
                        sm={12}
                        //md={this.state.contactFound ? 6 : 12}
                        className={classes.grid}
                    >
                        {this.props.loading !== 'Company' && (
                            <CompanyForm
                                loading={this.props.loading}
                                crmData={this.state.crmData}
                                handleFormOpen={this.props.handleFormOpen}
                                setFormData={this.props.setFormData}
                                setCrmData={this.props.setCrmData}
                                formOpen={this.props.formOpen}
                            />
                        )}
                    </Grid>

                    {/* <Grid
                        item
                        sm={12}
                        md={this.state.contactFound ? 6 : 12}
                        className={classes.grid}
                    >
                        <Paper className={classes.root} elevation={1}>
                            {this.state.contactFound && (
                                <ContactForm
                                    contactData={this.props.contactData}
                                    crmData={this.state.crmData}
                                />
                            )}
                        </Paper>
                    </Grid>  */}

                    <Grid item sm={12} className={classes.grid}>
                        
                            <Contacts
                                handleFormOpen={this.props.handleFormOpen}
                                setFormData={this.props.setFormData}
                                crmData={this.state.crmData}
                                loading={this.props.loading}
                            />
                        
                    </Grid>

                    <Grid item sm={12} className={classes.grid}>
                        <Grid container spacing={1}>
                            <Grid item lg={6} sm={12} className={classes.grid}>
                                {this.props.loading !== 'Services' && (
                                    <Services
                                        handleFormOpen={
                                            this.props.handleFormOpen
                                        }
                                        setFormData={this.props.setFormData}
                                        crmData={this.state.crmData}
                                        update={this.props.update}
                                        updateComponent={
                                            this.props.updateComponent
                                        }
                                    />
                                )}
                            </Grid>
                            <Grid item lg={6} sm={12} className={classes.grid}>
                                {this.props.loading !== 'Systems' && (
                                    <Systems
                                        handleFormOpen={
                                            this.props.handleFormOpen
                                        }
                                        setFormData={this.props.setFormData}
                                        crmData={this.state.crmData}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item sm={12} className={classes.grid}>
                        <BottomTabs
                            loading={this.props.loading}
                            handleFormOpen={this.props.handleFormOpen}
                            setFormData={this.props.setFormData}
                            crmData={this.state.crmData}
                            update={this.props.update}
                        />
                    </Grid>

                    <Grid item sm={12} className={classes.grid} >
                        {this.props.loading !== 'Notes' && (
                            <Notes
                                handleFormOpen={this.props.handleFormOpen}
                                setFormData={this.props.setFormData}
                                handleDrawerClose={this.props.handleFormClose}
                                crmData={this.state.crmData}
                                updateComponent={this.props.updateComponent}
                            />
                        )}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

Company.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Company)
