import React, { Component } from 'react'
import { PostData } from '../services/PostData'

import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

class FixServices extends React.Component {
    state = {
        disabled: false,
        start: '',
        end: '',
        services: 'ELECTRIC,WATER,GAS,WASTE_WATER,SECURITY_LIGHTS,STREET_LIGHTS,INTERNET,CABLE,SATELLITE,SANITATION,TELEPHONE,ELECTRIC_NONOUTAGE,FIBER,SOLAR,STORM_DRAINAGE',
    }

    handleChange = (event) => {
        if ((event.target.name === 'start' || event.target.name === 'end') && (isNaN(event.target.value) || event.target.value < 0)) {
            return;
        }

        this.setState({ [event.target.name]: event.target.value })
    }

    fixServices = () => {
        this.setState({ disabled: true })

        const data = {
            action: "fix_services",
            start: this.state.start,
            end: this.state.end,
            services: this.state.services,
        }

        PostData('CRM/ajax/ajaxCRM_UtilityServices.php', data).then(response => {
            this.setState({ disabled: false })
        })
    }

    render() {
        return (
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="start"
                        name="start"
                        label="First CRM ID to Check"
                        fullWidth
                        value={this.state.start}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="end"
                        name="end"
                        label="Last CRM ID to Check"
                        fullWidth
                        value={this.state.end}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="services"
                        name="services"
                        label="Services (Comma separated list)"
                        fullWidth
                        value={this.state.services}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button 
                        style={{backgroundColor: (this.state.disabled ? 'grey' : '#25525f'), color: 'white'}} 
                        onClick={this.fixServices}
                        disabled={this.state.disabled}
                    >
                        {this.state.disabled ? 'Loading' : 'Fix Services'}
                    </Button>
                </Grid>
            </Grid>
        )
    }
}

export default FixServices
