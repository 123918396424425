export function getCurrentDate(separator = '-') {
    let newDate = new Date()
    newDate.setDate(newDate.getDate() + 1)
    return formatDate(newDate, separator)
}

export function getCurrentTime(separator = ':') {
    let newDate = new Date()
    return formatTime(newDate, separator)
}

export function formatDate(date, separator = '-') {
    if (date === null || date === undefined) {
        return ''
    }

    if (!isValidDate(date)) {
        date = new Date(date)
    }

    date.setDate(date.getDate() + 1);

    return date.toLocaleString('en-US', {
        timeZone: 'US/Central',
        weekday: 'short',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

    // let day = date.getDate()
    // let month = date.getMonth() + 1
    // let year = date.getFullYear()

    // return `${year}${separator}${
    //     month < 10 ? `0${month}` : `${month}`
    // }${separator}${day}`
}

export function formatTime(time, separator = ':') {
    if (time === null || time === undefined) {
        return ''
    }

    if (!isValidDate(time)) {
        //return time
        time = new Date(time)
    } 

    return time.toLocaleString('en-US', {
        timeZone: 'US/Central',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    })

    // let hours = time.getHours()
    // let minutes = time.getMinutes()

    // return `${hours}${separator}${minutes}`
}

export function formatDateTime(dateTime) {
    if (dateTime === null || dateTime === undefined) {
        return ''
    }

    if (!isValidDate(dateTime)) {
        dateTime = new Date(dateTime)
    }

    return dateTime.toLocaleString('en-US', {
        timeZone: 'US/Central',
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    })
}

export function isValidDate(date) {
    return (
        date &&
        Object.prototype.toString.call(date) === '[object Date]' &&
        !isNaN(date)
    )
}
