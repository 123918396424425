// MARK: Imports
import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import {
    ClickAwayListener,
    Grid,
    TextField,
    Typography,
    Card,
    MenuItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    ExpansionPanelActions,
    Button,
    Paper,
    Tooltip,
    InputAdornment,
    IconButton,
    Table,
    TableCell, 
    TableRow,
    TableHead,
    TableBody,
    Collapse
} from '@material-ui/core'

import { PostData } from '../../services/PostData'
import NumberFormat from 'react-number-format'

import { MemoryRouter as Router } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faChevronDown,
    faChevronUp,
    faMapMarkedAlt,
    faBurn,
    faExternalLink,
    faEdit,
    faTimes,
    faPhone,
    faLink
} from '@fortawesome/pro-light-svg-icons'
import SupportDates from './SupportDates/SupportDates'
import ClientInfo from './ClientInfo/ClientInfo'
import Alias from './Alias/Alias'
import AssociationInfo from './AssociationInfo/AssociationInfo'

import SwipeableTabs from '../Reusable/SwipeableTabs'
import '../Layout/overrides.css'


const AdapterLink = React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
))
// MARK: styles
const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    title: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        width: '97%',
        //background: 'rgb(248,248,248)',
    },
    primary: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    container: {
        paddingBottom: 10,
    },
    buttons: {
        color: 'white',
        backgroundColor: theme.palette.primary.dark,
        marginTop: 4,
        marginBottom: 4,
    },
    expansionTitle: {
        color: 'white',
        backgroundColor: theme.palette.primary.dark,
    },
    info: {
        border: '1px solid ' + theme.palette.primary.dark,
    },
    saveCompany: {
        marginTop: 7,
        fontSize: 18,
        marginRight: 10,
        textTransform: 'capitalize',
    },
    cardButtons: {
        color: '#0166ff',
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    link: {
        root: '',
    },
    /*tabs: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
        paddingVertical: 1,
        marginVertical: 0
    },*/
    top: {
        paddingBottom: theme.spacing.unit,
    },
    textFieldGrid: {
        width: '26%',
        minWidth: 100,
    },
    errorMessage: {
        color: 'red',
    },
    modifiedText: {
        paddingLeft: 10,
        paddingBottom: 10,
        fontSize:14
    },
    websitebutton: {
        //paddingTop: 20,
        marginTop: 25
    }
})

class CompanyForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            crmData: {},
            marketData: {},
            map: {},
            dropdown: [],
            stats: false,
            map: false,
            edit: false,
            alias: false,
            dates: false,
            client: false,
            dropdown: [],
            popupOpen: false,
            company: '',
            popupText: '',
            industry_type: '',
            utility_type: '',
            change: {},
            url: '',
            prevProps: {},
            additionalExpanded: false,
            billingAddressData: [],
            mailingAddressData: [],
            originalVals: {},
            websiteEdit: false,
        }

    }

    //handles changes to CRM fields
    handleCRMChange = event => {
        let newCrmData = this.state.crmData
        newCrmData[event.target.name] = event.target.value
        this.setState({ crmData: newCrmData })
    }
    handleNoSave = () =>{
        let crmData = this.state.crmData
        crmData[this.state.popupText] = this.state.originalVals[this.state.popupText]
        this.setState({crmData: crmData, popupText: '', popupOpen: false})
    }
    handleClickAway = (newField) =>{
        if (newField === 'time_zone' && this.state.marketData.time_zone !== this.state.originalVals.time_zone) {
            this.setState({popupOpen: true, popupText: newField})
            return
        }

        for(let field in this.state.crmData){
            if(field === 'crm_id'){
                continue
            }
            if(this.state.crmData[field] !== this.state.originalVals[field]){
                this.setState({popupOpen: true, popupText: field})
                return
            }
        }
    }
    handleCRMSave = () => {
        const name = this.state.popupText
        const value = (name === 'time_zone' ? this.state.marketData[name] : this.state.crmData[name])
        const table = (name === 'time_zone' ? 'hug.crm_marketing_stats' : 'hug.crm_master')

        let originalVals = this.state.originalVals
        originalVals[name] = value
        this.setState({originalVals: originalVals, popupOpen: false, popupText: ''})

        const data = {
            action: 'updateColumnValue',
            qualifier: 'crm_id=' + this.state.crmData.crm_id,
            table_name: table,
            column_sql_type: 'text',
            column_name: name,
            column_value: value,
        }

        PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {
            this.props.setCrmData(this.state.crmData.crm_id)
        })

        if (name === 'web_site') {
            this.setUrl(value)
        }
    }

    setUrl = url => {
        if (url.substring(0, 3) === 'www') {
            url = 'http://' + url
        }
        this.setState({ url: url })
    }

    handleFieldChange = e => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleCompanySave = () => {
        let changes = this.state.change
        //this.setState({ popupOpen: false })
        let newCrmData = this.state.crmData

        for (let save in changes) {
            newCrmData[save] = changes[save]

            const data = {
                action: 'updateColumnValue',
                qualifier: 'crm_id=' + this.state.crmData.crm_id,
                table_name: 'hug.crm_master',
                column_sql_type: 'text',
                column_name: save,
                column_value: changes[save],
            }

            PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {})
        }
        this.setState({ crmData: newCrmData })
        this.props.setCrmData(newCrmData.crm_id)
    }


    handleAddressChange = event => {
        let change = this.state[event.target.id + 'AddressData']
        change[event.target.name] = event.target.value
        this.setState({ [event.target.id + 'AddressData']: change })

        const data = {
            action: 'updateColumnValue',
            qualifier:
                'crm_id=' +
                this.state.crmData.crm_id +
                " AND type='" +
                event.target.id +
                "'",
            table_name: 'hug.crm_additional_addresses',
            column_sql_type: event.target.type ? event.target.type : 'text',
            column_name: event.target.name,
            column_value: event.target.value,
        }

        PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {})
    }

    componentWillMount() {
        if (this.props.crmData) {
            this.setState({ crmData: this.props.crmData })
            this.setState({
                company: this.props.crmData.company,
                prevProps: this.props,
            })
            let originalVals = this.state.originalVals
            for(let item in this.props.crmData){
                originalVals[item] = this.props.crmData[item]
            }
            
            this.getDropdown()
            if (this.props.crmData.web_site) {
                this.setUrl(this.props.crmData.web_site)
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        //console.log(1)
        if (
            nextProps.crmData !== this.state.prevProps.crmData &&
            this.state.prevProps
        ) {
            this.setState({ crmData: nextProps.crmData })
            
            this.setState({
                company: nextProps.crmData.company,
                utility_type: nextProps.crmData.utility_type,
                industry_type: nextProps.crmData.industry_type,
                prevProps: nextProps,
                //prevProps: nextProps,
                //edit: nextProps.loading === 'ClientInfo' || this.state.prevProps.loading === 'ClientInfo' && true
            })
            let originalVals = this.state.originalVals
            for(let item in nextProps.crmData){
                originalVals[item] = nextProps.crmData[item]
            }
            this.getMarket(nextProps.crmData.crm_id)
            this.getAdditionalAddresses(nextProps.crmData.crm_id)
            this.getDropdown()
            if (nextProps.crmData.web_site) {
                this.setUrl(nextProps.crmData.web_site)
            }
        }
    }

    getMarket = id => {
        const data = {
            action: 'getCRMMarketingStats',
            crm_id: id,
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
        }

        PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {
            this.setState({ marketData: response.resultSet });
            
            let originalVals = this.state.originalVals

            for(let item2 in response.resultSet){
                originalVals[item2] = response.resultSet[item2]
            }
        })
    }

    getAdditionalAddresses = id => {
        const data = {
            action: 'getCRMAdditionalAddresses',
            crm_id: id,
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
        }

        PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {
            if (response.result) {
                response.resultSet && response.resultSet.forEach(type => {
                    if (type.type == 'billing') {
                        this.setState({ billingAddressData: type })
                    } else if (type.type == 'mailing') {
                        this.setState({ mailingAddressData: type })
                    }
                })
            }
        })
    }

    //Gets all dropdowns for page
    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'COMPANY',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            this.setState({ dropdown: response.dropdowns })
        })
    }

    //displays specific dropdown
    viewDropdown = control => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.name === control[0]) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })
        //if (control[1] === 'time_zone' && this.state.marketData) {
        //    if (
        //        this.state.marketData.time_zone === 'EDT' ||
        //        this.state.marketData.time_zone === 'EST' ||
        //        this.state.marketData.time_zone === 'Eastern Standard Time'
        //    ) {
        //        let new_state = this.state.marketData
        //        new_state.time_zone = 'EST - Eastern Standard Time'
        //        this.setState({ marketData: new_state })
        //    } else if (
        //        this.state.marketData.time_zone === 'MDT' ||
        //        this.state.marketData.time_zone === 'MST' ||
        //        this.state.marketData.time_zone === 'Mountain Standard Time'
        //    ) {
        //        let new_state = this.state.marketData
        //        new_state.time_zone = 'MST - Mountain Standard Time'
        //        this.setState({ marketData: new_state })
        //    } else if (
        //        this.state.marketData.time_zone === 'CDT' ||
        //        this.state.marketData.time_zone === 'CST' ||
        //        this.state.marketData.time_zone === 'Central Standard time'
        //    ) {
        //        let new_state = this.state.marketData
        //        new_state.time_zone = 'CST - Central Standard Time'
        //        this.setState({ marketData: new_state })
        //    } else if (
        //        this.state.marketData.time_zone === 'PDT' ||
        //        this.state.marketData.time_zone === 'PST' ||
        //        this.state.marketData.time_zone === 'Pacific Standard Time'
        //    ) {
        //        let new_state = this.state.marketData
        //        new_state.time_zone = 'PST - Pacific Standard Time'
        //        this.setState({ marketData: new_state })
        //    } else if (
        //        this.state.marketData.time_zone === 'AKDT' ||
        //        this.state.marketData.time_zone === 'AKST' ||
        //        this.state.marketData.time_zone === 'Alaskan Standard Time'
        //    ) {
        //        let new_state = this.state.marketData
        //        new_state.time_zone = 'AKST - Alaskan Standard Time'
        //        this.setState({ marketData: new_state })
        //    } else if (
        //        this.state.marketData.time_zone === 'HDT' ||
        //        this.state.marketData.time_zone === 'HST' ||
        //        this.state.marketData.time_zone === 'Hawaii Standard Time'
        //    ) {
        //        let new_state = this.state.marketData
        //        new_state.time_zone = 'HST - Hawaii Standard Time'
        //        this.setState({ marketData: new_state })
        //    } else if (
        //        this.state.marketData.time_zone === 'ADT' ||
        //        this.state.marketData.time_zone === 'AST' ||
        //        this.state.marketData.time_zone === 'Atlantic Standard Time'
        //    ) {
        //        let new_state = this.state.marketData
        //        new_state.time_zone = 'AST - Atlantic Standard Time'
        //        this.setState({ marketData: new_state })
        //    }
        //}

        if (control[0] === 'State' || control[0] === 'Province') {
            for (let i = 0; i < list.length; i++) {
                if (
                    list[i].code === this.state.crmData[control[1]] ||
                    list[i].name === this.state.crmData[control[1]]
                ) {
                    let new_state = this.state
                    new_state.crmData[control[1]] =
                        list[i].code + ' - ' + list[i].name
                    new_state.originalVals[control[1]] = list[i].code + ' - ' + list[i].name
                    this.setState(new_state)
                }
            }
        }

        return (
            <ClickAwayListener onClickAway={() => {this.handleClickAway(control[1])}}>
                <TextField
                    select
                    id={control[1]}
                    name={control[1]}
                    label={control[0]}
                    fullWidth
                    value={
                        
                             control[1] === 'time_zone'
                                ? this.state.marketData[control[1]]
                                : this.state.crmData[control[1]]
                            
                    }
                    onChange={
                        control[1] === 'time_zone'
                            ? this.handleMChange
                            : this.handleCRMChange
                    }
                    className={classes.textField}
                >
                    {list.map(item => (
                        <MenuItem
                            onChange={
                                control[0] === 'time_zone'
                                    ? this.handleMChange
                                    : this.handleCRMChange
                            }
                            key={item.code}
                            id={item.code}
                            value={
                                control[1] === 'country' ||
                                control[1] === 'utility_type' ||
                                control[1] === 'industry_type'
                                    ? item.name
                                    : item.code + ' - ' + item.name
                            }
                        >
                            {control[1] === 'country' ||
                            control[1] === 'utility_type' ||
                            control[1] === 'industry_type'
                                ? item.name
                                : item.code + ' - ' + item.name}
                        </MenuItem>
                    ))}
                </TextField>
            </ClickAwayListener>
        )
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    /////////////////////////////////////////////////
    //Opens and closes all the sections

    handleStats = () => {
        this.setState({ stats: !this.state.stats })
    }
    handleMChange = (event)=>{
        let marketData = this.state.marketData
        marketData[event.target.name] = event.target.value
        this.setState({ marketData: marketData })

        // let change = {}
        // change[e.target.name] = e.target.value
        // this.setState(change)
    }
    handleMap = () => {
        
        window.open(
            process.env.REACT_APP_API_URL +
                '/HarrisUtilityGroup/MarketingMap/map.php?action=crm_lookup&crm_id=' +
                this.state.crmData.crm_id,
            '_blank'
        )
        //this.setState({ map: !this.state.map })
    }
    handleEdit = () => {
        this.setState({ edit: !this.state.edit })
    }
    handleAlias = () => {
        this.setState({ alias: !this.state.alias })
    }
    handleSupportDates = () => {
        this.setState({ dates: !this.state.dates })
    }
    handleClientInfo = () => {
        this.setState({ client: !this.state.client })
    }

    //
    ///////////////////////////////////////////////////

    //Shows the basic information in the top card
    showLess = () => {
        const { classes } = this.props
        return (
            <React.Fragment>
                {/* <Card> */}
                <Grid container spacing={24}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={this.props.formOpen ? 12 : 4}
                        lg={this.props.formOpen ? 12 : 3}
                    >
                        <ClickAwayListener onClickAway={this.handleClickAway}>
                            <TextField
                                required
                                id="address_1"
                                name="address_1"
                                label="Address"
                                fullWidth
                                className={classes.textField}
                                value={this.state.crmData.address_1}
                                onChange={this.handleCRMChange}
                            />
                        </ClickAwayListener>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={this.props.formOpen ? 12 : 6}
                        md={this.props.formOpen ? 12 : 3}
                        lg={this.props.formOpen ? 6 : 2}
                    >
                        <ClickAwayListener onClickAway={this.handleClickAway}>
                            <TextField
                                required
                                id="city"
                                name="city"
                                label="City"
                                fullWidth
                                className={classes.textField}
                                value={this.state.crmData.city}
                                onChange={this.handleCRMChange}
                            />
                        </ClickAwayListener>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={this.props.formOpen ? 12 : 6}
                        md={this.props.formOpen ? 6 : 2}
                        lg={this.props.formOpen ? 2 : 1}
                    >
                        {this.state.crmData.country === 'Canada' ||
                        this.state.crmData.country === 'United States' ? (
                            this.viewDropdown(
                                this.state.crmData.country === 'Canada'
                                    ? ['Province', 'state']
                                    : this.state.crmData.country ===
                                          'United States' && ['State', 'state']
                            )
                        ) : (
                            <ClickAwayListener onClickAway={this.handleClickAway}>
                                <TextField
                                    id="state"
                                    name="state"
                                    label="State/Province"
                                    fullWidth
                                    className={classes.textField}
                                    value={this.state.crmData.state}
                                    onChange={this.handleCRMChange}
                                />
                            </ClickAwayListener>
                        )}
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={this.props.formOpen ? 12 : 6}
                        md={this.props.formOpen ? 6 : 3}
                        lg={this.props.formOpen ? 3 : 2}
                    >
                        {this.viewDropdown(['Country', 'country'])}
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={this.props.formOpen ? 12 : 6}
                        md={this.props.formOpen ? 6 : 2}
                        lg={this.props.formOpen ? 2 : 1}
                    >
                        <ClickAwayListener onClickAway={this.handleClickAway}>
                            <TextField
                                required
                                id="zip_code"
                                name="zip_code"
                                label="Zip / Postal code"
                                fullWidth
                                className={classes.textField}
                                value={this.state.crmData.zip_code}
                                onChange={this.handleCRMChange}
                            />
                        </ClickAwayListener>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sm={this.props.formOpen ? 12 : 4}
                        md={this.props.formOpen ? 6 : 3}
                        lg={this.props.formOpen ? 3 : 2}
                    >
                        {/* <ClickAwayListener onClickAway={this.handleClickAway}>
                            <NumberFormat
                                id="phone"
                                name="phone"
                                label="Office Phone"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField}
                                value={this.state.crmData.phone}
                                onChange={this.handleCRMChange}
                                format="(###) ###-####"
                            />
                        </ClickAwayListener> */}

                        {this.state.officePhoneEdit ? (
                            <ClickAwayListener onClickAway={this.handleClickAway}>
                                <NumberFormat
                                    id="phone"
                                    name="phone"
                                    label="Office Phone"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={this.state.crmData.phone}
                                    onChange={this.handleCRMChange}
                                    format="(###) ###-####"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                component="span"
                                            >
                                                <Tooltip title={'Close Editing'}>
                                                    <IconButton size='small'>
                                                        <FontAwesomeIcon
                                                            icon={faTimes}
                                                            size="1x"
                                                            color="black"
                                                            style={{
                                                                marginLeft: 5,
                                                            }}
                                                            onClick={e => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                this.setState({officePhoneEdit: false})
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </ClickAwayListener>
                        ) : (
                            <a href={"tel:"+this.state.crmData.phone} style={{textDecoration: 'none'}}>
                                <NumberFormat
                                    id="phone"
                                    name="phone"
                                    label="Office Phone"
                                    fullWidth
                                    className={classes.textField}
                                    customInput={TextField}
                                    value={this.state.crmData.phone}
                                    format="(###) ###-####"
                                    InputProps={{
                                        disableUnderline: true,
                                        startAdornment: (
                                            <InputAdornment
                                                position="start"
                                                component="span"
                                            >
                                                <Tooltip title={'Call'}>
                                                    <IconButton size='small'>
                                                        <FontAwesomeIcon
                                                            icon={faPhone}
                                                            size="1x"
                                                            color="black"
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                component="span"
                                            >
                                                <Tooltip title={'Edit Office Phone'}>
                                                    <IconButton size='small'>
                                                        <FontAwesomeIcon
                                                            icon={faEdit}
                                                            size="1x"
                                                            color="black"
                                                            style={{
                                                                marginLeft: 5,
                                                            }}
                                                            onClick={e => {
                                                                e.preventDefault()
                                                                e.stopPropagation()
                                                                this.setState({officePhoneEdit: true})
                                                            }}
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            </InputAdornment>
                                        )
                                    }}
                                />  
                            </a>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={this.props.formOpen ? 12 : 2}
                        md={this.props.formOpen ? 12 : 2}
                        lg={this.props.formOpen ? 1 : 1}
                    >
                        <Tooltip title="View Map" className={classes.textField} >
                            <Button onClick={this.handleMap}>
                                View Map
                                <FontAwesomeIcon
                                    icon={faMapMarkedAlt}
                                    size="2x"
                                    style={{
                                        marginLeft: 5,
                                    }}
                                />
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                {/* </Card> */}
            </React.Fragment>
        )
    }

    //Shows the detailed market stats
    showStats = () => {
        const { classes } = this.props

        return (
            
                <React.Fragment>
                        <Grid container spacing={1}>
                        <Grid item xs={6} sm={3}>
                                <TextField
                                disabled
                                    id="city_area"
                                    name="city_area"
                                    label="City Area (Sq Miles)"
                                    fullWidth
                                    className={classes.textField}
                                    value={this.state.marketData && this.state.marketData.city_area}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TextField
                                disabled
                                    id="latitude"
                                    name="latitude"
                                    label="Latitude"
                                    fullWidth
                                    className={classes.textField}
                                    value={this.state.crmData.latitude}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TextField
                                disabled
                                    id="longitude"
                                    name="longitude"
                                    label="Longitude"
                                    fullWidth
                                    className={classes.textField}
                                    value={this.state.crmData.longitude}
                                />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TextField
                                disabled
                                    id="county_area"
                                    name="county_area"
                                    label="County Area (Sq Miles)"
                                    fullWidth
                                    className={classes.textField}
                                    value={this.state.marketData && this.state.marketData.county_area}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="inherit"
                                    className={classes.textField}
                                >
                                    {'City Statistics - ' + this.state.crmData.city}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="inherit"
                                    className={classes.textField}
                                >
                                    {'County Statistics - ' + (this.state.marketData && this.state.marketData.county_name)}
                                </Typography>
                            </Grid>
                            


                            <Grid item xs={12} sm={6}>

                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Year</TableCell>
                                            <TableCell>Population</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Prior Data</TableCell>
                                            <TableCell>{this.state.marketData.census_prior_year}</TableCell>
                                            <TableCell>{this.state.marketData.city_population_prior}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Current Data</TableCell>
                                            <TableCell>{this.state.marketData.census_current}</TableCell>
                                            <TableCell>{this.state.marketData.city_population_current}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Residential</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>{this.state.marketData.city_population_residential}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Commercial</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>{this.state.marketData.city_population_commercial}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>

                                
                            <Grid item sm={6} xs={12}>


                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Description</TableCell>
                                            <TableCell>Year</TableCell>
                                            <TableCell>Population</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>Current Data</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>{this.state.marketData.county_population}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Residential</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>{this.state.marketData.county_population_residential}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Commercial</TableCell>
                                            <TableCell></TableCell>
                                            <TableCell>{this.state.marketData.county_population_commercial}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            


                            </Grid>
                            <Typography
                                    className={classes.modifiedText}
                                >
                                    {'City Growth Rate: ' + (parseFloat(this.state.marketData.city_population_current) - parseFloat(this.state.marketData.city_population_prior)) / parseFloat(this.state.marketData.city_population_prior) * 100 + '%'}
                            </Typography>
                        </Grid>
                            
                            
                        
                </React.Fragment>
            
        )
    }

    //Shows Client Info
    showClientInfo = () => {
        return (
            <Grid item xs={12}>
                {/* <Card> */}
                {this.props.loading !== 'ClientInfo' && (
                    <ClientInfo
                        handleFormOpen={this.props.handleFormOpen}
                        setFormData={this.props.setFormData}
                        crmData={this.state.crmData}
                    />
                )}
                {/* </Card> */}
            </Grid>
        )
    }

    //Shows Support Dates
    showSupport = () => {
        return (
            <Grid item xs={12}>
                <Card>
                    {this.props.loading !== 'SupportDates' && (
                        <SupportDates
                            handleFormOpen={this.props.handleFormOpen}
                            setFormData={this.props.setFormData}
                            crmData={this.state.crmData}
                        />
                    )}
                </Card>
            </Grid>
        )
    }

    //Shows Alias
    showAlias = () => {
        return (
            <Grid item xs={12}>
                <Card>
                    {this.props.loading !== 'Alias' && (
                        <Alias
                            handleFormOpen={this.props.handleFormOpen}
                            setFormData={this.props.setFormData}
                            crmData={this.state.crmData}
                        />
                    )}
                </Card>
            </Grid>
        )
    }
    // MARK: showEditCompany
    //^^ used for extension to jump to this section of code
    //Shows Edit Company
    showEditCompany = () => {
        const { classes } = this.props
        return (
            <React.Fragment>
                <Grid item xs={12}>
                    
                        <Grid item sm={12} md={12}>
                            <Grid container spacing={1}>
                                <Grid item lg={6} md={4} sm={6} xs={12}>
                                    <ClickAwayListener onClickAway={this.handleClickAway}>
                                        <TextField
                                            required
                                            id="company"
                                            name="company"
                                            label="Company Name"
                                            fullWidth
                                            className={classes.textField}
                                            value={this.state.crmData.company}
                                            onChange={this.handleCRMChange}
                                        />
                                    </ClickAwayListener>
                                </Grid>
                                <Grid
                                    item
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}
                                    className={classes.textFieldGrid}
                                >
                                    {this.viewDropdown([
                                        'Industry Type',
                                        'industry_type',
                                    ])}
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    {this.viewDropdown([
                                        'Utility Type',
                                        'utility_type',
                                    ])}
                                </Grid>
                                <Grid item lg={3} md={4} sm={6} xs={12}>
                                    {this.viewDropdown([
                                        'Time Zone',
                                        'time_zone',
                                    ])}
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={6}>
                                    <ClickAwayListener onClickAway={this.handleClickAway}>
                                        <TextField
                                            id="customer_number"
                                            name="customer_number"
                                            label="Customer Count"
                                            fullWidth
                                            className={classes.textField}
                                            value={
                                                this.state.crmData.customer_number
                                            }
                                            onChange={this.handleCRMChange}
                                        />
                                    </ClickAwayListener>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={6}>
                                    <ClickAwayListener onClickAway={this.handleClickAway}>
                                        <TextField
                                            id="budget_preparation_month"
                                            name="budget_preparation_month"
                                            label="Budget Prep Month (drop)"
                                            fullWidth
                                            className={classes.textField}
                                            value={
                                                this.state.crmData
                                                    .budget_preparation_month
                                            }
                                            onChange={this.handleCRMChange}
                                        />
                                    </ClickAwayListener>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2} xs={6}>
                                    <ClickAwayListener onClickAway={this.handleClickAway}>
                                        <TextField
                                            id="fiscal_year_end"
                                            name="fiscal_year_end"
                                            label="Fiscal Year End"
                                            fullWidth
                                            className={classes.textField}
                                            value={
                                                this.state.crmData.fiscal_year_end
                                            }
                                            onChange={this.handleCRMChange}
                                        />
                                    </ClickAwayListener>
                                </Grid>

                                <Grid item lg={3} md={3} sm={3} xs={10}>
                                    {this.state.websiteEdit ? (
                                        <ClickAwayListener onClickAway={this.handleClickAway}>
                                            <TextField 
                                                id="web_site"
                                                name="web_site"
                                                label="Web Site"
                                                fullWidth
                                                className={classes.textField}
                                                value={this.state.crmData.web_site}
                                                onChange={this.handleCRMChange}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            component="span"
                                                        >
                                                            <Tooltip title={'Close Editing'}>
                                                                <IconButton size='small'>
                                                                    <FontAwesomeIcon
                                                                        icon={faTimes}
                                                                        size="1x"
                                                                        color="black"
                                                                        style={{
                                                                            marginLeft: 5,
                                                                        }}
                                                                        onClick={e => {
                                                                            e.preventDefault()
                                                                            e.stopPropagation()
                                                                            this.setState({websiteEdit: false})
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </ClickAwayListener>
                                    ) : (
                                        <a href={this.state.url} target='_blank'>
                                            <TextField
                                                label="Web Site"
                                                fullWidth
                                                className={classes.textField}
                                                value={this.state.crmData.web_site}
                                                InputProps={{
                                                    disableUnderline: true,
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            component="span"
                                                        >
                                                            <Tooltip title={'Visit Website'}>
                                                                <IconButton size='small'>
                                                                    <FontAwesomeIcon
                                                                        icon={faLink}
                                                                        size="1x"
                                                                        color="black"
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment
                                                            position="end"
                                                            component="span"
                                                        >
                                                            <Tooltip title={'Edit Website'}>
                                                                <IconButton size='small'>
                                                                    <FontAwesomeIcon
                                                                        icon={faEdit}
                                                                        size="1x"
                                                                        color="black"
                                                                        style={{
                                                                            marginLeft: 5,
                                                                        }}
                                                                        onClick={e => {
                                                                            e.preventDefault()
                                                                            e.stopPropagation()
                                                                            this.setState({websiteEdit: true})
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />  
                                        </a>
                                    )}
                                </Grid>

                                <Grid item className={classes.websitebutton}>

                                </Grid>    
                                {/*<Grid item xs={6} sm={1}>
                                    <TextField
                                        id="latitude"
                                        name="latitude"
                                        label="Latitude"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.crmData.latitude}
                                        onChange={this.handleCRMChange}
                                    />
                                </Grid>
                                <Grid item item xs={6} sm={1}>
                                    <TextField
                                        id="longitude"
                                        name="longitude"
                                        label="Longitude"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.crmData.longitude}
                                        onChange={this.handleCRMChange}
                                    />
                                </Grid>*/}
                                {/* <Grid className={classes.buttonGrid} item>
                                    <Button
                                        className={classes.saveCompany}
                                        onClick={this.handleCompanyChange}
                                    >
                                        Save
                                    </Button>
                                </Grid> */}
                                
                            </Grid>
                        </Grid>
                    
                </Grid>
            </React.Fragment>
        )
    }

    showAdditionalAddresses = () => {
        const { classes } = this.props

        return (
            <React.Fragment>
                <Grid item xs={12} sm={12}>
                    <Card>
                        {/*<div className={classes.secondary}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12}>
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        color="inherit"
                                        className={classes.title}
                                    >
                                        Additional Addresses
                                    </Typography>
                                </Grid>
                            </Grid>
        </div>*/}

                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={24}>
                                <Grid item xs={12} sm={12}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}>
                                            <Typography
                                                component="h1"
                                                variant="h6"
                                                color="inherit"
                                                className={classes.textField}
                                            >
                                                Billing
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <TextField
                                                id="billing"
                                                name="address1"
                                                label="Address 1"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .billingAddressData
                                                        .address1
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={6} xs={12}>
                                            <TextField
                                                id="billing"
                                                name="address2"
                                                label="Address 2"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .billingAddressData
                                                        .address2
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={4} xs={6}>
                                            <TextField
                                                id="billing"
                                                name="city"
                                                label="City"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .billingAddressData.city
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={3} xs={6}>
                                            <TextField
                                                id="billing"
                                                name="state"
                                                label="State"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .billingAddressData
                                                        .state
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={3} xs={6}>
                                            <TextField
                                                id="billing"
                                                name="country"
                                                label="Country"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .billingAddressData
                                                        .country
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={1} md={2} xs={6}>
                                            <TextField
                                                id="billing"
                                                name="zip"
                                                label="Zip Code"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .billingAddressData.zip
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <Grid container spacing={24}>
                                        <Grid item xs={12}>
                                            <Typography
                                                component="h1"
                                                variant="h6"
                                                color="inherit"
                                                className={classes.textField}
                                            >
                                                Mailing
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={3} md={6} xs={12}>
                                            <TextField
                                                id="mailing"
                                                name="address1"
                                                label="Address 1"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .mailingAddressData
                                                        .address1
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={6} xs={12}>
                                            <TextField
                                                id="mailing"
                                                name="address2"
                                                label="Address 2"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .mailingAddressData
                                                        .address2
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={4} xs={6}>
                                            <TextField
                                                id="mailing"
                                                name="city"
                                                label="City"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .mailingAddressData.city
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={3} xs={6}>
                                            <TextField
                                                id="mailing"
                                                name="state"
                                                label="State"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .mailingAddressData
                                                        .state
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={2} md={3} xs={6}>
                                            <TextField
                                                id="mailing"
                                                name="country"
                                                label="Country"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .mailingAddressData
                                                        .country
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                        <Grid item lg={1} md={2} xs={6}>
                                            <TextField
                                                id="mailing"
                                                name="zip"
                                                label="Zip Code"
                                                fullWidth
                                                className={classes.textField}
                                                value={
                                                    this.state
                                                        .mailingAddressData.zip
                                                }
                                                onChange={
                                                    this.handleAddressChange
                                                }
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </React.Fragment>
        )
    }

    toggleAdditional = () => {
        this.setState({ additionalExpanded: !this.state.additionalExpanded })
    }

    //Toggles between what dropdown section is open on the page
    theToggle = () => {
        let clientInfo = (
            <Grid item xs={12} sm={12}>
                {this.showClientInfo()}
            </Grid>
        )
        let stats = (
            <Grid item xs={12} sm={12}>
                <Card>{this.showStats()}</Card>
            </Grid>
        )
        let editCompany = (
            <Grid item xs={12} sm={12}>
                {this.showEditCompany()}
            </Grid>
        )
        let support = (
            <Grid item xs={12} sm={12}>
                {this.showSupport()}
            </Grid>
        )
        let alias = (
            <Grid item xs={12} sm={12}>
                {this.showAlias()}
            </Grid>
        )
        let additionalAddresses = (
            <Grid item xs={12} sm={12}>
                <Card>{this.showAdditionalAddresses()}</Card>
            </Grid>
        )

        const { classes } = this.props

        let userData = JSON.parse(sessionStorage.getItem('userData'))
        let tabTitles

        if (userData.strategic_business_unit === 'dataVoice') {
            tabTitles = [
                'Client Info', 
                'Additional Addresses', 
                'Aliases', 
                // 'Population', 
                // 'Mapping',
                'Association Info' 
            ]
        } else {
            tabTitles = [
                'Client Info',
                'Additional Addresses',
                'Aliases',
                // 'Population',
                // 'Mapping',
            ]
        }

        return (
            <ExpansionPanel style={{borderStyle: 'none none none none'}}>
                <ExpansionPanelSummary
                    onClick={this.toggleAdditional}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    // expandIcon={
                    //     <FontAwesomeIcon
                    //         icon={faChevronDown}
                    //         size="1x"
                    //         color="white"
                    //     />
                    // }
                    className={classes.primary}
                    style={{
                        marginLeft: 0,
                        marginRight: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                        color: 'white',
                        //backgroundColor: 'white',
                    }}
                >
                    <FontAwesomeIcon
                        icon={
                            this.state.additionalExpanded
                                ? faChevronUp
                                : faChevronDown
                        }
                        style={{ marginTop: 5, marginLeft: 13 }}
                        size="1x"
                        color="white"
                    />
                    <Typography component="h6">
                    &nbsp;&nbsp; Additional Company Information
                    </Typography>
                </ExpansionPanelSummary>
                
                <ExpansionPanelActions style={{
                        marginLeft: 0,
                        marginRight: 0,
                        paddingLeft: 0,
                        paddingRight: 0,
                    }}
                    disableSpacing>
                
                    <Grid container spacing={1}>
                        
                        <Grid item xs={12}>
                        
                            {editCompany}
                            {/* {stats} */}
                        
                        </Grid>
                        <Grid item xs={12}>
                            <SwipeableTabs titles={tabTitles}>
                                {clientInfo}
                                {additionalAddresses}
                                {alias}
                                {this.state.crmData.crm_id && <AssociationInfo crmId={this.state.crmData.crm_id} />}
                            </SwipeableTabs>
                        </Grid>
                    </Grid>
                </ExpansionPanelActions>
            </ExpansionPanel>
                
            
        )
    }
    // MARK: render
    render() {
        // console.log(this.state)
        const { classes } = this.props
        return (
                <React.Fragment>
                    <Card>
                        {this.showLess()}
                    
                        {this.theToggle()}
                    </Card>
                
                {/* <Grid item xs={12}>
                    {this.bottomArrows()}
                </Grid> */}
                <Dialog
                    open={this.state.popupOpen}
                    aria-labelledby="save-company-info"
                    aria-describedby="description"
                >
                    <DialogTitle id="save-company-info">
                        {'Change Company Information?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="description">
                            {"Would you like to save the changes you've made to " + this.state.popupText.replace(/_/g, ' ') + '?'}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.handleNoSave}
                            className={classes.cardButtons}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={this.handleCRMSave}
                            className={classes.cardButtons}
                            autoFocus
                        >
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        )
    }
}

CompanyForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CompanyForm)
