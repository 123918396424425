import React from 'react'
import ReactDOM from 'react-dom'
import { Redirect } from 'react-router-dom'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import { styles } from '../styles'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import CloseIcon from '@material-ui/icons/Close'

import Drawer from '@material-ui/core/Drawer'
import MainDrawerItems from './Items/MainDrawerItems'

class LeftDrawer extends React.Component {
    constructor(props) {
        super(props)

        this.handleDrawerClose = this.handleDrawerClose.bind(this)
        this.goBack = this.goBack.bind(this)
    }

    // Returns the component that contains the list of items for the drawer
    // getDrawerList(classes) {
    //     let component
    //     switch (this.state.component) {
    //         case 'Company':
    //             component = (
    //                 <MainDrawerItems
    //                     setMain={this.props.setMain}
    //                     classes={classes}
    //                 />
    //             )
    //             break
    //     }
    //     return component
    // }

    // Call the parent function to close the drawer
    handleDrawerClose = () => {
        this.props.handleDrawerClose()
    }
    /* componentWillReceiveProps(nextProps){
        
    }*/
    // Sends the user back one page. Kind of like pressing the back button in the browser
    goBack = () => {
        this.props.history.goBack()
    }

    render() {
        const { classes } = this.props
        let open = this.props.open

        return (
            <Drawer
                variant="permanent"
                classes={{
                    paper: classNames(
                        this.props.classes.drawerPaper,
                        !open && this.props.classes.drawerPaperClose
                    ),
                }}
                open={open}
            >
                <div className={this.props.classes.toolbarIcon}>
                    <div className={this.props.classes.spacer} />
                    {/* <IconButton onClick={this.goBack}>
                        <Icon>arrow_back_ios</Icon>
                    </IconButton>
                    <div style={{flexGrow: 1}} /> */}
                    <IconButton onClick={this.handleDrawerClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <MainDrawerItems
                    setMain={this.props.setMain}
                    classes={classes}
                    open={open}
                    main={this.props.main}
                    setCrmData={this.props.setCrmData}
                    setFormData={this.props.setFormData}
                    handleFormClose={this.props.handleFormClose}
                    history={this.props.history}
                    handleDrawerOpen={this.props.handleDrawerOpen}
                />
                {/* {this.getDrawerList(classes)} */}
                {/* {this.props.open && (
                    <Link
                        to="/release-notes"
                        style={{
                            position: 'absolute',
                            bottom: 10,
                            left: 10,
                            color: '#d2d2d2',
                            textDecoration: 'none',
                            fontSize: 'small',
                        }}
                    >
                        {process.env.REACT_APP_VERSION}
                    </Link>
                )} */}
            </Drawer>
        )
    }
}

LeftDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(LeftDrawer)
