import React from 'react'
import PropTypes from 'prop-types'

import DateFnsUtils from '@date-io/date-fns'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import {
    Clear as ClearIcon,
    InsertInvitation as CalendarIcon
} from "@material-ui/icons";
import { IconButton, InputAdornment } from "@material-ui/core";

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MenuItem from '@material-ui/core/MenuItem'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import CKEditor from 'ckeditor4-react'
import { PostData } from '../../../services/PostData'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { addValidators, validate } from '../../../utils/Validate'
import FormWrapper from '../../Reusable/FormWrapper'
import SaveWarning from '../../Reusable/SaveWarning'


const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
})

class ClientInfoForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {
                purchase_order_required: false,
            },
            previous_sbu_client_id: null,
            sbuClientIdFound: false
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    handleChange = event => {
        let data = this.state.data ? this.state.data : {}
        data[event.target.name] = event.target.value
        this.setState({ data: data })
    }

    handleCChange = event => {
        console.log(this.state.data)
        let data = this.state.data
        data[event.target.id] = this.YesNo(event.target.checked)
        this.setState({ data: data })
    }
    handleNormalCChange = event => {
        console.log(this.state.data)
        let data = this.state.data
        data[event.target.id] = event.target.checked
        this.setState({ data: data })
    }

    handleDateWonChange = date => {
        let data = this.state.data
        data.date_won = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
        this.setState({ data: data })
    }

    handleDateLostChange = date => {
        let data = this.state.data
        data.date_lost = date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
        this.setState({ data: data })
    }

    onDateClear = (event, key) => {
        event.stopPropagation();

        let data = this.state.data
        data[key] = null
        this.setState({ data: data })
    }

    handleClose = () => {
        this.props.handleFormClose()
    }

    getCurrentDate(separator = '/') {
        let newDate = new Date()
        let date = newDate.getDate()
        if (date < 10) {
            date = String(date)
            date = '0' + date
        }
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()

        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`
    }


    handleSave = () => {
        if (validate(this, this.state.data)) {
            //console.log('test')
            //console.log(this.state.data.active)
            const data = {
                action: 'update_record',
                hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit),
                user_id: JSON.parse(sessionStorage.getItem('userData')).userid,
                recordid: this.state.data.recordid,
                crm_id: this.state.data.crm_id,
                strategic_business_unit: JSON.parse(
                    sessionStorage.getItem('userData')
                ).strategic_business_unit,
                sbu_client_id: this.state.data.sbu_client_id,
                previous_sbu_client_id: this.state.previous_sbu_client_id,
                harris_client_id: this.state.data.harris_client_id,
                team_support_id: this.state.data.team_support_id,
                active: this.state.data.active ,
                date_won: this.state.data.date_won,
                date_lost: this.state.data.date_lost,
                purchase_order_required: this.state.data
                    .purchase_order_required,
                at_risk_status: this.state.data.at_risk_status,
                client_info_note: this.state.data.client_info_note,
                at_risk_comments: this.state.data.at_risk_comments,
            }

            PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data).then(response => {
                if (response.found) {
                    this.setState({ sbuClientIdFound: true })
                } else {
                    this.setState({ sbuClientIdFound: false })
                    this.props.updateComponent('ClientInfo')
                }
            })

            return true
        }

        return false
    }

    addNew = () => {
        if (validate(this, this.state.data)) {
            //console.log(this.state.crmData.crm_id)
            const data = {
                action: 'insert_record',
                crm_id: this.state.crmData.crm_id,
                user_id: JSON.parse(sessionStorage.getItem('userData')).userid,
                strategic_business_unit: JSON.parse(
                    sessionStorage.getItem('userData')
                ).strategic_business_unit,
                sbu_client_id: this.state.data.sbu_client_id,
                hug_schema:
                    'hug_' + (JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit),

                harris_client_id: this.state.data.harris_client_id,
                team_support_id: this.state.data.team_support_id,
                active: true,
                date_won: this.state.data.date_won,
                date_lost: this.state.data.date_lost,
                purchase_order_required: this.state.data
                    .purchase_order_required,
                at_risk_status: this.state.data.at_risk_status,
                client_info_note: this.state.data.client_info_note,
                at_risk_comments: this.state.data.at_risk_comments,
            }
            
            PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data).then(response => {
                let data = this.state.data
                data.recordid = response.recordid
                data.crm_id = this.state.crmData.crm_id
                //console.log(response)
                data.active = true
                this.setState({data: data})
                //this.handleClose()
                this.props.updateComponent('ClientInfo')
            })
            return true
        }
        return false
    }

    componentWillMount() {
        this.setState({
            data: this.props.formData.client,
            crmData: this.props.formData.crmData,
            previous_sbu_client_id: this.props.formData.client.sbu_client_id
        }, () => {this.getDefaultSbuClientId()})
        //console.log(this.props.formData.crmData)
        addValidators(this, {
            date_won: 'required',
        })
    }

    componentWillReceiveProps(nextProps) {
        
        let newState = this.state
        const fields = ['harris_client_id', 'sbu_client_id', 'date_won', 'date_lost', 'team_support_id', 'active', 'at_risk_status', 'purchase_order_required', 'client_info_note', 'at_risk_comments', 'recordid']
        if(nextProps.formData.client){
            for(let i = 0; i<fields.length; i++){
                newState.data[fields[i]] = nextProps.formData.client[fields[i]] 
            }
        } 
        newState.crmData = nextProps.formData.crmData
        
        //console.log('this runs')
        //console.log(newState)
        this.setState(newState, () => {this.getDefaultSbuClientId()})

    }

    getDefaultSbuClientId = () => {
        if (this.state.data.sbu_client_id === null || this.state.data.sbu_client_id === undefined || this.state.data.sbu_client_id === '') {
            let data = {
                action: 'getDefaultSbuClientId',
                company_name: this.state.crmData.company,
                sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit
            }
    
            PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data).then(response => {
                let state = this.state
                state.data.sbu_client_id = response.sbu_client_id
                this.setState(state)
            })
        }
    }

    //Converts true and false to Y and N for the database and reports
    YesNo = input => {
        if (input === 'YES') {
            return true
        } else if (input === 'NO') {
            return false
        } else if (input === true) {
            return 'YES'
        } else if (input === false) {
            return 'NO'
        } else if (input === null) {
            return false
        }
    }

    render() {
        const { classes } = this.props
        console.log(this.state.previous_sbu_client_id)
        // let sbuClientId = this.state.sbuClientId 
        // if (sbuClientId === '' || sbuClientId === null || sbuClientId === undefined) {
        //     sbuClientId = JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit + '_' + this.state.crmData.crm_id;
        // }

        return (
            

            <FormWrapper
                invalid={this.state.invalid}
                type="Client Info"
                name={this.state.data && this.state.data.strategic_business_unit}
                handleSave={
                    this.state.data.crm_id ? this.handleSave : this.addNew
                }
                handleClose={this.handleClose}
            >
                {this.state.sbuClientIdFound && (
                    <Grid item xs={12} sm={12}>
                        <SaveWarning invalid={true} customMessage={'This SBU Client ID already exists'} />
                    </Grid>
                )}
                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h6" color="inherit">
                        General
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="harris_client_id"
                        name="harris_client_id"
                        label="Harris Client ID"
                        fullWidth
                        className={classes.textField}
                        value={this.state.data ? this.state.data.harris_client_id : ''}
                        onChange={this.handleChange}
                        
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        disabled={(JSON.parse(sessionStorage.getItem('userData')).access_level === '1') ? false : true}
                        id="sbu_client_id"
                        name="sbu_client_id"
                        label="SBU Client ID"
                        fullWidth
                        className={classes.textField}
                        value={this.state.data ? this.state.data.sbu_client_id : ''}//conditional fixes bug where fields would clear when pressing add new when form already open
                        onChange={this.handleChange}
                        
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            required
                            id='date_won'
                            name="date_won"
                            variant="inline"
                            label="Date Won"
                            format="yyyy/M/d"
                            className={classes.textField}
                            value={this.state.data ? (this.state.data.date_won || null) : null}
                            onChange={this.handleDateWonChange}
                            error={this.state.errorMessages.date_won !== undefined}
                            helperText={this.state.errorMessages.date_won}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={(event) => {this.onDateClear(event, 'date_won')}}>
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    {/* <TextField
                        required
                        InputLabelProps={{ shrink: true }}
                        id="date_won"
                        name="date_won"
                        label="Date Won"
                        type="date"
                        fullWidth
                        className={classes.textField}
                        value={this.state.data ? this.state.data.date_won : ''}
                        onChange={this.handleChange}
                        error={this.state.errorMessages.date_won !== undefined}
                        helperText={this.state.errorMessages.date_won}
                    /> */}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            id='date_lost'
                            name="date_lost"
                            variant="inline"
                            label="Date Lost"
                            format="yyyy/M/d"
                            className={classes.textField}
                            value={this.state.data ? (this.state.data.date_lost || null) : null}
                            onChange={this.handleDateLostChange}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton onClick={(event) => {this.onDateClear(event, 'date_lost')}}>
                                      <ClearIcon />
                                    </IconButton>
                                  </InputAdornment>
                                )
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    {/* <TextField
                        InputLabelProps={{ shrink: true }}
                        id="date_lost"
                        name="date_lost"
                        label="Date Lost"
                        type="date"
                        fullWidth
                        className={classes.textField}
                        value={this.state.data ? this.state.data.date_lost : ''}
                        onChange={this.handleChange}
                    /> */}
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        id="team_support_id"
                        name="team_support_id"
                        label="Team Support ID"
                        fullWidth
                        className={classes.textField}
                        value={this.state.data ? this.state.data.team_support_id :''}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.state.data.active && this.state.data.active !== "0"}
                                id="active"
                                onClick={this.handleNormalCChange}
                            />
                        }
                        label="Active"
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.YesNo(this.state.data.at_risk_status)}
                                
                                id="at_risk_status"
                                onClick={this.handleCChange}
                            />
                        }
                        label="At Risk"
                    />
                </Grid>
                <Grid item xs={6} sm={4}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                defaultChecked={this.state.data ? this.YesNo(
                                    this.state.data.purchase_order_required
                                ) : false}
                                id="purchase_order_required"
                                onClick={this.handleCChange}
                            />
                        }
                        label="Purchase Order Required"
                    />
                </Grid>

                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h6" color="inherit">
                        Notes
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        multiline
                        rows="3"
                        variant="outlined"
                        id="client_info_note"
                        name="client_info_note"
                        label="General Notes"
                        fullWidth
                        className={classes.textField}
                        value={this.state.data? this.state.data.client_info_note : ''}
                        onChange={this.handleChange}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        multiline
                        rows="3"
                        variant="outlined"
                        id="at_risk_comments"
                        name="at_risk_comments"
                        label="At Risk Comments"
                        fullWidth
                        className={classes.textField}
                        value={this.state.data ? this.state.data.at_risk_comments  : ''}
                        onChange={this.handleChange}
                    />
                </Grid>
            </FormWrapper>

            //            </Grid>
            //         </Card>
            //     </Grid>
            // </Grid>
        )
    }
}

ClientInfoForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ClientInfoForm)
