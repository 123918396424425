import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Quotes from '../../Company/Quotes/Quotes'
import Opportunities from '../../Company/Opportunities/Opportunities'
import Grid from '@material-ui/core/Grid'
import WorkOrders from '../../Company/WorkOrders/WorkOrders'
import List from '@material-ui/core/List'
import Products from '../../Company/Products/Products'
import Finance from '../../Company/Finance/Finanace'
import { positions, minHeight } from '@material-ui/system'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'


function TabContainer({children}) {
    //console.log(children)
    return (
        <Typography component="div" style={{ padding: 8 * 3 }}>
            {children}
        </Typography>
    )
}

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },
    selected: {
        backgroundColor: theme.palette.secondary.main,
    }
})

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,  
}
class BottomTabs extends React.Component {
    constructor(props){
        super(props)
        this.state= {
            first: true,
            value: 1
        }
    }

    handleChangeIndex = (event) =>{
        event.persist()
        //console.log(event)
        this.setState({value: this.tabFinder(event.target.textContent), first: false})
    }

    tabFinder = tab =>{
        switch(tab){
            case('Opportunities'):
                return 0
            case('Quotes'):
                return 1
            case('Work Orders'):
                return 2
            case('Products'):
                return 3
            case('Finance'):
                return 4
        }
    }

    render(){
        const props = this.props //normally wouldn't do this but, converted bottom tabs from func to class so it was easiest
        const { classes }  = props
        //const { theme } = useTheme()
        
        let value = this.state.value
        return (
        <div 
            //className={classes.root}
        >
            <Card>
            <AppBar position="static" elevation={0}>
                <Tabs
                    //className={classes.bar}
                    value={value}
                    onChange={this.handleChangeIndex}
                    textColor="white"
                    variant="fullWidth"
                    indicatorColor='secondary'
                    
                >
                    {/* <Tab label="Financials" /> */}
                    {/* <Tab label="Opportunities" className={(value === 0 ? classes.selected : null)} /> */}
                    <Tab  label="Opportunities" className={(value === 0 ? classes.selected : null)}  />
                    <Tab  label="Quotes"className={(value === 1 ? classes.selected : null)}   />
                    <Tab  label="Work Orders" className={(value === 2 ? classes.selected : null)}  />
                    <Tab  label="Products" className={(value === 3 ? classes.selected : null)}  />
                    {/*<Tab label="Finance"/>
                    <Tab disabled />
                    <Tab disabled />
                    <Tab disabled />
                    <Tab disabled /> */}
                </Tabs>
            </AppBar>
            <SwipeableViews
                //Animate height never worked on first render, it would cover up like half the component then work as soon as you change tabs,
                // so it is false on first render then after you switch tabs it is true
                //It still doesnt work when the components height increased like hitting the show more button
                //animateHeight={!this.state.first}
                //axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={this.handleChangeIndex}
                
            >
                {/* <TabContainer dir={theme.direction}>Financials</TabContainer> */}

                <TabContainer
                   // dir={theme.direction} 
                >
                    
                        <Opportunities
                            handleFormOpen={props.handleFormOpen}
                            setFormData={props.setFormData}
                            crmData={props.crmData}
                            index={value}
                            loading={props.loading}
                        />
                    
                </TabContainer>

                <TabContainer
                    //dir={theme.direction} 
                >
                    
                        <Quotes
                            handleFormOpen={props.handleFormOpen}
                            setFormData={props.setFormData}
                            crmData={props.crmData}
                            index={value}
                            update={props.loading}
                        />
                    
                </TabContainer>

                <TabContainer 
                    //dir={theme.direction}
                >
                    {props.loading !== 'WorkOrders' && (
                        <WorkOrders
                            handleFormOpen={props.handleFormOpen}
                            setFormData={props.setFormData}
                            crmData={props.crmData}
                            index={value}
                        />
                    )}
                </TabContainer>

                <TabContainer 
                    //dir={theme.direction}
                >
                    
                        <Products
                            handleFormOpen={props.handleFormOpen}
                            setFormData={props.setFormData}
                            crmData={props.crmData}
                            index={value}
                            loading={props.loading}
                        />
                    
                </TabContainer>
                <TabContainer
                    //dir={theme.direction}
                >
                    {props.loading !== 'Finance' && (
                        <Finance/>
                    )}
                </TabContainer>
            </SwipeableViews>
            </Card>
        </div>
    )}
}
BottomTabs.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(BottomTabs)
