import React from 'react'
import PropTypes from 'prop-types'
import { PostData } from '../../../services/PostData'
import { GetData } from '../../../services/GetData'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
})

class AssociationInfo extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            value: false
        }
    }

    handleChange = () => {
        this.setState({ value: !this.state.value }, () => {
            const data = {
                action: 'toggle_association',
                hug_schema: 'hug_datavoice',
                crmId: this.props.crmId,

                value: this.state.value,
            }
    
            PostData('CRM/ajax/association/ajaxAssociation.php', data)
        })
    }

    componentWillMount() {     
        console.log(this.props)   
        const data = {
            action: 'get_associations',
            hug_schema: 'hug_datavoice',
            crmId: this.props.crmId,
        }

        PostData('CRM/ajax/association/ajaxAssociation.php', data).then(response => {
            if (response.associations && response.associations.length > 0) {
                this.setState({ value: true })
            }
        })
    }

    render() {   
        const { classes } = this.props

        return (
            <Grid container spacing={1}>
                <Grid item xs={2}>
                    <Typography
                        component="h6"
                        color="inherit"
                        className={classes.textField}
                    >
                        APPA
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        select
                        fullWidth
                        name={'APPA'}
                        className={classes.textField}
                        onChange={this.handleChange}
                        value={this.state.value}
                    >
                        <MenuItem key={false} value={false}>
                            No
                        </MenuItem>
                        <MenuItem key={true} value={true}>
                            Yes
                        </MenuItem>
                    </TextField>                
                </Grid>
                <Grid item xs={6}></Grid>
                {/* <Grid item xs={12}>
                    <Divider variant="fullwidth" />
                </Grid> */}
            </Grid>
        )
    }
}

AssociationInfo.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AssociationInfo)
