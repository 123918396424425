import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const styles = theme => ({
    typography: {
        padding: theme.spacing(2),
    },
})

class SaveWarning extends Component {    
    render() {
        const { classes } = this.props
        let message = 'Please fill in the highlighted fields to save'
        if(this.props.customMessage){
            message = this.props.customMessage
        }

        if (this.props.invalid) {
            return (
                <Paper
                    style={{
                        backgroundColor: '#f44336',
                        color: 'white',
                    }}
                >
                    <Typography className={classes.typography}>
                        {message}
                    </Typography>
                </Paper>
            )
        } else {
            return ''
        }
    }
}

SaveWarning.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SaveWarning)