import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
//import { styles } from '../../Layout/styles'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'
import FullScreenDialog from '../../Reusable/FullScreenDialog'

import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import { CardMedia } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import MenuItem from '@material-ui/core/MenuItem'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { faGripHorizontal } from '@fortawesome/pro-light-svg-icons'
import { faGripLines } from '@fortawesome/pro-light-svg-icons'
import NumberFormat from 'react-number-format'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },

    lower: {
        textTransform: 'capitalize',
        padding: 0,
        margin: 0,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    add: {
        padding: 7,
        marginRight: 5,
    },
    showMore: {
        width: '100%',
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    typo: {
        paddingTop: 4,
        paddingLeft: 18,
    },
    drop: {
        //paddingBottom: 3,
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        width: '98%',
        //background: 'rgb(248,248,248)',
    },
    highlight: {
        cursor:'pointer',
    },
})

function Transition(props) {
    return <Slide direction="up" {...props} />
}

class WorkOrders extends Component {
    constructor(props) {
        super(props)

        this.state = {
            workOrder: [],
            more: false,
            open: false,
            dropdown: [],
            filter: {
                status: 'OPEN',
                year: 'ALL',
            },
            gridView: true,
            bubbleView: false,
            totalNumber: 0,
            sortNumber: 0,
        }

        this.handleToggle = this.handleToggle.bind(this)
        //this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentWillMount() {
        this.getDropdown()
        if (this.props.crmData) {
            const data1 = {
                action: 'getCustomersWorkOrders',
                crm_id: this.props.crmData.crm_id,
                wo_status_filter: "",
                sort_direction: 'ASC',
                sort_column: 'work_order_no',
                hug_schema: 'hug_'+(JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                userid: JSON.parse(sessionStorage.getItem('userData')).userid,
                sbu: JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,
            }

            PostData('Production/ajax/ajaxWIPLIST_2018.php', data1).then(
                response => {
                    if (response.result) {
                        this.setState({ workOrder: response.resultSet })
                    }
                }
            )
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.crmData && this.state.crmData !== nextProps.crmData) {
            this.getDropdown()
            this.setState({ crmData: nextProps.crmData })
            const data = {
                action: 'getCustomersWorkOrders',
                crm_id: nextProps.crmData.crm_id,
                wo_status_filter: "",
                sort_direction: 'ASC',
                sort_column: 'work_order_no',
                hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                userid: JSON.parse(sessionStorage.getItem('userData')).userid,
                sbu: JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,
            }

            PostData('Production/ajax/ajaxWIPLIST_2018.php', data).then(
                response => {
                    if (response.result) {
                        this.setState({ workOrder: response.resultSet })
                    }
                }
            )
        }
    }

    //if recordid =undefined, will open new work order
    handleOpen = recordid => {
        let WOdetail
        if (recordid === undefined) {
            recordid = {
                work_order_no: '',
                order_number: '',
                work_order_date: '',
                description: '',
                wo_amount: '',
                wo_type: '',
                wo_status: '',
                booking_type: '',
                billable: '',
                invoiced: '',
            }
            this.props.setFormData(
                {  WOdetails: {} },
                'WorkOrders'
            )
        } else {
            const userData = JSON.parse(sessionStorage.getItem('userData'))

            const data2 = {
                action: 'getWOdetails',
                sort_direction: 'ASC',
                sort_column: '',
                hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                userid: userData.userid,
                sbu: userData.strategic_business_unit,
                crm_id: this.props.crmData.crm_id,
                work_order_no: recordid.work_order_no,
            }

            PostData('Production/ajax/ajaxWORK_ORDER_details.php', data2).then(
                response => {
                    if (response.result) {
                        WOdetail = response
                        this.props.setFormData(
                            { WOdetails: WOdetail, num: recordid.work_order_no },
                            'WorkOrders'
                        )
                    }
                }
            )
        }
    }

    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'WORK_ORDER',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }

    //Shows dropdowns for the filters
    viewFilterDropdown = (control, id, name) => {
        const { classes } = this.props
        let list = []
        list.push({name: 'All', code: 'ALL'})

        if(this.state.dropdown.length > 0){
            this.state.dropdown.forEach(item =>{
                if (item.control_code == control ){
                    list.push({name: item.item_name, code: item.item_code})
                }
            })
        }
        
        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                className={classes.drop}
                onChange={this.handleFilterChange}
                defaultValue="ALL"
                value={this.state.filter[id]}
                InputProps={{
                    disableUnderline: true,
                }}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    //Changes filter values
    handleFilterChange = event => {
        let change = this.state.filter
        change[event.target.name] = event.target.value
        this.setState({ filter: change })
    }

    handleClose = () => {
        this.setState({ open: false })
    }

    handleToggle = () => {
        this.setState({ more: !this.state.more })
    }

    //Switches to bubbleView
    handleView1Change = () => {
        if (this.state.bubbleView == false) {
            this.setState({ bubbleView: true, gridView: false })
        }
    }
    //Switches to grid view
    handleView2Change = () => {
        if (this.state.gridView == false) {
            this.setState({ bubbleView: false, gridView: true })
        }
    }

    //Creates the list of work orders depending on the filters
    getWorkOrderList = () => {
        const { classes } = this.props
        if (this.state.workOrder && this.state.workOrder.length > 0) {
            let sort = []

            this.state.workOrder.forEach(order => {

                
                if (this.state.filter.year == 'ALL') {
                    if (this.state.filter.status === 'ALL' || this.state.filter.status == order.wo_status) {
                        sort.push(order)
                    }
                } else if (
                    (this.state.filter.status === 'ALL' ||
                    this.state.filter.status == order.wo_status) &&
                    this.state.filter.year ==
                        order.work_order_date.substring(0, 4)
                ) {
                    sort.push(order)
                }
            })
            //this.state.totalNumber = sort.length
            

            if (sort.length != 0) {
                if (this.state.bubbleView == true) {
                    if (this.state.more) {
                        return (
                            <React.Fragment>
                                {sort.map(
                                    workOrder => (
                                        <Grid item xs={2}>
                                            <Button
                                                onClick={() =>
                                                    this.handleOpen(workOrder)
                                                }
                                            >
                                                <ListItem>
                                                    <ListItemText
                                                        className={
                                                            classes.lower
                                                        }
                                                        primary={
                                                            workOrder.work_order_no
                                                        }
                                                        secondary={
                                                            workOrder.wo_description
                                                        }
                                                    />
                                                </ListItem>
                                            </Button>
                                        </Grid>
                                    ),
                                    this
                                )}
                                <Grid item xs={true} />
                            </React.Fragment>
                        )
                    } else {
                        let workOrderList = []
                        let loopIterator
                        if (sort.length <= 5) {
                            loopIterator = sort.length
                        } else {
                            loopIterator = 5
                        }
                        this.state.sortNumber = loopIterator

                        for (let i = 0; i < loopIterator; i++) {
                            let workOrder = sort[i]

                            workOrderList.push(
                                <Grid item xs={2}>
                                    <Button
                                        onClick={() =>
                                            this.handleOpen(workOrder)
                                        }
                                    >
                                        <ListItem>
                                            <ListItemText
                                                className={classes.lower}
                                                primary={
                                                    workOrder.work_order_no
                                                }
                                                secondary={
                                                    workOrder.wo_description
                                                }
                                            />
                                        </ListItem>
                                    </Button>
                                </Grid>
                            )

                            workOrderList[workOrderList.length + 1] = (
                                <React.Fragment>
                                    <Grid item xs={true} />
                                </React.Fragment>
                            )
                        }

                        return <React.Fragment>{workOrderList}</React.Fragment>
                    }
                } else {
                    
                    let workOrderList =[]
                    let newSort = []
                
                    if(sort.length > 4){
                        if (!this.state.more) {
                            for (let i = 0; i < 5; i++){
                                newSort[i] = sort[i]
                            }
                            sort = newSort
                        }        
                    }
                    
                        sort.forEach(workOrder =>{
                        
                    
                            workOrderList.push(

                        <TableRow onClick={() => this.handleOpen(workOrder)}>
                            <TableCell className={classes.highlight}>{workOrder.work_order_no}</TableCell>
                            <TableCell>{workOrder.name}</TableCell>
                            <TableCell>{workOrder.work_order_date}</TableCell>
                            <TableCell>{workOrder.wo_status}</TableCell>
                            <TableCell><NumberFormat value={workOrder.wo_amount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></TableCell>
                            <TableCell><NumberFormat value={workOrder.amount_invoiced} displayType={'text'} thousandSeparator={true} prefix={'$'} allowEmptyFormatting={true}
                                    fixedDecimalScale={true} decimalScale={2} /></TableCell>
                        </TableRow>
                                )
                            })
                            this.state.sortNumber = workOrderList.length
                            
                            return <React.Fragment>{this.viewGridView(workOrderList)}</React.Fragment>
                    
                    
                }
            }
        } else {
            return ''
        }
    }

    //Makes the table for grid view
    viewGridView = list => {
        const { classes } = this.props
            return (
                <Table className={classes.table} size="small" >
                    <TableHead>
                        <TableRow>   
                            <TableCell>Number</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Created</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Billed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{list}</TableBody>
                </Table>
            )
    }

    getMoreButton() {
        const { classes } = this.props
        let sort = []

        this.state.workOrder.forEach(order => {
            if (this.state.filter.year == 'ALL') {
                if (this.state.filter.status == order.wo_status) {
                    sort.push(order)
                }
            } else if (
                this.state.filter.status == order.wo_status &&
                this.state.filter.year ==
                    order.work_order_date.substring(0, 4)
            ) {
                sort.push(order)
            }
        })

        if (this.state.workOrder && sort.length >= 5) {
            return (
                <Grid container xs={12}>
                    <Button
                        className={classes.showMore}
                        onClick={this.handleToggle}
                    >
                        {this.state.more  ? (
                            <div>
                                <FontAwesomeIcon
                                    icon={faChevronUp}
                                    //size="2x"
                                />
                                &nbsp;1 to {sort.length} of {sort.length}
                            </div>
                        ) : (
                            <div>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    //size="2x"
                                />
                                &nbsp;1 to 5 of {sort.length}
                            </div>
                        )}
                    </Button>
                </Grid>
            )
        }
    }

    render() {
        const { classes } = this.props
        
        if (this.props.index !== 2) {
            return ''
        }

        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={12}>
                        {/* <Card> */}
                        
                            {/*<Grid
                                container
                                className={classes.secondary}
                                xs={12}
                            >
                                <Grid item>
                                    <Typography
                                        component="h1"
                                        variant="h6"
                                        color="inherit"
                                        className={classes.textField}
                                    >
                                        Work Orders
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.addGrid}>
                                    <IconButton
                                        className={classes.add}
                                        onClick={() => this.handleOpen(0)}
                                        
                                    >
                                        <FontAwesomeIcon
                                            icon={faPlusCircle}
                                            size="1x"
                                            color="white"
                                        />
                                    </IconButton>
        </Grid>*/}
                            
                            <Grid
                                container
                                spacing={1}
                                className={classes.sort}
                            >
                                <Grid item>
                                    <Typography
                                        component="h6"
                                        color="inherit"
                                        className={classes.typo}
                                    >
                                        Status:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {this.viewFilterDropdown(
                                        'SORT_STATUS',
                                        'status',
                                        'Status'
                                    )}
                                </Grid>

                                <Grid item>
                                    <Typography
                                        component="h6"
                                        color="inherit"
                                        className={classes.typo}
                                    >
                                        Year:
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    {this.viewFilterDropdown(
                                        'SORT_YEAR',
                                        'year',
                                        'Year'
                                    )}
                                </Grid>
                                <Grid item>
                                    <Tooltip title={'Bubble View'}>
                                        <IconButton
                                            onClick={this.handleView1Change}
                                            size="small"
                                            className={classes.add}
                                            style={this.state.bubbleView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripHorizontal}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={'Grid View'}>
                                        <IconButton
                                            onClick={this.handleView2Change}
                                            size="small"
                                            className={classes.add}
                                            style={this.state.gridView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripLines}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>  
                            </Grid>

                            <Divider variant="middle" />
                            <List className={classes.root}>
                                <Grid
                                    container
                                    spacing={0}
                                    justify="space-evenly"
                                >
                                    {this.getWorkOrderList()}
                                </Grid>
                            </List>
                            {this.getMoreButton()}
                            
                        {/* </Card> */}
                    </Grid>
                </Grid>

                {/*<Dialog
                    //fullScreen
                    fullWidth
                    maxWidth="lg"
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    {/* <AppBar >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    onClick={this.handleClose}
                                    aria-label="Close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <div className={classes.flex} />
                                <Button
                                    color="inherit"
                                    onClick={this.handleSave}
                                >
                                    save
                                </Button>
                            </Toolbar>
                        </AppBar> }

                    <ordersForm workOroderData={this.state.ordersData} />
                    </Dialog>*/}
            </React.Fragment>
        )
    }
}
WorkOrders.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(WorkOrders)
