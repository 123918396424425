import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Card, TextField, Grid, Divider } from '@material-ui/core'

import { PostData } from '../../services/PostData'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    lower: {
        textTransform: 'capitalize',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    pullRight: {
        float: 'right',
    },
    evenPadding: {
        padding: 10,
    }
})

class NotesSinceDate extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            sinceDate: null,
            notes: [],
        }
    }

    handleChange = event => {
        this.setState({ sinceDate: event.target.value })

        let userData = JSON.parse(sessionStorage.getItem('userData'))
        const data = {
            action: 'getNotesSinceDate',
            date: event.target.value,
            sbu: userData === null ? null : userData.strategic_business_unit,
        }

        PostData('CRM/ajax/ajaxContact_Notes.php', data).then(response => {
            if (response.result) {
                let notes = response.ContactNotes.filter(note => !note.subject.startsWith('PDF'))
                this.setState({ notes: notes })
            }
        })
    }

    render() {
        const { classes } = this.props

        return (
            <>
                <Grid>
                    <Grid item xs="3">
                        <TextField 
                            InputLabelProps={{ shrink: true }}
                            name='sinceDate'
                            type='date'
                            label='Show Notes Since'
                            onChange={this.handleChange}
                            value={this.state.sinceDate}
                            className={classes.textField}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                {this.state.notes && this.state.notes.map(note => (
                    <Card className={classes.evenPadding} style={{marginTop: 10}}>
                        <h2>{note.contact_by+': '+note.subject}</h2>
                        <h4>{'Utility: '+note.company}</h4>
                        <h4>{'Type: '+note.contact_type+(note.lead_source ? ', '+note.lead_source : '')}</h4>
                        <h4>{'Time: '+note.contact_time}</h4>
                        <div dangerouslySetInnerHTML={{
                            __html: note.notes,
                        }} />
                    </Card>
                ))}
            </>
        )
    }
}

NotesSinceDate.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(NotesSinceDate)
