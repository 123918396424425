import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import {
    Checkbox, 
    Modal,
    Paper,
    Menu,
    TableCell,
    IconButton,
    Icon,
    MenuItem,
    ListItem,
    List,
    ListItemText,
    InputBase,
    Tooltip,
    Grid,
    Button,
    Typography,
    Collapse,
    TextField,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTint,
    faTintSlash,
    faLightbulbExclamation,
    faWifi,
    faNetworkWired,
    faTvRetro,
    faSatelliteDish,
    faTrashAlt,
    faPhoneVolume,
    faWaterLower,
    faThunderstorm,
    faSun,
    faLightbulbSlash,
    faLightbulb,
    faBurn,
    faEdit,
} from '@fortawesome/pro-regular-svg-icons'
import StreetLight from '../../../images/icons/street-light.ico'
import SearchIcon from '@material-ui/icons/Search'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    lower: {
        textTransform: 'capitalize',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    pullRight: {
        float: 'right',
    },
})

class CustomerList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            searchField: '',
            columns: [],
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
        }

        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
    }

    handleSearchChange = e => {
        this.setState({ searchField: e.target.value })
    }

    componentWillMount() {
        this.search('')
        this.getDropdowns()
    }
    getDropdowns = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'CONTACT_LIST',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }

    openContactForm = contact =>{
        const data = {
            action: 'editUtilityPersonnelInfo',
            recordid: contact.recordid,
        }

        PostData('CRM/ajax/ajaxCRMUtilityPersonnel2018.php', data).then(
            response => {
                this.props.setFormData(response.rowUtilPersonnelRtn, 'Contact')
            }
        )
    }

    handleFilterChange = e => {
        let value = e.target.value
        let name = e.target.name

        this.setState({ sbu: value })
        this.search(this.state.searchValue, value)
    }

    handleSearchChange = e => {
        e.persist()
        this.setState(() => {
            return { searchField: e.target.value }
        })
    }

    handleSearchClick = () => {
        this.setState({ searchValue: this.state.searchField })
        this.search(this.state.searchField)
    }
    search = (searchVal, sbu = this.state.sbu) => {
        const data = {
            action: 'searchContact',
            sbu: sbu,
            searchVal: searchVal,
        }

        PostData('MarketingReports/ajax/ajaxsbu_contactsList.php', data).then(response => {
            this.setState({ columns: response.resultSet })
        })
    }

    render() {
        const { classes } = this.props

        const rows = [
            {
                id: 'crm_id',
                numeric: false,
                disablePadding: true,
                label: 'CRM ID',
            },
            {
                id: 'company',
                numeric: true,
                disablePadding: false,
                label: 'Company',
            },
            {
                id: 'name',
                numeric: true,
                disablePadding: false,
                label: 'Name',
            },
            {
                id: 'title',
                numeric: true,
                disablePadding: false,
                label: 'Title',
            },
            {
                id: 'phonenumber',
                numeric: true,
                disablePadding: true,
                label: 'Phone Number',
            },
            {
                id: 'email',
                numeric: true,
                disablePadding: false,
                label: 'Email',
            },
            {
                id: 'key_contact_list',
                numeric: true,
                disablePadding: false,
                label: 'Key Contact',
            },
        ]

        return (
            <EnhancedTable
                className={classes.secondary}
                rows={rows}
                columns={this.state.columns}
                showHeader={true}
                link={this.openContactForm}
                toolbar={
                    <CustomToolbar
                        classes={classes}
                        handleSearchClick={this.handleSearchClick}
                        handleSearchChange={this.handleSearchChange}
                        handleFilterChange={this.handleFilterChange}
                        sbu={this.state.sbu}
                        dropdown={this.state.dropdown}
                    />
                }
                tableRow={<CustomTableCells openClientInfoForm={this.openClientInfoForm}/>}
            />
        )
    }
}

class CustomTableCells extends React.Component {
    render() {
        const n = this.props.n

        return (
            <React.Fragment>
                <TableCell padding="checkbox" />
                <TableCell
                    style={{ cursor: 'pointer' }}
                    component="th"
                    scope="row"
                    padding={'default'}
                >
                    {n.crm_id}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.company}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.name}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.title}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.phonenumber}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.email}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    <Checkbox checked={n.key_contact_list === 'Y'} disabled/>
                </TableCell>
                
            </React.Fragment>
        )
    }
}

class CustomToolbar extends React.Component {
    render() {
        let sbu = []
        this.props.dropdown &&
            this.props.dropdown.forEach(dropdown => {
                if (dropdown.control_code === 'SBU') {
                    sbu.push(dropdown)
                }
            })

        const { classes } = this.props
        return (
            <React.Fragment>
                <Grid spacing={5} container>
                    <Grid item style={{ paddingTop: 5, marginTop: 35 }}>
                        <InputBase
                            className={this.props.classes.input}
                            placeholder={'Search'}
                            onChange={this.props.handleSearchChange}
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    this.props.handleSearchClick()
                                    ev.preventDefault()
                                }
                            }}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 10 }}>
                        <IconButton
                            className={this.props.classes.iconButton}
                            aria-label={'Search'}
                            onClick={this.props.handleSearchClick}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        sm={5}
                        md={4}
                        lg={3}
                        xl={2}
                        style={{ marginTop: 5 }}
                    >
                        <TextField
                            value={this.props.sbu}
                            name="sbu"
                            id="sbu"
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            label="Strategic Business Unit"
                        >
                            {sbu &&
                                sbu.map(item => (
                                    <MenuItem
                                        value={item.item_name}
                                        key={item.item_name}
                                    >
                                        {item.item_name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

CustomerList.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CustomerList)