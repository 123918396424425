import React from 'react'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import Drawer from '@material-ui/core/Drawer'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { styles } from '../styles'
import NoteForm from '../../Company/Notes/NoteForm'
import ContactForm from '../../Company/Contacts/ContactForm'
import ServiceForm from '../../Company/Services/ServiceForm'
import EditServiceForm from '../../Company/Services/EditServiceForm'
import SystemsForm from '../../Company/Systems/SystemsForm'
import EditSystemsForm from '../../Company/Systems/EditSystemsForm'
import QuotesForm from '../../Company/Quotes/QuotesForm'
import WorkOrdersForm from '../../Company/WorkOrders/WorkOrdersForm'
import OpportunitiesForm from '../../Company/Opportunities/OpportunitiesForm'
import SupportDatesForm from '../../Company/SupportDates/SupportDatesForm'
import AddCRMForm from '../../CompanySearch/AddCRMForm'
import DropdownForm from '../../Company/Maintenance/DropdownForm'
import AliasForm from '../../Company/Alias/AliasForm'
import ProductsForm from '../../Company/Products/ProductsForm'
import ClientInfoForm from '../../Company/ClientInfo/ClientInfoForm'
import AccountSettings from '../TopBar/AccountSettings'

class RightDrawer extends React.Component {
    getFormType = () => {
        switch (this.state.props.formType) {
            case 'Note':
                return (
                    <NoteForm
                        handleFormClose={this.props.handleFormClose}
                        updateComponent={this.props.updateComponent}
                        formData={this.state.props.formData}
                        crmData={this.state.props.crmData}
                    />
                )
            case 'Contact':
                return (
                    <ContactForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                        crmData={this.state.props.crmData}
                    />
                )
            case 'Service':
                return (
                    <ServiceForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                        crmData={this.state.props.crmData}
                    />
                )
            case 'EditService':
                return (
                    <EditServiceForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                        crmData={this.state.props.crmData}
                    />
                )
            case 'System':
                return (
                    <SystemsForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                    />
                )
            case 'EditSystem':
                return (
                    <EditSystemsForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                    />
                )
            case 'SupportDate':
                return (
                    <SupportDatesForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                    />
                )
            case 'Quotes':
                return (
                    <QuotesForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                        handleFormOpen={this.props.handleFormOpen}
                        setFormData={this.props.setFormData}
                        crmData={this.state.props.crmData}
                    />
                )
            case 'WorkOrders':
                return (
                    <WorkOrdersForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                    />
                )
            case 'Opportunities':
                return (
                    <OpportunitiesForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                        crmData={this.state.props.crmData}
                        handleFormOpen={this.props.handleFormOpen}
                        setFormData={this.props.setFormData}
                    />
                )

            case 'addCRM':
                return (
                    <AddCRMForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                    />
                )
            case 'Alias':
                return (
                    <AliasForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                        crmData={this.state.props.crmData}
                    />
                )
            case 'ClientInfo':
                return (
                    <ClientInfoForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                    />
                )
            case 'Products':
                return (
                    <ProductsForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                    />
                )
            case 'DropdownForm':
                return (
                    <DropdownForm
                        handleFormClose={this.props.handleFormClose}
                        formData={this.state.props.formData}
                        updateComponent={this.props.updateComponent}
                    />
                )
            case 'Account':
                return (
                    <AccountSettings
                        handleFormClose={this.props.handleFormClose}
                        formData={this.props.formData}/>
                )
            default:
                return ''
        }
    }
    componentWillMount(){
        this.setState({ crmData: this.props.crmData, props: this.props })
    }
    
    componentWillReceiveProps(nextProps) {

        this.setState({ crmData: nextProps.crmData, props: nextProps })
    }

    render() {
        const { classes } = this.props
        const open = this.state.props.open
        const width = this.state.props.width
        //console.log(this.state.props.formData)

        if (this.state.props.formType === 'Quotes' || this.state.props.formType === 'WorkOrders' || width === 'xs' || width === 'sm') {
            return (
                <Dialog open={open} maxWidth={'xl'}>
                    <DialogContent style={{ paddingLeft: 5, marginLeft: 5, paddingRight: 5, marginRight: 5}}>
                        {open && this.getFormType()}
                    </DialogContent>
                </Dialog>
            )
        }

        return (
            <Drawer
                className={classes.drawer}
                variant="permanent"
                anchor="right"
                open={open}
                classes={{
                    paper: classNames(
                        this.props.classes.rightDrawerPaper,
                        !open && this.props.classes.rightDrawerPaperClose
                    ),
                }}
            >
                <div />
                {open && this.getFormType()}
            </Drawer>
        )
    }
}

RightDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(RightDrawer)
