import React from 'react'
import { Redirect } from 'react-router-dom'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'

import CssBaseline from '@material-ui/core/CssBaseline'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Slide from '@material-ui/core/Slide'

import { styles } from '../Layout/styles'
import TopBar from '../Layout/TopBar/TopBar'
import LeftDrawer from '../Layout/LeftDrawer/LeftDrawer'
import MainContainer from '../Layout/Main/MainContainer'
import RightDrawer from './RightDrawer/RightDrawer'
import { PostData } from '../../services/PostData'

class Layout extends React.Component {
    state = {
        main: this.props.main,
        title: this.props.title,
        drawerOpen: false, // Whether or no the drawer is open
        formOpen: false,
        crmData: {},
        formData: {},
        formType: '',
        location: this.props.location,
        loading: '',
        update: '',
    }

    // For setting what's in the main component
    setMain = main => {
        this.setState({ main: main })
        if (main === 'CompanySearch') {
            this.setState({ crmData: {} })
        }
    }

    setCrmData = crmData => {
        const userData = JSON.parse(
            sessionStorage.getItem('userData'))
        if ((typeof crmData === 'string' || typeof crmData === 'number') && userData) {
            const data = {
                action: 'getStartupData2', //faster than getStartUpData, 2 just has less data
                crm_id: crmData,
                hug_schema: 'hug_' +(JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                strategic_business_unit: JSON.parse(
                    sessionStorage.getItem('userData')
                ).strategic_business_unit,
            }
            

            PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {
                this.setState({ crmData: response.CRMdata })
            })
        } else {
            this.setState({ crmData: crmData })
        }
    }

    // Closes the drawer. Used by a button in the LeftDrawer
    handleDrawerClose = () => {
        this.setState({ drawerOpen: false })
    }

    // Opens the drawer. Used by a button in the TopBar
    handleDrawerOpen = () => {
        this.setState({ drawerOpen: true })
    }

    setFormData = (formData, formType) => {
        let state = this.state
        state.formOpen = false
        state.formType = formType
        state.formData = formData
        state.drawerOpen = false
        this.setState(state, () => {
            if (formType === 'Quotes' || formType === 'WorkOrders') {
                this.setMain(formType+'Form')
            } else {
                this.setState({ formOpen: true })
            }
        })
    }

    // Opens the form
    handleFormOpen = formType => {
        let state = this.state
        state.formOpen = false
        state.formType = formType
        state.drawerOpen = false
        this.setState(state)
        this.setState({ formData: {crmData: this.state.crmData}, formOpen: true })
    }

    // Closes the form
    handleFormClose = (reload = false) => {
        let state = this.state
        state.formOpen = false
        state.formData = ''
        state.formType = ''
        this.setState(state)

        if (reload) {
            this.setMain('Company')
        } 
    }

    updateComponent = (component, formData = {}) => {
        //console.log(component)
        this.setState({ loading: component })
        this.setState({ loading: '' })
        this.setState({ update: component })
        if(component === 'Opportunities' && formData){
            this.setState({formData: formData})
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ main: nextProps.main, title: nextProps.title })
        this.setCrmData(nextProps.match.params.id)
        if(nextProps && nextProps.location && nextProps.location.state && nextProps.location.state.closeForm){
            this.handleFormClose()
        }
    }
    componentWillMount(){
        this.setState({main: this.props.main})

        //console.log(this.props)
        this.setCrmData(this.props.match.params.id)
        if(this.props && this.props.location && this.props.location.state && this.props.location.state.closeForm){
            this.handleFormClose()
        }   
    }

    componentDidMount() {
        window.onpopstate = this.handleFormClose   
        
    }

    componentWillUnmount() {
        window.onpopstate = null;
        
    }

    render() {
        let userData = sessionStorage.getItem("userData")
        if (userData === undefined || userData === null) {
            return <Redirect to={'/login'} />
        }

        const companyId = this.props.match.params.id
        const { classes } = this.props
        const { width } = this.props
        //console.log(this.state)
        return (
            <div className={classes.root} >
                <CssBaseline />
                <Grid>
                    <div style={{width: '100%', height: 50}}/>
                    <TopBar
                        crmData={this.state.crmData}
                        handleDrawerOpen={this.handleDrawerOpen}
                        open={this.state.drawerOpen}
                        handleFormClose={this.handleFormClose}
                        setFormData={this.setFormData}
                        setCrmData={this.setCrmData}
                        title={this.state.title}
                        updateComponent={this.updateComponent}
                        main={this.state.main}
                        setMain={this.setMain}
                    />
                </Grid>
                
                <LeftDrawer
                    classes={classes}
                    setMain={this.setMain}
                    handleDrawerClose={this.handleDrawerClose}
                    open={this.state.drawerOpen}
                    updateComponent={this.updateComponent}
                    main={this.state.main}
                    setCrmData={this.setCrmData}
                    setFormData={this.setFormData}
                    handleFormClose={this.handleFormClose}
                    history={this.props.history}
                    handleDrawerOpen={this.handleDrawerOpen}
                />
                <main style={{height: 50}}></main>
                <main className={classes.content}>
                    <Grid /*style={{marginTop: 50}}*/>
                        <MainContainer
                            main={this.state.main}
                            loading={this.state.loading}
                            companyId={companyId}
                            setCrmData={this.setCrmData}
                            setFormData={this.setFormData}
                            formOpen={this.state.formOpen}
                            handleFormOpen={this.handleFormOpen}
                            handleFormClose={this.handleFormClose}
                            handleDrawerClose={this.handleDrawerClose}
                            crmData={this.state.crmData}
                            updateComponent={this.updateComponent}
                            update={this.state.update}
                            formData={this.state.formData}
                            formType={this.state.formType}
                            setMain={this.setMain}
                        />
                    </Grid>
                </main>
                <RightDrawer
                    open={this.state.formOpen}
                    formData={this.state.formData}
                    formType={this.state.formType}
                    handleFormClose={this.handleFormClose}
                    updateComponent={this.updateComponent}
                    crmData={this.state.crmData}
                    setFormData={this.setFormData}
                    width={width}
                />
            </div>
        )
    }
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withWidth()(withStyles(styles)(Layout))
