//const drawerWidth = 240;
const drawerWidth = 320;
const rightDrawerWidth = 600;
//const rightDrawerLargeWidth = 930;

export const styles = theme => ({
    root: {
        display: "flex",
        backgroundColor: '#ffffff',
        overflow: 'hidden',
        height: '100%'
    },
    toolbar: {
        paddingRight: 24 // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: "flex",
        padding: "0 8px",
        alignItems: "center",
        //justifyContent: "flex-end",
        ...theme.mixins.toolbar
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36
    },
    menuButtonHidden: {
        display: "none"
    },
    title: {
        flexGrow: 1,
        textAlign: "center"
    },
    drawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        overflowX: "hidden",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        width: theme.spacing.unit * 7,
        // [theme.breakpoints.up("sm")]: {
        //     width: theme.spacing.unit * 9
        // }
    },
    rightDrawerPaper: {
        position: "relative",
        whiteSpace: "nowrap",
        width: rightDrawerWidth,
        top: 64,
        paddingTop: 5,
        height: '92vh',
        overflow: 'auto',
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    // rightDrawerLargePaper: {
    //     position: "relative",
    //     whiteSpace: "nowrap",
    //     width: rightDrawerLargeWidth,
    //     transition: theme.transitions.create("width", {
    //         easing: theme.transitions.easing.sharp,
    //         duration: theme.transitions.duration.enteringScreen
    //     })
    // },
    rightDrawerPaperClose: {
        display: "none",
        height: '100%',
        overflow: 'hidden'
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        padding: theme.spacing.unit * 3,
        height: "93vh",
        overflow: "auto",
        marginTop: 64
        

        
        //backgroundColor: theme.palette.background.main
    },
    chartContainer: {
        marginLeft: -22
    },
    tableContainer: {
        height: 320
    },
    h5: {
        marginBottom: theme.spacing.unit * 2
    },
    spacer: {
        flexGrow: 1
    },
    toolBarSpacer: {
        marginTop: theme.spacing.unit * 8.25
    },
});