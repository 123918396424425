import React from "react";
import ReactDOM from "react-dom";
import ReactToPdf from "react-to-pdf";

export default class Pdf extends React.Component {
    render() {
        return (
            <ReactToPdf>
                {({toPdf, targetRef}) =>  (
                    <div style={{width: 500, height: 500, background: 'red'}} onClick={toPdf} ref={targetRef}/>
                )}
            </ReactToPdf>
        )
    }
}