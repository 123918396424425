import React from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'

import Login from './components/Login/Login'
import Layout from './components/Layout/Layout'
import CampaignInquiry from './components/Campaigns/CampaignInquiry'
import CallList from './components/Campaigns/CallList'
import Pdf from './components/Reusable/Pdf'

const Routes = () => (
    <BrowserRouter basename="/">
        <Switch>
            <Route exact path="/" render={(props) => <Layout {...props} main={'CompanySearch'} />}/>
            <Route exact path="/login" component={Login}/>
            <Route exact path="/release-notes" render={(props) => <Layout {...props} main={'ReleaseNotes'} />} />
            <Route path="/companies" render={(props) => <Layout {...props} main={'CompanySearch'} />} />
            <Route path="/Quotes" render={(props) => <Layout {...props} main={'Quotes'} title='Quotes' />} />
            <Route path="/Tradeshows" render={(props) => <Layout {...props} main={'Tradshows'} title='Trade Shows' />} />
            <Route path="/CustomerList" render={(props) => <Layout {...props} main={'CustomerList'} title='Customer List' />} /> 
            <Route path="/ContactList" render={(props) => <Layout {...props} main={'ContactList'} title='Customer Contacts' />} /> 
            <Route path="/CallCampaign" render={(props) => <Layout {...props} main={'CallCampaign'} title='Call Campaign' />} />
            <Route path="/TotalFeesPerClient" render={(props) => <Layout {...props} main={'TotalFeesPerClient'} title='Total Fees Per Client' />} /> 
            <Route path="/OpportunityValuesByQuote" render={(props) => <Layout {...props} main={'OpportunityValuesByQuote'} title='Opportunity Values By Quote' />} />   
            <Route path="/NotesSinceDate" render={(props) => <Layout {...props} main={'NotesSinceDate'} title='Notes Since Date' />} />                                                                                                   
            <Route path="/company/:id/:contactId" render={(props) => <Layout {...props} main={'Company'} />} />
            <Route path="/company/:id" render={(props) => <Layout {...props} main={'Company'} />} />
            <Route path="/company-search" render={(props) => <Layout {...props} main={'CompanySearch'} />} />
            <Route path="/campaign-inquiry/:id" component={CampaignInquiry} />
            <Route path="/campaign-call-list/:campaignId/:stepId" component={CallList} />
            <Route path="/fix-services" render={(props) => <Layout {...props} main={'FixServices'} />} />
        </Switch>
    </BrowserRouter>
)

export default Routes
