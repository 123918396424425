import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { withStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import TextField from '@material-ui/core/TextField'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import Button from '@material-ui/core/Button'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import SupportDatesForm from './SupportDatesForm'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'

const styles = theme => ({
    root: {
        width: '100%',
    },
    paper: {
        marginTop: theme.spacing(3),
        width: '100%',
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
    },
    title: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        background: 'rgb(248,248,248)',
    },
    lastTable: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        background: 'rgb(248,248,248)',
        width: '97%',
    },
    /*addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
        height: '43px'
    },*/
    add: {
        padding: 5,
        margin: 1,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    input: {
        padding: '5px 5px',
        border: '2px solid rgb(232,232,232)',
        borderRadius: '2px',
        width: '97%',
        height: '82px',
        marginLeft: theme.spacing(1),
    },
    lowercase: {
        textTransform: 'none',
    },
    inputBox: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
})

function Transition(props) {
    return <Slide direction="up" {...props} />
}

class SupportDates extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            OMSData: {},
            IVRData: {}
        }

        this.handleOpen = this.handleOpen.bind(this)
    }

    componentWillMount() {
        const userData = JSON.parse(sessionStorage.getItem('userData'))

        const data = {
            action: 'Get_Maintenance_Record',
            //sbu_client_id: this.props.crmData.company,
            crm_id: this.props.crmData.crm_id,
            sbu: userData.strategic_business_unit,
            hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
        }
        this.setState({crmData: this.props.crmData})
        PostData('Support_Info/ajax/ajaxCustomerInfo.php', data).then(
            response => {
                if (response.result && response.result !== 'false') {
                    this.setState({ OMSData: response.RtnInfo.OMS, IVRData: response.RtnInfo.IVR, data: response.RtnInfo })   
                }
            }
        )
    }
    componentWillReceiveProps(nextProps){
        const userData = JSON.parse(sessionStorage.getItem('userData'))

        if(this.state.crmData !== nextProps.crmData){
            const data = {
                action: 'Get_Maintenance_Record',
                //sbu_client_id: nextProps.crmData.company,
                crm_id: nextProps.crmData.crm_id,
                sbu: userData.strategic_business_unit,
                hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
            }

            this.setState({crmData: nextProps.crmData})
            PostData('Support_Info/ajax/ajaxCustomerInfo.php', data).then(
                response => {   
                    if (response.result && response.result !== 'false') {
                        this.setState({ OMSData: response.RtnInfo.OMS, IVRData: response.RtnInfo.IVR, data: response.RtnInfo })
                    }
                }
            )
        }
    }

    handleOpen = () => {
        const data = {
            action: 'Get_Maintenance_Record',
            sbu_client_id: 'ZULU_HORIZONS',
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            //hug_schema: 'hug_horizons',
        }

        PostData('Support_Info/ajax/ajaxCustomerInfo.php', data).then(
            response => {
                this.props.setFormData(response.RtnInfo, 'SupportDate')
            }
        )
    }

    render() {
        const { classes } = this.props
        if (this.state.data !== undefined && this.state.OMSData !== undefined && this.state.IVRData !== undefined){
        return (
            <React.Fragment>
                <Card>
                    <div className={classes.secondary}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs={11}>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="inherit"
                                    className={classes.title}
                                >
                                    Support Dates
                                </Typography>
                            </Grid>
                            <Grid item className={classes.addGrid}>
                                <IconButton
                                    className={classes.add}
                                    onClick={() => this.handleOpen()}
                                >
                                    <Tooltip title={'Edit'}>
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            color="white"
                                        />
                                    </Tooltip>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </div>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>OMS</TableCell>
                                        <TableCell>IVR</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>Record Id</TableCell>
                                        <TableCell>
                                            {this.state.OMSData.recordid}
                                        </TableCell>
                                        <TableCell>
                                            {this.state.IVRData.recordid}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Support Level</TableCell>
                                        <TableCell>
                                            {this.state.OMSData.support_level}
                                        </TableCell>
                                        <TableCell>
                                            {this.state.IVRData.support_level}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Maintenance Type</TableCell>
                                        <TableCell>
                                            {
                                                this.state.OMSData
                                                    .maintenance_type
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                this.state.IVRData
                                                    .maintenance_type
                                            }
                                        </TableCell>
                                    </TableRow>

                                    <TableRow>
                                        <TableCell>
                                            Maintenance Agreement Start Date
                                        </TableCell>
                                        <TableCell>
                                            {
                                                this.state.OMSData
                                                    .contract_start_date
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                this.state.IVRData
                                                    .contract_start_date
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Maintenance Agreement Expiration
                                            Date
                                        </TableCell>
                                        <TableCell>
                                            {
                                                this.state.OMSData
                                                    .contract_end_date
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                this.state.IVRData
                                                    .contract_end_date
                                            }
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item xs={4}>
                            <Table className={classes.table} size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell />
                                        <TableCell>OMS</TableCell>
                                        <TableCell>IVR</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>System Type</TableCell>
                                        <TableCell>
                                            {this.state.OMSData.system_type}
                                        </TableCell>
                                        <TableCell>
                                            {this.state.IVRData.system_type}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Install Date</TableCell>
                                        <TableCell>
                                            {this.state.OMSData.installed_date}
                                        </TableCell>
                                        <TableCell>
                                            {this.state.IVRData.installed_date}
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Last Invoice Date</TableCell>
                                        <TableCell>
                                            {
                                                this.state.OMSData
                                                    .last_invoice_date
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                this.state.IVRData
                                                    .last_invoice_date
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Last Invoice Amount
                                        </TableCell>
                                        <TableCell>
                                            {
                                                this.state.OMSData
                                                    .last_invoice_amount
                                            }
                                        </TableCell>
                                        <TableCell>
                                            {
                                                this.state.IVRData
                                                    .last_invoice_amount
                                            }
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>
                                            Next Invoice Month
                                        </TableCell>
                                        <TableCell>
                                            {this.state.OMSData.billing_month}
                                        </TableCell>
                                        <TableCell>
                                            {this.state.IVRData.billing_month}
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Grid>
                        <Grid item sm={4}>
                            <Grid container spacing={1}>
                                <Grid item sm={12}>
                                    <Table
                                        className={classes.lastTable}
                                        size="small"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell />
                                                <TableCell>OMS</TableCell>
                                                <TableCell>IVR</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>
                                                    Projected Maintenance Last
                                                    Year Per CPM
                                                </TableCell>
                                                <TableCell />
                                                <TableCell />
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    Projected Maintenance This
                                                    Year Per CPM
                                                </TableCell>
                                                <TableCell />
                                                <TableCell />
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                            
                                
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
                <div>
                    <Dialog
                        //fullScreen
                        fullWidth
                        maxWidth="lg"
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                    >
                        {/* <AppBar >
                            <Toolbar>
                                <IconButton
                                    color="inherit"
                                    onClick={this.handleClose}
                                    aria-label="Close"
                                >
                                    <CloseIcon />
                                </IconButton>
                                <div className={classes.flex} />
                                <Button
                                    color="inherit"
                                    onClick={this.handleSave}
                                >
                                    save
                                </Button>
                            </Toolbar>
                        </AppBar> */}

                        <SupportDatesForm supportData={this.state.data} />
                    </Dialog>
                </div>
            </React.Fragment>
        )
                    }
                    else{return (<React.Fragment></React.Fragment>)}
    }
}

SupportDates.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SupportDates)
