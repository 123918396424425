/*
    To use this validation function you have to:
        1) Import it:
            
            import { addValidators, validate } from '../../../utils/Validate'

        2) Call addValidators in componentWillMount, and pass 'this', and a validators object.
            Each key should be the name of the field you want to validate, and each value should be the type of validation 
            (Right now it just does required, but we can add more):

            componentWillMount() {
                addValidators(this, {
                    yourFieldName: 'required',
                })
            }

        3) On each TextField that you want to validate, add helperText and error properties:

            error={this.state.errorMessages.yourFieldName !== undefined}
            helperText={this.state.errorMessages.yourFieldName}

            But change yourFieldName to the name of the field you want to validate

        4) Call the validate() function, before saving, and pass 'this':

            validate(this)

            If the fields to validate are not in the main state object, you will need to pass the object that has the fields:

            validate(this, this.state.fields)

        This function will return TRUE if the fields pass the validation, or FALSE if any of them don't
*/

export function addValidators(component, validators) {
    component.validators = validators
    component.setState({ errorMessages: {} })
    component.setState({ invalid: false })
}

export function validate(component, fields) {
    component.setState({errorMessages: {}})

    // If a fields object is not passed, then use the state object
    if (fields === undefined) {
        fields = component.state
    }

    let errorMessages = {}

    // Loop through each key in the validators object
    for (const key of Object.keys(component.validators)) {

        // Get the error message, if there is one
        let errorMessage = checkField(component.validators, key, fields[key])
        
        // If there is an error message, add that to the error messages object
        if (errorMessage !== null) {
            errorMessages[key] = errorMessage
        }
    }

    // If there are any error messages, then the fields are not valid, so return false
    if (Object.keys(errorMessages).length > 0) {
        component.setState({ errorMessages: errorMessages })
        component.setState({ invalid: true })
        console.log(errorMessages)
        return false
    }

    // Otherwise, the fields are valid, so return true
    component.setState({ invalid: false })
    return true
}

// For checking if one field is valid
export function validateOne(component, field, value) {
    component.setState({ errorMessages: {} })

    // Get the error message, if there is one
    let errorMessage = checkField(component.validators, field, value)
    
    // If there is an error message, add it to the error message object, and return false
    if (errorMessage !== null) {
        let errorMessages = {} 
        errorMessages[field] = errorMessage
        component.setState({ errorMessages: errorMessages })
        component.setState({ invalid: true })
        console.log(errorMessage)
        return false
    }

    // Otherwise, the field is valid, so return true
    return true
}

function checkField(validators, field, value) {
    switch (validators[field]) {
        case 'required' : {
            if (value === undefined || value === null || value === '' || (Array.isArray(value) &&  value.length === 0)) {
                return 'This field cannot be blank'
            }
        }
        default : {
            return null
        }
    }
}