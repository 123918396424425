import React from 'react'
import { Redirect } from 'react-router-dom'

import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { styles } from './styles'
import Logo from '../../images/h2_logo.jpg'
import { PostData } from '../../services/PostData'
import SimpleSnackbar from '../Reusable/SimpleSnackbar';

class Login extends React.Component {
    constructor() {
        super()

        this.state = {
            open: false,
            userid: '',
            password: '',
            loggedIn: false,
            error: false,
            snackbarOpen: false
        }

        this.onChange = this.onChange.bind(this)
        this.login = this.login.bind(this)
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    login = () => {
        if (this.state.userid && this.state.password) {
            let data = {
                action: 'login_md5',
                user_id: this.state.userid,
                password: this.state.password
            }

            PostData('ajax/ajaxHUGlogin.php', data).then(response => {
                if (response.result == false || response.result == 'false') {
                    this.setState({ error: true, snackbarOpen: true, loggedIn: false });
                } else {
                    sessionStorage.setItem("userData", JSON.stringify(response.returnData[0]));
                    var userData = JSON.parse(sessionStorage.getItem('userData'))
                    //console.log(userData)
                    //console.log(userData.name)
                    this.setState({ loggedIn: true });
                }
            })
        }
    }

    handleSnackbarClose = () => {
        this.setState({ snackbarOpen: false });
    }

    /* To get user data
        let userData = sessionStorage.getItem("userData")
        userData.recordid
    */

    // componentWillMount() {
    //     if (sessionStorage.getItem("userData")) {
    //         this.setState({ loggedIn: true });
    //     }
    // }

    render() {
        const { classes } = this.props

        if (this.state.loggedIn) {
            return <Redirect to={'/companies'} />
        }

        return (
            <main className={classes.main}>
                <CssBaseline />
                <Paper className={classes.paper}>
                    <img
                        src={Logo}
                        style={{
                            height: '15',
                            width: '15%',
                            paddingBottom: 20,
                        }}
                    />

                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>

                    <form className={classes.form}>
                        <FormControl margin="normal" required fullWidth error={this.state.error}>
                            <InputLabel htmlFor="email">User ID</InputLabel>
                            <Input
                                id="userid"
                                name="userid"
                                //autoComplete="email"
                                autoFocus
                                onChange={this.onChange}
                                onKeyPress={ev => {
                                    if (ev.key === 'Enter') {
                                        this.login()
                                        ev.preventDefault()
                                    }
                                }}
                            />
                        </FormControl>

                        <FormControl margin="normal" required fullWidth error={this.state.error}>
                            <InputLabel htmlFor="password">Password</InputLabel>
                            <Input
                                name="password"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                                onChange={this.onChange}
                                onKeyPress={ev => {
                                    if (ev.key === 'Enter') {
                                        this.login()
                                        ev.preventDefault()
                                    }
                                }}
                            />
                        </FormControl>

                        <FormControlLabel
                            control={
                                <Checkbox value="remember" color="primary" />
                            }
                            label="Remember me"
                        />
                        <Button
                            //type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.login}
                        >
                            Sign in
                        </Button>
                    </form>
                </Paper>
                
                <SimpleSnackbar open={this.state.snackbarOpen} handleClose={this.handleSnackbarClose}/>
            </main>
        )
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Login)