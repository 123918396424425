import React from 'react'
import PropTypes, { array } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Redirect } from 'react-router-dom'

import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import Collapse from '@material-ui/core/Collapse'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Popover from '@material-ui/core/Popover'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Divider from '@material-ui/core/Divider'

import CloseIcon from '@material-ui/icons/Close'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SpeakerNotesIcon from '@material-ui/icons/SpeakerNotes'
import BusinessIcon from '@material-ui/icons/Business'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import AssessmentIcon from '@material-ui/icons/Assessment'
import SecurityIcon from '@material-ui/icons/Security'
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faBuilding,
    faBadgeDollar,
    faChartLine,
    faChartNetwork,
    faUsersCrown,
    faMoneyCheckEditAlt,
    faHeartRate,
    faFileSearch,
    faAbacus,
    faHistory,
    faStopwatch,
    faReceipt,
    faFileInvoiceDollar,
    faUserShield,
} from '@fortawesome/pro-regular-svg-icons'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { PostData } from '../../../../services/PostData'
import MainDrawerSearchDropdown from './MainDrawerSearchDropdown'
import MenuOptions from './MenuOptions'

const styles = theme => ({
    textField: {
        marginLeft: 10,
        marginRight: theme.spacing.unit,
        paddingLeft: 20,
    },
    nested: {
        paddingLeft: theme.spacing(10),
    },
    iconButton: {
        color: '#25525f',
        fontSize: '22px',
        margin: 'auto',
        width: '30%',
    },
    iconButtonCenter: {
        color: '#25525f',
        fontSize: '22px',
        margin: 'auto',
    },
    drawerIcon: {
        paddingLeft: 0,
    },
})

const MONTHS = {
    January: "01", 
    February: "02", 
    March: "03", 
    April: "04", 
    May: "05", 
    June: "06", 
    July: "07", 
    August: "08", 
    September: "09", 
    October: "10", 
    November: "11", 
    December: "12" 
}

class MainDrawerItems extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            openSearch: false,
            searchVal: '',
            ITEM_HEIGHT: 48,
            showMenu: false,
            list: [],
            lastSearch: '',
            searchField: '',
            listOpen: '',
            menuOptionsOpen: false,
            options: [],
            optionName: '',
            optionsLink: ''
        }

        this.renderMenu = this.renderMenu.bind(this)
    }

    handleClick(main) {
        this.props.setMain(main)
    }
    handleEnter = () => {
        this.setState({ openSearch: true })
    }

    handleLeave = () => {
        this.setState({ openSearch: false })
        this.setState({ searchVal: '' })
    }
    handleSearch = () => {
        this.setState({ searchVal: this.state.searchField })
    }
    handleChange = e => {
        this.setState({ searchField: e.target.value })
    }
    handleBlur = () => {
        this.setState({ showMenu: false })
    }
    handleFocus = event => {
        this.setState({
            showMenu: true,
            anchorEl: event.target,
        })
    }

    handleOpenList = list => {
        if (this.state.listOpen === list) {
            this.setState({ listOpen: '' })
        } else {
            this.setState({ listOpen: list })
        }
    }

    openMenuOptions = (options, link, name) => {
        if (typeof options === 'string') {
            options = this.state[options]
        }

        this.setState({ 
            menuOptionsOpen: true, 
            options: options,
            optionsLink: link,
            optionName: name,

        })
    }

    closeMenuOptions = () => {
        this.setState({ menuOptionsOpen: false })
    }

    /*getFilteredSuggestions() {
        const { searchVal } = this.state
        if (searchVal.length === 0) {
            return this.state.suggestions
        } else {
            let list = this.state.suggestions.filter(
                s =>
                    
                    (s.label ? (s.label.substr(0, searchVal.length).toLowerCase() ===
                    searchVal.toLowerCase()) || s.id.substr(0,searchVal.length) === searchVal : (false))
                 
                
            )
            return this.getUnique(list)
        }
    }
    getUnique(arr){

        return arr.filter((e,i)=> arr.indexOf(e) >= i)
    }*/
    /*getFilteredSuggestions(searchVal) {
        let data = {
            action: 'doNameSearch',
            LETTERsearch: searchVal,
        }

        PostData('CRM/ajax/ajaxCRMSearch.php', data).then(response => {
            if (response.result) {
                console.log(response.resultSet)
                return response.resultSet
            } else {
                return ['Not Found']
            }
        })
    }*/
    handleMenuItemClick = event => {
        this.props.handleFormClose()

        this.setState({
            showMenu: false,
            searchVal: event.target.getAttribute('data-value') || '',
        })
        //let company = event.target.getAttribute('data-value')
        let id = event.target.getAttribute('data-id')
        /*const data1 = {
            user_id: JSON.parse(sessionStorage.getItem('userData')).userid,
            action: 'fetch_recent'
        }
        let recent_companies = []
        PostData('ajax/ajaxHUGlogin.php', data1).then(response => {
            console.log(response)
            recent_companies = response.resultSet[0]
        })*/
        let recent_companies = JSON.parse(sessionStorage.getItem('userData'))
            .recent_companies
        let sessionData = JSON.parse(sessionStorage.getItem('userData'))
        if (!recent_companies) {
            recent_companies = []
        } else {
            if (typeof recent_companies === 'string') {
                recent_companies = recent_companies.substr(1)
                recent_companies = recent_companies.substr(
                    0,
                    recent_companies.length - 1
                )
                recent_companies = recent_companies.split(',')

                for (let i = 0; i < recent_companies.length; i++) {
                    recent_companies[i] = recent_companies[i].substr(1)
                    recent_companies[i] = recent_companies[i].substr(
                        0,
                        recent_companies[i].length - 1
                    )
                }
            }
            let index = recent_companies.indexOf(id)

            if (index > -1) {
                if (recent_companies.length > 1) {
                    recent_companies.splice(index, 1)
                }
            }
            if (recent_companies.length === 1 && recent_companies[0] === id) {
                recent_companies = []
            }
            if (recent_companies.length === 5) {
                recent_companies.pop()
            }
        }
        recent_companies.unshift(id)
        const data = {
            action: 'set_recent_companies',
            user_id: JSON.parse(sessionStorage.getItem('userData')).userid,
            recent_companies: JSON.stringify(recent_companies),
        }
        PostData('ajax/ajaxHUGlogin.php', data).then(response => {})
        this.props.setCrmData(id)
        this.props.setMain('Company')
        sessionData.recent_companies = recent_companies
        sessionStorage.setItem('userData', JSON.stringify(sessionData))

        this.props.history.push('/company/' + id)
    }

    componentWillMount() {
        const userData = JSON.parse(sessionStorage.getItem('userData'))

        let data = {
            action: 'doClientSearch',
            sbu: userData.strategic_business_unit,
        }

        PostData('CRM/ajax/ajaxCRMSearch.php', data).then(response => {
            if (response.result) {
                let list = {}

                for (let i = 0; i < response.resultSet.length; i++) {
                    list[response.resultSet[i].company] = response.resultSet[i].sbu_client_id
                }

                this.setState({ list: list })
            }
        })
    }

    renderMenu = searchVal => {
        if (this.state.lastSearch !== searchVal) {
            let data = {
                action: 'doNameSearch',
                LETTERsearch: searchVal,
            }

            PostData('CRM/ajax/ajaxCRMSearch.php', data).then(response => {
                if (response.result) {
                    this.setState({
                        list: response.resultSet,
                        lastSearch: searchVal,
                    })
                }
            })
        }

        return (
            <Popover
                key="menu"
                open={this.state.showMenu && !!this.state.anchorEl}
                anchorEl={this.state.anchorEl}
                anchorOrigin={{
                    horizontal: 'left',
                    vertical: 'bottom',
                }}
                anchorPosition={{ top: 0, left: 0 }}
                disableAutoFocus
                disableEnforceFocus
                disableRestoreFocus
                hideBackdrop
                style={{ width: 314, height: 0 }}
                PaperProps={{
                    style: {
                        maxHeight: this.state.ITEM_HEIGHT * 4.7,
                        width: '100%',
                    },
                }}
            >
                {/*<MenuList>
                        {list.map(item => (
                            <MenuItem
                                key={item.company}
                                onClick={this.handleMenuItemClick}
                                data-value={item.company}
                                data-id={item.crm_id}
                            >
                                {item.company}
                            </MenuItem>
                        ))}
                        </MenuList>*/}
                <MainDrawerSearchDropdown
                    list={this.state.list}
                    handleMenuItemClick={this.handleMenuItemClick}
                />
            </Popover>
        )
    }
    render() {
        const { classes } = this.props
        let baseUrl = process.env.REACT_APP_API_URL
        const open = this.props.open
        //let list = this.getFilteredSuggestions(this.state.searchVal)

        const userData = JSON.parse(sessionStorage.getItem('userData'))

        return (
            <React.Fragment>
            <List onMouseLeave={this.handleLeave} component="nav" style={{overflowY: 'visible', height: 500}}>
                {!open ? (
                    <Tooltip
                        disableFocusListener
                        placement="right"
                        title="Companies"
                    >
                        <Link
                            to={'/company-search'}
                            /*href={
                                baseUrl === 'http://localhost'
                                    ? baseUrl + ':3000/redesign/company-search'
                                    : baseUrl + '/redesign/company-search'
                            }*/
                        >
                            <ListItem button className={classes.drawerIcon}>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faBuilding}
                                        size="2x"
                                        className={classes.iconButtonCenter}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Companies" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                ) : (
                    <div onMouseEnter={this.handleEnter}>
                        {/* <a
                            target="_self"
                            href={
                                baseUrl === 'http://localhost'
                                    ? baseUrl + ':3000/redesign/company-search'
                                    : baseUrl + '/company-search'
                            }
                            style={{ textDecoration: 'none', color: '#2d2d2d' }}
                        > */}
                        <Link
                            to={'/company-search'}
                            style={{ textDecoration: 'none', color: '#2d2d2d' }}
                        >
                            <ListItem button className={classes.drawerIcon}>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faBuilding}
                                        size="2x"
                                        className={classes.iconButton}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Companies" />
                            </ListItem>
                        </Link>
                        {/* </a> */}
                        <Collapse
                            in={
                                this.state.openSearch &&
                                this.props.main !== 'CompanySearch'
                            }
                        >
                            <Grid item className={classes.textField}>
                                <TextField
                                    autoComplete="off"
                                    label="Search Companies"
                                    value={this.state.searchField}
                                    key="field"
                                    fullWidth
                                    type="search"
                                    onFocus={this.handleFocus}
                                    onBlur={this.handleBlur}
                                    onKeyPress={ev => {
                                        if (ev.key === 'Enter') {
                                            this.handleSearch()
                                            ev.preventDefault()
                                        }
                                    }}
                                    onChange={this.handleChange}
                                    variant="outlined"
                                />
                                {this.state.searchVal &&
                                    this.renderMenu(this.state.searchVal)}
                            </Grid>
                        </Collapse>
                    </div>
                )}

                {!open ? (
                    <Tooltip placement="right" title="Sales">
                        <ListItem className={classes.drawerIcon} button                             
                            onClick={this.props.handleDrawerOpen}>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faMoneyCheckEditAlt}
                                    size="2x"
                                    className={classes.iconButtonCenter}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Sales" />
                        </ListItem>
                    </Tooltip>
                ) : (
                    <React.Fragment>
                        <ListItem
                            button
                            onClick={() => {
                                this.handleOpenList('Sales')
                            }}
                            className={classes.drawerIcon}
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faMoneyCheckEditAlt}
                                    size="2x"
                                    className={classes.iconButton}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Sales" />
                            <FontAwesomeIcon
                                icon={
                                    this.state.listOpen !== 'Sales'
                                        ? faChevronDown
                                        : faChevronUp
                                }
                                size="1x"
                                color="black"
                            />
                        </ListItem>
                        <Collapse
                            in={this.state.listOpen === 'Sales'}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding>
                                <Divider />
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/Leads_tracking/OpportunityTracker.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Opportunities" />
                                    </ListItem>
                                </a>
                                <Link
                                    to={'/Quotes'}
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Quotes" />
                                    </ListItem>
                                </Link>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/Quotes/QuotesList.php?WhereStatus=OPEN'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Quotes (Legacy)" />
                                    </ListItem>
                                </a>
                                <Link
                                    to={'/CustomerList'}
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Customer List" />
                                    </ListItem>
                                </Link>
                                {/* <Link
                                    to={'/ContactList'}
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Customer Contacts" />
                                    </ListItem>
                                </Link> */}
                                {/* <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/MarketingReports/crm_data.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Data Review" />
                                    </ListItem>
                                </a> */}
                                {/* <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/CRM/CRM_List_ALL.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Prospects" />
                                    </ListItem>
                                </a> */}
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/ReferenceList/ReferenceList2018.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Reference List" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/CustomerModuleSearch/CustomerModuleSearch.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Customer Modules" />
                                    </ListItem>
                                </a>
                                {/* <Link
                                    to='/CallCampaign'
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Call Campaigns" />
                                    </ListItem>
                                </Link> */}
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/tradeshows/tradeshows.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Trade Shows" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/tradeshows/EventAppointments.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="TS Appointments" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/tradeshows/TradeShow_Recap.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="TS Recaps" />
                                    </ListItem>
                                </a>
                                <Divider />
                            </List>
                        </Collapse>
                    </React.Fragment>

                    // <a
                    //     href={
                    //         baseUrl +
                    //         '/HarrisUtilityGroup/menuMarketing2018.php'
                    //     }
                    //     target="_blank"
                    //     style={{ textDecoration: 'none' }}
                    // >
                    // </a>
                )}

                {/* {!open ? (
                    <Tooltip title="Search">
                        <a
                            href={
                                baseUrl +
                                '/HarrisUtilityGroup/db_querys/reports_template.php'
                            }
                            target="_blank"
                            style={{ textDecoration: 'none' }}
                        >
                            <ListItem button>
                                <ListItemIcon>
                                    <AssessmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Search" />
                            </ListItem>
                        </a>
                    </Tooltip>
                ) : (
                    <a
                        href={
                            baseUrl +
                            '/HarrisUtilityGroup/db_querys/reports_template.php'
                        }
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                    >
                        <ListItem button>
                            <ListItemIcon>
                                <AssessmentIcon />
                            </ListItemIcon>
                            <ListItemText primary="Search" />
                        </ListItem>
                    </a>
                )} */}

                {/* {!open ? (
                    <Tooltip title="Quotes">
                        <a
                            href={
                                baseUrl +
                                '/HarrisUtilityGroup/menuQuotes2018.php'
                            }
                            target="_blank"
                            style={{ textDecoration: 'none' }}
                        >
                            <ListItem button>
                                <ListItemIcon>
                                    <SpeakerNotesIcon />
                                </ListItemIcon>
                                <ListItemText primary="Quotes" />
                            </ListItem>
                        </a>
                    </Tooltip>
                ) : (
                    <a
                        href={
                            baseUrl + '/HarrisUtilityGroup/menuQuotes2018.php'
                        }
                        target="_blank"
                        style={{ textDecoration: 'none' }}
                    >
                        <ListItem button>
                            <ListItemIcon>
                                <SpeakerNotesIcon />
                            </ListItemIcon>
                            <ListItemText primary="Quotes" />
                        </ListItem>
                    </a>
                )} */}

                {/* {!open ? (
                    <Tooltip placement="right" title="PS">
                        <a
                            href={
                                baseUrl +
                                '/HarrisUtilityGroup/menuProductionReports.php'
                            }
                            target="_blank"
                            style={{ textDecoration: 'none', color: '#2d2d2d' }}
                        >
                            <ListItem button className={classes.drawerIcon}>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faChartNetwork}
                                        size="2x"
                                        className={classes.iconButtonCenter}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="PS" />
                            </ListItem>
                        </a>
                    </Tooltip>
                ) : ( */}
                    {/* <React.Fragment>
                        <ListItem
                            button
                            onClick={() => {
                                this.handleOpenList('PS')
                            }}
                            className={classes.drawerIcon}
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faChartNetwork}
                                    size="2x"
                                    className={classes.iconButton}
                                />
                            </ListItemIcon>
                            <ListItemText primary="PS" />
                            <FontAwesomeIcon
                                icon={
                                    this.state.listOpen !== 'PS'
                                        ? faChevronDown
                                        : faChevronUp
                                }
                                size="1x"
                                color="black"
                            />
                        </ListItem>
                        <Collapse
                            in={this.state.listOpen === 'PS'}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding>
                                <Divider /> */}
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/menuProductionReports.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.drawerIcon}>
                                        <ListItemIcon>
                                            <FontAwesomeIcon
                                                icon={faReceipt}
                                                size="2x"
                                                className={classes.iconButtonCenter}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="Work Orders" />
                                    </ListItem>
                                </a>

                                {/* <a
                                    href={
                                        baseUrl +
                                        '/psm/'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.drawerIcon}>
                                        <ListItemIcon>
                                            <FontAwesomeIcon
                                                icon={faStopwatch}
                                                size="2x"
                                                className={classes.iconButtonCenter}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary="Time Entry" />
                                    </ListItem>
                                </a> */}
                                {/* <Divider />
                            </List>
                        </Collapse>
                    </React.Fragment> */}
                {/* )} */}

                {!open ? (
                    <Tooltip placement="right" title="Invoicing">
                        <ListItem button className={classes.drawerIcon}
                            onClick={this.props.handleDrawerOpen}>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faFileInvoiceDollar}
                                    size="2x"
                                    className={classes.iconButtonCenter}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Invoicing" />
                        </ListItem>
                    </Tooltip>
                ) : (
                    <React.Fragment>
                        <ListItem
                            button
                            onClick={() => {
                                this.handleOpenList('Invoicing')
                            }}
                            className={classes.drawerIcon}
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faFileInvoiceDollar}
                                    size="2x"
                                    className={classes.iconButton}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Invoicing" />
                            <FontAwesomeIcon
                                icon={
                                    this.state.listOpen !== 'Invoicing'
                                        ? faChevronDown
                                        : faChevronUp
                                }
                                size="1x"
                                color="black"
                            />
                        </ListItem>
                        <Collapse
                            in={this.state.listOpen === 'Invoicing'}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding>
                                <Divider />
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/RevenueRecognition/RevRecognition.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Record Revenue Recognition" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/RevenueRecognition/revRecogHarrisCSV.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Rev Rec - Create CSV File" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/RevenueRecognition/revRecogTableAdj.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Rev Rec - DB Table View" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/MaintenanceAgreements/Cust_Support_Info.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Customer Support Agreements" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/Monthly_Billings/ViewMonthlyInvoices_2018_08.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Monthly Billings" />
                                    </ListItem>
                                </a>

                                <ListItem button 
                                        onClick={() => {
                                            this.openMenuOptions(MONTHS, '/HarrisUtilityGroup/Monthly_Billings/MonthlyInvoice_2018_08.php', 'month')}
                                        } 
                                        className={classes.nested}>
                                    <ListItemText primary="Monthly Billing Forecasting" />
                                </ListItem>

                                <ListItem button 
                                        onClick={() => {
                                            this.openMenuOptions('list', '/HarrisUtilityGroup/MaintenanceAgreements/utility_annual_charges.php', 'clientid', 'md')}
                                        } 
                                        className={classes.nested}>
                                    <ListItemText primary="Annual and Monthly Charges" />
                                </ListItem>

                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/MaintenanceAgreements/ProjectMaintAgreements_2018.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Maintenance Agreements" />
                                    </ListItem>
                                </a>
                                <Link
                                    to={'/TotalFeesPerClient'}
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Total Fees Per Client" />
                                    </ListItem>
                                </Link>
                                <Divider />
                            </List>
                        </Collapse>
                    </React.Fragment>
                )}

                
                {!open ? (
                    <Tooltip placement="right" title="Reports">
                        <ListItem button className={classes.drawerIcon}
                            onClick={this.props.handleDrawerOpen}>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faChartLine}
                                    size="2x"
                                    className={classes.iconButtonCenter}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Reports" />
                        </ListItem>
                    </Tooltip>
                ) : (
                    <React.Fragment>
                        <ListItem
                            button
                            onClick={() => {
                                this.handleOpenList('Reports')
                            }}
                            className={classes.drawerIcon}
                        >
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faChartLine}
                                    size="2x"
                                    className={classes.iconButton}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Reports" />
                            <FontAwesomeIcon
                                icon={
                                    this.state.listOpen !== 'Reports'
                                        ? faChevronDown
                                        : faChevronUp
                                }
                                size="1x"
                                color="black"
                            />
                        </ListItem>
                        <Collapse
                            in={this.state.listOpen === 'Reports'}
                            timeout="auto"
                            unmountOnExit
                        >
                            <List component="div" disablePadding>
                                <Divider />
                                {/* <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/db_querys/reports_template.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Search" />
                                    </ListItem>
                                </a> */}
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/ActualBookingAnalysis/ActualBookingAnalysis.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Booking Analysis" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/ActualBookingAnalysis/ReferralBookingReport.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Referral Bookings" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/FutureBookingTrend/FutureBookingTrend.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Booking Forecast" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/FutureBookingTrend/FutureBookingContacts.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Booking Contacts" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/PipelineAnalysis/PipelineAnalysis.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Pipeline Analysis" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/AtRiskAccounts/AtRiskAccounts.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="At Risk Accounts" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/MonthlyReport_PG/Monthlyreport_PG.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Monthly Report" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/ActualBookingAnalysis/Reports_Template.php?report_type=booking'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Sales to HUG Customers" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/ActualBookingAnalysis/Reports_Template.php?report_type=pipeline'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Quotes to HUG Customers" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/FutureBookingTrend/ProjectSales_LF_PS.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Projected Future Revenue" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/MarketingReports/MarketShareByServiceType.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Market Share" />
                                    </ListItem>
                                </a>
                                {/* <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/MarketingReports/hug_customers.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="HUG Customers" />
                                    </ListItem>
                                </a> */}
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/MarketingReports/ClientsByDate_2018.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Customer Longevity" />
                                    </ListItem>
                                </a>
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/MarketingReports/Meter_Count.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Meter Count Analysis" />
                                    </ListItem>
                                </a>
                                {/* <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/MarketingReports/TargetMarketAnalysis.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Target Market Analysis" />
                                    </ListItem>
                                </a> */}
                                <a
                                    href={
                                        baseUrl +
                                        '/HarrisUtilityGroup/Harris_Custom_Reports/Util_Op_Sys_Harris_SBU.php'
                                    }
                                    target="_blank"
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Operating Systems By SBU" />
                                    </ListItem>
                                </a>
                                <Link
                                    to={'/OpportunityValuesByQuote'}
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Opportunity Values By Quote" />
                                    </ListItem>
                                </Link>
                                <Link
                                    to={'/NotesSinceDate'}
                                    style={{
                                        textDecoration: 'none',
                                        color: '#2d2d2d',
                                    }}
                                >
                                    <ListItem button className={classes.nested}>
                                        <ListItemText primary="Notes Since Date" />
                                    </ListItem>
                                </Link>

                                <Divider />
                            </List>
                        </Collapse>
                    </React.Fragment>
                )}

                {!open ? (
                    <Tooltip
                        disableFocusListener
                        placement="right"
                        title="Search"
                    >
                        <a
                            target="_blank"
                            href={
                                baseUrl +
                                '/HarrisUtilityGroup/db_querys/reports_template.php'
                            }
                        >
                            <ListItem button className={classes.drawerIcon}>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faFileSearch}
                                        size="2x"
                                        className={classes.iconButtonCenter}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Companies" />
                            </ListItem>
                        </a>
                    </Tooltip>
                ) : (
                    <a
                        target="_blank"
                        href={
                            baseUrl +
                            '/HarrisUtilityGroup/db_querys/reports_template.php'
                        }
                        style={{ textDecoration: 'none', color: '#2d2d2d' }}
                    >
                        <ListItem button className={classes.drawerIcon}>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faFileSearch}
                                    size="2x"
                                    className={classes.iconButton}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Search" />
                        </ListItem>
                    </a>
                )}

                {userData.strategic_business_unit === 'dataVoice' ? (
                    !open ? (
                        <Tooltip placement="right" title="dataVoice Operations">
                            <ListItem button className={classes.drawerIcon}
                                onClick={this.props.handleDrawerOpen}>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faHeartRate}
                                        size="2x"
                                        className={classes.iconButtonCenter}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="dataVoice Operations" />
                            </ListItem>
                        </Tooltip>
                    ) : (
                        <React.Fragment>
                            <ListItem
                                button
                                onClick={() => {
                                    this.handleOpenList('DV Operations')
                                }}
                                className={classes.drawerIcon}
                            >
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faHeartRate}
                                        size="2x"
                                        className={classes.iconButton}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="dataVoice Operations" />
                                <FontAwesomeIcon
                                    icon={
                                        this.state.listOpen !== 'DV Operations'
                                            ? faChevronDown
                                            : faChevronUp
                                    }
                                    size="1x"
                                    color="black"
                                />
                            </ListItem>
                            <Collapse
                                in={this.state.listOpen === 'DV Operations'}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <Divider />
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/RevenueRecognition/revRecogByPL.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Rev Rec By Product Line" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/MarketingReports/ClientOperatingSystem.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Client Operating Systems" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            'http://10.222.109.9/drupal/?q=node/25'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="VPN IP Addresses" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            'https://dvweb.biz:26433/financial/osm_clt_maps_2018.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Customer Facing Apps" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            'https://www.outageentry.com/SmartPhone/Menu.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="WAP Links" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/_dvSysmonManagementPG/Sysmon_Menu_PRO.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="SysMon Management" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/dataVoice_Operation/dvOptn.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Client Operations" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/dataVoice_Operation/TTS_Licenses.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="TTS Licenses" />
                                        </ListItem>
                                    </a>
                                    <Divider />
                                </List>
                            </Collapse>
                        </React.Fragment>
                    )
                ) : (
                    ''
                )}

                {JSON.parse(sessionStorage.getItem('userData')).access_level ===
                '1' ? (
                    !open ? (
                        <Tooltip placement="right" title="Administration">
                            <ListItem button className={classes.drawerIcon}
                                onClick={this.props.handleDrawerOpen}>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faUserShield}
                                        size="2x"
                                        className={classes.iconButtonCenter}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Administration" />
                            </ListItem>
                        </Tooltip>
                    ) : (
                        <React.Fragment>
                            <ListItem
                                button
                                onClick={() => {
                                    this.handleOpenList('Administration')
                                }}
                                className={classes.drawerIcon}
                            >
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faUserShield}
                                        size="2x"
                                        className={classes.iconButton}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Administration" />
                                <FontAwesomeIcon
                                    icon={
                                        this.state.listOpen !== 'Administration'
                                            ? faChevronDown
                                            : faChevronUp
                                    }
                                    size="1x"
                                    color="black"
                                />
                            </ListItem>

                            <Collapse
                                in={this.state.listOpen === 'Administration'}
                                timeout="auto"
                                unmountOnExit
                            >
                                <List component="div" disablePadding>
                                    <Divider />
                                    {JSON.parse(
                                        sessionStorage.getItem('userData')
                                    ).super_user_flag == 1 && (
                                        <ListItem
                                            button
                                            className={classes.nested}
                                            onClick={() =>
                                                this.props.setFormData(
                                                    '',
                                                    'DropdownForm'
                                                )
                                            }
                                        >
                                            <ListItemText primary="Dropdowns" />
                                        </ListItem>
                                    )}
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/TeamSupport/T_Support_2018.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Team Support" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/HarrisPersonnelAdmin/slider.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Harris Personnel" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/Quotes_LegalText/PDF_Legaltext.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Quotes Legal Text" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/Quotes/PDF_Config.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Quotes PDF Config" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/Milestones/Milestones.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Milestone Templates" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/products/ProductPricing.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Product Pricing" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/Harris_SBU_Master/HarrisSbuMaster.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="SBUs" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/_pg_backup_schemas/CopyNewSchema.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Build A New Schema" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/db_querys/pgSchemaCompare.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Compare Schemas" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/_pg_backup_schemas/SetupCPM_table.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Setup Product Modules" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/group_permission/group.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Groups" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/group_permission/permission.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Permissions" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/group_permission/harris_group.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="PSM Groups" />
                                        </ListItem>
                                    </a>
                                    <a
                                        href={
                                            baseUrl +
                                            '/HarrisUtilityGroup/import/import.php'
                                        }
                                        target="_blank"
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem
                                            button
                                            className={classes.nested}
                                        >
                                            <ListItemText primary="Import" />
                                        </ListItem>
                                    </a>
                                    <Link
                                        to={'/fix-services'}
                                        style={{
                                            textDecoration: 'none',
                                            color: '#2d2d2d',
                                        }}
                                    >
                                        <ListItem button className={classes.nested}>
                                            <ListItemText primary="Fix Services" />
                                        </ListItem>
                                    </Link>
                                    <Divider />
                                </List>
                            </Collapse>
                        </React.Fragment>
                    )
                ) : (
                    ''
                )}

                {!open ? (
                    <Tooltip
                        disableFocusListener
                        placement="right"
                        title="Legacy System"
                    >
                        <a
                            target="_blank"
                            href={baseUrl + '/HarrisUtilityGroup'}
                        >
                            <ListItem button className={classes.drawerIcon}>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faAbacus}
                                        size="2x"
                                        className={classes.iconButtonCenter}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Return To The Legacy System" />
                            </ListItem>
                        </a>
                    </Tooltip>
                ) : (
                    <a
                        target="_blank"
                        href={baseUrl + '/HarrisUtilityGroup'}
                        style={{ textDecoration: 'none', color: '#2d2d2d' }}
                    >
                        <ListItem button className={classes.drawerIcon}>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faAbacus}
                                    size="2x"
                                    className={classes.iconButton}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Return To The Legacy System" />
                        </ListItem>
                    </a>
                )}

                {!open ? (
                    <Tooltip
                        disableFocusListener
                        placement="right"
                        title="Release Notes"
                    >
                        <Link
                            to="/release-notes"
                            style={{ textDecoration: 'none', color: '#2d2d2d' }}
                        >
                            <ListItem button className={classes.drawerIcon}>
                                <ListItemIcon>
                                    <FontAwesomeIcon
                                        icon={faHistory}
                                        size="2x"
                                        className={classes.iconButtonCenter}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Release Notes" />
                            </ListItem>
                        </Link>
                    </Tooltip>
                ) : (
                    <Link
                        to="/release-notes"
                        style={{ textDecoration: 'none', color: '#2d2d2d' }}
                    >
                        <ListItem button className={classes.drawerIcon}>
                            <ListItemIcon>
                                <FontAwesomeIcon
                                    icon={faHistory}
                                    size="2x"
                                    className={classes.iconButtonCenter}
                                />
                            </ListItemIcon>
                            <ListItemText primary="Release Notes" />
                        </ListItem>
                    </Link>
                )}
            </List>

            <MenuOptions
                open={this.state.menuOptionsOpen} 
                closeMenuOptions={this.closeMenuOptions}
                options={this.state.options}
                optionName={this.state.optionName}
                optionsLink={this.state.optionsLink}
            />
            </React.Fragment>
        )
    }
}

MainDrawerItems.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MainDrawerItems)
