import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import store from '../../../store/Store'
import { setFormOpen } from '../../../store/actions/Actions'

import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
//import { styles } from '../../Layout/styles'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'
import FullScreenDialog from '../../Reusable/FullScreenDialog'

import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import { CardMedia } from '@material-ui/core'
import TableCell from '@material-ui/core/TableCell'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Icon from '@material-ui/core/Icon'
import Tooltip from '@material-ui/core/Tooltip'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import NumberFormat from 'react-number-format'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { faGripHorizontal } from '@fortawesome/pro-light-svg-icons'
import { faGripLines } from '@fortawesome/pro-light-svg-icons'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
        paddingTop: 3,
    },

    lower: {
        textTransform: 'capitalize',
        margin: 0,
        padding: 0,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    //Makes show more button cover the entire width
    showMore: {
        width: '100%',
    },
    add: {
        padding: 7,
        marginRight: 5,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        width: '98%',
        //background: 'rgb(248,248,248)',
    },
    highlight: {
        cursor: 'pointer',
    },
})

class Opportunities extends React.Component {
    constructor(props) {
        super(props)
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        this.state = {
            opportunities: [],
            more: false,
            open: false,
            gridView: true,
            bubbleView: false,
            status: 'Open',
            years: ['All']
        }

        this.handleToggle = this.handleToggle.bind(this)
        this.handleOpen = this.handleOpen.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentWillMount() {
        this.getDropdowns()
        this.getYears();
        this.setupWidget(this.props)
    }

    componentWillReceiveProps(nextProps) {
        this.setupWidget(nextProps)
        //console.log('UPDATING')
    }

    setupWidget = props => {
        let userData = JSON.parse(sessionStorage.getItem('userData'))

        if (
            (props.crmData && props.crmData !== this.state.crmData) ||
            props.loading === 'Opportunities'
        ) {
            //console.log('ACTUALLY UPDATING')

            this.setState({ crmData: props.crmData })
            const data = {
                hug_schema: 'hug_' + (JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
                action: 'getLeadTrackingInfo',
                request_type: 'sbu',
                crm_id: props.crmData.crm_id,
                strategic_business_unit: userData.strategic_business_unit,
            }

            PostData('CRM/ajax/ajaxCRMLeadsTracker.php', data).then(
                response => {
                    //console.log(response)
                    if (response.result && response.LeadTracking) {
                        let leads = response.LeadTracking
                        let date = new Date()
                        let year = date.getFullYear()
                        if(response.LeadTracking.length === 0){
                            this.setState({opportunities: null})
                        }
                        // let years = response.distinctYears
                        // years = years.reverse()
                        // years.unshift('All')
                        // if(response.distinctYears.length === 0){
                            year = 'All'
                        //}
                        
                        this.setState({
                            opportunities: leads,
                            // years: years,
                            year: year
                        }) 
                        
                    }
                }
            )
        }
    }
    getDropdowns = () =>{
        const data = {
            action: 'getDropdownItems',
            subsystem_code: 'OPPORTUNITY',
            control_code: 'FILTER'
        }
        PostData(
            'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            this.setState({filters: response.dropdowns})
        })
    }
    getYears = () =>{
        const data = {
            action: 'getDropdownItems',
            subsystem_code: 'QUOTE',
            control_code: 'SORT_YEAR'
        }
        PostData(
            'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            this.setState({years: response.dropdowns})
        })
    }

    handleOpen = data => {
        this.props.setFormData(data, 'Opportunities')
    }
    handleClose = () => {
        this.setState({ open: false })
    }

    handleToggle = () => {
        this.setState({ more: !this.state.more })
    }

    handleView1Change = () => {
        if (this.state.bubbleView == false) {
            this.setState({ bubbleView: true, gridView: false })
        }
    }
    handleView2Change = () => {
        if (this.state.gridView == false) {
            this.setState({ bubbleView: false, gridView: true })
        }
    }
    handleChange = e =>{
        this.setState({[e.target.name]: e.target.value})
    }

    getMoreButton() {
        const { classes } = this.props
        let newSort = []
        let sort = this.state.opportunities
        const status = this.state.status
        
        
        if(status !== 'All' && sort){
            sort = sort.filter(opp =>{
                if(status === 'Lost'){
                    
                    return opp.lead_stage === 'Lost - 0%'
                } else if(
                    status === 'Ready To Quote'
                ){
                    
                    return opp.quote_number > 0
                } else{
                    
                    return !opp.lost_to && !opp.quote_number
                }
                
            })
        }

        if(this.state.year !== 'All' && this.state.year !== '' && sort){
            sort = sort.filter(item => {
                
                if(item.date_started_tracking !== null && 
                    item.date_started_tracking !== undefined && 
                    item.date_started_tracking.substr(0,4) == this.state.year){
                        return true
                } else{
                    return false
                }
            })
        }
        
        if (this.state.opportunities && sort.length > 5) {
            return (
                <Grid container xs={12}>
                    <Button
                        className={classes.showMore}
                        onClick={this.handleToggle}
                    >
                        {this.state.more  ? (
                            <div>
                                <FontAwesomeIcon
                                    icon={faChevronUp}
                                    //size="2x"
                                />
                                &nbsp;1 to {sort.length} of {sort.length}
                            </div>
                        ) : (
                            <div>
                                <FontAwesomeIcon
                                    icon={faChevronDown}
                                    //size="2x"
                                />
                                &nbsp;1 to 5 of {sort.length}
                            </div>
                        )}
                    </Button>
                </Grid>
            )
        }
    }

    getOpportunitiesList = () => {
        const { classes } = this.props

        let newSort = []
        let sort = this.state.opportunities
        const status = this.state.status
        
        
        if(status !== 'All' && sort){
            sort = sort.filter(opp =>{
                if(status === 'Lost'){
                    
                    return opp.lead_stage === 'Lost - 0%'
                } else if(
                    status === 'Ready To Quote'
                ){
                    
                    return opp.quote_number
                } else{
                    
                    return !opp.lost_to && !opp.quote_number
                }
            })
        }

        if(this.state.year !== '' && this.state.year !== 'All' && sort){
            sort = sort.filter(item => {
                
                if(item.date_started_tracking !== null && 
                    item.date_started_tracking !== undefined && 
                    item.date_started_tracking.substr(0,4) == this.state.year){
                        return true
                } else{
                    return false
                }
            })
        }
        if(sort && sort.length > 5){
            if (!this.state.more) {
                for (let i = 0; i < 5; i++){
                    newSort[i] = sort[i]
                }
                sort = newSort
            }
        }
        if(this.state.bubbleView == true){
            if (
                this.state.opportunities !== undefined &&
                this.state.opportunities.length > 0
            ) {
                return (
                    <React.Fragment>
                        {sort &&
                            sort.map(
                                opportunities => (
                                    <Grid item>
                                        <ListItem>
                                            <Button
                                                onClick={() =>
                                                    this.handleOpen(
                                                        opportunities
                                                    )
                                                }
                                            >
                                                <ListItemText
                                                    className={classes.lower}
                                                    primary={
                                                        opportunities.opportunity_name
                                                    }
                                                    secondary={
                                                        opportunities.lead_stage
                                                    }
                                                />
                                            </Button>
                                        </ListItem>
                                    </Grid>
                                ),
                                this
                            )}
                    </React.Fragment>
                )
            } else {
                return ''
            }
        } else {
            let oppList = []
            sort &&
                sort.forEach(opp => {
                    oppList.push(
                        <TableRow onClick={() => this.handleOpen(opp)}>
                            <TableCell className={classes.highlight}>
                                {opp.opportunity_name}
                            </TableCell>
                            <TableCell>{opp.quote_number ? 'Ready To Quote - 25%' : opp.lead_stage}</TableCell>
                            <TableCell>
                                <NumberFormat
                                    value={opp.total_opportunity_value}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                />
                            </TableCell>
                            <TableCell>
                                <NumberFormat
                                    value={opp.close_probability}
                                    displayType={'text'}
                                    suffix={'%'}
                                />
                            </TableCell>
                        </TableRow>
                    )
                })
            return <React.Fragment>{this.viewGridView(oppList)}</React.Fragment>
        }
    }

    viewGridView = list => {
        const { classes } = this.props
        return (
            <Table className={classes.table} size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Lead Stage</TableCell>
                        <TableCell>Value</TableCell>
                        <TableCell>Probability</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{list}</TableBody>
            </Table>
        )
    }

    render() {
        const { classes } = this.props
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        //console.log(this.state)
        if (this.props.index !== 0) {
            return ''
        }

        return (
            <React.Fragment>
                <Grid xs = {12} md={12} sm={12} xl={12} lg={12} container spacing={1}>
                    <Grid item xs={12}>
                        {/* <Card> */}
                            {/*<div className={classes.secondary}>
                                <Grid container spacing={1}>
                                    <Grid item>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            color="inherit"
                                            className={classes.textField}
                                        >
                                            Opportunities
                                        </Typography>
                                    </Grid>
                                    <Grid item className={classes.addGrid}>
                                        <Tooltip title={'New Opportunity'}>
                                            <IconButton
                                                onClick={() =>
                                                    this.handleOpen(
                                                        /*needs BU userData ===
                                                            null
                                                            ? null
                                                            : userData.strategic_business_unit
                                                    )
                                                }
                                                className={classes.add}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    color="white"
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                            </div>*/}
                            <Grid
                                container
                                spacing={1}
                                className={classes.sort}
                            >
                                
                                <Typography style={{paddingTop: 11, paddingLeft: 20}}>Opportunity Status:</Typography>
                                <Grid item>
                                    <TextField 
                                        name='status'
                                        value={this.state.status}
                                        onChange={this.handleChange}
                                        select
                                        style={{marginLeft: 5, paddingTop: 3}}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                    >
                                        {this.state.filters && this.state.filters.map(item => 
                                            <MenuItem key={item.item_name} value={item.item_name}>
                                                {item.item_name}
                                            </MenuItem>)}
                                    </TextField>

                                </Grid>
                                <Typography style={{paddingTop: 11, paddingLeft: 20}}>Year:</Typography>
                                <Grid item>
                                    <TextField 
                                        name='year'
                                        value={this.state.year}
                                        onChange={this.handleChange}
                                        select
                                        style={{marginLeft: 5, paddingTop: 3}}
                                        InputProps={{
                                            disableUnderline: true,
                                        }}
                                    >
                                        {this.state.years && this.state.years.map(item => 
                                            <MenuItem key={item.item_name} value={item.item_name}>
                                                {item.item_name}
                                            </MenuItem>)}
                                    </TextField>

                                </Grid>
                                <Grid item className={classes}>
                                    <Tooltip title={'Bubble View'}>
                                        <IconButton
                                            onClick={this.handleView1Change}
                                            size="small"
                                            className={classes.add}
                                            style={this.state.bubbleView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripHorizontal}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={'Grid View'}>
                                        <IconButton
                                            onClick={this.handleView2Change}
                                            size="small"
                                            className={classes.add}
                                            style={this.state.gridView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripLines}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    </Grid>
                                    <Grid item className={classes.addGrid}>
                                    <Tooltip title={'Add Opportunity'}>
                                            <Button
                                                onClick={() =>
                                                    this.handleOpen(
                                                        /*needs BU*/ userData ===
                                                            null
                                                            ? null
                                                            : userData.strategic_business_unit
                                                    )
                                                }
                                                className={classes.add}
                                            >
                                                Add Opportunity
                                                <FontAwesomeIcon
                                                    icon={faPlusCircle}
                                                    color="black"
                                                    style={{
                                                        marginLeft: 5,
                                                    }}
                                                />
                                            </Button>
                                        </Tooltip>

                                    {/* <Tooltip title={'New Opportunity'}>
                                        <IconButton
                                            onClick={() =>
                                                this.handleOpen(
                                                    userData ===
                                                        null
                                                        ? null
                                                        : userData.strategic_business_unit
                                                )
                                            }
                                            className={classes.add}
                                        >
                                            <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip> */}
                                </Grid>
                            </Grid>

                            <Divider variant="middle" />
                            <List className={classes.root}>
                                <Grid
                                    container
                                    spacing={0}
                                    justify="space-evenly"
                                >
                                    {this.getOpportunitiesList()}
                                </Grid>
                            </List>
                            {this.getMoreButton()}
                        {/* </Card> */}
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}
Opportunities.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Opportunities)