import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTimes,
    faArrowAltCircleRight,
} from '@fortawesome/pro-light-svg-icons'
import { PostData } from '../../../services/PostData'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import MenuItem from '@material-ui/core/MenuItem'
import FormWrapper from '../../Reusable/FormWrapper'
import NumberFormat from 'react-number-format'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
})

class EditServiceForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            services: [],
            dropdown: [],

        }
        this.handleCChange = this.handleCChange.bind(this)
        this.handleFSave = this.handleFSave.bind(this)
        
        
    }

    componentWillMount() {
        this.setState({ services: this.props.formData.services })
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ services: this.props.formData.services })
    }

    handleNumChange = event => {
        let value = event.target.value.replace(/,/g , '')
        if(isNaN(value) ){
            return
        }
        let newfields = this.state.services
        
        let index = this.state.services.findIndex(element =>element.id == event.target.name)
        
        newfields[index].meter_count = value
        this.setState({ fields: newfields })
    }

    handleCChange = event => {
        let newActive = this.state.services
        let index = this.state.services.findIndex(element =>element.id == event.target.id)
        newActive[index].active = (Number(newActive[index].active) ? 0 : 1)
        this.setState({ services: newActive })
    }
    

    handleSave = () => {
        this.handleFSave()
        return true
    }

    toTitleCase = str =>{
        str= str.replace(/_/g, ' ')
        return str.replace(
            /([^\W_]+[^\s-]*) */g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    //saves the fields
    handleFSave = () => {
        let length = this.state.services.length
        let count = 0

        this.state.services.forEach(service =>{
            const data = {
                action: 'update_record',
                recordid: service.id,
                crm_id: this.props.crmData.crm_id,
                service_type: service.service_type,
                meter_count: service.meter_count,
                active: service.active,
                modified_by: JSON.parse(sessionStorage.getItem('userData')).userid,
                modified_date: this.getCurrentDate(),
            }

            PostData('CRM/ajax/ajaxCRM_UtilityServices.php', data).then(
                response => {
                    if(response.result){
                        count++

                        if (count === length) {
                            this.props.updateComponent('Services') 
                        }
                    }
                }
            )
        })
    }

    getCurrentDate(separator = '-') {
        let newDate = new Date()
        let date = newDate.getDate()
        if (date < 10) {
            date = String(date)
            date = '0' + date
        }
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()

        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`
    }

    handleClose = () => {
        this.props.handleFormClose()
    }

    listServices = () =>{
        const { classes } = this.props
        let array = []
        return(
            this.state.services.map(service =>(
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={1}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        key={service}
                                        checked={Number(service.active)}
                                        id={service.id}
                                        onClick={this.handleCChange}
                                    />
                                }
                                //label={sbu}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Typography
                                component="h6"
                                color="inherit"
                                className={classes.textField}
                            >
                                {this.toTitleCase(service.service_type)}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <NumberFormat 
                                id="meter_count"
                                name={service.id}
                                label="Meter Count"
                                fullWidth
                                className={classes.textField}
                                customInput={TextField} 
                                value={service.meter_count} 
                                thousandSeparator={true}
                                onChange={this.handleNumChange}
                                decimalScale={0}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                disabled
                                id="modified_date"
                                name='modified_date'
                                label="Modified"
                                fullWidth
                                className={classes.textField}
                                value={service.modified_date} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider variant="fullwidth" />
                        </Grid>
                    </Grid>
                </Grid>
            ), this
        ))

    }


    render() {
        
        const { classes } = this.props
        return (

            <FormWrapper
                invalid={this.state.invalid}
                type="Service"
                name='Services'
                handleSave={this.handleSave}
                handleClose={this.handleClose}
                //alwaysClose = {false}
            >
                <Grid container spacing={1}>   
                    
                    {this.listServices()}
                </Grid> 

                

            </FormWrapper>

            //             {/* </Grid>
            //         </Card>
            //      </Grid>
            //  </Grid>
            //  </React.Fragment> */}
        )
    }
}

EditServiceForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EditServiceForm)
