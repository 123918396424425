import React from 'react'
import PropTypes from 'prop-types'

import { PostData } from '../../../services/PostData'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import { addValidators, validate } from '../../../utils/Validate'

import { faSave } from '@fortawesome/free-solid-svg-icons'

import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import FormWrapper from '../../Reusable/FormWrapper'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
})

class EditSystemsForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            systemData: {
            },
            inactive: [],
            dropdown: [],
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSave = this.handleSave.bind(this)
    }

    componentWillMount() {

        this.setState({ systemData: this.props.formData.systems})        
        //this.setState({ inactive: this.props.formData.inactive })
        this.getDropdown()
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ systemData: this.props.formData.systems})
        this.getDropdown()
    }

    //handles all changes
    handleChange = event => {
        
        let index = this.state.systemData.findIndex(element =>element.system_recordid == event.target.name)
        
        let newSystemData = this.state.systemData
        

        newSystemData[index].created_by_userid = JSON.parse(sessionStorage.getItem('userData')).userid
        newSystemData[index].created_datetime = this.getCurrentDate()
        
        if(event.target.value == 'None'){
            newSystemData[index].created_by_userid = null
            newSystemData[index].created_datetime = null
        }

        newSystemData[index].system_name = event.target.value

        const data = {
            action: 'updateSystemInfoRecord',
            userid: JSON.parse(sessionStorage.getItem('userData')).userid,
            system_recordid: newSystemData[index].system_recordid,
            system_name: newSystemData[index].system_name,
            active: newSystemData[index].active,
            notes: newSystemData[index].notes,
            created_datetime: newSystemData[index].created_datetime,
            created_by_userid: newSystemData[index].created_by_userid,
        }

        PostData('CRM/ajax/ajaxCRM_SystemInfo.php', data).then(response => {
            this.props.updateComponent('Systems')
        })


        
        this.setState({ systemData: newSystemData })


        
    }

    //saves fields
    handleSave = () => {
        this.state.systemData.forEach(system =>{
            const data = {
                action: 'updateSystemInfoRecord',
                userid: JSON.parse(sessionStorage.getItem('userData')).userid,
                system_recordid: system.system_recordid,
                system_name: system.system_name,
                active: system.active,
                notes: system.notes,
                created_datetime: system.created_datetime,
                created_by_userid: system.created_by_userid,
            }

            PostData('CRM/ajax/ajaxCRM_SystemInfo.php', data).then(response => {
                //this.handleClose()
                
            })
        })
        this.props.updateComponent('Systems')
        return true
           
    }


    //closes sidebar
    handleClose = () => {
        this.props.handleFormClose()
    }
    getCurrentDate(separator = '-') {
        let newDate = new Date()
        let date = newDate.getDate()
        if (date < 10) {
            date = String(date)
            date = '0' + date
        }
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()

        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`
    }


    /*deleteSystem = () => {
        let newSystemData = this.state.systemData
        newSystemData.active = 0
        this.setState({ systemData: newSystemData })
        this.handleSave()
    }*/

    //gets all dropdowns for the page
    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'SYSTEMS'
        }

        PostData('Migrations/dropdowns/ajax/ajaxGetDropdownItems.php', data).then(
            response => {
                if(response.result){
                    this.setState({dropdown: response.dropdowns})
                }

            }
        )
    }

    //displays specific dropdown on page
    viewDropdown = (control, system, name) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })
        return (
            <TextField
                select
                fullWidth
                name={system.system_recordid}
                //label='System Name'
                className={classes.textField}
                onChange={this.handleChange}
                value={system.system_name }
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.name}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }


    listSystems = () =>{
        const { classes } = this.props
        return(
            this.state.systemData.map(system =>(
                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <Typography
                                component="h6"
                                color="inherit"
                                className={classes.textField}
                            >
                                {system.system_type.replace(/[_]/g , ' ')}
                            </Typography>
                        </Grid>
                        <Grid item xs={7}>
                            {this.viewDropdown(system.system_type.toUpperCase(), system, 'system_name')}
                        </Grid>

                        <Grid item xs={12}>
                            <Divider variant="fullwidth" />
                        </Grid>
                    </Grid>
                </Grid>
            ), this
        ))

    }

    render() {
        
        const { classes } = this.props

        return (

            <FormWrapper
                invalid={this.state.invalid}
                type="System"
                name='Systems'
                bottomless = {true}
                //handleSave={this.handleSave}
                handleClose={this.handleClose}
            >
                {this.listSystems()}

                
            </FormWrapper>

            //         </Grid>
            //     </Card>
            // </Grid>
        )
    }
}

EditSystemsForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EditSystemsForm)
