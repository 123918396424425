import React, { Component } from 'react'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { styles } from '../Layout/styles'

import { PostData } from '../../services/PostData'
import TopBar from '../Layout/TopBar/TopBar'
import Steps from './Steps'

// This is the layout for the campaign section, which is different then the other sections, but uses some of the same components
class CampaignInquiry extends Component {
    state = {
        title: '',
        // Get the id from the url
        id: this.props.match.params.id 
    }

    componentWillMount() {
        const data = {
            action: 'getMaster',
            id: this.state.id
        }

        // Get the campaign info
        PostData('master_campaign/ajax/ajaxMasterCampaign.php', data).then(
            response => {
                if (response.result) {
                    // Set the title on the app bar to be the name of the campaign
                    this.setState({ title: response.master.name })
                }
            }
        )
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <CssBaseline />
                <TopBar hideDrawerButton={true} title={this.state.title} />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Steps id={this.state.id} />
                </main>
            </div>
        )
    }
}

CampaignInquiry.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CampaignInquiry)
