import React from 'react'
import PropTypes from 'prop-types'
import Moment from 'react-moment'
import { Link } from 'react-router-dom'

import { PostData } from '../../../services/PostData'

import { withStyles, rgbToHex } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import DateFnsUtils from '@date-io/date-fns'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import ReactToPrint from 'react-to-print';

import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import {
    faChevronDown,
    faChevronUp,
    faMapMarkedAlt,
    faBurn,
    faAward,
    faBan,
    faFilePdf,
    faPrint,
    faSave,
    faReceipt,
    faPlus
} from '@fortawesome/pro-light-svg-icons'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { faTimes, faCopy, faLayerPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Paper from '@material-ui/core/Paper'
import IconButton from '@material-ui/core/IconButton'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import { closestIndexTo, isFirstDayOfMonth } from 'date-fns'

import Tooltip from '@material-ui/core/Tooltip'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons'
import { faCheck } from '@fortawesome/pro-light-svg-icons'
import { addValidators, validate } from '../../../utils/Validate'
import { FilePicker } from 'react-file-picker'
import CKEditor from 'ckeditor4-react'
import { DropzoneArea } from 'material-ui-dropzone'
import NumberFormat from 'react-number-format'
import Divider from '@material-ui/core/Divider'
import FileUploader from '../../Reusable/FileUploader'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'

import { Collapse } from '@material-ui/core'
import FormWrapper from '../../Reusable/FormWrapper'
import SwipeableTabs from '../../Reusable/SwipeableTabs'
import Notes from '../Notes/Notes'
import NoteForm from '../Notes/NoteForm'
import ProductRecap from '../Products/ProductRecap'
import { isNullOrUndefined } from 'util'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import SaveWarning from '../../Reusable/SaveWarning'
import {CSVLink, CSVDownload} from 'react-csv';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    modifiedTextField: {
        marginTop: 5,
        marginRight: 5,
        marginLeft: 5,
        paddingRight: 15,
    },
    modifiedText: {
        marginLeft: 5,
        paddingRight: 15,
        fontSize: 12,
    },
    
    table: {
        border: '2px solid rgb(232,232,232)',
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1),
        //marginLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        width: '98%',
        background: 'rgb(248,248,248)',
    },
    saasTable: {
        border: '2px solid rgb(232,232,232)',
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        width: '98%',
        background: 'rgb(248,248,248)',
    },
    row: {
        background: 'white',
    },
    rowHeader: {
        background: theme.palette.secondary.light,
        color: 'white',
    },
    rowHeaderTotal: {
        background: 'rgb(235,235,235)',
        fontWeight: 500,
    },
    cardButtons: {
        color: '#0166ff',
    },
    rowGrandTotal: {
        background: 'rgb(230,230,230)',
        fontWeight: 500,
    },
    cellHeaderTotal: {
        fontWeight: 500,
    },
    cellGrandTotal: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(15),
    },
    cellHeader: {
        fontWeight: 500,
        color: 'white',
    },
    list: {
        marginTop: theme.spacing(1),
        borderTop: '2px solid rgb(232,232,232)',
    },
    paper: {
        marginTop: theme.spacing(1),
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        marginTop: theme.spacing(1),
    },
    wonIt: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        backgroundColor: '#ecf9f1',
        border: '4px solid #ecf9f1',
    },
    lostIt: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        backgroundColor: '#ffe6e6',
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
        
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    add: {
        color: 'black',
        padding: 5,
        margin: 1,
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
    buttons: {
        color: 'white',
        backgroundColor: theme.palette.primary.dark,

        marginTop: 4,
        marginBottom: 4,
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 500,
    },
    total: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 500,
        paddingLeft: 23,
    },
    wrongtotal: {
        color: 'red',
        fontSize: theme.typography.pxToRem(15),
        fontWeight: 500,
        paddingLeft: 23,
    },
    specialText: {
        marginLeft: 5,
        paddingRight: 15,
        fontSize:12,
        color: 'white'
    },
    specialText2: {
        marginLeft: 5,
        paddingRight: 15,
        fontSize:12,
        color: 'rgb(240,240,240)',
        cursor: 'context-menu',
    },
    highlight: {
        cursor: 'pointer',
    },
    lines: {
        cursor: 'pointer',
        backgroundColor: theme.palette.secondary.paper,
    },
    selected: {
        backgroundColor: 'rgb(240,240,240)',
        color: theme.palette.secondary.main.dark,
        fontWeight: '500',
    },
    fileBox: {
        minHeight: '10px',
    },
    locked: {
        background: 'rgb(248,248,248)',
    },
    cellGrandTotal: {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(15),
    },
    errorButton: {
        backgroundColor: '#f44336',
        color: 'white'
    },
    scrolling: {
        position: 'sticky',
        top: 0
    },
    save: {
        color: 'white',
        //textTransform: 'capitalize',
        // fontSize: 19,
        marginRight: 10,
    },
    icon: {
        marginRight: 5,
        marginBottom: 2,
    },
})

class Saas extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            locked: true,
            dropdown: [],
            header: {},
            recap: {},
            includeQuoteValue: {
                net_software_price: true,
                professional_fees: true,
                hardware: true,
                training: true,
                custom: true,
            },
        }
    }
    
    componentWillMount() {
        this.getDropdown()
        this.getQuoteRecap()
    }

    // componentWillReceiveProps(nextProps) {
    // }

    // Gets all dropdowns in the form
    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'QUOTE',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }

    //Server call for recap table data
    getQuoteRecap = () => {
        const data = {
            action: 'getQuoteRecapSection',
            current_milestone_template: '',
            sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
            quote_number: this.props.quote.quote_number,
        }

        PostData('Quotes/ajax/ajaxhugQuoteSystem2019.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ recap: response.quoteRecapArr })
                }
            }
        )
    }

    /////////////////////////////////////////////////////////////////////////////////////
    //SAAS CALC
    /////////////////////////////////////////////////////////////////////////////////////

    //Shows dropdowns in saas tab
    viewSaasDropdown = (control, id, name) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })

        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                label={name}
                className={classes.textField}
                onChange={this.handleSaasCalcChange}
                value={this.state.header[id]}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    //calculates total quote value
    calculateTotalFees = (type) =>{
        let total = 0
        if(this.state.includeQuoteValue[type] === true){
            for (let product in this.state.recap) {
                total = parseFloat(total) + parseFloat(this.state.recap[product][type])
            }
        }
        return total
    }

    //changes to saas values
    handleSaasCalcChange = event => {
        let change = this.state.header
        let value = event.target.value.replace(/[,$]/g, '')
        change[event.target.name] = value
        this.setState({ header: change })
    }

    //changes for what to include in the quote value
    handleSaasIncludeChange = id =>{
        
        let change = this.state.includeQuoteValue
        change[id] = !this.state.includeQuoteValue[id]
        this.setState({ includeQuoteValue: change })
    }

    //Calculates totals for each row on yearly breakdown table
    calculateYearlyBreakdown = () =>{
        let year
        let balance
        //let maintenance
        let interest
        //let saas
        let annualFee = 0
        let userFee = 0
        let monthlyPayment = 0
        let total = 0
        let rows = []

        this.props.product_details.map(product => {
            if (product.qty > 0 && product.addon_option === 'false') {
                annualFee += parseFloat(product.lic_fee_amount)
                userFee += parseFloat(product.user_fees_amount)
            }
        })

        if (parseFloat(this.state.header.saas_total_saas) > 0 && parseFloat(this.state.header.saas_interest_rate) > 0) {
            interest = (parseFloat(this.state.header.saas_total_saas) * parseFloat(this.state.header.saas_interest_rate) / 100) 
                / parseFloat(this.state.header.saas_term_years);
        } else {
            interest = 0;
        }

        console.log(interest);

        for(let i = 1; i <= this.state.header.saas_term_years; i++){
            year = i
            balance = parseFloat(this.state.header.saas_total_saas) / parseFloat(this.state.header.saas_term_years) * (parseFloat(this.state.header.saas_term_years) - i +1)
            // maintenance = (this.calculateTotalFees('net_software_price') * 0.25) + (this.calculateTotalFees('net_software_price') * 0.25 * (parseFloat(this.state.header.saas_annual_maintenance_pct_increase) / 100 * (i - 1)))
            // saas = parseFloat(this.state.header.saas_total_saas) / parseFloat(this.state.header.saas_term_years)

            if (i === 1) {
                monthlyPayment = parseFloat(balance) / (parseFloat(this.state.header.saas_term_years) * 12)
            } else {
                annualFee = annualFee + (annualFee * (parseFloat(this.state.header.saas_annual_maintenance_pct_increase) / 100));
            }

            // total = annualFee + userFee + interest + (monthlyPayment * 12)
            total = annualFee + userFee + interest + (monthlyPayment)
            rows.push({year, balance, annualFee, userFee, interest, monthlyPayment, total})
        }
        this.calculateYearlyBreakdownTotals(rows)
        return this.yearlyBreakdownTable(rows)

    }

    //Calculates bottom totals of yearly breakdown
    calculateYearlyBreakdownTotals = (rows) =>{
        // let maint = 0
        let interest = 0
        // let saas = 0
        let annualFee = 0
        let userFee = 0
        let monthlyPayment = 0
        let total = 0

        rows.forEach(row =>{
            // maint = maint + row.maintenance
            interest = interest + row.interest
            // saas = saas + row.saas
            annualFee = annualFee + row.annualFee
            userFee = userFee + row.userFee
            monthlyPayment = monthlyPayment + row.monthlyPayment
            total = total + row.total
        })

        // this.state.header.saas_total_ps_support = maint
        this.state.header.saas_total_interest = interest
        // this.state.header.saas_total_saas = saas
        this.state.header.saas_annual_fee = annualFee
        this.state.header.saas_user_fee = userFee
        this.state.header.saas_monthly_payments = monthlyPayment
        this.state.header.saas_total = total
    }


    //The yearly breakdown rows
    yearlyBreakdownTable = (rows) =>{
        const { classes } = this.props
        return (
            rows.map(item => (
            <TableRow>
                <TableCell  align="right">
                    <NumberFormat
                        value={item.year}
                        displayType={'text'}
                    />
                </TableCell>
                <TableCell align="right">
                    <NumberFormat
                        value={item.balance}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                {/* <TableCell align="right">
                    <NumberFormat
                        value={item.maintenance}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell> */}
                <TableCell align="right">
                    <NumberFormat
                        value={item.annualFee}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                <TableCell align="right">
                    <NumberFormat
                        value={item.userFee}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                <TableCell align="right">
                    <NumberFormat
                        value={item.interest}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                {/* <TableCell align="right">
                    <NumberFormat
                        value={item.saas}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell> */}
                <TableCell align="right">
                    <NumberFormat
                        value={item.monthlyPayment}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
                <TableCell align="right" className={classes.cellHeaderTotal}>
                    <NumberFormat
                        value={item.total}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </TableCell>
            </TableRow>))
            
        )
    }

    render() {
        this.state.totalQuoteAmount =  this.calculateTotalFees('net_software_price') +
        this.calculateTotalFees('professional_fees')
        this.state.header.saas_total_saas = parseFloat(this.state.totalQuoteAmount) - parseFloat(this.state.header.license_down_payment)
        const { classes } = this.props

        return (
            <React.Fragment>

                <Grid container spacing={1} className={classes.body}>

                    <Grid item xs={12} sm={6} md={6}>
                        <Card>
                            <Grid item xs={12} sm={12}>
                                <Typography 
                                    component="h1" 
                                    variant="h6" 
                                    color="inherit"
                                    className={classes.textField}>
                                    Quote Value
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Table className={classes.saasTable} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Type</TableCell>
                                            <TableCell align="right">Cost</TableCell>
                                            <TableCell align="right">Include</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>License Fees</TableCell>
                                            <TableCell  align="right">
                                                <NumberFormat
                                                    value={this.calculateTotalFees('net_software_price')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => {this.handleSaasIncludeChange('net_software_price')}} >
                                                    {this.state.includeQuoteValue.net_software_price ? <FontAwesomeIcon icon={faCheck} size="1x"/> : <FontAwesomeIcon icon={faTimes} size="1x"/>}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>PS Fees</TableCell>
                                            <TableCell  align="right">
                                                <NumberFormat
                                                    value={this.calculateTotalFees('professional_fees')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => {this.handleSaasIncludeChange('professional_fees')}} >
                                                    {this.state.includeQuoteValue.professional_fees ? <FontAwesomeIcon icon={faCheck} size="1x"/> : <FontAwesomeIcon icon={faTimes} size="1x"/>}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Hardware</TableCell>
                                            <TableCell align="right">
                                                <NumberFormat
                                                    //value={this.calculateTotalFees('professional_fees')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => {this.handleSaasIncludeChange('hardware')}} >
                                                    {this.state.includeQuoteValue.hardware ? <FontAwesomeIcon icon={faCheck} size="1x"/> : <FontAwesomeIcon icon={faTimes} size="1x"/>}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Training</TableCell>
                                            <TableCell align="right">
                                                <NumberFormat
                                                    //value={this.calculateTotalFees('professional_fees')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => {this.handleSaasIncludeChange('training')}} >
                                                    {this.state.includeQuoteValue.training ? <FontAwesomeIcon icon={faCheck} size="1x"/> : <FontAwesomeIcon icon={faTimes} size="1x"/>}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>Custom</TableCell>
                                            <TableCell align="right">
                                                <NumberFormat
                                                    //value={this.calculateTotalFees('professional_fees')}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button onClick={() => {this.handleSaasIncludeChange('custom')}} >
                                                    {this.state.includeQuoteValue.custom ? <FontAwesomeIcon icon={faCheck} size="1x"/> : <FontAwesomeIcon icon={faTimes} size="1x"/>}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className={classes.cellGrandTotal}>Total</TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}>
                                                <NumberFormat
                                                    value={this.state.totalQuoteAmount
                                                    }
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                    prefix={'$'}
                                                    decimalScale={2}
                                                    fixedDecimalScale={true}
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.cellGrandTotal}></TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Grid>
                            
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Card>
                        <Grid container spacing={1}>
                                <Grid item xs={12} sm={12}>
                                    <Typography 
                                        component="h1" 
                                        variant="h6" 
                                        color="inherit"
                                        className={classes.textField}>
                                        SaaS Calculation
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    {this.viewSaasDropdown('SAAS_YEARS','saas_term_years','Agreement Years')}
                                </Grid>
                                <Grid item xs={12} sm={4} md={4}>
                                    {this.viewSaasDropdown('SAAS_INTEREST','saas_interest_rate','Interest Rate')}
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <NumberFormat
                                        disabled={this.state.locked}
                                        id='license_down_payment'
                                        name="license_down_payment"
                                        label="Down Payment"
                                        fullWidth
                                        className={classes.textField}
                                        customInput={TextField}
                                        value={this.state.header.license_down_payment}
                                        onChange={this.handleSaasCalcChange}
                                        thousandSeparator={true}
                                        decimalScale={2}
                                        prefix={'$'}
                                        fixedDecimalScale={true}
                                        defaultValue={0.0}
                                        allowEmptyFormatting={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <TextField
                                        disabled
                                        id="saas_annual_maintenance_pct"
                                        name="saas_annual_maintenance_pct"
                                        label="Annual Maintenance"
                                        fullWidth
                                        className={classes.textField}
                                        value={this.state.header.saas_annual_maintenance_pct}
                                        onChange={this.handleSaasCalcChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    {this.viewSaasDropdown('SAAS_MAINT_PCT_INCR','saas_annual_maintenance_pct_increase','Annual Maintenance Increase')}
                                </Grid>
                                <Grid item xs={12}>
                                    <Divider variant='middle' />
                                </Grid>

                                {/*This wasnt updating properly, shows yearly and monthly totals*/}

                                {/*<Grid item xs={12} >
                                    <Table className={classes.saasTable} size="small">
                                        <TableBody>
                                            <TableRow>
                                                <TableCell>Total Project Cost</TableCell>
                                                <TableCell  align="right">
                                                    <NumberFormat
                                                        value={this.state.header.saas_total_saas + this.state.header.saas_total_interest + this.state.header.saas_total_ps_support}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Annual Project Cost</TableCell>
                                                <TableCell  align="right">
                                                    <NumberFormat
                                                        value={(this.state.header.saas_total_saas + this.state.header.saas_total_interest + this.state.header.saas_total_ps_support) / this.state.header.saas_term_years}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>Monthly Project Cost</TableCell>
                                                <TableCell  align="right">
                                                    <NumberFormat
                                                        value={(this.state.header.saas_total_saas + this.state.header.saas_total_interest + this.state.header.saas_total_ps_support) / this.state.header.saas_term_years /12}
                                                        displayType={'text'}
                                                        thousandSeparator={true}
                                                        prefix={'$'}
                                                        decimalScale={2}
                                                        fixedDecimalScale={true}
                                                    />
                                                </TableCell>
                                                
                                            </TableRow>
                                        </TableBody>
                                                </Table>
                                </Grid>*/}
                                
                            </Grid>
                        </Card>
                    </Grid>
                    <Grid item xs={12} >
                        <Card>
                            <Grid item xs={12} sm={12}>
                                <Typography 
                                    component="h1" 
                                    variant="h6" 
                                    color="inherit"
                                    className={classes.textField}>
                                    Yearly Breakdown
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <Table className={classes.saasTable} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Year</TableCell>
                                            <TableCell align="right">Balance</TableCell>
                                            {/* <TableCell align="right">Maintenance</TableCell> */}
                                            <TableCell align="right">Annual Fee</TableCell>
                                            <TableCell align="right">User License Fee</TableCell>
                                            <TableCell align="right">Interest</TableCell>
                                            {/* <TableCell align="right">SaaS</TableCell> */}
                                            <TableCell align="right">Momthly Payment</TableCell>
                                            <TableCell align="right">Total Annual Fees</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {this.calculateYearlyBreakdown()}
                                        <TableRow>
                                            <TableCell className={classes.rowHeaderTotal}>Total</TableCell>
                                            <TableCell className={classes.rowHeaderTotal}/>
                                            {/* <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_total_ps_support}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell> */}
                                            <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_annual_fee}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_user_fee}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_total_interest}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell>
                                            {/* <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_total_saas}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell> */}
                                            <TableCell align="right" className={classes.rowHeaderTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_monthly_payments}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell>
                                            <TableCell align="right" className={classes.rowGrandTotal}>
                                                <NumberFormat
                                                    displayType={'text'}
                                                    value={this.state.header.saas_total}
                                                    thousandSeparator={true}
                                                    decimalScale={2}
                                                    prefix={'$'}
                                                    fixedDecimalScale={true}
                                                    defaultValue={0.0}
                                                    allowEmptyFormatting={true}
                                                    
                                                />
                                            </TableCell>
                                        </TableRow>
                                        
                                    </TableBody>
                                </Table>
                            </Grid>
                            
                        </Card>
                    </Grid>

                    
                </Grid>
            </React.Fragment>
        )
    }
}

Saas.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Saas)
