import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'
import FullScreenDialog from '../../Reusable/FullScreenDialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import TextField from '@material-ui/core/TextField'
import TableCell from '@material-ui/core/TableCell'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import Slide from '@material-ui/core/Slide'
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants'
import ServiceForm from './ServiceForm'
import Button from '@material-ui/core/Button'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTint,
    faTintSlash,
    faLightbulbExclamation,
    faWifi,
    faNetworkWired,
    faTvRetro,
    faSatelliteDish,
    faTrashAlt,
    faPhoneVolume,
    faWaterLower,
    faThunderstorm,
    faSun,
    faTrafficLight,
    faLightbulbOn,
    faLightbulbSlash,
    faLightbulb,
    faBurn,
    faCheck
} from '@fortawesome/pro-regular-svg-icons'
import { Tooltip } from '@material-ui/core'
import StreetLight from '../../../images/icons/street-light.png'
import {
    faGripHorizontal,
    faEdit,
    faChevronUp,
    faChevronDown,
    faPlusCircle,
    faGripLines
} from '@fortawesome/pro-light-svg-icons'
import TableRow from '@material-ui/core/TableRow'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import Divider from '@material-ui/core/Divider'
import NumberFormat from 'react-number-format'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    Tooltip: {
        backgroundColor: theme.palette.common.white,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    meterCount: {
        fontSize: 16,
    },
    add: {
        padding: 7,
        marginRight: 5,
    },
    addGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    add: {
        padding: 7,
        marginRight: 5,
    },
    highlight: {
        cursor: 'pointer',
    },
    faDisabled: {
        opacity: 0.3,
    }
})

function Transition(props) {
    return <Slide direction="up" {...props} />
}

function createColumn(
    recordid,
    service_type,
    meter_count,
    active,
    modified_date,
    verified,
    sbu,
) {
    return {
        id: recordid,
        service_type,
        meter_count,
        active,
        modified_date,
        verified,
        sbu,
    }
}

class Services extends Component {
    constructor(props) {
        super(props)

        this.state = {
            columns: [],
            services: [],
            active: {},
            client: [],
            more: false,
            gridView: false,
            bubbleView: true,
        }
    }

    componentWillMount() {
        if (this.props.crmData !== undefined) {
            this.setState({ crmData: this.props.crmData })
            this.getColumns(this.props.crmData.crm_id)
            this.getClient(this.props.crmData)
        }
    }
    componentWillReceiveProps(nextProps) {
        if (
            nextProps.crmData !== this.state.crmData ||
            nextProps.update === 'Services'
        ) {
            this.setState({ crmData: nextProps.crmData })
            this.getColumns(nextProps.crmData.crm_id)
            this.getClient(nextProps.crmData.crm_id)
            nextProps.update === 'Services' && nextProps.updateComponent('')
        }
    }

    //if id == 0, it will open as a new service
    handleOpen = id => {
        if (id === 0) {
            this.props.setFormData(
                {
                    service: '',
                    actives: [],
                    client: this.state.client,
                    activeServices: this.state.columns,
                },
                'Service'
            )
        } else {
            this.props.setFormData(
                {
                    service: this.state.services[id],
                    actives: this.state.active,
                    client: this.state.client,
                },
                'Service'
            )
        }
    }

    handleEditOpen = () => {
        this.props.setFormData({ services: this.state.columns }, 'EditService')
    }

    convertNumbers = value => {
        return Number(value).toLocaleString(navigator.language, {
            minimumFractionDigits: 0,
        })
    }

    toTitleCase = str => {
        str = str.replace(/_/g, ' ')
        return str.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        })
    }

    //Gets the active BU for given service type
    getActive = id => {
        const data = {
            action: 'getSBUUtilityServices',
            recordid: id,
        }

        PostData('CRM/ajax/ajaxCRM_UtilityServices.php', data).then(
            response => {
                if (response.result) {
                    this.setState({ active: response.utility_services_states })
                    this.handleOpen(id)
                }
            }
        )
    }

    //Gets all the sbu that have this company as a client
    getClient = id => {
        if (id) {
            const data = {
                action: 'getClientInfo',
                crm_id: id,
                strategic_business_unit: JSON.parse(
                    sessionStorage.getItem('userData')
                ).strategic_business_unit,
            }

            PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data).then(response => {
                if (response.result && response.AllClientInfo) {
                    let sbuArr = []

                    response.AllClientInfo.forEach(client => {
                        if (!sbuArr.includes(client.strategic_business_unit)) {
                            sbuArr.push(client.strategic_business_unit)
                        }
                    })

                    this.setState({ client: sbuArr })
                }
            })
        }
    }

    //Gets all information (services, meter count, recordid, active)
    getColumns = id => {
        if (id) {
            const data = {
                action: 'getUtilityServicesWithSbu',
                crm_id: id,
            }

            PostData('CRM/ajax/ajaxCRM_UtilityServices.php', data).then(
                response => {
                    if (response.result && response.utility_services_states) {
                        let columns = []
                        let services = {}

                        response.utility_services_states.forEach(service => {
                            columns.push(
                                createColumn(
                                    service.recordid,
                                    service.service_type,
                                    service.meter_count,
                                    service.active,
                                    service.modified_date,
                                    service.verified,
                                    service.sbu,
                                )
                            )
                            //console.log(service)

                            services[service.recordid] = service
                        })

                        this.setState({ columns: columns })
                        this.setState({ services: services })
                    }
                }
            )
        }
    }

    handleView1Change = () => {
        if (this.state.bubbleView == false) {
            this.setState({ bubbleView: true, gridView: false })
        }
    }
    handleView2Change = () => {
        if (this.state.gridView == false) {
            this.setState({ bubbleView: false, gridView: true })
        }
    }

    //Associates the service type with its icon
    utilityIcon = (service, sbu) => {
        const { classes } = this.props

        // console.log(service)
        let isDisabled = service.sbu === undefined || service.sbu === null || service.sbu === '' || (sbu.toLowerCase() != service.sbu.toLowerCase())

        if (service.service_type == 'WATER') {
            return <FontAwesomeIcon icon={faTint} size="3x" className={isDisabled ? classes.faDisabled : ''}/>
        }
        if (service.service_type == 'ELECTRIC') {
            return <FontAwesomeIcon icon={faLightbulb} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'GAS') {
            return <FontAwesomeIcon icon={faBurn} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'WASTE_WATER') {
            return <FontAwesomeIcon icon={faTintSlash} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'SECURITY_LIGHTS') {
            return <FontAwesomeIcon icon={faLightbulbExclamation} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'FIBER') {
            return <FontAwesomeIcon icon={faNetworkWired} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'INTERNET') {
            return <FontAwesomeIcon icon={faWifi} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'CABLE') {
            return <FontAwesomeIcon icon={faTvRetro} size="3x"className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'SATELLITE') {
            return <FontAwesomeIcon icon={faSatelliteDish} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'SANITATION') {
            return <FontAwesomeIcon icon={faTrashAlt} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'TELEPHONE') {
            return <FontAwesomeIcon icon={faPhoneVolume} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'WATER_IRRIGATION') {
            return <FontAwesomeIcon icon={faWaterLower} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'STORM_DRAINAGE') {
            return <FontAwesomeIcon icon={faThunderstorm} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'SOLAR') {
            return <FontAwesomeIcon icon={faSun} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        }
        if (service.service_type == 'STREET_LIGHTS') {
            return (
                <img
                    src={StreetLight}
                    style={{
                        height: '100%',
                        width: '100%',
                    }}
                    className={isDisabled ? classes.faDisabled : ''}
                />
            )
        }
        if (service.service_type == 'ELECTRIC_NONOUTAGE') {
            return <FontAwesomeIcon icon={faLightbulbSlash} size="3x" className={isDisabled ? classes.faDisabled : ''} />
        } else {
            return service.service_type
        }
    }

    //creates each service type button on the screen if it has a meter count > 0
    activeUtility = (service, sbu) => {
        const { classes } = this.props
        if (service.active == 1) {
            if (this.state.bubbleView == true) {
                return (
                    <Grid item xs={6} sm={4} md={2} lg={2}>
                        <ListItem>
                            <Button
                                onClick={() => {
                                    this.getActive(service.id)
                                }}
                            >
                                <Tooltip
                                    title={this.toTitleCase(
                                        service.service_type
                                    )}
                                >
                                    <ListItemText
                                        primary={this.utilityIcon(service, sbu)}
                                        secondary={
                                            <div className={classes.meterCount}>
                                                <NumberFormat
                                                    value={service.meter_count}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                />
                                                {service.verified==='1' ? (<FontAwesomeIcon icon={faCheck} size="1x" />) : ''}
                                            </div>
                                        }
                                    />
                                </Tooltip>
                            </Button>
                        </ListItem>
                    </Grid>
                )
            } else {
                return (
                    <TableRow
                        onClick={() => {
                            this.getActive(service.id)
                        }}
                    >
                        <TableCell className={classes.highlight}>
                            {this.toTitleCase(service.service_type)}
                        </TableCell>
                        <TableCell>
                            <NumberFormat
                                value={service.meter_count}
                                displayType={'text'}
                                thousandSeparator={true}
                            />
                            {service.verified==='1' ? (<FontAwesomeIcon icon={faCheck} size="1x" />) : ''}
                        </TableCell>
                        <TableCell>{service.modified_date}</TableCell>
                    </TableRow>
                )
            }
        } else {
            return false
        }
    }

    viewGridView = inside => {
        const { classes } = this.props

        if (this.state.gridView == true) {
            return (
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Meter Count</TableCell>
                            <TableCell>Modified</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{inside}</TableBody>
                </Table>
            )
        }
    }

    //helper function to create the services on screen
    getUtility = () => {
        let sbu = JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit

        let arr = this.state.columns.filter(service => service.active == 1)
        arr = arr.map(service => this.activeUtility(service, sbu))

        let missing = arr.length % 6
        let count = 0

        if (missing > 0) {
            for (let i = 0; i < missing; i++) {
                arr[arr.length] = (
                    <React.Fragment>
                        <Grid item xs={true} />
                    </React.Fragment>
                )
                count++
            }        
        }

        let newArr =[] 
        if (this.state.bubbleView == true) {
            if (!this.state.more) {
                newArr = arr.slice(0, 6)
            }else{
                newArr = arr
            }
            if (arr.length <= 6) {
                return newArr
            } else {
                return (
                    <React.Fragment>
                        {newArr}
                        {this.getMoreButton(arr.length - count)}
                    </React.Fragment>
                )
            }

        } else {
            if (!this.state.more) {
                newArr = arr.slice(0, 6)
            }else{
                newArr = arr
            }
            if (arr.length <= 6) {
                return this.viewGridView(arr)
            } else {
                return (
                    <React.Fragment>
                        {this.viewGridView(newArr)}
                        {this.getMoreButton(arr.length - count)}
                    </React.Fragment>
                )
            }
        }
    }
    handleToggle = () =>
        this.setState(prevState => {
            return { more: !prevState.more }
        })

    getMoreButton(length) {
        const { classes } = this.props

        return (
            <Grid container xs={12}>
                {length > 6 && (
                    <Button style={{ width: '100%' }} onClick={this.handleToggle}>
                    {this.state.more  ? (
                        <div>
                            <FontAwesomeIcon
                                icon={faChevronUp}
                                //size="2x"
                            />
                            &nbsp;1 to {length} of {length}
                        </div>
                    ) : (
                        <div>
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                //size="2x"
                            />
                            &nbsp;1 to 6 of {length}
                        </div>
                    )}
                </Button>)}
            </Grid>
        )
    }

    render() {
        const { classes } = this.props
        //console.log(this.state)
        return (
            <React.Fragment>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Card>
                            <div className={classes.secondary}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item /*xs ={10} md={11}*/>
                                        <Typography
                                            component="h1"
                                            variant="h6"
                                            color="inherit"
                                            className={classes.textField}
                                        >
                                            Services
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </div>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Tooltip title={'Bubble View'}>
                                        <IconButton
                                            onClick={this.handleView1Change}
                                            size="small"
                                            className={classes.add}
                                            style={this.state.bubbleView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripHorizontal}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>

                                    <Tooltip title={'Grid View'}>
                                        <IconButton
                                            onClick={this.handleView2Change}
                                            size="small"
                                            className={classes.add}
                                            style={this.state.gridView ? {backgroundColor: '#f1f1f1'} : null}
                                        >
                                            <FontAwesomeIcon
                                                icon={faGripLines}
                                                size="1x"
                                                color="black"
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>

                                <Grid item className={classes.addGrid}>
                                    <Tooltip title={'Add Service'}>
                                        <Button
                                            className={classes.add}
                                            onClick={() =>
                                                this.handleOpen(0)
                                            }
                                            style={{
                                                color: 'black'
                                            }}
                                        >
                                            Add Service
                                            <FontAwesomeIcon
                                                icon={faPlusCircle}
                                                color="black"
                                                style={{
                                                    marginLeft: 5,
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>

                                    <Tooltip title={'Bulk Edit'}>
                                        <Button
                                            onClick={this.handleEditOpen}
                                            className={classes.add}
                                            size="small"
                                        >
                                            Bulk Edit
                                            <FontAwesomeIcon
                                                icon={faEdit}
                                                size="1x"
                                                color="black"
                                                style={{
                                                    marginLeft: 5,
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Divider variant="middle" />

                            <Grid
                                container
                                spacing={1}
                                alignItems="center"
                                justify="space-around"
                            >
                                {this.getUtility()}
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>

                <div>
                    <Dialog
                        //fullScreen
                        fullWidth
                        maxWidth="lg"
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                    >
                        <ServiceForm
                            active={this.state.active}
                            columns={this.state.columns}
                        />
                    </Dialog>
                </div>
            </React.Fragment>
        )
    }
}

Services.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Services)
