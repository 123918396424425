import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
//import { styles } from '../Layout/styles'

import { Modal, Paper } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'
import MenuItem from '@material-ui/core/MenuItem'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import ListItemText from '@material-ui/core/ListItemText'
import InputBase from '@material-ui/core/InputBase'
import Chip from '@material-ui/core/Chip'
import SearchIcon from '@material-ui/icons/Search'
import Tooltip from '@material-ui/core/Tooltip'
import Grid from '@material-ui/core/Grid'
import { Button, Typography, Collapse } from '@material-ui/core'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import AdvancedSearch from './AdvancedSearch'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faPhone, faChevronDown, faChevronUp } from '@fortawesome/pro-light-svg-icons'
import { faPlusCircle } from '@fortawesome/pro-light-svg-icons'

import EnhancedTable from '../Reusable/EnhancedTable'
import { PostData } from '../../services/PostData'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    lower: {
        textTransform: 'capitalize',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    pullRight: {
        float: 'right',
    },

})

function createColumn(
    crm_id,
    company,
    strategic_business_unit,
    utility_type,
    phone,
    address_1,
    city,
    state,
    zip_code,
    sort_order
) {
    const address = address_1 + ', ' + city + ', ' + state + ', ' + zip_code
    return {
        id: crm_id,
        company,
        strategic_business_unit,
        utility_type,
        phone,
        address,
        sort_order
    }
}

class CompanySearch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            searchField: '',
            searchType: 'Company Name',
            columns: [],
            recents: [],
            recentActive: true,
        }

        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
        this.toggleRecents = this.toggleRecents.bind(this)
    }

    handleSearchClick = searchVal => {
        if (!searchVal) {
            searchVal = this.state.searchField
        }

        this.setState({ searchValue: searchVal })
        this.fetchList('Company Name', searchVal)
    }

    handleSearchChange = e => {
        this.setState({ searchField: e.target.value })
    }

    handleAdd = () => {
        //Format of data is [id/name, Label, defaultValue, isDropdown(bool)]
        const data = {
            company: ['company', 'Utility Name', '', false],
            country: ['country', 'Country', '', false],
            state: ['state', 'State', '', false],
            city: ['city', 'City', '', false],
            address_1: ['address_1', 'Street Address', '', false],
            zip_code: ['zip_code', 'Zip Code', '', false],
            latitude: ['latitude', 'Latitude', '', false],
            longitude: ['longitude', 'Longitude', '', false],
            p_o_box: ['p_o_box', 'P.O. Box', '', false],
            phone: ['phone', 'Office Phone', '', false],
            fax: ['fax', 'Fax', '', false],
            utility_type: ['utility_type', 'Utility Type', '', false],
            web_site: ['web_site', 'Website', '', false],
            
            
            
        }
        this.props.setFormData(data, 'addCRM')
    }

    componentWillMount() {
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        let recent = userData === null ? false : userData.recent_companies
        /*const data1 = {
            action: 'fetch_recent',
            user_id: JSON.parse(sessionStorage.getItem('userData')).userid
        }
        
        PostData('ajax/ajaxHUGlogin.php', data1).then(response => {
            recent = response.resultSet[0]
        })*/
        
        if (recent) {
            //console.log(recent)
            if (typeof recent === 'string') {
                //console.log(JSON.parse(recent))
                recent = recent.substr(1)
                recent = recent.substr(0, recent.length - 1)
                recent = recent.split(',')

                for (let i = 0; i < recent.length; i++) {
                    recent[i] = recent[i].substr(1)
                    recent[i] = recent[i].substr(0, recent[i].length - 1)
                }
            }
            
            
            let recents = []
            let columns = []
            for (let i = 0; i < recent.length; i++) {
                const data = {
                    action: 'doNameSearch',
                    LETTERsearch: recent[i],
                }
                PostData('CRM/ajax/ajaxCRMSearch.php', data).then(response => {
                    if (response.result) {
                        
                        let company = response.resultSet[0]
                        if (response.resultSet[0]) {
                            recents.push(
                                createColumn(
                                    company.crm_id,
                                    company.company,
                                    company.strategic_business_unit,
                                    company.utility_type,
                                    company.phone,
                                    company.address_1,
                                    company.city,
                                    company.state,
                                    company.zip_code,
                                    i
                                )
                            )
                            
                        }
                        if(i + 1 === recent.length){
                            // columns.push(
                            //     createColumn(
                            //         <Grid style={{height: 50}}><Typography component='h6' variant = 'h6' style={{paddingTop: 10}}>Companies</Typography></Grid>,
                            //         '',
                            //         '',
                            //         '',
                            //         '',
                            //         '',
                            //         '',
                            //         ''
                            //     )
                            // )
                            
                            const data2 = {
                                action: 'doNameSearch',
                                LETTERsearch: this.state.searchValue,
                            }
                    
                            PostData('CRM/ajax/ajaxCRMSearch.php', data2).then(response => {
                                if (response.result) {
                                    
                    
                                    response.resultSet.forEach(company => {
                                        columns.push(
                                            createColumn(
                                                company.crm_id,
                                                company.company,
                                                company.strategic_business_unit,
                                                company.utility_type,
                                                company.phone,
                                                company.address_1,
                                                company.city,
                                                company.state,
                                                company.zip_code
                                            )
                                        )
                                    })
                                    //////FIXES ORDER
                                    if(columns[1].crm_id != recent[0]){
                                        let x = this.arrFindId(columns, recent[0])
                                        let a = columns[1], b = columns[x];
                                        columns[x] = a;
                                        columns[1] = b;
                                    }
                                    if(columns[2].crm_id != recent[1]){
                                        let y = this.arrFindId(columns, recent[1])
                                        let c = columns[2], d = columns[y]
                                        columns[y]=c
                                        columns[2] = d
                                    }
                                    if(columns[3].crm_id != recent[2]){
                                        let z = this.arrFindId(columns, recent[2])
                                        let e = columns[3], f = columns[z]
                                        columns[z]=e
                                        columns[3] = f
                                    }
                                    if(columns[4].crm_id != recent[3]){
                                        let w = this.arrFindId(columns, recent[3])
                                        let g = columns[4], h = columns[w]
                                        columns[w]=g
                                        columns[4] = h
                                    }
                                    if(columns[5].crm_id != recent[4]){
                                        let t = this.arrFindId(columns, recent[4])
                                        let j = columns[5], k = columns[t]
                                        columns[t] = j
                                        columns[5] = k
                                    }
                    
                                    this.setState({ columns: columns })

                                }
                            })
                            
                            this.setState({recents: recents, recentActive: true})
                        }
                        
                    }
                })
                
            }    

        } else {
            const data2 = {
                action: 'doNameSearch',
                LETTERsearch: this.state.searchValue,
            }
            PostData('CRM/ajax/ajaxCRMSearch.php', data2).then(response => {
                if (response.result) {
                    let columns = []
                    response && response.resultSet && response.resultSet.forEach(company => {
                        columns.push(
                            createColumn(
                                company.crm_id,
                                company.company,
                                company.strategic_business_unit,
                                company.utility_type,
                                company.address_1,
                                company.city,
                                company.state,
                                company.zip_code
                            )
                        )
                    })
                    this.setState({columns: columns})
                }
            })
        }
    }

    arrFindId = (arr, id) => {
        for(let i = 0; i<arr.length; i++){
            if(arr[i] && arr[i].id == id){
                return i
                break
            }
        }
    }

    switchOffRecents = (id) =>{
        let columns = this.state.columns
        if(this.state.recentActive){
            
            columns.unshift(id)
        }
        this.setState({recentActive: false, columns: columns})

    }

    toggleRecents = () => {
        this.setState({ recentActive: !this.state.recentActive })
    }

    fetchList(searchType, searchValue) {
        let newAction = 'doIdSearch'
        if (searchType === 'Company Name') {
            newAction = 'doNameSearch'
        }

        if (searchValue.length >=5 && searchValue.substr(0, 5) === 'WHERE') {
            newAction = 'doAdvancedSearch'
        }
        

        const data = {
            action: newAction,
            LETTERsearch: searchValue,
        }
        

        PostData('CRM/ajax/ajaxCRMSearch.php', data).then(response => {
            if (response.result) {
                let columns = []
                

                if (
                    typeof response.resultSet === 'object' &&
                    response.resultSet.length > 0
                ) {
                    response.resultSet.forEach(company => {
                        columns.push(
                            createColumn(
                                company.crm_id,
                                company.company,
                                company.strategic_business_unit,
                                company.utility_type,
                                company.phone,
                                company.address_1,
                                company.city,
                                company.state,
                                company.zip_code
                            )
                        )
                    })

                    this.setState({ columns: columns, recentActive: false })
                } else {
                    this.setState({ columns: columns, recentActive: false })
                }
            }
        })
    }

    render() {
        const { classes } = this.props
        
        const rows = [
            {
                id: 'id',
                numeric: false,
                disablePadding: true,
                label: 'ID',
            },
            {
                id: 'company',
                numeric: false,
                disablePadding: false,
                label: 'Name',
            },
            {
                id: 'sbu',
                numeric: false,
                disablePadding: false,
                label: 'SBU',
            },
            {
                id: 'utility_type',
                numeric: false,
                disablePadding: false,
                label: 'Type',
            },
            {
                id: 'phone',
                numeric: false,
                disablePadding: false,
                label: 'Phone Number',
            },
            {
                id: 'address',
                numeric: false,
                disablePadding: false,
                label: 'Address',
            },
        ]
        
        return (
            <EnhancedTable
                handleFormClose = {this.props.handleFormClose}
                activeRecents = {this.state.recentActive}
                switchOffRecents = {this.switchOffRecents}
                className={classes.secondary}
                rows={rows}
                columns={this.state.recentActive ? this.state.recents : this.state.columns}
                link={'/company/'}
                showHeader={true}
                toolbar={
                    <CustomToolbar
                        classes={classes}
                        handleSearchClick={this.handleSearchClick}
                        handleSearchChange={this.handleSearchChange}
                        handleAdd={this.handleAdd}
                        toggleRecents={this.toggleRecents}
                        recentActive={this.state.recentActive}
                    />
                }
                tableRow={<CustomTableCells handleClose={this.props.handleFormClose}/>}
            />
        )
    }
}

class CustomTableCells extends React.Component {
    render() {
        const n = this.props.n

        return (
            <React.Fragment>
                <TableCell padding="checkbox" />
                <TableCell component="th" scope="row" padding="none">
                    {n.id}
                </TableCell>
                <TableCell>{n.company}</TableCell>
                <TableCell>{n.strategic_business_unit}</TableCell>
                <TableCell>{n.utility_type}</TableCell>
                <TableCell>
                    {n.phone !== '' && n.phone !== null && n.phone !== undefined &&
                        <span onClick={e => e.stopPropagation()}>
                            <a href={"tel:"+n.phone} style={{textDecoration: 'none', color: 'black'}}>                                        
                                <FontAwesomeIcon icon={faPhone} color={'black'} style={{marginRight: 5}}/>
                                {n.phone}
                            </a>
                        </span>
                    }
                </TableCell>
                <TableCell>{typeof n.id !== 'object' && n.address}</TableCell> 
            </React.Fragment>
        )
        //! <TableCell align="right"></TableCell>
    }
}

class CustomToolbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            advancedSearch: false,
        }
    }

    toggleDialog = () => {
        this.setState({ advancedSearch: !this.state.advancedSearch })
    }

    render() {
        const { classes } = this.props
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={11}>

                        <FormGroup row>
                            <InputBase
                                className={this.props.classes.input}
                                placeholder={'Search'}
                                onChange={this.props.handleSearchChange}
                                onKeyPress={ev => {
                                    if (ev.key === 'Enter') {
                                        this.props.handleSearchClick(this.state.searchField)
                                        ev.preventDefault()
                                    }
                                }}
                            />
                            <IconButton
                                className={this.props.classes.iconButton}
                                aria-label={'Search'}
                                onClick={() => this.props.handleSearchClick(this.state.searchField)}
                            >
                                <SearchIcon />
                            </IconButton>

                            <FormControlLabel
                                control={
                                    <Switch checked={this.props.recentActive} onChange={this.props.toggleRecents}  />
                                }
                                label="Show Recents"
                            />

                            <Button style={{padding: 5, margin: 5}} onClick={this.toggleDialog}>
                                <FontAwesomeIcon
                                    icon={this.state.advancedSearch ? faChevronUp : faChevronDown}
                                    size="1x"
                                    style={{
                                        marginRight: 10,
                                    }}
                                />
                                <Typography
                                    style={{
                                        textTransform: 'capitalize',
                                    }}
                                >
                                    Advanced Search
                                </Typography>
                            </Button>
                        </FormGroup>
                    </Grid>

                    
                    <Grid item xs={1} style={{marginLeft: 'auto', flexDirection: 'row'}}>
                        <Tooltip title="Add New CRM Record">
                            <IconButton
                                className={this.props.classes.pullRight}
                                //className={this.props.classes.Button}
                                onClick={this.props.handleAdd}
                            >
                                <FontAwesomeIcon icon={faPlusCircle} />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                {/* <Grid container direction="column" >
                    <Grid item>
                        <Grid container>
                            <Grid item  style={{paddingTop: 5, marginTop: 15}}>
                                <InputBase
                                    className={this.props.classes.input}
                                    placeholder={'Search'}
                                    onChange={this.props.handleSearchChange}
                                    onKeyPress={ev => {
                                        if (ev.key === 'Enter') {
                                            this.props.handleSearchClick(this.state.searchField)
                                            ev.preventDefault()
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item style={{marginTop: 10}}>
                                <IconButton
                                    className={this.props.classes.iconButton}
                                    aria-label={'Search'}
                                    onClick={() => this.props.handleSearchClick(this.state.searchField)}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </Grid>
                            <Grid item style={{marginTop: 10}}>
                                <Button style={{padding: 5, margin: 5}} onClick={this.toggleDialog}>
                                    <Typography
                                        style={{
                                            textTransform: 'capitalize',
                                            
                                        }}
                                    >
                                        Advanced Search
                                    </Typography>
                                </Button>
                            </Grid>

                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch checked={this.props.recentActive} onChange={this.props.toggleRecents}  />
                                    }
                                    label="Hide Recents"
                                />
                            </Grid>

                            <Grid item xs={1} style={{marginLeft: 'auto', flexDirection: 'row'}}>
                                <Tooltip title="Add New CRM Record">
                                    <IconButton
                                        className={this.props.classes.pullRight}
                                        //className={this.props.classes.Button}
                                        onClick={this.props.handleAdd}
                                    >
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid> */}

                        <Grid item xs={12}>
                            <Collapse in={this.state.advancedSearch}>
                                <Grid
                                    container
                                    xs={12}
                                    direction="column"
                                    alignItems="stretch"
                                >
                                    <AdvancedSearch
                                        handleSearchClick={
                                            this.props.handleSearchClick
                                        }
                                        handleClose={this.toggleDialog}
                                    />
                                </Grid>
                            </Collapse>
                        </Grid>
                    </Grid>
            </React.Fragment>
        )
    }
}

CompanySearch.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CompanySearch)