import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import { faTimes, faSave, faTrashAlt, faCopy } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import SaveWarning from './SaveWarning'
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

const styles = theme => ({
    textField: {
        margin: 1,
        marginLeft: 5,
        paddingBottom: 0,
        paddingTop: 5,
        paddingLeft: 3,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    buttonGroup: {
        marginTop: 10,
        marginBottom: 10,
        marginLeft: 10,
        position: 'sticky',
        bottom: 0,
    },
    save: {
        color: 'white',
        //textTransform: 'capitalize',
        // fontSize: 19,
        marginRight: 10,
    },
    icon: {
        marginRight: 5,
        marginBottom: 2,
    },
})

class FormWrapper extends Component {
    constructor(props) {
        super(props)

        this.handleSaveAndClose = this.handleSaveAndClose.bind(this)
    }

    handleSaveAndClose = () => {
        if(this.props.type === 'Opportunity'){
            this.props.setClose()
        }
        if (this.props.handleSave() && !this.props.alwaysClose) {
            this.props.handleClose()
        }
    }
    
    render() {
        const { classes } = this.props
    
        if(this.props.headless === true){
            return (
                <Grid container spacing={1} className={classes.body}>
                <SaveWarning invalid={this.props.invalid} customMessage={this.props.customMessage} />
                {this.props.children}

                <div className={classes.buttonGroup}>
                    {!this.props.alwaysClose && (
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.save}
                            onClick={this.props.handleSave}
                        >
                            <FontAwesomeIcon
                                icon={faSave}
                                size="1x"
                                color="white"
                                className={classes.icon}
                            />
                            Save
                        </Button>
                    )}

                    <Button
                        color="secondary"
                        variant="contained"
                        className={classes.save}
                        onClick={this.handleSaveAndClose}
                    >
                        <FontAwesomeIcon
                            icon={faSave}
                            size="1x"
                            color="white"
                            className={classes.icon}
                        />
                        {'Save & Close'}
                    </Button>

                    {/* <Button
                        variant="contained"
                        style={{
                            backgroundColor: '#B00020',
                            color: 'white',
                        }}
                        onClick={this.props.handleDelete}
                    >
                        <FontAwesomeIcon
                            icon={faTrashAlt}
                            size="1x"
                            color="white"
                            className={classes.icon}
                        />
                        Delete
                    </Button> */}
                </div>
            </Grid>
            )
        }
        else if (this.props.bottomless === true){
            return (
            <React.Fragment>
            <Grid container className={classes.outside}>
                <Grid item sm={12}>
                    <div className={classes.secondary}>
                        <Grid container>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                className={classes.textField}
                            >
                                {this.props.name
                                    ? (this.props.viewOnly
                                          ? 'View '
                                          : 'Edit ') + this.props.name
                                    : 'Add ' + this.props.type}
                            </Typography>
                            <Grid item className={classes.buttonGrid}>
                                {/* {!this.props.alwaysClose && (
                                    <Button
                                        color="primary"
                                        className={classes.save}
                                        onClick={this.props.handleSave}
                                    >
                                        Save
                                    </Button>
                                )}

                                <Button
                                    color="primary"
                                    className={classes.save}
                                    onClick={this.handleSaveAndClose}
                                >
                                    Save and Close
                                </Button> */}

                                <Button onClick={this.props.handleClose}>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        size="2x"
                                        color="white"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>

                    <SaveWarning
                        invalid={this.props.invalid}
                        customMessage={this.props.customMessage}
                    />
                </Grid>

                <Grid container spacing={1} className={classes.body}>
                    {this.props.children}
                </Grid>

                
            </Grid>
        </React.Fragment>)
        }
        else if (this.props.create === true){
            return (
                <React.Fragment>
                <Grid container className={classes.outside}>
                    <Grid item sm={12}>
                        <div className={classes.secondary}>
                            <Grid container>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="inherit"
                                    className={classes.textField}
                                >
                                    {'Add ' + this.props.type}
                                </Typography>
                                <Grid item className={classes.buttonGrid}>
                                    

                                    <Button onClick={this.props.handleClose}>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            size="2x"
                                            color="white"
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>

                        <SaveWarning
                            invalid={this.props.invalid}
                            customMessage={this.props.customMessage}
                        />
                    </Grid>

                    <Grid container spacing={1} className={classes.body}>
                        {this.props.children}
                    </Grid>

                    <div className={classes.buttonGroup}>
                        
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.save}
                            onClick={this.props.handleSave}
                        >
                            <FontAwesomeIcon
                                icon={faPlus}
                                size="1x"
                                color="white"
                                className={classes.icon}
                            />
                            Create
                        </Button>
                        
                    </div>
                </Grid>
            </React.Fragment>
            )
        }
        else{
            return (
                <React.Fragment>
                <Grid container className={classes.outside}>
                    <Grid item sm={12}>
                        <div className={classes.secondary}>
                            <Grid container>
                                <Typography
                                    component="h1"
                                    variant="h6"
                                    color="inherit"
                                    className={classes.textField}
                                >
                                    {this.props.name
                                        ? (this.props.viewOnly
                                              ? 'View '
                                              : 'Edit ') + this.props.name
                                        : 'Add ' + this.props.type}
                                </Typography>
                                <Grid item className={classes.buttonGrid}>
                                    {/* {!this.props.alwaysClose && (
                                        <Button
                                            color="primary"
                                            className={classes.save}
                                            onClick={this.props.handleSave}
                                        >
                                            Save
                                        </Button>
                                    )}

                                    <Button
                                        color="primary"
                                        className={classes.save}
                                        onClick={this.handleSaveAndClose}
                                    >
                                        Save and Close
                                    </Button> */}

                                    <Button onClick={this.props.handleClose}>
                                        <FontAwesomeIcon
                                            icon={faTimes}
                                            size="2x"
                                            color="white"
                                        />
                                    </Button>
                                </Grid>
                            </Grid>
                        </div>

                        <SaveWarning
                            invalid={this.props.invalid}
                            customMessage={this.props.customMessage}
                        />
                    </Grid>

                    <Grid container spacing={1} className={classes.body}>
                        {this.props.children}
                    </Grid>

                    {/* {!this.props.noSave && <div className={classes.buttonGroup}>} */}
                    <div className={classes.buttonGroup}>
                    
                    {(this.props.name || this.props.type ==='Opportunity') && (
                        <Button
                            color="secondary"
                            variant="contained"
                            className={classes.save}
                            onClick={this.props.handleSave}
                        >
                            <FontAwesomeIcon
                                icon={faSave}
                                size="1x"
                                color="white"
                                className={classes.icon}
                            />
                            Save
                        </Button>)}

                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.save}
                            onClick={this.handleSaveAndClose}
                        >
                            <FontAwesomeIcon
                                icon={faSave}
                                size="1x"
                                color="white"
                                className={classes.icon}
                            />
                            {'Save & Close'}
                        </Button>

                    {(this.props.type === 'Quote') && (
                        <Button
                            color="secondary"
                            variant="contained"
                            className={classes.save}
                            onClick={this.props.handleCopy}
                        >
                            <FontAwesomeIcon
                                icon={faCopy}
                                size="1x"
                                color="white"
                                className={classes.icon}
                            />
                            Duplicate
                        </Button>
                    )}

                        {/* {this.props.handleDelete !== undefined &&
                            this.props.handleDelete !== null &&
                            this.props.name !== undefined &&
                            this.props.name !== null &&
                            this.props.name !== '' && (
                                <Button
                                    variant="contained"
                                    style={{
                                        backgroundColor: '#B00020',
                                        color: 'white',
                                    }}
                                    onClick={this.props.handleDelete}
                                >
                                    <FontAwesomeIcon
                                        icon={faTrashAlt}
                                        size="1x"
                                        color="white"
                                        className={classes.icon}
                                    />
                                    Delete
                                </Button>
                                )}*/}
                    </div>
                </Grid>
            </React.Fragment>
            )
        }

        
    }
}

FormWrapper.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(FormWrapper)