import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTimes,
    faArrowAltCircleRight,
} from '@fortawesome/pro-light-svg-icons'
import { PostData } from '../../../services/PostData'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import MenuItem from '@material-ui/core/MenuItem'
import FormWrapper from '../../Reusable/FormWrapper'
import NumberFormat from 'react-number-format'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
})

class ServiceForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            fields: {
                recordid: '',
                service_type: '',
                meter_count: '',
                active: '',
                verified: null
            },
            dropdown: [],
            active: {
                sbu: '',
            },
            client: [],
        }

    }

    componentWillMount() {
        this.setState({ crmData: this.props.crmData })
        this.setState({ active: this.props.formData.actives })
        this.setState({ client: this.props.formData.client })

        if (this.props.formData.service.recordid !== undefined) {
            this.setState({ fields: this.props.formData.service })
        }

        this.getDropdown()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.crmData !== this.state.crmData) {
            this.setState({ crmData: nextProps.crmData })
        }

        this.setState({ fields: nextProps.formData.service })
        this.setState({ client: nextProps.formData.client })

        this.setState({ active: nextProps.formData.actives })
    }

    handleNumChange = event => {
        let value = event.target.value.replace(/,/g , '')
        if(isNaN(value) ){
            return
        }
        let newfields = this.state.fields
        newfields[event.target.id] = value
        this.setState({ fields: newfields })
    }

    handleVerifiedChange = (event) =>{
        let change = this.state.fields
        change.verified = event.target.value
        this.setState({fields: change})
    }

    deleteService = () => {
        if(this.state.fields !== undefined){
            let newfields = this.state.fields
            newfields.active = 0
            this.setState({ fields: newfields })
            this.handleSave()
            this.handleClose()
        }
    }

    //When a new service is chosen from the dropdown, this will find the record in the database
    handleNew = event => {
        let service = event.target.value

        const data = {
            action: 'getService',
            service: service,
            crm_id: this.state.crmData.crm_id,
        }

        PostData('CRM/ajax/ajaxCRM_UtilityServices.php', data).then(
            response => {
                this.setState({ fields: response.utility_services_states[0] })
                this.state.fields.active = '1'
            }
        )
    }

    //sets active state to array of active sbu
    handleCChange = event => {
        let newActive = this.state.active
        if (event.target.checked == true) {
            newActive.push({ sbu: event.target.id })
        } else {
            let c = 0
            let temp = []
            for (let i = 0; i < newActive.length; i++) {
                if (newActive[i].sbu !== event.target.id) {
                    temp[c] = newActive[i]
                    c++
                }
            }
            newActive = temp
        }
        this.setState({ active: newActive })
    }

    handleSave = () => {
        this.handleCSave()
        this.handleFSave()
        return true
    }

    //saves the checkboxes (first call deletes all checkboxes for the service type, second call adds the current checkboxes to table)
    handleCSave = () => {
        const data1 = {
            action: 'delete_sbu_record',
            service_recordid: this.state.fields.recordid,
        }

        PostData('CRM/ajax/ajaxCRM_UtilityServices.php', data1).then(
            response => {
                this.state.active.forEach(sbu => {
                    const data2 = {
                        action: 'insert_sbu_record',
                        recordid: this.state.fields.recordid,
                        sbu: sbu.sbu,
                    }

                    PostData(
                        'CRM/ajax/ajaxCRM_UtilityServices.php',
                        data2
                    ).then(response => {})
                })
            }
        )
    }

    //saves the fields
    handleFSave = () => {
        const data = {
            action: 'update_record',
            recordid: this.state.fields.recordid,
            crm_id: this.state.fields.crm_id || this.state.crmData.crm_id,
            service_type: this.state.fields.service_type,
            meter_count: this.state.fields.meter_count,
            active: this.state.fields.active,
            modified_by: JSON.parse(sessionStorage.getItem('userData')).userid,
            modified_date: this.getCurrentDate(),
            verified: this.state.fields.verified,
        }

        PostData('CRM/ajax/ajaxCRM_UtilityServices.php', data).then(
            response => {
                this.props.updateComponent('Services')
            }
        )
    }

    getCurrentDate(separator = '-') {
        let newDate = new Date()
        let date = newDate.getDate()
        if (date < 10) {
            date = String(date)
            date = '0' + date
        }
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()

        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`
    }

    handleClose = () => {
        this.props.handleFormClose()
    }

    //Calls for all the dropdowns on this page
    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'SERVICES',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            this.setState({ dropdown: response.dropdowns })
        })
    }

    toTitleCase = str =>{
        if(str){
            str= str.replace(/_/g, ' ')
            return str.replace(
                /([^\W_]+[^\s-]*) */g,
                function(txt) {
                    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
            );
        }else{
            return ''
        }
    }

    //displays the dropdown
    viewDropdown = control => {
        let inactiveServices = []
        console.log(this.props.formData.activeServices)
        this.props.formData.activeServices.forEach(service => {
            if (service.meter_count == 0 || service.active == 0 || service.active == null) {
                inactiveServices.push(service.service_type)
            }
        })
        
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                inactiveServices.forEach(service => {
                    if (service == item.item_code) {
                        list.push({
                            name: item.item_name,
                            code: item.item_code,
                        })
                    }
                })
            }
        })

        return (
            <TextField
                required
                select
                fullWidth
                id="type"
                name="type"
                label="Service Type"
                className={classes.textField}
                onChange={this.handleNew}
                value={this.state.fields.service_type}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }


    //if adding new service, adds additional select service field
    newService = () => {
        const { classes } = this.props
        if (this.state.fields.service_type == undefined || this.state.fields.service_type == ''){
            
            return (
                <Grid item xs={12} sm={12}>
                    {this.viewDropdown('SERVICES_TYPE')}
                </Grid>
            )
        }
    }

    //checks if sbu is active for given service
    sbuActive = sbu => {
        for (let i = 0; i < this.state.active.length; i++) {
            if (this.state.active[i].sbu == sbu) {
                return true
            }
        }
        return false
    }



    //returns array of all possible sbu for service, based on if this company is associated with the sbu in client info
    displaySBU = () => {
        let sbuArr = this.state.client.map(sbu => this.listItem(sbu), this)

        return sbuArr
    }

    //creates list of sbu
    listItem = sbu => {
        if (this.state.active.length > 0) {
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            defaultChecked={this.sbuActive(sbu)}
                            id={sbu}
                            onClick={this.handleCChange}
                        />
                    }
                    label={sbu}
                />
            )
        } else {
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            key={sbu}
                            defaultChecked={false}
                            id={sbu}
                            onClick={this.handleCChange}
                        />
                    }
                    label={sbu}
                />
            )
        }
    }

    render() {
        const { classes } = this.props
        console.log(this.state.fields)
        return (

            <FormWrapper
                invalid={this.state.invalid}
                type="Service"
                name={this.toTitleCase(this.state.fields.service_type)}
                handleSave={this.handleSave}
                handleClose={this.handleClose}
                handleDelete={this.deleteService}
            >
                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h6" color="inherit">
                        General
                    </Typography>
                </Grid>
                {this.newService()}

                <Grid item xs={6} >
                    <NumberFormat 
                        id="meter_count"
                        name="meter_count"
                        label="Meter Count"
                        fullWidth
                        className={classes.textField}
                        customInput={TextField} 
                        value={this.state.fields.meter_count} 
                        thousandSeparator={true}
                        onChange={this.handleNumChange}
                        decimalScale={0}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl component="fieldset">
                        
                        <RadioGroup className={classes.textField} name="verified" value={this.state.fields.verified} id='verified' onChange={this.handleVerifiedChange} row>
                            <FormControlLabel
                                value='1'
                                control={<Radio color="primary" />}
                                label="Verified"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value='0'
                                control={<Radio color="primary" />}
                                label="Estimate"
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>
                </Grid>


                <Grid item xs={12} sm={6}>
                    <TextField 
                        disabled
                        id="modified_date"
                        name="modified_date"
                        label="Modified Date"
                        fullWidth
                        className={classes.textField}
                        value={this.state.fields.modified_date} 
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        disabled
                        id="modified_by"
                        name="modified_by"
                        label="Modified By"
                        fullWidth
                        className={classes.textField}
                        value={this.state.fields.modified_by} 
                    />
                </Grid>
                
                

                <Grid item xs={12} sm={3}>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        //className={classes.textField}
                    >
                        Applicable SBU:
                    </Typography>
                    <FormGroup row>{this.displaySBU()}</FormGroup>
                </Grid>
                {/* <Grid item xs={12} sm={12}>
                    <Button onClick={this.deleteService}>Delete Service</Button>
                </Grid> */}
            </FormWrapper>

            //             {/* </Grid>
            //         </Card>
            //      </Grid>
            //  </Grid>
            //  </React.Fragment> */}
        )
    }
}

ServiceForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ServiceForm)
