import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import { withStyles } from '@material-ui/core/styles'
import TypoGraphy from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import Grid from '@material-ui/core/Grid'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import ToolTip from '@material-ui/core/ToolTip'
import Modal from '@material-ui/core/Modal'
import SaveWarning from '../../Reusable/SaveWarning'
import { List, ListItemIcon} from '@material-ui/core'




import { PostData } from '../../../services/PostData'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faCheck, faTimes }  from '@fortawesome/free-solid-svg-icons'
import { addValidators } from '../../../utils/Validate';
import { verify } from 'crypto';

const styles = theme => ({
    root: {
        display: 'flex',
    },
    list: {
        paddingBottom: 0,
        marginBottom: 0
    },
    listItem: {
        paddingVerical: 0,
    },
    listIcon: {
        paddingRight: 5,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    paper: {
        marginRight: theme.spacing.unit * 6,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    avatar: {
        margin: 10,
        color: '#fff',
        backgroundColor: '#605070',
    },
    phoneNumber: {
        fontSize: 16,
    },
    textField: {
        margin: 4,
        marginTop: 0,
        paddingTop: 5,
        marginBottom: 7,
    },
    buttonIcon: {
        marginRight: theme.spacing(1), 
    },
    buttonText: {
        textTransform: 'none'
    }

})

class MenuListComposition extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            open: false,
            loggedIn: true,
            oldWrong: true,
            new1: '',
            old: '',
            new2: '',
            passEqual: false,
            passLength: false,
            passUpper: false,
            passLower: false,
            passNum: false,

        }
    }

    componentWillMount() {
        if (JSON.parse(sessionStorage.getItem('userData')).access_level == 1) {
            this.getDropdowns()
        }
        
    }

    logout = () => {
        sessionStorage.setItem('userData', '')
        sessionStorage.clear()
        this.setState({ loggedIn: false })
    }
    

    getInitials = () => {
        let name = JSON.parse(sessionStorage.getItem('userData')).name

        let initials = name.charAt(0).toUpperCase()

        for (let i = 0; i < name.length; i++) {
            if (name.charAt(i) === ' ') {
                initials += name.charAt(i + 1).toUpperCase()
                break
            }
        }

        return initials
    }

    handleToggle = () => {
        this.setState(state => ({ open: !state.open }))
    }

    handleClose = event => {
        if (this.anchorEl.contains(event.target)) {
            return
        }

        this.setState({ open: false })
    }

    handleMaintenance = () => {
        this.props.setFormData('', 'DropdownForm')
    }
    getDropdowns = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'ACCOUNT_MENU',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            this.setState({ dropdown: response.dropdowns })
        })
    }
    handleChange = e => {
        let sbu = e.target.value
        let userData = JSON.parse(sessionStorage.getItem('userData'))
        userData.strategic_business_unit = sbu
        sessionStorage.setItem('userData', JSON.stringify(userData))
        this.props.updateComponent('ClientInfo')
    }
    

    render() {
        const { classes } = this.props
        const { open } = this.state
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        
        const accessLvlNum = userData ? userData.access_level : ''
        let accessLvl = ''
        switch (accessLvlNum) {
            case '1':
                accessLvl += 'Administrator'
                break
            case '2':
                accessLvl += 'SBU Admin'
                break
            case '3':
                accessLvl += 'SBU Read Only'
            case '4':
                accessLvl += 'Sales'
            case '5':
                accessLvl += 'Visitor'
        }

        if (!this.state.loggedIn) {
            return <Redirect to={'/login'} />
        }
        
        return (
            <div>
                <Button
                    buttonRef={node => {
                        this.anchorEl = node
                    }}
                    aria-owns={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={this.handleToggle}
                    color="inherit"
                    className={classes.buttonText}
                >
                    <AccountCircle className={classes.buttonIcon} />
                    {JSON.parse(sessionStorage.getItem('userData')).userid}

                </Button>



                <Popper
                    open={open}
                    anchorEl={this.anchorEl}
                    placement="bottom-end"
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            id="menu-list-grow"
                            style={{
                                transformOrigin:
                                    placement === 'bottom-end'
                                        ? 'center top'
                                        : 'center bottom',
                            }}
                        >
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <Card>
                                    <Grid
                                        container
                                        direction="column"
                                        alignContent="flex-start"
                                        style={{ backgroundColor: '#f2f2f2' }}
                                    >
                                        <Grid container direction="row">
                                            <Grid
                                                item
                                                xs={2}
                                                alignItems="center"
                                                style={{
                                                    marginTop: 13,
                                                    marginHorizontal: 3,
                                                    marginRight: 5,
                                                }}
                                            >
                                                <ToolTip title='Account Settings'>
                                                    <IconButton size = 'small' edge = 'start' onClick = {()=>{
                                                            this.props.setFormData({dropdown: this.state.dropdown}, 'Account')
                                                            this.setState({open: false})
                                                        }} onMouseEnter={() => this.setState({hover: true})} onMouseLeave={() => this.setState({hover: false})}>
                                                        <Avatar
                                                            className={classes.avatar}
                                                            style={{
                                                                width: 50,
                                                                height: 50,
                                                            }}
                                                        >
                                                            {this.state.hover ? <FontAwesomeIcon icon={faCog}/> : this.getInitials()}
                                                        </Avatar>
                                                    </IconButton>
                                                </ToolTip>
                                            </Grid>
                                            <Grid item>
                                                <ListItem>
                                                    <ListItemText
                                                        primary={userData.name}
                                                        secondary={
                                                            <div>
                                                                <div>
                                                                    {
                                                                        userData.email
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {
                                                                        userData.title
                                                                    }
                                                                </div>
                                                                <div>
                                                                    {accessLvl}
                                                                </div>
                                                            </div>
                                                        }
                                                    />
                                                </ListItem>
                                            </Grid>
                                        </Grid>
                                        <hr
                                            width="315"
                                            size="2"
                                            style={{ padding: 0, margin: 0 }}
                                        />
                                        <Grid
                                            container
                                            xs={12}
                                            style={{
                                                backgroundColor: '#e8e8e8',
                                                marginTop: 0,
                                                paddingTop: 10,
                                                direction: 'row',
                                                paddingLeft: 7,
                                            }}
                                        >
                                            <Grid item xs={3}>
                                                {userData.userid === 'es.horizons' || userData.userid === 'rh.horizons' || userData.name === 'Russell Hanson' /*accessLvl ===
                                                    'Administrator'temporarily commented out, still there for Eric and Russells account*/ &&
                                                this.state.dropdown ? (
                                                    <TextField
                                                        select
                                                        name="SBU"
                                                        id="SBU"
                                                        value={
                                                            userData.strategic_business_unit
                                                        }
                                                        className={
                                                            classes.textField
                                                        }
                                                        onChange={
                                                            this.handleChange
                                                        }
                                                    >
                                                        {this.state.dropdown &&
                                                            this.state.dropdown.map(
                                                                item => (
                                                                    <MenuItem
                                                                        key={
                                                                            item.item_code
                                                                        }
                                                                        value={
                                                                            item.item_name
                                                                        }
                                                                    >
                                                                        {
                                                                            item.item_name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                    </TextField>
                                                ) : (
                                                    <TypoGraphy style={{paddingTop: 8, paddingLeft: 10}}>
                                                        {
                                                            userData.strategic_business_unit
                                                        }
                                                    </TypoGraphy>
                                                )}
                                            </Grid>

                                            <Grid
                                                item
                                                xs={4}
                                                style={{
                                                    marginLeft: 'auto',
                                                }}
                                            >
                                                <Button
                                                    onClick={this.logout}
                                                    style={{
                                                        textTransform:
                                                            'capitalize',
                                                        backgroundColor:
                                                            '#e8e8e8',

                                                        marginTop:
                                                            accessLvl ===
                                                            'Administrator'
                                                                ? 2
                                                                : 0,
                                                        marginBottom: 10,
                                                        marginLeft: 20,
                                                    }}
                                                >
                                                    <TypoGraphy>
                                                        Log Out
                                                    </TypoGraphy>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </ClickAwayListener>
                        </Grow>
                    )}
                </Popper>
                
            </div>
        )
    }
}

MenuListComposition.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(MenuListComposition)
