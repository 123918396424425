import React from 'react'
import PropTypes from 'prop-types'

import { PostData } from '../../../services/PostData'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import Card from '@material-ui/core/Card'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import NumberFormat from 'react-number-format';

import { faTimes } from '@fortawesome/pro-light-svg-icons'

import { faSave } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider } from '@material-ui/core'
import FormWrapper from '../../Reusable/FormWrapper'

const styles = theme => ({
    textField: {
        margin: 5,
        paddingRight: 15,
    },
    number: {
        fontSize: 16,
        border: '1px solid',
    },

    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
})

class ProductsForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            data: {},
            dropdown: [],
        }

        this.handleChange = this.handleChange.bind(this)
    }

    componentWillMount() {
        this.setState({ data: this.props.formData })
        this.getDropdown()
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ data: nextProps.formData })
        this.getDropdown()
    }

    //handles all changes
    handleChange = event => {
        let newData = this.state.data
        if(event.target.name == 'status' && event.target.value == 'LOST'){
            newData.lost_date = this.getCurrentDate()
        } else {
            newData[event.target.name] = event.target.value
        }
        console.log(newData.status_date)
        this.setState({ data: newData }, () => console.log(this.state))
    }

    //Handles number changes
    handleNumChange = event => {
        let value = event.target.value.replace(/[,$]/g , '')
        if(isNaN(parseFloat(value))){
            return
         }
        let newData = this.state.data
        newData[event.target.name] = value

        newData.purchased_qty= parseFloat(newData.purchased_qty)
        newData.software_license_fee = parseFloat(newData.software_license_fee)
        newData.discount = parseFloat(newData.discount)

        newData.net_purchase_price = newData.purchased_qty * newData.software_license_fee - newData.discount
        this.setState({ data: newData })
        
    }


    getCurrentDate(separator = '/') {
        let newDate = new Date()
        let date = newDate.getDate()
        if (date < 10) {
            date = String(date)
            date = '0' + date
        }
        let month = newDate.getMonth() + 1
        let year = newDate.getFullYear()

        return `${year}${separator}${
            month < 10 ? `0${month}` : `${month}`
        }${separator}${date}`
    }

    //saves all fields
    handleSave = () => {
        
        if(this.state.data.recordid){
            //UPDATE EXISTING RECORD
            const data = {
                action: 'Update_Record_CPM',
                hug_schema: 'hug_' + JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
                sbu: JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
                userid: JSON.parse(sessionStorage.getItem('userData')).userid,
                recordid: this.state.data.recordid,
                status: this.state.data.status,
                purchased_state: this.state.data.purchased_state,
                date_status: this.state.data.date_status,
                date_contracted: this.state.data.date_contracted,
                date_billed: this.state.data.date_billed,
                date_warrantied: this.state.data.date_warrantied,
                list_price_each: this.state.data.software_license_fee,
                gross_purchase_price: this.state.data.gross_purchase_price,
                discount: this.state.data.discount,
                net_purchase_price: this.state.data.net_purchase_price,
                exclude_on_report: this.state.data.exclude_on_report,
                comments: this.state.data.comments,
                billing_time_frame: this.state.data.billing_time_frame,
                monthly_fee: this.state.data.monthly_fee,
                quantity: this.state.data.quantity,
                purchased_qty: this.state.data.purchased_qty,
                maint_cost: this.state.data.maint_cost,
                new_maint_cost: this.state.data.new_maint_cost,
                work_orders: this.state.data.work_orders,
                lost_date: this.state.data.lost_date,
            }

            PostData(
                'MaintenanceAgreements/ajax/ajaxCust_Maint_Billing_File.php',
                data
            ).then(response => {
                //this.handleClose()
                
                
            })
            return true
            
        }else{
            //ADD NEW RECORD
            const data = {
                action: 'Insert_Record_CPM',
                hug_schema: 'hug_' + JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit,
                sbu: JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,
                userid: JSON.parse(sessionStorage.getItem('userData')).userid,
                product_line: this.state.data.product_line,
                product_module: this.state.data.product_module,
                recordid: this.state.data.recordid,
                status: this.state.data.status,
                purchased_state: this.state.data.purchased_state,
                date_status: this.state.data.date_status,
                date_contracted: this.state.data.date_contracted,
                date_billed: this.state.data.date_billed,
                date_warrantied: this.state.data.date_warrantied,
                list_price_each: this.state.data.software_license_fee,
                gross_purchase_price: this.state.data.gross_purchase_price,
                discount: this.state.data.discount,
                net_purchase_price: this.state.data.net_purchase_price,
                exclude_on_report: this.state.data.exclude_on_report,
                comments: this.state.data.comments,
                billing_time_frame: this.state.data.billing_time_frame,
                monthly_fee: this.state.data.monthly_fee,
                quantity: this.state.data.quantity,
                purchased_qty: this.state.data.purchased_qty,
                maint_cost: this.state.data.maint_cost,
                new_maint_cost: this.state.data.new_maint_cost,
                work_orders: this.state.data.work_orders,
                lost_date: this.state.data.lost_date,
                sbu_client_id: this.state.data.sbu_client_id
            }
            PostData(
                'MaintenanceAgreements/ajax/ajaxCust_Maint_Billing_File.php',
                data
            ).then(response => {
                //this.handleClose()
                this.props.updateComponent('Products')  
                
            })
            return true
            
        }
        
    }

    handleClose = () => {
        this.props.handleFormClose()
    }

    //gets all dropdowns for page
    getDropdown = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'PRODUCTS',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            this.setState({ dropdown: response.dropdowns })
        })
    }

    //displays specific dropdown
    viewDropdown = (control, id, name, disabled) => {
        const { classes } = this.props
        let list = []
        this.state.dropdown.forEach(item => {
            if (item.control_code == control) {
                list.push({ name: item.item_name, code: item.item_code })
            }
        })

        return (
            <TextField
                select
                fullWidth
                id={id}
                name={id}
                label={name}
                className={classes.textField}
                onChange={this.handleChange}
                value={this.state.data[id]}
                disabled={disabled}
            >
                {list.map(item => (
                    <MenuItem key={item.name} value={item.code}>
                        {item.name}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    //Converts strings to Title Case
    toTitleCase = str =>{
        str= str.replace(/_/g, ' ')
        return str.replace(
            /([^\W_]+[^\s-]*) */g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    render() {
        const { classes } = this.props
        //console.log(this.state)
        const userData = JSON.parse(sessionStorage.getItem('userData'))
        //console.log(userData)
        const disabled = parseInt(userData.access_level) > 2 && !this.state.data.recordid
        //console.log(disabled)
        return (

            <FormWrapper
                invalid={this.state.invalid}
                type="Product"
                name={this.toTitleCase(this.props.formData.product_module)}
                handleSave={this.handleSave}
                handleClose={this.handleClose}
                noSave={disabled}
            >
                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h6" color="inherit">
                        Overview
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    {this.viewDropdown('STATUS', 'status', 'Status', disabled)}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {this.viewDropdown(
                        'PURCHASED_STATE',
                        'purchased_state',
                        'Stage',
                        disabled
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="date_status"
                        InputLabelProps={{ shrink: true }}
                        name="date_status"
                        label="Status Date"
                        type="date"
                        fullWidth
                        className={classes.textField}
                        value={this.state.data.date_status}
                        onChange={this.handleChange}
                        disabled = {disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="date_warrantied"
                        InputLabelProps={{ shrink: true }}
                        name="date_warrantied"
                        label="Warrantied Date"
                        type="date"
                        fullWidth
                        className={classes.textField}
                        value={this.state.data.date_warrantied}
                        onChange={this.handleChange}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="date_billed"
                        InputLabelProps={{ shrink: true }}
                        name="date_billed"
                        label="Billed Date"
                        type="date"
                        fullWidth
                        className={classes.textField}
                        value={this.state.data.date_billed}
                        onChange={this.handleChange}
                        disabled={disabled}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    {this.viewDropdown(
                        'BILLING_TIME',
                        'billing_time_frame',
                        'Billing Time Frame',
                        disabled
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    {this.viewDropdown(
                        'EXCLUDE_REPORT',
                        'exclude_on_report',
                        'Report',
                        disabled
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        id="lost_date"
                        InputLabelProps={{ shrink: true }}
                        name="lost_date"
                        label="Lost Date"
                        type="date"
                        fullWidth
                        className={classes.textField}
                        value={this.state.data.lost_date}
                        onChange={this.handleChange}    
                        disabled={disabled}    
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        id="comments"
                        name="comments"
                        label="Comments"
                        fullWidth
                        multiline
                        className={classes.textField}
                        value={this.state.data.comments}
                        onChange={this.handleChange}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h6" color="inherit">
                        Purchase
                    </Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <NumberFormat     
                        disabled   
                        id="purchased_qty"
                        name="purchased_qty"
                        label="Purchased Quantity"
                        fullWidth
                        className={classes.textField}
                        customInput={TextField} 
                        value={this.state.data.purchased_qty}
                        onChange={this.handleNumChange}
                        thousandSeparator={true}
                        decimalScale={0}
                        fixedDecimalScale={true}
                        defaultValue={0}
                        allowEmptyFormatting={true} 
                        disabled = {disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <NumberFormat   
                        disabled     
                        id="list_price_each"
                        name="list_price_each"
                        label="List Price Each"
                        fullWidth
                        className={classes.textField}
                        customInput={TextField} 
                        value={this.state.data.software_license_fee}
                        onChange={this.handleNumChange}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        defaultValue={0.00}
                        allowEmptyFormatting={true} 
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <NumberFormat  
                        disabled      
                        id="discount"
                        name="discount"
                        label="Discount"
                        fullWidth
                        className={classes.textField}
                        customInput={TextField} 
                        value={this.state.data.discount}
                        onChange={this.handleNumChange}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        defaultValue={0.00}
                        allowEmptyFormatting={true} 
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography
                        variant="subtitle2"
                        name="net_purchase_price"
                        className={classes.number}
                        onChange={this.handleChange}
                        align="center"
                    >
                        <NumberFormat  
                            value={this.state.data.net_purchase_price}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={'$'}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            defaultValue={0.00}
                            allowEmptyFormatting={true} 
                            disabled={disabled}
                        />

                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Typography component="h1" variant="h6" color="inherit">
                        Maintenance
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <NumberFormat        
                        id="monthly_fee"
                        name="monthly_fee"
                        label="Monthly Fee"
                        fullWidth
                        className={classes.textField}
                        customInput={TextField} 
                        value={this.state.data.monthly_fee}
                        onChange={this.handleNumChange}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        defaultValue={0.00}
                        allowEmptyFormatting={true} 
                        disabled={disabled}
                    />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <NumberFormat        
                        id="new_maint_cost"
                        name="new_maint_cost"
                        label="Maintenance Cost"
                        fullWidth
                        className={classes.textField}
                        customInput={TextField} 
                        value={this.state.data.new_maint_cost}
                        onChange={this.handleNumChange}
                        thousandSeparator={true}
                        prefix={'$'}
                        decimalScale={2}
                        fixedDecimalScale={true}
                        defaultValue={0.00}
                        allowEmptyFormatting={true} 
                        disabled={disabled}
                    />
                </Grid>
            </FormWrapper>

            //         </Grid>
            //     </Card>
            // </Grid>
        )
    }
}

ProductsForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(ProductsForm)