import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import {
    Checkbox, 
    Modal,
    Paper,
    Menu,
    TableCell,
    IconButton,
    Icon,
    MenuItem,
    ListItem,
    List,
    ListItemText,
    InputBase,
    Tooltip,
    Grid,
    Button,
    Typography,
    Collapse,
    TextField,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTint,
    faTintSlash,
    faLightbulbExclamation,
    faWifi,
    faNetworkWired,
    faTvRetro,
    faSatelliteDish,
    faTrashAlt,
    faPhoneVolume,
    faWaterLower,
    faThunderstorm,
    faSun,
    faLightbulbSlash,
    faLightbulb,
    faBurn,
    faEdit,
} from '@fortawesome/pro-regular-svg-icons'
import StreetLight from '../../../images/icons/street-light.ico'
import SearchIcon from '@material-ui/icons/Search'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    lower: {
        textTransform: 'capitalize',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    pullRight: {
        float: 'right',
    },
})

class Tradeshows extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            searchField: '',
            columns: [],
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
            year: new Date().getFullYear(),
            eventstate: 'All'
        }

        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
    }

    handleSearchChange = e => {
        this.setState({ searchField: e.target.value })
    }

    componentWillMount() {
        this.search('')
        this.getDropdowns()
    }
    getDropdowns = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'TRADESHOWS',
            requested_sbu: this.state.sbu,
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }

    

    handleFilterChange = e => {
        let value = e.target.value
        
        this.setState({ [e.target.name]: value })
        let state = this.state
        state[e.target.name] = value
        this.search(state)
    }

    handleSearchChange = e => {
        e.persist()
        this.setState(() => {
            return { searchField: e.target.value }
        })
    }

    handleSearchClick = () => {
        this.setState({ searchValue: this.state.searchField })
        this.search(this.state.searchField)
    }
    search = (state = this.state) => {
        const data = {
            action: 'getTradeShowList',
            requested_Sbu: state.sbu,
            searchVal: state.searchVal,
            wherefilter: state.eventstate,
            wherefilteryear: state.year

        }

        PostData('MarketingReports/ajax/ajaxTradeshows.php', data).then(response => {
            this.setState(prevState => {
                prevState.columns = response.resultSet
                let years = []
                years.sort((a,b) =>{
                    return( parseInt(a) - parseInt(b))
                })
                prevState.years = response.years
                return prevState
            })
            
        })
    }

    render() {
        const { classes } = this.props

        const rows = [
            {
                id: 'details',
                numeric: false,
                disablePadding: true,
                label: 'Event Details',
            },
            {
                id: 'sbu',
                numeric: true,
                disablePadding: false,
                label: 'Strategic Business Unit',
            },
            {
                id: 'eventdays',
                numeric: true,
                disablePadding: false,
                label: 'Days',
            },
            {
                id: 'eventstate',
                numeric: true,
                disablePadding: false,
                label: 'Event State',
            },
            {
                id: 'exhibitcost',
                numeric: true,
                disablePadding: true,
                label: 'Cost',
            },
        ]

        return (
            <EnhancedTable
                className={classes.secondary}
                rows={rows}
                columns={this.state.columns}
                showHeader={true}
                link={this.openContactForm}
                toolbar={
                    <CustomToolbar
                        classes={classes}
                        handleSearchClick={this.handleSearchClick}
                        handleSearchChange={this.handleSearchChange}
                        handleFilterChange={this.handleFilterChange}
                        sbu={this.state.sbu}
                        dropdown={this.state.dropdown}
                        eventstate={this.state.eventstate}
                        year = {this.state.year}
                        years = {this.state.years}
                    />
                }
                tableRow={<CustomTableCells openClientInfoForm={this.openClientInfoForm}/>}
            />
        )
    }
}

class CustomTableCells extends React.Component {
    render() {
        const n = this.props.n

        return (
            <React.Fragment>
                <TableCell padding="checkbox" />
                <TableCell
                    style={{ cursor: 'pointer' }}
                    component="th"
                    scope="row"
                    padding={'default'}
                >
                    {n.event_name}<br/>
                    {n.event_location}<br />
                    {n.eventdate}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.strategic_business_unit}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.eventdays}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.eventstate}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.exhibitcost}
                </TableCell>                
            </React.Fragment>
        )
    }
}

class CustomToolbar extends React.Component {
    render() {
        let sbu = []
        this.props.dropdown &&
            this.props.dropdown.forEach(dropdown => {
                if (dropdown.control_code === 'SBU') {
                    sbu.push(dropdown)
                }
            })

        const { classes } = this.props
        return (
            <React.Fragment>
                <Grid spacing={5} container>
                    <Grid item style={{ paddingTop: 5, marginTop: 35 }}>
                        <InputBase
                            className={this.props.classes.input}
                            placeholder={'Search'}
                            onChange={this.props.handleSearchChange}
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    this.props.handleSearchClick()
                                    ev.preventDefault()
                                }
                            }}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 10 }}>
                        <IconButton
                            className={this.props.classes.iconButton}
                            aria-label={'Search'}
                            onClick={this.props.handleSearchClick}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        sm={5}
                        md={4}
                        lg={3}
                        xl={2}
                        style={{ marginTop: 5 }}
                    >
                        <TextField
                            value={this.props.sbu}
                            name="sbu"
                            id="sbu"
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            label="Strategic Business Unit"
                        >
                            {sbu &&
                                sbu.map(item => (
                                    <MenuItem
                                        value={item.item_name}
                                        key={item.item_name}
                                    >
                                        {item.item_name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid 
                        item
                        xs={6} 
                        sm={4}
                        md={3}
                        lg={2}
                        xl={1}
                        style={{ marginTop: 5 }}
                    >
                        <TextField
                            value={this.props.eventstate}
                            name="eventstate"
                            id="eventstate"
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            label="Event State"
                        >
                            {this.props.dropdowns &&
                                this.props.dropdowns.map(item => item.CONTROL_CODE === 'EVENT_STATE' && (
                                    <MenuItem
                                        value={item.item_name}
                                        key={item.item_name}
                                    >
                                        {item.item_name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    <Grid xs = {4} md = {3} lg = {2} item>
                        <TextField
                            value={this.props.year}
                            name="year"
                            id="year"
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            label="Year"
                        >
                            {this.props.years &&
                                this.props.years.map(item => (
                                    <MenuItem
                                        value={item.item_name}
                                        key={item.item_name}
                                    >
                                        {item.item_name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

Tradeshows.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(Tradeshows)