import React from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'

function TabContainer({ children, dir }) {
    return (
        <Typography component="div" dir={dir} style={{ paddingTop: 24, paddingBottom: 24, paddingLeft: 0, paddingRight: 24 }}>
            {children}
        </Typography>
    )
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
    dir: PropTypes.string.isRequired,
}

const styles = theme => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: 'auto',
        height: 'auto'
    },
    bar: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        textTransform: 'capitalize'
    },
    indicator: {
        display: 'none'
    },
    selected: {
        backgroundColor: theme.palette.secondary.main,
    }
})

class SwipeableTabs extends React.Component {
    // const classes = useStyles()
    // const theme = useTheme()
    // const [value, setValue] = React.useState(0)

    // function handleChange(event, newValue) {
    //     setValue(newValue)
    // }

    // function handleChangeIndex(index) {
    //     setValue(index)
    // }

    constructor(props){
        super(props)
        this.state = {
            value: 0
        }
    }

    handleChange = value => {
        this.setState({ value: value })

        if (typeof this.props.handleTabChange === "function") { 
            this.props.handleTabChange(value)
        }
    }

    render() {
        const props = this.props
        const { classes } = props

        return (
            <div className={classes.root}>
                <AppBar position="static"  elevation={0}>
                    <Tabs className={classes.bar}
                        value={this.state.value}
                        onChange={this.handleChange}
                        textColor="white"
                        variant="fullWidth"
                        indicatorColor='secondary'
                    >
                        {props.titles.map((title, index) => {
                            return <Tab label={title} 
                                        key={index} 
                                        className={index === this.state.value ? classes.selected : ''}
                                        onClick={() => {
                                            this.handleChange(index)
                                        }}
                                    />
                        })}
                    </Tabs>
                </AppBar>
                <SwipeableViews
                    // animateHeight
                    axis={'x'}
                    index={this.state.value}
                    // onChangeIndex={this.handleChange}
                    style={{backgroundColor: '#ffffff', overflowY: 'auto'}}
                >
                    {props.children.map(child => (
                        <TabContainer dir={'ltr'} style={{ minHeight: 500}}>
                            {child}
                        </TabContainer>
                    ))}
                </SwipeableViews>
            </div>
        )
    }

}

SwipeableTabs.propTypes = {
    classes: PropTypes.object.isRequired,
}
export default withStyles(styles)(SwipeableTabs)