//MARK: imports
import React from 'react'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Collapse from '@material-ui/core/Collapse'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Divider from '@material-ui/core/Divider'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import arrayMove from 'array-move'
import ListContainer from './ListContainer'
import { PostData } from '../../../services/PostData'
import {
    faTimes,
    faEdit,
    faTrash,
    faPlus,
    faCheck,
    faBars
} from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//MARK: styles
const styles = theme => ({
    textField: {
        margin: 1,
        marginLeft: 5,
        paddingTop: 5,
        paddingLeft: 3,
        paddingRight: 5,
        textTransform: 'capitalize',
        marginRight: 5,
        marginTop: 10,
        marginBottom: 10
    },
    topText: {
        margin: 2,
        marginTop: 5,
        marginLeft: 5,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
        boxShadow: '0 1px 1px 0px' + theme.palette.secondary.main,
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    save: {
        color: 'white',
        textTransform: 'capitalize',
        fontSize: 19,
    },
    buttons: {
        marginLeft: 2,
    },
    addButton: {
        margin: 0,
        padding:0,
    },
    buttonsAdd: {
        marginRight: 13
    }

})
//MARK: constructor
class DropdownForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menuDropdown: '',
            controls: [],
            subsystems: {},
            controlDropdown: '',
            menuSet: false,
            title: 'Edit Dropdowns',
            listItems: [],
            activeAdd: false,
            addDropDown: '',
            popupOpen: false,
        }
    }
    //MARK: mount
    componentWillMount() {
        
        const data = {
            action: 'getAllSubsystems',
        }

        PostData(
            'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                let subsystems = []
                for (let i = 0; i < response.subsystems.length; i++) {
                    let toggle = true
                    for (let x = 0; x < subsystems.length; x++) {
                        if (
                            response.subsystems[i].subsystem_code ===
                            subsystems[x].id
                        ) {
                            toggle = false
                        }
                    }
                    if (toggle) {
                        subsystems.push({
                            id: response.subsystems[i].subsystem_code,
                            label: this.filterCode(
                                response.subsystems[i].subsystem_code
                            ),
                        })
                    }
                }

                this.setState({ subsystems: subsystems, window: window.document.activeElement })
            }
        })
    }
    //MARK: filter
    filterCode = code => {
        let newCode = code.replace(/_/g, ' ')
        
        newCode = newCode.charAt(0) + newCode.substring(1).toLowerCase()
        if (newCode === 'Add company') {
            newCode = 'Add Company'
        } else if (newCode === 'Work order') {
            newCode = 'Work Order'
        }
        return newCode
    }
    //MARK: renderMenuDropdown
    renderMenuDropdown() {
        const { classes } = this.props
        
        return (
            <Collapse in={!this.state.menuSet}>
                <Grid item xs={12} spacing = {1} style={{paddingRight: 7}}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        select
                        id="subsystem_code"
                        name="subsystem_code"
                        label="Select Menu"
                        value={this.state.menuDropdown}
                    >
                        <MenuList>
                            {this.state.subsystems.length > 1 &&
                                this.state.subsystems.map(subsystem => (
                                    <MenuItem
                                        onClick={this.handleMenu}
                                        id={subsystem.id}
                                        value={subsystem.label}
                                    >
                                        {subsystem.label}
                                    </MenuItem>
                                ))}
                        </MenuList>
                    </TextField>
                </Grid>
            </Collapse>
        )
    }
    //MARK: handleMenu
    handleMenu = event => {
        let subsystem = event.target.getAttribute('id')
        let value = event.target.getAttribute('value')
        const data = {
            action: 'controlSearch',
            subsystem_code: subsystem,
        }
        let controls = []
        PostData(
            'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                if (response.controls) {
                    
                    for (let i = 0; i < response.controls.length; i++) {
                        controls.push(
                            this.filterCode(response.controls[i].control_code)
                        )
                    }
                }
                for (let x = 0; x < controls.length; x++) {
                    let tempVar = controls[x].split(' ')
                    if (tempVar.length !== 1) {
                        tempVar[1] =
                            tempVar[1].charAt(0).toUpperCase() +
                            tempVar[1].substr(1, tempVar[1].length)
                        controls[x] = tempVar.join(' ')
                    }
                }
                this.setState({
                    controls: controls,
                    menuDropdown: value,
                    menuSet: true,
                    title: 'Edit ' + value + ' Dropdowns',
                    subsystem_code: subsystem,
                })
            }
        })
    }
    //MARK: renderDropdownSelector
    renderDropdownSelector() {
        const { classes } = this.props

        return (
            <Collapse in={this.state.menuDropdown}>
                <Grid xs={12} item spacing={1} style={{paddingRight: 7}}>
                    <TextField
                        className={classes.textField}
                        fullWidth
                        select
                        id="control_code"
                        name="control_code"
                        label="Select Dropdown"
                        onChange={this.handleDropdown}
                        value={this.state.controlDropdown}
                    >
                        {this.state.controls &&
                            this.state.controls.map(field => (
                                <MenuItem value={field} key={field}>
                                    {field}
                                </MenuItem>
                            ))}
                    </TextField>
                </Grid>
            </Collapse>
        )
    }
    //MARK: HandleClose
    handleClose = () => {
        this.props.handleFormClose()
    }
    //MARK: HandleClose
    handleDropdown = event => {
        this.setState({listItems: []})
        let value = event.target.value
        let controlCode = ''
        for (let i = 0; i < value.length; i++) {
            if (value.charAt(i) === ' ') {
                controlCode += '_'
            } else {
                controlCode += value.charAt(i).toUpperCase()
            }
        }
        const data = {
            control_code: controlCode,
            subsystem_code: this.state.subsystem_code,
            action: 'GetOneDropdown',
        }

        PostData(
            'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            let newState = this.state
            newState.controlDropdown = event.target.value
            newState.activeAdd= false
            newState['window'] = window.document.activeElement
            if (response.result && response.items) {
                
                let editorList = []

                for (let i = 0; i < response.items.length; i++) {
                    editorList.push({name: response.items[i].item_name, id: i, active: false, original: response.items[i].item_name})
                }
                newState.listItems = editorList

            }
            this.setState(newState)
        })
    }
/*
    //QuickSort Functions
    partition(items, left, right) {
        let pivot   = items[Math.floor((right + left) / 2)], //middle element
            i       = left, //left pointer
            j       = right; //right pointer
            
        while (i <= j) {
            while (Number(items[i].item.sort_order) < Number(pivot.item.sort_order)) {
                i++;
            }
            while (Number(items[j].item.sort_order) > Number(pivot.item.sort_order)) {
                j--;
            }
            if (i <= j) {
                this.swap(items, i, j); //swapping two elements
                i++;
                j--;
            }
        }
        return i;
    }
    swap(items, leftIndex, rightIndex){
        let temp = items[leftIndex];
        items[leftIndex] = items[rightIndex];
        items[rightIndex] = temp;
    }
    
    quickSort(items, left, right) {
        let index;
        if (items.length > 1) {
            index = this.partition(items, left, right); //index returned from partition
            if (left < index - 1) { //more elements on the left side of the pivot
                this.quickSort(items, left, index - 1);
            }
            if (index < right) { //more elements on the right side of the pivot
                this.quickSort(items, index, right);
            }
        }
        return items;
    }
    //END of quicksort functions
*/
    //MARK: HandleEditToggle
    handleEditToggle = id => 
        this.setState((prevState)=>{
            if (prevState.listItems[id].active) {
                prevState.listItems[id].name = prevState.listItems[id].original
                prevState.listItems[id].active = false
            }else{
                prevState.listItems[id].active = !prevState.listItems[id].active
            }
            return prevState
            })

    //MARK: handleDelete
    handleDelete = (id) => {
        this.toggleDialog()
        let newState = this.state
        this.setState({listItems: []})

        let item_name = this.state.deleting

        let value = this.state.controlDropdown
        let controlCode = ''
        for (let i = 0; i < value.length; i++) {
            if (value.charAt(i) === ' ') {
                controlCode += '_'
            } else {
                controlCode += value.charAt(i).toUpperCase()
            }
        }
        const data = {
            action: 'deleteItem',
            subsystem_code: this.state.subsystem_code,
            control_code: controlCode,
            item_name: item_name,
        }
        
        PostData(
            'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                for (let i = 0; i < newState.listItems.length; i++) {
                    
                    if (newState.listItems[i].name === item_name) {
                        
                        newState.listItems.splice(i, 1)
                        newState.deleting = ''
                        newState.popupOpen = false
                        
                        this.setState(newState)
                        break
                    }
                }
            }else{
                this.setState(newState)
            }
        })
    }
    //MARK: handleEditSave
    handleEditSave = id => {
        
        console.log(this.state.listItems[id].original)
        
        let value = this.state.controlDropdown
        let controlCode = ''
        for (let i = 0; i < value.length; i++) {
            if (value.charAt(i) === ' ') {
                controlCode += '_'
            } else {
                controlCode += value.charAt(i).toUpperCase()
            }
        }
        if (
            this.state.listItems[id].name !==
            this.state.listItems[id].original
        ) {
            const data = {
                action: 'editItem',
                table_name: 'hug.new_dropdown_item',
                qualifier:
                "item_name = '" +
                this.state.listItems[id].original +
                "' AND subsystem_code = '" +
                    this.state.subsystem_code +
                    "' AND control_code = '" +
                    controlCode +
                    "';",
                column_sql_type: 'text',
                column_name: 'updated_by',
                column_value: JSON.parse(sessionStorage.getItem('userData')).userid,
                user: JSON.parse(sessionStorage.getItem('userData')).userid,
                sbu: JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,
            }
            PostData(
                'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
                data
            ).then(response => {
                
                if (response.result) {
                    

                    const data2 = {
                        action: 'editItem',
                        table_name: 'hug.new_dropdown_item',
                        qualifier:
                        "item_name = '" +
                                    this.state.listItems[id].original +
                                    "' AND subsystem_code = '" +
                            this.state.subsystem_code +
                            "' AND control_code = '" +
                            controlCode +
                            "';",
                        column_sql_type: 'text',
                        column_name: 'updated_date',
                        column_value: 'date',
                        user: JSON.parse(sessionStorage.getItem('userData')).userid,
                        sbu: JSON.parse(sessionStorage.getItem('userData'))
                            .strategic_business_unit,
                    }

                    PostData(
                        'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
                        data2
                    ).then(respons => {
                        if(respons.result){
                            const data3 = {
                                action: 'editItem',
                                table_name: 'hug.new_dropdown_item',
                                qualifier:
                                    "item_name = '" +
                                    this.state.listItems[id].original +
                                    "' AND subsystem_code = '" +
                                    this.state.subsystem_code +
                                    "' AND control_code = '" +
                                    controlCode +
                                    "';",
                                column_sql_type: 'text',
                                column_name: 'item_name',
                                column_value: this.state.listItems[id].name,
                                user: JSON.parse(sessionStorage.getItem('userData')).userid,
                                sbu: JSON.parse(sessionStorage.getItem('userData'))
                                    .strategic_business_unit,
                            }
                            PostData(
                                'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
                                data3
                            ).then(respon => {
                                if(respon.result){
                                    let newState = this.state
                                    newState.listItems[id].original = newState.listItems[id].name
                                    newState.listItems[id].active = false
                                    this.setState(newState)
                                    
                                }
                            })
                        }
                    })
                }
            })
        }else{
            this.handleEditToggle(id)
        }
    }
    //MARK: handleTextEdit
    handleTextEdit = (id,val) => 
        this.setState(newState => {
            newState.listItems[id].name = val
            return {listItems: newState.listItems}
        })
    
    
    //MARK: handleAdd funcs
    handleAdd = () => {
        this.setState({ activeAdd: !this.state.activeAdd, addDropdown: '' })
    }
    handleAddChange = event => {
        this.setState({ addDropdown: event.target.value })
    }
    handleAddSave = () => {
        let newState = this.state
        this.setState({listItems: []})
        let value = this.state.controlDropdown
        let controlCode = ''
        for (let i = 0; i < value.length; i++) {
            if (value.charAt(i) === ' ') {
                controlCode += '_'
            } else {
                controlCode += value.charAt(i).toUpperCase()
            }
        }
        let value2 = this.state.addDropdown
        let item_code = ''
        for (let i = 0; i < value2.length; i++) {
            if (value2.charAt(i) === ' ') {
                item_code += '_'
            } else {
                item_code += value2.charAt(i).toUpperCase()
            }
        }
        const data = {
            subsystem_code: this.state.subsystem_code,
            control_code: controlCode,
            item_code: item_code,
            item_name: this.state.addDropdown,
            sort_order: this.state.listItems.length + 1,
            created_by: JSON.parse(sessionStorage.getItem('userData')).userid,
            action: 'addItem',
        }

        PostData(
            'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                
                
                newState.listItems.push({name: this.state.addDropdown, id: this.state.listItems.length +1, active: false, original: this.state.addDropdown})
                newState.activeAdd = false
                
                this.setState(newState)
            }
        })
    }
    //MARK: toggleDialog
    toggleDialog = (name) => {
        if(name){
            this.setState({ popupOpen: !this.state.popupOpen, deleting: name })
        }else{
            this.setState({popupOpen: !this.state.popupOpen, deleting: ''})
        }
    }
    //MARK: onSOrtEnd
    onSortEnd = ({oldIndex, newIndex}) => {
        let newState = this.state
        
        newState.listItems = arrayMove(this.state.listItems, oldIndex, newIndex)

        
        
        this.setState(newState)
        this.saveSort()
    }
    //MARK: saveSort
    saveSort() {
        let value = this.state.controlDropdown
        let controlCode = ''
        for (let i = 0; i < value.length; i++) {
            if (value.charAt(i) === ' ') {
                controlCode += '_'
            } else {
                controlCode += value.charAt(i).toUpperCase()
            }
        }
        for(let i = 0; i<this.state.listItems.length; i++){
            const data = {
                action: 'editItem',
                table_name: 'hug.new_dropdown_item',
                qualifier:
                    "item_name= '"+
                    this.state.listItems[i].original +
                    "' AND subsystem_code = '" +
                    this.state.subsystem_code +
                    "' AND control_code = '" +
                    controlCode +
                    "';",
                column_sql_type: 'text',
                column_name: 'sort_order',
                column_value: i+1,
                user: JSON.parse(sessionStorage.getItem('userData')).userid,
                sbu: JSON.parse(sessionStorage.getItem('userData'))
                    .strategic_business_unit,
                    }
            PostData(
                'migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
                data
            ).then(response => {})
        }
            
        
    }
    //MARK: render
    render() {
        
        const { classes } = this.props
        
        return (
            <Grid
                onClick={this.handleCheck}
                container
                className={classes.outside}
                direction="column"
                alignItems="stretch"
            >
                <Card>
                    <div className={classes.secondary}>
                        <Grid container >
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                className={classes.topText}
                            >
                                {this.state.title}
                            </Typography>
                            <Grid item className={classes.buttonGrid}>
                                <Button onClick={this.handleClose}>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        size="2x"
                                        color="white"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>
                    {this.renderMenuDropdown()}
                    {this.renderDropdownSelector()}
                    {this.state.controlDropdown && <Divider/>}
                    <Collapse timeout='auto' in={this.state.listItems}>
                        <ListContainer 
                            className={classes.addButton} 
                            pressDelay={100} 
                            listItems={this.state.listItems} 
                            helperContainer={this.state.window}
                            useWindowAsScrollContainer={true} 
                            onSortEnd={this.onSortEnd} 
                            handleTextEdit = {this.handleTextEdit} 
                            handleEditToggle={this.handleEditToggle} 
                            handleEditSave={this.handleEditSave} 
                            toggleDialog={this.toggleDialog}/>
                        </Collapse> 
        {this.state.controlDropdown && <List>
            
                <ListItem className={classes.addButton}>
                    <Slide in={this.state.activeAdd} direction="right">
                        <TextField
                            fullWidth
                            label="Add Drop Down Item"
                            id="add"
                            name="add"
                            className={classes.textField}
                            value={this.state.addDropdown}
                            onChange={this.handleAddChange}
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    this.handleAddSave()
                                    ev.preventDefault()
                                }
                            }}
                        />
                    </Slide>
                    {!this.state.activeAdd ? (
                        <ListItemIcon className={classes.buttonsAdd}>
                            <IconButton
                                
                                onClick={this.handleAdd}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                            </IconButton>
                        </ListItemIcon>
                    ) : (
                        <Grid>
                            <ListItemIcon>
                                <IconButton
                                    className={classes.buttons}
                                    onClick={this.handleAdd}
                                >
                                    <FontAwesomeIcon icon={faTimes} />
                                </IconButton>
                            </ListItemIcon>
                            <ListItemIcon className={classes.buttonsAdd}>
                                <IconButton>
                                    <FontAwesomeIcon
                                        className={classes.buttons}
                                        icon={faCheck}
                                        onClick={this.handleAddSave}
                                    />
                                </IconButton>
                            </ListItemIcon>
                        </Grid>
                    )}
                </ListItem>
            </List>}
            {this.state.controlDropdown && 
            
            <Divider/>}
            {this.state.controlDropdown && <Divider/>}
                </Card>
                <Dialog
                    open={this.state.popupOpen}
                    aria-labelledby={'Delete ' + this.state.deleting + '?'}
                    aria-describedby="description"
                >
                    <DialogTitle id={'Delete ' + this.state.deleting + '?'}>
                        {'Delete ' + this.state.deleting + '?'}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="description">
                            {'Are you sure you want to delete ' +
                                this.state.deleting}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={this.toggleDialog}
                            className={classes.cardButtons}
                        >
                            No
                        </Button>
                        <Button
                            onClick={this.handleDelete}
                            className={classes.cardButtons}
                            autoFocus
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

DropdownForm.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(DropdownForm)
