import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import { withStyles } from '@material-ui/core/styles'
import {
    Modal,
    Paper,
    Menu,
    TableCell,
    IconButton,
    Icon,
    MenuItem,
    ListItem,
    List,
    ListItemText,
    InputBase,
    Tooltip,
    Grid,
    Button,
    Typography,
    Collapse,
    TextField,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faTint,
    faTintSlash,
    faLightbulbExclamation,
    faWifi,
    faNetworkWired,
    faTvRetro,
    faSatelliteDish,
    faTrashAlt,
    faPhoneVolume,
    faWaterLower,
    faThunderstorm,
    faSun,
    faLightbulbSlash,
    faLightbulb,
    faBurn,
    faEdit,
} from '@fortawesome/pro-regular-svg-icons'
import StreetLight from '../../../images/icons/street-light.ico'
import SearchIcon from '@material-ui/icons/Search'
import SaveWarning from '../../Reusable/SaveWarning'

import EnhancedTable from '../../Reusable/EnhancedTable'
import { PostData } from '../../../services/PostData'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    lower: {
        textTransform: 'capitalize',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    pullRight: {
        float: 'right',
    },
})

class CustomerList extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            searchValue: '',
            searchField: '',
            columns: [],
            sbu: JSON.parse(sessionStorage.getItem('userData'))
                .strategic_business_unit,
        }

        this.handleSearchClick = this.handleSearchClick.bind(this)
        this.handleSearchChange = this.handleSearchChange.bind(this)
    }

    handleSearchChange = e => {
        this.setState({ searchField: e.target.value })
    }

    componentWillMount() {
        this.searchCustomers('')
        this.getDropdowns()
    }
    getDropdowns = () => {
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'CUSTOMER_LIST',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                this.setState({ dropdown: response.dropdowns })
            }
        })
    }
    openClientInfoForm = (x) =>{
        if(JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase() !== x.strategic_business_unit.toLowerCase()){
            this.setState({invalid: true})
            return 
        }
        
        const client = {
            active: x.active,
            at_risk_comments: x.at_risk_comments,
            at_risk_status: x.at_risk_status,
            client_info_note: x.client_info_note,
            crm_id: x.crm_id,
            date_lost: x.date_lost,
            date_won: x.date_won,
            harris_client_id: x.harris_client_id,
            purchase_order_required: x.purchase_order_required,
            recordid: x.recordid,
            sbu_client_id: x.sbu_client_id,
            strategic_business_unit: x.strategic_business_unit,
            team_support_id: x.team_support_id,
        }

        const data = {
            action: 'getStartupData2', //faster than getStartUpData, 2 just has less data
            crm_id: x.crm_id,
            hug_schema: 'hug_' +(JSON.parse(sessionStorage.getItem('userData')).strategic_business_unit.toLowerCase()),
            strategic_business_unit: JSON.parse(
                sessionStorage.getItem('userData')
            ).strategic_business_unit,
        }
        PostData('CRM/ajax/ajaxCRM_2018.php', data).then(response => {
            this.props.setFormData(
                { client: client, crmData: response.CRMdata },
                'ClientInfo'
            )
            this.setState({invalid: false})
        })
    }

    handleFilterChange = e => {
        let value = e.target.value
        let name = e.target.name

        this.setState({ sbu: value })
        this.searchCustomers(this.state.searchValue, value)
    }

    handleSearchChange = e => {
        e.persist()
        this.setState(() => {
            return { searchField: e.target.value }
        })
    }

    handleSearchClick = () => {
        this.setState({ searchValue: this.state.searchField })
        this.searchCustomers(this.state.searchField)
    }
    searchCustomers = (searchVal, sbu = this.state.sbu) => {
        const data = {
            action: 'clientSearchBySBU',
            sbu: sbu,
            searchVal: searchVal,
        }

        PostData('CRM/ajax/ajaxCRM_ClientInfo.php', data).then(response => {
            this.setState({ columns: response.ClientInfo })
        })
    }

    render() {
        const { classes } = this.props

        const rows = [
            {
                id: 'crm_id',
                numeric: false,
                disablePadding: true,
                label: 'CRM ID',
            },
            {
                id: 'strategic_business_unit',
                numeric: true,
                disablePadding: false,
                label: 'SBU',
            },
            {
                id: 'company',
                numeric: true,
                disablePadding: false,
                label: 'Company',
            },
            {
                id: 'date_won',
                numeric: true,
                disablePadding: false,
                label: 'Active Date',
            },
            {
                id: 'date_lost',
                numeric: true,
                disablePadding: false,
                label: 'Date Lost',
            },
            {
                id: 'at_risk_status',
                numeric: true,
                disablePadding: true,
                label: 'Risk Status',
            },
            {
                id: 'products',
                numeric: true,
                disablePadding: false,
                label: 'Product Lines',
            },
            {
                id: 'services',
                numeric: true,
                disablePadding: false,
                label: 'Services',
            },
            // {
            //     id: 'edit',
            //     numeric: true,
            //     disablePadding: false,
            //     label: 'Edit',
            // },
        ]

        return (
            <EnhancedTable
                className={classes.secondary}
                rows={rows}
                columns={this.state.columns}
                showHeader={true}
                link={'/company/'}
                toolbar={
                    <CustomToolbar
                        classes={classes}
                        handleSearchClick={this.handleSearchClick}
                        handleSearchChange={this.handleSearchChange}
                        handleFilterChange={this.handleFilterChange}
                        sbu={this.state.sbu}
                        dropdown={this.state.dropdown}
                        invalid={this.state.invalid}
                    />
                }
                tableRow={<CustomTableCells openClientInfoForm={this.openClientInfoForm}/>}
            />
        )
    }
}

class CustomTableCells extends React.Component {
    toTitleCase = str => {
        if (str !== null) {
            str = str.replace(/_/g, ' ')
            return str.replace(/([^\W_]+[^\s-]*) */g, function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
            })
        }
    }
    getProductList = productList =>{
        return productList ? productList.map((product,i) =>
            i === productList.length - 1 
            ? this.toTitleCase(product)
            : (this.toTitleCase(product)+', ')
        ) : ''
    }
    viewRiskStatus = value =>{
        if(value === 'LEAVING' || value === 'YES'){
            return 'At Risk'
        }else{
            return 'Not At Risk'
        }
    }
    serviceListToIconList = (serviceList) =>{
        if(!serviceList){
            return serviceList
        }
        let returnList = []
        for(let i = 0; i<serviceList.length; i++){
            returnList.push(this.getServiceIcon(serviceList[i]))
        }
        return returnList
    }

    getServiceIcon = type => {
        switch (type) {
            case 'WATER':
                return <FontAwesomeIcon icon={faTint} size="1x" />
                break
            case 'ELECTRIC':
                return <FontAwesomeIcon icon={faLightbulb} size="1x" />
                break
            case 'GAS':
                return <FontAwesomeIcon icon={faBurn} size="1x" />
                break
            case 'WASTE_WATER':
                return <FontAwesomeIcon icon={faTintSlash} size="1x" />
                break
            case 'SECURITY_LIGHTS':
                return (
                    <FontAwesomeIcon icon={faLightbulbExclamation} size="1x" />
                )
                break
            case 'FIBER':
                return <FontAwesomeIcon icon={faNetworkWired} size="1x" />
                break
            case 'INTERNET':
                return <FontAwesomeIcon icon={faWifi} size="1x" />
                break
            case 'CABLE':
                return <FontAwesomeIcon icon={faTvRetro} size="1x" />
                break
            case 'SATELLITE':
                return <FontAwesomeIcon icon={faSatelliteDish} size="1x" />
                break
            case 'SANITATION':
                return <FontAwesomeIcon icon={faTrashAlt} size="1x" />
                break
            case 'TELEPHONE':
                return <FontAwesomeIcon icon={faPhoneVolume} size="1x" />
                break
            case 'WATER_IRRIGATION':
                return <FontAwesomeIcon icon={faWaterLower} size="1x" />
                break
            case 'STORM_DRAINAGE':
                return <FontAwesomeIcon icon={faThunderstorm} size="1x" />
                break
            case 'SOLAR':
                return <FontAwesomeIcon icon={faSun} size="1x" />
                break
            case 'STREET_LIGHTS':
                return (
                    <img
                        src={StreetLight}
                        style={{
                            height: '15px',
                            //width: '15%',
                            padding: 0,
                            margin: 0,
                        }}
                    />
                )
                break
            case 'ELECTRIC_NONOUTAGE':
                return <FontAwesomeIcon icon={faLightbulbSlash} size="1x" />
                break
        }
    }

    render() {
        const n = this.props.n

        return (
            <React.Fragment>
                <TableCell padding="checkbox" />
                <TableCell
                    style={{ cursor: 'pointer' }}
                    component="th"
                    scope="row"
                    padding={'default'}
                >
                    {n.crm_id}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.strategic_business_unit}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.company}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.date_won}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {n.date_lost}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {this.viewRiskStatus(n.at_risk_status)}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {this.getProductList(n.products)}
                </TableCell>
                <TableCell style={{ cursor: 'pointer' }} align="right">
                    {this.serviceListToIconList(n.service_types)}
                </TableCell>
                {/* <TableCell align="right">
                    <IconButton 
                        onClick={(ev)=>{ev.preventDefault() 
                            this.props.openClientInfoForm(n)
                        }}
                    >
                        <FontAwesomeIcon icon={faEdit}/>
                    </IconButton>
                </TableCell> */}
            </React.Fragment>
        )
    }
}

class CustomToolbar extends React.Component {
    render() {
        let sbu = []
        this.props.dropdown &&
            this.props.dropdown.forEach(dropdown => {
                if (dropdown.control_code === 'SBU') {
                    sbu.push(dropdown)
                }
            })

        const { classes } = this.props
        return (
            <React.Fragment>
                <Grid spacing = {5} container>
                    <Grid item style={{ paddingTop: 5, marginTop: 35 }}>
                        <InputBase
                            className={this.props.classes.input}
                            placeholder={'Search'}
                            onChange={this.props.handleSearchChange}
                            onKeyPress={ev => {
                                if (ev.key === 'Enter') {
                                    this.props.handleSearchClick()
                                    ev.preventDefault()
                                }
                            }}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: 10 }}>
                        <IconButton
                            className={this.props.classes.iconButton}
                            aria-label={'Search'}
                            onClick={this.props.handleSearchClick}
                        >
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                    <Grid
                        item
                        xs={7}
                        sm={5}
                        md={4}
                        lg={3}
                        xl={2}
                        style={{ marginTop: 5 }}
                    >
                        <TextField
                            value={this.props.sbu}
                            name="sbu"
                            id="sbu"
                            fullWidth
                            select
                            onChange={this.props.handleFilterChange}
                            InputProps={{
                                disableUnderline: true,
                            }}
                            label="Strategic Business Unit"
                        >
                            {sbu &&
                                sbu.map(item => (
                                    <MenuItem
                                        value={item.item_name}
                                        key={item.item_name}
                                    >
                                        {item.item_name}
                                    </MenuItem>
                                ))}
                        </TextField>
                    </Grid>
                    
                    <Grid style={{paddingLeft: 2}} item xs={12}>
                        <SaveWarning  customMessage={'Cannot edit Client Info of other Business Units'} invalid = {this.props.invalid}/>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

CustomerList.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CustomerList)
