import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import { styles } from '../Layout/styles'

import TableCell from '@material-ui/core/TableCell'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@material-ui/core/Icon'

import TopBar from '../Layout/TopBar/TopBar'
import EnhancedTable from '../Reusable/EnhancedTable'
import { PostData } from '../../services/PostData'

function createColumn(
    id,
    customer_name,
    step_subject,
    contact_name,
    contact_phone_number,
    contact_email,
    campaign_name,
    crm_id,
    contact_id
) {
    return {
        id,
        customer_name,
        step_subject,
        contact_name,
        contact_phone_number,
        contact_email,
        campaign_name,
        crm_id,
        contact_id
    }
}

class CallList extends Component {
    state = {
        // Get the campaign id and step id from the url. See routes.js
        campaignId: this.props.match.params.campaignId,
        stepId: this.props.match.params.stepId,
        columns: [],
    }

    componentWillMount() {
        const data = {
            action: 'getStepCustomers',
            campaign_id: this.state.campaignId,
            step_id: this.state.stepId,
        }

        PostData('master_campaign/ajax/ajaxMasterCampaign.php', data).then(
            response => {
                if (response.result) {
                    let columns = []

                    response.customers.forEach(customer => {
                        columns.push(
                            createColumn(
                                customer.id,
                                customer.company,
                                customer.subject,
                                customer.contact_name,
                                customer.phonenumber,
                                customer.email,
                                customer.campaign_name,
                                customer.crm_id,
                                customer.contact_id,
                            )
                        )
                    })

                    this.setState({ columns: columns })
                }
            }
        )
    }

    render() {
        const { classes } = this.props

        const rows = [
            {
                id: 'step_subject',
                numeric: false,
                disablePadding: true,
                label: 'Step Subject',
            },
            {
                id: 'company',
                numeric: true,
                disablePadding: false,
                label: 'Company',
            },
            {
                id: 'contact_name',
                numeric: true,
                disablePadding: false,
                label: 'Contact Name',
            },
            {
                id: 'phone',
                numeric: true,
                disablePadding: false,
                label: 'Phone Number',
            },
            {
                id: 'email',
                numeric: true,
                disablePadding: false,
                label: 'Email',
            },
            {
                id: 'actions',
                numeric: true,
                disablePadding: false,
                label: 'Actions',
            },
        ]

        return (
            <div className={classes.root}>
                <CssBaseline />
                <TopBar hideDrawerButton={true} title="Campaign Call List" />
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <IconButton
                        component={Link}
                        to={'/campaign-inquiry/' + this.state.campaignId}
                    >
                        <Icon>arrow_back_ios</Icon>
                    </IconButton>
                    <EnhancedTable
                        rows={rows}
                        columns={this.state.columns}
                        additionalData={this.state.steps}
                        tableRow={<CustomTableCells />}
                    />
                </main>
            </div>
        )
    }
}

class CustomTableCells extends React.Component {
    render() {
        const n = this.props.n

        return (
            <React.Fragment>
                <TableCell padding="checkbox" />
                <TableCell component="th" scope="row" padding="none">
                    {n.step_subject}
                </TableCell>
                <TableCell
                    align="right"
                    component={Link}
                    to={'/customer/' + n.crm_id}
                >
                    {n.customer_name}
                </TableCell>
                <TableCell align="right">{n.contact_name}</TableCell>
                <TableCell align="right">{n.contact_phone_number}</TableCell>
                <TableCell align="right">{n.contact_email}</TableCell>
                <TableCell align="right">
                    <IconButton
                        component={Link}
                        to={'/customer/' + n.crm_id + '/' + n.contact_id}
                    >
                        <Icon>note</Icon>
                    </IconButton>
                </TableCell>
            </React.Fragment>
        )
    }
}

CallList.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(CallList)
