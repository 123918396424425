import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withStyles } from '@material-ui/core/styles'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'

import { styles } from '../styles'
import AccountMenu from './AccountMenu'

class TopBar extends React.Component {
    constructor(props) {
        super(props)

        this.handleDrawerOpen = this.handleDrawerOpen.bind(this)
    }

    // Calls the parent function to open the drawer
    handleDrawerOpen = () => {
        this.props.handleDrawerOpen()
    }

    render() {
        let open = this.props.open
        if(this.props.crmData && this.props.main === 'CompanySearch'){
            this.props.setMain('Company')
        }
        return (
            <AppBar
                elevation={2}
                position="absolute"
                className={classNames(
                    this.props.classes.appBar,
                    open && this.props.classes.appBarShift
                )}
            >
                <Toolbar
                    disableGutters={!open}
                    className={this.props.classes.toolbar}
                >
                    {!this.props.hideDrawerButton ? (
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.handleDrawerOpen}
                            className={classNames(
                                this.props.classes.menuButton,
                                open && this.props.classes.menuButtonHidden
                            )}
                        >
                            <MenuIcon />
                        </IconButton>
                    ) : (
                        <div className={this.props.classes.toolbar} />
                    )}

                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={this.props.classes.title}
                        style={{ textAlign: 'left' }}
                    >
                        {this.props.crmData === undefined ||
                        this.props.crmData.crm_id === undefined
                            ? this.props.title
                            : this.props.crmData.crm_id +
                            '  ' +
                            this.props.crmData.company +
                            '  (' +
                            this.props.crmData.industry_type +
                            ' / ' +
                            this.props.crmData.utility_type +
                            ')'}
                    </Typography>

                    <AccountMenu
                        crmData={this.props.crmData}
                        setCrmData={this.props.setCrmData}
                        setFormData={this.props.setFormData}
                        handleFormClose={this.props.handleFormClose}
                        updateComponent={this.props.updateComponent}
                    />
                </Toolbar>
            </AppBar>
        )
    }
}

TopBar.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(TopBar)
