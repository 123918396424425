export function PostData(script, data) {
    let baseURL = process.env.REACT_APP_API_URL + '/HarrisUtilityGroup/'

    return new Promise((resolve, reject) => {
        fetch(baseURL + script, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            //mode: 'cors'
        })
            .then(response => response.json())
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}
