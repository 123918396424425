import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import MenuItem from '@material-ui/core/MenuItem'
import Grid from '@material-ui/core/Grid'
import { TextField, Collapse, Card } from '@material-ui/core'
import { Button, Typography } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-light-svg-icons'
import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import SaveWarning from '../Reusable/SaveWarning'
import EnhancedTable from '../Reusable/EnhancedTable'
import { PostData } from '../../services/PostData'

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        paddingRight: 20,
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    body: {
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
    },
    outside: {
        paddingTop: theme.spacing.unit,
        paddingBottom: theme.spacing.unit,
        paddingLeft: theme.spacing.unit,
        paddingRight: theme.spacing.unit,
        background: 'rgb(248,248,248)',
    },

    lower: {
        textTransform: 'capitalize',
    },
    buttonGrid: {
        flexDirection: 'row',
        marginLeft: 'auto',
    },
    secondary: {
        backgroundColor: theme.palette.secondary.main,
        color: 'white',
    },
    pullRight: {
        float: 'right',
    },
})

class AdvancedSearch extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            invalid: false,
            qualifier: [],
            dropdowns: {},
            fields: [
                {
                    column: {
                        label: 'Column',
                        value: '',
                        dropdowns: [],
                        error: false,
                    },
                    expression: {
                        label: 'Expression',
                        value: '',
                        dropdowns: [],
                        error: false,
                    },
                    value: {
                        label: 'Value',
                        value: '',
                        error: false,
                    },
                },
                {
                    operator: {
                        label: 'Operator',
                        value: '',
                        dropdowns: [],
                    },
                    column: {
                        label: 'Column',
                        value: '',
                        dropdowns: [],
                        error: false,
                    },
                    expression: {
                        label: 'Expression',
                        value: '',
                        dropdowns: [],
                        error: false,
                    },
                    value: {
                        label: 'Value',
                        value: '',
                        error: false,
                    },
                },
            ],
        }
    }
    getDropdowns = () => {
        let newState = this.state
        const data = {
            action: 'getNewDropdown',
            subsystem_code: 'ADVANCED_SEARCH',
        }

        PostData(
            'Migrations/dropdowns/ajax/ajaxGetDropdownItems.php',
            data
        ).then(response => {
            if (response.result) {
                
                
                for (let i = 0; i < response.dropdowns.length; i++) {
                    for (let row = 0; row < this.state.fields.length; row++) {
                        if (
                            row == 0 &&
                            response.dropdowns[i].control_code === 'OPERATOR'
                        ) {
                            continue
                        }
                        
                        newState.fields[row][
                            response.dropdowns[i].name.toLowerCase()
                        ].dropdowns.push(response.dropdowns[i].item_name)
                        if(row == 0){
                            continue
                        }
                        if(newState.dropdowns[response.dropdowns[i].name.toLowerCase()]){
                            newState.dropdowns[response.dropdowns[i].name.toLowerCase()].push(response.dropdowns[i].item_name)
                        }else{
                            newState.dropdowns[response.dropdowns[i].name.toLowerCase()] = [response.dropdowns[i].item_name]
                        }
                    }
                }
                this.setState(newState)
            }
        })
    }
    componentWillMount() {
        this.getDropdowns()
    }
    handleChange = e => {
        let newState = this.state

        newState.fields[e.target.id][e.target.name].value = e.target.value
        this.setState(newState)
    }
    handleAdd = () => {
        let newState = this.state
        newState.fields.push({
            operator: {
                label: 'Operator',
                value: '',
                dropdowns: [],
            },
            column: {
                label: 'Column',
                value: '',
                dropdowns: [],
                error: false,
            },
            expression: {
                label: 'Expression',
                value: '',
                dropdowns: [],
                error: false,
            },
            value: {
                label: 'Value',
                value: '',
                error: false,
            },
        })

        for (let field in newState.fields[newState.fields.length - 1]) {
            if (field !== 'value') {
                newState.fields[newState.fields.length - 1][field].dropdowns =
                    newState.dropdowns[field]
            }
        }
        this.setState(newState)
    }
    deleteRow = row =>{
        let newState = this.state
        newState.fields.splice(row,1)
        this.setState(newState)
    }
    getFields() {
        const { classes } = this.props
        let component = []
        for (let row = 0; row < this.state.fields.length; row++) {
            for (let field in this.state.fields[row]) {
                let xs = 2
                if (row === 0) {
                    xs = 4
                } else if (field === 'value') {
                    xs = 3
                } else if (field === 'column' || field === 'expression') {
                    xs = 3
                }
                if(xs === 2){
                    component.push(
                        <Grid item xs={1}>
                            <IconButton onClick = {() => this.deleteRow(row)}>
                                <FontAwesomeIcon icon={faTrash}/>
                            </IconButton>
                        </Grid>
                    )
                }
                if (!this.state.fields[row][field].dropdowns) {
                    component.push(
                        <Grid item xs={xs}>
                            <TextField
                                name={field}
                                id={row}
                                label={this.state.fields[row][field].label}
                                onChange={this.handleChange}
                                fullWidth
                                className={classes.textField}
                                value={this.state.fields[row][field].value}
                                error={this.state.fields[row][field].error}
                                helperText={
                                    this.state.fields[row][field].error &&
                                    'This Field is Required'
                                }
                            />
                        </Grid>
                    )
                } else {
                    component.push(
                        <Grid item xs={xs}>
                            <TextField
                                name={field}
                                id={row}
                                label={this.state.fields[row][field].label}
                                onChange={e => this.handleDChange(e, row)}
                                fullWidth
                                className={classes.textField}
                                value={this.state.fields[row][field].value}
                                error={this.state.fields[row][field].error}
                                helperText={
                                    this.state.fields[row][field].error &&
                                    'This Field is Required'
                                }
                                select
                            >
                                {this.state.fields[row][field].dropdowns.map(
                                    item => (
                                        <MenuItem
                                            key={field}
                                            value={item}
                                            id={row}
                                        >
                                            {item}
                                        </MenuItem>
                                    )
                                )}
                            </TextField>
                        </Grid>
                    )
                }
            }
            component.push(<hr width="100%" size="2" />)
        }

        let addButton = (
            <Grid container xs={12}>
                <Grid item xs={1}>
                    {this.state.fields.length <= 3 && <IconButton onClick={this.handleAdd} style={{marginLeft: 5}}>
                        <FontAwesomeIcon icon={faPlus} />
                    </IconButton>}
                </Grid>
                <Grid item xs={10} />
                <Grid item xs={1}>
                    <Button
                        style={{ color: 'black', fontSize: 18 }}
                        onClick={this.verify}
                    >
                        Go!
                    </Button>
                </Grid>
            </Grid>
        )
            
        component.push(addButton)

        return (
            <Collapse in={this.state.fields}>
                <Grid container spacing={1} xs={12}>
                    {component}
                </Grid>
            </Collapse>
        )
    }

    verify = () => {
        let x = this.state.fields
        let validator = true
        for (let i = 0; i < this.state.fields.length; i++) {
            /* so pretty much if anything in the first row is missing, this makes the variable above called validator false,
    also if the user has added more rows and they are not filled out validator will return false and finally 
    if the last row in the form has no values in it or all fields are filled, then ur good and validator will return true but if
    there is at least one value missing then validator will return false, in all cases the error attribute of the field is activated true 
    which highlights the field in the form :)*/
            if (i === 0) {
                if (!x[i].column.value) {
                    validator = false
                    x[i].column.error = true
                }
                if (!x[i].expression.value) {
                    validator = false
                    x[i].expression.error = true
                }
                if (!x[i].value.value) {
                    validator = false
                    x[i].value.error = true
                }
            } else if (i === x.length - 1) {
                if (x[i].operator.value) {
                    if (!x[i].column.value) {
                        validator = false
                        x[i].column.error = true
                    }
                    if (!x[i].expression.value) {
                        validator = false
                        x[i].expression.error = true
                    }
                    if (!x[i].value.value) {
                        x[i].value.error = true
                        validator = false
                    }
                } else if (x[i].column.value) {
                    validator = false
                    x[i].operator.error = true
                    if (!x[i].expression.value) {
                        x[i].expression.error = true
                    }
                    if (!x[i].value.value) {
                        x[i].value.error = true
                    }
                } else if (x[i].expression.value) {
                    validator = false
                    x[i].column.error = true
                    x[i].operator.error = true
                    if (!x[i].value.value) {
                        x[i].value.error = true
                    }
                } else if (x[i].value.value) {
                    validator = false
                    x[i].column.error = true
                    x[i].operator.error = true
                    x[i].value.error = true
                }
            } else {
                if (!x[i].operator.value) {
                    x[i].operator.error = true
                    validator = false
                }
                if (!x[i].column.value) {
                    validator = false
                    x[i].column.error = true
                }
                if (!x[i].expression.value) {
                    validator = false
                    x[i].expression.error = true
                }
                if (!x[i].value.value) {
                    x[i].value.error = true
                    validator = false
                }
            }
        }
        
        if (!validator) {
            this.setState({ fields: x, invalid: true })
        } else {
            let newState = this.state
            if(!this.state.fields[this.state.fields.length - 1].column.value){
                newState.fields.splice(this.state.fields.length - 1, 1)
            }
            this.go(newState)
        }
        
    }
    go = (newState) => {
        /*this section generates an sql statement based on the values provided by the user
        if the number of conditions is one or two it is pretty straight forward, 
        with 3 conditions, if the operators are all the same then there are no brackets, if they arent then 
        the brackets are always placed around the first 2 conditions*/
        let script = ""
        if(newState.fields.length === 1 ){
            script += "WHERE "
            script += this.getColumnVal(newState.fields[0].column.value)
            script += " " 
            script += this.getSQLExpression(newState.fields[0].expression.value) 
            script += " "
            script +=  this.getSQLVal(newState.fields[0].expression.value, newState.fields[0].value.value, newState.fields[0].column.value)
            script += " "
        } else if(newState.fields.length === 2){
            script += "WHERE "
            script += this.getColumnVal(newState.fields[0].column.value)
            script += " "
            script += this.getSQLExpression(newState.fields[0].expression.value) 
            script += " "
            script +=  this.getSQLVal(newState.fields[0].expression.value,newState.fields[0].value.value, newState.fields[0].column.value)
            script += " "
            script += newState.fields[1].operator.value.toUpperCase() 
            script += " "
            script += this.getColumnVal(newState.fields[1].column.value)
            script += " "
            script += this.getSQLExpression(newState.fields[1].expression.value)
            script += " "
            script +=  this.getSQLVal(newState.fields[1].expression.value,newState.fields[1].value.value, newState.fields[1].column.value)
            
        } else if(newState.fields.length === 3){
            if(this.state.fields[1].operator.value === this.state.fields[2].operator.value){
                script += "WHERE "
                script += this.getColumnVal(newState.fields[0].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[0].expression.value) 
                script += " "
                script +=  this.getSQLVal(newState.fields[0].expression.value,newState.fields[0].value.value, newState.fields[0].column.value)
                script += " "
                script += newState.fields[1].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[1].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[1].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[1].expression.value,newState.fields[1].value.value, newState.fields[1].column.value)
                script += " "
                script += newState.fields[2].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[2].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[2].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[2].expression.value,newState.fields[2].value.value, newState.fields[2].column.value)
                
            }else{ 
                script += "WHERE ("
                script += this.getColumnVal(newState.fields[0].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[0].expression.value) 
                script += " "
                script +=  this.getSQLVal(newState.fields[0].expression.value,newState.fields[0].value.value, newState.fields[0].column.value)
                script += " "
                script += newState.fields[1].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[1].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[1].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[1].expression.value,newState.fields[1].value.value, newState.fields[1].column.value)
                script += ") "
                script += newState.fields[2].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[2].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[2].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[2].expression.value,newState.fields[2].value.value, newState.fields[2].column.value)        
            }
        }else{
            if(this.state.fields[1].operator.value === this.state.fields[2].operator.value && this.state.fields[1].operator.value === this.state.fields[3].operator.value){
                script += "WHERE "
                script += this.getColumnVal(newState.fields[0].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[0].expression.value) 
                script += " "
                script +=  this.getSQLVal(newState.fields[0].expression.value,newState.fields[0].value.value, newState.fields[0].column.value)
                script += " "
                script += newState.fields[1].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[1].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[1].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[1].expression.value,newState.fields[1].value.value, newState.fields[1].column.value)
                script += " "
                script += newState.fields[2].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[2].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[2].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[2].expression.value,newState.fields[2].value.value, newState.fields[2].column.value)
                script += " "
                script += newState.fields[3].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[3].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[3].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[3].expression.value,newState.fields[3].value.value, newState.fields[3].column.value)
            }else if(this.state.fields[1].operator.value === this.state.fields[3].operator.value && this.state.fields[2].operator.value !== this.state.fields[1].operator.value){
                script += "WHERE ("
                script += this.getColumnVal(newState.fields[0].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[0].expression.value) 
                script += " "
                script +=  this.getSQLVal(newState.fields[0].expression.value,newState.fields[0].value.value, newState.fields[0].column.value)
                script += " "
                script += newState.fields[1].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[1].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[1].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[1].expression.value,newState.fields[1].value.value, newState.fields[1].column.value)
                script += ") "
                script += newState.fields[2].operator.value.toUpperCase() 
                script += " ("
                script += this.getColumnVal(newState.fields[2].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[2].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[2].expression.value,newState.fields[2].value.value, newState.fields[2].column.value)
                script += " "
                script += newState.fields[3].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[3].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[3].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[3].expression.value,newState.fields[3].value.value, newState.fields[3].column.value)
                script += ") "
            } else if (newState.fields[1].operator.value === newState.fields[2].operator.value) {
                script += "WHERE ("
                script += this.getColumnVal(newState.fields[0].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[0].expression.value) 
                script += " "
                script +=  this.getSQLVal(newState.fields[0].expression.value,newState.fields[0].value.value, newState.fields[0].column.value)
                script += " "
                script += newState.fields[1].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[1].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[1].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[1].expression.value,newState.fields[1].value.value, newState.fields[1].column.value)
                script += " "
                script += newState.fields[2].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[2].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[2].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[2].expression.value,newState.fields[2].value.value, newState.fields[2].column.value)
                script += ") "
                script += newState.fields[3].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[3].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[3].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[3].expression.value,newState.fields[3].value.value, newState.fields[3].column.value)
                script += " "
            } else {
                script += "WHERE "
                script += this.getColumnVal(newState.fields[0].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[0].expression.value) 
                script += " "
                script +=  this.getSQLVal(newState.fields[0].expression.value,newState.fields[0].value.value, newState.fields[0].column.value)
                script += " "
                script += newState.fields[1].operator.value.toUpperCase() 
                script += " ("
                script += this.getColumnVal(newState.fields[1].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[1].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[1].expression.value,newState.fields[1].value.value, newState.fields[1].column.value)
                script += " "
                script += newState.fields[2].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[2].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[2].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[2].expression.value,newState.fields[2].value.value, newState.fields[2].column.value)
                script += " "
                script += newState.fields[3].operator.value.toUpperCase() 
                script += " "
                script += this.getColumnVal(newState.fields[3].column.value)
                script += " "
                script += this.getSQLExpression(newState.fields[3].expression.value)
                script += " "
                script +=  this.getSQLVal(newState.fields[3].expression.value,newState.fields[3].value.value, newState.fields[3].column.value)
                script += ") "
            }
        } 
        console.log(script)
        
        
        //this.props.handleClose()
        this.props.handleSearchClick(script)
    }
    getSQLExpression = (expression ) =>{
        switch(expression){
            case "Contains":
                return " ILIKE "
                break
            case "Is Equal To":
                return "="
                break
            case "Does Not Equal":
                return "!="
                break
            case "Is Greater Than":
                return ">"
                break
            case "Is Less Than":
                return "<"
                break
            case "Is Greater Than or Equal To": 
                return ">="
                break
            case "Is Less Than or Equal To": 
                return "<="
                break
            case "Is In": 
                return "IN"
        }
    }

    getSQLVal = (expression, value, column) => {
        let returnVal = ""
        if(expression==="Contains"){
            returnVal += "'%"+value+"%'"
        } else{
            returnVal += "'"+value+"'"
        }

        
        return returnVal
    }
    getColumnVal=(column, expression)=>{
        column = column.toLowerCase()
        column = column.replace(/ /g, "_")
        if(column === "crm_id"){
            return "CAST(cm." + column + " as text)"
        } else if(column === "address") {
            return "cm.address_1"
        }else if(column === 'po_box'){
            return "cm.p_o_box"
        } else if(column === 'strategic_business_unit'){
            return "ci."+column
        } 
        return "cm."+column
    }

    handleDChange = (e, id) => {
        let newState = this.state
        

        newState.fields[id][e.target.name].value = e.target.value
        this.setState(newState)
    }
    render() {
        const { classes } = this.props

        return (
            <Card>
                <Grid
                    onClick={this.handleCheck}
                    container
                    className={classes.outside}
                    direction="column"
                    alignItems="stretch"
                >
                    <SaveWarning invalid={this.state.invalid} customMessage='Please fill in the highlighted fields to perform the search'/>
                    <Grid item xs={12}>
                        <Typography component='h1' variant = 'h6' style={{paddingBottom: 5}}>
                            Advanced Search
                        </Typography>
                    </Grid>
                    {/*<div className={classes.secondary}>
                        <Grid container>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                className={classes.textField}
                            >
                                Advanced Search
                            </Typography>
                            <Grid item className={classes.buttonGrid}>
                                <Button onClick={this.props.handleClose}>
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        size="2x"
                                        color="white"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </div>*/}

                    {this.getFields()}
                </Grid>
            </Card>
            
        )
    }
}

AdvancedSearch.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(AdvancedSearch)