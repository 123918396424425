import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import { FilePicker } from 'react-file-picker'

export default class FileUploader extends Component {
    render() {
        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={6}>
                        <FilePicker
                            extensions={['md']}
                            // onChange={FileObject => (/* do something with File object */)}
                            // onError={errMsg => (/* do something with err msg string */)}
                        >
                            <Button variant="contained" component="label">
                                {this.props.text
                                    ? this.props.text
                                    : 'Upload'}
                            </Button>
                        </FilePicker>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography>Test</Typography>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}
