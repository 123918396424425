import React, { Component } from 'react'
import './App.css'
import { Provider } from 'react-redux'

import ClearCache from 'react-clear-cache'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import teal from '@material-ui/core/colors/teal'

import Routes from './routes'
import { isThisISOWeek } from 'date-fns'

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#25525f',
        },
        secondary: {
            main: '#4f8c98',
        },
        background: {
            main: '#d2d2d2',
            //main: 'red'
        },
    },
})

class App extends Component {
    constructor(props) {
        super(props)
        this.state = {
            appName: 'HBOT',
            home: false,
        }
    }

    render() {
        require('dotenv').config()

        return (
            <MuiThemeProvider theme={theme}>
                <ClearCache auto>
                    {({ isLatestVersion, emptyCacheStorage }) => (
                        <div>
                            {!isLatestVersion && (
                                <p>
                                    <a
                                        href="#"
                                        onClick={e => {
                                            e.preventDefault()
                                            emptyCacheStorage()
                                        }}
                                    >
                                        Update version
                                    </a>
                                </p>
                            )}
                        </div>
                    )}
                </ClearCache>
                <Routes />
            </MuiThemeProvider>
        )
    }
}

export default App
