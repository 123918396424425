import React from 'react'
import ReactDOM from 'react-dom'
import { Link } from 'react-router-dom'
import { PostData } from '../../services/PostData'

import classNames from 'classnames'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Checkbox from '@material-ui/core/Checkbox'
import IconButton from '@material-ui/core/IconButton'
import Tooltip from '@material-ui/core/Tooltip'
import DeleteIcon from '@material-ui/icons/Delete'
import FilterListIcon from '@material-ui/icons/FilterList'
import SearchIcon from '@material-ui/icons/Search'
import InputBase from '@material-ui/core/InputBase'
import { lighten } from '@material-ui/core/styles/colorManipulator'
import Collapse from '@material-ui/core/Collapse'
import Card from '@material-ui/core/Card'

import { notDeepEqual } from 'assert'

function desc(a, b, orderBy) {
    if(!a||!b){
        return 0
    }
    if (b[orderBy] < a[orderBy]) {
        return -1
    }
    if (b[orderBy] > a[orderBy]) {
        return 1
    }
    return 0
}

function stableSort(array, cmp) {
    const stabilizedThis = array && array.map((el, index) => [el, index])
    stabilizedThis && stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0])
        if (order !== 0) return order
        return a[1] - b[1]
    })
    return stabilizedThis && stabilizedThis.map(el => el[0])
}

function getSorting(order, orderBy) {
    return order === 'desc'
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy)
}

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property)
    }

    render() {
        const {
            onSelectAllClick,
            order,
            orderBy,
            numSelected,
            rowCount,
        } = this.props

        const rows = this.props.rows

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox" />
                    {rows && rows.map(
                        row => (
                            <TableCell
                                key={row.id}
                                align={row.numeric ? 'right' : 'left'}
                                padding={
                                    row.disablePadding ? 'none' : 'default'
                                }
                                sortDirection={
                                    orderBy === row.id ? order : false
                                }
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={
                                        row.numeric
                                            ? 'bottom-end'
                                            : 'bottom-start'
                                    }
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.props.activeRecents ? () => this.props.switchOffRecents(row.id) : this.createSortHandler(row.id)}
                                        //onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        )
    }
}

EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
}

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
})

let EnhancedTableToolbar = props => {
    const { numSelected, classes } = props

    return (
        <Toolbar
            className={classNames(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {props.toolbar}
        </Toolbar>
    )
}

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
}

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar)

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
})

class EnhancedTable extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'name',
        selected: [],
        //data: [],
        page: 0,
        rowsPerPage: 15,
    }

    handleRequestSort = (event, property) => {
        const orderBy = property
        let order = 'desc'

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc'
        }

        this.setState({ order, orderBy })
    }

    handleSelectAllClick = event => {
        if (event.target.checked) {
            this.setState(state => ({ selected: state.data.map(n => n.id) }))
            return
        }
        this.setState({ selected: [] })
    }

    handleChangePage = (event, page) => {
        this.setState({ page })
    }

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value })
    }

    componentWillReceiveProps(nextProps){
        let columns = nextProps.columns
        if(columns && columns.length > 0 && typeof columns[0] === 'string'){
            
            const orderBy = columns[0]
            columns.shift()
            let order = 'asc' 
            

            //if(this.state.orderBy === orderBy && this.state.order === 'desc'){
            //    order = 'desc'
            //}
            this.setState({order: order, orderBy: orderBy, columns: nextProps.columns})
        }
        else if(this.props.activeRecents){
            this.setState({order: 'asc', orderBy: 'sort_order'})
        }

        if (nextProps.rowsPerPage) {
            this.setState({ rowsPerPage: nextProps.rowsPerPage })
        }
    }

    isSelected = id => this.state.selected.indexOf(id) !== -1

    render() {
        const { classes } = this.props
        const {
            //data,
            order,
            orderBy,
            selected,
            rowsPerPage,
            page,
        } = this.state
        let n = {id:<Typography style={{marginVertical: 10}} component='h6' variant='h6'>Recent</Typography>}
        const data = this.props.columns

        const emptyRows =
            rowsPerPage -
            Math.min(rowsPerPage, data ? data.length : 0 - page * rowsPerPage)

        return (
            <Paper className={classes.root}>
                {this.props.showHeader && (
                    <EnhancedTableToolbar
                        numSelected={selected.length}
                        modelPlural={this.props.modelPlural}
                        dialog={this.props.dialog}
                        toolbar={this.props.toolbar}
                    />
                )}
                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                    >
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={this.handleSelectAllClick}
                            onRequestSort={this.handleRequestSort}
                            switchOffRecents = {this.props.switchOffRecents}
                            activeRecents={this.props.activeRecents}
                            rowCount={data ? data.length: 0}
                            rows={this.props.rows}
                        />
                        <TableBody>
                            {this.props.totalRow && (
                                <TableRow>
                                    {this.props.totalRow}
                                </TableRow>
                            )}

                            {this.props.activeRecents && <TableRow   style={{textDecoration:'none', height: 60}}>
                                {React.cloneElement(this.props.tableRow,{n:n})}                       
                                
                            </TableRow>}
                            {data && stableSort(data, getSorting(order, orderBy))
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map(n => {
                                    const isSelected = this.isSelected(n.id)

                                    return (
                                        <React.Fragment>
                                            <TableRow
                                                hover={typeof n.id !== 'object'}
                                                
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.id}
                                                selected={isSelected}
                                                component={typeof this.props.link === 'string' ? Link : 'tr' }
                                                to={
                                                    (typeof n.id !== 'object' && typeof this.props.link === 'string') && (this.props.link + (n.id ? n.id : n.crm_id))
                                                }
                                                onClick={typeof this.props.link !== 'string' && this.props.link && (() =>this.props.link(n))}
                                                style={{textDecoration: 'none'}}
                                            >
                                                {React.cloneElement(
                                                    this.props.tableRow,
                                                    {
                                                        n: n,
                                                        additionalData: this
                                                            .props
                                                            .additionalData,
                                                    }
                                                )}
                                            </TableRow>

                                            {/* {this.props.expandable && (
                                                <Collapse
                                                    in={
                                                        this.state.expanded ===
                                                        n.id
                                                    }
                                                    timeout="auto"
                                                    component={CollapseRow}
                                                    unmountOnExit
                                                >
                                                    {
                                                        n.notes
                                                    }
                                                </Collapse>
                                            )} */}
                                        </React.Fragment>
                                    )
                                })}
                                
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[15, 20, 25]}
                    component="div"
                    count={data && data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </Paper>
        )
    }
}

class CollapseRow extends React.Component {
    render() {
        return (
            <TableRow>
                <TableCell colSpan="7">
                    {this.props.children}
                </TableCell>
            </TableRow>
        )
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(EnhancedTable)